//import logo from './logo.svg';
import '../App.css';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import React, { useState } from "react";
import HeadersExcel from "../pages/HeadersExcel";
import CustomersExcel from "../pages/CustomersExcel";
import ExportCSVExcel from '../pages/ExportCSVExcel';

// generate customer objects

const ExcelCustInv = () => {
 

  const headers = [
    { label: "Inv.No", key: "inv_no" },
    { label: "Date", key: "inv_date" },
    { label: "Customer", key: "customer_name" },
    { label: "GST IN", key: "gst" },
    { label: "Sub Total", key: "sub_total" },
    { label: "CGST", key: "cgst" },
    { label: "SGST", key: "sgst" },
    { label: "Total Amount", key: "total_amount" },
  ];

  //function customersData() {
    let invArr = [
    /*
    for (let i = 0; i <= 29; i++) {
      custs[i] = {
        firstName: `firstname${i}`,
        lastName: `lastname${i}`,
        email: `mail${i}@mail.com`,
        address: `#${i}, block name, floor #${i} street name, city name, state name`,
        postcode: `${i}0000`
      };
      */
      {
        inv_no: "101",
        inv_date: "12-03-2023",
        customer_name: "vimal",
        gst:"1234",
        sub_total:"5000",
        cgst:"567",
        sgst:"234",
        total_amount: "4000",
        
      },
      {
        inv_no: "102",
        inv_date: "04-12-2023",
        customer_name: "haritha",
        gst:"8907",
        sub_total:"8000",
        cgst:"789",
        sgst:"2567",
        total_amount: "6000",
       
      },
      {
        inv_no: "103",
        inv_date: "18-09-2023",
        customer_name: "harshi",
        gst:"7854",
        sub_total:"9000",
        cgst:"3463",
        sgst:"6747",
        total_amount: "8000",
        
      },
      {
        inv_no: "104",
        inv_date: "15-07-2023",
        customer_name: "jas",
        gst:"78567",
        sub_total:"6000",
        cgst:"675",
        sgst:"855",
        total_amount: "13000",
       
      },
    ];
    
    //return invArr;
  
  //console.log(customersData());
  const [customers, setCustomers] = useState(invArr);
 
  const wscols = [
    
    {
      wch: Math.max(...customers.map((customer) => customer.inv_no.length))
    },
    
    { wch: Math.max(...customers.map((customer) => customer.inv_date.length)) },
    { wch: Math.max(...customers.map((customer) => customer.customer_name.length)) },
    { wch: Math.max(...customers.map((customer) => customer.gst.length)) },
    { wch: Math.max(...customers.map((customer) => customer.sub_total.length)) },
    { wch: Math.max(...customers.map((customer) => customer.cgst.length)) },
    { wch: Math.max(...customers.map((customer) => customer.sgst.length)) },  
  ];

  console.log(
    Math.max(...customers.map((customer) => customer.total_amount.length))
  );
  

  return (
    <div className="App">
      <HeadersExcel className="header" topicTitle="Customer Invoice" />
      <div className="row">
        <div className="col-md-4 center"> 
          <ExportCSVExcel
            csvData={customers}
            fileName={"cust-Inv_2024_01_17_11_34_55_88899.csv"}
            wscols={wscols}
          />
        </div>
      </div>
      <CustomersExcel customers={customers} />
    </div>
  );
};

export default ExcelCustInv;
