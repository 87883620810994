import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Form, Button, Spinner, Image } from "react-bootstrap";
import { withRouter } from "react-router";
//import Sidebar from './Sidebar';
import Sidebar2 from "./Sidebar2";
import Footer from "./Footer";
//import Topmenu from "./Topmenu";
import OrderDetails from "./OrderDetails";
import "../Dashboard.css";

//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";

import { useNavigate } from "react-router-dom";

import * as Icon from "react-bootstrap-icons";
import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsCardText,
} from "react-icons/bs";

import { BsFillEnvelopePlusFill } from "react-icons/bs";

import axios from "axios";
import { client } from "../config/Config";
import SidebarMain from "./SidebarMain";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';

const ListProductPricing = (props) => {
  //function ListUsers(props) {
  const initialized = useRef(false);
  const navigate = useNavigate();

  var productArr = [
    {
      id: 1,
      productcode: "M5",
      productname: "M5",
      price: "3000",
    },
    {
      id: 2,
      productcode: "M15",
      productname: "M15",
      price: "3200",
    },
    {
      id: 3,
      productcode: "M30",
      productname: "M30",
      price: "3400",
    },
  ];

  const [productList, setProductList] = useState(productArr);
  const [toRender, setToRender] = useState(false);
  //navigate("/addUpdateUser");

  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::ListProduct called ##########################"
      );
      setToRender(true);
      initialized.current = true;
    }
  }, []);

  const handleAdd = async (e) => {
    console.log("handle add product called : ", e);
    navigate("/addupdateproduct", { state: {} });
  };

  const handleView = async (e) => {
    console.log("handle edit product called : ", e);
    navigate("/addupdateproduct", { state: {} });
  };

  const handleOnChange = async (e) => {
    console.log("handle on change called : ", e);
  };

  return (
    <Container>
      <SidebarMain />
      { toRender ? 
        <form>
        <Row>
          <Col xs={6} md={6} sm={6}>
            <h2 className="text-left">Pricing</h2>
          </Col>
          <Col xs={6} md={6} sm={6}>
            <Button
              id="addUpdateId"
              className="float-end"
              onClick={(e) => handleAdd(e)}
            >
              Price
            </Button>
          </Col>
        </Row>

        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th key={"product-list-heading-key"}>S.No</th>
              <th>Product Code</th>
              <th>Product Name</th>
              <th> Price</th>
              <th>Active</th>
              <th>...</th>
            </tr>
          </thead>
          <tbody>
            {productList.map((product, idx) => (
              <tr key={`product-table-row-id-${idx}`}>
                <td key={`product-list-item-key-${idx}`}>{idx + 1}</td>
                <td key={`product-list-productcode-key-${idx}`}>
                  {product.productcode}
                </td>
                <td key={`product-list-productname-key-${idx}`}>
                  {product.productname}
                </td>
                <td key={`product-list-price-key-${idx}`}>{product.price}</td>
                <td>
                  <Form.Check // prettier-ignore
                    key={`product-switch-key-${idx}`}
                    type="switch"
                    id="user-switch"
                    onChange={(e) => handleOnChange(e)}
                    checked={true}
                  />
                </td>
                <td>
                  <BsCardText
                    key={`view-icon-product-key-${idx}`}
                    size={28}
                    style={{ color: "#0D6EFD", cursor: "pointer" }}
                    onClick={(e) => handleView(e)}
                    productcode={`view-icon-product-productcode-${idx}`}
                    productname={`view-icon-product-productname-${idx}`}
                    price={`view-icon-product-price-${idx}`}
                    id={`view-icon-product-id-${idx}`}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <br></br>
        </form>
       : 
       <div className="container-spinner">
      <div className="vertical-center-spinner">
       {/*<Button variant="primary" disabled>*/}
       <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{ width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
 
       {/*</Button>*/}
       </div>
       </div>
 }
        
    </Container>
  );
};

export default ListProductPricing;
