import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

import { COMPANY_NAME } from '../../config/Config';

const styles = StyleSheet.create({
   
    titleContainer:{
        flexDirection: 'row',
        marginTop: 72
    },
    reportTitle:{
        fontSize: 12,
        flexDirection: 'row',
        textAlign: 'flex-end', 
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    },
    container2: {
        paddingBottom:0,
        flexDirection: 'row',
    }

  });


  const InvoiceThankYouMsg = () => (
    <View>
    <View style={{display: "flex", flexDirection: "row", marginTop: 10}}>
       
        <Text style={{flex: 1}}>Thanks for your business with {COMPANY_NAME}</Text>
      </View>
    <View style={{display: "flex", flexDirection: "row", marginTop: 50}}>
        <Text style={{flex: 1}}>Dispatched By</Text>
        <Text style={{flex: 1, textAlign: 'right'}}>Customer Signature</Text>
      </View>
    </View>
  );
  
  export default InvoiceThankYouMsg