import React from 'react';
import { Page, Document, Image, StyleSheet, View, Text, Span } from '@react-pdf/renderer';
import OrderTitle from './OrderTitle'
import OrderBillTo from './OrderBillTo'
import OrderNo from './OrderNo'
import OrderItemsTable from './OrderItemsTable'
import OrderThankYouMsg from './OrderThankYouMsg'
import logo_left from '../../../src/logo_left.png';
import logo_right from '../../../src/logo_right.png';
import logo_qrcode from '../../../src/assets/images/qrcode/461.png';
import logo2 from '../../../src/logo_avn.png';
import logo3 from '../../../src/kmc_logo.jpg';
import logo_addr from '../../../src/logo_addr.jpeg';
import OrderAdditionalCharges from './OrderAdditionalCharges';
import OrderCompanyAddress from './OrderCompanyAddress';

import invoice from '../../data/invoice';
import { Postcard } from 'react-bootstrap-icons';
import Badge from 'react-bootstrap/Badge';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 9,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
        flexDirection: 'column',
    }, 
    logo: {
        //width: '100%',
        //height: '33%',
        width: '100%',
        height: '100%',
        paddingTop: 30,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    logo_image2: {
        width: '15%',
        height: '98px',
        paddingTop: 30,
        marginLeft: 'auto',
        marginRight: 'auto' 
    },
    business_content: {
        width: '60%',
        height: '98px',
        //paddingTop: 30,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    container2: {
        //paddingBottom:0,
        flexDirection: 'row',
        //borderBottomColor: '#bff0fd',
        //backgroundColor: '#bff0fd',
        //borderBottomWidth: 1,
        //alignItems: 'center',
        //height: 12,
        //textAlign: 'center',
        //fontStyle: 'bold',
        //flexGrow: 1,
    },
    row: {
        flex: 1,
        flexDirection: 'row',
        flexGrow: 1,
    },
    left: {
        // width: '33%',//<- working alternative
        flexGrow: 0,
        flexShrink: 1,
        flexBasis: 200,
      },
    
      right: {
        //padding: 5,
        // width: '66%', //<- working alternative
        flexShrink: 1,
        flexGrow: 2,
        alignItems: 'right',
        textAlign: 'right'
      },
    container3: {
        flexDirection: 'row',
        //width: '33%',
        //borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        textAlign: 'center',
        //height: 12,
        //fontStyle: 'bold',
        //flexGrow: 1,
        //marginLeft: '40%'
        //paddingBottom:1,
        //marginBottom: 12
        //marginTop: 48
    },
    containerGst: {
        //flexDirection: 'row',
        //width: '33%',
        //borderBottomColor: '#bff0fd',
        
        alignItems: 'left',
        textAlign: 'left',
    },
    containerContact: {
        alignItems: 'right',
        textAlign: 'right',
        //paddingBottom: 180,
    },
    border: { 
        flexDirection: 'column', 
        backgroundColor: '#FFFFFF', 
        margin: 10, 
        borderTop: 3, 
        borderBottom: 3, 
        borderLeft: 3, 
        borderRight: 3, 
        borderColor: '#22618F', 
        BorderStyle: 'solid' 
    },
    row2: {
        flex: 1,
        flexDirection: 'row',
        flexGrow: 1,
      },
      left2: {
        //width: '100%',//<- working alternative
        flexGrow: 0,
        flexShrink: 1,
        flexBasis: 200,
        margin: 10, 
        borderTop: 3, 
        borderBottom: 3, 
        borderLeft: 3, 
        borderRight: 3, 
        borderColor: '#22618F', 
        BorderStyle: 'solid'
      },
    
      right2: {
        padding: 5,
        width: '50%', //<- working alternative
        flexShrink: 1,
        flexGrow: 2,
        margin: 10, 
        borderTop: 3, 
        borderBottom: 3, 
        borderLeft: 3, 
        borderRight: 3, 
        borderColor: '#22618F', 
        BorderStyle: 'solid'
      },
      row3: {
        //flex: 1,
        flexDirection: 'row',
        //flexGrow: 1,
      },
      left3: {
        // width: '33%',//<- working alternative
        padding: 5,
        flexGrow: 0,
        flexShrink: 1,
        flexBasis: 230,
        margin: 2, 
        borderTop: 1, 
        borderBottom: 1, 
        borderLeft: 1, 
        borderRight: 1, 
        borderColor: '#e7e5e5', 
        BorderStyle: 'dashed',
        height: '120px',
        //border: 'solid #e7e5e5 3px',
        //padding: '1em',
        //margin: '1em',
        borderRadius: 5
      },
    
      right3: {
        padding: 5,
        // width: '66%', //<- working alternative
        flexShrink: 1,
        flexGrow: 2,
        flexShrink: 1,
        flexGrow: 2,
        margin: 2, 
        borderTop: 1, 
        borderBottom: 1, 
        borderLeft: 1, 
        borderRight: 1, 
        borderColor: '#e7e5e5', 
        BorderStyle: 'double',
        height: '120px',
        borderRadius: 5
      },
  });

  //var invoice_id = 461;
  //var pathQrCode = '../assets/images/qrcode/' + invoice_id + '.png';
  
  
  const Order = (props) => {
      console.log("Inside Invoice component ##################");
      console.log(">>>>>>>>>>>>>>> props data : ***************** ", props.orderLoad.company_info);
  //const Invoice = ({invoice, orderLoad}) => (
    const SLASH_CHAR = "/"
    return (
            <Document>
                <Page size="A4" style={styles.page}>
                    
                {/*<View style={styles.row}>*/}
                        {/*<Image style={styles.logo} src={logo_addr} />*/}
                        {/*
                        <View style={styles.left}>
                        <Text>GSTIN: 33ALUPA7680B1Z5</Text>
                        </View>
                        */}
                        {/*
                        <View style={styles.container3}>
                        <Image style={styles.logo_image2} src={logo_kmc2} />
                        <View style={styles.business_content} key="address-key">
                            <Text style={{fontSize: "20px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{props.orderLoad.company_info.name}</Text>
                            <Text>{props.orderLoad.company_info.address_line1}</Text>
                            <Text>{props.orderLoad.company_info.address_line2}-{props.orderLoad.company_info.pincode}</Text>
                            <Text>{props.orderLoad.company_info.district_name}, {props.orderLoad.company_info.state_name}</Text>
                            
                        </View>
                        </View>
                        */}
                        {/*
                        <View style={styles.right}>
                        <Text>Cell: 9942410747 / 8870886958</Text>
                        </View>
                        */}
                        {/*
                        <View style={styles.row} key="address-key">
                            <Text>KMC CONCRETE</Text>
                            <Text>Thannerpandhal, Avinashi</Text>
                            <Text>Tiruppur District, 641654</Text>
                        </View>
                        */}
                        
                    {/*</Page></View>*/}

                    {/*
                    <View style={styles.container3}>
                        <CompanyAddress invoice={invoice}/>
                    </View>
                    */}
                    <View style={{flexDirection: 'row', alignItems: 'center', textAlign: 'center'}}>
                        <View style={{flex: 1, width: '20%', alignItems: 'center'}}>
                            <Text style={{textAlign: 'center', fontSize: "13px",fontWeight: 'bold', fontFamily:'Helvetica-Bold'}}>&nbsp;&nbsp;Invoice&nbsp;&nbsp;</Text>
                        </View>
                        <View><Text style={{textAlign: 'right', fontSize: "11px",fontWeight: 'bold', fontFamily:'Helvetica'}}>{"[Original]"}</Text></View>
                    </View>

                    <View style={{flexDirection: 'row'}}>
                        <View style={{flex: 1}}>
                            <Text><Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>GSTIN : </Text>{props.orderLoad.company_info.gst_no}</Text>
                        </View>

                        <View style={{flex: 1}}>
                            <Text style={{textAlign: 'right'}}><Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Cell : </Text>{ props.orderLoad.company_info.mobile_primary ? `${props.orderLoad.company_info.phone} / ${props.orderLoad.company_info.mobile_primary}` : `${props.orderLoad.company_info.phone}` }</Text>

                        </View>
                    </View>
                    

                    {/*<View style={{flex: 1, flexDirection: 'row'}}>
                            <Image src={logo_kmc2} />
                        
                            <View key="address-key">
                                <Text style={{fontSize: "20px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{props.orderLoad.company_info.name}</Text>
                                <Text>{props.orderLoad.company_info.address_line1}</Text>
                                <Text>{props.orderLoad.company_info.address_line2}-{props.orderLoad.company_info.pincode}</Text>
                                <Text>{props.orderLoad.company_info.district_name}, {props.orderLoad.company_info.state_name}</Text>
                            </View>
                    </View>
                    */}

                <View style={{flexDirection: 'row'}}>
                    <View style={{  textAlign: 'right', width: '15%' }}> 
                        <Image style={{ marginHorizontal: '10%', height: "48px", width: "48px" }} src={logo_right} />
                    </View>
                    
                    <View style={{flex: 1, width: "70%", paddingRight: "0px"}} key="address-key">
                        <Text style={{fontSize: "20px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold', color: "green", alignItems: "center", textAlign: 'center'}}>{props.orderLoad.company_info.name}</Text>
                        <Text style={{alignItems: 'left !important', textAlign: 'left !important'}}><Text style={{fontFamily: 'Helvetica-Bold'}}>Head Office:</Text> {props.orderLoad.company_info.address_line1}, {props.orderLoad.company_info.address_line2}-{props.orderLoad.company_info.pincode}, {props.orderLoad.company_info.district_name}, {props.orderLoad.company_info.state_name}</Text>
                        <Text style={{alignItems: 'left !important', textAlign: 'left !important'}}><Text style={{fontFamily: 'Helvetica-Bold'}}>Branch:</Text> {props.orderLoad.company_info.address_line1_branch1}, {props.orderLoad.company_info.address_line2_branch1}-{props.orderLoad.company_info.pincode_branch1}, {props.orderLoad.company_info.district_name_branch1}, {props.orderLoad.company_info.state_name_branch1}</Text>
                    </View>

                    <View style={{  textAlign: 'right', width: '15%' }}> 
                        <Image alt={"qrcode"} src={`/images/qrcode/${props.orderLoad.order_info.id}.png`}/>
                    </View>

            
                </View>
                    
                    
                    {/*<OrderNo orderLoad={props.orderLoad}/>*/}
                    {/*<View style={styles.container2}>*/}
                        {/*<OrderNo style={styles.left2} orderLoad={props.orderLoad}/>*/}
                        {/*
                        <View style={styles.left2}>
                            <Text><Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Order No: </Text>{props.orderLoad.order_info.order_no}</Text>
                            <Text><Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Order Date: </Text>{props.orderLoad.order_info.order_date}</Text>
                            <Text><Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>State : </Text>TAMIL NADU UYUHHHGG^^GGFREEEEEEKKKKKKKKKKKJJJJJJJJJJJJMNHFVFF</Text>
                            <Text><Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>State Code: </Text>33</Text>
                        </View>
                        */}
                        {/*<OrderBillTo style={styles.right2} orderLoad={props.orderLoad} />*/}
                        <View style={styles.row3}>
                            {/*<View style={styles.left3}>*/}
                            <OrderNo orderLoad={props.orderLoad}/>   
                            {/*<Text><Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Order No: </Text>{props.orderLoad.order_info.order_no}</Text>
                            <Text><Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Order Date: </Text>{props.orderLoad.order_info.order_date}</Text>
                            <Text><Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>State : </Text>TAMIL NADU</Text>
                    <Text><Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>State Code: </Text>33</Text>*/}
                            {/*</View>*/}
                        
                            {/*
                            <View style={styles.right3}>
                                <Text>Anand Constructions</Text>
                            </View>
                            */}
                            <OrderBillTo orderLoad={props.orderLoad} />
                        </View>
                    {/*</Page></View>*/}
                    <OrderItemsTable orderLoad={props.orderLoad}/>
                    
                    <OrderThankYouMsg items={props.orderLoad.prod_list} invoice={props.orderLoad}/>
                </Page>
            </Document>
        );
  }
  
  export default Order
