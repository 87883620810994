import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Form, Button, View, Modal,Spinner, Image } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
import Sidebar2 from "./Sidebar2";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
//import ListGroup from "react-bootstrap/ListGroup";
//import Badge from "react-bootstrap/Badge";
//import DateTimeField from "react-bootstrap-datetimepicker"
//import DateTimePicker from "react-datetime-picker";

import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client, appGlobal } from "../config/Config";
import axios from "axios";

import { useLocation } from "react-router-dom";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import { BsFillPenFill, BsFillTrashFill,BsArrowLeftCircle,BsPencilSquare } from "react-icons/bs";

import DateTimePicker from "react-datetime-picker";
import SidebarMain from "./SidebarMain";
import {getDistrictList, getStateList } from "./utils";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';

const AddUpdateEmployee = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  //console.log("PASSED STATE VALUE IN Patient Add/Update page : ", state);
  let initialItems = [];
  /*
  const roleArr = [
    { value: 1, label: "Manager" },
    { value: 2, label: "Owner" },
    { value: 3, label: "Supervisor" },
  ];
  */
  const [optionsRole, setOptionsRole] = useState([]);
  
  const [empRoleIdSelect, setEmpRoleIdSelect] = useState({})
  const [empDeptIdSelect, setEmpDeptIdSelect] = useState({})
  const [optDist, setOptDist] = useState([]);
 /*
  const deptArr = [
    { value: 1, label: "Supervising" },
    { value: 2, label: "Production" },
    { value: 3, label: "Tranport" },
    { value: 4, label: "Office" },
    { value: 5, label: "Canteen" },
  ];
  */
  const [optionsDepartment, setOptionsDepartment] = useState({});
  const [departmentIdSelect, setDepartmentIdSelect] = useState({});


 // const [optionsDepartment, setOptionsDepartment] = useState(departmentArr);

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }
  const intialValues = {
    employee_no: "",
    email: "",
    designation: "",
    employee_role: "",
    employee_department:"",
    address_line1: "",
    address_line2: "",
	  emg_contact_person: "",
	  emg_contact_person_no: "",
	  emg_contact_person_relation: "",
    stateId: 0,
    districtId: 0,
    pincode: "",
    first_name: "",
    lastname: "",
    date_of_birth: formatDate(new Date()),
    date_of_joining: formatDate(new Date()),
    contact_no_primary: "",
    contact_no_secondary: "",
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [validateError, setValidateError] = useState(false);

  const [refresh, setRefresh] = useState(false);

  const [roleIdSelect, setRoleIdSelect] = useState({});
  const [employmenttypeIdSelect, setEmploymenttypeIdSelect] = useState({});
  var validateErr = false;

  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [isReadOnly, setIsReadOnly] = useState(true);

  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  //const [selectedId, setSelectedId] = useState(-1);
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
    
  );
  const [submitAction, setSubmitAction] = useState(false);
  const [toRender, setToRender] = useState(false);

 

  const handleValueChange = async (e, data) => {
    //e.preventDefault();
    console.log("Button clicked : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);

    if (name === "contact_no_primary" || name === "contact_no_secondary" || name === "emg_contact_person_no" || name === "pincode") {

      console.log("Inside check ******************");
      //const numericRegExp = /^(?:[1-9]\d*|\d)$/;
      if (e.target.value.match(appGlobal.wholeNumberRegExp)) {
        //var valueTmp = Math.round(e.target.value.replace(/\D/g, ""));
        setFormValues({ ...formValues, [name]: e.target.value });
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
    /*
      console.log("Inside check ******************");
     // const numericRegExp = /^(?:[1-9]\d*|\d)$/;
     var valueTmp = e.currentTarget.value.replace(/[^\d]/g, '');
     // if (e.currentTarget.value.match(numericRegExp)) {
      //var valueTmp = Math.round(e.target.value.replace(/\D/g, ""));
      setFormValues({ ...formValues, [name]: valueTmp });
      //setFormValues({ ...formValues, [name]: e.currentTarget.value });
      //}
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
   */
  };

  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
   // console.log("contact number length : ", values["emg_contact_person_no"].length);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;
    /*
    if (!values.employee_name) {
      errors.employee_name = "Employee name is required";
      validateErr = true;
      setValidateError(true);
    }
    */
    
    if (!values.first_name) {
      errors.first_name = "Employee name is required!";
      validateErr = true;
      setValidateError(true);
    }
    
    if (!values.employee_no) {
      errors.employee_no = "Employee number is required!";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.address_line2) {
      errors.address_line2 = "City / Town is required!";
      validateErr = true;
      setValidateError(true);
    }
    
    {/*
    if (!values.emg_contact_person) {
      errors.emg_contact_person = "Emergency Contact Person  is required!";
      validateErr = true;
      setValidateError(true);
    }
  */}
    if (!values.pincode) {
      errors.pincode = "Pincode is required!";
      validateErr = true;
      setValidateError(true);
    }
    if (values.pincode && values.pincode.length != 6) {
      errors.pincode = "Pincode should be 6 digits!";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.contact_no_primary) {
      errors.contact_no_primary = "Contact Number is required!";
      validateErr = true;
      setValidateError(true);
    }
    
    if (values.contact_no_primary && values.contact_no_primary.length != 10) {
      errors.contact_no_primary = "Contact number should be 10 digits!";
      validateErr = true;
      //setValidateError(true);
    }
    {/*
    if (!values.emg_contact_person_no) {
      errors.emg_contact_person_no = "Contact Number is required!";
      validateErr = true;
      setValidateError(true);
    }
   
    if (values.emg_contact_person_no && values.emg_contact_person_no.length != 10) {
      errors.emg_contact_person_no = "Contact number should be 10 digits!";
      validateErr = true;
      //setValidateError(true);
    }
  */}
   // console.log("BEFORE RETURN validate errors ...", validateError);
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };

  const handleRoleSelect = (e) => {
    console.log("handleRoleSelect called ...", e);
    setEmpRoleIdSelect(e);
  };
  const handleDepartmentSelect = (e, data) => {
    console.log("handleDepartmentSelect called ...", e);
    setEmpDeptIdSelect(e);
  };
  const handleEmploymentTypeSelect = (e, data) => {
    console.log("handleEmploymentTypeSelect called ...", e);
    setEmploymenttypeIdSelect(e);

    var EmploymentTypeIdInput = e.value;
    var EmploymentTypeNameInput = e.label;
    //getDistrictList(stateIdInput, stateNameInput);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit called ...");
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
   
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ",
      validateErr
    );
    if (validateErr === false) {
      console.log("FORM VALUES at handleSubmit: ", formValues);
     // console.log("LINE ITEMS : ", items);
     callUpdateApi();
     // console.log("Form submit called ...");

     // console.log("User Name : ", formValues["name"]);
    }
  };
  /*
  var optionsState = [
    { value: 1, label: "TamilNadu" },
    { value: 2, label: "Kerala" },
    { value: 3, label: "Karnataka" },
  ];
  */

  const [optionsState, setOptionsState] = useState([]);
  const [stateIdSelect, setStateIdSelect] = useState({});

  const handleStateSelect = async(e) => {
    console.log("handleStateSelect called ...", e);
    setStateIdSelect(e);

    // var stateIdInput = e.value;
    //var stateNameInput = e.label;
    var [retStateDist, distArrRet] = await getDistrictList(e.value);
    if ( retStateDist ){
      setOptDist(distArrRet);
      setDistrictIdSelect(distArrRet[0]);
    }
  };
  
  const [districtIdSelect, setDistrictIdSelect] = useState({});
  const handleDistrictSelect = (e, data) => {
    console.log("handleDistrictSelect called ...", e);
    setDistrictIdSelect(e);
  };

  const handleEdit = async (e) => {
    console.log("Handle Edit user called : ", e);
    setIsReadOnly(false);
  };

  const handleDelete = async (e) => {
    console.log("Handle delete user called : ", e);
  };
  const handleCloseDialog = () => {
    setShowDialog(false);
    navigate("/ListEmployees");
  };

  const handleReset = async (e) => {
    console.log("Handle click Reset called : ",e);
    navigate(0);
  }
  const handleCancel = async (e) => {
    console.log("Handle click Cancel called : ", e);
  };
  const handleBack = async (e) => {
    console.log("handle back clicked : ", e);
    navigate(-1);
  };

  const toSqlDatetime = (inputDate) => {
    const date = new Date(inputDate);
    const dateWithOffest = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    return dateWithOffest.toISOString().slice(0, 19).replace("T", " ");
  };

  const getRoleList = async() => {
    console.log("getRoleList method called ...");
    var retState = false;
    var empRoleArr = [];
 
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(
        client.domain + "/hrms/getEmployeeRoles",
        { headers },
        { withCredentials: false }
      );
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getRoleList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        
        if (res.data.detail.data.length > 0) {
          //var empRoleInd = {};
          
          res.data.detail.data.map((empRole) => {
            console.log("Emp role ind data : ", empRole);
            var empRoleInd = {};
            empRoleInd.value = empRole.id;
            empRoleInd.label = empRole.name;
            empRoleArr.push(empRoleInd);
          
          })
          console.log("Emp Role Arr : ", empRoleArr);        
          /*
          setOptionsRole(empRoleArr);
          setEmpRoleIdSelect({
            value: res.data.detail.data[0].id,
            label: res.data.detail.data[0].name,
          });
          */
        }
        retState = true;
      }
      
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    
    }
    return [retState, empRoleArr];
  }
  const getDepartmentList = async() => {
    console.log("getDepartmentList method called ...");
    var retState = false;
    var empDeptArr = [];
   
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + "/hrms/getDepartments", { headers }, { withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getRoleList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        
        if (res.data.detail.data.length > 0) {
          //var empRoleInd = {};
         // var empDeptArr = [];
          res.data.detail.data.map((empDept) => {
            console.log("Dept role ind data : ", empDept);
            var empDeptInd = {};
            empDeptInd.value = empDept.id;
            empDeptInd.label = empDept.name;
            empDeptArr.push(empDeptInd);
          })
          console.log("Emp Dept Arr : ", empDeptArr);  
          /*      
          setOptionsDepartment(empDeptArr);
          setEmpDeptIdSelect({
            value: res.data.detail.data[0].id,
            label: res.data.detail.data[0].name,
          });
          */
        }
        retState = true;
      }
      
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
    return [retState, empDeptArr];
  }

  
  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::addUpdateService called ##########################"
      );
      if (state === null) {
        navigate("/listEmployees");
      } 
      else {
        console.log("PASSED STATE VALUE IN Employee Add/Update page : ", state);
        if (state.action === "add") {
          setIsReadOnly(true);
        }
        

        ( async() => {
          //await getDistrictListWrapper();
          var stateIdTmp = 1;
          if ( state.action === 'view' || state.action === 'edit') {
            stateIdTmp = state.passed_element.state_id;
          }
          var [retStateDist, distArrRet] = await getDistrictList(stateIdTmp);
          if ( retStateDist) {
            setOptDist(distArrRet);
            if ( state.action === 'add') {
            setDistrictIdSelect(distArrRet[0]);
            }
            else {
              var findDist = distArrRet.find(element => element.value === state.passed_element.district_id);
              if ( findDist !== undefined ) {
                console.log("++++++++++++++++++------------------- FOUND DISTRICT : ", findDist);
                setDistrictIdSelect({value: state.passed_element.district_id, label: findDist.label});
              }
            }
          }

          var country_id = appGlobal.COUNTRY_ID;
          var [retStateStList, stateArrRet] = await getStateList(country_id);
          if ( retStateStList ) {
            setOptionsState(stateArrRet);
            if ( state.action === 'add' ) {
              setStateIdSelect(stateArrRet[0]);
            }
            else {
              var findState = stateArrRet.find(element => element.value === state.passed_element.state_id);
              if ( findState ) {
                console.log("STATE FOUND OBJECT : ", findState);
                setStateIdSelect({value: state.passed_element.state_id, label: findState.label});
              }
          }
          }

          var [retStateEmpRole, empRoleArr] = await getRoleList();
          if ( retStateEmpRole ) {
            setOptionsRole(empRoleArr);
            if ( state.action === 'add' ) {
              setEmpRoleIdSelect(empRoleArr[0]);
            }
            else {
              var findEmpRole = empRoleArr.find(element => element.value === state.passed_element.employee_role_id);
              if ( findEmpRole !== undefined ) {
                console.log("++++++++++++++++++------------------- FOUND ROLE : ", findEmpRole);
                setEmpRoleIdSelect({value: state.passed_element.employee_role_id, label: findEmpRole.label});
              }
            }
          }
          
          var [retStateEmpDept, empDeptArr] = await getDepartmentList();
          if ( retStateEmpDept ) {
            setOptionsDepartment(empDeptArr);
            if ( state.action === 'add' ) {
              setEmpDeptIdSelect(empDeptArr[0]);
            }
            else {
              var findEmpDept = empDeptArr.find(element => element.value === state.passed_element.employee_dept_id);
              if ( findEmpDept !== undefined ) {
                console.log("++++++++++++++++++------------------- FOUND DEPARTMENT : ", findEmpDept);
                setEmpDeptIdSelect({value: state.passed_element.employee_dept_id, label: findEmpDept.label});
              }
            }
          }
         setToRender(true); 
        })();
        
        //getDeptList();
        //setFormValues(state.passed_element);
        //setFormValues({ ...formValues, ["date_of_birth"]:formatDate(new Date(state.passed_element.date_of_birth))})
        for (const [key, value] of Object.entries(state.passed_element)) {
          console.log(`${key} : ${value}`);
          formValues[key] = value;
          if ( key === 'date_of_birth' || key === 'date_of_joining') {
            formValues[key] = formatDate(new Date(value));
          }
        }
        console.log("newObj :", formValues);
        if (state.action === "add") {
          setIsReadOnly(false);
        }
      }
      setRefresh(true);
      initialized.current = true;
    }
  }, []);
  const callUpdateApi = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var apiToCall = "";
      if (state.action === "add") {
        apiToCall = "/hrms/addEmployee";
      } else if (state.action === "view") {
        apiToCall = "/hrms/updateEmployee";
      } else {
        apiToCall = "/hrms/addEmployee";
      }
      var reqParam = {
        id: formValues["id"],
        "department_id": empDeptIdSelect.value,
        "employee_dept": formValues["employee_dept"],
        "role_id": empRoleIdSelect.value,
        "employee_role": formValues["employee_role"],
        "employee_no": formValues[ "employee_no"],
        "employee_name": formValues["employee_name"] ,
        "first_name": formValues["first_name"],
        "last_name": formValues["last_name"] || "",
        "designation": formValues["designation"] || "",
        "date_of_birth": formValues["date_of_birth"],
        "date_of_joining": formValues["date_of_joining"],
        "date_of_leaving": formValues["date_of_leaving"] || "",
        "contact_no_primary": formValues["contact_no_primary"],
        "contact_no_secondary": formValues["contact_no_secondary"] || "",
        "email": formValues[ "email"] || "",
        "address_line1": formValues["address_line1"] || "",
        "address_line2": formValues["address_line2"],
        "district_id":districtIdSelect.value, 
        "state_id": stateIdSelect.value,
        "pincode": formValues["pincode"],
        "emgcontact_person": formValues["emg_contact_person"] || "",
        "emgcontact_person_No": formValues["emg_contact_person_no"] || "",
        "emgcontact_person_relation": formValues["emg_contact_person_relation"] || "",
      }
     
      var res = await axios.post(client.domain + apiToCall, reqParam, { headers },{ withCredentials: false });
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        setShowMsg(res.data.detail.data.message);
        setShowMsgTitle("Success");
        setShowDialog(true);
        setSubmitAction(false); 
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          
        }
      }
      setSubmitAction(false);
    }
  };
  const handlePostSaveOk = () => {
    console.log("user confirmed to employee added ...");
    setShowDialog(false);
    navigate("/listEmployees");
  };
  const handleSave = async (e) => {
    e.preventDefault();
    console.log("Handle Click save called : ", e);
    console.log("Formvalues : ", formValues);

    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    if (validateErr === false) {
      setSubmitAction(true);
      callUpdateApi();
    }
  };

  console.log("formvalues FULL : ", formValues);
  const onChangeDatePicker = (e, id) => {
    //setRefresh(false);
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);

    //formValues[e.target.name] = e.target.value;
    //setRefresh(true);
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
    //setFormValues({ ...formValues, ["date_of_joining"]: e.target.value });
  };


  return (
    <>
      <Container>
        <SidebarMain />
        { toRender ?
        (
        <Form>
          <Row>
          <Col xs={4} md={4} sm={4} lg={4}>
              <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>Employee</span>
              { (state.action === 'view' || state.action === 'edit') &&
              <>
             
            <Button variant={state.passed_element.is_active ? "outline-success" : "outline-danger"} size="sm"><span style={{cursor: "default", fontWeight: "500"}}>{state.passed_element.status}</span></Button>
            </>
            }
              &nbsp;&nbsp;
           {/* <Button variant={state.passed_element.is_active ? "outline-success" : "outline-danger"} size="sm"><span style={{cursor: "default", fontWeight: "500"}}>{state.passed_element.status}</span></Button>*/}
            </Col>
            
            <Col
              xs={8}
              md={8}
              sm={8}
              lg={8}
              className="float-end justify-content-end"
            >
              <div className="d-flex justify-content-end">
                <Button variant="warning" className="btn btn-warning btn-md"  onClick={(e) => handleBack(e)}>
                  <BsArrowLeftCircle
                    key={`back-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                  
                    name={`back-icon-patient-name`}
                    id={`edit-icon-patient-id`}
                  />
                </Button>
                {(state.action === "edit" || state.action === "view") && (
              <>
                &nbsp;&nbsp;
                <Button variant="primary" className="btn btn-primary btn-md" onClick={(e) => handleEdit(e)}>
                  <BsPencilSquare
                    key={`edit-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                   
                    name={`edit-icon-paBiSolidEditAlttient-name`}
                    id={`edit-icon-doctor-id`}
                  />
                </Button>
                </>
                )}
              </div>
            </Col>
          </Row>
          <hr align="center" />
          <div className="ui-divider"></div>
          <div className="ui-form">
            <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3" controlId="employeeform.first_name">
                    <Form.Label  className="field required">First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="first_name"
                    placeholder="First Name"
                    value={formValues.first_name}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.first_name}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3" controlId="employeeform.last_name">
                    <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="last_name"
                    placeholder="Last Name"
                    value={formValues.last_name}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.last_name}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3" controlId="employeeform.employee_no">
                    <Form.Label className="field required">Employee Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="employee_no"
                    placeholder="Employee Number"
                    value={formValues.employee_no}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                  
                </Form.Group>
                <p className="error-msg">{formErrors.employee_no}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
              <Form.Group
                    as={Row}
                    className="mb-1"
                  >
                    <Form.Label className="field required">Date Of Birth</Form.Label>
                    <Form.Control
                      type="date"
                      id="date_of_birth"
                      name="date_of_birth"
                      placeholder="Date Of Birth"
                      value={formValues.date_of_birth}
                      onChange={onChangeDatePicker}
                      readOnly={isReadOnly}
                      style={{
                        border: !isReadOnly && "1px solid gray",
                      }}
                    />
                  </Form.Group>
                <p className="error-msg">{formErrors.date_of_birth}</p>
              </Col>
            </Row>
            <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3" controlId="employeeform.employee_role">
                  <Form.Label className="field required">Role</Form.Label>
                  <Select
                    id="employee_role"
                    name="employee_role"
                    value={{
                      value: empRoleIdSelect.value,
                      label: empRoleIdSelect.label,
                    }}
                    options={optionsRole}
                    defaultValue={{
                      value: empRoleIdSelect.value,
                      label: empRoleIdSelect.label,
                    }}
                    onChange={handleRoleSelect}
                    isDisabled={isReadOnly}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.employee_role}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3" controlId="employeeform.employee_dept">
                  <Form.Label className="field required">Department</Form.Label>
                  <Select
                    id="employee_department"
                    name="employee_department"
                    value={{
                      value: empDeptIdSelect.value,
                      label: empDeptIdSelect.label,
                    }}
                    options={optionsDepartment}
                    defaultValue={{
                      value: empDeptIdSelect.value,
                      label: empDeptIdSelect.label,
                    }}
                    onChange={handleDepartmentSelect}
                    isDisabled={isReadOnly}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.employee_department}</p>
              </Col>
              {/*
              <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3" controlId="employeeform.employmenttype">
                  <Form.Label>Employment Type</Form.Label>
                  <Select
                    id="employmenttype"
                    name="employmenttype"
                    value={{
                      value: employmenttypeIdSelect.value,
                      label: employmenttypeIdSelect.label,
                    }}
                    options={optionsEmploymentType}
                    defaultValue={{
                      value: employmenttypeIdSelect.value,
                      label: employmenttypeIdSelect.label,
                    }}
                    onChange={handleEmploymentTypeSelect}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.employmenttype}</p>
              </Col>
                  */}
                   <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3" controlId="employeeform.designation">
                    <Form.Label >Designation</Form.Label>
                  <Form.Control
                    type="text"
                    name="designation"
                    placeholder="Designation"
                    value={formValues.designation}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                  
                </Form.Group>
                <p className="error-msg">{formErrors.designation}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
              <Form.Group
                    as={Row}
                    className="mb-1"
                  >
                    <Form.Label  className="field required">Date Of Joining</Form.Label>
                    <Form.Control
                      type="date"
                      id="date_of_joining"
                      name="date_of_joining"
                      placeholder="Date Of joining"
                      value={formValues.date_of_joining}
                      onChange={onChangeDatePicker}
                      readOnly={isReadOnly}
                      style={{
                        border: !isReadOnly && "1px solid gray",
                      }}
                    />
                  </Form.Group>
                <p className="error-msg">{formErrors.date_of_joining}</p>
              </Col>
            </Row>
           
            <Row>
              <Col xs={2} md={2} sm={2} lg={2}>
                <h4>Contact Details</h4>
              </Col>
            
            <hr align="center" />
            </Row>
            <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3" controlId="employeeform.contact_no_primary">
                  <Form.Label  className="field required">Primary Number</Form.Label>
                   <Form.Control
                    type="text"
                    name="contact_no_primary"
                    placeholder="Primary Number"
                    value={formValues.contact_no_primary}
                    onChange={handleValueChange}
                    maxLength={10}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                   
                </Form.Group>
                <p className="error-msg">{formErrors.contact_no_primary}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3" controlId="employeeform.contact_no_secondary">
                  <Form.Label>Secondary Number</Form.Label>
                   <Form.Control
                    type="text"
                    name="contact_no_secondary"
                    placeholder="Secondary Number"
                    value={formValues.contact_no_secondary}
                    onChange={handleValueChange}
                    maxLength={10}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.contact_no_secondary}</p>
              </Col>
            </Row>
            <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3" controlId="employeeform.address_line1">
                  <Form.Label>Street / Location</Form.Label>
                  <Form.Control
                    type="text"
                    name="address_line1"
                    placeholder="Building No / Street"
                    value={formValues.address_line1}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.address_line1}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3" controlId="employeeform.address_line2">
                  <Form.Label className="field required">City / Town</Form.Label>
                  <Form.Control
                    type="text"
                    name="address_line2"
                    placeholder="City / Town"
                    value={formValues.address_line2}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.address_line2}</p>
              </Col>
              <Col xs={2} md={2} sm={2} lg={2}>
                  <Form.Group className="mb-3" controlId="employeeform.address2">
                  <Form.Label>District</Form.Label>
                   <Select
                   id="district"
                   name="district"
                   value={{
                     value: districtIdSelect.value,
                     label:districtIdSelect.label,
                   }}
                   options={optDist}
                   defaultValue={{
                     value: districtIdSelect.value,
                     label: districtIdSelect.label,
                   }}
                   onChange={handleDistrictSelect}
                   isDisabled={isReadOnly}
                 />
               </Form.Group>
                <p className="error-msg">{formErrors.district}</p>
              </Col>
             
              <Col xs={2} md={2} sm={2} lg={2}>
                  <Form.Group className="mb-3" controlId="employeeform.state">
                  <Form.Label>State</Form.Label>
                   <Select
                   id="state"
                   name="state"
                   value={{
                     value: stateIdSelect.value,
                     label:stateIdSelect.label,
                   }}
                   options={optionsState}
                   defaultValue={{
                     value: stateIdSelect.value,
                     label: stateIdSelect.label,
                   }}
                   onChange={handleStateSelect}
                   isDisabled={isReadOnly}
                 />
               </Form.Group>
                <p className="error-msg">{formErrors.state}</p>
              </Col>
               <Col xs={2} md={2} sm={2} lg={2}>
                  <Form.Group className="mb-3" controlId="employeeform.pincode">
                  <Form.Label  className="field required">Pincode</Form.Label>
                   <Form.Control
                    type="text"
                    name="pincode"
                    placeholder="Pincode"
                    value={formValues.pincode}
                    onChange={handleValueChange}
                    maxLength={6}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.pincode}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} sm={12} lg={12}>
                <h4>Emergency Contact</h4>
              </Col>
              <hr align="center" />
            </Row>
           
            <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3" controlId="employeeform.emg_contact_person">
                  <Form.Label>Contact person</Form.Label>
                   <Form.Control
                    type="text"
                    name="emg_contact_person"
                    placeholder="Contact Person"
                    value={formValues.emg_contact_person}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.emg_contact_person}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3" controlId="employeeform.emg_contact_person_no">
                  <Form.Label>Contact person Number</Form.Label>
                   <Form.Control
                    type="text"
                    name="emg_contact_person_no"
                    placeholder="Contact Person Number"
                    value={formValues.emg_contact_person_no}
                    onChange={handleValueChange}
                    maxLength={10}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.emg_contact_person_no}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3" controlId="employeeform.emg_contact_person_relation">
                  <Form.Label>Relation To Employee</Form.Label>
                   <Form.Control
                    type="text"
                    name="emg_contact_person_relation"
                    placeholder="Realtion to Employee"
                    value={formValues.emg_contact_person_relation}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.emg_contact_person_relation}</p>
              </Col>
            </Row>
            {/*
            <Row>
              <Col xs={2} md={2} sm={2} lg={2}>
                <h2>Exit Details</h2>
              </Col>
            </Row>
            <Row>
              <Col xs={2} md={2} sm={2} lg={2}>
                <label>Relieving Date:</label>
              </Col>
              <Col xs={2} md={2} sm={2} lg={2}>
                <DateTimePicker
                  onChange={onChangeDatePicker}
                  calendarIcon={null}
                  format="dd-MM-y HH:mm:ss"
                  value={formValues.relievingDate}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={2} md={2} sm={2}>
                <div className="field">
                  <label>Relieving Note :</label>
                </div>
              </Col>
              <Col xs={4} md={4} sm={4}>
                <div className="field">
                  <input
                    type="text"
                    name="relievingnote"
                    placeholder="RelievingNote"
                    value={formValues.relevingnote}
                    onChange={handleValueChange}
                  />
                </div>
                <p className="error-msg">{formErrors.relievingnote}</p>
              </Col>
            </Row>
                  */}
            {!isReadOnly && (
            <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ disply: "flex", justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleReset(e)}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      type="submit"
                      id="save"
                      onClick={(e) => handleSave(e)}
                      disabled={submitAction ? true: false}
                    >
                       {submitAction && 
                      <>
                      <Spinner
                         as="span"
                         animation="border"
                         size="sm"
                         role="status"
                         aria-hidden="true"
                         />
                        <span className="visually-hidden">Loading...</span>
                       </> 
                       } 
                     Save
                    </Button>
                  </div>
                </Col>
              </Row>
               )}
              <Modal
              show={showDialog}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>{showMsgTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{showMsg}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handlePostSaveOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </Form>
         )
         :
         <div className="container-spinner">
         <div className="vertical-center-spinner">
          {/*<Button variant="primary" disabled>*/}
          <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
             <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
                 <span className="visually-hidden">Loading...</span>
               </Spinner>
    
          {/*</Button>*/}
          </div>
          </div>
       }
      </Container>
    </>
  );
};

export default AddUpdateEmployee;
