import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Form, Button, View, Image, Spinner } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
import Sidebar2 from "./Sidebar2";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
//import ListGroup from "react-bootstrap/ListGroup";
//import Badge from "react-bootstrap/Badge";
//import DateTimeField from "react-bootstrap-datetimepicker"
//import DateTimePicker from "react-datetime-picker";

import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client } from "../config/Config";
import axios from "axios";

import { useLocation } from "react-router-dom";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import { BsFillPenFill, BsFillTrashFill } from "react-icons/bs";

import DateTimePicker from "react-datetime-picker";
import SidebarMain from "./SidebarMain";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';

import MenuEms from "./MenuEms";

const AddUpdateLeave = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  //console.log("PASSED STATE VALUE IN Patient Add/Update page : ", state);

  if (state === null) {
    navigate("/");
  }

  let initialItems = [];

  const optionsEmployee = [
    { value: 1, label: "Abi" },
    { value: 2, label: "John" },
    { value: 3, label: "Ram" },
  ];
  const optionsLeaveType = [
    { value: 1, label: "Fullday" },
    { value: 2, label: "OffDay" },
    { value: 3, label: "Offday" },
  ];

  const intialValues = {
    employee: "",
    leavetype: "",
    leaveFrom: new Date(),
    leaveTo: new Date(),
    reason: "",
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [validateError, setValidateError] = useState(false);

  const [employeeIdSelect, setEmployeeIdSelect] = useState({});
  const [leavetypeIdSelect, setLeavetypeIdSelect] = useState({});
  const [toRender, setToRender] = useState(false);

  var validateErr = false;

  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);

  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::addUpdateLeave called ##########################"
      );
      console.log("PASSED STATE VALUE IN Service Add/Update page : ", state);
      setToRender(true);
      initialized.current = true;
    }
  }, []);

  const handleValueChange = async (e, data) => {
    e.preventDefault();
    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    setFormValues({ ...formValues, [name]: value });

    //console.log("Clicked data : ", data);
  };

  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!values.employeeName) {
      errors.userName = "Employee name is required";
      validateErr = true;
      setValidateError(true);
    }

    console.log("BEFORE RETURN validate errors ...", validateError);
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };

  const handleEmployeeSelect = (e, data) => {
    console.log("handleEmployeeSelect called ...", e);
    setEmployeeIdSelect(e);

    var EmployeeIdInput = e.value;
    var EmployeeNameInput = e.label;
    //getDistrictList(stateIdInput, stateNameInput);
  };
  const handleLeavetypeSelect = (e, data) => {
    console.log("handleEmployeeSelect called ...", e);
    setEmployeeIdSelect(e);

    var LeavetypeIdInput = e.value;
    var LeavetypeNameInput = e.label;
    //getDistrictList(stateIdInput, stateNameInput);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit called ...");
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& : ",
      validateError
    );
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ",
      validateErr
    );
    if (validateErr === false) {
      console.log("FORM VALUES at handleSubmit: ", formValues);
      console.log("LINE ITEMS : ", items);
      console.log("Form submit called ...");

      console.log("Employee Name : ", formValues["name"]);
    }
  };
  const toSqlDatetime = (inputDate) => {
    const date = new Date(inputDate);
    const dateWithOffest = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    return dateWithOffest.toISOString().slice(0, 19).replace("T", " ");
  };

  const onChangeDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    var formattedDt = toSqlDatetime(e);

    setFormValues({ ...formValues, ["leave from"]: formattedDt });

    console.log("Formatted str Leave From : ", formattedDt);
  };

  const handleEdit = async (e) => {
    console.log("Handle Edit  called : ", e);
  };

  const handleDelete = async (e) => {
    console.log("Handle delete  called : ", e);
  };

  const handleSave = async (e) => {
    console.log("Handle Click save called : ", e);
  };
  const handleCancel = async (e) => {
    console.log("Handle click Cancel called : ", e);
  };

  return (
    <>
      <Container>
        <SidebarMain />
        { toRender ?
        (
        <Form>
          <Row>
            <Col xs={2} md={2} sm={2} lg={2}>
              <h1>Leave</h1>
            </Col>
            <Col xs={1} md={1} sm={1} lg={1}>
              <BsFillPenFill
                key={`edit-btn-user-key`}
                size={24}
                style={{ color: "#0D6EFD", cursor: "pointer" }}
                onClick={(e) => handleEdit(e)}
                name={`edit-icon-paBiSolidEditAlttient-name`}
                id={`edit-icon-user-id`}
              />
            </Col>

            <Col xs={1} md={1} sm={1} lg={1}>
              <BsFillTrashFill
                key={`delete-btn-user-key`}
                size={24}
                style={{ color: "#0D6EFD", cursor: "pointer" }}
                onClick={(e) => handleDelete(e)}
                name={`delete-icon-user-name`}
                id={`delete-icon-user-id`}
              />
            </Col>
          </Row>
          <br></br>
          <div className="ui-divider"></div>
          <div className="ui-form">
            <Row>
              <Col xs={2} md={2} sm={2} lg={2}>
                <div className="field">
                  <label>Employee:</label>
                </div>
              </Col>

              <Col xs={4} md={4} sm={4} lg={4}>
                <div className="field col-6">
                  <Select
                    id="employee"
                    name="employee"
                    value={{
                      value: employeeIdSelect.value,
                      label: employeeIdSelect.label,
                    }}
                    options={optionsEmployee}
                    defaultValue={{
                      value: employeeIdSelect.value,
                      label: employeeIdSelect.label,
                    }}
                    onChange={handleEmployeeSelect}
                  />
                </div>
                <p className="error-msg">{formErrors.employee}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={2} md={2} sm={2} lg={2}>
                <div className="field">
                  <label>Leave Type:</label>
                </div>
              </Col>

              <Col xs={4} md={4} sm={4} lg={4}>
                <div className="field col-6">
                  <Select
                    id="leavetype"
                    name="leavetype"
                    value={{
                      value: leavetypeIdSelect.value,
                      label: leavetypeIdSelect.label,
                    }}
                    options={optionsLeaveType}
                    defaultValue={{
                      value: leavetypeIdSelect.value,
                      label: leavetypeIdSelect.label,
                    }}
                    onChange={handleLeavetypeSelect}
                  />
                </div>
                <p className="error-msg">{formErrors.leavetype}</p>
              </Col>
            </Row>

            <Row>
              <Col xs={2} md={2} sm={2}>
                <div className="field">
                  <label>Leave from :</label>
                </div>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <DateTimePicker
                  onChange={onChangeDatePicker}
                  calendarIcon={null}
                  format="dd-MM-y HH:mm:ss"
                  value={formValues.leaveFrom}
                />
              </Col>

              <Col xs={2} md={2} sm={2}>
                <div className="field">
                  <label>Leave To :</label>
                </div>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <DateTimePicker
                  onChange={onChangeDatePicker}
                  calendarIcon={null}
                  format="dd-MM-y HH:mm:ss"
                  value={formValues.leaveTo}
                />
                <p></p>
              </Col>
            </Row>

            <Row>
              <Col xs={2} md={2} sm={2} lg={2}>
                <div className="field">
                  <label>Reason: </label>
                </div>
              </Col>
              <Col xs={8} md={8} sm={8} lg={8}>
                <div className="field">
                  <input
                    type="text"
                    name="reason"
                    placeholder="Reason"
                    value={formValues.reason}
                    onChange={handleValueChange}
                  />
                </div>
                <p className="error-msg">{formErrors.reason}</p>
              </Col>
            </Row>

            <Row className="flex float-end">
              <Col xs={2} md={2} sm={2} lg={2}>
                <Button id="save" onClick={(e) => handleSave(e)}>
                  Save
                </Button>
              </Col>
              {/*
              <Col xs={2} md={2} sm={2} lg={2}>
                <Button id="cancel" onClick={(e) => handleClickCancel(e)}>
                  Cancel
                </Button>
              </Col>
            */}
            </Row>
          </div>
        </Form>
        )
        :
        <div className="container-spinner">
        <div className="vertical-center-spinner">
         {/*<Button variant="primary" disabled>*/}
         <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
            <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{ width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
                <span className="visually-hidden">Loading...</span>
              </Spinner>
   
         {/*</Button>*/}
         </div>
         </div>
      }
      </Container>
    </>
  );
};

export default AddUpdateLeave;
