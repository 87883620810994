import { Button } from "react-bootstrap";
import { CSVLink, CSVDownload } from "react-csv";
import { TbReportAnalytics } from "react-icons/tb";
import invoice from "../data/invoice";

const CsvGenerate = (props) => {
  console.log("CsvGenerate Loaded ...", props.dataPass);

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("_");


  }

  const formateTime = (currTime) => {
    var h = (currTime.getHours() < 10 ? "0" : "") + currTime.getHours();
    var m = (currTime.getMinutes() < 10 ? "0" : "") + currTime.getMinutes();
    var s = (currTime.getSeconds() < 10 ? "0" : "") + currTime.getSeconds();
    var ms =  currTime.getMilliseconds();
    var hrMin = h + "_" + m + "_" + s + "_" + ms;
    return hrMin;
  };

  /*
  var endTimeTmp = new Date();
  const dateCopy = new Date(endTimeTmp.getTime());
  var minForFiiling = 10;
  var endTimeMod = dateCopy.setMinutes(dateCopy.getMinutes() + minForFiiling);
  console.log("endTimeMod: ", endTimeMod);
  var endTimeMod2 = new Date(endTimeMod);
  console.log("endTimeMod2: ", endTimeMod2);
  var custInvoice = new Date(endTimeMod) ;
  */
  var currTimeTmp = new Date();
  var date_str = formatDate(currTimeTmp);
  console.log("***************DATE:", date_str);
  var time_str = formateTime(currTimeTmp);
  console.log("***************TIME:", time_str);

  var filename_gen = date_str + "_" + time_str;
  return(
  <CSVLink 
  data={props.dataPass}
  filename={filename_gen}
  //className="btn btn-primary"
  >
    <Button
      variant="danger">
        <TbReportAnalytics style={{width: '21px', height: '21px', paddingTop: '-8px'}}/>
      Export Excel
    </Button>
    </CSVLink>  
  );
  }

  export default CsvGenerate;
