import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Table,
  Spinner,
  Image
} from "react-bootstrap";
import { withRouter } from "react-router";
//import Sidebar from './Sidebar';
import Sidebar2 from "./Sidebar2";
import Footer from "./Footer";
//import Topmenu from "./Topmenu";
import OrderDetails from "./OrderDetails";
import "../Dashboard.css";

//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";

import { useNavigate } from "react-router-dom";

import * as Icon from "react-bootstrap-icons";
import axios from "axios";
import { client, OS_STMT_FOR_NDAYS } from "../config/Config";
import Select from "react-select";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';



import { BsFillTrashFill, BsPencilSquare, BsFileRuled, BsArrowLeftCircle, BsCardText, BsFillPrinterFill} from "react-icons/bs";

import { AiOutlineOrderedList } from "react-icons/ai";


import MenuOms from "./MenuOms";

import rmcBlueIcon from "../assets/images/ready_mix_blue.png";
import SidebarMain from "./SidebarMain";

import {getDistrictList, getStateList, getProductApiCall, getProductBuy, getProductsSell, searchAllUniqLocations, formatDate, formatDateIndian } from "./utils";

const ListCustOsStmt = (props) => {
  const initialized = useRef(false);
  let stmtArr = [
    {
      stmt_no: "101",
      os_date: "12-03-2023",
      customer_name: "vimal",
      total_volume: "300",
      total_amount: "4000",
      previous_balance: "2000",
      received_amount: "1000",
      current_balance: "5000",
      total_balance: "10000"
    },
    {
      stmt_no: "102",
      os_date: "04-12-2023",
      customer_name: "haritha",
      total_volume: "500",
      total_amount: "8000",
      previous_balance: "1000",
      received_amount: "1500",
      current_balance: "8000",
      total_balance: "15000"
    },
    {
      stmt_no: "103",
      os_date: "18-09-2023",
      customer_name: "harshi",
      total_volume: "600",
      total_amount: "4500",
      previous_balance: "6000",
      received_amount: "900",
      current_balance: "6700",
      total_balance: "16600"
    },
    {
      stmt_no: "104",
      os_date: "15-07-2023",
      customer_name: "jas",
      total_volume: "360",
      total_amount: "7800",
      previous_balance: "4500",
      received_amount: "8900",
      current_balance: "9000",
      total_balance: "18000"
    },
  ];
  
  
  const [isLoaded, setIsLoaded] = useState(false);
  const [stmtList, setStmtList] = useState([]);
  const [dcListFull, setDcListFull] = useState([]);
  //const [orderList, setOrderList] = useState(orderListLoad);
  //const [orderListFull, setOrderListFull] = useState([]);
  //const [orderListFull, setOrderListFull] = useState([]);
  const [orderDcFetched, setOrderDcFetched] = useState(false);
  
  /*
  function padTo2Digits(num) {
    //console.log("<<<<<<<<<<<<<< given number >>>>>>>>>>>>>>>> : ", num);
    var modNum = num.toString().padStart(2, "0");
    console.log("Modified  value : ", modNum);
    return modNum;
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }
  */
 /*
  var today = new Date();
  var priorDate = new Date().setDate(today.getDate() - OS_STMT_FOR_NDAYS);
  console.log("MODIFIED PREVIOUS DATE : ", priorDate);
  */
  const intialValues = {
    start_date: formatDate(new Date(new Date().setDate(new Date().getDate() - OS_STMT_FOR_NDAYS))),
    end_date: formatDate(new Date()),
  }

  

  const [deliveryChellan, setDeliveryChellan] = useState([]);
  const [prodList, setProdList] = useState([]);
  const [toRender, setToRender] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [customerListFull, setCustomerListFull] = useState([]);
  const [customerIdSelect, setCustomerIdSelect] = useState({value: 0, label: "All Customers"});
  const [optCustomer, setOptCustomer] = useState([]);
  const [productList, setProductList] = useState([]);
  const [productListFull, setProductListFull] = useState([]);
  const [productIdSelect, setProductIdSelect] = useState({});
  const [optProduct, setOptProduct] = useState([]);
  const [formValues, setFormValues] = useState(intialValues);
  const [dcList, setDcList] = useState([]);
  


  const [optLocations, setOptLocations] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState({});
  const navigate = useNavigate();

  const handleAdd = (e) => {
    e.preventDefault();
    console.log("Add or Update Order button clicked ...", e);
    navigate(`/addUpdateCustOsStmt`, {
      state: { passed_element: [], action: "add" },
    });
  };
  

  

  const handleEdit = (e, data) => {
    console.log("Handle action edit Clicked : ", e);
    //console.log("Handle Clicked edit DATA : ", data);
    console.log("Handle action edit Clicked target : ", e.target);
    console.log(
      "Handle action edit Clicked target Current : ",
      e.currentTarget
    );
    console.log(
      "Handle action edit Clicked target Current id : ",
      e.currentTarget.id
    );
    console.log("Handle action edit Clicked target ID : ", e.target.id);
    //console.log("Handle action edit Clicked target NAME : ", e.target.name);
    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(
        0,
        clickedTagId.lastIndexOf("-")
      );
      console.log("current selected element name : ", nameOfElement);
      console.log("Current SELECTED id : ", curSelId);
      console.log("CUR SELECTED VALUE : ", stmtList[curSelId]);

      //navigate(`/${client.urlDelimiter}orderDetails`, {state : orderList[curSelId]});
      navigate(`/addUpdateCustOsStmt`, {
        state: { passed_element: stmtList[curSelId], action: "edit" },
      });
    }
  };

  const handleView = async (e) => {
    console.log("handle view called : ", e);

    console.log("Handle action view Clicked target : ", e.target);
    console.log(
      "Handle action view Clicked target Current : ",
      e.currentTarget
    );
    console.log(
      "Handle action view Clicked target Current id : ",
      e.currentTarget.id
    );
    console.log("Handle action view Clicked target ID : ", e.target.id);
    //console.log("Handle action edit Clicked target NAME : ", e.target.name);

    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(
        0,
        clickedTagId.lastIndexOf("-")
      );
      console.log("current selected element name view : ", nameOfElement);
      console.log("Current SELECTED id view : ", curSelId);
      console.log("CUR SELECTED VALUE view : ", stmtList[curSelId]);
      console.log("Before call orderDetails page ...");
      navigate(`/addUpdateCustOsStmt`, {
        state: { passed_element: stmtList[curSelId], action: "view" },
      });
    }
  };

  const actionDeleteHandle = (e, data) => {
    console.log("Handle action delete Clicked : ", e);
    //console.log("Handle Clicked delete DATA : ", data);
  };

  for (var ni = 0; ni < deliveryChellan.length; ni++) {
    console.log("IND DAtA Dashboard : ", deliveryChellan[ni]);
  }
 

 const getCustOsStmtList = async(customer_id, start_date, end_date) => {
  var retState = false;
    var newCustOsStmtArr = [];
    console.log("getCustOsStmtList method called ...");


    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + `/getCustOsStmt?customer_id=${customer_id}&start_date=${start_date}&end_date=${end_date}`, { headers }, { withCredentials: false });
      
      console.log("Response from server getCustOsStmtList : ", res.data.detail.data);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getCustOsStmtList : ", res.data.detail.data);        
        /*
        res.data.detail.data.map((prodItem) => {
          var newSellProd = {
            "value": prodItem.id,
            "label": prodItem.name,
            "id": prodItem.id,
            "name": prodItem.name,
            "description": prodItem.description,
            "hsncode": prodItem.hsncode,
            "uom": prodItem.uom_value,
            "unit_price": prodItem.unit_price,
            "cgst": prodItem.cgst,
            "sgst": prodItem.sgst,
            "igst": prodItem.igst,
            "is_product_cc": prodItem.is_composite
          }
          newSellProdArr.push(newSellProd);
        })
        */
        //setProductList(newBuyProdArr);
        //setStmtList(res.data.detail.data);
        newCustOsStmtArr = res.data.detail.data;
        retState = true;
      }
     
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          
        }
      }
    }
    return [retState, newCustOsStmtArr];
 }
  

  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect called ##########################"
      );
      ( async() => {
        var [retState, customerArr] = await getCustomerListDropdown();
        if ( retState ) {
          setOptCustomer(customerArr);
          setCustomerIdSelect(customerArr[0]);
        }

        var customer_id_tmp = 0;
        var start_date_tmp = new Date();
        var end_date_tmp = new Date();
        customer_id_tmp = customerIdSelect.value;
        start_date_tmp = formValues["start_date"];
        end_date_tmp = formValues["end_date"];
        var [retStateCustOs, newCustOsStmtArr] = await getCustOsStmtList(customer_id_tmp, start_date_tmp, end_date_tmp);
        if ( retStateCustOs ) {
          //var newCustOsStmtArr1 = [{value: 0, label: "All Locations"}, ...newCustOsStmtArr];
          console.log("RETURNED CUSOTMER OS STMT LIST : ", newCustOsStmtArr);
          setStmtList(newCustOsStmtArr);
          setIsLoaded(true);
        }
        setToRender(true);
      })();

      //setProductInfo(product_info);
      //getOrderList();
      
     
      
      initialized.current = true;
    }
  }, []);

  const handleBack = async (e) => {
    console.log("handle back called : ", e);
    navigate(-1);
  };
  const getCustomerListDropdown = async () => {
    console.log("getCustomerListDropdown method called ...");
    var retState = false;
    
  
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + "/crm/getAllCustomers", { headers }, { withCredentials: false });
      
      console.log("Response from server getCustomerList : ", res.data.detail.data);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getCustomerList : ", res.data.detail.data);
        
  
        var customerArr = [];
        var custAll = { value: 0, label: 'All Customers'};
        customerArr.push(custAll);
        res.data.detail.data.map((itemCustomer, idx) => {
          console.log("Ind val : ", itemCustomer);
          var customerInd = {
            value: itemCustomer.id,
            label: itemCustomer.name,
          };
          console.log("In customer modified : ", customerInd);
          customerArr.push(customerInd);
        });

        var keySearch = "order_date";
        
        
  
        console.log("Mod Full VALUE : ", customerArr);
        retState = true;
      }
     
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.data
          );
         
        }
      }
    }
    return [retState, customerArr];
  };
  
 
  const onChangeDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);
    var orderListFilter = []
   /*
    if ( e.value === 0 ) {
      setOrderList(orderListFull);
    }
    else {
      productListFull.map((date) => {
        if ( date.order_date === e.value ) {
          orderListFilter.push(date);
        }
      })
      setOrderList(orderListFilter);
    }
    
    */
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };
 

  const handleDateSearch = async() => {
    console.log("handleDateSearch called ...");
    console.log("++++++++++++ START DATE : %s, END DATE : %s >>>>>>>>>>>>>>>>", formValues["start_date"], formValues["end_date"]);

    var [retStateCustOs, newCustOsStmtArr] = await getCustOsStmtList(customerIdSelect.value, formValues["start_date"], formValues["end_date"]);
        if ( retStateCustOs ) {
          //var newCustOsStmtArr1 = [{value: 0, label: "All Locations"}, ...newCustOsStmtArr];
          console.log("RETURNED CUSOTMER OS STMT LIST : ", newCustOsStmtArr);
          setStmtList(newCustOsStmtArr);
          setIsLoaded(true);
        }
    
    /*
    var dcListFilter = [];
    dcListFull.map((dc) => {
      const d = Date.parse(dc.dc_date);
      if ( d >= start && d <= end ) {
        if ( selectedLocationId.label !== 'All' && customerIdSelect.value !== 0 ) {
          if ( dc.address_line1 === selectedLocationId.label && dc.customer_id === customerIdSelect.value ) {
            dcListFilter.push(dc);
          }
        }
        else if ( selectedLocationId.label === 'All' && customerIdSelect.value !== 0 ) {
          if ( dc.customer_id === customerIdSelect.value ) {
            dcListFilter.push(dc);
          }
        }
        else if ( selectedLocationId.label !== 'All' && customerIdSelect.value === 0 ) {
          if ( dc.address_line1 === selectedLocationId.label ) {
            dcListFilter.push(dc);
          }
        }
        else {
          dcListFilter.push(dc);
        }
      } 
    })
    setStmtList(dcListFilter);
    */

  };
  const handleCustomerSelect = (e, data) => {
    console.log("handleCustomerSelect called ...", e);

    /*
    var dcListFilter = []

    if ( e.value === 0 ) {
      //setOrderList(orderListFull);
      setDcList(dcListFull);
      setSelectedLocationId({value: "All", label: "All"});
    }
    else {
      dcListFull.map((dc) => {
        if ( selectedLocationId.label !== 'All' ) {
          if ( dc.customer_id === e.value && dc.address_line1 === selectedLocationId.label) {
            dcListFilter.push(dc);
          }
        }
        else {
          if ( dc.customer_id === e.value ) {
            dcListFilter.push(dc);
          }
        }
      })
      setStmtList(dcListFilter);
    }
    */
    setCustomerIdSelect(e);
  };

  const handlePrintCustOsStmtList = async(e) => {
    console.log("handlePrintDCDirect called : ", e);
    console.log("handlePrintDCDirect called : ", e.currentTarget);
    console.log("handlePrintDCDirect called ID : ", e.currentTarget.id);
    console.log("handlePrintDCDirect called NAME : ", e.currentTarget.name);

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(
      urlStr.lastIndexOf(client.urlDelimiter) + 1
    );
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);

    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printCustomerStmt?customer_id=${customerIdSelect.value}&start_date=${formValues["start_date"]}&end_date=${formValues["end_date"]}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0,0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);

  }

 
  return (
    <Container>
     <SidebarMain />
     { toRender ?  
      <form>
     
        <Row>
        
        <Col xs={3} md={3} sm={3} lg={3}> 
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.25rem" }}>Cust Outstanding Statements</span>
        </Col>
        <Col xs={2} md={2} sm={2} lg={2}>
              <Row>
              <Col>
              <Select
                    inline
                    id="customer"
                    name="customer"
                    value={{
                      value: customerIdSelect.value,
                      label: customerIdSelect.label,
                    }}
                    options={optCustomer}
                    defaultValue={{
                      value: customerIdSelect.value,
                      label: customerIdSelect.label,
                    }}
                    onChange={handleCustomerSelect}
                    //isDisabled={isReadOnly}
                  />
                  </Col>
                  </Row>    
            </Col>
           
            <Col xs={5} md={5} sm={5} lg={5}> 
            
            <Row>
             
            <Col>
                <Form.Control
                  type="date"
                  name="start_date"
                  placeholder="Start Date"
                  value={formValues.start_date}
                  onChange={onChangeDatePicker}
                  //readOnly={isReadOnly}
                  
                />
              
            </Col>
          
            <Col>
                <Form.Control
                  type="date"
                  name="end_date"
                  placeholder="End Date"
                  value={formValues.end_date}
                  onChange={onChangeDatePicker}
                  //readOnly={isReadOnly}
                  
                />
                 </Col>

                 <Col >
            <Button id="search-order-id"
            size={"sm"}
            variant="success"
            style={{paddingBottom:"8px"}}
            onClick={(e) => handleDateSearch(e)}>
              Search
            </Button>
             </Col>

                 </Row>
                 </Col>        

          <Col
            xs={2}
            md={2}
            sm={2}
            lg={2}
            className="d-flex justify-content-end float-end"
          >
            <Button id="direct-dc-print" name = "direct-dc-print"  onClick={(e)  => handlePrintCustOsStmtList(e)}>
                <BsFillPrinterFill
                  size={18}
                  style={{ color: "white", cursor: "pointer" }}
                  name={`print-direct-dc-icon`}
                  id={`print-direct-dc-icon`}
                />
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button
             variant="warning"
            id="add-btn-id"
            name="add-btn-name"
            className=" float-end"
            onClick={(e) => handleAdd(e)}
            >
              New OS Stmt
            </Button> 
          </Col>

         
        </Row>
        <hr align="center" />
        

       { isLoaded && 
        <Table striped bordered hover size="sm">
          <thead>
            <tr className="table-primary">
              <th className="table-row-heading" key={"order-list-heading-key"}>
                S.No
              </th>
              {/*<th className="table-row-heading">Stmt No</th>*/}
              <th className="table-row-heading" style={{textAlign: "center"}}>Date</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Customer</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Location</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Contact Number</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Old Balance</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Current Balance</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Advance</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Total Balance</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Details</th>
            </tr>
          </thead>

          <tbody>
            {stmtList.map((stmtInd, idx) => (
              <tr key={`stmt-table-row-key-${idx}`}>
                <td style={{textAlign: "right"}}
                  key={`stmt-table-row-key-sno-${idx}`}
                  id={`stmt-table-row-id-sno-${idx}`}
                  name={`stmt-table-row-name-sno-${idx}`}
                >
                  {idx + 1}
                </td>
                {/*
                <td
                  key={`stmt-table-row-key-stmtno-${idx}`}
                  id={`stmt-table-row-id-stmtno-${idx}`}
                  name={`stmt-table-row-name-stmtno-${idx}`}
                >
                  {stmtInd.id}
                </td>
                */}
                <td style={{textAlign: "left"}}
                  key={`stmt-table-row-key-date-${idx}`}
                  id={`stmt-table-row-id-date-${idx}`}
                  name={`stmt-table-row-name-date-${idx}`}
                >
                  {formatDateIndian(new Date(stmtInd.stmt_date))}
                </td>
                <td style={{textAlign: "left"}}
                  key={`stmt-table-row-key-customer-${idx}`}
                  id={`stmt-table-row-id-customer-${idx}`}
                  name={`stmt-table-row-name-customer-${idx}`}
                >
                  {stmtInd.customer_name}
                </td>
                
                <td 
                  style={{ padding: "5px", textAlign: "left" }}
                  key={`stmt-table-row-key-location${idx}`}
                  id={`stmt-table-row-id-location-${idx}`}
                  name={`stmt-table-row-name-location-${idx}`}
                >
                  {stmtInd.address1}
                </td>
                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`stmt-table-row-key-contact_number-${idx}`}
                  id={`stmt-table-row-id-contact_number-${idx}`}
                  name={`stmt-table-row-name-contact_number-${idx}`}
                >
                  {stmtInd.contact_number}
                </td>
                
                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`stmt-table-row-key-previous_balance-${idx}`}
                  id={`stmt-table-row-id-previous_balance-${idx}`}
                  name={`stmt-table-row-name-previous_balance-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(stmtInd.previous_balance).toFixed(2)))}
                </td>
                
                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`stmt-table-row-key-current_balance-${idx}`}
                  id={`stmt-table-row-id-current_balance-${idx}`}
                  name={`stmt-table-row-name-current_balance-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(stmtInd.current_balance).toFixed(2)))}
                </td>

                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`stmt-table-row-key-received_amount-${idx}`}
                  id={`stmt-table-row-id-received_amount-${idx}`}
                  name={`stmt-table-row-name-received_amount-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(stmtInd.total_received).toFixed(2)))}
                </td>
                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`stmt-table-row-key-total_balance-${idx}`}
                  id={`stmt-table-row-id-total_balance-${idx}`}
                  name={`stmt-table-row-name-total_balance-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(stmtInd.total_balance).toFixed(2)))}
                </td>
               
                
                
                
                <td style={{textAlign: "center"}}
                  key={`stmt-table-row-key-actions-${idx}`}
                  id={`stmt-table-row-id-actions-${idx}`}
                  name={`stmt-table-row-name-actions-${idx}`}
                >
                  <BsCardText
                    size={28}
                    style={{ color: "#0d6efd", cursor: "pointer" }}
                    onClick={(e) => handleEdit(e)}
                    name={`stmt-view-icon-${idx}`}
                    id={`stmt-view-icon-${idx}`}
                  />
                  &nbsp;&nbsp; 
                </td>
              </tr>
            ))}
          </tbody>
        </Table>  
        }  
        </form>
       : 
       <div className="container-spinner">
      <div className="vertical-center-spinner">
       {/*<Button variant="primary" disabled>*/}
       <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{ width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
 
       {/*</Button>*/}
       </div>
       </div>
      }      
       
      
    </Container>
  );
};
//const Dashboard = withRouter(Dash);
export default ListCustOsStmt;
