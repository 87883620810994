import React, { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { Page, Text, View, Document, StyleSheet, PDFViewer,Image } from '@react-pdf/renderer';

import { COMPANY_NAME, client } from '../config/Config';
import logo_left from '../logo_left.png';
import logo_right from '../logo_right.png';

//import StockDocument from "./StockDocument";

const borderColor = '#a8a8a8';
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 9,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    //width: '100%',
    //height: '33%',
    width: '100%',
    height: '100%',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  logo_image2: {
    width: '15%',
    height: '98px',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  business_content: {
    width: '60%',
    height: '98px',
    //paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container2: {
    //paddingBottom:0,
    flexDirection: 'row',
    //borderBottomColor: '#bff0fd',
    //backgroundColor: '#bff0fd',
    //borderBottomWidth: 1,
    //alignItems: 'center',
    //height: 12,
    //textAlign: 'center',
    //fontStyle: 'bold',
    //flexGrow: 1,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 1,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#a8a8a8',
  },
  container: {
    flexDirection: 'row',
    borderBottomColor: '#a8a8a8',
    backgroundColor: '#a8a8a8',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
  },
 
  
});

const PrintFuelPass = (props) => {
  const [reportData, setReportData] = useState({});
  const [toRender, setToRender] = useState(false);

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  const formateTime = (currTime) => {
    var h = (currTime.getHours() < 10 ? "0" : "") + currTime.getHours();
    var m = (currTime.getMinutes() < 10 ? "0" : "") + currTime.getMinutes();
    var hrMin = h + ":" + m;
    return hrMin;
  };

  const formatPrintDate = (currTime) => {
    var dateComponent = [
      padTo2Digits(currTime.getDate()),
      padTo2Digits(currTime.getMonth() + 1),
      currTime.getFullYear(),
    ].join("-");

    var timeComponent = [
      padTo2Digits(currTime.getHours() % 12 || 12 ),
      padTo2Digits(currTime.getMinutes())
    ].join(":");

    const ampm = currTime.getHours() >= 12 ? 'PM' : 'AM';

    return dateComponent + " " + timeComponent + " " + ampm;
  }
  /*
  const getReportList = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
     

      var res = await axios.get(
        client.domain + "/ims/getReportCurrentStock",
        { headers },
        { withCredentials: false }
      );

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getReportList : ", res.data.detail.data);
        setReportData(res.data.detail.data);

        //setShowMsgTitle("Report Info Added Successfully");
        setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        //setShowMsg("Please try after sometime");
        //setShowMsgTitle("Network Issue!");
        //setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg("Please try again ...");
          //setShowMsgTitle("Unable to process your request!");
          //setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          //setShowMsg(error.response.data.detail);
          //setShowMsgTitle("Unable to process your request at this moment!");
          //setShow(true);
        }
      }
    }
  };
  */

  const getReportList = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
     

      var res = await axios.get(
        client.domain + "/ims/getReportCurrentStock",
        { headers },
        { withCredentials: false }
      );

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getReportList : ", res.data.detail.data);
        setReportData(res.data.detail.data);

        //setShowMsgTitle("Report Info Added Successfully");
        setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        //setShowMsg("Please try after sometime");
        //setShowMsgTitle("Network Issue!");
        //setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg("Please try again ...");
          //setShowMsgTitle("Unable to process your request!");
          //setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          //setShowMsg(error.response.data.detail);
          //setShowMsgTitle("Unable to process your request at this moment!");
          //setShow(true);
        }
      }
    }
  };

  useEffect(() => {
    console.log("useEffect method called ...");

    getReportList();
  }, [])

  return (
    <PDFViewer style={styles.viewer}>
      {toRender === true &&
        <Document>
          <Page size="A4" style={styles.page}>
          

            {/* 
      <View style={styles.section}>
        <Text  className="text-center"style={{fontWeight: 'bold'}}  >{reportData.report_header.name}</Text>
   
      <View style={styles.headerContainer}>
        <Text style={{fontWeight: 'bold'}}>{reportData.report_header.gst_no}</Text>
        <Text>{reportData.report_header.address_line1} </Text>
        <Text>{reportData.report_header.address_line2}</Text>
        <Text>{reportData.report_header.district}</Text>
        <Text>{reportData.report_header.pincode}</Text>
    </View>
     </View>
            */}

            <View>
              <View style={{ flexDirection: 'row' }}>
             
                <View>
                <Text style={{fontFamily: 'Helvetica-Bold', paddingRight: "80px", alignItems: "right",paddingTop: "10px"}}>No : 239</Text>
                </View>
                <View style={{ flex: 1, width: "100%", alignItems: "center", /*paddingRight: "30px",*/ /*,marginTop: 1, marginBottom: 10, borderWidth: 1*/ }} key="address-key">
                <Text style={{fontFamily: 'Helvetica-Bold', paddingRight: "10px", alignItems: "center",paddingTop: "10px"}}>Fuel Pass
                </Text>
                <Text style={{fontFamily: 'Helvetica-Bold', paddingRight: "450px", alignItems: "right",paddingTop: "10px"}}>To:</Text>
                  <Text style={{ fontSize: "20px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold', color: "green" }}>Avina Agencies</Text>
                  <Text style={{ fontSize: "12px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>Indian Oil Dealer</Text>
                  <Text>2/14, Dhanam Nagar, Mylampatti Post, Chinniyampalayam</Text>
                  <Text>Coimbatore - 641062</Text>
                </View>
                <View >
                <Text style={{fontFamily: 'Helvetica-Bold', paddingRight: "70px", alignItems: "center",paddingTop: "5px"}}>Date : 08-03-2024</Text>  
                </View>  
              </View>
            </View>
            <View style={{ width: "100%", alignItems: "left", paddingRight: "50px", paddingTop: "20px", textAlign: 'left', paddingTop: "10px" }}>
              <Text style={{ fontSize: "10px", textAlign: 'left', alignContent: 'left', paddingBottom: "5px",fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>From :  {COMPANY_NAME}</Text>
              <Text style={{ fontSize: "10px", textAlign: 'left', alignContent: 'left', paddingBottom: "5px",fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Vehicle :  TN 39 DY 7314</Text>
              <Text style={{ fontSize: "10px", textAlign: 'left', alignContent: 'left', paddingBottom: "5px",fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Please Supply the following and Debit to Our A/C.</Text>
              <Text style={{ fontSize: "10px", textAlign: 'left', alignContent: 'left', paddingBottom: "5px",fontWeight: 'bold', fontFamily: 'Helvetica-Bold',borderBottomWidth: 1}}>Diesel :</Text>
              <hr style={{paddingBottom: "10px"}}/>
              <Text style={{ fontSize: "10px", textAlign: 'left', alignContent: 'left', paddingBottom: "5px",fontWeight: 'bold', fontFamily: 'Helvetica-Bold',borderBottomWidth: 1}}>Petrol :</Text>
              <hr style={{paddingBottom: "10px"}}/>
              <Text style={{ fontSize: "10px", textAlign: 'left', alignContent: 'left', paddingBottom: "5px",fontWeight: 'bold', fontFamily: 'Helvetica-Bold',borderBottomWidth: 1}}>Oil :</Text>
              <hr style={{paddingBottom: "10px"}}/>
            </View>
            <hr style={{paddingBottom: "10px"}}/>
            <View style={{ width: "100%", paddingBottom: "20px" }}>
             <Text style={{fontSize: "8px", paddingLeft: "360px", alignItems: "left",paddingBottom:"50px"}}>Authorised Signatory</Text>
              </View>   
           
            

           
           
              

          </Page>
        </Document>
      }
    </PDFViewer>
  );
}

export default PrintFuelPass;