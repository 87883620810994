import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

import { NumToWord, convertNumberToWords } from '../../pages/utils';

const borderColor = '#a8a8a8'
const itemRowHeight = 70;
const paddingHeight = 6;
const itemExtraHeight = 48;
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#a8a8a8',
        borderBottomWidth: 1,
        alignItems: 'center',
        //height: 24,
        fontStyle: 'bold',
        //paddingBottom: '30px'
    },
    sno: {
        width: '10%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    description: {
        width: '40%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    rate: {
        width: '30%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        //height: 20,
        //height: 18,
        //textAlign: 'right',
        paddingLeft: 8,
        //paddingTop: 2
        height: 24
    },
    amount: {
        width: '20%',
        //borderRightColor: borderColor,
        //borderRightWidth: 1,
        //borderLeftColor: borderColor,
        //borderLeftWidth: 1,
        textAlign: 'right',
        paddingLeft: 8,
        height: 24
    },
    addl_charges: {
        width: '30%',
        textAlign: 'right',
        //paddingRight: 8,
    },
    row_empty: {
        flexDirection: 'row',
        borderBottomColor: '#a8a8a8',
        //borderBottomWidth: 1,
        alignItems: 'center',
        //height: 24,
        fontStyle: 'bold',
    },
    sno_empty: {
        width: '10%',
        //textAlign: 'left',
        //borderRightColor: borderColor,
        //borderRightWidth: 1,
        //paddingLeft: 8,
    },
    description_empty: {
        width: '40%',
        //textAlign: 'left',
        //borderRightColor: borderColor,
        //borderRightWidth: 1,
        //paddingLeft: 8,
    },
    qty_empty: {
        width: '10%',
        //borderRightColor: borderColor,
        //borderRightWidth: 1,
        //textAlign: 'right',
        //paddingRight: 8,
    },
    amount_in_words: {
        //flexWrap: 'wrap',
        width: '50%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
        height: 24,
        //marginTop: 1,
        //marginBottom: 10,
        //borderWidth: 1,
    },
    sno_with_space: {
        width: '10%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        height: itemRowHeight,
        paddingLeft: 8,
    },
    description_with_space: {
        width: '28%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        height: itemRowHeight,
        paddingLeft: 8,
    },
    hsncode_with_space: {
        width: '12%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        height: itemRowHeight,
        paddingLeft: 8,
    },
    qty_with_space: {
        width: '13%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        height: itemRowHeight,
        paddingRight: 8,
    },
    rate_with_space: {
        width: '22%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        height: itemRowHeight,
        paddingRight: 8,
    },
    amount_with_space: {
        width: '20%',
        textAlign: 'right',
        height: itemRowHeight,
        paddingRight: 8,
    },
    rate_at_reverse: {
        width: '30%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        //paddingRight: 8,
    },
    amount_at_reverse: {
        width: '20%',
        textAlign: 'right',
        paddingRight: 8,
    }
});


const OrderTableRow = (props) => {
    console.log("Items Full : ", props);
    console.log("GST INCLDUE Or NOT STATUS : ", props.invoice.order_info.bill_gst_include);
    console.log("LENGTH OF ITEMS ARRAY : ", props.items.length);
    console.log("prodcut Type : ", props.items[0].prodInfo.product_type);
    const rows = props.items.map((item, idx) =>
        <View style={{ ...styles.row }} key="items">
            { idx === (props.items.length -1) ?
            <Text style={{ ...styles.sno_with_space, paddingTop: `${paddingHeight}px`, height: styles.sno_with_space.height + itemExtraHeight}}>{idx + 1}</Text>
            :
            <Text style={{ ...styles.sno_with_space, paddingTop: `${paddingHeight}px`}}>{idx + 1}</Text>
            }
            {/*
            {item.prodInfo.product_type === 'PRODUCT' ?
            <Text style={idx === (props.items.length -1) ? { ...styles.description_with_space, paddingTop: `${paddingHeight}px`, height: styles.sno_with_space.height + itemExtraHeight} : { ...styles.description_with_space, paddingTop: `${paddingHeight}px`}}>{item.prodInfo.product_name}{'\n'}{props.invoice.order_info.notes}</Text>
            :
            <Text style={idx === (props.items.length -1) ? { ...styles.description_with_space, paddingTop: `${paddingHeight}px`, height: styles.sno_with_space.height + itemExtraHeight} : { ...styles.description_with_space, paddingTop: `${paddingHeight}px`}}>{item.prodInfo.product_name}</Text>
            }
            */}
            <Text style={idx === (props.items.length -1) ? { ...styles.description_with_space, paddingTop: `${paddingHeight}px`, height: styles.sno_with_space.height + itemExtraHeight} : { ...styles.description_with_space, paddingTop: `${paddingHeight}px`}}>{item.prodInfo.product_name}{'\n'}{item.prodInfo.addl_notes.length > 0 && item.prodInfo.addl_notes.replace(/^/,"(").replace(/$/,")")}</Text>
            

            <Text style={idx === (props.items.length -1) ? { ...styles.hsncode_with_space, paddingTop: `${paddingHeight}px`, height: styles.sno_with_space.height + itemExtraHeight}: { ...styles.hsncode_with_space, paddingTop: `${paddingHeight}px`}}>{item.prodInfo.hsncode}</Text>
            <Text style={idx === (props.items.length -1) ? { ...styles.qty_with_space, paddingTop: `${paddingHeight}px`, height: styles.sno_with_space.height + itemExtraHeight}:{ ...styles.qty_with_space, paddingTop: `${paddingHeight}px`}}>{item.prodInfo.quantity.toFixed(2)}</Text>
            <Text style={idx === (props.items.length -1) ? { ...styles.rate_with_space, paddingTop: `${paddingHeight}px`, height: styles.sno_with_space.height + itemExtraHeight}: { ...styles.rate_with_space, paddingTop: `${paddingHeight}px`}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.prodInfo.unit_price).toFixed(2)))}</Text>
            { item.producy_type === 'PRODUCT' ?  
            <Text style={idx === (props.items.length -1) ? { ...styles.amount_with_space, paddingTop: `${paddingHeight}px`, height: styles.sno_with_space.height + itemExtraHeight}:{ ...styles.amount_with_space, paddingTop: `${paddingHeight}px`}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.prodInfo.quantity * item.prodInfo.unit_price).toFixed(2)))}</Text>
            :
            <Text style={idx === (props.items.length -1) ? { ...styles.amount_with_space, paddingTop: `${paddingHeight}px`, height: styles.sno_with_space.height + itemExtraHeight}: { ...styles.amount_with_space, paddingTop: `${paddingHeight}px`}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.prodInfo.total_item_price).toFixed(2)))}</Text>
            }
        </View>
    )

    var cgstPercDisp = 0.00;
    var sgstPercDisp = 0.00;
    //console.log("PRODINFO :", prodInfo);
    if (props.invoice.order_info.bill_gst_include === true ) {
        cgstPercDisp = props.items[0].prodInfo.cgst_perc;
        sgstPercDisp = props.items[0].prodInfo.sgst_perc;
    }

    let total_product_amount = 0.00;
    props.items.map((item) => {
        console.log("++++++++++++++ INDIVIDUAL PRODUCT ITEM VALUE : ", item);
        if ( item.prodInfo.product_type === 'PRODUCT') {
            total_product_amount = total_product_amount + (item.prodInfo.quantity * item.prodInfo.unit_price);
        }
        else {
            total_product_amount = total_product_amount + item.prodInfo.total_item_price;
        }
        console.log("total prodcut amount at each iterate : ", total_product_amount);
    });

    /*
    let total_product_amount = props.items.map(item => (item.prodInfo.quantity * item.prodInfo.unit_price))
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    */
    console.log("TOTAL AMOUNT : ", total_product_amount);

    let cgst_amount = 0.00;
    //cgst_amount = props.items.map(item => (item.prodInfo.quantity * item.prodInfo.unit_price * (item.prodInfo.cgst_perc / 100))).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    if (props.invoice.order_info.bill_gst_include === true ) {
        props.items.map((item) => {
        if ( item.prodInfo.product_type === 'PRODUCT') {
            cgst_amount = cgst_amount + (item.prodInfo.quantity * item.prodInfo.unit_price * (item.prodInfo.cgst_perc / 100));
        }
        else {
            cgst_amount = cgst_amount + (item.prodInfo.total_item_price * (item.prodInfo.cgst_perc / 100));
        }
    })
    }
    console.log("CGST AMOUNT : ", cgst_amount);

    let sgst_amount = 0.00;
    /*
    if (props.invoice.order_info.bill_gst_include === true ) {
        sgst_amount = props.items.map(item => (item.prodInfo.quantity * item.prodInfo.unit_price * (item.prodInfo.sgst_perc / 100)))
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    }
    */
    if (props.invoice.order_info.bill_gst_include === true ) {
        props.items.map((item) => {
            if ( item.prodInfo.product_type === 'PRODUCT') {
                sgst_amount = sgst_amount + (item.prodInfo.quantity * item.prodInfo.unit_price * (item.prodInfo.sgst_perc / 100));
            }
            else {
                sgst_amount = sgst_amount + (item.prodInfo.total_item_price * (item.prodInfo.sgst_perc / 100));
            }
        })
    }
    console.log("SGST AMOUNT : ", sgst_amount);

    let igst_amount = 0;
    /*
    if (props.invoice.order_info.bill_gst_include === true ) {
        igst_amount = props.items.map(item => (item.prodInfo.quantity * item.prodInfo.unit_price * (item.prodInfo.igst_perc / 100)))
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    }
    */
    if (props.invoice.order_info.bill_gst_include === true ) {
        props.items.map((item) => {
            if ( item.prodInfo.product_type === 'PRODUCT') {
                igst_amount = igst_amount + (item.prodInfo.quantity * item.prodInfo.unit_price * (item.prodInfo.igst_perc / 100));
            }
            else {
                igst_amount = igst_amount + (item.prodInfo.total_item_price * (item.prodInfo.igst_perc / 100));
            }
        })
    }
    console.log("IGST AMOUNT : ", igst_amount);

    let total_gst = cgst_amount + sgst_amount + igst_amount;

    let final_total = total_product_amount + total_gst + props.invoice.order_info.diesel_charges + props.invoice.order_info.pumping_charges;
    console.log("FINAL TOTAL NUMERIC :  ", final_total);
    var total_rounded = Math.round(final_total);
    //var decimal_part = final_total - Math.floor(final_total);
    console.log("Rounded total value : ", total_rounded);
    //console.log("Decimal part only : ", decimal_part);
    //var decimalOnly = Math.round(decimal_part * 100);
    //var decimal1 = (final_total + "").split(".");
    //console.log("ONLY DECIMAL : ", decimal1);
    //var totalRupeeInWords = NumToWord(total_rounded);
    var totalRupeeInWords = convertNumberToWords(total_rounded);
    var finalAmtInWords = "";
    /*
    if ( decimal_part > 0 ) {
        var totalPaisaInWords = NumToWord(decimalOnly);
        console.log("FINAL TOTAL IN RUPEE : ", totalRupeeInWords);
        console.log("FINAL TOTAL IN PAISE : ", totalPaisaInWords);
        finalAmtInWords = totalRupeeInWords + " rupees and " + totalPaisaInWords + " paisa only";
    }
    else {
        finalAmtInWords = totalRupeeInWords + " rupees only";
    }
    */
    //finalAmtInWords = totalRupeeInWords + " rupees only";
    finalAmtInWords = totalRupeeInWords;
    console.log("FINAL TOTAL IN RUPEE : ", finalAmtInWords);
    /*
    const dieselCharges = (
       
       
        <View style={styles.row} key="items">
            <Text style={styles.sno}></Text>
            <Text style={{ ...styles.description, textAlign: 'right' }}></Text>
            <Text style={styles.qty}></Text>
            <Text style={styles.rate}>Diesel Charges</Text>
            <Text style={styles.amount}>{(props.invoice.order_info.diesel_charges).toFixed(2)}</Text>
        </View>
    );
    */
    
    /*
    const pumpingCharges = (
        <View style={styles.row} key="items">
            <Text style={styles.sno}></Text>
            <Text style={{ ...styles.description, textAlign: 'right' }}></Text>
            <Text style={styles.qty}></Text>
            <Text style={styles.rate}>Pumping Charges</Text>
            <Text style={styles.amount}>{(props.invoice.order_info.pumping_charges).toFixed(2)}</Text>
        </View>
    );
    */
    

    const gstHeading = (
        <View style={styles.row} key="items">
            <Text style={styles.sno}></Text>
            <Text style={{ ...styles.description, textAlign: 'right' }}></Text>
            {/*<Text style={styles.qty}>GST</Text>*/}
            <Text style={styles.addl_charges}>GST</Text>
            <Text style={styles.amount}></Text>
        </View>
    );

    //var total_product_amount_tmp = parseFloat(total_product_amount).toFixed(2);
    var total_product_amount_tmp = new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(total_product_amount).toFixed(2));
    console.log("SUB TOTAL WITH COMMA : ", total_product_amount_tmp);
    //total_product_amount_tmp.toLocaleString('en-IN', {maximumFractionDigits: 2, currency: 'INR'})

    const totalProdAmtDisp = (
        <View style={{ ...styles.row_empty}} key="items">
        <Text style={{ ...styles.amount_in_words, fontWeight: 'bold', fontFamily: 'Helvetica-Bold', textAlign: 'left', width: '60%', paddingLeft: 8, paddingTop: '5px'}}></Text>
            {/*<Text style={{...styles.description_empty, textAlign: 'right'}}></Text>*/}
            {/*<Text style={styles.qty_empty}></Text>*/}
            <Text style={{ ...styles.rate, borderBottomWidth: 1, borderBottomColor: borderColor, fontFamily: 'Helvetica-Bold', width: '25%', textAlign: 'right', paddingTop: '5px', paddingRight: '8px'}}>SUB TOTAL</Text>
            <Text style={{ ...styles.amount, borderBottomWidth: 1, borderBottomColor: borderColor, fontFamily: 'Helvetica-Bold', width: '15%', textAlign: 'right', paddingTop: '5px', paddingRight: '4px' }}>{total_product_amount_tmp}</Text>
        </View>
    );

    /*
    //final_total = 999456.00;
    var amtInWords = "";
    var decimal1 = (final_total.toString() + "").split(".");
    if ( decimal1.length > 1 ) {
        console.log("ONLY DECIMAL : ", decimal1);
        console.log("FINAL TOTAL IN RUPEE : ", NumToWord(decimal1[0]));
        console.log("FINAL TOTAL IN PAISE : ", NumToWord(decimal1[1]));
        amtInWords = NumToWord(decimal1[0]) + " rupees and " + NumToWord(decimal1[1]) + " paisa only";
    }
    else {
        amtInWords = NumToWord(decimal1[0]) + " rupees only";
    }
    */
    const cgstDisp = (
        <View style={styles.row_empty} key="items">
            <Text style={{ ...styles.amount_in_words, fontWeight: 'bold', fontFamily: 'Helvetica-Bold', textAlign: 'left', width: '60%', paddingLeft: 8, paddingTop: '5px'}}>Amount in words:</Text>
            {/*<Text style={{...styles.description_empty, textAlign: 'right'}}></Text>*/}
            {/*<Text style={styles.qty_empty}></Text>*/}
            <Text style={{ ...styles.rate, borderBottomWidth: 1, borderBottomColor: borderColor, width: '25%', textAlign: 'right', paddingTop: '5px', paddingRight: '8px' }}>CGST @ {cgstPercDisp}%</Text>
            <Text style={{ ...styles.amount, borderBottomWidth: 1, borderBottomColor: borderColor, width: '15%', textAlign: 'right', paddingTop: '5px', paddingRight: '4px' }}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(cgst_amount).toFixed(2)))}</Text>
        </View>
    );

    const sgstDisp = (
        <View style={{ ...styles.row_empty}} key="items">
            {/*<Text style={{ ...styles.amount_in_words, width: '60%'}}> </Text>*/}
            <Text style={{ ...styles.amount_in_words, fontFamily: 'Helvetica', /*flexDirection: 'row', flexWrap: 'wrap',*/ textAlign: 'left', width: '60%', paddingLeft: 8, height: 28}}>{finalAmtInWords}</Text>
            {/*<Text style={styles.sno_empty}></Text>
                <Text style={{...styles.description_empty, textAlign: 'right'}}></Text>
                <Text style={styles.qty_empty}></Text>*/}
            <Text style={{ ...styles.rate, borderBottomWidth: 1, borderBottomColor: borderColor, width: '25%', textAlign: 'right', paddingTop: '5px', height: 28, paddingRight: '8px' }}>SGST @ {cgstPercDisp}%</Text>
            <Text style={{ ...styles.amount, borderBottomWidth: 1, borderBottomColor: borderColor, width: '15%', textAlign: 'right', paddingTop: '5px', paddingRight: '4px', height: 28 }}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(sgst_amount).toFixed(2)))}</Text>
        </View>
    );


    const igstDisp = (
        <View style={styles.row} key="items">
            <Text style={styles.sno}></Text>
            <Text style={{ ...styles.description, textAlign: 'right' }}></Text>
            <Text style={styles.qty}></Text>
            <Text style={styles.rate}>IGST</Text>
            <Text style={styles.amount}>{igst_amount.toFixed(2)}</Text>
        </View>
    );


    const totGstDisp = (
        <View style={{ ...styles.row_empty }} key="items">
            <Text style={{ ...styles.amount_in_words, width: '60%'}}> </Text>
            {/*<Text style={styles.sno_empty}></Text>
                        <Text style={{...styles.description_empty, textAlign: 'right'}}></Text>
                    <Text style={styles.qty_empty}></Text>*/}
            <Text style={{ ...styles.rate, borderBottomWidth: 1, borderBottomColor: borderColor, width: '25%', textAlign: 'right', paddingTop: '5px', paddingRight: '8px' }}>Total GST</Text>
            <Text style={{ ...styles.amount, borderBottomWidth: 1, borderBottomColor: borderColor, width: '15%', textAlign: 'right', paddingTop: '5px', paddingRight: '4px' }}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(total_gst).toFixed(2)))}</Text>
        </View>
    );

    var final_total_tmp = new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(final_total).toFixed(2));
    const actualTotalDisp = (
        <View style={{ ...styles.row_empty }} key="items">
            <Text style={{ ...styles.amount_in_words, width: '60%'}}> </Text>
            {/*<Text style={styles.sno_empty}></Text>
                        <Text style={{...styles.description_empty, textAlign: 'right'}}></Text>
                    <Text style={styles.qty_empty}></Text>*/}
            <Text style={{ ...styles.rate, fontWeight: 'bold', fontFamily: 'Helvetica-Bold', width: '25%', borderBottomWidth: 1, borderBottomColor: borderColor, textAlign: 'right', paddingTop: '5px', paddingRight: '8px' }}>TOTAL</Text>
            <Text style={{ ...styles.amount,fontWeight: 'bold', fontFamily: 'Helvetica-Bold', width: '15%', borderBottomWidth: 1, borderBottomColor: borderColor, textAlign: 'right', paddingTop: '5px', paddingRight: '4px' }}>{final_total_tmp}</Text>
        </View>
    );

    var sum_rounded_off = 0.00;
    sum_rounded_off = Math.round(final_total).toFixed(2) - final_total;
    sum_rounded_off = sum_rounded_off.toFixed(2);
    //console.log("<<<<<<<<<<<<<< ROUNDED OFF 31 : ", tmp_val2);
    //sum_rounded_off = Math.round((tmp_val2 + Number.EPSILON)*100)/100;
  console.log("<<<<<<<<<<<<<< ROUNDED OFF 32 : ", sum_rounded_off);
  
    const roundOffDisp = (
        <View style={{ ...styles.row_empty, borderBottomWidth: 1, borderBottomColor: borderColor }} key="items">
            <Text style={{ ...styles.amount_in_words, width: '60%'}}> </Text>
            {/*<Text style={styles.sno_empty}></Text>
                        <Text style={{...styles.description_empty, textAlign: 'right'}}></Text>
                    <Text style={styles.qty_empty}></Text>*/}
            <Text style={{ ...styles.rate, width: '25%', textAlign: 'right', paddingTop: '5px', paddingRight: '8px' }}>Round off </Text>
            <Text style={{ ...styles.amount, width: '15%', textAlign: 'right', paddingTop: '5px', paddingRight: '4px' }}>{sum_rounded_off}</Text>
        </View>
    );


    return (<Fragment>{rows}{/*{dieselCharges}{pumpingCharges}*/}{totalProdAmtDisp}{cgstDisp}{sgstDisp}{totGstDisp}{actualTotalDisp}{roundOffDisp}</Fragment>)
}



export default OrderTableRow