import React, { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';

import { client } from '../config/Config';
//import logo_kmc1 from '../logo_kmc1.png';
//import logo_kmc2 from '../logo_kmc2.png';
import logo_left from '../logo_left.png';
import logo_right from '../logo_right.png';

import StockDocument from "./StockDocument";
import { getCompanyInfo, formatDateIndian } from "./utils";

const borderColor = '#a8a8a8';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 9,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    //width: '100%',
    //height: '33%',
    width: '100%',
    height: '100%',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  logo_image2: {
    width: '15%',
    height: '98px',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  business_content: {
    width: '60%',
    height: '98px',
    //paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container2: {
    //paddingBottom:0,
    flexDirection: 'row',
    //borderBottomColor: '#bff0fd',
    //backgroundColor: '#bff0fd',
    //borderBottomWidth: 1,
    //alignItems: 'center',
    //height: 12,
    //textAlign: 'center',
    //fontStyle: 'bold',
    //flexGrow: 1,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 1,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#a8a8a8',
  },
  container: {
    flexDirection: 'row',
    borderBottomColor: '#a8a8a8',
    //backgroundColor: '#a8a8a8',
    //borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
  },
  sno: {
    width: '5%',
    fontSize: "10px",
    borderRightColor: borderColor,
    borderRightWidth: 1,

  },
  delivery_date: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    //textAlign: 'left'
  },
  customer_name: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  product_name: {
    width: '11%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
  },
  address_line1: {
    width: '11%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
  },
  total_quantity: {
    width: '11%',
    fontSize: "9px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
  },
  unit_price: {
    width: '11%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
  },
  product_price: {
    width: '11%',
    borderRightColor: borderColor,
    fontSize: "10px",
    //borderRightWidth: 1,
  },
  extra_charges: {
    width: '11%',
    borderRightColor: borderColor,
    fontSize: "10px",
    //borderRightWidth: 1,
  },
  total_amount: {
    width: '11%',
    borderRightColor: borderColor,
    fontSize: "10px",
    //borderRightWidth: 1,
  },
  row: {
    flexDirection: 'row',
    //borderBottomColor: '#a8a8a8',
    //borderBottmWidth: 1,
    alignItems: 'center',
    //height: 24,
    fontStyle: 'bold',
    flexGrow: 1
  },
  snoData: {
    width: '5%',
    //textAlign: 'left',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    fontSize: "10px",
    //paddingLeft: 8,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  delivery_dateData: {
    width: '10%',
    fontSize: "10px",
    //textAlign: 'left',
    //textAlign: 'justify',
    //alignContent: 'justify',
    //textAlign: 'justify',
    //textJustify: 'inter-word',
    //display:'flex',
    //flexWrap: 'wrap',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    //paddingLeft: 8,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  customer_nameData: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    //textAlign: 'right',
    //paddingRight: 8,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  product_nameData: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    //textAlign: 'right',
    //paddingRight: 8,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  address_line1Data: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    //textAlign: 'right',
    //paddingRight: 8,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  total_quantityData: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //textAlign: 'right',
    //paddingRight: 8,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  unit_priceData: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //textAlign: 'right',
    //paddingRight: 8,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  product_priceData: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    //textAlign: 'right',
    //paddingRight: 8,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  extra_chargesData: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    //textAlign: 'right',
    //paddingRight: 8,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  total_amountData: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    //textAlign: 'right',
    //paddingRight: 8,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },


});

const PrintDeliverySummaryDaily = (props) => {
  const [reportData, setReportData] = useState({});
  const [toRender, setToRender] = useState(false);
  const [summaryList, setSummaryList] = useState([]);
  const [dcListFetched, setDcListFetched] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({});

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  const formateTime = (currTime) => {
    var h = (currTime.getHours() < 10 ? "0" : "") + currTime.getHours();
    var m = (currTime.getMinutes() < 10 ? "0" : "") + currTime.getMinutes();
    var hrMin = h + ":" + m;
    return hrMin;
  };

  const formatPrintDate = (currTime) => {
    var dateComponent = [
      padTo2Digits(currTime.getDate()),
      padTo2Digits(currTime.getMonth() + 1),
      currTime.getFullYear(),
    ].join("-");

    var timeComponent = [
      padTo2Digits(currTime.getHours() % 12 || 12),
      padTo2Digits(currTime.getMinutes())
    ].join(":");

    const ampm = currTime.getHours() >= 12 ? 'PM' : 'AM';

    return dateComponent + " " + timeComponent + " " + ampm;
  };

  const getAllSummaryList = async () => {
    var retState = false;
    var deliverySummaryList = [];
    try {
      var res = await axios.get(client.domain + `/getDeliverySummaryList`, { withCredentials: false });
      console.log("Response from server getAllSummaryList : ", res.data.detail);
      if (res.data.detail.status === 'SUCCESS') {
        console.log("Response message from server SummaryList : ", res.data.detail.data);
        retState = true;
        if (res.data.detail.data.length > 0) {
          deliverySummaryList = res.data.detail.data;
        }

      }
    }
    catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === 'ERR_NETWORK') {
        console.log("Not able to reach target server please try after sometime");
      }
      else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === 'FAILED') {
          console.log('Request failed due to ', error.response.data.detail.res_data);

        }
      }
    }
    return [retState, deliverySummaryList];
  }


  useEffect(() => {
    console.log("useEffect method called ...");
    (async () => {
      var [retState, deliverySummaryList] = await getAllSummaryList();
      if (retState) {
        setSummaryList(deliverySummaryList);
        setDcListFetched(true);
        setToRender(true);

      }
      var [retState, companyInfoTmp] = await getCompanyInfo();
      if (retState) {
        setCompanyInfo(companyInfoTmp);
      }
    })();
  }, [])

  return (
    <PDFViewer style={styles.viewer}>
      {toRender === true &&
        <Document >

          <Page size="A4" style={styles.page} orientation="Landscape">

            <View>
              <View style={{ flexDirection: 'row' }}>
                <View style={{ textAlign: 'right', width: '25%' }}>
                  <Image style={{ marginHorizontal: '10%', width: 'auto', height: 'auto', height: "64px", width: "64px" }} src={logo_left} />
                </View>
                <View style={{ flex: 1, width: "100%", alignItems: "center", /*paddingRight: "30px",*/ borderBottomWidth: 1, borderBottomColor: '#a7a5a5'/*,marginTop: 1, marginBottom: 10, borderWidth: 1*/ }} key="address-key">
                  <Text style={{ fontSize: "20px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>{companyInfo.name}</Text>
                  <Text>{companyInfo.address_line1}</Text>
                  <Text>{companyInfo.address_line2}-{companyInfo.pincode}</Text>
                  <Text>{companyInfo.district_name}, {companyInfo.state_name}</Text>
                  <Text style={{ fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica', color: "red" }}><Text style={{ fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold', color: "red" }}>Phone:</Text> {companyInfo.phone}</Text>
                </View>
                <View style={{ textAlign: 'right', width: '25%' }}>
                  <Image style={{ marginHorizontal: '10%', width: 'auto', height: 'auto', height: "64px", width: "64px" }} src={logo_right} />
                </View>
              </View>
            </View>


            <View style={{ width: "100%", alignItems: "center",/* paddingLeft: "20px",*/ textAlign: 'center', borderBottomWidth: 1, borderBottomColor: '#a7a5a5', paddingTop: "5px" }}>
              <Text style={{ fontSize: "12px", textAlign: 'center', alignContent: 'center', fontFamily: 'Helvetica-Bold', color: '#646363' }}>Daily Delivery Summary</Text>
            </View>



            <View style={{ width: "75%", alignItems: "left", paddingRight: "50px", paddingTop: "20px", textAlign: 'left' }}>
              <Text style={{ fontSize: "10px", textAlign: 'left', alignContent: 'left', fontFamily: 'Helvetica-Bold' }}>summary</Text>
            </View>

            <View style={styles.tableContainer}>

              <View style={styles.container}>
                <Text style={styles.sno}>S.No</Text>
                {/*
                <Text style={styles.delivery_date}>Date</Text>
                <Text style={styles.customer_name}>Customer</Text>
                <Text style={styles.product_name}>Grade</Text>
                <Text style={styles.address_line1}>Location</Text>
                <Text style={styles.total_quantity}>Total Volume</Text>
                <Text style={styles.unit_price}>Unit Price</Text>
                <Text style={styles.product_price}>Product Price</Text>
                <Text style={styles.extra_charges}>Extra Charges </Text>
                <Text style={styles.total_amount}>Total Amount</Text>
                */}
              </View>



              <View style={styles.con} key="items">
                {summaryList.map((summary, idx) => (
                  <View style={styles.row} key="items-${idx}">
                    <Text style={styles.snoData}>{idx + 1}</Text>
                    {/*
                    <Text style={styles.delivery_dateData}>{formatDateIndian(new Date(summary.delivery_date))}</Text>
                    <Text style={styles.customer_nameData}>{summary.customer_name}</Text>
                    <Text style={styles.product_nameData}>{summary.product_name}</Text>
                    <Text style={styles.address_line1Data}>{summary.address_line1}</Text>
                    <Text style={styles.total_quantityData}>{summary.total_quantity}</Text>
                    <Text style={styles.unit_priceData}>{summary.unit_price}</Text>
                    <Text style={styles.product_priceData}>{summary.total_quantityData * summary.unit_price}</Text>
                    <Text style={styles.extra_chargesData}>{parseFloat(summary.pumping_chargesData) + parseFloat(summary.diesel_chargesDate) + parseFloat(summary.others_charges1Data)}</Text>
                    <Text style={styles.total_amountData}>{summary.total_amountData}</Text>
                    */}
                  </View>
                ))}
              </View>

            </View>









            <View style={{ flexDirection: 'row' }}>

              <Text style={{ textAlign: 'left', flex: 1 }} render={({ pageNumber, totalPages }) => (
                `Printed On: ${formatPrintDate(new Date())}`
              )} fixed />



              <Text style={{ flex: 1, textAlign: 'right', justifyContent: 'flex-end', alignSelf: 'flex-end' }} render={({ pageNumber, totalPages }) => (
                `${pageNumber} / ${totalPages}`
              )} fixed />
            </View>


          </Page>
        </Document>
      }
    </PDFViewer>
  );
}

export default PrintDeliverySummaryDaily;