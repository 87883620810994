import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  Modal,
  Spinner,
  Image
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
import Sidebar from "./Sidebar";
import Sidebar2 from "./Sidebar2";
import Topmenu from "./Topmenu";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
//import DateTimeField from "react-bootstrap-datetimepicker"
import DateTimePicker from "react-datetime-picker";

import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client } from "../config/Config";
import axios from "axios";

import { useLocation } from "react-router-dom";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import MenuVms from "./MenuVms";

import { BsArrowLeftCircle, BsFillPenFill,BsPencilSquare } from "react-icons/bs";
import SidebarMain from "./SidebarMain";
import {getVehicleTypeList } from "./utils";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';

function AddUpdateVehicle(props) {
  const initialized = useRef(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  let initialItems = [];
  function padTo2Digits(num) {
    console.log("<<<<<<<<<<<<<< given number >>>>>>>>>>>>>>>> : ", num);
    var modNum = num.toString().padStart(2, "0");
    console.log("Modified  value : ", modNum);
    return modNum;
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  const intialValues = {
    reg_date: formatDate(new Date()),
    fc_date: formatDate(new Date()),
    ins_renewal_date: formatDate(new Date()),
    vehicle_name: "",
    reg_no: "",
    vehicle_type_id: "",
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  
  const [vehicleTypeOpt, setVehicleTypeOpt] = useState([]);
  const [items, setItems] = useState(initialItems);

  const [isReadOnly, setIsReadOnly] = useState(true);
  // const [formValues, setFormValues] = useState(intialValues);

  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showMsgTitle, setShowMsgTitle] = useState("Invalid authentication Session!");
  const [submitAction, setSubmitAction] = useState(false);
  const [validateError, setValidateError] = useState(false);
  const [toRender, setToRender] = useState(false);
  var validateErr = false;

  const handleOnChange = (e) => {
    //e.preventDefault();
    console.log("Button clicked : ", e);

    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);

    setFormValues({ ...formValues, [name]: value });
  };
  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!values.vehicle_name) {
      errors.vehicle_name = "Name is required";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.reg_no) {
      errors.reg_no = "Register Number is required";
      validateErr = true;
      setValidateError(true);
    }
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit called ...");
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ",
      validateErr
    );
    if (validateErr === false) {
      console.log("FORM VALUES at handleSubmit: ", formValues);
      //console.log("LINE ITEMS : ", items);
      callUpdateApi();
      //console.log("Form submit called ...");

      //console.log("Doctor Name : ", formValues["first_name"]);
    }
  };
  const handleSelect = (e, data) => {
    console.log("SELECTED Dropdown value : ", e);
    console.log("SELECTED element id : ", data);
    var currentId = data.name.substring(data.name.lastIndexOf("-") + 1);
    console.log("Current id : ", currentId);
  };
 
  const [vehicleTypeIdSelect, setVehicleTypeIdSelect] = useState({});
  const handleVehicleSelect = (e, data) => {
    console.log("handle Vehicle Select called ...", e);
    setVehicleTypeIdSelect(e);
  };

  const handleDelete = async (e) => {
    console.log("handle delete clicked : ", e);
  };
  const onChangeDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);

    /*
    var formattedDt = toSqlDatetime(e);
    setFormValues({ ...formValues, ["dc_date"]: formattedDt });
    console.log("Formatted str DC date : ", formattedDt);
    */
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (!initialized.current) {
      console.log("*************** useEffect::addUpdateCustomer called ##########################");

      if (state === null) {
        navigate("/listVehicles");
      } else {
        console.log("PASSED STATE VALUE IN Vehicle Add/Update page : ", state);
        if (state.action === "add") {
          setIsReadOnly(true);
        }


        ( async() => {
        var [retState, vehicleTypeArr] = await getVehicleTypeList();
        if ( retState ) {
          setVehicleTypeOpt(vehicleTypeArr);
          if ( state.action === 'add' ){ 
          setVehicleTypeIdSelect(vehicleTypeArr[0]);
        }
        else {
          var findVehicle = vehicleTypeArr.find(element => element.value === state.passed_element.vehicle_type_id);
          if ( findVehicle ) {
            console.log("STATE FOUND OBJECT : ", findVehicle);
            setVehicleTypeIdSelect({value: state.passed_element.vehicle_type_id, label: findVehicle.label});
          }
      }
      
        }
        setToRender(true);
      })();
        if (state.action === "add") {
          setIsReadOnly(false);
        } else {
          setFormValues(state.passed_element);
          console.log("newObj 876 :", formValues);

          formValues["id"] = state.passed_element.id;
          formValues["vehicle_name"] = state.passed_element.vehicle_name;
          formValues["reg_no"] = state.passed_element.reg_no;

          var regDateTmp = new Date(state.passed_element.reg_date);
          var modifiedDate = "";
          var yeartmp = regDateTmp.getFullYear();
          var monthTmp =
            ((regDateTmp.getMonth() + 1) < 10 ? "0" : "") +
            (regDateTmp.getMonth() + 1);
          var dateTmp =
            (regDateTmp.getDate() < 10 ? "0" : "") + regDateTmp.getDate();
          modifiedDate = yeartmp + "-" + monthTmp + "-" + dateTmp;
          console.log("Modified reg date : ", modifiedDate);

          //setFormValues({ ...formValues, ["reg_date"]: modifiedDate });
          formValues["reg_date"] = modifiedDate;

          var fcDateTmp = new Date(state.passed_element.fc_date);
          var modifiedDate = "";
          var yeartmp = fcDateTmp.getFullYear();
          var monthTmp =
            ((fcDateTmp.getMonth() + 1) < 10 ? "0" : "") + (fcDateTmp.getMonth() + 1);
          var dateTmp =
            (fcDateTmp.getDate() < 10 ? "0" : "") + fcDateTmp.getDate();
          modifiedDate = yeartmp + "-" + monthTmp + "-" + dateTmp;
          console.log("Modified fc date : ", modifiedDate);

          //setFormValues({ ...formValues, ["fc_date"]: modifiedDate });
          formValues["fc_date"] = modifiedDate;
          var insRenewalDateTmp = new Date(
            state.passed_element.ins_renewal_date
          );
          var modifiedDate = "";
          var yeartmp = insRenewalDateTmp.getFullYear();
          var monthTmp =
            ((insRenewalDateTmp.getMonth() + 1) < 10 ? "0" : "") +
            (insRenewalDateTmp.getMonth() + 1);
          var dateTmp =
            (insRenewalDateTmp.getDate() < 10 ? "0" : "") +
            insRenewalDateTmp.getDate();
          modifiedDate = yeartmp + "-" + monthTmp + "-" + dateTmp;
          console.log("Modified renewal date : ", modifiedDate);

          setFormValues({ ...formValues, ["ins_renewal_date"]: modifiedDate });
          //formValues["ins_renewal_date"] = modifiedDate;

          /*
          setFormValues({
            ...formValues,
            ["vehicle_name"]: state.passed_element.vehicle_name,
          });
          */

          /*
        setFormValues({
          ...formValues,
          ["reg_no"]: state.passed_element.reg_no,
        });
        */
        }
      }
      initialized.current = true;
    }
  }, []);

  const callUpdateApi = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers,Authorization: ` ${aIVal}`}
      var apiToCall = "";
      if (state.action === "add") {
        apiToCall = "/vms/addVehicle";
      } else if (state.action === "view") {
        apiToCall = "/vms/updateVehicle";
      } else {
        apiToCall = "/vms/addVehicle";
      }

      var reqParam = {
        vehicle_type_id: vehicleTypeIdSelect.value,
        vehicle_name: formValues["vehicle_name"],
        reg_no: formValues["reg_no"],
        reg_date: formValues["reg_date"],
        fc_date: formValues["fc_date"],
        ins_renewal_date: formValues["ins_renewal_date"],
        id: formValues["id"],
      };
      var headers = client.headers;
      var res = await axios.post(
        client.domain + apiToCall,
        reqParam,
        { headers },
        { withCredentials: false }
      );

      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        setShowMsg(res.data.detail.data.message);
        setShowMsgTitle("Success");
        setShowDialog(true);
        setSubmitAction(false); 
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle("LOGIN FAILED!");
          //setShow(true);
        }
      }
      setSubmitAction(false);
    }
  };
  const handlePostSaveOk = () => {
    console.log("user confirmed to vehicle added ...");
    //console.log("user selected id for action is : ", selectedId);

    // call update API
    // updateCustomerStatus(selectedId);
    setShowDialog(false);
    navigate("/listVehicles");
  };

  const toSqlDatetime = (inputDate) => {
    const date = new Date(inputDate);
    const dateWithOffest = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    return dateWithOffest.toISOString().slice(0, 19).replace("T", " ");
  };

  const handleVehicleTypeSelect = (e, data) => {
    console.log("handleVehicleTypeSelect called ...", e);

    setVehicleTypeIdSelect(e);
  };
  

  /*
    const onChangeRegistrationDate = (e) => {
      console.log("Date onChange Loading time called ...", e);
      var formattedDt = toSqlDatetime(e);
      setFormValues({...formValues, ["registrationDate"]: formattedDt});
      console.log("Formatted str Registration Date : ", formattedDt);
    }
    const onChangeFCDate = (e) => {
      var formattedDt = toSqlDatetime(e);
      setFormValues({...formValues, ["fcdate"]: formattedDt});
      console.log("Formatted str FC Date : ", formattedDt);
      
    }
    const onChangeInsuranceRenewalDate = (e) => {
      var formattedDt = toSqlDatetime(e);
      setFormValues({...formValues, ["InsuranceRenewalDate"]: formattedDt});
      console.log("Formatted str InsuranceRenewalDate : ", formattedDt);
    }
    */
  const onDateChange = async (e, id) => {
    console.log("Clicked value : ", e);
    console.log("DATA : ", id);
    //const{name , value} = e.target;
    //console.log("Captured input element name : ", name);
    //console.log("Captured input element value : ", value);

    var formattedDt = toSqlDatetime(e);

    setFormValues({ ...formValues, [id]: formattedDt });
  };

  const handleValueChange = async (e, data) => {
    e.preventDefault();
    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    setFormValues({ ...formValues, [name]: value });

    //console.log("Clicked data : ", data);
  };

  const handleBack = (e) => {
    console.log("handle Back clicked : ", e);
    navigate(-1);
  };

  const handleEdit = async (e) => {
    console.log("handle edit called : ", e);
    setIsReadOnly(false);
  };
  const handleSave = async (e) => {
    e.preventDefault();
    console.log("Handle Click save called : ", e);
    console.log("Formvalues : ", formValues);

    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    if (validateErr === false) {
      setSubmitAction(true);
      callUpdateApi();
    }
  };
  console.log("formvalues FULL : ", formValues);
  const handleReset = async (e) => {
    console.log("handle reset clicked : ", e);
    navigate(0);
  };
  /*
  const handleClose = async (e) => {
    setShow(false);
    navigate(-1);
  };
  */
  const handleCloseDialog = () => {
    setShowDialog(false);
    navigate("/ListVehicles");
  };

  return (
    <>
      <Container>
          <SidebarMain />
          { toRender ?
        (
         <Form>
        <Row>
        <Col xs={4} md={4} sm={4} lg={4}>
              <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>Vehicle</span>
              { (state.action === 'view' || state.action === 'edit') &&
              <>
              &nbsp;&nbsp;
            <Button variant={state.passed_element.is_active ? "outline-success" : "outline-danger"} size="sm"><span style={{cursor: "default", fontWeight: "500"}}>{state.passed_element.status}</span></Button>
            </>
            }
            </Col>
            
          <Col
            xs={8}
            md={8}
            sm={8}
            lg={8}
            className="float-end justify-content-end"
          >
            <div className="d-flex justify-content-end">
              <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                <BsArrowLeftCircle
                  key={`back-btn-patient-key`}
                  size={24}
                  style={{ cursor: "pointer" }}
                  
                  name={`back-icon-vehicle-name`}
                  id={`edit-icon-vehicle-id`}
                />
              </Button>
              {(state.action === "edit" || state.action === "view") && (
              <>
              &nbsp;&nbsp;
              <Button variant="primary" className="btn btn-primary btn-md" onClick={(e) => handleEdit(e)}>
                <BsPencilSquare
                  key={`edit-btn-vehicle-key`}
                  size={24}
                  style={{ cursor: "pointer" }}
                 
                  name={`edit-icon-paBiSolidEditAlttient-name`}
                  id={`edit-icon-vehicle-id`}
                />
              </Button>
               </>
               )}
            </div>
          </Col>
        
        </Row>
        <hr align="center" />
        
          <div className="ui-divider"></div>
          <div className="ui-form">
          <Row>
            <Col xs={4} md={4} sm={4} lg={4}>
            <Form.Group className="mb-3" controlId="vehicleform.vehicle_type_id">
             <Form.Label>Type</Form.Label>
                <Select
                
                  name="vehicle_type_id"
                  value={{
                    value: vehicleTypeIdSelect.value,
                    label: vehicleTypeIdSelect.label,
                  }}
                  options={vehicleTypeOpt}
                  defaultValue={{
                    value: vehicleTypeIdSelect.value,
                    label: vehicleTypeIdSelect.label,
                  }}
                  onChange={handleVehicleTypeSelect}
                  isDisabled={isReadOnly}
                  style={{
                    border: !isReadOnly && "1px solid gray",
                  }}
                />
                </Form.Group>
              
              <p className="error-msg">{formErrors.vehicle_type_id}</p>
            </Col>
            <Col xs={4} md={4} sm={4} lg={4}>
            <Form.Group className="mb-3" controlId="vehicleform.vehicle_name">
             <Form.Label className="field required">Name</Form.Label>
                <Form.Control
                  type="text"
                  name="vehicle_name"
                 
                  placeholder="Vehicle Name"
                  value={formValues.vehicle_name}
                  onChange={handleValueChange}
                  readOnly={isReadOnly}
                  style={{
                    border: !isReadOnly && "1px solid gray",
                  }}
                />
                </Form.Group>
      
              <p className="error-msg">{formErrors.vehicle_name}</p>
            </Col>
            <Col xs={4} md={4} sm={4} lg={4}>
            <Form.Group className="mb-3" controlId="vehicleform.reg_no">
             <Form.Label className="field required">Registration Number</Form.Label>
                <Form.Control
                  type="text"
                  name="reg_no"
              
                  placeholder="Registration Number"
                  value={formValues.reg_no}
                  readOnly={isReadOnly}
                  onChange={handleValueChange}
                  style={{
                    border: !isReadOnly && "1px solid gray",
                  }}
                />
              </Form.Group>
              <p className="error-msg">{formErrors.reg_no}</p>
            </Col>
            </Row>
            <Row>
            <Col xs={4} md={4} sm={4} lg={4}>
            <Form.Group className="mb-3" controlId="vehicleform.reg_date">
             <Form.Label>Registration  Date</Form.Label>
                <Form.Control
                  type="date"
                  name="reg_date"
                  placeholder="Registration Date"
                  value={formValues.reg_date}
                  onChange={onChangeDatePicker}
                  readOnly={isReadOnly}
                  style={{
                    border: !isReadOnly && "1px solid gray",
                  }}
                />
              </Form.Group>
              <p className="error-msg">{formErrors.reg_date}</p>
            </Col>
            <Col xs={4} md={4} sm={4} lg={4}>
            <Form.Group className="mb-3" controlId="vehicleform.fc_date">
             <Form.Label>FC Date</Form.Label>
                <Form.Control
                  type="date"
                  name="fc_date"
                  placeholder="FC Date"
                  value={formValues.fc_date}
                  onChange={onChangeDatePicker}
                  readOnly={isReadOnly}
                  style={{
                    border: !isReadOnly && "1px solid gray",
                  }}
                />
              </Form.Group>
              <p className="error-msg">{formErrors.fcDate}</p>
            </Col>
            <Col xs={4} md={4} sm={4} lg={4}>
            <Form.Group className="mb-3" controlId="vehicleform.reg_no">
             <Form.Label>Insurance Renewal Date</Form.Label>
                <Form.Control
                  type="date"
                  name="ins_renewal_date"
                  placeholder="Insurance Date"
                  value={formValues.ins_renewal_date}
                  onChange={onChangeDatePicker}
                  readOnly={isReadOnly}
                  style={{
                    border: !isReadOnly && "1px solid gray",
                  }}
                />
              </Form.Group>
              <p className="error-msg">{formErrors.ins_renewal_date}</p>
            </Col>
          </Row>
          {!isReadOnly && (
            <Row>
              <Col
                xs={12}
                md={12}
                sm={12}
                lh={12}
                style={{ disply: "flex", justifyContent: "right" }}
              >
                <div className="d-flex justify-content-end">
                  <Button
                    variant="secondary"
                    id="Reset"
                    onClick={(e) => handleReset(e)}
                  >
                    Clear
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    type="submit"
                    id="save"
                    onClick={(e) => handleSave(e)}
                    disabled={submitAction ? true: false}
                  >
                     {submitAction && 
                      <>
                      <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      />
                      <span className="visually-hidden">Loading...</span>
                    </>
                    }  
                   Save
                   
                  </Button>
                </div>
                </Col>
            </Row>
          )}
          
          <Modal
              show={showDialog}

              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>{showMsgTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{showMsg}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handlePostSaveOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            </div>
            </Form>
            )
        :
        <div className="container-spinner">
        <div className="vertical-center-spinner">
         {/*<Button variant="primary" disabled>*/}
         <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
            <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{ width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
                <span className="visually-hidden">Loading...</span>
              </Spinner>
   
         {/*</Button>*/}
         </div>
         </div>
      }  
      </Container>
    </>
  );
}

export default AddUpdateVehicle;
