import React, { useState, useEffect, useRef, useSyncExternalStore } from "react";
import { Container, Row, Col, Card, Form, Button, View, Modal, Spinner, Image } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
import Sidebar2 from "./Sidebar2";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
//import ListGroup from "react-bootstrap/ListGroup";
//import Badge from "react-bootstrap/Badge";
//import DateTimeField from "react-bootstrap-datetimepicker"
//import DateTimePicker from "react-datetime-picker";

import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client, appGlobal } from "../config/Config";
import axios from "axios";

import { useLocation } from "react-router-dom";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import { BsFillPenFill, BsFillTrashFill,BsPencilSquare,BsArrowLeftCircle, BsFillPrinterFill } from "react-icons/bs";

import DateTimePicker from "react-datetime-picker";

import MenuSettings from "./MenuSettings";
import SidebarMain from "./SidebarMain";

import { formateTime, getDistrictList, getStateList, formatDate, formatDateIndian } from "./utils";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';

import { CiEdit } from "react-icons/ci";
import { BiSolidEditAlt } from "react-icons/bi";
import Badge from "react-bootstrap/Badge";

const AddUpdateDeliverySummary = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  //console.log("PASSED STATE VALUE IN Patient Add/Update page : ", state);



  let initialItems = [];

  
/*
  const optionsState = [
    { value: 1, label: "Tamil Nadu" },
    { value: 2, label: "Kerala" },
    { value: 3, label: "Karanataka" },
    { value: 4, label: "Andra Pradesh" },
    { value: 5, label: "Telungana" },
    { value: 6, label: "Pondichery" },
  ];
  */
  

  const intialValues = {
    
    delivery_date: formatDate(new Date()),
    client: "",
    grade: "",
    dc: "",
    location: "",
    unit_price: 0.00,
    total_volume: 0.00,
    product_price: 0.00,
    pumping_charges: 0.00,
    diesel_charges: 0.00,
    others_charges1: 0.00,
    others_charges2: 0.00,
    others_charges3: 0.00,
    total_amount: 0.00,
    returned_volume: 0.00,
    savings_volume: 0.00,
    returned_volume_lessamount: 0.00,
    savings_volume_lessamount: 0.00,
    returned_volume_new: 0.00,
    savings_volume_new: 0.00,
    amount_returned: 0.00,
    amount_savings: 0.00,
    
    customer_id: 0,
    customer_name: "",
    address_line1: "",
    product_id: 0,
    product_name: "",
    total_dcs: 0,
    net_amount: 0.00,
    returned_volume_modal: 0.00,
    returned_amount_modal: 0.00,
    returned_volume_modal_err: "",
    savings_volume_modal: 0.00,
    savings_amount_modal: 0.00,
    savings_volume_modal_err: "",
    returned_amount_modal_err: "",
    savings_amount_modal_err: "",
    dcprint: "ONLY",
    quantity: 0.00,
    total_quantity: 0.00,
    return_quantity: 0.00,
    return_quantity_amount: 0.00,
    save_quantity: 0.00,
    save_quantity_amount: 0.00
  };
  let dcArr = [
    {
      dc_no:"475",
      dc_date:"14-12-2023",
      volume: "8",
      total_volume:"8",
      unit_price: "4500",
      pumping_charges:"1000",
      diesel_charges:"2000",
      other_charges:"1500",
      total_amount:"36000",
      returned:"700",
      savings:"600"
     
    },
    {
      dc_no:"345",
      dc_date:"14-12-2023",
      volume: "9",
      total_volume:"17",
      unit_price: "4500",
      pumping_charges:"1000",
      diesel_charges:"2000",
      other_charges:"1500",
      total_amount:"40500",
      returned:"500",
      savings:"400"
      
    },
    {
      dc_no:"445",
      dc_date:"14-12-2023",
      volume: "8",
      total_volume:"25",
      unit_price: "4500",
      pumping_charges:"0",
      diesel_charges:"0",
      other_charges:"0",
      total_amount:"36000",
      returned:"800",
      savings:"900"
      
    },
    {
      dc_no:"678",
      dc_date:"14-12-2023",
      volume: "9",
      total_volume:"34",
      unit_price: "4500",
      pumping_charges:"1000",
      diesel_charges:"2000",
      other_charges:"1500",
      total_amount:"40500",
      returned:"600",
      savings:"500"
      
    },
    
  ];
  const [dcList, setDcList] = useState([]);
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [toRender, setToRender] = useState(false);
  const [validateError, setValidateError] = useState(false);
  
  var validateErr = false;

 

  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [isReadOnly, setIsReadOnly] = useState(true);
  //const [show, setShow] = useState(false);
  //const [showMsg, setShowMsg] = useState(false);
  //const [showDialog, setShowDialog] = useState(false);
  const [dcDialog, setDCDialog] = useState(false);
  const [printDialog, setPrintDialog] = useState(false);
  const [optDist, setOptDist] = useState([]);
  const [summaryList, setSummaryList] = useState([]);
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );
  const [submitAction, setSubmitAction] = useState(false);
  const [passedValue, setPassedValue] = useState();
  const [selectedId, setSelectedId] = useState(0);
  const [editAction, setEditAction] = useState(false);
  const [dcAction, setDCAction] = useState(false);
  const [printAction, setPrintAction] = useState(false);
  const[dcUpdate, setDCUpdate] = useState(false);

  const [selectedDcListRowId, setSelectedDcListRowId] = useState(-1);

  const [selectedDCRowForPrint, setSelectedDCRowForPrint] = useState(-1);
  //const [disableState, setDisableState] = useState(true);

  const [dialogResStatus, setDialogResStatus] = useState("success");


  const [showAlert, setShowAlert] = useState();
  const [alertPurpose, setAlertPurpose] = useState('init');
  const [alertTitle, setAlertTitle] = useState('Success');
  const [alertBody, setAlertBody] = useState('Summary Saved Successfully');

  const handleOnChange = async(e) => {
    //e.preventDefault();
    console.log("handleOnChange : ", e);

    console.log("Button clicked : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value); 
    
    if (name === "unit_price" || name === "pumping_charges" || name === "diesel_charges" || name === "others_charges1" || name === "return_quantity" || name === "return_quantity_amount" || name === "save_quantity" || name === "save_quantity_amount") 
    {
      console.log("Inside check ******************");
      //const numericRegExp = /^(?:[1-9]\d*|\d)$/;  
      if (e.target.value.match(appGlobal.numericRegExp)) {
        //setFormValues({ ...formValues, [name]: e.target.value });

        //var total_quantity_tmp = 0.00;
        //total_quantity_tmp = parseFloat(formValues["total_quantity"]) - ( parseFloat(formValues["return_quantity"]) + parseFloat(formValues["save_quantity"]));
        var product_price_tmp = 0.00;
        var total_amount_tmp = 0.00;
        var net_amount_tmp = 0.00;
        if ( name === 'unit_price' ) {
          console.log("Unit Price : ", e.currentTarget.value);
          if ( isNaN(parseFloat(e.currentTarget.value)) === false ) {
            console.log("INSIDE CONDITION BEFORE CHECK PRODUCT AND TOTAL PRICE");
            product_price_tmp = parseFloat(e.currentTarget.value) * parseFloat(formValues["total_quantity"]);
            console.log("CALCULATED PRODUCT PRICE FOR UNIT PRICE CHANGE : ", product_price_tmp);
            total_amount_tmp = product_price_tmp + parseFloat(formValues["pumping_charges"]) + parseFloat(formValues["diesel_charges"]) + parseFloat(formValues["others_charges1"]);
            console.log("CALCULATED TOTAL AMOUNT FOR UNIT PRICE CHANGE : ", total_amount_tmp);
            
            //net_amount_tmp = total_amount_tmp - ((formValues.return_quantity * value) + (formValues.save_quantity * value));
            net_amount_tmp = total_amount_tmp - (formValues.return_quantity_amount + formValues.save_quantity_amount);
            console.log("CALCULATED NET AMOUNT FOR UNIT PRICE CHANGE : ", net_amount_tmp);
            setFormValues({ ...formValues, unit_price: e.target.value, product_price: product_price_tmp, total_amount: total_amount_tmp, net_amount: net_amount_tmp});
            //setFormValues({ ...formValues, [name]: e.currentTarget.value});
          }
          else{
            setFormValues({ ...formValues, unit_price: e.target.value });
          }
        }
        else if ( name === 'pumping_charges' ) {
          console.log("Pumping Charges : ", e.currentTarget.value);
          if ( isNaN(parseFloat(e.currentTarget.value)) === false ) {
            console.log("INSIDE CONDITION BEFORE CHECK PRODUCT AND TOTAL PRICE at Pumping Charges");
            product_price_tmp = parseFloat(formValues["unit_price"]) * parseFloat(formValues["total_quantity"]);
            console.log("CALCULATED PRODUCT PRICE FOR PUMPING CHARGES CHANGE : ", product_price_tmp);
            total_amount_tmp = product_price_tmp + parseFloat(e.currentTarget.value) + parseFloat(formValues["diesel_charges"]) + parseFloat(formValues["others_charges1"]);
            console.log("CALCULATED TOTAL AMOUNT FOR PUMPING CHARGES CHANGE : ", total_amount_tmp);
            net_amount_tmp = total_amount_tmp - ((formValues.return_quantity * value) + (formValues.save_quantity * value));
            console.log("CALCULATED NET AMOUNT FOR PUMPING CHARGES CHANGE : ", net_amount_tmp);
            setFormValues({ ...formValues, pumping_charges: e.target.value, ["product_price"]: product_price_tmp, ["total_amount"]: total_amount_tmp, ["net_amount"]: net_amount_tmp});
            //setFormValues({ ...formValues, [name]: e.currentTarget.value});
          }
          else{
            setFormValues({ ...formValues, pumping_charges: e.target.value });
          }
        }
        else if ( name === 'diesel_charges' ) {
          console.log("Diesel Charges : ", e.currentTarget.value);
          if ( isNaN(parseFloat(e.currentTarget.value)) === false ) {
            console.log("INSIDE CONDITION BEFORE CHECK PRODUCT AND TOTAL PRICE at Diesel Charges");
            product_price_tmp = parseFloat(formValues["unit_price"]) * parseFloat(formValues["total_quantity"]);
            console.log("CALCULATED PRODUCT PRICE FOR DIESEL CHARGES CHANGE : ", product_price_tmp);
            total_amount_tmp = product_price_tmp + parseFloat(formValues["pumping_charges"]) + parseFloat(e.currentTarget.value) + parseFloat(formValues["others_charges1"]);
            console.log("CALCULATED TOTAL AMOUNT FOR DIESEL CHARGES CHANGE : ", total_amount_tmp);
            net_amount_tmp = total_amount_tmp - ((formValues.return_quantity * value) + (formValues.save_quantity * value));
            console.log("CALCULATED NET AMOUNT FOR DIESEL CHARGES CHANGE : ", net_amount_tmp);
            setFormValues({ ...formValues, diesel_charges: e.target.value, ["product_price"]: product_price_tmp, ["total_amount"]: total_amount_tmp, ["net_amount"]: net_amount_tmp});
            //setFormValues({ ...formValues, [name]: e.currentTarget.value});
          }
          else{
            setFormValues({ ...formValues, diesel_charges: e.target.value });
          }
        }
        else if ( name === 'others_charges1' ) {
          console.log("Others Charges1 : ", e.currentTarget.value);
          if ( isNaN(parseFloat(e.currentTarget.value)) === false ) {
            console.log("INSIDE CONDITION BEFORE CHECK PRODUCT AND TOTAL PRICE at Others Charges1");
            product_price_tmp = parseFloat(formValues["unit_price"]) * parseFloat(formValues["total_quantity"]);
            console.log("CALCULATED PRODUCT PRICE FOR OTHERS CHARGES1 CHANGE : ", product_price_tmp);
            total_amount_tmp = product_price_tmp + parseFloat(formValues["pumping_charges"]) + parseFloat(formValues["diesel_charges"]) + parseFloat(e.currentTarget.value);
            console.log("CALCULATED TOTAL AMOUNT FOR OTHERS CHARGES1 CHANGE : ", total_amount_tmp);
            net_amount_tmp = total_amount_tmp - ((formValues.return_quantity * value) + (formValues.save_quantity * value));
            console.log("CALCULATED NET AMOUNT FOR OTHERS CHARGES1 CHANGE : ", net_amount_tmp);
            setFormValues({ ...formValues, others_charges1: e.target.value, ["product_price"]: product_price_tmp, ["total_amount"]: total_amount_tmp, ["net_amount"]: net_amount_tmp});
            //setFormValues({ ...formValues, [name]: e.currentTarget.value});
          }
          else{
            setFormValues({ ...formValues, others_charges1: e.target.value });
          }
        }
        /*
        if ( name === 'unit_price' ) {
          console.log("Unit Price : ", formValues["unit_price"]);
          if ( isNaN(parseFloat(e.currentTarget.value)) === false ) {
            console.log("INSIDE CONDITION BEFORE CHECK PRODUCT AND TOTAL PRICE");
            product_price_tmp = parseFloat(e.target.value) * parseFloat(formValues["total_quantity"]);
            total_amount_tmp = product_price_tmp + parseFloat(formValues["pumping_charges"]) + parseFloat(formValues["diesel_charges"]) + parseFloat(formValues["others_charges"]);
            net_amount_tmp = total_amount_tmp - ((formValues.return_quantity * value) + (formValues.save_quantity * value));
            setFormValues({ ...formValues, [name]: e.target.value, ["product_price"]: product_price_tmp, ["total_amount"]: total_amount_tmp, ["net_amount"]: net_amount_tmp});
            //setFormValues({ ...formValues, [name]: e.currentTarget.value});
          }
        }
        if ( name === 'unit_price' ) {
          console.log("Unit Price : ", formValues["unit_price"]);
          if ( isNaN(parseFloat(e.currentTarget.value)) === false ) {
            console.log("INSIDE CONDITION BEFORE CHECK PRODUCT AND TOTAL PRICE");
            product_price_tmp = parseFloat(e.target.value) * parseFloat(formValues["total_quantity"]);
            total_amount_tmp = product_price_tmp + parseFloat(formValues["pumping_charges"]) + parseFloat(formValues["diesel_charges"]) + parseFloat(formValues["others_charges"]);
            net_amount_tmp = total_amount_tmp - ((formValues.return_quantity * value) + (formValues.save_quantity * value));
            setFormValues({ ...formValues, [name]: e.target.value, ["product_price"]: product_price_tmp, ["total_amount"]: total_amount_tmp, ["net_amount"]: net_amount_tmp});
            //setFormValues({ ...formValues, [name]: e.currentTarget.value});
          }
        }
        */
        else {
          setFormValues({ ...formValues, [name]: e.currentTarget.value });
        }
        
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };
  
  const [optionsState, setOptionsState] = useState([]);
  const [stateIdSelect, setStateIdSelect] = useState({});
 /*
  const handleStateSelect = async(e) => {
    console.log("handleStateSelect called ...", e);
    setStateIdSelect(e);

    // var stateIdInput = e.value;
    //var stateNameInput = e.label;
    var [retStateDist, distArrRet] = await getDistrictList(e.value);
    if ( retStateDist ){
      setDistrictIdSelect(distArrRet[0]);
      setOptDist(distArrRet);
    }
  };
  
  const [districtIdSelect, setDistrictIdSelect] = useState({});

  const handleDistrictSelect = (e, data) => {
    console.log("handleDistrictSelect called ...", e);
    setDistrictIdSelect(e);
  };
  */
   
 /*
  const  getCompany = async () => {
    var retState = false;
    var companyInfoTmp = {};
    console.log("getCompany api called ..");
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + "/core/getCompany", { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.data[0]);
        //setCompanyList(res.data.detail.data[0]);
        //setShowMsgTitle("Company Info Added Successfully");
        companyInfoTmp = res.data.detail.data[0];
        setFormValues(res.data.detail.data[0]);

        
        retState = true;
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          setShowMsg(error.response.data.detail);
          setShowMsgTitle("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
    return [retState, companyInfoTmp];
  };
  */

  const getDCListForSummary = async(summary_id_passed) => {
    console.log("PASSED SUMMARY ID : ", summary_id_passed);

    var retState = false;
    var dcListForSummary = [];
    var aIVal = localStorage.getItem("_aI");
    var headers = {...client.headers, Authorization: `Bearer ${aIVal}`, SerialKey: 'HNGD8743JESWE'};

    var reqParam  = {
      params: {
      summary_id: summary_id_passed
      }
    };
    try {
      var res = await axios.get(client.domain + `/fetchDCListForSummary`, reqParam, { headers }, { withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getAllSummaryList : ", res.data.detail);
      if (res.data.detail.status === 'SUCCESS') {
        console.log("Response message from server SummaryList : ", res.data.detail.data);
        //console.log("Response message from server SummaryList user_info : ", res.data.detail.res_data.user_info);
        //console.log("Response message from server getAllSummaryList : ", res.data.detail.res_data.dc_list);
        //console.log("Length of district array : ", res.data.detail.res_data.dc_list.length);
        //optionsDistrict = res.data.detail.res_data.district_list

        //setOrderInfoDet(res.data.detail.res_data.order_info);
        retState = true;
        if (res.data.detail.data.length > 0) {
          //setDcList(res.data.detail.data);
          dcListForSummary = res.data.detail.data;
          //setDeliveryChellan(res.data.detail.res_data.dc_list);
          //setDcList(res.data.detail.res_data.dc_list);
          //setDcListFull(res.data.detail.res_data.dc_list);
          //setProdList(res.data.detail.res_data.dc_list);
          //setOptDist(res.data.detail.res_data.district_list)
          //setDistrictIdSelect({value: res.data.detail.res_data.district_list[0].value, label: res.data.detail.res_data.district_list[0].label});
        }
        //setOrderDcFetched(true);
      }
    }
    catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === 'ERR_NETWORK') {
        console.log("Not able to reach target server please try after sometime");
      }
      else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === 'FAILED') {
          console.log('Request failed due to ', error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
    return [retState, dcListForSummary];


  }

  useEffect(() => {
    if (!initialized.current) {
      console.log("*************** useEffect::addUpdateCompany called ##########################");
      console.log("STATE PASSED ELEMENT :", state);
      if (state.action === 'view' || state.action === 'edit') {
        console.log("PASSED ELEMENT In STATE : ", state.passed_element);
        setFormValues({ ...formValues, 
          customer_name: state.passed_element.customer_name, 
          delivery_date: formatDate(new Date(state.passed_element.delivery_date)), 
          address_line1: state.passed_element.address_line1, 
          product_name: state.passed_element.product_name, 
          total_dcs: state.passed_element.total_dcs,
          total_quantity: state.passed_element.total_quantity,
          unit_price: state.passed_element.unit_price,
          product_price: state.passed_element.total_quantity * state.passed_element.unit_price,
          pumping_charges: state.passed_element.pumping_charges,
          diesel_charges: state.passed_element.diesel_charges,
          others_reason1: state.passed_element.others_reason1 + state.passed_element.others_reason2 + state.passed_element.others_reason3,
          others_charges1: state.passed_element.others_charges1,
          others_charges2: state.passed_element.others_charges2,
          others_charges3: state.passed_element.others_charges3,
          total_amount: state.passed_element.total_amount,
          return_quantity: state.passed_element.return_quantity,
          return_quantity_amount: state.passed_element.return_quantity_amount,
          save_quantity: state.passed_element.save_quantity,
          save_quantity_amount: state.passed_element.save_quantity_amount,
          net_amount: state.passed_element.net_amount
          }
        );
      }
      initialized.current = true;

      ( async() => {
      var [retState, dcListForSummary] = await getDCListForSummary(state.passed_element.id);
      if ( retState ) {
        setDcList(dcListForSummary);
      }
      setToRender(true);  
      })();
    }
  }, []);
 /*
  const callUpdateApi = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}`}

      
      var apiToCall = "/core/updateCompany";
     
      var reqParam = {
        id: formValues["id"],
        name: formValues["name"] || "",
        display_name: formValues["name"] || "",
        contact_person: formValues["contact_person"],
        contact_person_title: formValues["contact_person_title"] || "",
        gst_no: formValues["gst_no"],
        phone: formValues["phone"],
        contact_number_primary: formValues["contact_number_primary"] || "",
        contact_number_secondary: formValues["contact_number_secondary"] || "",
        email: formValues["email"] || "",
        website: formValues["website"] || "",
        address_line1: formValues["address_line1"],
        address_line2: formValues["address_line2"],
        district_id: districtIdSelect.value,
        pincode: formValues["pincode"],
      };

      
      var res = await axios.post(client.domain + apiToCall, reqParam, { headers }, { withCredentials: client.withCredentialState });
     
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        setShowMsg(res.data.detail.data.message);
        setShowMsgTitle("Success");
        setShowDialog(true);
        setSubmitAction(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle("LOGIN FAILED!");
          //setShow(true);
        }
      }
      setSubmitAction(false);
    }
  };
  */

  const handleValueChange = async (e, data) => {
    //e.preventDefault();
    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    

    if (name === "savings_amount_modal" || name === "savings_volume_modal" || name === "returned_amount_modal" || name === "returned_volume_modal") 
    {
      console.log("Inside check ******************");
      //const numericRegExp = /^(?:[1-9]\d*|\d)$/;  
       if (e.target.value.match(appGlobal.numericRegExp)) {
        var dcItem = dcList[selectedDcListRowId];
       // var dc_item_total_quantity = parseFloat(["returned_volume_modal_err"]) + parseFloat("returned_volume_modal_err");
        console.log("quantity: ", dcItem["dc_item_quantity"]);
        console.log("e.target.value: ", parseFloat(e.target.value));
        console.log("dcItem : ", dcItem);
        console.log("returned_volume_modal: ",formValues["returned_volume_modal"]);
        console.log("savings_volume_modal: ",formValues["savings_volume_modal"]);

        var sum_return_save = 0.00
        if ( name === 'returned_volume_modal' ) {
          console.log("<<<<<<<<<<<<<<< Unit Price >>>>>>>>>>> : ", formValues["unit_price"]);
          var returned_volume_amount_tmp = value * formValues["unit_price"];
          //setFormValues({ ...formValues, returned_amount_modal: returned_volume_amount_tmp});
          if ( returned_volume_amount_tmp < formValues["product_price"] ) {
            formValues["returned_amount_modal"] = returned_volume_amount_tmp;
            formValues["returned_volume_modal_err"] = "";
            if ( formValues["savings_volume_modal"] && isNaN(parseFloat(formValues["savings_volume_modal"])) === false) {
              sum_return_save = parseFloat(value) +  formValues["savings_volume_modal"];
            }
          }
          else{
            setFormValues({ ...formValues, ["returned_volume_modal_err"]: "Please update unit price" });
            return;
          }
      }
        else if ( name === 'savings_volume_modal' ) {
          var savings_volume_amount_tmp = value * formValues["unit_price"];
          if ( savings_volume_amount_tmp < formValues["product_price"] ) {
          formValues["savings_amount_modal"] = savings_volume_amount_tmp;
          formValues["savings_volume_modal_err"] = "";
            if ( formValues["returned_volume_modal"] && isNaN(parseFloat(formValues["returned_volume_modal"])) === false) {
              sum_return_save = parseFloat(formValues["returned_volume_modal"]) + parseFloat(value);
            } 
          }
          else{
            setFormValues({ ...formValues, ["savings_volume_modal_err"]: "Please update unit price" });
            return;
          }
        }
        console.log("SUM OF SAVE RETURN : ", sum_return_save);
        var cnd1 = (e.target.value) <= dcItem["dc_item_quantity"];
        console.log("CONDITION 1 : ", cnd1);
        var cnd2 = (parseFloat(dcItem["dc_item_quantity"]) >= sum_return_save);        
        console.log("CONDITION 2 : ", cnd2);
        //console.log("CONDITION 3 : ", isNaN(parseFloat(e.target.value)));

        
        //if ( (e.target.value) <= dcItem["dc_item_quantity"] &&  ((dcItem["dc_item_quantity"]) >= ((formValues["returned_volume_modal"]) + (formValues["savings_volume_modal"])))) {
        if ( !e.target.value || isNaN(parseFloat(e.target.value)) || (cnd1 && cnd2) ) {
          console.log("STATE 1001 entered ...");
          
          if ( !e.target.value || isNaN(parseFloat(e.target.value)) ) {
            if ( name === 'returned_volume_modal' ) {
              setFormValues({ ...formValues, ["returned_volume_modal"]: 0.00})
            }
            else if ( name === 'savings_volume_modal' ) {
              setFormValues({ ...formValues, ["savings_volume_modal"]: 0.00})
            }
          }
          else {
            setFormValues({ ...formValues, [name]: e.target.value, ["returned_volume_modal_err"]: "" });
          }
          
           //setFormValues({ ...formValues, [name]: e.target.value, ["returned_volume_modal_err"]: "" });
        }
        /*
        if ((e.target.value)<= dcItem["dc_item_quantity"] && ((dcItem["dc_item_quantity"]) >= ((formValues["returned_volume_modal"]) + (formValues["savings_volume_modal"])))) {
          console.log("STATE 1002 entered ...");
          setFormValues({ ...formValues, [name]: e.target.value, ["savings_volume_modal_err"]: "" });
        }
        else if (e.target.value < dcItem["return_quantity_amount"] && ((dcItem["return_quantity_amount"])>=((formValues["returned_amount_modal"]) + (formValues["savings_amount_modal"])))){
          console.log("STATE 1003 entered ...");
          setFormValues({ ...formValues, [name]: e.target.value, ["returned_amount_modal_err"]: "" });
        }
        else if (e.target.value < dcItem["save_quantity_amount"] && ((dcItem["save_quantity_amount"])>=((formValues["returned_amount_modal"]) + (formValues["savings_amount_modal"])))){
          console.log("STATE 1004 entered ...");
          setFormValues({ ...formValues, [name]: e.target.value, ["savings_amount_modal_err"]: "" });
        }
        else {
          console.log("STATE 1005 entered ...");
          setFormValues({ ...formValues, returned_volume_modal_err : "Please enter valid quantity"});
          setFormValues({ ...formValues, savings_volume_modal_err : "Please enter valid quantity"});
          setFormValues({ ...formValues, returned_amount_modal_err : "Please enter valid amount"});
          setFormValues({ ...formValues, savings_amount_modal_err : "Please enter valid amount"});
        }
        */
        /*
        if ((e.target.value)<= dcItem["dc_item_quantity"] && ((dcItem["dc_item_quantity"]) >= ((formValues["returned_volume_modal"]) + (formValues["savings_volume_modal"])))) {
          console.log("STATE 1002 entered ...");
          setFormValues({ ...formValues, [name]: e.target.value, ["savings_volume_modal_err"]: "" });
        }
        else if (e.target.value < dcItem["return_quantity_amount"]){
          console.log("STATE 1003 entered ...");
          setFormValues({ ...formValues, [name]: e.target.value, ["returned_amount_modal_err"]: "" });
        }
        else if (e.target.value < dcItem["save_quantity_amount"]){
          console.log("STATE 1004 entered ...");
          setFormValues({ ...formValues, [name]: e.target.value, ["savings_amount_modal_err"]: "" });
        }
        else {
          console.log("STATE 1005 entered ...");
          setFormValues({ ...formValues, returned_volume_modal_err : "Please enter valid quantity"});
          setFormValues({ ...formValues, savings_volume_modal_err : "Please enter valid quantity"});
          setFormValues({ ...formValues, returned_amount_modal_err : "Please enter valid amount"});
          setFormValues({ ...formValues, savings_amount_modal_err : "Please enter valid amount"});
        }
        */
        setFormValues({ ...formValues, [name]: e.target.value });
      }

    }     
    else{
    setFormValues({ ...formValues, [name]: value });
    }
    //console.log("Clicked data : ", data);
 
  /*
   var total_quantity = parseFloat(state.passed_element.return_quantity) + parseFloat(state.passed_element.save_quantity);  

    if (name === 'returned_volume_modal' && name === 'savings_volume_modal' && total_quantity < e.target.value) {
      state.passed_element.total_amount = "please enter valid quantity";
    }
  */
  };

  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;
   /*
    if (!values.name) {
      errors.name = "Company Name is required!";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.contact_person) {
      errors.contact_person = "Contact Person is required!";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.address_line2) {
      errors.address_line2 = "City / Town is required!";
      validateErr = true;
      //setValidateError(true);
    }
    if (!values.phone) {
      errors.phone = "Phone number is required!";
      validateErr = true;
      //setValidateError(true);
    }

    if (values.phone && values.phone.length != 10) {
      errors.phone = "Phone number should be 10 digits!";
      validateErr = true;
      //setValidateError(true);
    }
    
    if (!values.contact_number_primary) {
      errors.contact_number_primary = "Primary Number is required!";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.pincode) {
      errors.pincode = "Pincode is required!";
      validateErr = true;
      //setValidateError(true);
    }

    if (values.pincode && values.pincode.length != 6) {
      errors.pincode = "Pincode should be 6 digits!";
      validateErr = true;
      //setValidateError(true);
    }
    */
     
    if (values.returned_volume_modal && values.savings_volume_modal > values.total_quantity) {
      errors.returned_volume_modal = "please enter valid number";
      errors.savings_volume_modal = "please enter valid number"
       validateErr = true;
    }
  
    


    console.log("BEFORE RETURN validate errors ...", validateError);
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };
  
  const handleEdit = async (e) => {
    console.log("handle edit clicked at AddUpdateSummary : ", e);
    setIsReadOnly(false);
    //setDisableState(false);
  };
  
  const handleReset = async (e) => {
    console.log("handle reset clicked : ", e);
    navigate(0);
  };
  const handleDCEdit = async (e) => {
    //setDCAction(true);
    //setFormValues({ ...formValues, [returned_volume_modal]: 0.00})
    //setFormValues({...formValues, [returned_amount_modal]: 0.00})
    //setFormValues({...formValues, [savings_volume_modal]: 0.00})
    //setFormValues({...formValues, [savings_amount_modal]: 0.00})
    
    //setFormValues({ ...formValues, returned_volume_modal: 0.00, returned_amount_modal: 0.00, savings_volume_modal: 0.00, savings_amount_modal: 0.00});
    setDCDialog(true);
    console.log("Handle Edit clicked FULL : ", e);
    console.log("Handle Edit clicked FULL currentTarget : ", e.currentTarget);
    console.log("Handle Edit clicked FULL target : ", e.target);
    //var clickedTagId = e.currentTarget.id
    
    const { name, value } = e.currentTarget;
    var currentId = name.substring(name.lastIndexOf("-") + 1);
    setSelectedDcListRowId(currentId);

    setFormValues({ ...formValues, returned_volume_modal: dcList[currentId].return_quantity, returned_amount_modal: dcList[currentId].return_quantity_amount, savings_volume_modal: dcList[currentId].save_quantity, savings_amount_modal: dcList[currentId].save_quantity_amount, returned_volume_modal_err: "", savings_volume_modal_err: ""});
    //console.log("Captured input element name : ", name);
    //console.log("Captured input element value : ", value);
    //const newitems = items.slice();
    //newitems[currentId] = itemSelected;
    //setItems(newitems);
  };
  const handlePrint = async (e) => {
    //setPrintAction(true);
    setPrintDialog(true);
    console.log("Handle Edit clicked FULL : ", e);
    console.log("Handle Edit clicked FULL currentTarget : ", e.currentTarget);
    console.log("Handle Edit clicked FULL target : ", e.target);

    const { name, value } = e.currentTarget;
    var currentId = name.substring(name.lastIndexOf("-") + 1);
    console.log("SELECTED DC ROW ID FOR PRINT : ", currentId);
    setSelectedDCRowForPrint(currentId);
  };
  
  const handleSave = async (e) => {
    //e.preventDefault();
    console.log("Handle Click save called : ", e);
    console.log("Formvalues : ", formValues);

    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
   /*
    if (validateErr === false) {
      setSubmitAction(true);
      callUpdateApi();
    }
    */

    var dcListArr = []
    dcList.map((dcInfo) => {
      console.log("SAVE RETURN UPADTED DC INFO : ", dcInfo)
      console.log("DC ID in summary DC INFO : ", dcInfo.dc_id)
      console.log("DC ITEM ID in SUMMARY DC INFO : ", dcInfo.dc_item_id)
      console.log("RETURN QUANTITY in SUMMARY DC INFO : ", dcInfo.return_quantity)
      console.log("RETURN QUANTITY AMOUNT in SUMMARY DC INFO : ", dcInfo.return_quantity_amount)
      console.log("SAVE QUANTITY in SUMMARY DC INFO : ", dcInfo.save_quantity)
      console.log("SAVE QUANTITY AMOUNT in SUMMARY DC INFO : ", dcInfo.save_quantity_amount)

      var dcUpateInfo = {dc_id: dcInfo.dc_id, dc_item_id: dcInfo.dc_item_id, 
        return_quantity: dcInfo.return_quantity, return_quantity_amount: dcInfo.return_quantity_amount, 
        save_quantity: dcInfo.save_quantity, save_quantity_amount: dcInfo.save_quantity_amount};
      dcListArr.push(dcUpateInfo);

    })
    let reqAddUpdateSummary = {
      summary_id: state.passed_element.id,
      unit_price: formValues["unit_price"],
      total_quantity: formValues["total_quantity"],
      total_amount: formValues["total_price"],
      pumping_charges: formValues["pumping_charges"],
      diesel_charges: formValues["diesel_charges"],
      others_reason1: formValues["others_reason1"],
      others_charges1: formValues["others_charges1"],
      others_charges2: formValues["others_charges2"],
      others_charges3: formValues["others_charges2"],
      return_quantity: formValues["return_quantity"],
      return_quantity_amount: formValues["return_quantity_amount"],
      save_quantity: formValues["save_quantity"],
      save_quantity_amount: formValues["save_quantity_amount"],
      net_amount: formValues["net_amount"],
      dc_save_return: dcListArr
    }

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
      setSubmitAction(true);
      var res = await axios.post(client.domain + "/updateDeliverySummary", reqAddUpdateSummary, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.res_data);
        setAlertPurpose('success');
        setAlertTitle('Success');
        setAlertBody("Summary Updated Successfully!");
        setSubmitAction(false);
        setShowAlert(true);
       
      }
      else {
          setAlertPurpose('failure');
          setAlertTitle('Failed');
          setAlertBody("Summary Update Failed!");
          setSubmitAction(false);
          setShowAlert(true);
          
        }
      } catch (error) {
        console.log("FULL ERROR : ", error);
        setAlertPurpose('failure');
        setAlertTitle('Failed');
        setAlertBody("Unable to process your request at this moment.Please try after sometime!");
        setSubmitAction(false);
        setShowAlert(true);
        
        /*
        if ( error.code === 'ERR_NETWORK' ) {
          console.log("Not able to reach target server please try after sometime");
          setShowMsg('Please try after sometime');
          setShowMsgTitle('Network Issue!');
          setShow(true);
        }
        else{
          console.log("ERROR : ", error.response.data.detail);
          if (error.response.data.detail.status === 'FAILED') {
            console.log('Request failed due to ', error.response.data.detail.res_data);
            //setShowMsg('Please Re-Login');
            //setShowMsgTitle('Invalid Session!');
            //setShow(true);
            navigate("/auth");
          }
        }
        */
      }
  };

  const handleAlertOk = () => {
    console.log("user confirmed to customer added ...");
    //console.log("user selected id for action is : ", selectedId);

    // call update API
    // updateCustomerStatus(selectedId);
    setShowAlert(false);
    //navigate("/listCustomers");
    /*
    if ( dialogResStatus === 'success' ) {
      navigate("/listDeliverySummary");
    }
    */

    if (alertPurpose === 'success') {
      navigate(-1);
      setAlertPurpose('init');
    }
    else if (alertPurpose === 'failure') {
      setAlertPurpose('init');
    }
  };

  const handleCloseDialog = () => {
    if ( alertPurpose === 'success') {
      navigate(-1);
      setAlertPurpose('init');
    }
    else  {
      setAlertPurpose('init');
    }
    setShowAlert(false);
    //navigate("/ListCustomers");
  };
  const handleDCCloseDialog = () => {
    setDCDialog(false);
    
  };

  const quantityPriceRecalculate = async(unit_price) => {
    var unit_price_to_multiply = 0;
    if ( unit_price && unit_price > 0.01 ) {
      unit_price_to_multiply = unit_price;
    }
    var newDcList = dcList.slice();
    var return_quantity_tmp = 0.00;
    var return_quantity_amount_tmp = 0.00
    var save_quantity_tmp = 0.00;
    var total_quantity_tmp = 0.00;
    var total_quantity_after_return_save = 0.00;
    var product_price_tmp = 0.00;
    var save_quantity_amount_tmp = 0.00;
    var total_amount_tmp = 0.00;
    var pumping_charges_tot_tmp = 0.00;
    var diesel_charges_tot_tmp = 0.00;
    var others_charges1_tot_tmp = 0.00;
    var others_charges2_tot_tmp = 0.00;
    var others_charges3_tot_tmp = 0.00;
    var net_amount_tmp = 0.00;

    newDcList.map((dcInd) => {
      return_quantity_tmp = return_quantity_tmp + dcInd.return_quantity;
      return_quantity_amount_tmp = return_quantity_amount_tmp + dcInd.return_quantity_amount;
      save_quantity_amount_tmp = save_quantity_amount_tmp + dcInd.save_quantity_amount;
      save_quantity_tmp = save_quantity_tmp + dcInd.save_quantity;
      total_quantity_tmp = total_quantity_tmp + dcInd.dc_item_quantity;
      pumping_charges_tot_tmp = pumping_charges_tot_tmp + dcInd.pumping_charges;
      diesel_charges_tot_tmp = diesel_charges_tot_tmp + dcInd.diesel_charges;
      others_charges1_tot_tmp = others_charges1_tot_tmp + dcInd.others_charges1;
      others_charges2_tot_tmp = others_charges2_tot_tmp + dcInd.others_charges2;
      others_charges3_tot_tmp = others_charges3_tot_tmp + dcInd.others_charges3;
    })
    if ( isNaN(parseFloat(formValues["pumping_charges"])) === false ) {
      pumping_charges_tot_tmp = parseFloat(formValues["pumping_charges"]);
    }

    if ( isNaN(parseFloat(formValues["diesel_charges"])) === false ) {
      diesel_charges_tot_tmp = parseFloat(formValues["diesel_charges"]);
    }

    if ( isNaN(parseFloat(formValues["others_charges1"])) === false ) {
      others_charges1_tot_tmp = parseFloat(formValues["others_charges1"]);
    }

    if ( isNaN(parseFloat(formValues["others_charges2"])) === false ) {
      others_charges2_tot_tmp = parseFloat(formValues["others_charges2"]);
    }

    if ( isNaN(parseFloat(formValues["others_charges3"])) === false ) {
      others_charges3_tot_tmp = parseFloat(formValues["others_charges3"]);
    }

    console.log("RETRUNED QUANTITY AMOUNT : ", return_quantity_amount_tmp);
    console.log("SAVING QUANTITY AMOUNT : ", save_quantity_amount_tmp);
    total_quantity_after_return_save = (total_quantity_tmp - (return_quantity_tmp + save_quantity_tmp));
    //product_price_tmp =  (total_quantity_after_return_save * formValues.unit_price) + (return_quantity_amount_tmp + save_quantity_amount_tmp);
    product_price_tmp =  (total_quantity_tmp * formValues.unit_price);
    total_amount_tmp = product_price_tmp + pumping_charges_tot_tmp + diesel_charges_tot_tmp + others_charges1_tot_tmp + others_charges2_tot_tmp + others_charges3_tot_tmp;
    net_amount_tmp = total_amount_tmp - ((return_quantity_tmp * unit_price_to_multiply) + (save_quantity_tmp * unit_price_to_multiply)); 
    
    console.log("<<<<<<<<<<<<<<<<<<<< quantityPriceRecalculate called ... >>>>>>>>>>>>>>>>>>>");
    setFormValues({ ...formValues, 
      return_quantity: return_quantity_tmp, 
      save_quantity: save_quantity_tmp, 
      //total_quantity: total_quantity_after_return_save,
      total_quantity: total_quantity_tmp,
      product_price: product_price_tmp,
      return_quantity_amount: return_quantity_amount_tmp,
      save_quantity_amount: save_quantity_amount_tmp,
      total_amount: total_amount_tmp,
      net_amount: net_amount_tmp
      }
    );
  }

  const handleDCSaveDialog = async() => {
    console.log("user confirmed to enable or disable ...");
    console.log("user selected id for action is : ", selectedId);
    setDCDialog(false);
    setDCUpdate(true);

    var dcItem = dcList[selectedDcListRowId];
    dcItem.return_quantity = parseFloat(formValues["returned_volume_modal"]);
    dcItem.return_quantity_amount = parseFloat(formValues["returned_amount_modal"]);

    dcItem.save_quantity = parseFloat(formValues["savings_volume_modal"]);
    dcItem.save_quantity_amount = parseFloat(formValues["savings_amount_modal"]);
    dcItem.dcUpdate = true;

    //var newItemsTmp = items.slice();
    //newItemsTmp[selectedId] = dcItem;
    //setItems(newItemsTmp);

    var newDcList = dcList.slice();
    newDcList[selectedDcListRowId] = dcItem;
    setDcList(newDcList);
    /*
    var newDcList = dcList.slice();
    var returned_quantity_tmp = 0.00;
    var return_quantity_amount_tmp = 0.00
    var save_quantity_tmp = 0.00;
    var total_quantity_tmp = 0.00;
    var total_quantity_after_return_save = 0.00;
    var product_price_tmp = 0.00;
    var save_quantity_amount_tmp = 0.00;
    newDcList[selectedDcListRowId] = dcItem;

    newDcList.map((dcInd) => {
      returned_quantity_tmp = returned_quantity_tmp + dcInd.return_quantity;
      return_quantity_amount_tmp = return_quantity_amount_tmp + dcInd.return_quantity_amount;
      save_quantity_amount_tmp = save_quantity_amount_tmp + dcInd.save_quantity_amount;
      save_quantity_tmp = save_quantity_tmp + dcInd.save_quantity;
      total_quantity_tmp = total_quantity_tmp + dcInd.dc_item_quantity; 
    })
    console.log("RETRUNED QUANTITY AMOUNT : ", return_quantity_amount_tmp);
    console.log("SAVING QUANTITY AMOUNT : ", save_quantity_amount_tmp);
    total_quantity_after_return_save = (total_quantity_tmp - (returned_quantity_tmp + save_quantity_tmp));
    product_price_tmp =  (total_quantity_after_return_save * formValues.unit_price) + (return_quantity_amount_tmp + save_quantity_amount_tmp);
    

    setFormValues({ ...formValues, 
      return_quantity: returned_quantity_tmp, 
      save_quantity: save_quantity_tmp, 
      total_quantity: total_quantity_after_return_save,
      product_price: product_price_tmp,
      return_quantity_amount: return_quantity_amount_tmp,
      save_quantity_amount: save_quantity_amount_tmp
      }
    );
    */
    if ( isNaN(parseFloat(formValues["unit_price"])) === false && parseFloat(formValues["unit_price"]) > 0.01 ) {
      console.log("AMOUNT RECALCULATED ...");
      await quantityPriceRecalculate(parseFloat(formValues["unit_price"]));
    }
  };
  const handlePrintCloseDialog = () => {
    setPrintDialog(false);
    
  };
  
  const handlePrintSaveDialog = () => {
    //console.log("user confirmed to enable or disable ...");
    //console.log("user selected id for action is : ", selectedId);

    //console.log("handlePrintDCDirect called : ", e);
    //console.log("handlePrintDCDirect called : ", e.currentTarget);
    //console.log("handlePrintDCDirect called ID : ", e.currentTarget.id);
    //console.log("handlePrintDCDirect called NAME : ", e.currentTarget.name);

    setPrintDialog(false);

    console.log("<<<<<<<<<<<< SELECTED OPTION FOR PRINT >>>>>>>>>>>>>>> : ", formValues["dcprint"]);

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(
      urlStr.lastIndexOf(client.urlDelimiter) + 1
    );
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);

    var dcIndInfo = dcList[selectedDCRowForPrint];
    console.log("SELECTED DC INFO : ", dcIndInfo);
    var dc_id = dcIndInfo.dc_id;
    
    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printDirectDC?dc_id=${dc_id}&action=${formValues["dcprint"]}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0,0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);    
  };
  const handleBack = async (e) => {
    console.log("handle Back called : ", e);
    navigate(-1);
  };

  const onChangeDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.CurrentTarget.value);
    setFormValues({ ...formValues, [e.target.name]: e.currentTarget.value });
  };
  const handlePrintFullDC = async(e) => {
    console.log("Handle print order clicked : ", e);
    //passedValue.order_id

    //e.preventDefault();
    console.log("Handle action print Clicked : ", e);

    console.log("CALLED CURRENT PAGE OrderDetails PRINT DOCUMENT : ", window.location.href);

    console.log("CALLED window locaion OrderDetails PRINT DOCUMENT : ", window.location);
    //console.log("ORDER ID : ", orderId);
    //console.log("DC ID : ", dcId);

    //const navigate = useNavigate();

    //navigate("/printDC");
    //navigate("/printDocument", {state:{orderId: orderId, dcId: dcId}}, { replace: true });

    //navigate("/renderInWindow", {state:{orderId: orderId, dcId: dcId}}, { replace: true });
    console.log("LOCATION PATH DIRECTORY PRINT DOCUMENT : ", __dirname);

    //navigate("/newWindow", {state:{orderId: orderId, dcId: dcId}}, { replace: true });

    //window.open(`http://localhost:3000/#/printDocument?order_id=${orderId}&dc_id=${dcId}`);
    //window.open(`http://${window.location.host}/#/printDocument?order_id=${orderId}&dc_id=${dcId}`);

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);

    //window.open(`${urlTmp}${client.urlDelimiter}/printFullOrder?order_id=${passedValue.order_id}`);
    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printFullOrder?order_id=${passedValue.order_id}`, "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0,0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
  }
  const handlePrintDC = async(e) => {
    //passedValue.order_id

    /*
    //e.preventDefault();
    console.log("Handle action print Clicked : ", e);

    console.log("CALLED CURRENT PAGE OrderDetails PRINT DOCUMENT : ", window.location.href);

    console.log("CALLED window locaion OrderDetails PRINT DOCUMENT : ", window.location);
    //console.log("ORDER ID : ", orderId);
    //console.log("DC ID : ", dcId);

    //const navigate = useNavigate();

    //navigate("/printDC");
    //navigate("/printDocument", {state:{orderId: orderId, dcId: dcId}}, { replace: true });

    //navigate("/renderInWindow", {state:{orderId: orderId, dcId: dcId}}, { replace: true });
    console.log("LOCATION PATH DIRECTORY PRINT DOCUMENT : ", __dirname);

    //navigate("/newWindow", {state:{orderId: orderId, dcId: dcId}}, { replace: true });

    //window.open(`http://localhost:3000/#/printDocument?order_id=${orderId}&dc_id=${dcId}`);
    //window.open(`http://${window.location.host}/#/printDocument?order_id=${orderId}&dc_id=${dcId}`);

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);

    //window.open(`${urlTmp}${client.urlDelimiter}/printFullOrder?order_id=${passedValue.order_id}`);
    var order_id = 0;
    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printFullOrder?order_id=${order_id}`, "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0,0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
    */
    console.log("handlePrintDCDirect called : ", e);
    console.log("handlePrintDCDirect called : ", e.currentTarget);
    console.log("handlePrintDCDirect called ID : ", e.currentTarget.id);
    console.log("handlePrintDCDirect called NAME : ", e.currentTarget.name);

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(
      urlStr.lastIndexOf(client.urlDelimiter) + 1
    );
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);

    var dcIndInfo = dcList[selectedDCRowForPrint];
    console.log("SELECTED DC INFO : ", dcIndInfo);
    var dc_id = dcIndInfo.dc_id;
    
    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printDirectDC?dc_id=${dc_id}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0,0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
  };

  const newItemHandle = async (event) => {
    //event.preventDefault();
    console.log("New item addition button clicked : ", event);
    console.log("New item addition button clicked target :", event.target);
    console.log("New item addition button clicked currentTarget : ", event.currentTarget);

    var selectedItem = items[selectedId];
    let itemNewDc = {
      returned_volume_new: "",
      amount_returned: "",
      savings_volume_new: "",
      amount_savings: "",
    };
   
    selectedItem.dc.push(itemNewDc);
    const itemsNew = items.slice();
    itemsNew[selectedId] = selectedItem;
    console.log("modified  array : ", selectedItem);
    console.log("item array : ", itemsNew);
    setItems(itemsNew);
  };

  const handleRadioChange = async (e) => {
    console.log("Value button clicked : ", e);

    const { name, id } = e.currentTarget;
    console.log("Captured value name : ", name);
    console.log("Captured value id : ", id);

    var currentRowName = id.substring(id.lastIndexOf("-") + 1);
    var nameOfElement = id.substring(0, id.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current row id : ", currentRowName);
    setFormValues({...formValues, ["dcprint"]: currentRowName.toUpperCase()});
    
  };
  

  return (
    <>
      <Container>
        <SidebarMain />
        { toRender ?
        (
        <Form>
          <Row>
          <Col xs={4} md={4} sm={4} lg={4}>  
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>Delivery Summary</span>
            </Col>
            <Col
              xs={8}
              md={8}
              sm={8}
              lg={8}
            >
              <div className="d-flex justify-content-end">
              <Button key={`back-btn`} variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                  <BsArrowLeftCircle
                    key={`back-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                   
                    name={`back-icon-patient-name`}
                    id={`edit-icon-patient-id`}
                  />
                </Button>
                &nbsp;&nbsp;
              <Button variant="primary" className="btn btn-primary btn-md"
              key={`edit-btn-company-key`}
              style={{ cursor: "pointer" }}
              onClick={handleEdit}
              name={`edit-icon-company-name`}
              id={`edit-icon-company-id`}>
                  <BsPencilSquare
                    size={24}
                  />
                </Button>
                </div>
                </Col> 
          </Row>
          <div className="ui-divider"></div>
          <div className="ui-form">
          <hr align="center" />
            <Row>
            <Col xs={2} md={2} sm={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label className="field">Delivery Date</Form.Label>
                  {/*
                  <Form.Control
                    type="text"
                    id="date-id"
                    name="date"
                    placeholder="Delivery Date"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["date"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                  */}
                  <Form.Control
                  type="date"
                  name="delivery_date"
                  disabled="true"
                  placeholder="Delivery Date"
                  value={formValues.delivery_date}
                  onChange={onChangeDatePicker}
                  //readOnly={isReadOnly}
                  
                />
                </Form.Group>
                <p className="error-msg">{formErrors.date}</p>
              </Col>
              
              
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label >Customer</Form.Label>
                  <Form.Control
                    type="text"
                    id="client-id"
                    name="customer_name"
                    disabled="true"
                    placeholder="Customer Name"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["customer_name"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.client}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label >
                    Site
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="address_line1"
                    name="address_line1"
                    disabled="true"
                    placeholder="Site"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["address_line1"]}
                    maxLength={10}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.location}</p>
              </Col>
             
            
            <Col xs={2} md={2} sm={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Grade
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="product_name"
                    name="product_name"
                    disabled="true"
                    placeholder="Grade"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["product_name"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.grade}</p>
              </Col> 
            <Col xs={2} md={2} sm={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label className="field">
                    No.Of DCs
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="total_dcs"
                    name="total_dcs"
                    disabled="true"
                    placeholder="No.Of DCs"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["total_dcs"]}
                    style={{border: !isReadOnly && "1px solid gray", textAlign: "right"}}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.dc}</p>
              </Col>
              </Row>
              
            <Row>
           
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group
                  className="mb-3"
                >
                  <Form.Label>
                  Total Volume(M<sup>3</sup>)
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="total_quantity"
                    name="total_quantity"
                    disabled="true"
                    //className="mobNum"
                    placeholder="Total Volume"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    maxLength={10}
                    value={formValues["total_quantity"].toFixed(2)}
                    style={{border: !isReadOnly && "1px solid gray", textAlign: "right"}}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.total_volume}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="required">Unit Price</Form.Label>
                  <Form.Control
                    type="text"
                    id="unit_price"
                    name="unit_price"
                    //className="mobNum"
                    placeholder="Unit Price"
                    onChange={handleOnChange}
                    readOnly={isReadOnly}
                    maxLength={10}
                    value={formValues["unit_price"]}
                    style={{border: !isReadOnly && "1px solid gray", textAlign: "right"}}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.unit_price}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Product Price</Form.Label>
                  <Form.Control
                    type="text"
                    id="product_price-id"
                    name="product_price"
                    disabled="true"
                    //className="mobNum"
                    placeholder="Product Price"
                    onChange={handleOnChange}
                    readOnly={isReadOnly}
                    maxLength={10}
                    value={formValues["product_price"].toFixed(2)}
                    style={{border: !isReadOnly && "1px solid gray", textAlign: "right"}}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.product_price}</p>
              </Col>
              
            </Row>
           
            <Row>
            <Col xs={2} md={2} sm={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>Pumping Charges </Form.Label>
                  <Form.Control
                    type="text"
                    id="pumping_charges-id"
                    name="pumping_charges"
                    placeholder="Pumping Charges"
                    maxLength={10}
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["pumping_charges"]}
                    style={{border: !isReadOnly && "1px solid gray", textAlign: "right"}}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.pumping_charges}</p>
              </Col>
              <Col xs={2} md={2} sm={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>Diesel Charges </Form.Label>
                  <Form.Control
                    type="text"
                    id="diesel_charges-id"
                    name="diesel_charges"
                    placeholder="Diesel Charges"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["diesel_charges"]}
                    style={{border: !isReadOnly && "1px solid gray", textAlign: "right"}}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.diesel_charges}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Others Reason</Form.Label>
                  <Form.Control
                    type="text"
                    id="others_reason1"
                    name="others_reason1"
                    placeholder="Reason"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["others_reason1"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.others}</p>
              </Col>
              <Col xs={2} md={2} sm={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>Other Charges</Form.Label>
                  <Form.Control
                    type="text"
                    id="others_charges1"
                    name="others_charges1"
                    placeholder="Other Charges"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["others_charges1"]}
                    style={{border: !isReadOnly && "1px solid gray", textAlign: "right"}}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.other_charges}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Total Amount</Form.Label>
                  <Form.Control
                    type="text"
                    id="total_amount"
                    name="total_amount"
                    disabled="true"
                    placeholder="Total Amount"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["total_amount"].toFixed(2)}
                    style={{border: !isReadOnly && "1px solid gray", textAlign: "right"}}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.total_amount}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={2} md={2} sm={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label className="field">
                    Returned Volume(M<sup>3</sup>)
                  </Form.Label>
                  <Form.Control
                    disabled="true"
                    type="text"
                    id="return_quantity"
                    name="return_quantity"
                    placeholder="Returned Volume"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["return_quantity"].toFixed(2)}
                    style={{border: !isReadOnly && "1px solid gray", textAlign: "right"}}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.return_quantity}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field">
                    Returned Volume - Less Amount
                  </Form.Label>
                  <Form.Control
                    disabled="true"
                    type="text"
                    id="return_quantity_amount"
                    name="return_quantity_amount"
                    placeholder="Returned Volume Amount"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["return_quantity_amount"].toFixed(2)}
                    style={{border: !isReadOnly && "1px solid gray", textAlign: "right"}}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.return_quantity_amount}</p>
              </Col>
              <Col xs={2} md={2} sm={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label className="field">
                    Savings Volume(M<sup>3</sup>)
                  </Form.Label>
                  <Form.Control
                    disabled="true"
                    type="text"
                    id="save_quantity"
                    name="save_quantity"
                    placeholder="Savings Volume"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["save_quantity"].toFixed(2)}
                    style={{border: !isReadOnly && "1px solid gray", textAlign: "right"}}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.save_quantity}</p>
              </Col>
              <Col xs={2} md={2} sm={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label className="field">
                    Savings Volume Amount
                  </Form.Label>
                  <Form.Control
                    disabled="true"
                    type="text"
                    id="save_quantity_amount"
                    name="save_quantity_amount"
                    placeholder="Savings Volume Amount"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["save_quantity_amount"].toFixed(2)}
                    style={{border: !isReadOnly && "1px solid gray", textAlign: "right"}}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.save_quantity_amount}</p>
              </Col>
              <Col xs={2} md={2} sm={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label className="field">
                    Net Amount
                  </Form.Label>
                  <Form.Control
                    disabled="true"
                    type="text"
                    id="net_amount"
                    name="net_amount"
                    placeholder="Net Amount"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["net_amount"].toFixed(2)}
                    style={{border: !isReadOnly && "1px solid gray", textAlign: "right"}}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.net_amount}</p>
              </Col>
            </Row>
            <Row>
        
        <Col xs={2} md={2} sm={2} lg={2}> 
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem" }}>Delivery Challans</span>
        </Col>
        </Row>
        
            <Table striped bordered hover size="sm">
          <thead>
            <tr className="table-primary">
              <th className="table-row-heading" key={"order-list-heading-key"}>
                S.No
              </th>
              <th className="table-row-heading">DC No</th>
              <th className="table-row-heading">DC Date</th>
              <th className="table-row-heading">Volume(M<sup>3</sup>)</th>
              <th className="table-row-heading">Total Vol.(M<sup>3</sup>)</th>
              <th className="table-row-heading">Unit Price</th>
              <th className="table-row-heading">Extra Charges</th>
              <th className="table-row-heading">Product Price</th>
              <th className="table-row-heading">Total Price</th>
              <th className="table-row-heading">Rtn Vol.(M<sup>3</sup>)</th>
              <th className="table-row-heading">Save.(M<sup>3</sup>)</th>
              <th className="table-row-heading">...</th>
              <th className="table-row-heading">Print</th>
             
            </tr>
          </thead>

          <tbody>
            {dcList.map((dcInd, idx) => (
              <tr key={`dc-table-row-key-${idx}`}>
                <td
                  key={`dc-table-row-key-sno-${idx}`}
                  id={`dc-table-row-id-sno-${idx}`}
                  name={`dc-table-row-name-sno-${idx}`}
                >
                  {idx + 1}
                </td>
                <td
                  key={`dc-table-row-key-dcno-${idx}`}
                  id={`dc-table-row-id-dcno-${idx}`}
                  name={`dc-table-row-name-dcno-${idx}`}
                >
                  {dcInd.dc_number}
                </td>
                
                <td
                  key={`dc-table-row-key-date-${idx}`}
                  id={`dc-table-row-id-date-${idx}`}
                  name={`dc-table-row-name-date-${idx}`}
                >
                  {formatDateIndian(new Date(dcInd.dc_date))}
                  {/*{formatDateIndian(new Date(dcInd.dc_date))}*/}
                </td>
               
                <td
                  key={`dc-table-row-key-volume-${idx}`}
                  id={`dc-table-row-id-volume-${idx}`}
                  name={`dc-table-row-name-volume-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(dcInd.dc_item_quantity).toFixed(2)))}
                  
                </td>
               
                
                <td
                  key={`dc-table-row-key-totalvolume-${idx}`}
                  id={`dc-table-row-id-totalvolume-${idx}`}
                  name={`dc-table-row-name-totalvolume-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(dcInd.dc_item_total_quantity).toFixed(2)))}
                
                </td>
                
                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`dc-table-row-key-unitprice-${idx}`}
                  id={`dc-table-row-id-unitprice-${idx}`}
                  name={`dc-table-row-name-unitprice-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(dcInd.unit_price).toFixed(2)))}
                </td>
                
                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`dc-table-row-key-pumpingcharges-${idx}`}
                  id={`dc-table-row-id-pumpingcharges-${idx}`}
                  name={`dc-table-row-name-pumpingcharges-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format((parseFloat(dcInd.pumping_charges) + parseFloat(dcInd.diesel_charges) + parseFloat(dcInd.others_charges1)).toFixed(2)))}
                  {/*{parseFloat(dcInd.pumping_charges) + parseFloat(dcInd.diesel_charges) + parseFloat(dcInd.others_charges1)}*/}
                </td>
                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`dc-table-row-key-product_price-${idx}`}
                  id={`dc-table-row-id-product_price-${idx}`}
                  name={`dc-table-row-name-product_price-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat((dcInd.dc_item_total_quantity - (dcInd.return_quantity + dcInd.save_quantity)) * dcInd.unit_price).toFixed(2)))}
                
                </td>

                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`dc-table-row-key-total_amount-${idx}`}
                  id={`dc-table-row-id-total_amount-${idx}`}
                  name={`dc-table-row-name-total_amount-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat((((dcInd.dc_item_total_quantity - (dcInd.return_quantity + dcInd.save_quantity)) * dcInd.unit_price) + parseFloat(dcInd.pumping_charges) + parseFloat(dcInd.diesel_charges) + parseFloat(dcInd.others_charges1))).toFixed(2)))}
                
                </td>


                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`dc-table-row-key-returned-${idx}`}
                  id={`dc-table-row-id-returned-${idx}`}
                  name={`dc-table-row-name-returned-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(dcInd.return_quantity).toFixed(2)))}
                </td>
                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`dc-table-row-key-savings-${idx}`}
                  id={`dc-table-row-id-savings-${idx}`}
                  name={`dc-table-row-name-savings-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(dcInd.save_quantity).toFixed(2)))}
                </td>
                <td>
                <Button variant="primary" className="btn btn-primary btn-md"
               key={`edit-btn-company-key`}
              style={{ cursor: "pointer" }}
              onClick={handleDCEdit}
              name={`edit-icon-delivery-summary-dc-name-${idx}`}
              id={`edit-icon-delivery_summary_dc-id-${idx}`}
              disabled={isReadOnly ? true : false}>
             
                  <BiSolidEditAlt
                    size={24}
                    
                  />
                </Button>
                </td>
                <td>
                <Button id={`dc-print-btn-id-${idx}`} name = {`dc-print-btn-name-${idx}`}  onClick={(e)  => handlePrint(e)}>
                <BsFillPrinterFill
                  size={18}
                  style={{ color: "white", cursor: "pointer" }}
                  name={`print-order-dc-icon-${idx}`}
                  id={`print-order-dc-icon-${idx}`}
                  
                />
                  </Button>
                </td>
                
               
              </tr>
            ))}
          </tbody>
        </Table>
        
            
            {!isReadOnly && (
            <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ disply: "flex", justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleReset(e)}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      //type="submit"
                      id="save"
                      onClick={(e) => handleSave(e)}
                      disabled={submitAction ? true: false}
                    >
                      {submitAction && 
                      <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    <span className="visually-hidden">Loading...</span>
                    </>  
                    }
                    Update
                      
                    </Button>
                  </div>
                </Col>  
              </Row>
            )} 
             <Modal
              show={showAlert}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>{alertTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{alertBody}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handleAlertOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal> 
          </div>
        </Form>
       )
       :
       <div className="container-spinner">
       <div className="vertical-center-spinner">
        {/*<Button variant="primary" disabled>*/}
        <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
           <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
               <span className="visually-hidden">Loading...</span>
             </Spinner>
  
        {/*</Button>*/}
        </div>
        </div>
     }
      </Container>
      
      <Modal
        show={dcDialog}
        backdrop="static"
        keyboard={false}  
      >
        <Modal.Header>
          <Modal.Title>
          <Badge pill bg="secondary">
                            
            {selectedDcListRowId >= 0 ? dcList[selectedDcListRowId].dc_number : ""}
            </Badge>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Row>
                      <Col xs={2} md={2} sm={2} lg={2}>
                      <Form.Label>Rtn.Vol(M<sup>3</sup>)</Form.Label>
                      </Col>
                      <Col xs={4} md={4} sm={4} lg={4}>
                      <Form.Control
                        type="text"
                        name="returned_volume_modal"
                        id="returned_volume_modal"
                        placeholder="Returned Volume"
                        value={formValues.returned_volume_modal}
                        onChange={handleValueChange}
                        maxLength={6}
                        style={{
                          border: !isReadOnly && "1px solid gray",
                        }}
                      />
                      <p className="error-msg">{formValues.returned_volume_modal_err}</p>
                      </Col>
                      
                      <Col xs={2} md={2} sm={2} lg={2} >
                      <Form.Label>Amount</Form.Label>
                      </Col>
                      <Col xs={4} md={4} sm={4} lg={4}>
                      <Form.Control
                        type="text"
                        name="returned_amount_modal"
                        placeholder="Returned Amount"
                        value={formValues.returned_amount_modal}
                        onChange={handleValueChange}
                        maxLength={10}
                        //disabled={true}
                        style={{
                          border: !isReadOnly && "1px solid gray",
                        }}
                      />
                      <p className="error-msg">{formValues.returned_amount_modal_err}</p>
                      </Col>
                      </Row>
                      <p></p>
                      <Row>
                      <Col xs={2} md={2} sm={2} lg={2} >
                      <Form.Label>Save.Vol(M<sup>3</sup>)</Form.Label>
                      </Col>
                      <Col xs={4} md={4} sm={4} lg={4}>
                      <Form.Control
                        type="text"
                        name="savings_volume_modal"
                        placeholder="Savings Volume"
                        value={formValues.savings_volume_modal}
                        onChange={handleValueChange}
                        maxLength={6}
                        style={{
                          border: !isReadOnly && "1px solid gray",
                        }}
                      />
                      <p className="error-msg">{formValues.savings_volume_modal_err}</p>
                      </Col>
                      <Col xs={2} md={2} sm={2} lg={2} >
                      <Form.Label>Amount</Form.Label>
                      </Col>
                      <Col xs={4} md={4} sm={4} lg={4}>
                      <Form.Control
                        type="text"
                        name="savings_amount_modal"
                        placeholder="Amount"
                        value={formValues.savings_amount_modal}
                        onChange={handleValueChange}
                        maxLength={10}
                        style={{
                          border: !isReadOnly && "1px solid gray",
                        }}
                      />
                      <p className="error-msg">{formValues.savings_amount_modal_err}</p>
                      </Col>
                      </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDCCloseDialog}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleDCSaveDialog}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      

      <Modal
              show={printDialog}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>Print Options</Modal.Title>
              </Modal.Header>
              <Modal.Body><Row>
              <Col xs={6} md={6} sm={6} lg={6} >
              {/*
              <Button
                      variant="primary"
                      id="print_savings"
                      
                      onClick={(e) => handlePrintFullDC(e)}
                    >
                      Print With Returned & Savings
                    </Button>
                    */}
                    <Form.Check
            inline
            label="Without Return & Save"
            name="dcprint"
            type="radio"
            id={`dc-print-radio-only`}
            onChange={(e) => handleRadioChange(e)}
            checked={ formValues["dcprint"] === 'ONLY' ? true : false}
          />
                    </Col>
                   
                    <Col xs={6} md={6} sm={6} lg={6} >
                    {/*
                    <Button
                      variant="primary"
                      id="print_dc"
                      
                      onClick={(e) => handlePrintDC(e)}
                    >
                      Print DC Only
                    </Button>
                    */}
                    <Form.Check
            inline
            label="With Return & save"
            name="dcprint"
            type="radio"
            id={`dc-print-radio-returnsave`}
            onChange={(e) => handleRadioChange(e)}
            checked={ formValues["dcprint"] === 'RETURNSAVE' ? true : false}
          />
                    </Col>
                   
                </Row>
                </Modal.Body>
              <Modal.Footer>
              <Button variant="secondary" onClick={handlePrintCloseDialog}>
            Cancel
          </Button>
                <Button variant="primary" onClick={handlePrintSaveDialog}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal> 
    </>
  );
};

export default AddUpdateDeliverySummary;
