import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Form, Button, View, Image,Spinner } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
import Sidebar2 from "./Sidebar2";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
//import ListGroup from "react-bootstrap/ListGroup";
//import Badge from "react-bootstrap/Badge";
//import DateTimeField from "react-bootstrap-datetimepicker"
//import DateTimePicker from "react-datetime-picker";

import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client } from "../config/Config";
import axios from "axios";

import { useLocation } from "react-router-dom";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import { BsFillPenFill, BsFillTrashFill } from "react-icons/bs";

import DateTimePicker from "react-datetime-picker";

import MenuEms from "./MenuEms";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';
import SidebarMain from "./SidebarMain";

const AddUpdateEmpAttendance = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  //console.log("PASSED STATE VALUE IN Patient Add/Update page : ", state);

  /*
  if (state === null) {
    navigate("/");
  }


  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::ListUsers called ##########################"
      );

      initialized.current = true;
    }
  }, []);
  */
 
  var workTimeArr = [
    { value: 1, label: "Full Time", name: "full" },
    { value: 2, label: "Part Time", name: "part" },
    { value: 3, label: "Leave", name: "leave" },
    { value: 4, label: "Absence", name: "absence" },
  ];
  var empAttendanceArr = [
    {
      id: 1,
      empId: "23AVS10001",
      empName: "Raya A",
      empDesignation: "Head of Engineering",
      workTimeId: { value: 1, label: "Full Time" },
      notes: "Full time worked",
    },
    {
      id: 2,
      empId: "23AVS10002",
      empName: "Ilango",
      empDesignation: "Head of Business",
      workTimeId: { value: 2, label: "Part Time" },
      notes: "Part time worked",
    },
    {
      id: 3,
      empId: "23AVS10002",
      empName: "Celciya",
      empDesignation: "Software Engineer - Trainee",
      workTimeId: { value: 3, label: "Leave Time" },
      notes: "Full time worked",
    },
  ];
  const [empAttendance, setEmpAttendance] = useState(empAttendanceArr);

  let initialItems = [];

  const intialValues = {
    attendancefor: new Date(),
    Note: "",
  };

  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [validateError, setValidateError] = useState(false);

  const [roleIdSelect, setRoleIdSelect] = useState({});
  var validateErr = false;

  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [toRender, setToRender] = useState(false);

  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::addUpdateEmployeeAttendance called ##########################"
      );
      console.log("PASSED STATE VALUE IN Employee Add/Update page : ", state);
      setToRender(true);
      initialized.current = true;
    }
  }, []);

  const handleValueChange = async (e, data) => {
    e.preventDefault();
    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    setFormValues({ ...formValues, [name]: value });

    //console.log("Clicked data : ", data);
  };

  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!values.employeeName) {
      errors.employeeName = "Employee name is required";
      validateErr = true;
      setValidateError(true);
    }

    console.log("BEFORE RETURN validate errors ...", validateError);
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };
  const toSqlDatetime = (inputDate) => {
    const date = new Date(inputDate);
    const dateWithOffest = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    return dateWithOffest.toISOString().slice(0, 19).replace("T", " ");
  };

  const onChangeDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    var formattedDt = toSqlDatetime(e);

    setFormValues({ ...formValues, ["attendancefor"]: formattedDt });

    console.log("Formatted str Attendance For : ", formattedDt);
  };

  const handleSave = async (e) => {
    console.log("Handle Click save called : ", e);
  };

  const handleBack = async (e) => {
    console.log("handle back clicked : ", e);
  };

  const handleAttendanceRadio = async (e) => {
    console.log("handle Attendance clicked : ", e);
  };

  return (
    <>
      <Container>
        <SidebarMain />
        { toRender ?
        (
        <Form onSave={handleSave}>
          <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
              <h3>Attendance</h3>
            </Col>
            <Col xs={3} md={3} sm={3} lg={3}>
              <DateTimePicker
                onChange={onChangeDatePicker}
                calendarIcon={null}
                format="dd-MM-y HH:mm:ss"
                value={formValues.attendancefor}
              />
            </Col>
            {/*
            <Col xs={6} md={6} sm={6} lg={6}>
              <Button
                id="add-btn-id"
                name="add-btn-name"
                className="btn btn-success btm-sm float-end"
                onClick={(e) => handleBack(e)}
              >
                Back
              </Button>
            </Col>
          */}
          </Row>
          <br></br>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Emp. Id</th>
                <th>Emp. Name</th>
                <th>Designation</th>
                <th>Attendance</th>
                <th>Note</th>
              </tr>
            </thead>

            <tbody>
              {empAttendance.map((empAttInd, idx) => (
                <tr>
                  <td>{idx + 1}</td>
                  <td>{empAttInd.empId}</td>
                  <td>{empAttInd.empName}</td>
                  <td>{empAttInd.empDesignation}</td>
                  <td>
                    {/*
                    <Form.Check
                      type="radio"
                      id={`att-check-full-id-${idx}`}
                      name={`att-check-full-name-${idx}`}
                      label="Full Time"
                      checked={empAttInd.workTimeId.value === }
                      onChange={(e) => handleAttendanceRadio(e)}
                    ></Form.Check>
                    <Form.Check
                      type="radio"
                      id={`att-check-part-id-${idx}`}
                      name={`att-check-part-name-${idx}`}
                      label="Part Time"
                      checked={true}
                      onChange={(e) => handleAttendanceRadio(e)}
                    ></Form.Check>
                    <Form.Check
                      type="radio"
                      id={`att-check-leave-id-${idx}`}
                      name={`att-check-leave-name-${idx}`}
                      label="Leave"
                      checked={true}
                      onChange={(e) => handleAttendanceRadio(e)}
                    ></Form.Check>
                    <Form.Check
                      type="radio"
                      id={`att-check-absence-id-${idx}`}
                      name={`att-check-absence-name-${idx}`}
                      label="Absence"
                      checked={true}
                      onChange={(e) => handleAttendanceRadio(e)}
                    ></Form.Check>
              */}
                    {workTimeArr.map((workTimeInd, idx2) => (
                      <Form.Check
                        type="radio"
                        id={`att-check-${workTimeInd.name}-id-${idx}`}
                        name={`att-check-${workTimeInd.name}-name-${idx}`}
                        label={workTimeInd.label}
                        checked={
                          workTimeInd.value === empAttInd.workTimeId.value
                        }
                        onChange={(e) => handleAttendanceRadio(e)}
                      ></Form.Check>
                    ))}
                  </td>
                  <td>
                    <input
                      type="text"
                      value={empAttInd.notes}
                      id="text-notes-id-${idx}"
                      name="text-notes-name-${idx}"
                    ></input>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Row>
            <Col xs={11} md={11} sm={11} lg={11}>
              <Button
                id="reset-btn-id"
                name="reset-btn-name"
                className="btn btn-success btm-sm float-end"
                onClick={(e) => handleSave(e)}
              >
                Reset
              </Button>
            </Col>
            <Col xs={1} md={1} sm={1} lg={1}>
              <Button
                id="save-btn-id"
                name="save-btn-name"
                className="btn btn-success btm-sm float-end"
                onClick={(e) => handleSave(e)}
              >
                Save
              </Button>
            </Col>
          </Row>
          {/*
          <Row>
            <Col xs={2} md={2} sm={2} lg={2}>
              <label>Attendance for:</label>
            </Col>
            <Col xs={2} md={2} sm={2} lg={2}>
              <DateTimePicker
                onChange={onChangeDatePicker}
                calendarIcon={null}
                format="dd-MM-y HH:mm:ss"
                value={formValues.attendancefor}
              />
            </Col>
          </Row>

          <Row className="flex float-end">
            <Col xs={2} md={2} sm={2} lg={2}>
              <Button id="save" onClick={(e) => handleSave(e)}>
                Save
              </Button>
            </Col>
          </Row>

          <div className="ui-divider"></div>
          <div className="ui-form">
            <Row>
              <Col xs={2} md={2} sm={2}>
                <div className="field">
                  <label>Employee Id :</label>
                </div>
              </Col>
              <Col xs={4} md={4} sm={4}>
                <div className="field">
                  <input
                    type="text"
                    name="employeeid"
                    placeholder="Employee Id"
                    value={formValues.employeeid}
                    onChange={handleValueChange}
                  />
                </div>
                <p className="error-msg">{formErrors.employeeid}</p>
              </Col>
            </Row>

            <Row>
              <Col xs={2} md={2} sm={2}>
                <div className="field">
                  <label>Employee Name :</label>
                </div>
              </Col>
              <Col xs={4} md={4} sm={4}>
                <div className="field">
                  <input
                    type="text"
                    name="employeename"
                    placeholder="Employee Name"
                    value={formValues.employeename}
                    onChange={handleValueChange}
                  />
                </div>
                <p className="error-msg">{formErrors.employeename}</p>
              </Col>
            </Row>

            <Row>
              <Col xs={2} md={2} sm={2}>
                <div className="field">
                  <label>Designation :</label>
                </div>
              </Col>
              <Col xs={4} md={4} sm={4}>
                <div className="field">
                  <input
                    type="text"
                    name="designation"
                    placeholder="Designation"
                    value={formValues.designation}
                    onChange={handleValueChange}
                  />
                </div>
                <p className="error-msg">{formErrors.designation}</p>
              </Col>
            </Row>

            <Row>
              <Col xs={2} md={2} sm={1} lg={2}>
                <div className="field">
                  <label>Attendance: </label>
                </div>
              </Col>

              <Col xs={4} md={4} sm={4} lg={4}>
                <div className="field">
                  <div key={`inline-radio`} className="mb-3">
                    <Form.Check
                      inline
                      label="Full Day"
                      name="Attendance"
                      type="radio"
                      id={`inline-radio-full day`}
                      checked="true"
                    />
                    <Form.Check
                      inline
                      label="Off Day"
                      name="attendance"
                      type="radio"
                      id={`inline-radio-on day`}
                    />
                    <Form.Check
                      inline
                      label="On Leave"
                      name="attendance"
                      type="radio"
                      id={`inline-radio-on leave`}
                    />
                    <Form.Check
                      inline
                      label="Absent"
                      name="attendance"
                      type="radio"
                      id={`Absent`}
                    />
                  </div>
                </div>
                <p className="error-msg">{formErrors.attendance}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={2} md={2} sm={2}>
                <div className="field">
                  <label>Note :</label>
                </div>
              </Col>
              <Col xs={4} md={4} sm={4}>
                <div className="field">
                  <input
                    type="text"
                    name="note"
                    placeholder="Note"
                    value={formValues.note}
                    onChange={handleValueChange}
                  />
                </div>
                <p className="error-msg">{formErrors.note}</p>
              </Col>
            </Row>
          </div>
        */}
        </Form>
        )
        :
        <div className="container-spinner">
        <div className="vertical-center-spinner">
         {/*<Button variant="primary" disabled>*/}
         <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
            <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{ width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
                <span className="visually-hidden">Loading...</span>
              </Spinner>
   
         {/*</Button>*/}
         </div>
         </div>
      }
      </Container>
    </>
  );
};

export default AddUpdateEmpAttendance;
