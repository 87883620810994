import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

import { NumToWord } from '../../pages/utils';
import { BANK_NAME, BANK_BRANCH, ACC_NUMBER, IFSC_CODE, COMPANY_NAME } from '../../config/Config';

const styles = StyleSheet.create({
   
    titleContainer:{
        flexDirection: 'row',
        marginTop: 72
    },
    reportTitle:{
        fontSize: 12,
        flexDirection: 'row',
        textAlign: 'flex-end', 
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    },
    container2: {
        paddingBottom:0,
        flexDirection: 'row',
    },
    row4: {
      flex: 1,
      flexDirection: 'row',
      flexGrow: 1,
    },
    left4: {
      // width: '33%',//<- working alternative
      padding: 5,
      flexGrow: 0,
      flexShrink: 1,
      flexBasis: 230,
      margin: 2, 
      //borderTop: 1, 
      //borderBottom: 1, 
      //borderLeft: 1, 
      //borderRight: 1, 
      //borderColor: '#e7e5e5', 
      //BorderStyle: 'dashed',
      height: '120px',
      //border: 'solid #e7e5e5 3px',
      //padding: '1em',
      //margin: '1em',
      //borderRadius: 5
    },
  
    right4: {
      padding: 5,
      // width: '66%', //<- working alternative
      //flexShrink: 1,
      //flexShrink: 2,
      //flexGrow: 2,
      margin: 2, 
      //borderTop: 1, 
      //borderBottom: 1, 
      //borderLeft: 1, 
      //borderRight: 1, 
      //borderColor: '#e7e5e5', 
      //BorderStyle: 'double',
      //height: '120px',
      //borderRadius: 5
    }
  });

  


  const OrderThankYouMsg = (props) => {
    let total = props.items.map(item => item.prodInfo.quantity * item.prodInfo.unit_price)
        .reduce((accumulator, currentValue) => accumulator + currentValue , 0)
    console.log("Total price at InvoiceTableFooter: ", total);


    let cgst_amount = 0.00;
    if (props.invoice.order_info.bill_gst_include === true ) {
      cgst_amount = props.items.map(item => (item.prodInfo.quantity * item.prodInfo.unit_price * (item.prodInfo.cgst_perc / 100)))
        .reduce((accumulator, currentValue) => accumulator + currentValue , 0)
    }
    console.log("CGST AMOUNT : ", cgst_amount);

    let sgst_amount = 0.00;
    if (props.invoice.order_info.bill_gst_include === true ) {
      sgst_amount = props.items.map(item => (item.prodInfo.quantity * item.prodInfo.unit_price * (item.prodInfo.sgst_perc / 100)))
        .reduce((accumulator, currentValue) => accumulator + currentValue , 0)
    }
    console.log("CGST AMOUNT : ", sgst_amount);

    let igst_amount = 0.00;
    if (props.invoice.order_info.bill_gst_include === true ) {
      igst_amount = props.items.map(item => (item.prodInfo.quantity * item.prodInfo.unit_price * (item.prodInfo.igst_perc / 100)))
        .reduce((accumulator, currentValue) => accumulator + currentValue , 0)
    }
    console.log("CGST AMOUNT : ", igst_amount);

    let total_gst = cgst_amount + sgst_amount + igst_amount;
    
    total = total + props.invoice.order_info.diesel_charges + props.invoice.order_info.pumping_charges + total_gst;
    total = Math.round(total);

    console.log(NumToWord(total));

    return (
    <View>
      {/*
      <View style={styles.row}>
        <Text style={{flex: 1, margin: 5}}><Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Amount in words:</Text> {NumToWord(total)} rupees only</Text>
      </View>
      */}
      {/*
      <View style={styles.row4}>
        <View style={styles.left4}>
        <Text style={{flex: 1, fontSize: "12px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold', margin: 10}}>Bank details:</Text>
        <Text style={{margin: 10}}>Bank Name: HDFC Bank</Text>
        <Text style={{margin: 10}}>Branch : Avinashi</Text>
        <Text style={{margin: 10}}>A/C Number : 50200042592178</Text>
        <Text style={{margin: 10}}>IFSC Code: HDFC0002225</Text>
      </View>

      <View style={styles.right4}>
        <Text style={{flex: 1, textAlign: 'end', alignItems: 'end', margin: 10}}>For KMC CONCRETE</Text>
        <Text style={{textAlign: 'right', height: '32px', margin: 10}}></Text>
        <Text style={{textAlign: 'right', margin: 10}}>Authorised Signature</Text>
      </View> 
    </View>
    */}
    <View style={{ paddingTop: "5px"}}>
    <Text><Text style={{ fontSize: "10px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Notes: </Text>{props.invoice.order_info.notes}</Text>
    </View>
    <View style={{flexDirection: 'row', paddingTop: "20px"}}>
      <View style={{flex: 1}}>
     
        <Text style={{ fontSize: "12px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold', margin: 2}}>Bank details:</Text>
        <Text></Text>
        <View style={{flexDirection: 'row'}}>
        <Text style={{ margin: 2, textAlign: "left", fontSize: "11px"}}>Bank Name      : </Text>
        <Text style={{ margin: 2, fontSize: "11px"}}>{BANK_NAME}</Text>
        </View>
        <View style={{flexDirection: 'row'}}>
        <Text style={{ margin: 2, textAlign: "left", fontSize: "11px"}}>Branch             : </Text>
        <Text style={{ margin: 2, fontSize: "11px"}}>{BANK_BRANCH}</Text>
        </View>
        <View style={{flexDirection: 'row'}}>
        <Text style={{ margin: 2, textAlign: "Right", fontSize: "11px"}}>A/C Number     : </Text>
        <Text style={{ margin: 2, textAlign: "Right", fontSize: "11px"}}>{ACC_NUMBER}</Text>
        </View>
        <View style={{flexDirection: 'row'}}>
        <Text style={{margin: 2, textAlign: "Right", fontSize: "11px"}}>IFSC Code       : </Text>
        <Text style={{margin: 2, fontSize: "11px"}}>{IFSC_CODE}</Text>

        </View>
      </View>

      <View style={{flex: 1}}>
        <Text style={{flex: 1, textAlign: 'right', alignItems: 'right', margin: 5, fontFamily: 'Helvetica-Bold', fontSize: "11px"}}>For {COMPANY_NAME}</Text>
        <Text style={{textAlign: 'right', height: '32px', margin: 5}}></Text>
        <Text style={{textAlign: 'right', margin: 5, fontSize: "11px"}}>Authorised Signature</Text>
      </View>
    </View>
    {/*
    <View style={styles.row}>
        <Text style={{flex: 5, paddingTop: "10px", fontFamily: 'Helvetica-Bold'}}>Thanks for your business with KMC Readymix Concrete.</Text>
      </View>
  */}

    </View>

    
    );
  }
  export default OrderThankYouMsg;