import React from "react";
import { Table } from "react-bootstrap";

// 3. create customers table component
const CustomersExcel = ({ customers }) => {
  // table header
  const TableHeader = (
    <thead className="bgvi">
      <tr>
        <th>#</th>
        <th>Inv.No</th>
        <th>Date</th>
        <th>Customer</th>
        <th>GST IN</th>
        <th>Sub Total</th>
        <th>CGST</th>
        <th>SGST</th>
        <th>Total Amount</th>
      </tr>
    </thead>
  );

  // table row construction
  const CustomerRow = (customer, index) => (
    <tr key={index} className="even">
      <td>{index + 1}</td>
      <td>{customer.inv_no}</td>
      <td>{customer.inv_date}</td>
      <td>{customer.customer_name}</td>
      <td>{customer.gst}</td>
      <td>{customer.sub_total}</td>
      <td>{customer.cgst}</td>
      <td>{customer.sgst}</td>
      <td>{customer.total_amount}</td>
    </tr>
  );

  // render customers's items
  const CustomerTable = customers.map((cust, index) =>
    CustomerRow(cust, index)
  );

  return (
    <Table striped bordered hover>
      {TableHeader}
      <tbody>{CustomerTable}</tbody>
    </Table>
  );
};

export default CustomersExcel;