import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

import { COMPANY_NAME } from '../../config/Config';

const styles = StyleSheet.create({
   
    titleContainer:{
        flexDirection: 'row',
        marginTop: 72
    },
    reportTitle:{
        fontSize: 12,
        flexDirection: 'row',
        textAlign: 'flex-end', 
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    },
    container2: {
        paddingBottom:0,
        flexDirection: 'row',
    }

  });


  const DCReturnSave = (props) => {
    console.log("DC ITEM LIST : ", props.dcLoad.dc_item_list);
    const rows = props.dcLoad.dc_item_list.map((item, idx) =>
       

        <View>
             <View style={{marginTop: 20}}>
            <Text></Text>
          </View>

          
          
        <View style={styles.row} key="items">
            <Text style={styles.description}>Return Quantity : {item.return_quantity}</Text>
        </View>
        <View style={styles.row} key="items">
          <Text style={styles.description}>Return Quantity Amount : {item.return_quantity_amount}</Text>
        </View>

        <View style={styles.row} key="items">
            <Text style={styles.description}>Save Quantity : {item.save_quantity}</Text>
        </View>
        
        <View style={styles.row} key="items">
          <Text style={styles.description}>Save Quantity Amount : {item.save_quantity_amount}</Text>
        </View>

        </View>
    )
    console.log("AFTER FRAME TAGS : ", rows);
    return (<Fragment>{rows}</Fragment>)
    
  }
  
  export default DCReturnSave