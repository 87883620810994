import React, { Fragment } from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#0d0d0d'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: borderColor,
        //borderBottomWidth: 1,
        alignItems: 'center',
        //height: 24,
        fontSize: 12,
        fontStyle: 'bold',
    },
    description: {
        width: '85%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
    },
    total: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
  });


const InvoiceTableFooter = (props) => {
    console.log("Props at InvoiceTableFooter : ", props);
    let total = props.items.map(item => item.total_quantity * item.unit_price)
        .reduce((accumulator, currentValue) => accumulator + currentValue , 0)
    console.log("Total price at InvoiceTableFooter: ", total);
    
    total = total + props.invoice.dc_info.diesel_charges + props.invoice.dc_info.pumping_charges;

    return(
        <Fragment>
            <View style={styles.row}>
            <Text style={styles.description}>TOTAL</Text>
            <Text style={styles.total}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(total).toFixed(2)))}</Text>
            </View>
        </Fragment>
    )
};
  
  export default InvoiceTableFooter