import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Form, Button,Table, Modal, Spinner, Image } from "react-bootstrap";
import { withRouter } from "react-router";
//import Sidebar from './Sidebar';
import Sidebar2 from "./Sidebar2";
import Footer from "./Footer";
//import Topmenu from "./Topmenu";
import OrderDetails from "./OrderDetails";
import "../Dashboard.css";

//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";

import { useNavigate } from "react-router-dom";

import * as Icon from "react-bootstrap-icons";
import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsCardText,
} from "react-icons/bs";

import { BsFillEnvelopePlusFill } from "react-icons/bs";

import axios from "axios";
import { client } from "../config/Config";

import MenuUms from "./MenuUms";
import SidebarMain from "./SidebarMain";

import logo_avinaerp from '../assets/images/logo_avinaerp.png';


//import SidebarMain from "./SidebarMain";

const ListUsers = (props) => {
  //function ListUsers(props) {
  const initialized = useRef(false);
  const navigate = useNavigate();

  
  const [usersList, setUsersList] = useState([]);
  const [toRender, setToRender] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [modalDialogTitle, setModalDialogTitle] = useState("");
  const [modalDialogBody, setModalDialogBody] = useState("");
  const [modalAction, setModalAction] = useState("");  
  const [selectedId, setSelectedId] = useState(-1);
  const [showMsg, setShowMsg] = useState("Please login again");
  const [show, setShow] = useState(false);
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );

  //navigate("/addUpdateUser");
  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const handleShowDialog = () => {
    setShowDialog(true);
  };
  const updateUserStatus = async (selectedId) => {
    var selectedObj = usersList[selectedId];
    let reqStatusUpdatePayload = {
      id: selectedObj.id,
      is_active: !selectedObj.is_active,
    };

    console.log("payload:", reqStatusUpdatePayload);

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}


      var res = await axios.post(
        client.domain + "/core/activateUser",
        reqStatusUpdatePayload,
        { headers },
        { withCredentials: false }
      );
      
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        var itemSelectedUpdate = usersList[selectedId];
        itemSelectedUpdate.is_active = !itemSelectedUpdate.is_active;
        console.log(
          "<<<<<<<<<<<<<<<<< ITEM SELECTED MODIFIED ********** ",
          itemSelectedUpdate
        );

        const newitems = usersList.slice();
        newitems[selectedId] = itemSelectedUpdate;
        setUsersList(newitems);

        setShowDialog(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );  
        }
      }
    }
    setShowDialog(false);
  };

  const handleDialogAction = () => {
    console.log("user confirmed to enable or disable ...");
    console.log("user selected id for action is : ", selectedId);

    // call update API
    if ( modalAction === 'switch' ) { 
    updateUserStatus(selectedId);
    }
    else {
      setShowDialog(false);
    }
  };
  const getUsersList = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
    

      var res = await axios.get(
        client.domain + "/core/getAllUsers",
        { headers },
        { withCredentials: false }
      );
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", 0);
        setUsersList(res.data.detail.data);
        setShowMsgTitle("User Info Added Successfully");
        setToRender(true);
        
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
          
        } else {
          console.log("Non status ERROR code ...");
          setShowMsg(error.response.data.detail);
          setShowMsgTitle("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
  };

  const newUserValidation = async() => {
    var retState = false;
    var resMsg = {};
    var retAppState = "";
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
  
      var res = await axios.get(
        client.domain + "/core/allowNewUser",
        { headers },
        { withCredentials: false }
      );
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        retState = true;
        resMsg = res.data.detail.data;
        retAppState = res.data.detail.status;
        if ( res.data.detail.data.allow_newuser === true ) {
          navigate("/addUpdateUser", {state: { passed_element: [], action: "add" }});
        }
      }
      else {
        /*
        setShowDialog(true);
        setModalDialogTitle("Unable to create new account");
        setModalAction("new_user")
        setModalDialogBody(res.data.detail.data.message);
        */
        navigate("/addUpdateUser", {state: { passed_element: [], action: "add" }});
      }
     
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
          
        } else {
          console.log("Non status ERROR code ...");
          setShowMsg(error.response.data.detail);
          setShowMsgTitle("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
    return [retState, resMsg, retAppState];
  }

  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::ListUsers called ##########################"
      );
      getUsersList();

      initialized.current = true;
    }
  }, []);

    const handleAdd = async (e) => {
    console.log("handle add users called : ", e);
    var [retStateNewUserValid, resMsg] = await newUserValidation();
    /*
    if ( retStateNewUserValid ) {
      if ( resMsg.allow_newuser === true ) {
        navigate("/addUpdateUser", {state: { passed_element: [], action: "add" }});
      }
      else { 
        //setModalDialogTitle("User creation failed!");
        //setModalDialogBody(resMsg.message);
        //setShowDialog(true);
        navigate("/addUpdateUser", {state: { passed_element: [], action: "add" }});
      }
  }
  */
}

 
   const handleView = async (e) => {
    console.log("handle edit users called : ", e);
    console.log("handle edit users target called : ", e.target);
    var selectedTagId = e.currentTarget.id;
    console.log("SELECTED TAG ID: ", selectedTagId);
    if (selectedTagId) {
      var currentId = selectedTagId.substring(
        selectedTagId.lastIndexOf("-") + 1
      );
      var nameOfElement = selectedTagId.substring(
        0,
        selectedTagId.lastIndexOf("-")
      );
      console.log("Name of element VIEW: ", nameOfElement);
      console.log("Current id VIEW : ", currentId);

      console.log("<<<<<<<<<<<<<<<<< ITEM SELECTED VIEW ********** ", usersList[currentId]);
      navigate("/addUpdateUser", {
      state: {
        passed_element: usersList[currentId],
        action: "view",
      },
    });
  }
   
  };
  const handleOnChange = async (e) => {
    console.log("handle on change called : ", e);

    var selectedTagId = e.target.id;
    console.log("SELECTED TAG ID: ", selectedTagId);
    var currentId = selectedTagId.substring(selectedTagId.lastIndexOf("-") + 1);
    var nameOfElement = selectedTagId.substring(
      0,
      selectedTagId.lastIndexOf("-")
    );
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    console.log("<<<<<<<<<<<<<<<<< ITEM SELECTED ********** ", usersList[currentId]);

    setSelectedId(currentId);

    setModalAction("switch");
    setModalDialogTitle("Confirmation");
    setModalDialogBody("Activae / In-Activate this user? Click OK to proceed...");
    setShowDialog(true);
  };

  const actionEditHandle = (e, data) => {
    console.log("Handle action edit Clicked : ", e);
    //console.log("Handle Clicked edit DATA : ", data);
    console.log("Handle action edit Clicked target : ", e.target);
    console.log("Handle action edit Clicked target Current : ", e.currentTarget);
    console.log("Handle action edit Clicked target Current id : ", e.currentTarget.id);
    console.log("Handle action edit Clicked target ID : ", e.target.id);
    console.log("Handle action edit Clicked target NAME : ", e.target.name);
  };

  return (
    <Container>
      <SidebarMain />
      { toRender ?  
      <form>
      <Row>
            <Col xs={6} sm={6} md={6} lg={6}>
              <h4>Users</h4>
            </Col>
            <Col xs={6} md={6} sm={6} lg={6}>
              <Button
                variant="warning"
                id="add-btn-id"
                name="add-btn-name"
                className=" float-end"
                onClick={(e) => handleAdd(e)}
              >
                New User
              </Button>
            </Col>
          </Row>
          <hr align="center" />
          <div className="ui-divider"></div>
          <Table striped bordered hover>
            <thead>
              <tr className="table-primary">
                <th className="table-row-heading" style={{textAlign: "center"}}>S.No</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>User Name</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>User Role</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Employee Name</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Contact No</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Email</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Lock Status</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Active</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Details</th>
              </tr>
            </thead>
            <tbody>
              {usersList.map((user, idx) => (
                <tr key={`user-table-row-id-${idx}`}>
                  <td key={`user-list-item-key-sno-${idx}`} style={{textAlign: "right"}}>{idx + 1}</td>
                  <td key={`user-list-item-key-username${idx}`} style={{textAlign: "left"}}>{user.username}</td>
                  <td key={`user-list-item-key-user_role_name${idx}`} style={{textAlign: "left"}}>{user.user_role_name}</td>
                  <td key={`user-list-item-key-employee_name${idx}`} style={{textAlign: "left"}}>{user.employee_name}</td>
                  <td key={`user-list-item-key-contact_no${idx}`} style={{textAlign: "right"}}>{user.contact_no}</td>
                  <td key={`user-list-item-key-email${idx}`} style={{textAlign: "left"}}>{user.email}</td>
                  <td key={`user-list-item-key-lock_status${idx}`} style={{textAlign: "left"}}>{user.lock_status}</td>
                  <td style={{textAlign: "center"}}>
                    <Form.Check // prettier-ignore
                      key={`user-switch-key-${idx}`}
                      type="switch"
                      id={`user-switch-${idx}`}
                      name={`user-switch-${idx}`}
                      onChange={handleOnChange}
                      checked={user.is_active}
                    ></Form.Check>
                  </td>
                  <td style={{textAlign: "center"}}>
                    <BsCardText
                      key={`edit-btn-user-key-${idx}`}
                      size={28}
                      style={{ color: "#0D6EFD", cursor: "pointer" }}
                      onClick={(e) => handleView(e)}
                      name={`edit-icon-user-name-${idx}`}
                      id={`edit-icon-user-id-${idx}`}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Modal
        show={showDialog}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{modalDialogTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalDialogBody}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleDialogAction}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      </form>
       : 
       <div className="container-spinner">
      <div className="vertical-center-spinner">
       {/*<Button variant="primary" disabled>*/}
       <Image src={logo_avinaerp}roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
       {/*</Button>*/}
       </div>
       </div>
      }   
     </Container>
        
     
   
  );
};
//const Dashboard = withRouter(Dash);
export default ListUsers;
