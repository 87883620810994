import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Form, Button, Spinner, Image } from "react-bootstrap";
import { withRouter } from "react-router";
//import Sidebar from './Sidebar';
import Sidebar2 from "./Sidebar2";
import Footer from "./Footer";
//import Topmenu from "./Topmenu";
import OrderDetails from "./OrderDetails";
import "../Dashboard.css";

//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";

import { useNavigate } from "react-router-dom";

import * as Icon from "react-bootstrap-icons";
import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsCardText,
} from "react-icons/bs";

import { BsFillEnvelopePlusFill } from "react-icons/bs";

import axios from "axios";
import { client } from "../config/Config";

import MenuEms from "./MenuEms";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';
import SidebarMain from "./SidebarMain";

const ListLeaves = (props) => {
  //function ListUsers(props) {
  const initialized = useRef(false);
  const navigate = useNavigate();

  var leavesArr = [
    {
      date: "11.08.2023",
      id: 1,
      name: "Abi",
      type: "fullday",
      reason: "sick-leave",
    },
    {
      date: "15.08.2023",
      id: 2,
      name: "john",
      type: "offday",
      reason: "casual-leave",
    },
    {
      date: "17.08.2023",
      id: 3,
      name: "ram",
      type: "offday",
      reason: "function",
    },
  ];

  const [leavesList, setLeavesList] = useState(leavesArr);
  const [toRender, setToRender] = useState(false);
  //navigate("/addUpdateLeaves");

  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::ListLeaves called ##########################"
      );
      setToRender(true);
      initialized.current = true;
    }
  }, []);

  const handleAdd = async (e) => {
    console.log("handle add leaves called : ", e);
    navigate("/addupdateleaves", { state: {} });
  };

  const handleView = async (e) => {
    console.log("handle edit leaves called : ", e);
    navigate("/addupdateleaves", { state: {} });
  };

  const handleOnChange = async (e) => {
    console.log("handle on change called : ", e);
  };

  return (
    <Container>
      <SidebarMain />
      { toRender ?  
      <form>
        <Row>
          <Col xs={6} md={6} sm={6}>
            <h2 className="text-left">Leaves</h2>
          </Col>
          <Col xs={6} md={6} sm={6}>
            <Button
              id="addUpdateId"
              className="float-end"
              onClick={(e) => handleAdd(e)}
            >
              New Leaves
            </Button>
          </Col>
        </Row>

        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th key={"leave-list-heading-key"}>S.No</th>
              <th>Date</th>
              <th> Id</th>
              <th> Name</th>
              <th> Type</th>
              <th> Reason</th>
            </tr>
          </thead>
          <tbody>
            {leavesList.map((leave, idx) => (
              <tr key={`leave-table-row-id-${idx}`}>
                <td key={`leave-list-item-key-${idx}`}>{idx + 1}</td>
                <td key={`leave-list-date-key-${idx}`}>{leave.date}</td>
                <td key={`leave-list-id-key-${idx}`}>{leave.id}</td>
                <td key={`leave-list-name-key-${idx}`}>{leave.name}</td>
                <td key={`leave-list-type-key-${idx}`}>{leave.type}</td>
                <td key={`leave-list-reason-key-${idx}`}>{leave.reason}</td>

                <td>
                  <Form.Check // prettier-ignore
                    key={`leave-switch-key-${idx}`}
                    type="switch"
                    id="leave-switch"
                    onChange={(e) => handleOnChange(e)}
                    checked={true}
                  />
                </td>
                <td>
                  <BsCardText
                    key={`view-icon-leave-key-${idx}`}
                    size={28}
                    style={{ color: "#0D6EFD", cursor: "pointer" }}
                    onClick={(e) => handleView(e)}
                    name={`view-icon-leave-name-${idx}`}
                    id={`view-icon-leave-id-${idx}`}
                    designation={`view-icon-leave-type-${idx}`}
                    mobile={`view-icon-leave-reason-${idx}`}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </form>
       : 
       <div className="container-spinner">
      <div className="vertical-center-spinner">
       {/*<Button variant="primary" disabled>*/}
       <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{ width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
 
       {/*</Button>*/}
       </div>
       </div>
      }
    </Container>
  );
};
//const Dashboard = withRouter(Dash);
export default ListLeaves;
