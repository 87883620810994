import React, { Component, Fragment } from 'react';
import {PDFViewer} from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

import Order from '../components/reports/Order';
import invoice from '../data/invoice';
import { COMPANY_NAME } from '../config/Config';

const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
      alignItems: 'center',
      alignContent: 'center',
      textAlign: 'center'
    }
  });

// Create Document Component
const StockDocument = (props) => {
  console.log("FULL PROPS : ", props);
  //console.log("PROPS DATA COMPANY : ", props.order_data.company_info);
  //console.log("PROPS DATA DC : ", props.order_data.dc_info);
  //console.log("PROPS DATA ORDER : ", props.order_data.order_info);
  return (
    <Fragment>
        <Document>
         <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text>{COMPANY_NAME}</Text>
      
      <View style={styles.headerContainer}>
        <Text style={{fontWeight: 'bold'}}>Address:</Text>
        <Text>gdja gjas </Text>
        <Text>hafks hf ja gfjasf</Text>
        <Text>nv nsadkfhdskfa</Text>
        <Text>GST: gg ffjafs</Text>
    </View>
    <View style={styles.headerContainer}>
        <Text>Section #2</Text>
        </View>
      </View>
      </Page>
      </Document>
    </Fragment>
  )
}

export default StockDocument;