import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#0d0d0d';
const rowHeight = 26;
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: borderColor,
        backgroundColor: '#a8a8a8',
        borderBottomWidth: 1,
        alignItems: 'center',
        //height: rowHeight,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
        display: 'table-cell',
        verticalAlign: 'middle'
    },
    sno: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        //height: rowHeight,
        display: 'table-cell',
        verticalAlign: 'middle'
    },
    description: {
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        alignItems: 'center',
        //height: rowHeight,
        display: 'table-cell',
        verticalAlign: 'middle'
    },
    qty: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        //height: rowHeight,
        display: 'table-cell',
        verticalAlign: 'middle'
    },
    rate: {
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        //height: rowHeight,
        display: 'table-cell',
        verticalAlign: 'middle'
    },
    amount: {
        width: '20%',
        //height: rowHeight,
        display: 'table-cell',
        verticalAlign: 'middle'
    },
  });

  const InvoiceTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.sno} className="align-items-center">S.No</Text>
        <Text style={styles.description} className="align-items-center align-middle">Item Description</Text>
        <Text style={styles.qty}>Volume</Text>
        <Text style={styles.qty}>Total Volume</Text>
        <Text style={styles.rate} className="align-items-center">Unit Price</Text>
        <Text style={styles.amount}>Product Price</Text>
    </View>
  );
  
  export default InvoiceTableHeader