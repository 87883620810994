import React, { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';

import { client } from '../config/Config';

import StockDocument from "./StockDocument";

const borderColor = '#a8a8a8';
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 9,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    //width: '100%',
    //height: '33%',
    width: '100%',
    height: '100%',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  logo_image2: {
    width: '15%',
    height: '98px',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  business_content: {
    width: '60%',
    height: '98px',
    //paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container2: {
    //paddingBottom:0,
    flexDirection: 'row',
    //borderBottomColor: '#bff0fd',
    //backgroundColor: '#bff0fd',
    //borderBottomWidth: 1,
    //alignItems: 'center',
    //height: 12,
    //textAlign: 'center',
    //fontStyle: 'bold',
    //flexGrow: 1,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 1,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#a8a8a8',
  },
  container: {
    flexDirection: 'row',
    borderBottomColor: '#a8a8a8',
    backgroundColor: '#a8a8a8',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
  },
  sno: {
    width: '5%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
   
  },
  product_name: {
    width: '29%',
    fontSize: "12px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left'
  },
  stock_open: {
    width: '11%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
  },
  stock_in: {
    width: '11%',
    fontSize: "12px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
  },
  stock_out: {
    width: '11%',
    fontSize: "12px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
  },
  stock_available: {
    width: '11%',
    fontSize: "9px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
  },
  stock_min: {
    width: '11%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
  },
  stock_max: {
    width: '11%',
    borderRightColor: borderColor,
    fontSize: "10px",
    //borderRightWidth: 1,
  },
  row: {
    flexDirection: 'row',
    //borderBottomColor: '#a8a8a8',
    //borderBottmWidth: 1,
    alignItems: 'center',
    //height: 24,
    fontStyle: 'bold',
  },
  snoData: {
    width: '5%',   
    textAlign: 'left',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    fontSize: "12px",
    paddingLeft: 8,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  product_nameData: {
    width: '29%',
    fontSize: "12px",
    //textAlign: 'left',
    textAlign: 'justify',
    alignContent: 'justify',
    //textAlign: 'justify',
    textJustify: 'inter-word',
    display:'flex',
    flexWrap: 'wrap',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    paddingLeft: 8,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  stock_openData: {
    width: '11%',
    fontSize: "12px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  stock_inData: {
    width: '11%',
    fontSize: "12px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  stock_outData: {
    width: '11%',
    fontSize: "12px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  stock_availableData: {
    width: '11%',
    fontSize: "12px",
    borderRightColor: borderColor,
    textAlign: 'right',
    paddingRight: 8,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  stock_minData: {
    width: '11%',
    fontSize: "12px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
   stock_maxData: {
    width: '11%',
    fontSize: "12px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  
  sno_today: {
    width: '8%',
    textAlign: 'left',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    paddingLeft: 8,
    fontSize: "12px"
  },
  product_name_today: {
    width: '45%',
    textAlign: 'left',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    paddingLeft: 8,
    fontSize: "12px"
  },
  stock_in_today: {
    width: '20%',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    fontSize: "12px",
    paddingRight: 8
  },
  stock_out_today: {
    width: '20%',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    fontSize: '12px',
    paddingRight: 8
  },
  snoData_today: {
    width: '8%',
    textAlign: 'left',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    paddingLeft: 8,
    fontSize: "12px",
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
    
  },
  product_nameData_today: {
    width: '52%',
    textAlign: 'left',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    paddingLeft: 8,
    fontSize: "12px",
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  stock_inData_today: {
    width: '20%',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
    fontSize: "12px",
    borderWidth: 1,
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  stock_outData_today: {
    width: '20%',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
    fontSize: "12px",
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  sno_po: {
    width: '8%',
    textAlign: 'left',
    fontSize: '12px',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    //paddingLeft: 8,
  },
  product_name_po: {
    width: '32%',
    textAlign: 'left',
    fontSize: '12px',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    //paddingLeft: 8,
  },
  stock_in_po: {
    width: '15%',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    fontSize: '12px',
    //paddingRight: 8,
  },
  stock_out_po: {
    width: '15%',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    fontSize: '12px',
    //paddingRight: 8,
  },
  dc_no_po: {
    width: '15%',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    fontSize: '12px',
    //paddingRight: 8,
  },
  po_dc_date_po: {
    width: '15%',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    fontSize: '12px',
    //paddingRight: 8,
  },
  snoData_po: {
    width: '8%',
    textAlign: 'left',
    fontSize: '12px',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    paddingLeft: 8,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  product_nameData_po: {
    width: '32%',
    textAlign: 'left',
    fontSize: '12px',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    paddingLeft: 8,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  stock_inData_po: {
    width: '15%',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    fontSize: '12px',
    paddingRight: 8,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  stock_outData_po: {
    width: '15%',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    fontSize: '12px',
    paddingRight: 8,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  dc_noData_po: {
    width: '15%',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    fontSize: '12px',
    paddingRight: 8,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  po_dc_dateData_po: {
    width: '15%',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    fontSize: '12px',
    paddingRight: 8,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  sno_so: {
    width: '8%',
    textAlign: 'left',
    fontSize:'12px',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    paddingLeft: 8,
  },
  product_name_so: {
    width: '32%',
    fontSize:'12px',
    textAlign: 'left',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    paddingLeft: 8,
  },
  stock_in_so: {
    width: '15%',
    fontSize:'12px',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
  },
  stock_out_so: {
    width: '15%',
    fontSize:'12px',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
  },
  dc_no_so: {
    width: '15%',
    fontSize:'12px',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
  },
  po_dc_date_so: {
    width: '15%',
    fontSize:'12px',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
  },
  snoData_so: {
    width: '8%',
    textAlign: 'left',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    fontSize:'12px',
    paddingLeft: 8,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  product_nameData_so: {
    width: '32%',
    textAlign: 'left',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    paddingLeft: 8,
    fontSize:'12px',
    paddingLeft: 8,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  stock_inData_so: {
    width: '15%',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
    fontSize:'12px',
    paddingLeft: 8,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  stock_outData_so: {
    width: '15%',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
    fontSize:'12px',
    paddingLeft: 8,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  dc_noData_so: {
    width: '15%',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
    fontSize:'12px',
    paddingLeft: 8,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  po_dc_dateData_so: {
    width: '15%',
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
    fontSize:'12px',
    paddingLeft: 8,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
});

const PrintStock = (props) => {
  const [reportData, setReportData] = useState({});
  const [toRender, setToRender] = useState(false);

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  const formateTime = (currTime) => {
    var h = (currTime.getHours() < 10 ? "0" : "") + currTime.getHours();
    var m = (currTime.getMinutes() < 10 ? "0" : "") + currTime.getMinutes();
    var hrMin = h + ":" + m;
    return hrMin;
  };

  const formatPrintDate = (currTime) => {
    var dateComponent = [
      padTo2Digits(currTime.getDate()),
      padTo2Digits(currTime.getMonth() + 1),
      currTime.getFullYear(),
    ].join("-");

    var timeComponent = [
      padTo2Digits(currTime.getHours() % 12 || 12 ),
      padTo2Digits(currTime.getMinutes())
    ].join(":");

    const ampm = currTime.getHours() >= 12 ? 'PM' : 'AM';

    return dateComponent + " " + timeComponent + " " + ampm;
  }

  const getReportList = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
     

      var res = await axios.get(
        client.domain + "/ims/getReportCurrentStock",
        { headers },
        { withCredentials: false }
      );

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getReportList : ", res.data.detail.data);
        setReportData(res.data.detail.data);

        //setShowMsgTitle("Report Info Added Successfully");
        setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        //setShowMsg("Please try after sometime");
        //setShowMsgTitle("Network Issue!");
        //setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg("Please try again ...");
          //setShowMsgTitle("Unable to process your request!");
          //setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          //setShowMsg(error.response.data.detail);
          //setShowMsgTitle("Unable to process your request at this moment!");
          //setShow(true);
        }
      }
    }
  };

  useEffect(() => {
    console.log("useEffect method called ...");

    getReportList();
  }, [])

  return (
    <PDFViewer style={styles.viewer}>
      {toRender === true &&
        <Document>
          <Page size="A4" style={styles.page}>
          

            {/* 
      <View style={styles.section}>
        <Text  className="text-center"style={{fontWeight: 'bold'}}  >{reportData.report_header.name}</Text>
   
      <View style={styles.headerContainer}>
        <Text style={{fontWeight: 'bold'}}>{reportData.report_header.gst_no}</Text>
        <Text>{reportData.report_header.address_line1} </Text>
        <Text>{reportData.report_header.address_line2}</Text>
        <Text>{reportData.report_header.district}</Text>
        <Text>{reportData.report_header.pincode}</Text>
    </View>
     </View>
            */}


            <View>
              <View style={{ flexDirection: 'row' }}>

                <View style={{ flex: 1, width: "75%", alignItems: "center", /*paddingRight: "30px",*/ borderBottomWidth: 1, borderBottomColor: '#a7a5a5'/*,marginTop: 1, marginBottom: 10, borderWidth: 1*/ }} key="address-key">
                  <Text style={{ fontSize: "20px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold', color: "green" }}>{reportData.report_header.name}</Text>
                  <Text>{reportData.report_header.address_line1}, {reportData.report_header.address_line2}-{reportData.report_header.pincode}</Text>
                  <Text>{reportData.report_header.district}, {reportData.report_header.state}, {reportData.report_header.country}</Text>
                  <hr style={{paddingBottom: "10px"}}/>
                </View>
              </View>
            </View>
            <View style={{ width: "100%", alignItems: "center",/* paddingLeft: "20px",*/ textAlign: 'center', borderBottomWidth: 1, borderBottomColor: '#a7a5a5', paddingTop: "5px" }}>
              <Text style={{ fontSize: "12px", textAlign: 'center', alignContent: 'center', fontFamily: 'Helvetica-Bold', color: '#646363' }}>{reportData.report_title}</Text>
            </View>
            


            <View style={{ width: "75%", alignItems: "left", paddingRight: "50px", paddingTop: "20px", textAlign: 'left' }}>
              <Text style={{ fontSize: "10px", textAlign: 'left', alignContent: 'left', fontFamily: 'Helvetica-Bold' }}>{reportData.stock_ason_date.title}</Text>
            </View>

            <View style={styles.tableContainer}>
              
              <View style={styles.container}>
                <Text style={styles.sno}>S.No</Text>
                <Text style={styles.product_name}>Product Name</Text>
                <Text style={styles.stock_open}>Stock Open</Text>
                <Text style={styles.stock_in}>Stock In</Text>
                <Text style={styles.stock_out}>Stock Out</Text>
                <Text style={styles.stock_available}>Stock Avail</Text>
                <Text style={styles.stock_min}>Stock Min</Text>
                <Text style={styles.stock_max}>Stock Max</Text>
              </View>



             
                {reportData.stock_ason_date.data.map((item, idx) => (
                  <View style={styles.row} key="items">
                    <Text style={styles.snoData}>{idx + 1}</Text>
                    <Text style={styles.product_nameData}>{item.product_name}</Text>
                    <Text style={styles.stock_openData}>{item.stock_open}</Text>
                    <Text style={styles.stock_inData}>{item.stock_in}</Text>
                    <Text style={styles.stock_outData}>{item.stock_out}</Text>
                    <Text style={styles.stock_availableData}>{item.stock_available}</Text>
                    <Text style={styles.stock_minData}>{item.stock_min}</Text>
                    <Text style={styles.stock_maxData}>{item.stock_max}</Text>
                  </View>
                ))}
              
              </View>
                
              
              
            { (reportData.hasOwnProperty("stock_today") && reportData["stock_today"].hasOwnProperty("data")) &&
              <>
              <View style={{ width: "75%", alignItems: "left", paddingRight: "50px", textAlign: 'left', paddingTop: "20px" }}>
              <Text style={{ fontSize: "10px", textAlign: 'left', alignContent: 'left', fontFamily: 'Helvetica-Bold' }}>{reportData.stock_today.title}</Text>
            </View>

              
              <View style={styles.tableContainer}>
              
              <View style={styles.container}>
                <Text style={styles.sno_today}>S.No</Text>
                <Text style={styles.product_name_today}>Product Name</Text>
                <Text style={styles.stock_in_today}>Stock In</Text>
                <Text style={styles.stock_out_today}>Stock Out</Text>
                
              </View>



             
                {reportData.stock_today.data.map((item, idx) => (
                  <View style={styles.row} key="items">
                    <Text style={styles.snoData_today}>{idx + 1}</Text>
                    <Text style={styles.product_nameData_today}>{item.product_name}</Text>
                    <Text style={styles.stock_inData_today}>{item.stock_in}</Text>
                    <Text style={styles.stock_outData_today}>{item.stock_out}</Text>
                  </View>
                ))}
              
              </View>
              </>
              }
              

              { (reportData.hasOwnProperty("stock_po_dc") && reportData["stock_po_dc"].hasOwnProperty("data")) &&
              <>
            <View style={{ width: "75%", alignItems: "left", paddingRight: "50px", textAlign: 'left' }}>
              <Text style={{ fontSize: "10px", textAlign: 'left', alignContent: 'left', fontFamily: 'Helvetica-Bold' }}>{reportData.stock_po_dc.title}</Text>
            </View>
            <View style={styles.tableContainer}>
              <View style={styles.container}>
                <Text style={styles.sno_po}>S.No</Text>
                <Text style={styles.product_name_po}>Product Name</Text>
                <Text style={styles.stock_in_po}>Stock In</Text>
                <Text style={styles.stock_out_po}>Stock Out</Text>
                <Text style={styles.dc_no_po}>DC No</Text>
                <Text style={styles.po_dc_date_po}>DC Date</Text>

                </View>


                
                {reportData.stock_po_dc.data.map((item, idx) => (
                  <View style={styles.row} key="items">
                    <Text style={styles.snoData_po}>{idx + 1}</Text>
                    <Text style={styles.product_nameData_po}>{item.product_name}</Text>
                    <Text style={styles.stock_inData_po}>{item.stock_in}</Text>
                    <Text style={styles.stock_outData_po}>{item.stock_out}</Text>
                    <Text style={styles.dc_noData_po}>{item.dc_no}</Text>
                    <Text style={styles.po_dc_dateData_po}>{item.po_dc_date}</Text>
                    </View>
                ))}
             
            </View>
            </>
            }

          { (reportData.hasOwnProperty("stock_so_dc") && reportData["stock_so_dc"].hasOwnProperty("data")) &&
          <>
            <View style={{ width: "100%", alignItems: "left", paddingRight: "10px", textAlign: 'left' }}>
              <Text style={{ fontSize: "10px", textAlign: 'left', alignContent: 'left', fontFamily: 'Helvetica-Bold' }}>{reportData.stock_so_dc.title}</Text>
              </View>

            <View style={styles.tableContainer}>
              
              <View style={styles.container}>
                <Text style={styles.sno_so}>S.No</Text>
                <Text style={styles.product_name_so}>Product Name</Text>
                <Text style={styles.stock_in_so}>Stock In</Text>
                <Text style={styles.stock_out_so}>Stock Out</Text>
                <Text style={styles.dc_no_so}>DC No</Text>
                <Text style={styles.po_dc_date_so}>DC Date</Text>
                </View>


               
                {reportData. stock_so_dc.data.map((item, idx) => (
                  <View style={styles.row} key="items">
                    <Text style={styles.snoData_so}>{idx + 1}</Text>
                    <Text style={styles.product_nameData_so}>{item.product_name}</Text>
                    <Text style={styles.stock_inData_so}>{item.stock_in}</Text>
                    <Text style={styles.stock_outData_so}>{item.stock_out}</Text>
                    <Text style={styles.dc_noData_so}>{item.dc_no}</Text>
                    <Text style={styles.po_dc_dateData_so}>{item.po_dc_date}</Text>
                    </View>
                ))}
              
              </View>
              </>
          }
              

            <View style={{flexDirection: 'row'}}>
            
            <Text style={{textAlign: 'left', flex: 1}} render={({ pageNumber, totalPages }) => (
                `Printed On: ${formatPrintDate(new Date())}`
            )} fixed />
            
            
           
              <Text style={{flex: 1, textAlign: 'right', justifyContent: 'flex-end', alignSelf: 'flex-end'}} render={({ pageNumber, totalPages }) => (
                `${pageNumber} / ${totalPages}`
              )} fixed />
              </View>
              

          </Page>
        </Document>
      }
    </PDFViewer>
  );
}

export default PrintStock;