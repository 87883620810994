import React from 'react';
import { Page, Document, Image, StyleSheet, View, Text, Span } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'
import BillTo from './BillTo'
import InvoiceNo from './InvoiceNo'
import InvoiceItemsTable from './InvoiceItemsTable'
import InvoiceThankYouMsg from './InvoiceThankYouMsg'
import logo_left from '../../../src/logo_left.png';
import logo_right from '../../../src/logo_right.png';
import logo2 from '../../../src/logo_avn.png';
import logo3 from '../../../src/kmc_logo.jpg';
import logo_addr from '../../../src/logo_addr.jpeg';
import AdditionalCharges from './AdditionalCharges';
import CompanyAddress from './CompanyAddress';

import DCReturnSave from './DCReturnSave';

import invoice from '../../data/invoice';
import { Postcard } from 'react-bootstrap-icons';
import Badge from 'react-bootstrap/Badge';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
        flexDirection: 'column',
    }, 
    logo: {
        //width: '100%',
        //height: '33%',
        width: '100%',
        height: '100%',
        paddingTop: 30,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    logo_image2: {
        width: '15%',
        height: '98px',
        paddingTop: 30,
        marginLeft: 'auto',
        marginRight: 'auto' 
    },
    business_content: {
        width: '60%',
        height: '98px',
        //paddingTop: 30,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    container2: {
        //paddingBottom:0,
        flexDirection: 'row',
        //borderBottomColor: '#bff0fd',
        //backgroundColor: '#bff0fd',
        //borderBottomWidth: 1,
        //alignItems: 'center',
        //height: 12,
        //textAlign: 'center',
        //fontStyle: 'bold',
        //flexGrow: 1,
    },
    container3: {
        flexDirection: 'row',
        //width: '33%',
        //borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        paddingBottom: 5,
        marginHorizontal: 5,
        alignItems: 'center',
        textAlign: 'center',
        //height: 12,
        //fontStyle: 'bold',
        //flexGrow: 1,
        //marginLeft: '40%'
        //paddingBottom:1,
        //marginBottom: 12
        //marginTop: 48
    }
  });
  
  const Delivery = (props) => {
      console.log("Inside Invoice component ##################", props);
      console.log(">>>>>>>>>>>>>>> props data : ***************** ", props.dcLoad.company_info);
      console.log("DELIVERY ACTION : ", props.action);
  //const Invoice = ({invoice, dcLoad}) => (
    return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.container3}>
                        {/*<Image style={styles.logo} src={logo_addr} />*/}
                        <View style={{width: "25px"}}>
                        <Image style={{ marginHorizontal: '10%', width: 'auto', height: 'auto', height: "80px", width: "80px", textAlign: "left"}} src={logo_left} />
                        </View>
                        <View style={{...styles.business_content, textAlign: "center",paddingLeft:"42px"}} key="address-key">
                            <Text style={{fontSize: "19px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{props.dcLoad.company_info.name}</Text>
                            <Text>{props.dcLoad.company_info.address_line1}</Text>
                            <Text>{props.dcLoad.company_info.address_line2}-{props.dcLoad.company_info.pincode}</Text>
                            <Text>{props.dcLoad.company_info.district_name}, {props.dcLoad.company_info.state_name}</Text>
                            <Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica'}}><Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>GST Number:</Text> {props.dcLoad.company_info.gst_no}</Text>
                            <Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica'}}><Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Phone:</Text> {props.dcLoad.company_info.phone}</Text> 
                        </View>
                        <View style={{width: "25px", paddingRight: "85px"}}>
                        <Image style={{ marginHorizontal: '10%', width: 'auto', height: 'auto', height: "80px", width: "80px", textAlign: "right"}} src={logo_right} />
                        </View>
                        {/*
                        <View style={styles.row} key="address-key">
                            <Text>KMC CONCRETE</Text>
                            <Text>Thannerpandhal, Avinashi</Text>
                            <Text>Tiruppur District, 641654</Text>
                        </View>
                        */}
                        
                    </View>

                    {/*
                    <View style={styles.container3}>
                        <CompanyAddress invoice={invoice}/>
                    </View>
                    */}


                    
                    <InvoiceTitle title='Delivery Challan'/>
                    <InvoiceNo dcLoad={props.dcLoad}/>
                    <View style={styles.container2}>
                        <BillTo dcLoad={props.dcLoad} />
                        <AdditionalCharges dcLoad={props.dcLoad} />
                    </View>
                    <InvoiceItemsTable dcLoad={props.dcLoad}/>
                    
                    <InvoiceThankYouMsg />
                    { props.action === 'RETURNSAVE' && 
                    <DCReturnSave dcLoad={props.dcLoad} />
                    }
                </Page>
            </Document>
        );
  }
  
  export default Delivery
