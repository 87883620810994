import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Form, Button, View,Spinner, Image } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
import Sidebar2 from "./Sidebar2";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
//import ListGroup from "react-bootstrap/ListGroup";
//import Badge from "react-bootstrap/Badge";
//import DateTimeField from "react-bootstrap-datetimepicker"
//import DateTimePicker from "react-datetime-picker";

import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client } from "../config/Config";
import axios from "axios";

import { useLocation } from "react-router-dom";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import { BsFillPenFill, BsFillTrashFill,BsCardText } from "react-icons/bs";

import DateTimePicker from "react-datetime-picker";

import MenuSettings from "./MenuSettings";
import SidebarMain from "./SidebarMain";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';



const ListStocks = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  //console.log("PASSED STATE VALUE IN Patient Add/Update page : ", state);
  /*
  var stockArr = [
    {
      id: 13,
      product_id: 13,
      name: "Admixture",
      hsncode: "HSN-ADM",
      uom: "LITTER",
      trade_type_id: "BUY",
      trade_type: "Buy",
      stock_open: 0,
      stock_in: 0,
      stock_out: 0,
      stock_available: 0,
      stock_in_pending: 0,
      stock_out_pending: 0
     
    },
    {
      id: 11,
      product_id: 11,
      name: "Aggregate",
      hsncode: "HSN-AGG",
      uom: "KG",
      trade_type_id: "BUY",
      trade_type: "Buy",
      stock_open: 0,
      stock_in: 0,
      stock_out: 0,
      stock_available: 0,
      stock_in_pending: 0,
      stock_out_pending: 0
     
    },
    {
      id: 9,
      product_id: 9,
      name: "Cement",
      hsncode: "HSN-CEM",
      uom: "Tonne",
      trade_type_id: "BUY",
      trade_type: "Buy",
      stock_open: 0,
      stock_in: 0,
      stock_out: 0,
      stock_available: 0,
      stock_in_pending: 0,
      stock_out_pending: 0
    },
  ];
  */

  const [stockList, setStockList] = useState([]);
  const [toRender, setToRender] = useState(false);
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("Please login again");
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );
  //const [docNumber, setDocNumber] = useState(docNumberArr);

  let initialItems = [];
  var intialValues = {
    productId: "",
  };
 
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [validateError, setValidateError] = useState(false);

  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);

  var validateErr = false;
  
  const getStockList = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}`}
    
      

      var res = await axios.get(
        client.domain + "/ims/getAllStocks",
        { headers },
        { withCredentials: false }
      );
      console.log("Response from server at getStockList : ", res.data.detail.data);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.data);
        setStockList(res.data.detail.data);
        setShowMsgTitle("Stock Info Added Successfully");
        setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          setShowMsg(error.response.data.detail);
          setShowMsgTitle("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
  };

  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::ListDoctNumber called ##########################"
      );
      getStockList();
      setToRender(true);
     // console.log("PASSED STATE VALUE IN Document Add/Update page : ", state);
      initialized.current = true;
    }
  }, []);

  const handleView = async (e) => {
    console.log("handle edit stock called : ", e);
    console.log(
      "handle edit stock currentTarget called : ",
      e.currentTarget
    );
    console.log("handle edit stock target called : ", e.target);
    var selectedTagId = e.currentTarget.id;
    console.log("SELECTED TAG ID: ", selectedTagId);
    if (selectedTagId) {
      var currentId = selectedTagId.substring(
        selectedTagId.lastIndexOf("-") + 1
      );
      var nameOfElement = selectedTagId.substring(
        0,
        selectedTagId.lastIndexOf("-")
      );
      console.log("Name of element VIEW: ", nameOfElement);
      console.log("Current id VIEW : ", currentId);

      console.log(
        "<<<<<<<<<<<<<<<<< ITEM SELECTED VIEW ********** ",
      stockList[currentId]
      );
      navigate("/addUpdateStock", {
        state: {
          passed_element: stockList[currentId],
          action: "view",
        },
      });
    }
  };

  return (
      <Container>
        <SidebarMain />
        { toRender ?  
        <form>
          <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
              <h4>Stocks</h4>
            </Col>
          </Row>
          <hr align="center" />
          <div className="ui-divider"></div>
          <Table striped bordered hover>
            <thead>
              <tr className="table-primary">
                <th className="table-row-heading">#</th>
                <th className="table-row-heading">Name</th>
                <th className="table-row-heading">HSN Code</th>
                <th className="table-row-heading">UOM</th>
                <th className="table-row-heading">Trade Type </th>
                <th className="table-row-heading">Stock Open</th>
                <th className="table-row-heading">Stock In</th>
                <th className="table-row-heading">Stock Out</th>
                <th className="table-row-heading">Stock Available</th>
                <th className="table-row-heading">Details</th>
              </tr>
            </thead>

            <tbody>
              {stockList.map((stock, idx) => (
                <tr key={`doc-num-row-key-${idx}`}>
                  <td>{idx + 1}</td>
                  <td>{stock.name}</td>
                  <td>{stock.hsncode}</td>
                  <td>{stock.uom}</td>
                  <td>{stock.tt_value}</td>
                  <td style={{textAlign: "right"}} >{stock.stock_open}</td>
                  <td style={{textAlign: "right"}}>{stock.stock_in}</td>
                  <td style={{textAlign: "right"}}>{stock.stock_out}</td>
                  <td style={{textAlign: "right"}}>{stock.stock_available}</td>      
                  <td>
                    <BsCardText
                      key={`edit-btn-stock-key-${idx}`}
                      size={28}
                      style={{ color: "#0D6EFD", cursor: "pointer" }}
                      onClick={(e) => handleView(e)}
                      name={`edit-icon-stock-name-${idx}`}
                      id={`edit-icon-stock-id-${idx}`}
                    />
                  </td>
                 
                </tr>
              ))}
            </tbody>
          </Table>
          </form>
      : 
      <div className="container-spinner">
     <div className="vertical-center-spinner">
      {/*<Button variant="primary" disabled>*/}
      <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
      {/*</Button>*/}
      </div>
      </div>
}
    </Container>
         
  );
};

export default ListStocks;
