import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  Spinner,
  Modal,
  Image
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
import Sidebar from "./Sidebar";
import Sidebar2 from "./Sidebar2";
import Topmenu from "./Topmenu";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
//import DateTimeField from "react-bootstrap-datetimepicker"
import DateTimePicker from "react-datetime-picker";

import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client, ConfigMinValue, configMaxValue, appGlobal } from "../config/Config";
import axios from "axios";

import { useLocation } from "react-router-dom";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import { BsCartX, BsArrowLeftCircle, BsFillXCircleFill } from "react-icons/bs";
import SidebarMain from "./SidebarMain";
import {getDistrictList, getStateList, getVehicleList, getDriverList, getProductsSell } from "./utils";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';

const AddUpdateDC = (props) => {
  const initialized = useRef(false);
  const navigate = useNavigate();
  const { state } = useLocation();

  //console.log("Product List : ", state.prod_list);

  console.log("STATE VALUE : ", state);
  if (state === null) {
    navigate("/");
  } else {
    console.log("PASSED STATE VALUE IN DC Add/Update page : ", state);
  }

  const [passedValue, setPassedValue] = useState({});
  const [date, setDate] = useState(new Date());
  //setPassedValue(state);

  /*
    let initialItems = [
      {id:1, product: {value:3, label:"M15"}, price: "4900", quantity: 5, "total_item_price": "24500", "gst": "4410", "total_price":"28910", gst:{cgst: 2205.00, sgst:2205.00, igst:0.00, totGst: 4410.00}}, 
      {id:1, product: {value:6, label:"M25"}, price: "4800", quantity: 7, "total_item_price": "215001", "gst": "38701", "total_price":"253701", gst:{cgst: 2205.00, sgst:2205.00, igst:0.00, totGst: 4410}}
    ]
    */

  /*
    const itemProdOptions = [
      { value: 1, label: 'M5', stock_id: 1 },
    ];
    */
  let initialItems = [];

  /*
    const options = [
        { value: 1, label: 'M5', stock_id: 1 },
        { value: 2, label: 'M7.5', stock_id: 2 },
        { value: 6, label: 'M10', stock_id: 3 },
        { value: 7, label: 'M15', stock_id: 4 },
        { value: 8, label: 'M20', stock_id: 5 },
        { value: 9, label: 'M25', stock_id: 6 },
        { value: 10, label: 'M30', stock_id: 7 }
    ];
    */
/*
  let product_list = [
    {
      product_id: 1,
      //stock_id: 1,
      product_name: "M5",
      unit_price: 3900,
      cgst: 9.0,
      sgst: 9.0,
      igst: 0.0,
    },
    {
      product_id: 2,
      stock_id: 13,
      product_name: "M7.5",
      unit_price: 3900,
      cgst: 9.0,
      sgst: 9.0,
      igst: 0.0,
    },
    {
      product_id: 6,
      stock_id: 14,
      product_name: "M10",
      unit_price: 4200,
      cgst: 9.0,
      sgst: 9.0,
      igst: 0.0,
    },
    {
      product_id: 7,
      stock_id: 15,
      product_name: "M15",
      unit_price: 4600,
      cgst: 9.0,
      sgst: 9.0,
      igst: 0.0,
    },
    {
      product_id: 8,
      stock_id: 16,
      product_name: "M20",
      unit_price: 5000,
      cgst: 9.0,
      sgst: 9.0,
      igst: 0.0,
    },
    {
      product_id: 9,
      stock_id: 17,
      product_name: "M25",
      unit_price: 5400,
      cgst: 9.0,
      sgst: 9.0,
      igst: 0.0,
    },
    {
      product_id: 10,
      stock_id: 18,
      product_name: "M30",
      unit_price: 5800,
      cgst: 9.0,
      sgst: 9.0,
      igst: 0.0,
    },
    {
      product_id: 13,
      stock_id: 19,
      product_name: "M40",
      unit_price: 6000,
      cgst: 9.0,
      sgst: 9.0,
      igst: 0.0,
    },
  ];
  */
  /*

  const optionsDistrict = [
    { value: 1, label: "Coimbatore" },
    { value: 2, label: "Tiruppur" },
    { value: 3, label: "Erode" },
    { value: 4, label: "Karur" },
    { value: 5, label: "Namakkal" },
    { value: 6, label: "Ooty" },
    { value: 7, label: "Salem" },
  ];

  const optionsState = [
    { value: 1, label: "Tamil Nadu" },
    { value: 2, label: "Kerala" },
    { value: 3, label: "Karanataka" },
    { value: 4, label: "Andra Pradesh" },
    { value: 5, label: "Telungana" },
    { value: 6, label: "Pondichery" },
  ];

  const vehicleArr = [
    { value: 4, label: "TN 20 AS 4152" },
    { value: 5, label: "TN 18 C 8033" },
    { value: 6, label: "TN 39 DX 7296" },
    { value: 7, label: "TN 39 DX 7257" },
    { value: 8, label: "TN 39 DX 9927" },
    { value: 9, label: "TN 39 DX 2599" },
  ];

  const driverArr = [
    { value: 3, label: "Prabhu" },
    { value: 4, label: "Deva" },
    { value: 5, label: "Mathesh" },
    { value: 6, label: "Murukeshan" },
    { value: 7, label: "Shanmugaraj" },
    { value: 8, label: "Ayyappan" },
    { value: 9, label: "Azhagu Raja" },
    { value: 10, label: "Balasubramani" },
  ];
  */

  const optionsProdLoad = [];

  const [optionDriver, setOptionDriver] = useState([]);
  const [optionVehicle, setOptionVehicle] = useState([]);

  const [vehicleLoad, setVehicleLoad] = useState(false);
  const [driverLoad, setDriverLoad] = useState(false);

  const [submitAction, setSubmitAction] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showMsgBody, setShowMsgBody] = useState("");
  const [showMsgTitle, setShowMsgTitle] = useState("");
  const [dialogResStatus, setDialogResStatus] = useState("success");

  const handlePostSaveOk = async() => {
    console.log("handleSaveOk clicked ...");
    if ( dialogResStatus === 'success' ) {
      navigate(-1);
    }
  }

  const handleCloseDialog = async() => {
    console.log("handleCloseDialog clicked ...");
    if ( dialogResStatus === 'success' ) {
      navigate(-1);
    }
  }


  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");

    /*
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("-");
    */
  }

  const formateTime = (currTime) => {
    var h = (currTime.getHours() < 10 ? "0" : "") + currTime.getHours();
    var m = (currTime.getMinutes() < 10 ? "0" : "") + currTime.getMinutes();
    var hrMin = h + ":" + m;
    return hrMin;
  };

  //const intialValues = {company:"", address: "", city:"", zip:"", gst_number: "", phone: "", district: "", state: "", product:{value:"m15", label:"M15"}};
  const intialValues = {
    contact_person: "",
    contact_number: "",
    email: "",
    address1: "",
    address2: "",
    pincode: "",
    website: "",
    product: { value: "m15", label: "M15" },
    district_id: 1,
    state_id: 1,
    dc_date: formatDate(new Date()),
    loading_start_time: formateTime(new Date()),
    loading_end_time: formateTime(new Date()),
    driverId: 1,
    vehicleId: 1,
    pumping_charges: 0.00,
    diesel_charges: 0.00,
    amount: 0.00,
    reason: "",
    ewaybill_number: "",
    //volume:1
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [productInfo, setProductInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [stateIdSelect, setStateIdSelect] = useState({});

  const [districtIdSelect, setDistrictIdSelect] = useState({});

  const [vehicleIdSelect, setVehicleIdSelect] = useState({});

  const [driverIdSelect, setDriverIdSelect] = useState({});

  const [optDist, setOptDist] = useState([]);
  const [optState, setOptState] = useState([]);

  const [optVehicle, setOptVehicle] = useState([]);

  const [optDriver, setOptDriver] = useState([]);

  const [optionsSearch, setOptionsSearch] = useState([]);

  const [companySearchText, setCompanySearchText] = useState("");

  const [searchedCompany, setSearchedCompany] = useState("");

  const [optionsProd, setOptionsProd] = useState([]);

  const [validateError, setValidateError] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [showMsg, setShowMsg] = useState("");
 const [msgType, setMsgType] = useState("success");
  const [show, setShow] = useState(false);

  var validateErr = false;

  //type ValuePiece = Date | null;
  //type Value = ValuePiece | [ValuePiece, ValuePiece];

  //const [value, onChange] = useState<Value>(new Date());

  //const [searchText, setSearchText] = useState('');
  function convertDatePickerTimeToMySQLTime(str) {
    var month, day, year, hours, minutes, seconds;
    var date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    hours = ("0" + date.getHours()).slice(-2);
    minutes = ("0" + date.getMinutes()).slice(-2);
    seconds = ("0" + date.getSeconds()).slice(-2);

    var mySQLDate = [date.getFullYear(), month, day].join("-");
    var mySQLTime = [hours, minutes, seconds].join(":");
    return [mySQLDate, mySQLTime].join(" ");
  }

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

    const toSqlDatetime = (inputDate) => {
    const date = new Date(inputDate);
    const dateWithOffest = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    return dateWithOffest.toISOString().slice(0, 19).replace("T", " ");
  };

  const onChangeDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);

    /*
    var formattedDt = toSqlDatetime(e);
    setFormValues({ ...formValues, ["dc_date"]: formattedDt });
    console.log("Formatted str DC date : ", formattedDt);
    */
    setFormValues({ ...formValues, ["dc_date"]: e.target.value });
  };

  const onChangeLoadingStartTime = (e) => {
    console.log("Date onChange Loading time called ...", e);
    console.log("Date onChange Loading time TIME called ...", e.target.value);
    /*
    var formattedDt = toSqlDatetime(e);

    setFormValues({ ...formValues, ["loading_start_time"]: formattedDt });
    console.log("Formatted str Loading time : ", formattedDt);
    */
    setFormValues({ ...formValues, ["loading_start_time"]: e.target.value });
  };

  const onChangeLoadingEndTime = (e) => {
    console.log("Date onChange Loading time called ...", e);
    console.log("Date onChange Loading time TIME called ...", e.target.value);
    // var formattedDt = toSqlDatetime(e);

    setFormValues({ ...formValues, ["loading_end_time"]: e.target.value });
    //console.log("Formatted str Loading time : ", formattedDt);
  };

  const handleValueChange = async (e, data) => {
    e.preventDefault();
    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    setFormValues({ ...formValues, [name]: value });

    //console.log("Clicked data : ", data);

    if (name === "contact_number" || name === "pincode") {
      console.log("Inside check ******************");
      var valueTmp = Math.round(e.target.value.replace(/\D/g, ""));
      setFormValues({ ...formValues, [name]: valueTmp });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  /*
  const handleSearch = (e, dat) => {
    console.log("Handled search method called ...", e); 
    getDistrictList(stateIdInput, stateNameInput);
  };
  */

  const handleDistrictSelect = (e, data) => {
    console.log("handleDistrictSelect called ...", e);

    setDistrictIdSelect(e);
  };

  const handleVehicleSelect = (e, data) => {
    console.log("handleVehicleSelect called ...", e);

    setVehicleIdSelect(e);
  };

  const handleDriverSelect = (e, data) => {
    console.log("handleDriverSelect called ...", e);

    setDriverIdSelect(e);
  };

  const handleStateSelect = async(e, data) => {
    console.log("handleStateSelect called ...", e);
    setStateIdSelect(e);
    var [retStateDist, distArrRet] = await getDistrictList(e.value);
    if ( retStateDist) {
      setOptDist(distArrRet);
      setDistrictIdSelect(distArrRet[0]);
    }
  };

  const handleChange = (e, data) => {
    console.log("Handle Change clicked : ", e);
    console.log("Handle Change clicked target : ", e.target);
    console.log("Handle Change clicked current target : ", e.currentTarget);
    //console.log("HTML tag : ", data);
    const { name, value } = e.target;
    console.log("Captured Volume name : ", name);
    console.log("Captured Volume value : ", value);
    console.log("Captured Total Volume name : ", name);
    console.log("Captured Total Volume value : ", value);
    //setFormValues({...formValues, [name]: value});
    console.log("FORM VALUES at handleChange func : ", formValues);

    var currentId = name.substring(name.lastIndexOf("-") + 1);
    var nameOfElement = name.substring(0, name.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    console.log(
      "<<<<<<<<<<<<<<<<< ITEM SELECTED ********** ",
      items[currentId]
    );
    var itemSelected = items[currentId];
    console.log("Product: ", itemSelected.product_id);
    console.log("Product: ", itemSelected.product_name);
    console.log("Price : ", itemSelected.unit_price);
    console.log("Product cost : ", itemSelected.total_item_price);
    console.log("GST : ", itemSelected.gst);
    console.log("Total cost : ", itemSelected.total_price);
    console.log("Quantity : ", itemSelected.quantity);

    console.log("Product Info : ", state.passed_element.prod_list);


    var eVal = { value : itemSelected.product_id, label: itemSelected.product_name };
    var dc_prod_cnt = calculateDCCount(eVal);
    console.log("total Delivered qty at handleChange: ", dc_prod_cnt);

    var prod_ordered_qty = getOrderedQty(eVal);
    console.log("Product Ordered Qty at handleChange: ", prod_ordered_qty);

    var qty_balance_dc = prod_ordered_qty - dc_prod_cnt;
    qty_balance_dc = Math.round((qty_balance_dc + Number.EPSILON) * 100) / 100

    console.log("Balance qty to be delivered : ", qty_balance_dc);

    itemSelected.balance_dc = qty_balance_dc;

    const currentProductIndex = state.passed_element.prod_list.findIndex((productInd) => productInd.product_name === itemSelected.product_name);
    console.log("Currently selected row product id at handleChange : ", currentProductIndex);
                              
    var currAvaialble = parseFloat(itemSelected.balance_dc);
    if ( state.action === 'edit' || state.action === 'view') {
      const currentItemIndex = state.passed_element.dcInfo.items.findIndex((itemInd) => itemInd.product_name === itemSelected.product_name);
      console.log("Currently selected row ITEM VALUE at handleChange : ", currentItemIndex);

      console.log("EXITING QUAAAAAANNNNNNTTTTTTIIIIIITTTTTTYYYYYY : ", state.passed_element.dcInfo.items[currentItemIndex].quantity);
      currAvaialble = parseFloat(itemSelected.balance_dc) + parseFloat(state.passed_element.dcInfo.items[currentItemIndex].quantity);
    }
    console.log("CURRENTLY AVAILABLE**************** : ", currAvaialble);
    console.log("USER TYPED VALUE&&&&&&&&&&&&&&&&&&& : ", e.currentTarget.value);
    if (currentProductIndex < 0) {
      itemSelected.error = "Please select product from list";

      const newitems = items.slice();
      newitems[currentId] = itemSelected;
      setItems(newitems);

      return 0;
    } 
    //else if ( nameOfElement === "quantity" && e.target.value.match(appGlobal.numericRegExp) && ((itemSelected.balance_dc + itemSelected.quantity) < e.target.value) ) {
    else if ( nameOfElement === "quantity" && e.target.value.match(appGlobal.numericRegExp) && currAvaialble < e.target.value ) {  
      itemSelected.qty_error = "Please select valid quantity";

      const newitems = items.slice();
      newitems[currentId] = itemSelected;
      setItems(newitems);

      return 0;
    }
    else if ( nameOfElement === "unit_price" && !e.target.value.match(appGlobal.numericRegExp)  ) {
      itemSelected.qty_error = "Please type valid unit price";

      const newitems = items.slice();
      newitems[currentId] = itemSelected;
      setItems(newitems);

      return 0;
    }
    else {
      itemSelected.error = "";
      itemSelected.qty_error = "";
    }
    console.log("Currently selected row product value : ", state.passed_element.prod_list[currentProductIndex]);

    // Commented on 08-08-2023 05:55:45 PM By Rayappan Antoniraj
    //itemSelected.product = {value: productInfo[currentProductIndex].product_id, label: productInfo[currentProductIndex].product_name, stock_id: productInfo[currentProductIndex].stock_id};

    //itemSelected.unit_price = productInfo[currentProductIndex].unit_price;

    if (nameOfElement === "quantity" || nameOfElement === "unit_price" || nameOfElement === "pumping_charges" || nameOfElement === "diesel_charges") {
      console.log("Product Quantity item edited ...");
      if (e.target.value.match(appGlobal.numericRegExp)) {
        itemSelected[nameOfElement] = e.target.value;
      }
    } else {
      console.log("Other than handled option clicked so skipping it ...");
    }
      /*
      const valueTmp = Math.max(
        ConfigMinValue,
        Math.min(configMaxValue, Number(e.target.value))
      );
      */
     /*
      var valueTmp = e.target.value.replace(/[^0-9.]/g, "");
      //.replace(/(\..*)\./g, "$1");
      console.log("Matched value >>>>>>>>>>>>>>> : ", valueTmp);
      itemSelected[nameOfElement] = valueTmp;
    } else if (nameOfElement === "unit_price") {
      console.log("Product unit cost item edited ...");
      itemSelected.unit_price = value;
    } else {
      console.log("Other than handled option clicked so skipping it ...");
    }
    */
    {
      /*
      itemSelected.quantity = valueTmp;
    } else if (nameOfElement === "prod-unit-cost") {
      console.log("Product unit cost item edited ...");
      itemSelected.unit_price = value;
    } else {
      console.log("Other than handled option clicked so skipping it ...");
    }
    //itemSelected.total_item_price = productInfo[currentProductIndex].unit_price * itemSelected.quantity;
    //itemSelected.unit_price = productInfo[currentProductIndex].unit_price;
  */
    }
    itemSelected.total_item_price = itemSelected.unit_price * itemSelected.quantity;
    console.log("<<<<<<<<<<<<<<<<<<< PASSED ORDER INFO +++++++++++++++ : ", passedValue.passed_element.order_info);
    if ( passedValue.passed_element.order_info.bill_gst_include === true ) {
      itemSelected.cgst = (itemSelected.total_item_price * state.passed_element.prod_list[currentProductIndex].cgst_perc) / 100;
      itemSelected.sgst = (itemSelected.total_item_price * state.passed_element.prod_list[currentProductIndex].sgst_perc) / 100;
      itemSelected.igst = (itemSelected.total_item_price * state.passed_element.prod_list[currentProductIndex].igst_perc) / 100;
    }

    itemSelected.cgst_perc = state.passed_element.prod_list[currentProductIndex].cgst_perc;
    itemSelected.sgst_perc = state.passed_element.prod_list[currentProductIndex].sgst_perc;
    itemSelected.igst_perc = state.passed_element.prod_list[currentProductIndex].igst_perc;

    let totalGst = itemSelected.cgst + itemSelected.sgst + itemSelected.igst;
    totalGst = 0.00;
    //var gst = { cgst: cgstTmp, sgst: sgstTmp, igst: igstTmp, totGst: totalGst}
    //itemSelected.gst = gst;
    //itemSelected.total_item_price = productInfo[currentProductIndex].unit_price * itemSelected.quantity;

    itemSelected.total_price = parseFloat(itemSelected.total_item_price) + parseFloat(totalGst);

    console.log("Modified unit cost : ", itemSelected.unit_price);
    console.log("Modified volume : ", itemSelected.quantity);
    console.log("Modified Balance volume : ", itemSelected.balance_dc);
    console.log("Modified Product cost : ", itemSelected.total_item_price);
    console.log("Modified Total cost : ", itemSelected.total_price);

    const newitems = items.slice();
    newitems[currentId] = itemSelected;
    setItems(newitems);
  };

 /*
  const getDriverList = async (driverIdInput, driverNameInput) => {
    console.log("getDriverList method called ...");
    let reqLoginPayload = {
      driverId: driverIdInput,
      driverName: driverNameInput,
    };

   
    console.log("payload:", reqLoginPayload);

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(
        client.domain + "/hrms/getDrivers",
        { headers },
        { withCredentials: false }
      );
     
      console.log("Response from server getDriverList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log(
          "Response message from server getDriverList : ",
          res.data.detail.data
        );
        console.log(
          "Length of driver array : ",
          res.data.detail.data.length
        );
        //optionsDistrict = res.data.detail.res_data.district_list

        if (res.data.detail.data.length > 0) {
          var driverArr = [];
          res.data.detail.data.map((driver) => {
            console.log("Driver ind data : ", driver);
            var driverInd = {};
            driverInd.value = driver.id;
            driverInd.label = driver.name;
            driverArr.push(driverInd);
          
          })
          console.log("Driver Arr : ", driverArr);  
          setOptDriver(driverArr);
          setDriverIdSelect({
            value: res.data.detail.data[0].id,
            label: res.data.detail.data[0].name,
          });
        }
        //setDistrictIdSelect(res.data.detail.res_data.district_list);

      }
     
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
  };
*/


  const autoPopulateDCItems = async() => {
    var eCal = { value : state.passed_element.prod_list[0].product_id, label: state.passed_element.prod_list[0].product_name};
    var dc_prod_cnt = calculateDCCount(eCal);
    console.log("total Delivered qty : ", dc_prod_cnt);

    var prod_ordered_qty = getOrderedQty(eCal);
    console.log("Product Ordered Qty : ", prod_ordered_qty);

    var qty_balance_dc = prod_ordered_qty - dc_prod_cnt;
    qty_balance_dc = Math.round((qty_balance_dc + Number.EPSILON) * 100) / 100

    console.log("Balance qty to be delivered : ", qty_balance_dc);

    //itemSelected.balance_dc = qty_balance_dc;

    const newitems = items.slice();
    let itemNew = {
      id: 1,
      //product: { value: 0, label: "" },
      unit_price: state.passed_element.prod_list[0].unit_price,
      quantity: 1.00,
      total_item_price: state.passed_element.prod_list[0].unit_price,
      total_price: state.passed_element.prod_list[0].unit_price,
      cgst: 0.00,
      sgst: 0.00,
      igst: 0.00,
      cgst_perc: 0.00,
      sgst_perc: 0.00,
      igst_perc: 0.00,
      dc_item_id: 0,
      value: 0,
      label: "",
      product_id: state.passed_element.prod_list[0].product_id,
      product_name: state.passed_element.prod_list[0].product_name,
      order_item_id: state.passed_element.prod_list[0].id,
      balance_dc: qty_balance_dc,
      total_dc: dc_prod_cnt,
      //volume:1
    };
    newitems.push(itemNew);
    setItems(newitems);
  }
 
  const [dcReportData, setDcReportData] = useState({});
  const [toRender, setToRender] = useState(false);

  useEffect(() => {
    if (!initialized.current) {
      console.log("*************** useEffect called ##########################");
      console.log(formErrors);
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        console.log(formErrors);
      }
      
      var prodListFromOrder = [];
      if (state !== null) {
        console.log("PASSED STATE VALUE : ", state);
        setPassedValue(state);
        state.passed_element.prod_list.map((itemProduct) => {
          console.log("Product List AddUpdateDc: ", itemProduct);
          
          var prodFromOrder = {
            value: itemProduct.product_id,
            label: itemProduct.product_name,
            //stock_id: itemProduct.stock_id,
            item_id: itemProduct.id,
          };
          
          prodListFromOrder.push(prodFromOrder);
        });
        console.log("Product List from order AddUpdateDC : ", prodListFromOrder);
        setOptionsProd(prodListFromOrder);

        /*
        if (state.action === "edit") {
          console.log("PASSED DC INFO : ", state.passed_element.dcInfo);

          getDcDetailsEdit(state.passed_element.order_id, state.passed_element.dc_id);
        }
        */

        //setFormValues({ ...formValues, ["address1"]: state.passed_element.order_address.delivery_address.address_line1});
        if ( state.action === 'add') {
          formValues["address1"] = state.passed_element.order_address.delivery_address.address_line1;
          formValues["pincode"] = state.passed_element.order_address.delivery_address.pincode;
          formValues["contact_person"] = state.passed_element.order_address.delivery_address.contact_name;
          formValues["contact_number"] = state.passed_element.order_address.delivery_address.contact_number;
          setFormValues({ ...formValues, ["address2"]: state.passed_element.order_address.delivery_address.address_line2});
        }
        else if ( state.action === 'view' || state.action === 'edit' ) {
          // Will be fetched by api 
        }
        else {
          formValues["address1"] = state.passed_element.order_address.delivery_address.address_line1;
          formValues["pincode"] = state.passed_element.order_address.delivery_address.pincode;
          formValues["contact_person"] = state.passed_element.order_address.delivery_address.contact_name;
          formValues["contact_number"] = state.passed_element.order_address.delivery_address.contact_number;
          setFormValues({ ...formValues, ["address2"]: state.passed_element.order_address.delivery_address.address_line2}); 
        }
      }
      
      ( async() => {
        //await getDistrictListWrapper();
        var stateIdTmp = 1;
        if ( state.action === 'view' || state.action === 'edit') {
          stateIdTmp = state.passed_element.state_id;
        }
        var [retStateDist, distArrRet] = await getDistrictList(stateIdTmp);
        if ( retStateDist) {
          setOptDist(distArrRet);
          if ( state.action === 'add') {
            //setDistrictIdSelect(distArrRet[0]);
            var findDist = distArrRet.find(element => element.label === appGlobal.DEFAULT_DISTRICT);
            if (findDist !== undefined) {
              console.log("++++++++++++++++++------------------- FOUND DISTRICT : ", findDist);
              setDistrictIdSelect(findDist);
            }
          }
          else {
            var findDist = distArrRet.find(element => element.value === state.passed_element.district_id);
            if ( findDist !== undefined ) {
              console.log("++++++++++++++++++------------------- FOUND DISTRICT : ", findDist);
              setDistrictIdSelect({value: state.passed_element.district_id, label: findDist.label});
            }
          }
        }

        var country_id = appGlobal.COUNTRY_ID;
        var [retStateStList, stateArrRet] = await getStateList(country_id);
        if ( retStateStList ) {
          setOptState(stateArrRet);
          if ( state.action === 'add' ) {
            setStateIdSelect(stateArrRet[0]);
          }
          else {
            var findState = stateArrRet.find(element => element.value === state.passed_element.state_id);
            if ( findState ) {
              console.log("STATE FOUND OBJECT : ", findState);
              setStateIdSelect({value: state.passed_element.state_id, label: findState.label});
            }
        }
        }
        
        var [retStateSellProd, newSellProdArr] = await getProductsSell();
        if ( retStateSellProd ) {
          setProductInfo(newSellProdArr);
        }

        var [retStateDriver, driverArr] = await getDriverList();
        if (retStateDriver) {
          setOptDriver(driverArr);
          if ( state.action === 'add' ) {
            setDriverIdSelect(driverArr[0]);
          }
          setDriverLoad(true);
        }

        var [retStateVehicle, vehicleArr] = await getVehicleList();
        if ( retStateVehicle ) {
          setOptVehicle(vehicleArr);
          if ( state.action === 'add' ) {
            setVehicleIdSelect(vehicleArr[0]);
          }
          setVehicleLoad(true);
        }

        if ( state.action === "edit" || state.action === 'view' ) {
          console.log("PASSED DC INFO : ", state.passed_element.dcInfo);
          await getDcDetailsEdit(state.passed_element.order_id, state.passed_element.dc_id);
        }

        if ( state.action === 'add') {
          await autoPopulateDCItems();
        }
      })();
       
      
      initialized.current = true;
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit called ...");
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log("^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& : ", validateError);
    console.log("^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ", validateErr);

    if (items.length < 1) {
      setShowMsg("Please add atleast one product in order");
      setMsgType("warning");
      setShow(true);
      return 0;
    }
    {
      /*
    console.log("Currently selected row product id : ", currentProductIndex);
    if (currentProductIndex < 0) {
      itemSelected.error = "Please select product from list";

      const newitems = items.slice();
      newitems[currentId] = itemSelected;
      setItems(newitems);
      return 0;
    } else {
      itemSelected.error = "";
    }
  */
    }

    {
      /*
    var emptyItem = false;
    items.map((item) => {
      console.log("Item value : ", item);
      if (item.product_id < 1 || !item.product.product_name) {
        item.error = "Please select product from list";
        //setShowMsg("Please selecyt product from dropdown");
        //setMsgType("warning");
        //setShow(true);
        emptyItem = true;
        return 0;
      } else {
        item.error = "";
      }
    });
  */
    }

    console.log("<<<<<<<<<<<<<<<< API calling continue >>>>>>>>>>>>>>>>>>");
    if (emptyItem) {
      console.log("unselcted product item so exit from submission ...");
      return 0;
    }

    if (validateErr === false) {
      console.log("FORM VALUES at handleSubmit: ", formValues);
      console.log("LINE ITEMS : ", items);
      console.log("Form submit called ...");

      console.log("DC Date : ", formValues["dc_date"]);
      console.log("ORDER ID : ", passedValue.passed_element.order_id);
      console.log("CUSTOMER ID : ", passedValue.passed_element.customer_id);
      console.log("Vehicle ID select value: ", vehicleIdSelect.value);
      console.log("Vehicle ID select name: ", vehicleIdSelect.label);
      //console.log("Vehicle ID select value : ", formValues["vehicleIdSelect"].value);
      console.log("Driver ID select value: ", driverIdSelect.value);
      console.log("Driver ID select name: ", driverIdSelect.label);

      console.log("DISTRICT ID select value: ", districtIdSelect.value);
      console.log("STATE ID select name: ", stateIdSelect.label);
      //console.log("GST Number : ", formValues["gst_number"]);
      //console.log("Phone Number : ", formValues["phone"]);

      console.log("Tot number of items : ", total_items);
      console.log("Sum of Tot Product Cost : ", sum_product_cost);
      console.log("Sum of Tot GST : ", sum_product_gst);
      console.log("Sum of total cost : ", sum_product_total);

      //console.log("SEARCHED COMPANY : ", searchedCompany);

      /*
          var company_name_tmp = '';
          var company_id_tmp = 0;
          if (!searchedCompany || searchedCompany.length < 1 ) {
            company_name_tmp = companySearchText;
            company_id_tmp = 0; 
          }
          else {
            company_name_tmp = searchedCompany.label;
            company_id_tmp = searchedCompany.value;
          }

          console.log("Company name to submit : ", company_name_tmp);
          */
      var addrIdTmp = 0;
      var dcIdTmp = 0;
      var dcNoTmp = "";
      if (state.action === "view" || state.action === "edit") {
        addrIdTmp = formValues["address_id"];
        dcIdTmp = formValues["dc_id"];
        dcNoTmp = formValues["dc_no"];
      }

      if (items.length < 1) {
        setShowMsg("Please add atleast one product in order");
        setMsgType("warning");
        setShow(true);
        return 0;
      }

      var emptyItem = false;
      items.map((item) => {
        console.log("Item value : ", item);
        if (item.product_id < 1 || !item.product_name) {
          item.error = "Please select product from list";
          //setShowMsg("Please selecyt product from dropdown");
          //setMsgType("warning");
          //setShow(true);
          emptyItem = true;
          return 0;
        } 
        else if ( !item.quantity || item.quantity < 0.01 ) {
          item.qty_error = "Please select valid qty";
          emptyItem = true;
          return 0;
        }
        else {
          item.error = "";
        }
      });

      console.log("<<<<<<<<<<<<<<<< API calling continue >>>>>>>>>>>>>>>>>>");
      if (emptyItem) {
        console.log("unselcted product item so exit from submission ...");
        return 0;
      }

      let reqAddUpdateDC = {
        /*
            company: formValues["company"],
            address: formValues["address"],
            city: formValues["city"],
            zip: formValues["zip"],

            phone: formValues["phone"],
            gst_number: formValues["gst_number"],

            district: formValues["district"],
            state: formValues["state"],
          */
        /*
          company_name: company_name_tmp,
          company_id: company_id_tmp,
          company_disp_name: formValues["company_disp_name"],
          gst_number: formValues["gst_number"],
          phone: formValues["phone"],
          primary_number: formValues["primary_number"],
          secondary_number : formValues["secondary_number"],
          contact_person: formValues["contact_person"],
          contact_number: formValues["contact_number"],
          email: formValues["email"],
          address1: formValues["address1"],
          address2: formValues["address2"],
          distId: districtIdSelect.value,
          stateId: stateIdSelect.value,
          pincode: formValues["pincode"],
          website: formValues["website"],
          */
        dc_id: dcIdTmp,
        dc_no: dcNoTmp,
        address_id: addrIdTmp,
        orderId: passedValue.passed_element.order_id,
        customerId: passedValue.passed_element.customer_id,
        dc_date: formValues["dc_date"],
        vehicleId: vehicleIdSelect.value,
        driverId: driverIdSelect.value,
        contactPerson: formValues["contact_person"],
        contactNumber: formValues["contact_number"].toString(),
        address1: formValues["address1"],
        address2: formValues["address2"],
        districtId: districtIdSelect.value,
        stateId: stateIdSelect.value,
        pincode: formValues["pincode"].toString(),
        //loadingStartTime: formValues["loading_start_time"],
        //loadingEndTime:  formValues["loadingEndTime"],

        total_items: total_items,
        sum_product_cost: sum_product_cost,
        sum_product_gst: sum_product_gst,

        sum_product_cgst: sum_product_cgst,
        sum_product_sgst: sum_product_sgst,
        sum_product_igst: sum_product_igst,

        amount_discount: amount_discount,

        sum_product_total: sum_product_total,

        pumping_charges: formValues["pumping_charges"],
        diesel_charges: formValues["diesel_charges"],

        loading_start_time:
          formValues["dc_date"] + " " + formValues["loading_start_time"],
        loading_end_time:
          formValues["dc_date"] + " " + formValues["loading_end_time"],
        //dc_id: formValues["dc_id"],
        //address_id: formValues["address_id"],

        orderItems: JSON.stringify(items),
      };

     
      console.log("payload addUpdateDC :", reqAddUpdateDC);

      var testTkn = "UJDFISDHjkdah8473bjkhgjhf";
      var headers = {...client.headers,Authorization: " " + testTkn,};

      try {
        var aIVal = localStorage.getItem("_aI");
        var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
        setSubmitAction(true);
        var res = await axios.post(
          client.domain + "/insertDC",
          reqAddUpdateDC,
          { headers },
          { withCredentials: false }
        );
        console.log("Response from server : ", res.data.detail);

        if (res.data.detail.status === "SUCCESS") {
          console.log("Response message from server : ", res.data.detail.res_data);
          //navigate(-1);
          setSubmitAction(false);
          setShowDialog(true);
          setShowMsgTitle('Success');
          setShowMsgBody("DC inserted Successfully!");
          setDialogResStatus('success');
        }
        else {
          setSubmitAction(false);
          setShowDialog(true);
          setShowMsgTitle('Failed');
          setShowMsgBody("DC insertion failed!");
          setDialogResStatus('failure');
        }
      } catch (error) {
        console.log("FULL ERROR : ", error);
        setSubmitAction(false);
        setShowDialog(true);
        setShowMsgTitle('Warning');
        setShowMsgBody("Unable to process your request at this moment.Please try after sometime!");
        setDialogResStatus('failure');
        /*
        if ( error.code === 'ERR_NETWORK' ) {
          console.log("Not able to reach target server please try after sometime");
          setShowMsg('Please try after sometime');
          setShowMsgTitle('Network Issue!');
          setShow(true);
        }
        else{
          console.log("ERROR : ", error.response.data.detail);
          if (error.response.data.detail.status === 'FAILED') {
            console.log('Request failed due to ', error.response.data.detail.res_data);
            //setShowMsg('Please Re-Login');
            //setShowMsgTitle('Invalid Session!');
            //setShow(true);
            navigate("/auth");
          }
        }
        */
      }
    }
  };
  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    if ( values.contact_number ) {
    console.log("contact number length : ", values.contact_number);
    }
    if ( values.pincode ) {
      console.log("pincode number length : ", values.pincode);
    }

    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;
   

    if (!companySearchText && !searchedCompany) {
      //if (!values.company){
      //errors.company = "Company is required!";
    }

    /*
    if (!values.contact_person) {
      errors.contact_person = "Contact person is required";
      validateErr = true;
      setValidateError(true);
    }

    if (!values.contact_number) {
      errors.contact_number = "Contact person number is required";
      validateErr = true;
      setValidateError(true);
    }
    */

    if (values.contact_number && values.contact_number.toString().length != 10) {
      errors.contact_number = "Contact number should be 10 digits!";
      validateErr = true;
    }

    if (!values.address1) {
      errors.address1 = "Street / Location name is required!";
      validateErr = true;
      setValidateError(true);
    }
    if (driverIdSelect.value < 1) {
      console.log("driverIdSelect VALUE : ", driverIdSelect);
      errors.driverId = "Driver name is required!";
      validateErr = true;
      setValidateError(true);
    }

    /*
    if (!values.pincode) {
      errors.pincode = "Pincode is required!";
      validateErr = true;
      setValidateError(true);
    }
    */
   
    if (values.pincode && values.pincode.toString().length != 6) {
      errors.pincode = "Pincode should be 6 digits!";
      validateErr = true;
    }

    if (vehicleIdSelect.value < 1) {
      console.log("vehicleIdSelect VALUE : ", vehicleIdSelect);
      errors.vehicleId = "Vehicle Number is required!";
      validateErr = true;
      setValidateError(true);
    }

    items.map((item)=> {
      if (!item.quantity) {
        item.quantity_err = "Volume is required!";
        validateErr = true;
        setValidateError(true);
      }
      else {
        item.quantity_err = "";
      }
  
      if (!item.unit_price) {
        item.unit_price_err = "Unit price is required!";
        validateErr = true;
        setValidateError(true);
      }
      else {
        item.unit_price_err = "";
      }
    })

    console.log("BEFORE RETURN validate errors ...", validateError);
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };

  const saveHandler = async (event) => {
    // Prevent page reload
    event.preventDefault();

    console.log("Form submit clicked ...");

    console.log("DATA : ", document.forms[0]);

    /*
        var { username, password } = document.forms[0];
        console.log("username : ", username.value);
        console.log("pasword : ", password.value);
        */
  };

  const backHandler = async (event) => {
    event.preventDefault();
    console.log("Back button clicked ...");
  };
  const handleClear = async (e) => {
    e.preventDefault();
    console.log("handle clear called : ", e);
    console.log("Handle clear clicked current target ID: ", e.currentTarget.id);
    navigate(0);
  };

  const newItemHandle = async (event) => {
    event.preventDefault();
    console.log("New item addition button clicked ...");

    const newitems = items.slice();
    let itemNew = {
      id: 1,
      //product: { value: 0, label: "" },
      unit_price: 0.00,
      quantity: "",
      total_item_price: 0.00,
      total_price: 0.00,
      cgst: 0.00,
      sgst: 0.00,
      igst: 0.00,
      cgst_perc: 0.00,
      sgst_perc: 0.00,
      igst_perc: 0.00,
      dc_item_id: 0,
      value: 0,
      label: "",
      order_item_id: 0
    };
    newitems.push(itemNew);
    setItems(newitems);

    console.log("FULL ITEM VALUE at newItemHandle : ", items);
  };

  const calculateDCCount = (e) => {
    var product_dc_cnt = 0;
    console.log("Product ID at calculateDCCount: ", e.value);
    console.log("Product NAME at calculateDCCount: ", e.label);
    
    console.log("DC list : ", state.passed_element.dc_list);

    state.passed_element.dc_list.map((dc_ind) => {
      dc_ind.items.map((item) => {
        if ( e.value === item.product_id ) {
          console.log("DC product ID at calculateDCCount : ", item.product_id);
          console.log("DC product NAME at calculateDCCount : ", item.product_name);
          console.log("DC product QUANTITY at calculateDCCount : ", item.quantity);
          product_dc_cnt = product_dc_cnt + item.quantity;
        }
      })
    })
    return product_dc_cnt;
  } 

  const getOrderedQty = (e) => {
    var product_ordered_qty = 0;
    console.log("Product ID : ", e.value);
    console.log("Product NAME : ", e.label);
    
    console.log("Product list : ", state.passed_element.prod_list);
    
    state.passed_element.prod_list.map((prod_info) => {
      if ( prod_info.product_id === e.value ) {
        console.log("Product ID : ", prod_info.product_id);
        console.log("Product NAME : ", prod_info.product_name);
        console.log("Product QUANTITY : ", prod_info.quantity);
        product_ordered_qty = prod_info.quantity;
      }
    })
    return product_ordered_qty;
  } 

  const handleSelect = (e, data) => {
    console.log("SELECTED Dropdown value : ", e);
    console.log("SELECTED element id : ", data);
    var currentId = data.name.substring(data.name.lastIndexOf("-") + 1);
    console.log("Current id : ", currentId);

    var itemSelected = items[currentId];
    console.log("---------------- SELECTED ITEMS ++++++++++++++++ : ", items[currentId]);
    //console.log("Product: ", itemSelected.product);
    console.log("Price : ", itemSelected.unit_price);
    console.log("Product cost : ", itemSelected.total_item_price);
    console.log("GST : ", itemSelected.cgst);
    console.log("Total cost : ", itemSelected.total_price);
    console.log("Quantity : ", itemSelected.quantity);

    console.log("Product Info : ", state.passed_element.prod_list);
    /*
    const currentProductIndex = productInfo.findIndex((productInd) => productInd.name === e.label);
    console.log("Currently selected row product id at handleSelect : ", currentProductIndex);
    console.log("Currently selected row product value : ", productInfo[currentProductIndex]);
    */
    const currentProductIndex = state.passed_element.prod_list.findIndex((productInd) => productInd.product_name === e.label);
    console.log("Currently selected row product id at handleSelect : ", currentProductIndex);
    console.log("Currently selected row product value : ", state.passed_element.prod_list[currentProductIndex]);
   
    itemSelected.id = e.value;
    //itemSelected.product = e;
    itemSelected.product_id = e.value;
    itemSelected.product_name = e.label;
    itemSelected.order_item_id = e.item_id;
    
    itemSelected.value = e.value;
    itemSelected.label = e.label;
    //itemSelected.price = productInfo[currentProductIndex].unit_price;
    //itemSelected.unit_price = productInfo[currentProductIndex].unit_price;
    itemSelected.unit_price = state.passed_element.prod_list[currentProductIndex].unit_price;
    //itemSelected.total_item_price = productInfo[currentProductIndex].unit_price * itemSelected.quantity;

    if ( passedValue.passed_element.order_info.bill_gst_include === true ) {
      itemSelected.cgst = (itemSelected.total_item_price * state.passed_element.prod_list[currentProductIndex].cgst_perc) / 100;
      itemSelected.sgst = (itemSelected.total_item_price * state.passed_element.prod_list[currentProductIndex].sgst_perc) / 100;
      itemSelected.igst = (itemSelected.total_item_price * state.passed_element.prod_list[currentProductIndex].igst_perc) / 100;
    }
    itemSelected.cgst_perc = state.passed_element.prod_list[currentProductIndex].cgst_perc;
    itemSelected.sgst_perc = state.passed_element.prod_list[currentProductIndex].sgst_perc;
    itemSelected.igst_perc = state.passed_element.prod_list[currentProductIndex].igst_perc;

    let totalGst = itemSelected.cgst + itemSelected.sgst + itemSelected.igst;

    //var gst = { cgst: cgstTmp, sgst: sgstTmp, igst: igstTmp, totGst: totalGst}
    //itemSelected.gst = gst
    itemSelected.total_item_price = state.passed_element.prod_list[currentProductIndex].unit_price * itemSelected.quantity;

    itemSelected.total_price = parseFloat(itemSelected.total_item_price) + parseFloat(totalGst);

    console.log("Modified unit cost : ", itemSelected.unit_price);
    console.log("Modified Product cost : ", itemSelected.total_item_price);
    console.log("Modified Total cost : ", itemSelected.total_price);

    var dc_prod_cnt = calculateDCCount(e);
    console.log("total Delivered qty : ", dc_prod_cnt);

    var prod_ordered_qty = getOrderedQty(e);
    console.log("Product Ordered Qty : ", prod_ordered_qty);

    var qty_balance_dc = prod_ordered_qty - dc_prod_cnt;
    qty_balance_dc = Math.round((qty_balance_dc + Number.EPSILON) * 100) / 100

    console.log("Balance qty to be delivered : ", qty_balance_dc);

    itemSelected.balance_dc = qty_balance_dc;
    //itemSelected.order_item_id = e.item_id;
    const newitems = items.slice();
    newitems[currentId] = itemSelected;
    setItems(newitems);
    //var fieldValue = "product";
    //setFormValues({ ...formValues, [fieldValue]: e });
  };

  console.log("FORM VALUES Before rendering :", formValues);

  var total_items = 0;
  var sum_product_cost = 0.0;
  var sum_product_gst = 0.0;
  var sum_product_cgst = 0.0;
  var sum_product_sgst = 0.0;
  var sum_product_igst = 0.0;

  var sum_product_total = 0.0;
  var amount_discount = 0.0;

  items.map((item) => {
    console.log("IND ITEM : ", item);
    console.log("Total item price : ", item.total_item_price);
    console.log("GST  : ", item.cgst + item.sgst + item.igst);

    console.log("CGST  : ", item.cgst);
    console.log("SGST  : ", item.sgst);
    console.log("IGST  : ", item.igst);

    console.log("Total price : ", item.total_price);
    total_items = total_items + 1;
    sum_product_cost = sum_product_cost + item.total_item_price;

    sum_product_cgst = sum_product_cgst + item.cgst;
    sum_product_sgst = sum_product_sgst + item.sgst;
    sum_product_igst = sum_product_igst + item.igst;

    //sum_product_total = sum_product_total + item.total_price;
    //sum_product_total =  sum_product_total +
  });

  sum_product_cgst = 0.00;
  sum_product_sgst = 0.00;
  sum_product_igst = 0.00;

  sum_product_gst = sum_product_cgst + sum_product_sgst + sum_product_igst;
  sum_product_total = sum_product_cost + sum_product_gst;

  sum_product_total = sum_product_total - amount_discount + parseFloat(formValues.pumping_charges) + parseFloat(formValues.diesel_charges);

  //console.log("Tot number of items : ", total_items);
  //console.log("Sum of Tot Product Cost : ", sum_product_cost);

  //console.log("Sum of Tot CGST : ", sum_product_cgst);
  //console.log("Sum of Tot SGST : ", sum_product_sgst);
  //console.log("Sum of Tot iGST : ", sum_product_igst);

  //console.log("Sum of Tot GST : ", sum_product_gst);

  //console.log("Sum of total cost : ", sum_product_total);

  //console.log("Currently selected district : ", districtIdSelect);
  //console.log("opt district : ", optDist);
  //console.log("*********** opt district value ############# : ", optDist.value);

  //console.log("Currently selected state : ", stateIdSelect);
  //console.log("+++++++++++++++ Company search text ^^^^^^^^^^^^^^^^^^^^  :", companySearchText);
  //console.log("Searched company <<<<<<<<<<<<<<<<<<< : ", searchedCompany);
  //console.log("PRODUCT LIST FROM ORDER ITEMS : ", optionsProd);

  const getDcDetailsEdit = async (orderId, dcId) => {
    let reqDCPrintReq = {
      order_id: orderId,
      dc_id: dcId,
    };
    console.log("payload DC Print :", reqDCPrintReq);

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
      var res = await axios.post(
        client.domain + "/getDCDetails",
        reqDCPrintReq,
        { headers },
        { withCredentials: false }
      );
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server DCDetails : ", res.data.detail.res_data);
        //navigate("/dashboard");
        setDcReportData(res.data.detail.res_data);

        formValues["dc_id"] = res.data.detail.res_data.dc_info.dc_id;
        formValues["dc_no"] = res.data.detail.res_data.dc_info.dc_no;
        formValues["dc_date"] = res.data.detail.res_data.dc_info.dc_date;

        setVehicleIdSelect({
          value: res.data.detail.res_data.dc_info.vehicle_id,
          label: res.data.detail.res_data.dc_info.vehicle_reg_number,
        });
        setDriverIdSelect({
          value: res.data.detail.res_data.dc_info.driver_id,
          label: res.data.detail.res_data.dc_info.driver_name,
        });
        formValues["contact_person"] = res.data.detail.res_data.dc_info.contact_person;
        formValues["contact_number"] = res.data.detail.res_data.dc_info.contact_number;

        formValues["address1"] = res.data.detail.res_data.dc_info.address_line1;
        formValues["address2"] = res.data.detail.res_data.dc_info.address_line2;

        setDistrictIdSelect({
          value: res.data.detail.res_data.dc_info.district_id,
          label: res.data.detail.res_data.dc_info.district_name,
        });
        setDriverIdSelect({
          value: res.data.detail.res_data.dc_info.driver_id,
          label: res.data.detail.res_data.dc_info.driver_name,
        });
        setStateIdSelect({
          value: res.data.detail.res_data.dc_info.state_id,
          label: res.data.detail.res_data.dc_info.state_name,
        });
        setVehicleIdSelect({
          value: res.data.detail.res_data.dc_info.vehicle_id,
          label: res.data.detail.res_data.dc_info.vehicle_reg_number,
        });

        formValues["pincode"] = res.data.detail.res_data.dc_info.pincode;
        //formValues["loading_start_time"] = res.data.detail.res_data.dc_info.loading_start_time;
        var loadStartDateTime = new Date(
          res.data.detail.res_data.dc_info.loading_start_time
        );
        var onlyHrMin = formateTime(loadStartDateTime);
        console.log("Load Start time in hh:mm : ", onlyHrMin);
        formValues["loading_start_time"] = onlyHrMin;

        //formValues["loading_end_time"] = res.data.detail.res_data.dc_info.loading_end_time;
        var loadEndDateTime = new Date(
          res.data.detail.res_data.dc_info.loading_end_time
        );
        var onlyHrMin = formateTime(loadEndDateTime);
        console.log("Load End time in hh:mm : ", onlyHrMin);
        formValues["loading_end_time"] = onlyHrMin;
        formValues["pumping_charges"] =
          res.data.detail.res_data.dc_info.pumping_charges;
        formValues["diesel_charges"] =
          res.data.detail.res_data.dc_info.diesel_charges;

        formValues["address_id"] = res.data.detail.res_data.dc_info.address_id;

        setItems(res.data.detail.res_data.dc_item_list);

        setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        //setShowMsg('Please try after sometime');
        //setShowMsgTitle('Network Issue!');
        //setShow(true);
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg('Please try again ...');
          //setShowMsgTitle('Unable to process your request!');
          //setShow(true);
          //navigate("/auth");
        }
      }
    }
  };

  const handleItemDelete = (e) => {
    console.log("handle item delete called ...", e);
    console.log("handle item delete e.currentTarget : ", e.currentTarget);
    console.log("handle item delete e.currentTarget ID : ", e.currentTarget.id);
    console.log("handle item delete e.target : ", e.target.id);

    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(
        0,
        clickedTagId.lastIndexOf("-")
      );
      console.log("current selected DELETE element name : ", nameOfElement);
      console.log("Current SELECTED DELETE id : ", curSelId);
      console.log("FULL ITEM VALUE : ", items);
      console.log("CUR SELECTED DELETE VALUE : ", items[curSelId]);

      //const indexPos = items.indexOf(curSelId);

      const spliced = items.splice(curSelId, 1);
      console.log("Removed item from order items: ", spliced);

      var newItems = items.slice();
      setItems(newItems);
    }
  };

  const handleBack = async (e) => {
    console.log("handle back called : ", e);
    navigate(-1);
  };

 
  return (
    <>
      <Container>
        <SidebarMain />
        
        { (vehicleLoad && driverLoad) ?
        <Form>
          <Row>
            <Col xs={4} md={4} sm={4} lg={4}>
              <h4>Delivery Chellan</h4>
            </Col>
            <Col
              xs={8}
              md={8}
              sm={8}
              lg={8}
              className="d-flex justify-content-end float-end"
            >
              <Button variant="warning" className="btn btn-warning btn-md">
                <BsArrowLeftCircle
                  key={`back-btn-dc-key`}
                  size={24}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleBack(e)}
                  name={`back-icon-dc-name`}
                  id={`edit-icon-dc-id`}
                />
              </Button>
            </Col>
          </Row>

          <p></p>

          <div className="ui-divider"></div>
          <div className="ui-form">
            {/*<Row style={{ height: props.height, width: props.width * 0.7 }}>
              <hr />
            </Row>
            */}
            {/*
            <Row style={{ textAlign: "center" }}>
              <hr width="50%" />
            </Row>
            */}
            <hr align="center" />

            <Row>
              {/*
       <Col xs={2} md={2} sm={2}>
        <div className="field">
          <label>DC Quantity :</label>
        </div>
        </Col>
        
       <Col xs={2} md={2} sm={2}>
        <div className="field">
          
          <input 
          type="text" 
          name="dc_number" 
          placeholder="DC Number" 
          value={formValues.dc_number}
          onChange={handleValueChange} />
        </div>
        <p className="error-msg">{ formErrors.dc_number}</p>
        </Col>
        */}
              {/*
        <Col xs={2} md={2} sm={2}>
        <div className="field">
        <AsyncTypeahead
        id="company-search-fd-id"
        name="company-search-fd-name"
  isLoading={isLoading}
  onSearch={query => {
    searchHandleFunc(query)
  }}
  onChange={selected => {companySearchSelectFunc(selected)}}
  options={optionsSearch}
  placeholder="Choose a company ..."
/>
</div>
</Col>
*/}
              {/*
        <AsyncTypeahead
          
          id="company-search-field"
          clearButton
          isLoading={isLoading}
          onSearch={query => {
    setIsLoading(true)
    fetch(`${client.domain}/search/customers?q=${query}`)
      .then(resp => resp.json())
      .then(json => {
        setOptionsSearch(json.detail.res_data.district_list);
        console.log("SEARCHED VALUE : ", json.detail.res_data.district_list);
        setIsLoading(false)
      });
  }}
  onChange={selected => console.log("SELECTED VALUE : ", selected)}
  options={optionsSearch}
  placeholder="Choose a company ..."
        />
        */}
              <Col xs={6} md={3} sm={4} lg={2}>
                <Form.Group className="mb-3" controlId="dcform.dc_date">
                  <Form.Label>DC Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="dc_date"
                    placeholder="DC Date"
                    value={formValues.dc_date}
                    onChange={onChangeDatePicker}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} md={3} sm={4} lg={2}>
                <Form.Group
                  className="mb-3"
                  controlId="dcform.loading_start_time"
                >
                  <Form.Label>Loading start time </Form.Label>
                  {/*<DateTimePicker
                    onChange={onChangeLoadingStartTime}
                    calendarIcon={null}
                    format="dd-MM-y HH:mm:ss"
                    value={formValues.loading_start_time}
                  />*/}

                  <Form.Control
                    type="time"
                    name="loading_start_time"
                    value={formValues.loading_start_time}
                    onChange={onChangeLoadingStartTime}
                  />
                </Form.Group>
              </Col>

              <Col xs={6} md={3} sm={4} lg={2}>
                <Form.Group
                  className="mb-3"
                  controlId="dcform.loading_end_time"
                >
                  <Form.Label>Loading end time </Form.Label>
                  <Form.Control
                    type="time"
                    name="loading_end_time"
                    value={formValues.loading_end_time}
                    onChange={onChangeLoadingEndTime}
                  />
                </Form.Group>
                {/* <DateTimePicker
                    onChange={onChangeLoadingEndTime}
                    calendarIcon={null}
                    format="dd-MM-y HH:mm:ss"
                    value={formValues.loading_end_time}
                />*/}
              </Col>

              <Col xs={6} md={3} sm={4} lg={3}>
                <Form.Group className="mb-3" controlId="dcform.vehicle">
                  <Form.Label className="field required">Vehicle</Form.Label>

                  {/*
        <Col xs={2} md={2} sm={2}>
        <div className="field">
          <input 
          type="text" 
          name="vehicle_number" 
          placeholder="Vehicle Number" 
          value={formValues.vehicle_number}
          onChange={handleValueChange} />
        </div>
        <p className="error-msg">{ formErrors.vehicle_number}</p>
        </Col>
        */}
                  <Select
                    id="vehicle-dropdown"
                    name="vehicle"
                    value={{
                      value: vehicleIdSelect.value,
                      label: vehicleIdSelect.label,
                    }}
                    options={optVehicle}
                    defaultValue={{
                      value: vehicleIdSelect.value,
                      label: vehicleIdSelect.label,
                    }}
                    onChange={handleVehicleSelect}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.vehicle}</p>
              </Col>

              <Col xs={6} md={3} sm={4} lg={3}>
                <Form.Group className="mb-3" controlId="dcform.driver">
                  <Form.Label className="field required">Driver </Form.Label>
                  <Select
                    name="driver"
                    value={{
                      value: driverIdSelect.value,
                      label: driverIdSelect.label,
                    }}
                    options={optDriver}
                    defaultValue={{
                      value: driverIdSelect.value,
                      label: driverIdSelect.label,
                    }}
                    onChange={handleDriverSelect}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.driver}</p>
              </Col>
              
            </Row>

            {/*
        <Row>
       <Col xs={2} md={2} sm={2}>
        <div className="field">
          <label>Phone Number :</label>
        </div>
        </Col>
       <Col xs={2} md={2} sm={2}>
        <div className="field">
          
          <input 
          type="text" 
          name="phone" 
          placeholder="Phone" 
          value={formValues.phone}
          onChange={handleValueChange} />
        </div>
        <p className="error-msg">{ formErrors.phone}</p>
        </Col>

        <Col xs={2} md={2} sm={2}>
        <div className="field">
          <label>Primary Number :</label>
          </div>
        </Col>
        <Col xs={2} md={2} sm={2}>
        <div className="field">
          <input 
          type="text" 
          name="primary_number" 
          placeholder="Primary Number" 
          value={formValues.primary_number}
          onChange={handleValueChange} />
        </div>
        <p className="error-msg">{ formErrors.primary_number}</p>
        </Col>

        <Col xs={2} md={2} sm={2}>
        <div className="field">
          <label>Secondary Number :</label>
          </div>
        </Col>
        <Col xs={2} md={2} sm={2}>
        <div className="field">
          <input 
          type="text" 
          name="secondary_number" 
          placeholder="Secondary Number" 
          value={formValues.secondary_number}
          onChange={handleValueChange} />
        </div>
        <p className="error-msg">{ formErrors.secondary_number}</p>
        </Col>

        </Row>
      */}
            <Row>
              {/*
              <Col xs={6} md={3} sm={4} lg={2}>
                <Form.Group className="mb-3" controlId="dcform.contact_person">
                  <Form.Label  className="field">Contact Person </Form.Label>
                  <Form.Control
                    type="text"
                    name="contact_person"
                    placeholder="Contact Person"
                    value={formValues.contact_person}
                    onChange={handleValueChange}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.contact_person}</p>
              </Col>

              <Col xs={6} md={3} sm={4} lg={2}>
                <Form.Group className="mb-3" controlId="dcform.contact_number">
                  <Form.Label className="field">Contact Number </Form.Label>
                  <Form.Control
                    type="text"
                    name="contact_number"
                    placeholder="Contact Number"
                    value={formValues.contact_number}
                    onChange={handleValueChange}
                    maxLength={10}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.contact_number}</p>
              </Col>
              */}
              {/*
        <Col xs={2} md={2} sm={2}>
        <div className="field">
          <label>Email :</label>
          </div>
        </Col>
        <Col xs={2} md={2} sm={2}>
        <div className="field">
          <input 
          type="text" 
          name="email" 
          placeholder="Email" 
          value={formValues.email}
          onChange={handleValueChange} />
        </div>
        <p className="error-msg">{ formErrors.email}</p>
        </Col>
        */}

              <Col xs={6} md={3} sm={4} lg={3}>
                <Form.Group className="mb-3" controlId="dcform.address1">
                  <Form.Label className="field required">Street / Location</Form.Label>
                  <Form.Control
                    type="text"
                    name="address1"
                    placeholder="Building No / Street Name"
                    value={formValues.address1}
                    onChange={handleValueChange}
                  />
                </Form.Group>

                <p className="error-msg">{formErrors.address1}</p>
              </Col>

              <Col xs={6} md={3} sm={4} lg={3}>
                <Form.Group className="mb-3" controlId="dcform.address2">
                  <Form.Label className="field">City / Town </Form.Label>
                  <Form.Control
                    type="text"
                    name="address2"
                    placeholder="City / Town"
                    value={formValues.address2}
                    onChange={handleValueChange}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.address2}</p>
              </Col>

              <Col xs={6} md={3} sm={4} lg={2}>
                <Form.Group className="mb-3" controlId="dcform.district">
                  <Form.Label>District </Form.Label>
                  <Select
                    id="district"
                    name="district"
                    value={{
                      value: districtIdSelect.value,
                      label: districtIdSelect.label,
                    }}
                    options={optDist}
                    defaultValue={{
                      value: districtIdSelect.value,
                      label: districtIdSelect.label,
                    }}
                    onChange={handleDistrictSelect}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.district}</p>
              </Col>

              <Col xs={6} md={3} sm={4} lg={2}>
                <Form.Group className="mb-3" controlId="dcform.state">
                  <Form.Label>State </Form.Label>
                  <Select
                   
                    name="state"
                    value={{
                      value: stateIdSelect.value,
                      label: stateIdSelect.label,
                    }}
                    options={optState}
                    defaultValue={{
                      value: stateIdSelect.value,
                      label: stateIdSelect.label,
                    }}
                    onChange={handleStateSelect}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.state}</p>
              </Col>
              <Col xs={6} md={3} sm={4} lg={2}>
                <Form.Group className="mb-3" controlId="dcform.pincode">
                  <Form.Label className="field">Pincode </Form.Label>
                  <Form.Control
                    type="text"
                    name="pincode"
                    placeholder="Pincode"
                    value={formValues.pincode}
                    onChange={handleValueChange}
                    maxLength={6}
                  />
                </Form.Group>

                <p className="error-msg">{formErrors.pincode}</p>
              </Col>
            </Row>

            <Row>
            <Col xs={6} md={3} sm={4} lg={2}>
                <Form.Group className="mb-3" controlId="dcform.contact_person">
                  <Form.Label  className="field">Contact Person </Form.Label>
                  <Form.Control
                    type="text"
                    name="contact_person"
                    placeholder="Contact Person"
                    value={formValues.contact_person}
                    onChange={handleValueChange}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.contact_person}</p>
              </Col>

              <Col xs={6} md={3} sm={4} lg={2}>
                <Form.Group className="mb-3" controlId="dcform.contact_number">
                  <Form.Label className="field">Contact Number </Form.Label>
                  <Form.Control
                    type="text"
                    name="contact_number"
                    placeholder="Contact Number"
                    value={formValues.contact_number}
                    onChange={handleValueChange}
                    maxLength={10}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.contact_number}</p>
              </Col>

              <Col xs={6} md={3} sm={4} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label className="field">E-WayBill Number </Form.Label>
                  <Form.Control
                    type="text"
                    name="ewaybill_number"
                    id="ewaybill_number"
                    placeholder="E-WayBill Number"
                    value={formValues.ewaybill_number}
                    onChange={handleValueChange}
                    maxLength={12}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.ewaybill_number}</p>
              </Col>

            </Row>

            <hr align="center" />

            <Row>
              <Col xs={6} md={6} sm={6} lg={6}>
                <div className="d-flex justify-content-start">
                  <h5>
                    <Badge pill bg="secondary">
                      Item Details
                    </Badge>
                  </h5>
                </div>
              </Col>

              {/*
                  &nbsp;&nbsp;
                  <p
                    className="error-msg"
                    style={{
                      position: "relative",
                      top: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    {formErrors.alertMsg}
                  </p>
               
              </Col>
                  */}
                  
             {/*
              <Col xs={6} md={6} sm={6}>
                <div className="d-flex justify-content-end">
                  <Button
                    className="btn btn-warning"
                    style={{ alignItems: "self-end" }}
                    onClick={newItemHandle}
                  >
                    Add Item
                  </Button>
                </div>
              </Col>
                */}   
            </Row>

            {/*<Row style={{height:props.height}}><hr /></Row>*/}

            {/*
        <Row>
        <Col xs={3} md={3} sm={3}>
            
        <Dropdown  onSelect={handleSelect}>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item eventKey="m5">M.5</Dropdown.Item>
        <Dropdown.Item eventKey="m7.5">M7.5</Dropdown.Item>
        <Dropdown.Item eventKey="m10">M10</Dropdown.Item>
        <Dropdown.Item eventKey="m15" active>M15</Dropdown.Item>
        <Dropdown.Item eventKey="m20">M20</Dropdown.Item>
        <Dropdown.Item eventKey="m25">M25</Dropdown.Item>
        <Dropdown.Item eventKey="m30">M30</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    */}
            {/*
    <Select
                    value={options.value}
                    options={options}
                    defaultValue={{value: formValues.product.value, label: formValues.product.label}}
                    onChange={handleSelect}
                />
        </Col>
        <Col xs={3} md={3} sm={3}>
        <div className="field">
          <input 
          type="number" 
          name="quantity" 
          placeholder="Quantity"
          value={formValues.quantity}
          onChange={handleChange}
          />
          <label>m3</label>
        </div>
        <p>{ formErrors.quantity}</p>
        
        </Col>
        </Row>
    */}

            

            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Product</th>
                  <th className="field required">Volume</th>
                  <th>Balance Volume</th>
                  <th className="field required">Unit Price</th>
                  <th>Product Price</th>
                  {/*<th>GST</th>*/}
                  <th>Total Price</th>
                  {/*state.action === "add" && <th>...</th>*/}
                </tr>
              </thead>

              <tbody>
                {items.map((item, idx) => (
                  <tr>
                    <td style={{paddingTop:"15px"}}>{idx + 1}</td>
                    <td style={{width: "20%"}}>
                      <Select
                        id={`product-item-${idx}`}
                        name={`product-item-${idx}`}
                        value={{
                          value: item.product_id,
                          label: item.product_name,
                        }}
                        options={optionsProd}
                        defaultValue={{
                          value: item.product_id,
                          label: item.product_name,
                        }}
                        onChange={handleSelect}
                      />
                    </td>
                   
                    <td style={{width: "20%"}}>
                      <Form.Group className="mb-3" controlId="m3">
                        
                          <Form.Control
                            type="text"
                            name={`quantity-${idx}`}
                            placeholder="Volume"
                            value={item.quantity}
                            // max="100000"
                            onChange={handleChange}
                            maxLength={5}
                          />
                       
                      </Form.Group>
                      <p className="error-msg">{item.quantity_err}</p>
                    </td>
                    <td style={{color: "red", paddingTop: "15px"}}>{item.balance_dc}</td>
                    <td>
                    <Form.Group className="mb-3" controlId="m3">
                    <Form.Control
                                style={{ padding: "5px", textAlign: "right" }}
                                type="text"
                                name={`unit_price-${idx}`}
                                placeholder="Unit Price"
                                value={item.unit_price}
                                maxLength={10}
                                onChange={handleChange}
                              />
                      </Form.Group>  
                      <p className="error-msg">{item.unit_price_err}</p>   
                    </td>
              

                    {/*<td>{item.unit_price}</td>*/}
                   

                    <td style={{paddingTop:"15px"}}>{item.total_item_price}</td>
                    {/*<td>{item.cgst + item.sgst + item.igst}</td>*/}
                    <td style={{paddingTop:"15px"}}>{item.total_price}</td>
                    {/*
                    {state.action === "add" && (
                      <td>
                        <BsFillXCircleFill
                          size={28}
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={(e) => handleItemDelete(e)}
                          name={`item-delete-icon-${idx}`}
                          id={`item-delete-icon-${idx}`}
                        />
                      </td>
                    )}
                    */}
                  </tr>
                ))}
              </tbody>
            </Table>

            <Row>
              <Col xs={2} sm={2} md={2} lg={2}>
                <Form.Group className="mb-3" controlId="dcform.pumping_charges">
                  <Form.Label>Pumping Charges </Form.Label>

                  <Form.Control
                    type="text"
                    placeholder="Pumping Charges"
                  
                    name="pumping_charges"
                    value={formValues.pumping_charges}
                    onChange={handleValueChange}
                  />
                </Form.Group>
              </Col>

              <Col xs={2} sm={2} md={2} lg={2}>
                <Form.Group className="mb-3" controlId="dcform.diesel_charges">
                  <Form.Label>Diesel Charges </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Diesel Charges"
                
                    name="diesel_charges"
                    value={formValues.diesel_charges}
                    onChange={handleValueChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={5} sm={5} md={5} lg={5}>
                <Form.Group className="mb-3" controlId="dcform.reason">
                  <Form.Label>Others </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Reason"
                    name="reason"
                    value={formValues.reason}
                    onChange={handleValueChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={3} sm={3} md={3} lg={3}>
                <Form.Group className="mb-3" controlId="dcform.amount">
                  <Form.Label>Amount </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Amount"
                    name="amount"
                    value={formValues.amount}
                    onChange={handleValueChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row style={{ height: props.height }}>
              <br></br>
              <p></p>
            </Row>

            <Row>
              <Col xs={3} md={3} sm={3}>
                <div style={{ fontWeight: "bold" }}>
                  Total Items : {total_items}
                </div>
              </Col>
              <Col xs={3} md={3} sm={3}>
                <div style={{ fontWeight: "bold" }}>
                  Total Product Cost : {sum_product_cost}
                </div>
              </Col>
              {/*
              <Col xs={3} md={3} sm={3}>
                <div style={{ fontWeight: "bold" }}>
                  Total GST : {sum_product_gst}
                </div>
              </Col>
              */}
              <Col xs={3} md={3} sm={3}>
                <div style={{ fontWeight: "bold" }}>
                  Total Cost : {sum_product_total}
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={12} sm={12}>
                <div className="d-flex justify-content-end">
                  <Button
                    className="btn btn-secondary"
                    id="btn-clear-order-id"
                    name="btn-clear-order-name"
                    onClick={handleClear}
                  >
                    Clear
                  </Button>
                  &nbsp;&nbsp;
                  <Button 
                    className="fbtn btn btn-primary"
                    onClick={handleSubmit}
                  >
                  { submitAction &&
                    <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                  }
                  Save DC</Button>
                </div>
              </Col>
            </Row>
          </div>
          <Modal
              show={showDialog}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>{showMsgTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{showMsgBody}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handlePostSaveOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
        </Form>
        :
        <div className="container-spinner">
        <div className="vertical-center-spinner">
         <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
             <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
                 <span className="visually-hidden">Loading...</span>
               </Spinner>
         </div>
         </div>
        }
      </Container>
      {/*
      {show === true && (
        <div>
          <br></br>
          <Alert
            variant={msgType}
            style={{
              display: "inline-block",
              alignItems: "center",
              top: "50%",
              left: "50%",
              marginTop: "-80px",
              marginLeft: "-80px",
            }}
            className="w-25 float-center text-center p-1"
            onClose={handleClose}
            dismissible
          >
            <p>{showMsg}</p>
          </Alert>
        </div>
      )}
          */}
    </>
  );
};

export default AddUpdateDC;
