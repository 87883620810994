import React, { useState, useEffect, useRef, Fragment } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    Form,
    Button,
    Table,
    Spinner,
    Image
} from "react-bootstrap";
import { withRouter } from "react-router";
//import Sidebar from './Sidebar';
import Sidebar2 from "./Sidebar2";
import Footer from "./Footer";
//import Topmenu from "./Topmenu";
import OrderDetails from "./OrderDetails";
import "../Dashboard.css";

//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";

import { useNavigate } from "react-router-dom";

import * as Icon from "react-bootstrap-icons";
import axios from "axios";
import { client } from "../config/Config";
import Select from "react-select";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';



import {
    BsFillTrashFill,
    BsPencilSquare,
    BsFileRuled,
    BsArrowLeftCircle,
    BsCardText,
    BsReceipt,
    BsFillPrinterFill,
} from "react-icons/bs";

import { AiOutlineOrderedList } from "react-icons/ai";

import MenuOms from "./MenuOms";

import rmcBlueIcon from "../assets/images/ready_mix_blue.png";
import SidebarMain from "./SidebarMain";

import { getDistrictList, getStateList, getProductApiCall, getProductBuy, getProductsSell, formatDate, formatDateIndian } from "./utils";

import { OS_RECEIPT_FOR_NDAYS } from "../config/Config";

const ListCustReceipts = (props) => {

  const intialValues = {
    start_date: formatDate(new Date(new Date().setDate(new Date().getDate() - OS_RECEIPT_FOR_NDAYS))),
    end_date: formatDate(new Date())
};


    const initialized = useRef(false);
    const [toRender, setToRender] = useState(false);

    let receiptArr = [
      {
      sno: "1",
      receipt_number: "RCPT001",
      receipt_date: "01-01-2024",
      customer: "RRR Constructions",
      amount: "5000",
      received_mode: "Cash",
      received_by: "Joel"  
      },
      {
        sno: "2",
        receipt_number: "RCPT002",
        receipt_date: "04-02-2024",
        customer: "SMS Readymix",
        amount: "8000",
        received_mode: "Cheque",
        received_by: "Manoj"  
        },
        {
          sno: "3",
          receipt_number: "RCPT003",
          receipt_date: "08-04-2024",
          customer: "ABC Constructions",
          amount: "1000",
          received_mode: "Net Banking",
          received_by: "Mariya"  
          },
          {
            sno: "4",
            receipt_number: "RCPT004",
            receipt_date: "07-08-2024",
            customer: "DCR Readymix",
            amount: "9000",
            received_mode: "Cash",
            received_by: "Bala"  
            },
            {
              sno: "5",
              receipt_number: "RCPT005",
              receipt_date: "02-05-2024",
              customer: "MMC Constructions",
              amount: "6000",
              received_mode: "Cheque",
              received_by: "Farina"  
              },

    ]

    const [optCustomer, setOptCustomer] = useState([]);
    const [customerIdSelect, setCustomerIdSelect] = useState({});
    const [receiptList, setReceiptList] = useState([]);
    //const [receiptListFull, setReceiptListFull] = useState([]);
    const [formValues, setFormValues] = useState(intialValues);
    const navigate = useNavigate();

    const getCustomerListDropdown = async () => {
        console.log("getCustomerListDropdown method called ...");
        var retState = false;
        
        try {
          var aIVal = localStorage.getItem("_aI");
          var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
          var res = await axios.get(
            client.domain + "/crm/getAllCustomers",
            { headers },
            { withCredentials: false }
          );
          
          console.log(
            "Response from server getCustomerList : ",
            res.data.detail.data
          );
          if (res.data.detail.status === "SUCCESS") {
            console.log(
              "Response message from server getCustomerList : ",
              res.data.detail.data
              
            );
            
            var customerArr = [];
            var custAll = { value: 0, label: 'All Customers'};
            customerArr.push(custAll);
            res.data.detail.data.map((itemCustomer, idx) => {
              console.log("Ind val : ", itemCustomer);
              var customerInd = {
                value: itemCustomer.id,
                label: itemCustomer.name,
              };
              console.log("In customer modified : ", customerInd);
              customerArr.push(customerInd);
            });
    
            console.log("Mod Full VALUE : ", customerArr);
            retState = true;
          }
         
        } catch (error) {
          console.log("FULL ERROR : ", error);
          if (error.code === "ERR_NETWORK") {
            console.log(
              "Not able to reach target server please try after sometime"
            );
          } else {
            console.log("ERROR : ", error.response.data.detail);
            if (error.response.data.detail.status === "FAILED") {
              console.log(
                "Request failed due to ",
                error.response.data.detail.data
              );
             
            }
          }
        }
        return [retState, customerArr];
      };

      
      const getReceiptList = async(customer_id, start_date, end_date) => {
        console.log("getReceiptList method called ...");
        console.log("start date   : ", start_date);
        console.log("end date     : ", end_date);
        console.log("customer id  : ", customer_id);
        try {
          var aIVal = localStorage.getItem("_aI");
          console.log("Authorization token : ", aIVal);
    
          var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
    
          console.log("Headers : ", headers);
    
          
          var res = await axios.get(client.domain + `/getAllReceiptsByFilter?customer_id=${customer_id}&start_date=${start_date}&end_date=${end_date}`, {headers}, {
            withCredentials: false,
          });
          //.then((res) => {
          //console.log("ADD New user res:", res);
          //localStorage.setItem('_token', res.data.res_data.token);
          console.log("Response from server getReceiptList : ", res.data.detail);
          if (res.data.detail.status === "SUCCESS") {
            console.log("Response message from server getReceiptList : ", res.data.detail.data);
           if (res.data.detail.data.length > 0) {
              setReceiptList(res.data.detail.data);
              //setReceiptListFull(res.data.detail.data);
            }
            setToRender(true);
          }
          
        } catch (error) {
          console.log("FULL ERROR : ", error);
          if (error.code === "ERR_NETWORK") {
            console.log("Not able to reach target server please try after sometime");
        setToRender(true);
          } else {
            console.log("ERROR : ", error.response.data.detail);
            if (error.response.data.detail.status === "FAILED") {
              console.log("Request failed due to ",error.response.data.detail.res_data);
            }
          }
        }
        setToRender(true);
      };
      

      const handleViewReceipt = async(e) => {
        console.log("handleViewReceipt called :", e);
      }

    useEffect(() => {
        if (!initialized.current) {
            console.log("*************** useEffect called ##########################");

            (async () => {
                var [retState, customerArr] = await getCustomerListDropdown();
                if ( retState ) {
                    setOptCustomer(customerArr);
                    setCustomerIdSelect(customerArr[0]);
                }

                if ( retState ) {
                  await getReceiptList(customerArr[0].value, formValues.start_date, formValues.end_date);
                  setToRender(true);
                }
                else {
                  await getReceiptList(0, formValues.start_date, formValues.end_date);
                  setToRender(true);
                }
            })();

            initialized.current = true;
        }
    }, []);



    

    

    const handleCustomerSelect = async (e) => {
        console.log("Handle customer select called : ", e);

        setCustomerIdSelect(e);
    }

    const onChangeDatePicker = async (e) => {
        console.log("onchangeDatePicker called : ", e);
        console.log("onChangeDatePicker target called ...", e.target);
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    }

    const handleDateSearch = async (e) => {
        console.log("handleDateSearch called : ", e);
        await getReceiptList(customerIdSelect.value, formValues.start_date, formValues.end_date);
    }

    const handleReceiptAdd = async (e) => {
        console.log("handleClickAddOrder called : ", e);

        navigate(`/addUpdateCustReceipt`, {
            state: { passed_element: [], action: "add" },
          });
    }
   /*
    const handleReceiptView = async(e) => {
      console.log("handleReceiptView called : ", e);
      console.log("Handle action edit Clicked target : ", e.currentTarget);

      var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(
        0,
        clickedTagId.lastIndexOf("-")
      );
      console.log("current selected element name : ", nameOfElement);
      console.log("Current SELECTED id : ", curSelId);
      console.log("CUR SELECTED VALUE : ", receiptList[curSelId]);
      
      navigate(`/addUpdateCustReceipt`, {
        state: { passed_element: receiptList[curSelId], action: "view" },
      });
      
    }
  };
  */


  const handleReceiptView = async (e) => {
    console.log("handle edit customers called : ", e);
    console.log(
      "handle edit customers currentTarget called : ",
      e.currentTarget
    );
    console.log("handle edit customers target called : ", e.target);
    var selectedTagId = e.currentTarget.id;
    console.log("SELECTED TAG ID: ", selectedTagId);
    if (selectedTagId) {
      var currentId = selectedTagId.substring(
        selectedTagId.lastIndexOf("-") + 1
      );
      var nameOfElement = selectedTagId.substring(
        0,
        selectedTagId.lastIndexOf("-")
      );
      console.log("Name of element VIEW: ", nameOfElement);
      console.log("Current id VIEW : ", currentId);

      console.log(
        "<<<<<<<<<<<<<<<<< ITEM SELECTED VIEW ********** ",
        receiptList[currentId]
      );
      navigate("/addUpdateCustReceipt", {
        state: {
          passed_element: receiptList[currentId],
          action: "view",
        },
      });
    }
  };

  const handleFullPrint = async (e) => {
    //setPrintAction(true);
    //setFormValues({ ...formValues, summaryPrint: "ONLY"});
    //setPrintDialog(true);
    //navigate("/printFullReceipt");
    //console.log("Handle Edit clicked FULL : ", e);
    //console.log("Handle Edit clicked FULL currentTarget : ", e.currentTarget);
    //console.log("Handle Edit clicked FULL target : ", e.target);

    //const { name, value } = e.currentTarget;
    //var currentId = name.substring(name.lastIndexOf("-") + 1);
    //console.log("SELECTED DC ROW ID FOR PRINT : ", currentId);
    //setSelectedDCRowForPrint(currentId);
    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);

    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printFullReceipt?customer_id=${customerIdSelect.value}&start_date=${formValues.start_date}&end_date=${formValues.end_date}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0,0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
  };

    return (
        <Container>
            <SidebarMain />
            {toRender ?
                <form>
                    <Row>

                        <Col xs={1} md={1} sm={1} lg={1}>
                            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem" }}>Receipts</span>
                        </Col>
                        <Col xs={2} md={2} sm={2} lg={2} style={{paddingLeft: "10px"}}>
                            <Row>
                                <Col>
                                    <Select
                                        inline
                                        id="customer"
                                        name="customer"
                                        value={{
                                            value: customerIdSelect.value,
                                            label: customerIdSelect.label,
                                        }}
                                        options={optCustomer}
                                        defaultValue={{
                                            value: customerIdSelect.value,
                                            label: customerIdSelect.label,
                                        }}
                                        onChange={handleCustomerSelect}
                                    />
                                </Col>

                            </Row>
                        </Col>

                        <Col xs={5} md={5} sm={5} lg={5}>
                            {/*<span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1rem",verticalAlign:"bottom" }}>StartDate:</span>*/}
                            <Row>

                                <Col>
                                    <Form.Control
                                        type="date"
                                        name="start_date"
                                        placeholder="Start Date"
                                        value={formValues.start_date}
                                        onChange={onChangeDatePicker}
                                    //readOnly={isReadOnly}

                                    />

                                </Col>

                                <Col>
                                    <Form.Control
                                        type="date"
                                        name="end_date"
                                        placeholder="End Date"
                                        value={formValues.end_date}
                                        onChange={onChangeDatePicker}
                                    //readOnly={isReadOnly}

                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col
                            xs={1}
                            md={1}
                            sm={1}
                            lg={1}
                            className="d-flex justify-content-end float-end"
                        >
                            <Button id="search-order-id"
                                size={"sm"}
                                variant="success"
                                onClick={(e) => handleDateSearch(e)}>
                                Search
                            </Button>

                        </Col>
                        <Col xs={3}
                            md={3}
                            sm={3}
                            lg={3}
                            className="d-flex justify-content-end float-end">

                              <Button id="receiptPrint" name = "receiptPrint"  onClick={(e)  => handleFullPrint(e)}>
                              <BsFillPrinterFill
                              size={18}
                              style={{ color: "white", cursor: "pointer" }}
                              name={`print-receipt-icon`}
                              id={`print-receipt-icon`}
                              />
                            </Button>
                              &nbsp;&nbsp;&nbsp;&nbsp;

                            <Button id="add-order-id"
                                size={"sm"}
                                variant="warning"
                                onClick={(e) => handleReceiptAdd(e)}>
                                New Receipt
                            </Button>
                        </Col>
                        

                    </Row>

                    <hr align="center" />

                    <Table striped bordered hover size="sm">
          <thead>
            <tr className="table-primary">
              <th className="table-row-heading" key={"order-list-heading-key"}>
                S.No
              </th>
              <th className="table-row-heading" style={{textAlign : "center"}}>Receipt No</th>
              <th className="table-row-heading" style={{textAlign : "center"}}>Receipt Date</th>
              <th className="table-row-heading" style={{textAlign : "center"}}>Customer</th>
              <th className="table-row-heading" style={{textAlign : "center"}}>Amount</th>
              <th className="table-row-heading" style={{textAlign : "center"}}>Received Mode</th>
              <th className="table-row-heading" style={{textAlign : "center"}}>Reference No</th>
              <th className="table-row-heading" style={{textAlign : "center"}}>Received By</th>
              <th className="table-row-heading" style={{textAlign : "center"}}>Received For</th>
              <th className="table-row-heading" style={{textAlign : "center"}}>Notes</th>
              <th className="table-row-heading" style={{textAlign : "center"}}>Details</th>
            </tr>
          </thead>

          <tbody>
            {receiptList.map((receiptInd, idx) => (
              <tr key={`receipt-table-row-key-${idx}`}>
                
                <td style={{textAlign : "right", width: "40px"}}
                  key={`receipt-table-row-key-sno-${idx}`}
                  id={`receipt-table-row-id-sno-${idx}`}
                  name={`receipt-table-row-name-sno-${idx}`}
                  
                >
                  {idx + 1}
                </td>

                <td
                  key={`receipt-table-row-key-ono-${idx}`}
                  id={`receipt-table-row-id-ono-${idx}`}
                  name={`rceipt-table-row-name-ono-${idx}`}
                  style= {{width: "100px", textAlign: "right"}}
                >
                  {receiptInd.receipt_number}
                </td>

                <td
                  key={`receipt-table-row-key-date-${idx}`}
                  id={`receipt-table-row-id-date-${idx}`}
                  name={`rceipt-table-row-name-date-${idx}`}
                  style= {{width: "120px", textAlign: "left"}}
                >
                  {formatDateIndian(new Date(receiptInd.receipt_date))}
                </td>

                <td style={{textAlign: "left"}}
                  key={`receipt-table-row-key-customer-${idx}`}
                  id={`receipt-table-row-id-customer-${idx}`}
                  name={`receipt-table-row-name-customer-${idx}`}
                >
                  {receiptInd.customer_name}
                </td>

                <td
                  key={`receipt-table-row-key-amount-${idx}`}
                  id={`receipt-table-row-id-amount-${idx}`}
                  name={`rceipt-table-row-name-amount-${idx}`}
                  style={{textAlign: "right"}}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(receiptInd.amount).toFixed(2)))}
                </td>


                <td style={{textAlign: "left"}}
                  key={`receipt-table-row-key-mode-${idx}`}
                  id={`receipt-table-row-id-mode-${idx}`}
                  name={`rceipt-table-row-name-mode-${idx}`}
                  //style= {{width: "120px"}}
                >
                  {receiptInd.received_mode}
                </td>
                <td style={{textAlign: "right"}}
                  key={`receipt-table-row-key-ref-number-${idx}`}
                  id={`receipt-table-row-id-ref-number-${idx}`}
                  name={`rceipt-table-row-name-ref-number-${idx}`}
                >
                  {receiptInd.ref_number}
                </td>
                <td style={{textAlign: "left"}}
                  key={`receipt-table-row-key-received-by-${idx}`}
                  id={`receipt-table-row-id-received-by-${idx}`}
                  name={`rceipt-table-row-name-received-by-${idx}`}
                >
                  {receiptInd.received_by_name}
                </td>

                <td
                  key={`receipt-table-row-key-towards-${idx}`}
                  id={`receipt-table-row-id-towards-${idx}`}
                  name={`rceipt-table-row-name-towards-${idx}`}
                  style= {{width: "180px", textAlign: "left"}}
                >
                  {receiptInd.towards}
                </td>

                <td
                  key={`receipt-table-row-key-addl-notes-${idx}`}
                  id={`receipt-table-row-id-addl-notes-${idx}`}
                  name={`rceipt-table-row-name-addl-notes-${idx}`}
                  style= {{width: "180px", textAlign: "left"}}
                >
                  {receiptInd.addl_notes}
                </td>

                <td style={{textAlign: "center"}}
                  key={`order-table-row-key-actions-${idx}`}
                  id={`order-table-row-id-actions-${idx}`}
                  name={`order-table-row-name-actions-${idx}`}
                >
                  <Button
                    variant="light"
                    size="sm"
                    onClick={(e) => handleReceiptView(e)}
                    name={`order-edit-icon-${idx}`}
                    id={`order-edit-icon-${idx}`}
                  >
                    <BsCardText
                    size={28}
                    style={{ color: "#0d6efd", cursor: "pointer" }}
                    name={`order-view-icon-${idx}`}
                    id={`order-view-icon-${idx}`}
                    />
                  </Button>
                </td>
                </tr>
            ))}
            </tbody>
            </Table>
                
                </form>
                : 
                <div className="container-spinner">
               <div className="vertical-center-spinner">
                {/*<Button variant="primary" disabled>*/}
                <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
                   <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{ width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
                       <span className="visually-hidden">Loading...</span>
                     </Spinner>
          
                {/*</Button>*/}
                </div>
                </div>
          }
                
                
        </Container>
    );

}

export default ListCustReceipts;