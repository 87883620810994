import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Form, Button, View,Modal,Spinner, Image } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
import Sidebar2 from "./Sidebar2";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
//import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
//import DateTimeField from "react-bootstrap-datetimepicker"
//import DateTimePicker from "react-datetime-picker";

import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client } from "../config/Config";
import axios from "axios";

import { useLocation } from "react-router-dom";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import { BsFillPenFill, BsFillTrashFill,BsPencilSquare,BsArrowLeftCircle } from "react-icons/bs";

import DateTimePicker from "react-datetime-picker";

import MenuSettings from "./MenuSettings";
import SidebarMain from "./SidebarMain";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';

const AddUpdateProfile = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  //console.log("PASSED STATE VALUE IN Patient Add/Update page : ", state);



  let initialItems = [];
  
  const intialValues = {
    user_name: "",
    first_name: "",
    last_name: "",
    email: "",
    contact_no: "",
    user_role_name: ""
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [toRender, setToRender] = useState(false);
  const [validateError, setValidateError] = useState(false);
  
  var validateErr = false;

 

  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
 
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );
  const [submitAction, setSubmitAction] = useState(false);

  const handleOnChange = (e) => {
    //e.preventDefault();
    console.log("handleOnChange : ", e);

    console.log("Button clicked : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
/*
    setFormValues({ ...formValues, [name]: value});
    if (name === "phone" || name === "pincode" || name === "contact_number_primary" || name === "contact_number_secondary" ) {
      console.log("Inside check ******************");
      var valueTmp = e.currentTarget.value.replace(/[^\d]/g, '');
      setFormValues({ ...formValues, [name]: valueTmp });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
    */
    setFormValues({ ...formValues, [name]: value });
  }
  
  

  const 
  getProfile = async () => {
    console.log("getProfile api called ..");
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}` }
    /*  var res = await axios.get(client.domain + "/core/getUserProfile?id=" + 5, { headers }, { withCredentials: false });*/
     
      var res = await axios.get(client.domain + "/core/getUserProfile", { headers },
        { withCredentials: false }
      );
      
      /*
      var reqParam = {
        id: formValues["id"],
        first_name:formValues["first_name"],
        last_name:formValues["last-name"],
        contact_no:formValues["mobile"],
        email:formValues["email"],
        user_name:formValues["user_name"],
        user_role_name:formValues["user_role"]
      };
      */

      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.data);
        setFormValues(res.data.detail.data);
        setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
         
        } else {
          console.log("Non status ERROR code ...");
          setShowMsg(error.response.data.detail);
          setShowMsgTitle("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
  };
  
  
  useEffect(() => {
    if (!initialized.current) {
      console.log("*************** useEffect::addUpdateProfile called ##########################");
      //console.log("PASSED STATE VALUE IN Service Add/Update page : ", state);
      getProfile();
      setToRender(true);
      initialized.current = true; 
    }
  }, []);
  
 
  const handleChange = async (e) => {
    console.log("handle change clicked : ", e);
    navigate("/addUpdatePassword");
    
  };

  return (
    <>
      <Container>
        <SidebarMain />
        { toRender ?
        (
        <Form>
          <Row>
          <Col xs={2} md={2} sm={2} lg={2}>  
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}> My Profile</span>
            </Col>
           
              <Col
              xs={10}
              md={10}
              sm={10}
              lg={10}
              className="float-end justify-content-end"
            >
              <div className="d-flex justify-content-end">
              {/*<Button variant="outline-light">Light</Button>{' '}*/}
              <Button
                     style={{cursor: "default", fontWeight: "500"}}
                     variant="outline-info"
                      id="Reset"
                      size="sm"
                      onClick={(e) => handleChange(e)}
                    >
                      Change Password
                    </Button>
              {/*
              <div className="d-flex justify-content-end">
                <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                  <BsArrowLeftCircle
                    key={`back-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                   
                    name={`back-icon-patient-name`}
                    id={`edit-icon-patient-id`}
                  />
                </Button>
                &nbsp;&nbsp;
              <Button variant="primary" className="btn btn-primary btn-md"
              key={`edit-btn-company-key`}
              style={{ cursor: "pointer" }}
              onClick={handleEdit}
              name={`edit-icon-company-name`}
              id={`edit-icon-company-id`}>
                  <BsPencilSquare
                    size={24}
                  />
                </Button>
                */}
                </div>
                
                </Col> 
          </Row>
          <div className="ui-divider"></div>
          <div className="ui-form">
          <hr align="center" />
            <Row>
            
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label> First Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="first_name"
                    name="first_name"
                    placeholder="First Name"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["first_name"]}
                    style={{
                      border:"1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.first_name}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label> Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="last_name"
                    name="last_name"
                    placeholder="Last Name"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["last_name"]}
                    style={{
                      border:"1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.last_name}</p>
              </Col>
             
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label> Mobile</Form.Label>
                  <Form.Control
                    type="text"
                    id="contact_no"
                    name="contact_no"
                    placeholder="Mobile"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["contact_no"]}
                    style={{
                      border: "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.contact_no}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label> Email</Form.Label>
                  <Form.Control
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Email"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["email"]}
                    style={{
                      border: "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.email}</p>
              </Col>
              </Row>
              <Row>
                  <Col xs={6} md={6} sm={6} lg={6}>
                    <h4 style={{ color: "#4d4d4d" }}>
                      <Badge pill bg="secondary">
                        User Details
                      </Badge>
                    </h4>
                  </Col>
                </Row>
              <Row>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label> User Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="user_name"
                    name="user_name"
                    placeholder="User Name"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["user_name"]}
                    style={{
                      border: "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.user_name}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>User Role</Form.Label>
                  <Form.Control
                    type="text"
                    id="user_role_name"
                    name="user_role_name"
                    placeholder="User Role"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["user_role_name"]}
                    style={{
                      border:  "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.user_role_name}</p>
              </Col> 
            </Row>
            {/*
            {!isReadOnly && (
            <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ disply: "flex", justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleReset(e)}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      //type="submit"
                      id="save"
                      onClick={(e) => handleSave(e)}
                      disabled={submitAction ? true: false}
                    >
                      {submitAction ? 
                      <>
                      <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        <span className="visually-hidden">Loading...</span>
        </> : 
        'Save'}
                      
                    </Button>
                  </div>
                </Col>  
              </Row>
            )} 
             <Modal
              show={showDialog}
              onHide={handleCloseDialog}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>Success</Modal.Title>
              </Modal.Header>
              <Modal.Body>Company Details Updated Successfully...!</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handlePostSaveOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal> 
                      */}
          </div>
        </Form>
        )
        :
        <div className="container-spinner">
        <div className="vertical-center-spinner">
         {/*<Button variant="primary" disabled>*/}
         <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
            <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{ width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
                <span className="visually-hidden">Loading...</span>
              </Spinner>
   
         {/*</Button>*/}
         </div>
         </div>
      }
      </Container>
    </>
  );
};

export default AddUpdateProfile;
