import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

import { formatTime } from "../../pages/utils";

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 12,
        //justifyContent: "flex-end",
        //alignItems: 'flex-end',
        //alignItems: 'flex-end',
        //alignSelf: 'flex-end',
        //textAlign: 'right',
        marginLeft: '40%',
    },
    billTo: {
        marginTop: 20,
        paddingBottom: 3,
        fontFamily: 'Helvetica-Bold',
        fontStyle: 'italic'
    },
  });


  const AdditionalCharges = (props) => {
  return (
    <View style={styles.headerContainer}>
        <Text style={styles.billTo}>Delivery Details:</Text>
        <Text><Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Vehicle: </Text>{props.dcLoad.dc_info.vehicle_reg_number}</Text>
        <Text><Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Driver: </Text>{props.dcLoad.dc_info.driver_name}</Text>
        <Text><Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Loading Start time: </Text>{formatTime(new Date(props.dcLoad.dc_info.loading_start_time))}</Text>
        <Text><Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Receiving time: </Text></Text>
    </View>
  );
  }
  export default AdditionalCharges
