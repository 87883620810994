const invoice = {
    "id": "5df3180a09ea16dc4b95f910",
    "owner_company": "KMC CONCRETE",
    "owner_email": "info@kmcreadymix.avinasoft.in",
    "owner_phone": "+91-9942410747",
    "owner_address1": "KMC READYMIX",
    "owner_address2": "THANNEERPANTHAL",
    "owner_city": "Avinashi",
    "owner_district": "Tiruppur",
    "owner_pincode": "641654",
    "owner_gst": "33AQKPM3955A1Z1",
    "invoice_no": "DC-10021",
    "balance": "$2,283.74",
    "customer_name": "PAULRAJ",
    "cust_cont_name": "PAULRAJ",
    "phone": "+91-9787453372",
    "address1": "Samathuvapuram, Tiruppur",
    "address2": "Tiruppur",
    "trans_date": "2023-08-01",
    "due_date": "2023-09-01",
    "driver_name": "Karuppusamy",
    "vehilcle_number": "TN 39 DY 7314",
    "diesel_charges": "3000.00",
    "pumping_charges": "1000.00",
    "items": [
      {
        "sno": 1,
        "desc": "M20",
        "qty": 5,
        "rate": 4400.00
      },
      {
        "sno": 2,
        "desc": "M30",
        "qty": 5,
        "rate": 4800.00
      }
    ]
  }
  
  export default invoice