import React, { useState, useEffect, useRef, useReducer } from "react";
import Table from "react-bootstrap/Table";
import { fragment } from "react";
import select from "react-select";
import Form from "react-bootstrap/Form";
import Sidebar2 from "./Sidebar2";
import Button from "react-bootstrap/Button";
import Select from "react-select"

import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsCardText,
  BsFillPrinterFill
} from "react-icons/bs";
import MenuIms from "./MenuIms";
import { Row, Col, Alert, Modal, Container, Spinner, Image } from "react-bootstrap";
import axios from "axios";
import { client } from "../config/Config";

import { useNavigate } from "react-router-dom";

import "../App.css";
import SidebarMain from "./SidebarMain";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';



function ListSuppliers() {
  const initialized = useRef(false);
  const navigate = useNavigate();

  const [checkedState, setCheckedState] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  let SupplierArr = [
  ];

  const [checked, setChecked] = useState(false);
  const [selectedId, setSelectedId] = useState(-1);

  const [supplierList, setSupplierList] = useState([]);
  const [supplierListFull, setSupplierListFull] = useState([]);
  const [toRender, setToRender] = useState(false);
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("Please login again");
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );
  const [showDialog, setShowDialog] = useState(false);
  const [supplierIdSelect, setSupplierIdSelect] = useState({});
  const [optSupplier, setOptSupplier] = useState([]);

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const handleShowDialog = () => {
    setShowDialog(true);
  };
  const updateSupplierStatus = async (selectedId) => {
    var selectedObj = supplierList[selectedId];
    let reqStatusUpdatePayload = {
      id: selectedObj.id,
      is_active: !selectedObj.is_active,
    };

 
    console.log("payload:", reqStatusUpdatePayload);

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
      var res = await axios.post(
        client.domain + "/srm/activateSupplier",
        reqStatusUpdatePayload,
        { headers },
        { withCredentials: false }
      );
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {   
        var itemSelectedUpdate = supplierList[selectedId];
        itemSelectedUpdate.is_active = !itemSelectedUpdate.is_active;
        console.log(
          "<<<<<<<<<<<<<<<<< ITEM SELECTED MODIFIED ********** ",
          itemSelectedUpdate
        );

        const newitems = supplierList.slice();
        newitems[selectedId] = itemSelectedUpdate;
        setSupplierList(newitems);

        setShowDialog(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
        }
      }
    }
    setShowDialog(false);
  };

  const handleSwitchAction = () => {
    console.log("user confirmed to enable or disable ...");
    console.log("user selected id for action is : ", selectedId);
    updateSupplierStatus(selectedId);
  };
  const actionEditHandle = (e, data) => {
    console.log("Handle action edit Clicked : ", e);
    //console.log("Handle Clicked edit DATA : ", data);
    console.log("Handle action edit Clicked target : ", e.target);
    console.log(
      "Handle action edit Clicked target Current : ",
      e.currentTarget
    );
    console.log(
      "Handle action edit Clicked target Current id : ",
      e.currentTarget.id
    );
    console.log("Handle action edit Clicked target ID : ", e.target.id);
    console.log("Handle action edit Clicked target NAME : ", e.target.name);
  };
  const getSupplierList = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      

      var res = await axios.get(
        client.domain + "/srm/getAllSuppliers",
        { headers },
        { withCredentials: false }
      );
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", 0);
        setSupplierList(res.data.detail.data);
        //setShowMsgTitle("Supplier Info Added Successfully");
        setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
        } else {
          console.log("Non status ERROR code ...");
          setShowMsg(error.response.data.detail);
          setShowMsgTitle("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
  };
  const handleStateChange = (e) => {
    console.log("clicked Value :", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);

    var currentId = name.substring(name.lastIndexOf("-") + 1);
    var nameOfElement = name.substring(0, name.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);
  };
  const SwitchComponent = () => {};

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };
  const handleSupplierSelect = (e, data) => {
    console.log("handleSupplierSelect called ...", e);

    var supplierListFilter = []

    if ( e.value === 0 ) {
      setSupplierList(supplierListFull);
    }
    else {
      supplierListFull.map((supp) => {
        if ( supp.id === e.value ) {
          supplierListFilter.push(supp);
        }
      })
      setSupplierList(supplierListFilter);
    }
    setSupplierIdSelect(e);
  };
  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::ListSuppliers called ##########################"
      );
      getSupplierList();
      initialized.current = true;
      /*
      ( async() => {
        var [retState, supplierArr] = await getSupplierListDropdown();
        if ( retState ) {
          setOptSupplier(supplierArr);
          setSupplierIdSelect(supplierArr[0]);
        }
      })();
      */
    }
  }, []);

  const handleAdd = async (e) => {
    console.log("handle add suppliers called : ", e);
    navigate("/addUpdateSupplier", {
      state: { passed_element: [], action: "add" },
    });
  };
 

  const handleView = async (e) => {
    console.log("handle edit suppliers called : ", e);
    console.log(
      "handle edit suppliers currentTarget called : ",
      e.currentTarget
    );
    console.log("handle edit suppliers target called : ", e.target);
    var selectedTagId = e.currentTarget.id;
    console.log("SELECTED TAG ID: ", selectedTagId);
    if (selectedTagId) {
      var currentId = selectedTagId.substring(
        selectedTagId.lastIndexOf("-") + 1
      );
      var nameOfElement = selectedTagId.substring(
        0,
        selectedTagId.lastIndexOf("-")
      );
      console.log("Name of element VIEW: ", nameOfElement);
      console.log("Current id VIEW : ", currentId);

      console.log(
        "<<<<<<<<<<<<<<<<< ITEM SELECTED VIEW ********** ",
        supplierList[currentId]
      );
      navigate("/addUpdateSupplier", {
        state: {
          passed_element: supplierList[currentId],
          action: "view",
        },
      });
    }
   
  };
  const handleOnChange = async (e) => {
    console.log("handle on change called : ", e);

    var selectedTagId = e.target.id;
    console.log("SELECTED TAG ID: ", selectedTagId);
    var currentId = selectedTagId.substring(selectedTagId.lastIndexOf("-") + 1);
    var nameOfElement = selectedTagId.substring(
      0,
      selectedTagId.lastIndexOf("-")
    );
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    console.log(
      "<<<<<<<<<<<<<<<<< ITEM SELECTED ********** ",
      supplierList[currentId]
    );

    setSelectedId(currentId);

    setShowDialog(true);
  };

  const handleAlertClose = async (e) => {
    setShow(false);
    navigate(-1);
  };
  /*
  const getSupplierListDropdown = async () => {
    console.log("getSupplierListDropdown method called ...");
    var retState = false;
    
  
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(
        client.domain + "/srm/getAllSuppliers",
        { headers },
        { withCredentials: false }
      );
      
      console.log(
        "Response from server getSupplierList : ",
        res.data.detail.data
      );
      if (res.data.detail.status === "SUCCESS") {
        console.log(
          "Response message from server getSupplierList : ",
          res.data.detail.data
        );
        
  
        var supplierArr = [];
        var suppAll = { value: 0, label: 'All'};
        supplierArr.push(suppAll);
        res.data.detail.data.map((itemSupplier, idx) => {
          console.log("Ind val : ", itemSupplier);
          var supplierInd = {
            value: itemSupplier.id,
            label: itemSupplier.name,
          };
          console.log("In supplier modified : ", supplierInd);
          supplierArr.push(supplierInd);
        });
  
        console.log("Mod Full VALUE : ", supplierArr);
        retState = true;
      }
     
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.data
          );
         
        }
      }
    }
    return [retState, supplierArr];
  };
  */
  const handleSupplierPrint = async (e) => {
    //navigate("/printCustomer")
     
     var urlStr = window.location.href;
     var paramsTmp = urlStr.substring(
       urlStr.lastIndexOf(client.urlDelimiter) + 1
     );
     var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));
 
     console.log("CALLED URL : ", urlTmp);
     console.log("CALLED PAGE : ", paramsTmp);
 
     var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printSupplier?`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
     winPrint.moveTo(0,0);
     winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
     
     
   };
  return (
    <Container>
      <SidebarMain />
      { toRender ?  
      <form>
        <Row>
        <Col xs={4} md={4} sm={4} lg={4}> 
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>Suppliers</span>
           
             </Col>
             {/*
             <Col xs={2} md={2} sm={2} lg={2}>
              <Select
                    inline
                    id="supplier"
                    name="supplier"
                    value={{
                      value: supplierIdSelect.value,
                      label: supplierIdSelect.label,
                    }}
                    options={optSupplier}
                    defaultValue={{
                      value: supplierIdSelect.value,
                      label: supplierIdSelect.label,
                    }}
                    onChange={handleSupplierSelect}
                    //isDisabled={isReadOnly}
                  />   
            </Col> 
            */}  
         
          <Col xs={8} md={8} sm={8} lg={8} className="d-flex justify-content-end float-end">
          <Button id="supplierPrint" name = "supplierPrint"  onClick={(e)  => handleSupplierPrint(e)}>
                <BsFillPrinterFill
                 size={18}
                 style={{ color: "white", cursor: "pointer" }}
                 name={`print-supplier-icon`}
                 id={`print-supplier-icon`}
                 />
                 </Button>
                 &nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              variant="warning"
              id="add-btn-id"
              name="add-btn-name"
              className=" float-end"
              onClick={(e) => handleAdd(e)} 
            >
              New Supplier
            </Button>
          </Col>
        </Row>
        <hr align="center" />
          <div className="ui-divider"></div>
        <Table striped bordered hover>
          <thead>
            <tr className="table-primary">
              <th className="table-row-heading" style={{textAlign: "center"}}>S.No</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Name</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Place</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Contact Person</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Contact Number</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>GST Number</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Active</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Details</th>
            </tr>
          </thead>
          <tbody>
            {supplierList.map((supplier, idx) => (
              <tr key={`supplier-list-row-key-${idx}`}>
                <td style={{textAlign: "right"}}>{idx + 1}</td>
                <td style={{textAlign: "left"}}>{supplier.name}</td>
                <td style={{textAlign: "left"}}>{supplier.address_line2}</td>
                <td style={{textAlign: "left"}}>{supplier.contact_person}</td>
                <td style={{textAlign: "right"}}>{supplier.contact_person_number}</td>
                <td style={{textAlign: "right"}}>{supplier.gst_no}</td>
                <td style={{textAlign: "center"}}>
                  {supplier.active}
                  <Form.Check // prettier-ignore
                    key={`supplier-switch-key-${idx}`}
                    type="switch"
                    id={`supplier-switch-${idx}`}
                    name={`supplier-switch-${idx}`}
                    onChange={handleOnChange}
                    checked={supplier.is_active}
                  ></Form.Check>
                </td>
                <td style={{textAlign: "center"}}>
                  <BsCardText
                    key={`edit-btn-supplier-key-${idx}`}
                    size={28}
                    style={{ color: "#0D6EFD", cursor: "pointer" }}
                    onClick={(e) => handleView(e)}
                    name={`edit-icon-supplier-name-${idx}`}
                    id={`edit-icon-supplier-id-${idx}`}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Modal
        show={showDialog}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Activae / In-Activate this supplier? Click OK to proceed...
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSwitchAction}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      </form>
      : 
      <div className="container-spinner">
     <div className="vertical-center-spinner">
      {/*<Button variant="primary" disabled>*/}
      <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
      {/*</Button>*/}
      </div>
      </div>
}
    </Container>
  );
}
export default ListSuppliers;
