import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Form, Button, View, Spinner, Modal, InputGroup, Image } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
import Sidebar2 from "./Sidebar2";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
//import ListGroup from "react-bootstrap/ListGroup";
//import Badge from "react-bootstrap/Badge";
//import DateTimeField from "react-bootstrap-datetimepicker"
//import DateTimePicker from "react-datetime-picker";

import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client } from "../config/Config";
import axios from "axios";

import { useLocation } from "react-router-dom";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import { BsFillPenFill, BsFillTrashFill,BsArrowLeftCircle, BsPencilSquare } from "react-icons/bs";

import DateTimePicker from "react-datetime-picker";
import SidebarMain from "./SidebarMain";
import { Eye, EyeSlashFill } from "react-bootstrap-icons";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';


const AddUpdateUser = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  //console.log("PASSED STATE VALUE IN Patient Add/Update page : ", state);

 
  let initialItems = [];
  /*

  const optionsRole = [
    { value: 1, label: "Admin" },
    { value: 2, label: "SuperAdmin" },
    { value: 3, label: "Staff" },
  ];
   */
  const intialValues = {
    
    employee_name: "",
    username: "",
    user_role_name: "",
    lock_status: false,
    password: "",
    confirm_password: ""	
  };
 
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [validateError, setValidateError] = useState(false);

  const [roleIdSelect, setRoleIdSelect] = useState({});
  var validateErr = false;

  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [submitAction, setSubmitAction] = useState(false);
  const [optionsEmp, setOptionsEmp] = useState([]);
  const [optionsUserRole, setOptionsUserRole] = useState([]);
  const [empIdSelect, setEmpIdSelect] = useState({});
  const [userRoleIdSelect, setUserRoleIdSelect] = useState({});
  const [showMsg, setShowMsg] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [toRender, setToRender] = useState(false);
  
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
    
  );
  

  const getUserRoleList = async() => {
    console.log("getRoleList method called ...");
    var retState = false;
    var userRoleArr = [];
 
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(
        client.domain + "/core/getUserRoles",
        { headers },
        { withCredentials: false }
      );
      
      console.log("Response from server getUserRoleList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        
        if (res.data.detail.data.length > 0) {
          //var empRoleInd = {};
          
          res.data.detail.data.map((userRole) => {
            console.log("User role ind data : ", userRole);
            var userRoleInd = {};
            userRoleInd.value = userRole.id;
            userRoleInd.label = userRole.name;
            userRoleArr.push(userRoleInd);
          
          })
          console.log("user Role Arr : ", userRoleArr);        
         
        }
        retState = true;
      }
      
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
         
        }
      }
    
    }
    return [retState, userRoleArr];
  }
  const getEmpList = async() => {
    console.log("getEmpList method called ...");
    var retState = false;
    var empArr = [];
 
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(
        client.domain + "/hrms/getEmployees",
        { headers },
        { withCredentials: false }
      );
     
      console.log("Response from server getEmpList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        
        if (res.data.detail.data.length > 0) {
          //var empRoleInd = {};
          
          res.data.detail.data.map((emp) => {
            console.log("Employee ind data : ", emp);
            var empInd = {};
            empInd.value = emp.id;
            empInd.label = emp.employee_name;
            empArr.push(empInd);
          
          })
          console.log("Employee Arr : ", empArr);        
         
        }
        retState = true;
      }
      
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          
        }
      }
    
    }
    return [retState, empArr];
  }
  

  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::addUpdateService called ##########################"
      );
      if (state === null) {
        navigate("/listUsers");
        
      } else {
        console.log("PASSED STATE VALUE IN User Add/Update page : ", state);
         console.log("Inside else for view or edit ...");
         
        /*
        setFormValues(state.passed_element);
        if (state.action === "add") {
          setIsReadOnly(false);
        }
        else {
          var is_active_val = 'NO';
          if ( state.action === 'edit' || state.action === 'view' ) {
          if ( state.passed_element.is_active === true ) {
            is_active_val = 'YES';
          }
          var passedElement = { ...state.passed_element, lock_status: is_active_val};
          setFormValues(passedElement);
        }
        */
       if ( state.action === 'view' || state.action === 'edit') {
        var is_active_val = 'NO';
        if ( state.action === 'edit' || state.action === 'view' ) {
        if ( state.passed_element.is_active === true ) {
          is_active_val = 'YES';
        }
        var passedElement = { ...state.passed_element, lock_status: is_active_val};
        setFormValues(passedElement);
      }
    }
    else {
      setFormValues({ ...formValues, ["lock_status"]: 'NO'});
      setIsReadOnly(false);
    }
        ( async() => {
          var [retStateUserRole, userRoleArr] = await getUserRoleList();
          if ( retStateUserRole ) {
            setOptionsUserRole(userRoleArr);
            if ( state.action === 'add' ) {
              setUserRoleIdSelect(userRoleArr[0]);
            }
            else {
              var findUserRole = userRoleArr.find(element => element.value === state.passed_element.user_role_id);
              if ( findUserRole !== undefined ) {
                console.log("++++++++++++++++++------------------- FOUND ROLE : ", findUserRole);
                setUserRoleIdSelect({value: state.passed_element.user_role_id, label: findUserRole.label});
              }
            }
          }
          var [retStateEmp, empArr] = await getEmpList();
          if ( retStateEmp ) {
            setOptionsEmp(empArr);
            if ( state.action === 'add' ) {
              setEmpIdSelect(empArr[0]);
            }
            else {
              var findEmp = empArr.find(element => element.value === state.passed_element.employee_id);
              if ( findEmp !== undefined ) {
                console.log("++++++++++++++++++------------------- FOUND ROLE : ", findEmp);
                setEmpIdSelect({value: state.passed_element.id, label: findEmp.label});
              }
            }
          }
        })();
       setToRender(true);
      console.log("PASSED STATE VALUE IN User Add/Update page : ", state);
      console.log("newObj :", formValues);
      /*  
      if (state.action === "add") {
          setIsReadOnly(false);
        }
      */
    }
  initialized.current = true;
  }
  }, []);
  const callUpdateApi = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
      var apiToCall = "";
      if (state.action === "add") {
        apiToCall = "/core/addUser";
      } else if (state.action === "view") {
        apiToCall = "/core/updateUser";
      } else {
        apiToCall = "/core/addUser";
      }
      
      var lockStatus = false;
      if ( formValues["lock_status"] === 'YES') {
        lockStatus = true;
      }
     
      var reqParam = {
        id: formValues["id"],
        username: formValues["username"],
        user_role_id:userRoleIdSelect.value,
        employee_id: empIdSelect.value, 
        is_locked: lockStatus
      }

      if ( state.action === 'add' ) {
        reqParam.password = formValues["password"];
      }
   
     
      var res = await axios.post(client.domain + apiToCall, reqParam, { headers },{ withCredentials: false });
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        setShowMsg(res.data.detail.data.message);
        setShowMsgTitle("Success");
        setShowDialog(true);
        setSubmitAction(false);
      }
      else {
        console.log("error data.message : ", res.data.detail.data);
        setShowMsg(res.data.detail.data.message);
        setShowMsgTitle("User creation failed");
        setShowDialog(true);
        setSubmitAction(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          
        }
      }
      setSubmitAction(false);
    }
  };

 
  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if ( state.action === 'add') {
    if (!values.username) {
      errors.username = "User name is required";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.password) {
      errors.password = "Password is required";
      validateErr = true;
      setValidateError(true);
    }

    if (!values.confirm_password) {
      errors.confirm_password = "Confirm Password should not be empty";
      validateErr = true;
    }

    if ( values.password !== values.confirm_password ) {
      errors.password = "password and confirm password should be same";
      errors.confirm_password = "password and confirm password should be same";
      validateErr = true;
    }
  }

    console.log("BEFORE RETURN validate errors ...", validateError);
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };

  const handleUserRoleSelect = (e, data) => {
    console.log("handleUserRoleSelect called ...", e);
    setUserRoleIdSelect(e);  
  };
  const handleEmpSelect = (e) => {
    console.log("handleEmpSelect called ...", e);
    setEmpIdSelect(e);
  };
    const handleValueChange = async (e) => {
    console.log("Value button clicked : ", e);

    const { name, id } = e.currentTarget;
    console.log("Captured value name : ", name);
    console.log("Captured value id : ", id);

    var currentRowName = id.substring(id.lastIndexOf("-") + 1);
    var nameOfElement = id.substring(0, id.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current row id : ", currentRowName);
    setFormValues({ ...formValues, ["lock_status"]: currentRowName.toUpperCase() });
  };
/*
  const handleValueChange = async (e) => {
    console.log("On change clicked : ", e);

    var tagId = e.target.id;
    var currentRowName = tagId.substring(tagId.lastIndexOf("-") + 1);
    var nameOfElement = tagId.substring(0, tagId.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current row id : ", currentRowName);

    setFormValues({ ...formValues, ["is_locked"]: currentRowName.toUpperCase() });
  };
*/
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit called ...");
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& : ",
      validateError
    );
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ",
      validateErr
    );
    if (validateErr === false) {
      console.log("FORM VALUES at handleSubmit: ", formValues);
      console.log("LINE ITEMS : ", items);
      console.log("Form submit called ...");

      console.log("User Name : ", formValues["name"]);
    }
  };
  const handleOnChange = (e) => {
    //e.preventDefault();
    console.log("handleOnChange : ", e);

    console.log("Button clicked : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);

    setFormValues({ ...formValues, [name]: value});
    
  };

  const handleEdit = async (e) => {
    console.log("Handle Edit user called : ", e);
    setIsReadOnly(false);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    console.log("Handle Click save called : ", e);
    console.log("Formvalues : ", formValues);

    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    if (validateErr === false) {
      setSubmitAction(true);
      callUpdateApi();
    }
  };

 
  const handleReset = async (e) => {
    console.log("handle reset clicked : ", e);
    if(state.passed_element.is_active === false){
      setShowMsgTitle("Alert");
      setShowMsg("Please activate the user before reset password!")
      setShowDialog(true);
    }
    else{
    navigate("/addUpdateResetPassword", {state: {passed_element: {"username": state.passed_element.username, "user_id": state.passed_element.id}, action: 'view'}}); 
    }
  };
  const handleBack = async (e) => {
    console.log("handle back clicked : ", e);
    navigate(-1);
  };
  const handleClear = async (e) => {
    console.log("handle clear clicked : ", e);
    navigate(0);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    //navigate("/listUsers");
  };

  const handlePostSaveOk = () => {
    console.log("user confirmed to user added ...");
    //setShowDialog(false);
    if(state.action === 'edit' || state.action === 'view'){
     navigate("/listUsers");
    }
    else{
      setShowDialog(false); 
    }
    
  };
  const [showPass, setShowPass] = useState(false);
  const clickHandler = () => {
    setShowPass((prev) => !prev);
  };
  return (
    <>
      <Container>
        <SidebarMain />
        { toRender ?
        (
        <Form>
        <Row>
            <Col xs={2} md={2} sm={2} lg={2}>  
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>User</span>
            { (state.action === 'view' || state.action === 'edit') &&
              <>
              &nbsp;&nbsp;
             
            <Button variant={state.passed_element.is_active ? "outline-success" : "outline-danger"} size="sm"><span style={{cursor: "default", fontWeight: "500"}}>{state.passed_element.status}</span></Button>
            </>
            }
            </Col>
            
            <Col
              xs={10}
              md={10}
              sm={10}
              lg={10}
              className="float-end justify-content-end"
            >
              <div className="d-flex justify-content-end">
              <Button
                      style={{cursor: "default", fontWeight: "500"}}
                      variant="outline-info"
                      id="Reset"
                      size="sm"
                      onClick={(e) => handleReset(e)}
                    >
                      Reset Password
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                  <BsArrowLeftCircle
                    key={`back-btn-user-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                   
                    name={`back-icon-user-name`}
                    id={`back-icon-user-id`}
                  />
                </Button>
                {(state.action === "edit" || state.action === "view") && (
                  <>
                &nbsp;&nbsp;
                <Button variant="primary" className="btn btn-primary btn-md"  onClick={(e) => handleEdit(e)}>
                  <BsPencilSquare
                    key={`edit-btn-user-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                  
                    name={`edit-icon-paBiSolidEditAlttient-name`}
                    id={`edit-icon-user-id`}
                  />
                </Button>
                </>
                )}
              </div>
            </Col>
          </Row>
          <hr align="center" />
          <div className="ui-divider"></div>
          <div className="ui-form">
            <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3" >
                  <Form.Label className="field required">Employee</Form.Label>
                  <Select
                    id="employee_name"
                    name="employee_name"
                    value={{
                      value: empIdSelect.value,
                      label: empIdSelect.label,
                    }}
                    options={optionsEmp}
                    defaultValue={{
                      value: empIdSelect.value,
                      label: empIdSelect.label,
                    }}
                    onChange={handleEmpSelect}
                    readOnly={state.action === 'add' ? isReadOnly : true}
                    isDisabled={state.action !== 'add' && true}
                   //disabled={isReadOnly}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.employee_name}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required"> User Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="username"
                    name="username"
                    placeholder="User Name"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={state.action === 'add' ? isReadOnly : true}
                    disabled={state.action !== 'add' && true}
                    //disabled={isReadOnly}
                    value={formValues["username"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.username}</p>
              </Col>
              
            </Row>
            <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3">
                  <Form.Label className="field required"> User Role</Form.Label>
                  <Select
                    id="user_role_name"
                    name="user_role_name"
                    value={{
                      value: userRoleIdSelect.value,
                      label: userRoleIdSelect.label,
                    }}
                    options={optionsUserRole}
                    defaultValue={{
                      value: userRoleIdSelect.value,
                      label: userRoleIdSelect.label,
                    }}
                    onChange={handleUserRoleSelect}
                    isDisabled={isReadOnly}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.user_role_name}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
              <Form.Label className="field required">Locked</Form.Label>
                <div key={`inline-radio`} className="mb-3">
                  <Form.Check
                    disabled={isReadOnly}
                    inline
                    label="Yes"
                    name="lock_status"
                    type={"radio"}
                    id={`inline-radio-yes`}
                    onChange={(e) => handleValueChange(e)}
                    checked={ formValues["lock_status"] === 'YES' ? true : false}
                  />
                  <Form.Check
                    disabled={isReadOnly}
                    inline
                    label="No"
                    name="lock_status"
                    type={"radio"}
                    id={`inline-radio-no`}
                    onChange={(e) => handleValueChange(e)}
                    checked={ formValues["lock_status"] === 'NO' ? true : false}
                  />
                </div>
              </Col>
            </Row>
            { (state.action === 'add') &&
            <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
               {/*
                <Form.Group className="mb-3">
                  <Form.Label className="field required"> Password</Form.Label>
                 
                  <Form.Control
                    type="text"
                    id="password"
                    name="password"
                    placeholder="Password"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["password"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                  */}
                  <Form.Group className="mb-3">
                  <Form.Label className="field required"> Password</Form.Label>
                   <InputGroup className="mb-3">
       
        <Form.Control
          type="password"
          name="password"
          placeholder="Password"
          aria-label="password"
          aria-describedby="password-input"
          required
          onChange={(e) => handleOnChange(e)}
          readOnly={isReadOnly}
          value={formValues["password"]}
        />
        <InputGroup.Text onClick={clickHandler}>
          {/* You can use both. I ran into some width issues with FontAwesome but they can be fixed by playing around */}
          {/* <FontAwesomeIcon icon={showPass ? faEyeSlash : faEye} /> */}
          {showPass ? <EyeSlashFill /> : <Eye />}
        </InputGroup.Text>
      </InputGroup>
      </Form.Group>
                <p className="error-msg">{formErrors.password}</p>
              </Col> 
            
            <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required"> Confirm Password</Form.Label>
                  <Form.Control
                    type="text"
                    id="confirm_password"
                    name="confirm_password"
                    placeholder="Confirm Password"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["confirm_password"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.confirm_password}</p>
              </Col> 
            </Row>
            }

            {!isReadOnly && (
              <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ disply: "flex", justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleClear(e)}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      type="submit"
                      id="save"
                      onClick={(e) => handleSave(e)}
                      disabled={submitAction ? true: false}
                    >
                     {submitAction && 
                      <>
                      <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                     <span className="visually-hidden">Loading...</span>
                     </>
                     }  
                     Save
                    </Button>
                  </div>
                </Col>
                
              </Row>
            )}
             <Modal
              show={showDialog}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>{showMsgTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{showMsg}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handlePostSaveOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </Form>
        )
        :
        <div className="container-spinner">
        <div className="vertical-center-spinner">
         {/*<Button variant="primary" disabled>*/}
         <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
            <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{ width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
                <span className="visually-hidden">Loading...</span>
              </Spinner>
   
         {/*</Button>*/}
         </div>
         </div>
      }
      </Container>
    </>
  );
};

export default AddUpdateUser;
