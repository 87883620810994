import { Fragment, useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  Badge,
  Modal,
  Spinner,
  Image
} from "react-bootstrap";

import Sidebar2 from "./Sidebar2";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import "../Dashboard.css";
import "../App.css";
import { client,appGlobal } from "../config/Config";
import axios from "axios";

import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsTrashFill,
  BsArrowLeftCircle,
  BsFillPenFill,
} from "react-icons/bs";
import SidebarMain from "./SidebarMain";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';

//function AddUpdateCustomer () {
const AddUpdateStock = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  let initialItems = [];
  const intialValues = {
    name: "",
    hsncode: "",
    uom: "",
    tt_value: "",
    stock_open: 0,
    stock_in: 0,
    stock_out: 0,
    stock_available: 0,
    stock_min:0,
    stock_max:0,

  };
  const [formValues, setFormValues] = useState(intialValues);
  const [toRender, setToRender] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const [validateError, setValidateError] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);

  const [isReadOnly, setIsReadOnly] = useState(true);

  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  //const [selectedId, setSelectedId] = useState(-1);
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );
  const [submitAction, setSubmitAction] = useState(false);

  var validateErr = false;

  const handleOnChange = (e) => {
    //e.preventDefault();
    console.log("Button clicked : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    if (e.currentTarget.value && name === "stock_open" || name === "stock_min" || name === "stock_max"  ) {
      console.log("Inside check ******************");
      
     // const numericRegExp = /^\d*\.?\d*$/; //original
     // const numericRegExp = /^\d*(\.\d{0,2})?$/;
    
      if (e.currentTarget.value.match(appGlobal.numericRegExp)) {
        setFormValues({ ...formValues, [name]: e.currentTarget.value });
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };
    const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
   

    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!values.stock_open) {
      errors.stock_open = " Stock Open is required!";
      validateErr = true;
      //setValidateError(true);
    }
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };
 
 
  const handleEdit = async (e) => {
    console.log("handle edit clicked : ", e);
    setIsReadOnly(false);
  };

  const handleBack = async (e) => {
    console.log("handle back clicked : ", e);
    navigate(-1);
  };

  const handleReset = async (e) => {
    console.log("handle reset clicked : ", e);
    navigate(0);
  };
  
  const handleCloseDialog = () => {
    setShowDialog(false);
    navigate("/ListDocNumber");
  };

  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::addUpdateStock called ##########################"
      );

      if (state === null) {
        navigate("/listStock");
      } else {
        console.log("PASSED STATE VALUE IN Stock Add/Update page : ", state);
        if (state.action === "add") {
          setIsReadOnly(true);
        }
        else {
          setFormValues(state.passed_element);
        }
        console.log("newObj :", formValues);
        if (state.action === "add") {
          setIsReadOnly(false);
        }
      }
      setToRender(true);
      initialized.current = true;
    }
  }, []);
  

  
  const callUpdateApi = async () => {

      
      /*
      else if ( formValues["trade_type"] === 'SELL' ) {
        reqParam.product_id = formValues["product_id"];
        reqParam.stock_open = formValues["stock_open"];
        reqParam.stock_id = formValues["stock_id"];
        if (state.action === "view" || state.action === "edit") {
          reqParam.id = state.passed_element.id;
          apiToCall = "/ims/updateProductTTSellStockOpen";
        }
      }
     */ 
     try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
       var apiToCall = "";
      var reqParam = {};
       var reqParam = {};
      if ( formValues["tt_value"] === 'BUY') {
        reqParam.product_id = formValues["product_id"];
        reqParam.stock_open = parseFloat(formValues["stock_open"]);
        reqParam.stock_min = parseFloat(formValues["stock_min"]);
        reqParam.stock_max = parseFloat(formValues["stock_max"]);
        reqParam.stock_id = formValues["id"];
        if(state.action === "view" || state.action === "edit") {
          reqParam.id = state.passed_element.id;
          apiToCall = "/ims/updateProductTTBuyStockOpen";
         }
      }

      var res = await axios.post(client.domain + apiToCall, reqParam, { headers }, { withCredentials: false });
      
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        setShowMsg(res.data.detail.data.message);
        setShowMsgTitle("Success");
        //setShow(true);
        setShowDialog(true);
        setSubmitAction(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
        }
      }
      setSubmitAction(false);
    }
  };
  const handlePostSaveOk = () => {
    console.log("user confirmed to Stock added ...");
    
    setShowDialog(false);
    navigate("/listStocks");
  };
  
  const handleSave = async (e) => {
    e.preventDefault();
    console.log("Handle Click save called : ", e);
    console.log("Formvalues : ", formValues);

    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    if (validateErr === false) {
      setSubmitAction(true);
      callUpdateApi();
    }
    
  };
/*
  const handleClose = async (e) => {
    setShow(false);
    navigate(-1);
  };
*/
  return (
    <>
      <Container>
        <SidebarMain />
        { toRender ?
        (
        <Form>
          <Row>
            <Col xs={4} md={4} sm={4} lg={4}>  
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>Stock</span>
            </Col>
            
            <Col
              xs={8}
              md={8}
              sm={8}
              lg={8}
              className="float-end justify-content-end"
            >
              <div className="d-flex justify-content-end">
                <Button variant="warning" className="btn btn-warning btn-md"
                key={`back-btn-stock-key`}
               
                style={{ cursor: "pointer" }}
                onClick={(e) => handleBack(e)}
                name={`back-icon-stock-name`}
                id={`back-icon-stock-id`}>
                  <BsArrowLeftCircle
                    size={24}
                  />
                </Button>
                {(state.action === "edit" || state.action === "view") && (
                  <>
                &nbsp;&nbsp;
                <Button variant="primary" className="btn btn-primary btn-md"
                key={`edit-btn-stock-key`}
              
                style={{ cursor: "pointer" }}
                onClick={(e) => handleEdit(e)}
                name={`edit-icon-paBiSolidEditAlttient-name`}
                id={`edit-icon-stock-id`}>
                  <BsPencilSquare
                    size={24}
                  />
                </Button>
                </>
                )}
              </div>
            </Col>
          </Row>

          <div className="ui-divider"></div>
          <div className="ui-form">
            <hr align="center" />
            {/*
            <Row style={{ height: props.height }}>
              <hr />
            </Row>
              */}

            <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label >  Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="name"
                    name="name"
                    placeholder=" Name"
                    onChange={(e) => handleOnChange(e)}
                    disabled={isReadOnly || true}
                    value={formValues["name"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.name}</p>
                </Col>
                <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label > HSN Code</Form.Label>
                  <Form.Control
                    type="text"
                    id="hsncode"
                    name="hsncode"
                    placeholder=" HSN Code"
                    onChange={(e) => handleOnChange(e)}
                    disabled={isReadOnly  || true}
                    value={formValues["hsncode"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.hsncode}</p>
                </Col>
                <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label >UOM </Form.Label>
                  <Form.Control
                    type="text"
                    id="uom"
                    name="uom"
                    placeholder=" UOM"
                    onChange={(e) => handleOnChange(e)}
                    disabled={isReadOnly || true}
                    value={formValues["uom"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.uom}</p>
                </Col>
                <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label >Trade Type</Form.Label>
                  <Form.Control
                    type="text"
                    id="tt_value"
                    name="tt_value"
                    placeholder=" Name"
                    onChange={(e) => handleOnChange(e)}
                    disabled={isReadOnly || true}
                    value={formValues["tt_value"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
              </Col>
              </Row>
              <Row>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Stock Open</Form.Label>
                  <Form.Control
                    
                    type="text"
                    id="stock_open"
                    maxLength={10}
                    name="stock_open"
                    placeholder="Stock Open"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={(!isReadOnly && formValues["tt_value"] !== 'SELL') ? false : true}
                    value={formValues["stock_open"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.stock_open}</p>
                </Col>
               
               
             
             
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label > Stock In</Form.Label>
                  <Form.Control
                    type="text"
                    id="stock_in"
                    name="stock_in"
                    placeholder="Stock In"
                    onChange={(e) => handleOnChange(e)}
                    disabled={isReadOnly || true}
                    value={formValues["stock_in"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.stock_in}</p>
                </Col>
                <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label > Stock Out</Form.Label>
                  <Form.Control
                    type="text"
                    id="stock_out"
                    name="stock_out"
                    placeholder="Stock Out"
                    onChange={(e) => handleOnChange(e)}
                    disabled={isReadOnly || true}
                    value={formValues["stock_out"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.stock_out}</p>
                </Col>
                <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label> Stock Available</Form.Label>
                  <Form.Control
                    type="text"
                    id="stock_available"
                    name="stock_available"
                    placeholder="Stock Available"
                    onChange={(e) => handleOnChange(e)}
                    disabled={isReadOnly || true}
                    value={formValues["stock_available"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.stock_available}</p>
              </Col> 
              </Row>
              <Row>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label >Alert level Minimum</Form.Label>
                  <Form.Control
                   
                    type="text"
                    id="stock_min"
                    name="stock_min"
                    placeholder="Alert Level Minimum"
                    maxLength={10}
                    onChange={(e) => handleOnChange(e)}
                    readOnly={(!isReadOnly && formValues["tt_value"] !== 'SELL') ? false : true}
                    value={formValues["stock_min"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.stock_min}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label >Alert Level Maximum</Form.Label>
                  <Form.Control
                  
                    type="text"
                    id="stock_max"
                    name="stock_max"
                    maxLength={10}
                    placeholder="Alert Level Maximum"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={(!isReadOnly && formValues["tt_value"] !== 'SELL') ? false : true}
                    value={formValues["stock_max"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.stock_max}</p>
                </Col>
              </Row>
             
            {(!isReadOnly && formValues["tt_value"] !== 'SELL') && (
              <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ disply: "flex", justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleReset(e)}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      type="submit"
                      id="save"
                      onClick={(e) => handleSave(e)}
                      disabled={submitAction ? true: false}
                    >
                       {submitAction && 
                      <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                       />
                      <span className="visually-hidden">Loading...</span>
                      </> 
} 
                     Save
                    </Button>
                  </div>
                </Col>
                
              </Row>
            )}
           
            
            <Modal
              show={showDialog}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>{showMsgTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{showMsg}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handlePostSaveOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
                
          </div>
        </Form>
        )
        :
        <div className="container-spinner">
        <div className="vertical-center-spinner">
         {/*<Button variant="primary" disabled>*/}
         <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
            <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{ width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
                <span className="visually-hidden">Loading...</span>
              </Spinner>
   
         {/*</Button>*/}
         </div>
         </div>
      }
      </Container>
    </>
  );
};

export default AddUpdateStock;
