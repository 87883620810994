import React, {useState, useEffect, useRef} from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { client } from '../config/Config';
import axios from 'axios';

import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
  } from "@react-pdf/renderer";


  import OrderDocument from "./OrderDocument";

  import useWindowDimensions from "./ScreenSizeDetection";
  
  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#d11fb6",
      color: "white",
    },
    section: {
      margin: 10,
      padding: 10,
    },
    viewer: {
      width: window.innerWidth, //the pdf viewer will take up all of the width and height
      height: window.innerHeight,
    },
  });
  
  // Create Document Component
  function PrintFullOrder(props) {
    const [toRender, setToRender] = useState(false);
    const { location } = useLocation();
    const { state } = useLocation();
    const navigate = useNavigate();
    console.log("LOCATION NAME : ", location);

    const [show, setShow] = useState(false);
    const [orderReportData, setOrderReportData] = useState({});
    const [showMsg, setShowMsg] = useState('Please login again');
    const [showMsgTitle, setShowMsgTitle] = useState('Invalid authentication Session!');


    console.log("CALLED CURRENT PAGE : ", window.location.href);

    console.log("CALLED window locaion : ", window.location);
    let params = (new URL(window.location)).searchParams;
    console.log("COMPLETE PARAMS : ", params);
    let orderIdParam = params.get("order_id");
    console.log("ORDER ID FROM PARAM : ", orderIdParam);

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.indexOf('?') + 1);
    var urlTmp = urlStr.substring(0, urlStr.indexOf("?"));

    console.log("URL : ", urlTmp);
    console.log("URL : ", paramsTmp);

    let searchParams = new URLSearchParams(paramsTmp);
    var orderIdTemp2 = searchParams.get("order_id");
    //var dcIdTemp2 = searchParams.get("dc_id");

    console.log("Extracted Order id : ", orderIdTemp2);
    //console.log("Extracted DC id : ", dcIdTemp2);

    //console.log("CALLED CURRENT PAGE 2 : ", props.location.pathname);
    //console.log("PASSED STATE VALUE IN PrintDocument page : ", state);
    /*
    if ( state === null ) {
        navigate("/")
    }
    */

    const getOrderDetailsPrint = async(orderId) => {
        let reqOrderPrintReq = {
                        order_id : orderId,
                };

                var headers = client.headers;
                console.log("payload DC Print :", reqOrderPrintReq);

                try {
                        var res = await axios.post(client.domain + '/getFullOrderDetailsPrint', reqOrderPrintReq, { headers }, { withCredentials: false });
                        console.log("Response from server : ", res.data.detail);

                        if ( res.data.detail.status === 'SUCCESS') {
                                console.log("Response message from server : ", res.data.detail.res_data);
                                //navigate("/dashboard");
                                setOrderReportData(res.data.detail.res_data)
                                setToRender(true);
                        }
                }
                catch(error) {
                        console.log("FULL ERROR : ", error);
                        if ( error.code === 'ERR_NETWORK' ) {
                                console.log("Not able to reach target server please try after sometime");
                                setShowMsg('Please try after sometime');
                                setShowMsgTitle('Network Issue!');
                                setShow(true);
                        }
                        else{
                                console.log("ERROR : ", error.response.data.detail);
                                if (error.response.data.detail.status === 'FAILED') {
                                        console.log('Request failed due to ', error.response.data.detail.res_data);
                                        setShowMsg('Please try again ...');
                                        setShowMsgTitle('Unable to process your request!');
                                        setShow(true);
                                        //navigate("/auth");
                                }
                        }
                }
    }


   useEffect(() => {

        //var aIVal = localStorage.getItem("_aI");
        //var usernameVal = localStorage.getItem("username");
        //console.log("ORDER Id : ", state.orderId);
        //console.log("DC Id : ", state.dcId);
        
        //var orderIdTmp = 116;
        //var dcIdTmp = 62;
        getOrderDetailsPrint(orderIdTemp2);
        //setToRender(true);
        /*
        if((usernameVal !== null && usernameVal !== '') && (aIVal !== null && aIVal !== '')) {
            validateSession(usernameVal, aIVal);
        }
        else {
            //console.log('Not logged In');
            //navigate("/auth");
        }
        */
 }, [])


    const { height, width } = useWindowDimensions();
    console.log("<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>> Width : ", width);
    console.log("<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>> Height : ", height);

    return (
      <PDFViewer style={{width: window.innerWidth, height: window.innerHeight}}>
       { toRender === true && 
        <OrderDocument order_data={orderReportData} />
    }
      </PDFViewer>
    );
  }
  export default PrintFullOrder;
