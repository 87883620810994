import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import OrderTableHeader from './OrderTableHeader'
import OrderTableRow from './OrderTableRow'
import OrderTableBlankSpace from './OrderTableBlankSpace'
import OrderTableFooter from './OrderTableFooter'

//const tableRowsCount = 3;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 14,
        borderWidth: 1,
        borderColor: '#a8a8a8',  
    },
});

  //const InvoiceItemsTable = (props) => (
  const OrderItemsTable = (props) => {
    console.log("PROPS at InvoiceItemsTable : ", props);
    let tableRowsCountTmp = props.orderLoad.prod_list.length;
    return (
    <View style={styles.tableContainer}>
        <OrderTableHeader />
        <OrderTableRow items={props.orderLoad.prod_list} invoice={props.orderLoad}/>
        <OrderTableBlankSpace rowsCount={ tableRowsCountTmp - props.orderLoad.prod_list.length} />
        <OrderTableFooter items={props.orderLoad.prod_list} invoice={props.orderLoad}/>
    </View>
  );
  }
  
  export default OrderItemsTable