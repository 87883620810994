import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  Label,
  Spinner,
  Image
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
import Sidebar from "./Sidebar";
import Sidebar2 from "./Sidebar2";
import Topmenu from "./Topmenu";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
//import DateTimeField from "react-bootstrap-datetimepicker"
import DateTimePicker from "react-datetime-picker";

import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client, ConfigMinValue, configMaxValue, appGlobal } from "../config/Config";
import axios from "axios";

import { useLocation } from "react-router-dom";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import { BsCartX, BsArrowLeftCircle, BsFillXCircleFill } from "react-icons/bs";
import SidebarMain from "./SidebarMain";
import {getProductBuy } from "./utils";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';

const AddUpdatePurchaseDC = (props) => {
  const initialized = useRef(false);
  const navigate = useNavigate();
  const { state } = useLocation();

  //console.log("Product List : ", state.prod_list);

  /*
  console.log("STATE VALUE : ", state);
  if (state === null) {
    navigate("/");
  } else {
    console.log("PASSED STATE VALUE IN DC Add/Update page : ", state);
  }
  */
  const [passedValue, setPassedValue] = useState({});
  const [productList, setProductList] = useState();
  const [empList, setEmpList] = useState();
  const [date, setDate] = useState(new Date());
  //setPassedValue(state);

  /*
    let initialItems = [
      {id:1, product: {value:3, label:"M15"}, price: "4900", quantity: 5, "total_item_price": "24500", "gst": "4410", "total_price":"28910", gst:{cgst: 2205.00, sgst:2205.00, igst:0.00, totGst: 4410.00}}, 
      {id:1, product: {value:6, label:"M25"}, price: "4800", quantity: 7, "total_item_price": "215001", "gst": "38701", "total_price":"253701", gst:{cgst: 2205.00, sgst:2205.00, igst:0.00, totGst: 4410}}
    ]
    */

  /*
    const itemProdOptions = [
      { value: 1, label: 'M5', stock_id: 1 },
    ];
    */
  let initialItems = [];

  /*
    const options = [
        { value: 1, label: 'M5', stock_id: 1 },
        { value: 2, label: 'M7.5', stock_id: 2 },
        { value: 6, label: 'M10', stock_id: 3 },
        { value: 7, label: 'M15', stock_id: 4 },
        { value: 8, label: 'M20', stock_id: 5 },
        { value: 9, label: 'M25', stock_id: 6 },
        { value: 10, label: 'M30', stock_id: 7 }
    ];
    */
  /*

 let product_list = [
   {
     product_id: 1,
     stock_id: 1,
     product_name: "M5",
     unit_price: 3900,
     cgst: 9.0,
     sgst: 9.0,
     igst: 0.0,
   },
   {
     product_id: 2,
     stock_id: 13,
     product_name: "M7.5",
     unit_price: 3900,
     cgst: 9.0,
     sgst: 9.0,
     igst: 0.0,
   },
   {
     product_id: 6,
     stock_id: 14,
     product_name: "M10",
     unit_price: 4200,
     cgst: 9.0,
     sgst: 9.0,
     igst: 0.0,
   },
   {
     product_id: 7,
     stock_id: 15,
     product_name: "M15",
     unit_price: 4600,
     cgst: 9.0,
     sgst: 9.0,
     igst: 0.0,
   },
   {
     product_id: 8,
     stock_id: 16,
     product_name: "M20",
     unit_price: 5000,
     cgst: 9.0,
     sgst: 9.0,
     igst: 0.0,
   },
   {
     product_id: 9,
     stock_id: 17,
     product_name: "M25",
     unit_price: 5400,
     cgst: 9.0,
     sgst: 9.0,
     igst: 0.0,
   },
   {
     product_id: 10,
     stock_id: 18,
     product_name: "M30",
     unit_price: 5800,
     cgst: 9.0,
     sgst: 9.0,
     igst: 0.0,
   },
   {
     product_id: 13,
     stock_id: 19,
     product_name: "M40",
     unit_price: 6000,
     cgst: 9.0,
     sgst: 9.0,
     igst: 0.0,
   },
 ];

 const optionsDistrict = [
   { value: 1, label: "Coimbatore" },
   { value: 2, label: "Tiruppur" },
   { value: 3, label: "Erode" },
   { value: 4, label: "Karur" },
   { value: 5, label: "Namakkal" },
   { value: 6, label: "Ooty" },
   { value: 7, label: "Salem" },
 ];

 const optionsState = [
   { value: 1, label: "Tamil Nadu" },
   { value: 2, label: "Kerala" },
   { value: 3, label: "Karanataka" },
   { value: 4, label: "Andra Pradesh" },
   { value: 5, label: "Telungana" },
   { value: 6, label: "Pondichery" },
 ];

 const vehicleArr = [
   { value: 4, label: "TN 20 AS 4152" },
   { value: 5, label: "TN 18 C 8033" },
   { value: 6, label: "TN 39 DX 7296" },
   { value: 7, label: "TN 39 DX 7257" },
   { value: 8, label: "TN 39 DX 9927" },
   { value: 9, label: "TN 39 DX 2599" },
 ];

 const driverArr = [
   { value: 3, label: "Prabhu" },
   { value: 4, label: "Deva" },
   { value: 5, label: "Mathesh" },
   { value: 6, label: "Murukeshan" },
   { value: 7, label: "Shanmugaraj" },
   { value: 8, label: "Ayyappan" },
   { value: 9, label: "Azhagu Raja" },
   { value: 10, label: "Balasubramani" },
 ];
 */

  const optionsProdLoad = [];

  // const [optionDriver, setOptionDriver] = useState(driverArr);
  // const [optionVehicle, setOptionVehicle] = useState(vehicleArr);

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");


  }

  const formateTime = (currTime) => {
    var h = (currTime.getHours() < 10 ? "0" : "") + currTime.getHours();
    var m = (currTime.getMinutes() < 10 ? "0" : "") + currTime.getMinutes();
    var hrMin = h + ":" + m;
    return hrMin;
  };



  const intialValues = {

    dc_date: formatDate(new Date()),
    //loading_start_time: new Date().toLocaleTimeString(),
    received_time: formateTime(new Date()),
    addl_info: "",
    vehicle_info: "",
    received_by: "",
    products: "",

  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [productInfo, setProductInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [empIdSelect, setEmpIdSelect] = useState({});
  /* const [stateIdSelect, setStateIdSelect] = useState({
     value: 1,
     label: "Tamil Nadu",
   });
 
   const [districtIdSelect, setDistrictIdSelect] = useState({
     value: 2,
     label: "Tiruppur",
   });
 
   const [vehicleIdSelect, setVehicleIdSelect] = useState({});
 
   const [driverIdSelect, setDriverIdSelect] = useState({
     value: 6,
     label: "Ayyappan (KMC1006)",
   });
 
   const [optDist, setOptDist] = useState(optionsDistrict);
 
   const [optVehicle, setOptVehicle] = useState([]);
 
   const [optDriver, setOptDriver] = useState([]);
 
   const [optionsSearch, setOptionsSearch] = useState([]);
 
   const [companySearchText, setCompanySearchText] = useState("");
 
   const [searchedCompany, setSearchedCompany] = useState("");
   */

  const [optionsProd, setOptionsProd] = useState(optionsProdLoad);

  const [validateError, setValidateError] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [showMsg, setShowMsg] = useState("");
  const [msgType, setMsgType] = useState("success");
  const [show, setShow] = useState(false);

  const [orderProdList, setOrderProdList] = useState([]);


  const [supplierInfo, setSupplierInfo] = useState({});

  const [toRender, setToRender] = useState(false);

  var validateErr = false;

  //type ValuePiece = Date | null;
  //type Value = ValuePiece | [ValuePiece, ValuePiece];

  //const [value, onChange] = useState<Value>(new Date());

  //const [searchText, setSearchText] = useState('');
  function convertDatePickerTimeToMySQLTime(str) {
    var month, day, year, hours, minutes, seconds;
    var date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    hours = ("0" + date.getHours()).slice(-2);
    minutes = ("0" + date.getMinutes()).slice(-2);
    seconds = ("0" + date.getSeconds()).slice(-2);

    var mySQLDate = [date.getFullYear(), month, day].join("-");
    var mySQLTime = [hours, minutes, seconds].join(":");
    return [mySQLDate, mySQLTime].join(" ");
  }

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const toSqlDatetime = (inputDate) => {
    const date = new Date(inputDate);
    const dateWithOffest = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    return dateWithOffest.toISOString().slice(0, 19).replace("T", " ");
  };

  const onChangeDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);

    /*
    var formattedDt = toSqlDatetime(e);
    setFormValues({ ...formValues, ["dc_date"]: formattedDt });
    console.log("Formatted str DC date : ", formattedDt);
    */
    setFormValues({ ...formValues, ["dc_date"]: e.target.value });
  };

  const onChangeReceivedTime = (e) => {
    console.log("Date onChange Loading time called ...", e);
    console.log("Date onChange Loading time TIME called ...", e.target.value);
    /*
    var formattedDt = toSqlDatetime(e);

    setFormValues({ ...formValues, ["loading_start_time"]: formattedDt });
    console.log("Formatted str Loading time : ", formattedDt);
    */
    setFormValues({ ...formValues, ["received_time"]: e.target.value });
  };


  const handleValueChange = async (e, data) => {
    e.preventDefault();
    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    setFormValues({ ...formValues, [name]: value });

    //console.log("Clicked data : ", data);
    /*

    if (name === "contact_number" || name === "pincode") {
      console.log("Inside check ******************");
      var valueTmp = Math.round(e.target.value.replace(/\D/g, ""));
      setFormValues({ ...formValues, [name]: valueTmp });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
    */
    setFormValues({ ...formValues, [name]: value });
  };
  /*

  const handleSearch = (e, dat) => {
    console.log("Handled search method called ...", e);

    var stateIdInput = 1;
    var stateNameInput = "TAMIL NADU";
    getDistrictList(stateIdInput, stateNameInput);
  };

  const handleDistrictSelect = (e, data) => {
    console.log("handleDistrictSelect called ...", e);

    setDistrictIdSelect(e);
  };

  const handleVehicleSelect = (e, data) => {
    console.log("handleVehicleSelect called ...", e);

    setVehicleIdSelect(e);
  };

  const handleDriverSelect = (e, data) => {
    console.log("handleDriverSelect called ...", e);

    setDriverIdSelect(e);
  };

  const handleStateSelect = (e, data) => {
    console.log("handleStateSelect called ...", e);
    setStateIdSelect(e);

    var stateIdInput = e.value;
    var stateNameInput = e.label;
    getDistrictList(stateIdInput, stateNameInput);
  };
*/
  const handleChange = (e, data) => {
    console.log("Handle Change clicked : ", e);
    console.log("Handle Change clicked target : ", e.target);
    //console.log("HTML tag : ", data);
    const { name, value } = e.target;
    console.log("Captured Quantity name : ", name);
    console.log("Captured Quantity value : ", value);
    //setFormValues({...formValues, [name]: value});
    console.log("FORM VALUES at handleChange func : ", formValues);

    var currentId = name.substring(name.lastIndexOf("-") + 1);
    var nameOfElement = name.substring(0, name.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    console.log(
      "<<<<<<<<<<<<<<<<< ITEM SELECTED ********** ",
      items[currentId]
    );
    var itemSelected = items[currentId];
    //console.log("Product: ", itemSelected.product);

    console.log("Quantity : ", itemSelected.quantity);

    //console.log("Product Info : ", productInfo);

    const currentProductIndex = orderProdList.findIndex((productInd) => productInd.product_name === itemSelected.product_name);
    console.log("Currently selected row product id : ", currentProductIndex);
    if (currentProductIndex < 0) {
      itemSelected.error = "Please select product from list";

      const newitems = items.slice();
      newitems[currentId] = itemSelected;
      setItems(newitems);

      return 0;
    } else {
      itemSelected.error = "";
    }
    console.log("Currently selected row product value : ", orderProdList[currentProductIndex]);

    if (nameOfElement === "quantity") {
      console.log("Product Quantity item edited ...");
      if (e.currentTarget.value.match(appGlobal.numericRegExp)) {
        itemSelected[nameOfElement] = e.currentTarget.value.replace(/^0+/, '');
      }
    } else {
      console.log("Other than handled option clicked so skipping it ...");
    }
/*
      var valueTmp = e.target.value.replace(/[^0-9.]/g, "");
      //.replace(/(\..*)\./g, "$1");
      console.log("Matched value >>>>>>>>>>>>>>> : ", valueTmp);
      itemSelected[nameOfElement] = valueTmp;
    }
    else {
      console.log("Other than handled option clicked so skipping it ...");
    }
    */
    /*
     itemSelected.total_item_price =
       itemSelected.unit_price * itemSelected.quantity;
     itemSelected.cgst =
       (itemSelected.total_item_price * productInfo[currentProductIndex].cgst) /
       100;
     itemSelected.sgst =
       (itemSelected.total_item_price * productInfo[currentProductIndex].sgst) /
       100;
     itemSelected.igst =
       (itemSelected.total_item_price * productInfo[currentProductIndex].igst) /
       100;
     let totalGst = itemSelected.cgst + itemSelected.sgst + itemSelected.igst;
 
     //var gst = { cgst: cgstTmp, sgst: sgstTmp, igst: igstTmp, totGst: totalGst}
     //itemSelected.gst = gst;
     //itemSelected.total_item_price = productInfo[currentProductIndex].unit_price * itemSelected.quantity;
 
     itemSelected.total_price =
       parseFloat(itemSelected.total_item_price) + parseFloat(totalGst);
 
     console.log("Modified unit cost : ", itemSelected.unit_price);
     console.log("Modified Product cost : ", itemSelected.total_item_price);
     console.log("Modified Total cost : ", itemSelected.total_price);
     */

    console.log("MODIFIED ELEMENT >>>>>>>>>>>>>>>>>>>>> : ", itemSelected);
    const newitems = items.slice();
    newitems[currentId] = itemSelected;
    setItems(newitems);
  };
  /*

  const getDistrictList = async (stateIdInput, stateNameInput) => {
    console.log("getDistrictList method called ...");
    let reqLoginPayload = {
      stateId: stateIdInput,
      stateName: stateNameInput,
    };

    var headers = client.headers;
    console.log("payload:", reqLoginPayload);

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {
        ...client.headers,
        Authorization: `Bearer ${aIVal}`
      }
      var res = await axios.post(
        client.domain + "/getDistrict",
        reqLoginPayload,
        { headers },
        { withCredentials: false }
      );
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getDistrictList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log(
          "Response message from server getDistrictList : ",
          res.data.detail.res_data
        );
        console.log(
          "Response message from server getDistrictList user_info : ",
          res.data.detail.res_data.user_info
        );
        console.log(
          "Response message from server getDistrictList dist list : ",
          res.data.detail.res_data.district_list
        );
        console.log(
          "Length of district array : ",
          res.data.detail.res_data.district_list.length
        );
        //optionsDistrict = res.data.detail.res_data.district_list

        if (res.data.detail.res_data.district_list.length > 0) {
          setOptDist(res.data.detail.res_data.district_list);
          setDistrictIdSelect({
            value: res.data.detail.res_data.district_list[0].value,
            label: res.data.detail.res_data.district_list[0].label,
          });
        }
        //setDistrictIdSelect(res.data.detail.res_data.district_list);

        //localStorage.setItem('_aI', res.data.detail.res_data.aI);
        //localStorage.setItem('userRoleId', userRoleId);
        //localStorage.setItem('username', res.data.detail.res_data.user_info.username);

        //navigate("/dashboard");
      }
      
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
  };
  
  const getDriverList = async (driverIdInput, driverNameInput) => {
    console.log("getDriverList method called ...");
    let reqLoginPayload = {
      driverId: driverIdInput,
      driverName: driverNameInput,
    };

    var headers = client.headers;
    console.log("payload:", reqLoginPayload);

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {
        ...client.headers,
        Authorization: `Bearer ${aIVal}`
      }
      var res = await axios.get(
        client.domain + "/hrms/getDrivers",
        { headers },
        { withCredentials: false }
      );
     
      console.log("Response from server getDriverList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log(
          "Response message from server getDriverList : ",
          res.data.detail.data
        );
        console.log(
          "Length of driver array : ",
          res.data.detail.data.length
        );
        //optionsDistrict = res.data.detail.res_data.district_list

        if (res.data.detail.data.length > 0) {
          var driverArr = [];
          res.data.detail.data.map((driver) => {
            console.log("Driver ind data : ", driver);
            var driverInd = {};
            driverInd.value = driver.id;
            driverInd.label = driver.name;
            driverArr.push(driverInd);
          
          })
          console.log("Driver Arr : ", driverArr);  
          setOptDriver(driverArr);
          setDriverIdSelect({
            value: res.data.detail.data[0].id,
            label: res.data.detail.data[0].name,
          });
        }
        //setDistrictIdSelect(res.data.detail.res_data.district_list);

      }
     
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
  };



  const getVehicleList = async (vehicleIdInput, vehicleNameInput) => {
    console.log("getVehicleist method called ...");
    let reqLoginPayload = {
      vehicleId: vehicleIdInput,
      vehicleName: vehicleNameInput,
    };

    var headers = client.headers;
    console.log("payload:", reqLoginPayload);

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {
        ...client.headers,
        Authorization: `Bearer ${aIVal}`
      }
      var res = await axios.get(
        client.domain + "/vms/getDeliveryVehicles",
        reqLoginPayload,
        { headers },
        { withCredentials: false }
      );
     
      console.log("Response from server getVehicleList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log(
          "Response message from server getVehicleList : ",
          res.data.detail.data
        );
      
        console.log(
          "Length of vehicle array : ",
          res.data.detail.data.length
        );
        //optionsDistrict = res.data.detail.res_data.district_list

        if (res.data.detail.data.length > 0) {
          var vehicleArr = [];
          res.data.detail.data.map((vehicle) => {
            console.log("Vehicle ind data : ", vehicle);
            var vehicleInd = {};
            vehicleInd.value = vehicle.id;
            vehicleInd.label = vehicle.name;
            vehicleArr.push(vehicleInd);
          
          })
          console.log("Vehicle Arr : ", vehicleArr);  
          setOptVehicle(vehicleArr);
          if (state.action === 'add') {
          setVehicleIdSelect({
            value: res.data.detail.data[0].id,
            label: res.data.detail.data[0].name
          });
        }
        }
       
      }
      
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
  };


  const [dcReportData, setDcReportData] = useState({});
  const [toRender, setToRender] = useState(false);
  */
 
  const getEmpList = async () => {
    var retState = false;
    var newEmpArr = [];
    console.log("getEmpList method called ...");


    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(
        client.domain + "/hrms/getEmployees",
        { headers },
        { withCredentials: false }
      );
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response from server getEmpList : ", res.data.detail);


        res.data.detail.data.map((emp) => {
          var newEmp = {
            "value": emp.id,
            "label": emp.employee_name,
            "id": emp.id,
            "employee_name": emp.employee_name,
            "employee_role_id": emp.employee_role_id,
            "employee_role": emp.employee_role
          }
          newEmpArr.push(newEmp);
        })
        setEmpList(newEmpArr);
        setEmpIdSelect(newEmpArr[0]);

        retState = true;
      }


    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }

    }
    return [retState, newEmpArr];
  }

  useEffect(() => {
    if (!initialized.current) {
      console.log("*************** useEffect called ##########################");
      if (state !== null) {
        console.log("PASSED STATE VALUE : ", state);
        console.log("PASSED ELEMENT ORDER ID : ", state.passed_element.order_id);
        console.log("PASSED ELEMENT SUPPLIER INFO : ", state.passed_element.supplier_info);

        if (state.action === 'view' || state.action === 'edit') {
          formValues.dc_date = formatDate(new Date(state.passed_element.dc_detail.dc_date));
          formValues.received_time = formateTime(new Date(state.passed_element.dc_detail.received_time));
          formValues.dc_id = state.passed_element.dc_detail.dc_id;
          formValues.dc_no = state.passed_element.dc_detail.dc_no;
          formValues.vehicle_info = state.passed_element.dc_detail.vehicle_info;
          formValues.addl_info = state.passed_element.dc_detail.addl_info;
          formValues.received_by = state.passed_element.dc_detail.received_by;

          setItems(state.passed_element.dc_detail.items);
        }
        else {
          formValues.dc_id = 0;
        }

        setSupplierInfo(state.passed_element.supplier_info);
        console.log("PASSED ELEMENT ORDER INFO : ", state.passed_element.order_info);
        console.log("PASSED ELEMENT ORDER ITEMS : ", state.passed_element.order_items);

        var orderProdArr = [];
        state.passed_element.order_items.map((item, idx) => {
          console.log("^^^^^^^^^^^^^^^^^^ Order product item : ", item);
          var orderProd = { "value": item.product_id, "label": item.product_name, product_id: item.product_id, product_name: item.product_name };
          orderProdArr.push(orderProd);
        })
        setOrderProdList(orderProdArr);
      }

      getProductBuy();

      const getEmpListWrapper = async () => {
        var [retStateEmp, empArrRet] = await getEmpList();

        if ( retStateEmp && (state.action === 'view' || state.action === 'edit') ) {
          var findObj = empArrRet.find(element => element.id === state.passed_element.dc_detail.received_by);
          if ( findObj !== undefined ) {
            console.log("Emp find object : ", findObj)
            setEmpIdSelect({value: findObj.id, label: findObj.employee_name});
          }
        }
        else {
          setEmpIdSelect(empArrRet[0]);
        }
        setToRender(true);
      }
      getEmpListWrapper();

      initialized.current = true;
    }
  }, []);
  /*
     
      var prodListFromOrder = [];
      if (state !== null) {
        state.passed_element.prod_list.map((itemProduct) => {
          console.log("Product List AddUpdateDc: ", itemProduct);
          var prodFromOrder = {
            value: itemProduct.product_id,
            label: itemProduct.product_name,
            stock_id: itemProduct.stock_id,
            item_id: itemProduct.id,
          };
          prodListFromOrder.push(prodFromOrder);
        });
        console.log(
          "Product List from order AddUpdateDC : ",
          prodListFromOrder
        );
        setOptionsProd(prodListFromOrder);

        if (state.action === "edit") {
          console.log("PASSED DC INFO : ", state.passed_element.dcInfo);

          getDcDetailsEdit(
            state.passed_element.order_id,
            state.passed_element.dc_id
          );
        }
      }
      */


  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit called ...");
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log("^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& : ", validateError);
    console.log("^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ", validateErr);

    if (items.length < 1) {
      setShowMsg("Please add atleast one product in order");
      setMsgType("warning");
      setShow(true);
      return 0;
    }

    var dcIdTmp = 0;
    if (state.action === "view" || state.action === "edit") {
      dcIdTmp = state.passed_element.dc_id;
    }

    /*
    console.log("Currently selected row product id : ", currentProductIndex);
    if (currentProductIndex < 0) {
      itemSelected.error = "Please select product from list";

      const newitems = items.slice();
      newitems[currentId] = itemSelected;
      setItems(newitems);
      return 0;
    } else {
      itemSelected.error = "";
    }
    */

    var emptyItem = false;
    items.map((item) => {
      console.log("Item value : ", item);
      if (item.product_id < 1 || !item.product_name) {
        item.error = "Please select product from list";
        //setShowMsg("Please selecyt product from dropdown");
        //setMsgType("warning");
        //setShow(true);
        emptyItem = true;
        return 0;
      } else {
        item.error = "";
      }
    });


    console.log("<<<<<<<<<<<<<<<< API calling continue >>>>>>>>>>>>>>>>>>");
    if (emptyItem) {
      console.log("unselcted product item so exit from submission ...");
      return 0;
    }

    if (validateErr === false) {
      console.log("FORM VALUES at handleSubmit: ", formValues);
      console.log("LINE ITEMS : ", items);
      console.log("Form submit called ...");

      console.log("DC Date : ", formValues["dc_date"]);
      console.log("ORDER ID : ", state.passed_element.order_id);
      console.log("DC NUmber : ", formValues["dc_no"]);
      /*
      console.log("CUSTOMER ID : ", passedValue.passed_element.customer_id);
      console.log("Vehicle ID select value: ", vehicleIdSelect.value);
      console.log("Vehicle ID select name: ", vehicleIdSelect.label);
      //console.log("Vehicle ID select value : ", formValues["vehicleIdSelect"].value);
      console.log("Driver ID select value: ", driverIdSelect.value);
      console.log("Driver ID select name: ", driverIdSelect.label);

      console.log("DISTRICT ID select value: ", districtIdSelect.value);
      console.log("STATE ID select name: ", stateIdSelect.label);
      //console.log("GST Number : ", formValues["gst_number"]);
      //console.log("Phone Number : ", formValues["phone"]);

      console.log("Tot number of items : ", total_items);
      console.log("Sum of Tot Product Cost : ", sum_product_cost);
      console.log("Sum of Tot GST : ", sum_product_gst);
      console.log("Sum of total cost : ", sum_product_total);
      */

      //console.log("SEARCHED COMPANY : ", searchedCompany);

      /*
      var addrIdTmp = 0;
      var dcIdTmp = 0;
      var dcNoTmp = "";
      if (state.action === "view" || state.action === "edit") {
        //addrIdTmp = formValues["address_id"];
        //dcIdTmp = formValues["dc_id"];
        //dcNoTmp = formValues["dc_no"];
      }

      if (items.length < 1) {
        setShowMsg("Please add atleast one product in order");
        setMsgType("warning");
        setShow(true);
        return 0;
      }

      var emptyItem = false;
      items.map((item) => {
        console.log("Item value : ", item);
        console.log("Product ID : ", item.product_id);
        if (parseInt(item.product_id) < 1 || !item.product_name) {
          item.error = "Please select product from list";
          //setShowMsg("Please selecyt product from dropdown");
          //setMsgType("warning");
          //setShow(true);
          emptyItem = true;
          return 0;
        } else {
          item.error = "";
        }
      });

      console.log("<<<<<<<<<<<<<<<< API calling continue >>>>>>>>>>>>>>>>>>");
      if (emptyItem) {
        console.log("unselcted product item so exit from submission ...");
        return 0;
      }
      */
      /*
            let reqAddUpdateDC = {
             
              dc_id: dcIdTmp,
              dc_no: dcNoTmp,
              address_id: addrIdTmp,
              orderId: passedValue.passed_element.order_id,
              customerId: passedValue.passed_element.customer_id,
              dc_date: formValues["dc_date"],
              vehicleId: vehicleIdSelect.value,
              driverId: driverIdSelect.value,
              contactPerson: formValues["contact_person"],
              contactNumber: formValues["contact_number"],
              address1: formValues["address1"],
              address2: formValues["address2"],
              districtId: districtIdSelect.value,
              stateId: stateIdSelect.value,
              pincode: formValues["pincode"],
              //loadingStartTime: formValues["loading_start_time"],
              //loadingEndTime:  formValues["loadingEndTime"],
      
              total_items: total_items,
              sum_product_cost: sum_product_cost,
              sum_product_gst: sum_product_gst,
      
              sum_product_cgst: sum_product_cgst,
              sum_product_sgst: sum_product_sgst,
              sum_product_igst: sum_product_igst,
      
              amount_discount: amount_discount,
      
              sum_product_total: sum_product_total,
      
              pumping_charges: formValues["pumping_charges"],
              diesel_charges: formValues["diesel_charges"],
      
              loading_start_time:
                formValues["dc_date"] + " " + formValues["loading_start_time"],
              loading_end_time:
                formValues["dc_date"] + " " + formValues["loading_end_time"],
              //dc_id: formValues["dc_id"],
              //address_id: formValues["address_id"],
      
              orderItems: JSON.stringify(items),
            };
            */

      //var headers = client.headers;

      // console.log("payload addUpdateDC :", reqAddUpdateDC);

      let reqAddUpdateDC = {
        order_id: state.passed_element.order_id,
        dc_id: formValues.dc_id,
        dc_date: formValues.dc_date,
        dc_no: formValues.dc_no,
        received_time: formValues.received_time,
        vehicle_info: formValues.vehicle_info,
        addl_info: formValues.addl_info,
        received_by: empIdSelect.value,
        total_items: items.length,
        items: JSON.stringify(items)
      }
      console.log("DC INSERT req : ", reqAddUpdateDC);


      try {
        var aIVal = localStorage.getItem("_aI");
        var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
        var res = await axios.post(
          client.domain + "/insertPurchaseDC",
          reqAddUpdateDC,
          { headers },
          { withCredentials: false }
        );
        console.log("Response from server : ", res.data.detail);

        if (res.data.detail.status === "SUCCESS") {
          console.log(
            "Response message from server : ",
            res.data.detail.res_data
          );
          navigate(-1);
        }
      } catch (error) {
        console.log("FULL ERROR : ", error);
      }
    }

  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);

    if (!values.vehicle_info) {
      errors.vehicle_info = "Vehicle Info is required";
      validateErr = true;
    }

    console.log("BEFORE RETURN validate errors ...", validateError);
    return errors;
  };

  const saveHandler = async (event) => {
    // Prevent page reload
    event.preventDefault();

    console.log("Form submit clicked ...");

    console.log("DATA : ", document.forms[0]);

    /*
        var { username, password } = document.forms[0];
        console.log("username : ", username.value);
        console.log("pasword : ", password.value);
        */
  };

  const backHandler = async (event) => {
    event.preventDefault();
    console.log("Back button clicked ...");
  };
  const handleClear = async (e) => {
    e.preventDefault();
    console.log("handle clear called : ", e);
    console.log("Handle clear clicked current target ID: ", e.currentTarget.id);
    navigate(0);
  };

  const newItemHandle = async (event) => {
    event.preventDefault();
    console.log("New item addition button clicked ...");

    const newitems = items.slice();
    let itemNew = {
      //id: 0,
      //product: { value: 0, label: "" },
      product_id: 0,
      product_name: "",
      value: 0,
      label: "",
      dc_item_id: 0,
      quantity: 1,
    };
    newitems.push(itemNew);
    setItems(newitems);

    console.log("FULL ITEM VALUE at newItemHandle : ", items);
  };


  const handleEmpSelect = (e) => {
    console.log("Handle Employee Select Called : ", e);
    //var currentId = e.name.substring(e.name.lastIndexOf("-") + 1);
    //console.log("Current id : ", currentId);
    //setFormValues({ ...formValues, ["employee"]: e });
    setEmpIdSelect({ value: e.value, label: e.label });
  }

  const handleSelect = (e, data) => {
    console.log("SELECTED Dropdown value : ", e);
    console.log("SELECTED element id : ", data);
    var currentId = data.name.substring(data.name.lastIndexOf("-") + 1);
    console.log("Current id : ", currentId);

    var itemSelected = items[currentId];
    console.log("---------------- SELECTED ITEMS ++++++++++++++++ : ", items[currentId]);
    //console.log("Product: ", itemSelected.product);

    console.log("Quantity : ", itemSelected.quantity);

    console.log("Product Info : ", orderProdList);
    const currentProductIndex = orderProdList.findIndex((productInd) => productInd.product_name === e.label);
    console.log("Currently selected row product id : ", currentProductIndex);
    console.log("Currently selected row product value : ", orderProdList[currentProductIndex]);

    itemSelected.product_id = e.product_id;
    itemSelected.product_name = e.product_name;


    //var gst = { cgst: cgstTmp, sgst: sgstTmp, igst: igstTmp, totGst: totalGst}
    //itemSelected.gst = gst

    //itemSelected.total_item_price = productList[currentProductIndex].unit_price * itemSelected.quantity;


    const newitems = items.slice();
    newitems[currentId] = itemSelected;
    setItems(newitems);
    //var fieldValue = "product";
    //setFormValues({ ...formValues, [fieldValue]: e });
  };

  /*
      const searchActionFunc = (json) => {
        setOptionsSearch(json.detail.res_data.district_list);
        setIsLoading(false)
        console.log("SEARCHED VALUE inside searchActionFunc : ", json.detail.res_data.district_list);
      }
      */

  /*
const companySearchSelectFunc = async (selected) => {
  console.log(
    "**************** User selected value from search result +++++++++++++ : ",
    selected
  );
  if (selected.length > 0) {
    setSearchedCompany(selected[0]);

    const response = await fetch(
      `${client.domain}/getCompanyInfo?company_id=${selected[0].value}`
    );
    const company_info = await response.json();
    console.log("COMPANY INFO : ", company_info.detail.res_data.company_info);

    //setFormValues({...formValues, [name]: value});
    //let entries = Object.entries(company_info.detail.res_data.company_info)
    //entries.map( ([key, val] = entry) => {
    //entries.map( ([key, val] = entry) => {
    for (const [key, value] of Object.entries(
      company_info.detail.res_data.company_info
    )) {
      //console.log("KEY : ", key);
      //console.log("VAL : ", val);
      console.log("KEY : %s, VAL: %s", key, value);

      //setFormValues({...formValues, [key]: value});
    }

    setFormValues(company_info.detail.res_data.company_info);

    console.log(
      "STATE ID : ",
      company_info.detail.res_data.company_info["state_id"]
    );
    setStateIdSelect({
      value: company_info.detail.res_data.company_info["state_id"],
      label: company_info.detail.res_data.company_info["state_name"],
    });
    console.log(
      "DISTRICT ID : ",
      company_info.detail.res_data.company_info["dist_id"]
    );
    setDriverIdSelect({
      value: company_info.detail.res_data.company_info["dist_id"],
      label: company_info.detail.res_data.company_info["district_name"],
    });

    setDriverIdSelect({
      value: company_info.detail.res_data.company_info["driver_id"],
      label: company_info.detail.res_data.company_info["driver_name"],
    });
    setVehicleIdSelect({
      value: company_info.detail.res_data.company_info["vehicle_id"],
      label: company_info.detail.res_data.company_info["vehicle_name"],
    });
    console.log("FORMVALUES 2 : ", formValues);
  }
};
*/

  /*
  const searchHandleFunc = async (query) => {
    setIsLoading(true);
    fetch(`${client.domain}/search/customers?q=${query}`)
      .then((resp) => resp.json())
      .then((json) => {
        //searchActionFunc(json)
        setOptionsSearch(json.detail.res_data.company_list);
        setIsLoading(false);
        console.log(
          "SEARCHED VALUE inside searchActionFunc : ",
          json.detail.res_data.company_list
        );
      });
    setCompanySearchText(query);
  };
  */

  console.log("FORM VALUES Before rendering :", formValues);

  var total_items = 0;
  //var sum_product_cost = 0.0;
  //var sum_product_gst = 0.0;
  //var sum_product_cgst = 0.0;
  //var sum_product_sgst = 0.0;
  //var sum_product_igst = 0.0;

  //var sum_product_total = 0.0;
  //var amount_discount = 0.0;

  /*
  items.map((item) => {
    console.log("IND ITEM : ", item);
    total_items = total_items + 1;
  });
  */

  /*
  items.map((item) => {
    console.log("IND ITEM : ", item);
    console.log("Product Cost : ", item.total_item_price);
    console.log("GST  : ", item.cgst + item.sgst + item.igst);

    console.log("GST  : ", item.cgst);
    console.log("GST  : ", item.sgst);
    console.log("GST  : ", item.igst);

    console.log("Product Cost : ", item.total_price);
    total_items = total_items + 1;
    sum_product_cost = sum_product_cost + item.total_item_price;

    sum_product_cgst = sum_product_cgst + item.cgst;
    sum_product_sgst = sum_product_sgst + item.sgst;
    sum_product_igst = sum_product_igst + item.igst;

    //sum_product_total = sum_product_total + item.total_price;
    //sum_product_total =  sum_product_total +
  });

  sum_product_gst = sum_product_cgst + sum_product_sgst + sum_product_igst;
  sum_product_total = sum_product_cost + sum_product_gst;

  sum_product_total =
    sum_product_total -
    amount_discount +
    parseFloat(formValues.pumping_charges) +
    parseFloat(formValues.diesel_charges);

  //console.log("Tot number of items : ", total_items);
  //console.log("Sum of Tot Product Cost : ", sum_product_cost);

  //console.log("Sum of Tot CGST : ", sum_product_cgst);
  //console.log("Sum of Tot SGST : ", sum_product_sgst);
  //console.log("Sum of Tot iGST : ", sum_product_igst);

  //console.log("Sum of Tot GST : ", sum_product_gst);

  //console.log("Sum of total cost : ", sum_product_total);

  //console.log("Currently selected district : ", districtIdSelect);
  //console.log("opt district : ", optDist);
  //console.log("*********** opt district value ############# : ", optDist.value);

  //console.log("Currently selected state : ", stateIdSelect);
  //console.log("+++++++++++++++ Company search text ^^^^^^^^^^^^^^^^^^^^  :", companySearchText);
  //console.log("Searched company <<<<<<<<<<<<<<<<<<< : ", searchedCompany);
  //console.log("PRODUCT LIST FROM ORDER ITEMS : ", optionsProd);

  const getDcDetailsEdit = async (orderId, dcId) => {
    let reqDCPrintReq = {
      order_id: orderId,
      dc_id: dcId,
    };

    var headers = client.headers;
    console.log("payload DC Print :", reqDCPrintReq);

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {
        ...client.headers,
        Authorization: `Bearer ${aIVal}`
      }
      var res = await axios.post(
        client.domain + "/getDCDetails",
        reqDCPrintReq,
        { headers },
        { withCredentials: false }
      );
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log(
          "Response message from server DCDetails : ",
          res.data.detail.res_data
        );
        //navigate("/dashboard");
        setDcReportData(res.data.detail.res_data);

        formValues["dc_id"] = res.data.detail.res_data.dc_info.dc_id;
        formValues["dc_no"] = res.data.detail.res_data.dc_info.dc_no;
        formValues["dc_date"] = res.data.detail.res_data.dc_info.dc_date;

        setVehicleIdSelect({
          value: res.data.detail.res_data.dc_info.vehicle_id,
          label: res.data.detail.res_data.dc_info.vehicle_reg_number,
        });
        setDriverIdSelect({
          value: res.data.detail.res_data.dc_info.driver_id,
          label: res.data.detail.res_data.dc_info.driver_name,
        });
        formValues["contact_person"] =
          res.data.detail.res_data.dc_info.contact_person;
        formValues["contact_number"] =
          res.data.detail.res_data.dc_info.contact_number;

        formValues["address1"] = res.data.detail.res_data.dc_info.address_line1;
        formValues["address2"] = res.data.detail.res_data.dc_info.address_line2;

        setDistrictIdSelect({
          value: res.data.detail.res_data.dc_info.district_id,
          label: res.data.detail.res_data.dc_info.district_name,
        });
        setDriverIdSelect({
          value: res.data.detail.res_data.dc_info.driver_id,
          label: res.data.detail.res_data.dc_info.driver_name,
        });
        setStateIdSelect({
          value: res.data.detail.res_data.dc_info.state_id,
          label: res.data.detail.res_data.dc_info.state_name,
        });
        setVehicleIdSelect({
          value: res.data.detail.res_data.dc_info.vehicle_id,
          label: res.data.detail.res_data.dc_info.vehicle_reg_number,
        });

        formValues["pincode"] = res.data.detail.res_data.dc_info.pincode;
        //formValues["loading_start_time"] = res.data.detail.res_data.dc_info.loading_start_time;
        var loadStartDateTime = new Date(
          res.data.detail.res_data.dc_info.loading_start_time
        );
        var onlyHrMin = formateTime(loadStartDateTime);
        console.log("Load Start time in hh:mm : ", onlyHrMin);
        formValues["loading_start_time"] = onlyHrMin;

        //formValues["loading_end_time"] = res.data.detail.res_data.dc_info.loading_end_time;
        var loadEndDateTime = new Date(
          res.data.detail.res_data.dc_info.loading_end_time
        );
        var onlyHrMin = formateTime(loadEndDateTime);
        console.log("Load End time in hh:mm : ", onlyHrMin);
        formValues["loading_end_time"] = onlyHrMin;
        formValues["pumping_charges"] =
          res.data.detail.res_data.dc_info.pumping_charges;
        formValues["diesel_charges"] =
          res.data.detail.res_data.dc_info.diesel_charges;

        formValues["address_id"] = res.data.detail.res_data.dc_info.address_id;

        setItems(res.data.detail.res_data.dc_item_list);

        setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        //setShowMsg('Please try after sometime');
        //setShowMsgTitle('Network Issue!');
        //setShow(true);
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg('Please try again ...');
          //setShowMsgTitle('Unable to process your request!');
          //setShow(true);
          //navigate("/auth");
        }
      }
    }
  };
  */

  const handleItemDelete = (e) => {
    console.log("handle item delete called ...", e);
    console.log("handle item delete e.currentTarget : ", e.currentTarget);
    console.log("handle item delete e.currentTarget ID : ", e.currentTarget.id);
    console.log("handle item delete e.target : ", e.target.id);

    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(
        0,
        clickedTagId.lastIndexOf("-")
      );
      console.log("current selected DELETE element name : ", nameOfElement);
      console.log("Current SELECTED DELETE id : ", curSelId);
      console.log("FULL ITEM VALUE : ", items);
      console.log("CUR SELECTED DELETE VALUE : ", items[curSelId]);

      //const indexPos = items.indexOf(curSelId);

      const spliced = items.splice(curSelId, 1);
      console.log("Removed item from order items: ", spliced);

      var newItems = items.slice();
      setItems(newItems);
    }
  };

  const handleBack = async (e) => {
    console.log("handle back called : ", e);
    navigate(-1);
  };

  const handleClose = async (e) => {
    setShow(false);
    //navigate(-1);
  };

  return (
    <>
      <Container>
        <SidebarMain />
        { toRender ?
        (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={4} md={4} sm={4} lg={4}>
              <h4> Inward Delivery </h4>
            </Col>
            <Col
              xs={8}
              md={8}
              sm={8}
              lg={8}
              className="d-flex justify-content-end float-end"
            >
              <Button variant="warning" className="btn btn-warning btn-md">
                <BsArrowLeftCircle
                  key={`back-btn-dc-key`}
                  size={24}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleBack(e)}
                  name={`back-icon-dc-name`}
                  id={`back-icon-dc-id`}
                />
              </Button>
            </Col>
          </Row>

          <p></p>

          <hr align="center" />

          <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
              <Form.Group className="mb-3">
                <Form.Label>Supplier Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  id="supplier-field-name"
                  placeholder="Supplier Name"
                  value={supplierInfo.name}
                  readOnly={true}
                />
              </Form.Group>
              {/*<h5>Supplier Name: <Badge bg="secondary">{supplierInfo.name}</Badge></h5>*/}
            </Col>

            <Col xs={3} md={3} sm={3} lg={3}>
              <Form.Group className="mb-3">
                <Form.Label>GST Number</Form.Label>
                <Form.Control
                  type="text"
                  name="gst_no"
                  id="supplier-gst-field-name"
                  placeholder="GST Number"
                  value={supplierInfo.gst_no}
                  readOnly={true}
                />
              </Form.Group>
              {/*<h5>GST Number: <Badge bg="secondary">{supplierInfo.gst_no}</Badge></h5>*/}
            </Col>
            <Col xs={3} md={3} sm={3} lg={3}>
              <Form.Group className="mb-3">
                <Form.Label>Contact Number</Form.Label>
                <Form.Control
                  type="text"
                  name="contact_person_number"
                  id="supplier-contact-field-name"
                  placeholder="Contact Number"
                  value={supplierInfo.contact_person_number}
                  readOnly={true}
                />
              </Form.Group>
              {/*<h5>Contact Number: <Badge bg="secondary">{supplierInfo.contact_person_number}</Badge></h5>*/}
            </Col>
            <Col xs={3} md={3} sm={3} lg={3}>
              <Form.Group className="mb-3">
                <Form.Label>City Or Town</Form.Label>
                <Form.Control
                  type="text"
                  name="address_line2"
                  id="supplier-address2-field-name"
                  placeholder="City Or Town"
                  value={supplierInfo.address_line2}
                  readOnly={true}
                />
              </Form.Group>
              {/*<h5>City or Town: <Badge bg="secondary">{supplierInfo.address_line2}</Badge></h5>*/}
            </Col>
          </Row>

          <div className="ui-divider"></div>
          <div className="ui-form">

            <hr align="center" />

            <Row>
              {/*
       <Col xs={2} md={2} sm={2}>
        <div className="field">
          <label>DC Quantity :</label>
        </div>
        </Col>
        
       <Col xs={2} md={2} sm={2}>
        <div className="field">
          
          <input 
          type="text" 
          name="dc_number" 
          placeholder="DC Number" 
          value={formValues.dc_number}
          onChange={handleValueChange} />
        </div>
        <p className="error-msg">{ formErrors.dc_number}</p>
        </Col>
        */}
              {/*
        <Col xs={2} md={2} sm={2}>
        <div className="field">
        <AsyncTypeahead
        id="company-search-fd-id"
        name="company-search-fd-name"
  isLoading={isLoading}
  onSearch={query => {
    searchHandleFunc(query)
  }}
  onChange={selected => {companySearchSelectFunc(selected)}}
  options={optionsSearch}
  placeholder="Choose a company ..."
/>
</div>
</Col>
*/}
              {/*
        <AsyncTypeahead
          
          id="company-search-field"
          clearButton
          isLoading={isLoading}
          onSearch={query => {
    setIsLoading(true)
    fetch(`${client.domain}/search/customers?q=${query}`)
      .then(resp => resp.json())
      .then(json => {
        setOptionsSearch(json.detail.res_data.district_list);
        console.log("SEARCHED VALUE : ", json.detail.res_data.district_list);
        setIsLoading(false)
      });
  }}
  onChange={selected => console.log("SELECTED VALUE : ", selected)}
  options={optionsSearch}
  placeholder="Choose a company ..."
        />
        */}
              <Col xs={3} md={3} sm={3} lg={2}>
                <Form.Group className="mb-3" controlId="dcform.dc_date">
                  <Form.Label>DC Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="dc_date"
                    placeholder="DC Date"
                    value={formValues.dc_date}
                    onChange={onChangeDatePicker}
                  />
                </Form.Group>
              </Col>
              <Col xs={3} md={3} sm={3} lg={2}>
                <Form.Group
                  className="mb-3"
                  controlId="dcform.received_time"
                >
                  <Form.Label>Received time </Form.Label>


                  <Form.Control
                    type="time"
                    name="received_time"
                    value={formValues.received_time}
                    onChange={onChangeReceivedTime}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} md={3} sm={4} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>DC Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="dc_no"
                    id="dc_no"
                    placeholder="DC Number"
                    value={formValues.dc_no}
                    onChange={handleValueChange}
                  />
                </Form.Group>

                <p className="error-msg">{formErrors.dc_no}</p>
              </Col>
              <Col xs={6} md={3} sm={4} lg={2}>
                <Form.Group className="mb-3" controlId="dcform.vehicle_info">
                  <Form.Label>Vehicle Info</Form.Label>
                  <Form.Control
                    type="text"
                    name="vehicle_info"
                    placeholder="Vehicle Info"
                    value={formValues.vehicle_info}
                    onChange={handleValueChange}
                  />
                </Form.Group>

                <p className="error-msg">{formErrors.vehicle_info}</p>
              </Col>


              <Col xs={6} md={3} sm={4} lg={2}>
                <Form.Group className="mb-3" controlId="dcform.addl_info">
                  <Form.Label>Addl.Info</Form.Label>
                  <Form.Control
                    type="text"
                    name="addl_info"
                    placeholder="Addl.Info"
                    value={formValues.addl_info}
                    onChange={handleValueChange}
                  />
                </Form.Group>

                <p className="error-msg">{formErrors.addl_info}</p>
              </Col>
              <Col xs={2} sm={2} md={2} lg={2}>

                <Form.Group className="mb-3">
                  <Form.Label className="field required">Received By</Form.Label>
                  <Select

                    id="employee"
                    name="employee"
                    value={{
                      value: empIdSelect.value,
                      label: empIdSelect.label,
                    }}
                    options={empList}
                    defaultValue={{
                      value: empIdSelect.value,
                      label: empIdSelect.label,
                    }}
                    onChange={handleEmpSelect}

                  />
                </Form.Group>
                <p className="error-msg">{formErrors.received_by}</p>

              </Col>

            </Row>



            <hr align="center" />

            <Row>
              <Col xs={6} md={6} sm={6} lg={6}>
                <div className="d-flex justify-content-start">
                  <h5>
                    <Badge pill bg="secondary">
                      Item Details
                    </Badge>
                  </h5>
                </div>
              </Col>

              <Col xs={6} md={6} sm={6}>
                <div className="d-flex justify-content-end">
                  <Button
                    className="btn btn-warning"
                    style={{ alignItems: "self-end" }}
                    onClick={newItemHandle}
                  >
                    Add Item
                  </Button>
                </div>
              </Col>
            </Row>

            <br></br>

            <Table striped bordered hover size="sm">
              <thead>
                <tr className="table-primary">
                  <th className="table-row-heading">S.No</th>
                  <th className="table-row-heading">Product</th>
                  <th className="table-row-heading">Qty</th>
                  {state.action === "add" &&
                  <th className="table-row-heading">...</th>
                  }
                </tr>
              </thead>

              <tbody>
                {items.map((item, idx) => (
                  <tr>
                    <td className="col-md-2" style={{ width: "5%" }}>{idx + 1}</td>
                    <td className="col-2" style={{ width: "15%" }}>

                      <Select
                        id={`product-item-${idx}`}
                        name={`product-item-${idx}`}
                        value={{
                          value: item.product_id,
                          label: item.product_name,
                        }}
                        options={orderProdList}
                        defaultValue={{
                          value: item.product_id,
                          label: item.product_name,
                        }}
                        onChange={handleSelect}
                      />
                      <p className="error-msg">{item.error}</p>
                    </td>
                    <td className="col-2" style={{ width: "10%" }}>
                      <Form.Group as={Row} className="mb-3">
                        <Col sm={10} md={10}>
                          <Form.Control
                            type="text"
                            name={`quantity-${idx}`}
                            placeholder="Quantity"
                            value={item.quantity}
                            id={`quantity-${idx}`}
                            // max="100000"
                            onChange={handleChange}
                            maxLength={5}
                          />
                        </Col>
                      </Form.Group>
                    </td>


                    {state.action === "add" && (
                    <td className="col-2" style={{ width: "5%" }}>
                      <BsFillXCircleFill
                        size={28}
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={(e) => handleItemDelete(e)}
                        name={`item-delete-icon-${idx}`}
                        id={`item-delete-icon-${idx}`}
                      />
                    </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>



            <Row style={{ height: props.height }}>
              <br></br>
              <p></p>
            </Row>

            <Row>
              <Col xs={3} md={3} sm={3}>
                <div style={{ fontWeight: "bold" }}>
                  Total Items : {items.length}
                </div>
              </Col>

            </Row>

            <Row>
              <Col xs={12} md={12} sm={12}>
                <div className="d-flex justify-content-end">
                  <Button
                    className="btn btn-secondary"
                    id="btn-clear-order-id"
                    name="btn-clear-order-name"
                    onClick={handleClear}
                  >
                    Clear
                  </Button>
                  &nbsp;&nbsp;
                  <button className="btn btn-primary" onClick={handleSubmit}>Save DC</button>
                </div>
              </Col>
            </Row>
          </div>
        </Form>
        )
        :
        <div className="container-spinner">
        <div className="vertical-center-spinner">
         {/*<Button variant="primary" disabled>*/}
         <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
            <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{ width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
                <span className="visually-hidden">Loading...</span>
              </Spinner>
   
         {/*</Button>*/}
         </div>
         </div>
      }
      </Container>
      
    </>
  );
};

export default AddUpdatePurchaseDC;
