import React, { Fragment } from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

import { formatDateIndian } from '../../pages/utils.js';

const styles = StyleSheet.create({
    invoiceNoContainer: {
        flexDirection: 'row',
        //marginTop: 12,
        justifyContent: 'flex-end'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDate: {
            fontSize: 12,
            fontStyle: 'bold',
    },
    label: {
        width: 60,
        fontFamily: 'Helvetica-Bold'
    }
    
  });


  //const InvoiceNo = ({invoice}) => (
const InvoiceNo = (props) => {
    console.log("Props Data at InvoiceNo: ", props);
    return (
        <Fragment>
            <View style={styles.invoiceNoContainer}>
                <Text style={styles.label}>DC No:</Text>
                <Text style={styles.invoiceDate}>{props.dcLoad.dc_info.dc_no}</Text>
            </View >
            <View style={styles.invoiceDateContainer}>
                <Text style={styles.label}>DC Date: </Text>
                <Text >{formatDateIndian(new Date(props.dcLoad.dc_info.dc_date))}</Text>
            </View >
        </Fragment>
  );
  }
  export default InvoiceNo
