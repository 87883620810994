import React, { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';

import { client } from '../config/Config';

import StockDocument from "./StockDocument";
import {getCompanyInfo, formatDateIndian } from "./utils";

import logo_left from '../logo_left.png';
import logo_right from '../logo_right.png';

const borderColor = '#55585a';
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 9,
    paddingTop: 30,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    //width: '100%',
    //height: '33%',
    width: '100%',
    height: '100%',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  logo_image2: {
    width: '15%',
    height: '98px',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  business_content: {
    width: '60%',
    height: '98px',
    //paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container2: {
    //paddingBottom:0,
    flexDirection: 'row',
    //borderBottomColor: '#bff0fd',
    //backgroundColor: '#bff0fd',
    //borderBottomWidth: 1,
    //alignItems: 'center',
    //height: 12,
    //textAlign: 'center',
    //fontStyle: 'bold',
    //flexGrow: 1,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 1,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#a8a8a8',

  },
  container: {
    flexDirection: 'row',
    borderBottomColor: '#a8a8a8',
    backgroundColor: '#a8a8a8',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    //flexGrow: 1,
    //flexWrap: 'wrap',
    //marginTop: 1,
    //marginBottom: 10,
    //borderWidth: 1,
    //borderWidth: 1,
    //marginBottom: 5,
  },
  sno: {
    width: '5%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left'
   
  },
  inv_no: {
    width: '6%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    //paddingRight:2
  },
  inv_date: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    //paddingRight:2
  },
  
  
  customer_name: {
    width: '19%',
    fontSize: "10px",
    borderRightColor: borderColor,
    textAlign: 'left',
    //borderRightWidth: 1,
  },
  gst: {
    width: '12%',
    fontSize: "10px",
    borderRightColor: borderColor,
    textAlign: 'right',
    //borderRightWidth: 1,
  },
  sub_total: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    textAlign: 'right',
    //borderRightWidth: 1,
    
  },
  cgst: {
    width: '13%',
    fontSize: "10px",
    borderRightColor: borderColor,
    textAlign: 'right',
    //borderRightWidth: 1,
  },
  sgst: {
    width: '10%',
    borderRightColor: borderColor,
    fontSize: "10px",
    textAlign: 'right',
    //borderRightWidth: 1,
  },
  
  total_amount: {
    width: '15%',
    borderRightColor: borderColor,
    fontSize: "10px",
    textAlign: 'right',
    //borderRightWidth: 1,
  },
  row: {
    flexDirection: 'row',
    //borderBottomColor: '#a8a8a8',
    //borderBottmWidth: 1,
    alignItems: 'center',
    //height: 24,
    fontStyle: 'bold',
  },
  snoData: {
    width: '5%',   
    textAlign: 'right',
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    fontSize: "10px",
    paddingRight: 40,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  inv_noData: {
    width: '6%',
    fontSize: "10px",
    //textAlign: 'left',
    textAlign: 'right',
    //alignContent: 'justify',
    //textAlign: 'justify',
    //textJustify: 'inter-word',
    //display:'flex',
    //flexWrap: 'wrap',
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    paddingRight: 60,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },

  inv_dateData: {
    width: '10%',
    fontSize: "10px",
    //textAlign: 'left',
    textAlign: 'left',
    //alignContent: 'justify',
    //textAlign: 'justify',
    //textJustify: 'inter-word',
    //display:'flex',
    //flexWrap: 'wrap',
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    //paddingLeft: 4,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  
 
  customer_nameData: {
    width: '19%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    paddingLeft: 1,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  gstData: {
    width: '12%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    //paddingLeft: 4,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  sub_totalData: {
    width: '10%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 1,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  cgstData: {
    width: '13%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 1,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  sgstData: {
    width: '10%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 1,
     //paddingRight: 8,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
   
  total_amountData: {
    width: '15%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 1,
    //paddingRight: 8,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  


});

const PrintCustInv = (props) => {

 let invArr = [
  {
    inv_no: "101",
    inv_date: "12-03-2023",
    customer_name: "vimal",
    gst:"1234",
    sub_total:"5000",
    cgst:"567",
    sgst:"234",
    total_amount: "4000",
    
  },
  {
    inv_no: "102",
    inv_date: "04-12-2023",
    customer_name: "haritha",
    gst:"8907",
    sub_total:"8000",
    cgst:"789",
    sgst:"2567",
    total_amount: "6000",
   
  },
  {
    inv_no: "103",
    inv_date: "12-12-2023",
    customer_name: "harshi",
    gst:"7854",
    sub_total:"9000",
    cgst:"3463",
    sgst:"6747",
    total_amount: "8000",
    
  },
  {
    inv_no: "104",
    inv_date: "09-11-2023",
    customer_name: "jas",
    gst:"78567",
    sub_total:"6000",
    cgst:"675",
    sgst:"855",
    total_amount: "13000",
   
  },
 ]

 
  const [reportData, setReportData] = useState({});
  const [toRender, setToRender] = useState(false);
  const [invoiceList, setInvoiceList] = useState([]);
 
  const [companyInfo, setCompanyInfo] = useState({});
  var customer_id_ref = useRef(0);
  var start_date_ref = useRef(formatDate(new Date()));
  var end_date_ref = useRef(formatDate(new Date()));

  var amount_basic_ref = useRef(0.00);
  var cgst_ref = useRef(0.00);
  var sgst_ref = useRef(0.00);
  var amount_total_ref = useRef(0.00);
  
  console.log("CALLED CURRENT PAGE : ", window.location.href);

    console.log("CALLED window locaion : ", window.location);
    let params = (new URL(window.location)).searchParams;
    console.log("COMPLETE PARAMS : ", params);
    let orderIdParam = params.get("order_id");
    console.log("ORDER ID FROM PARAM : ", orderIdParam);

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.indexOf('?') + 1);
    var urlTmp = urlStr.substring(0, urlStr.indexOf("?"));

    console.log("URL : ", urlTmp);
    console.log("URL : ", paramsTmp);

    let searchParams = new URLSearchParams(paramsTmp);
    var customerIdTemp2 = searchParams.get("customer_id");
    console.log("CUSTOMED ID : ", customerIdTemp2);
    var productIdTemp2 = searchParams.get("product_id");
    console.log("PRODUCT ID : ", productIdTemp2);
    var startDateStr = searchParams.get("start_date");
    console.log("START DATE : ", startDateStr);
    var endDateStr = searchParams.get("end_date");
    console.log("END DATE : ", endDateStr);

    console.log("Extracted CUSTOMER ID  : ", customerIdTemp2);
    console.log("Extracted PRODUCT ID : ", productIdTemp2);
   
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  const formateTime = (currTime) => {
    var h = (currTime.getHours() < 10 ? "0" : "") + currTime.getHours();
    var m = (currTime.getMinutes() < 10 ? "0" : "") + currTime.getMinutes();
    var hrMin = h + ":" + m;
    return hrMin;
  };

  const formatPrintDate = (currTime) => {
    var dateComponent = [
      padTo2Digits(currTime.getDate()),
      padTo2Digits(currTime.getMonth() + 1),
      currTime.getFullYear(),
    ].join("-");

    var timeComponent = [
      padTo2Digits(currTime.getHours() % 12 || 12 ),
      padTo2Digits(currTime.getMinutes())
    ].join(":");

    const ampm = currTime.getHours() >= 12 ? 'PM' : 'AM';

    return dateComponent + " " + timeComponent + " " + ampm;
  }
  /*
  const getAllSummaryList = async () => {
    var retState = false;
    var deliverySummaryList = [];
    try {
      
      var res = await axios.get(client.domain + `/getDeliverySummaryList?customer_id=${customerIdTemp2}&start_date=${startDateStr}&end_date=${endDateStr}&product_id=${productIdTemp2}`, { withCredentials: false });
      console.log("Response from server getAllSummaryList : ", res.data.detail);
      if (res.data.detail.status === 'SUCCESS') {
        console.log("Response message from server SummaryList : ", res.data.detail.data);
        retState = true;
        if (res.data.detail.data.length > 0) {
          deliverySummaryList = res.data.detail.data; 
        }
        
      }
    }
    catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === 'ERR_NETWORK') {
        console.log("Not able to reach target server please try after sometime");
      }
      else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === 'FAILED') {
          console.log('Request failed due to ', error.response.data.detail.res_data);
         
        }
      }
    }
    return [retState, deliverySummaryList];
  }
  
    */

  const getCustInvoiceList = async() => {
    var retState = false;
    var retInvoiceList = [];
    try {
      var aIVal = localStorage.getItem("_aI");
      console.log("Authorization token : ", aIVal);

      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}

      console.log("Headers : ", headers);

      console.log("++++++++++++++++++++ CUSTOMER ID SELECT ----------------: ", customer_id_ref.current);

      
      var res = await axios.get(client.domain + `/getAllOrders?customer_id=${customer_id_ref.current}&start_date=${start_date_ref.current}&end_date=${end_date_ref.current}`, {headers}, {
        withCredentials: false,
      });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getOrdersList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log(
          "Response message from server getDistrictList : ",
          res.data.detail.res_data
        );
        //setOrderList(res.data.detail.data);
        //setOrderListFull(res.data.detail.data);

        if (res.data.detail.res_data.order_list.length > 0) {
          setInvoiceList(res.data.detail.res_data.order_list);
          retInvoiceList = res.data.detail.res_data.order_list;
          retState = true;
          //setOrderListFull(res.data.detail.res_data.order_list);
        }
        setToRender(true);
      }
      
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
	setToRender(true);
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ",error.response.data.detail.res_data);
        }
      }
    }
    setToRender(true);

    return [retState, retInvoiceList];
  };
  
  useEffect(() => {
    console.log("useEffect method called ...");
    (async () => {
      //var [retState, deliverySummaryList] = await getAllSummaryList();
      /*
      if (retState) {
        setSummaryList(deliverySummaryList);
        //setDcListFetched(true);
        setToRender(true); 
      }
      */

      console.log("useEffect method called ...");
    console.log("CALLED CURRENT PAGE : ", window.location.href);

    console.log("CALLED window locaion : ", window.location);
    let params = (new URL(window.location)).searchParams;
    console.log("COMPLETE PARAMS : ", params);
    let orderIdParam = params.get("order_id");
    console.log("ORDER ID FROM PARAM : ", orderIdParam);

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.indexOf('?') + 1);
    var urlTmp = urlStr.substring(0, urlStr.indexOf("?"));

    console.log("URL : ", urlTmp);
    console.log("URL : ", paramsTmp);

    let searchParams = new URLSearchParams(paramsTmp);

    var customerIdTemp2 = searchParams.get("customer_id");
    console.log("PRODUCT ID : ", customerIdTemp2);
    customer_id_ref.current = customerIdTemp2;

    var startDateStr = searchParams.get("start_date");
    console.log("START DATE : ", startDateStr);
    start_date_ref.current = startDateStr;

    var endDateStr = searchParams.get("end_date");
    console.log("END DATE : ", endDateStr);
    end_date_ref.current = endDateStr;
      
      var [retState, companyInfoTmp] = await getCompanyInfo();
      if (retState) {
        setCompanyInfo(companyInfoTmp);
        setToRender(true); 
      }

      var [retState, retInvoiceList] = await getCustInvoiceList();

      if ( retState ) {
        amount_basic_ref.current = 0.00;
        cgst_ref.current = 0.00;
        sgst_ref.current = 0.00;
        amount_total_ref.current = 0.00;
        retInvoiceList.map((invoice) => {
          console.log("INDIVIDUAL INVOICE : ", invoice);
          amount_basic_ref.current = cgst_ref.current + invoice.amount_basic;
          cgst_ref.current = cgst_ref.current + invoice.cgst;
          sgst_ref.current = sgst_ref.current + invoice.sgst;
          amount_total_ref.current = amount_total_ref.current + invoice.amount_total;
        })
      }
    })();
    
  }, [])

  return (
    <PDFViewer style={styles.viewer}>
      {toRender === true &&
        <Document>
          <Page size="A4" orientation="landscape" style={styles.page}>
            <View>
              <View style={{ flexDirection: 'row', borderBottomWidth: 1, borderBottomColor: '#a7a5a5' }}>
                <View style={{ textAlign: 'right', width: '25%' }}>
                  <Image style={{ marginHorizontal: '10%', width: 'auto', height: 'auto', height: "64px", width: "64px" }} src={logo_left} />
                </View>
                <View style={{ width: "50%", alignItems: "center", /*paddingRight: "30px",*/ /*borderBottomWidth: 1,*/ borderBottomColor: '#a7a5a5'/*,marginTop: 1, marginBottom: 10, borderWidth: 1*/ }} key="address-key">
                  <Text style={{ fontSize: "20px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>{companyInfo.name}</Text>
                  <Text>{companyInfo.address_line1}</Text>
                  <Text>{companyInfo.address_line2}-{companyInfo.pincode}</Text>
                  <Text>{companyInfo.district_name}, {companyInfo.state_name}</Text>
                  <Text style={{ fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica'}}><Text style={{ fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>Mobile:</Text> {companyInfo.phone}</Text>
                </View>
                <View style={{ textAlign: 'right', width: '25%',paddingLeft: '140px', alignItems: 'right'}}>
                  <Image style={{ /*marginHorizontal: '10%',*/ width: 'auto', height: 'auto', height: "64px", width: "64px"}} src={logo_right} />
                </View>
              </View>
            </View>
           
            <View style={{ width: "100%", alignItems: "center", textAlign: 'center',  paddingTop: "5px",borderBottomWidth: 1, borderBottomColor: '#a7a5a5' }}>
              <Text style={{ fontSize: "12px", textAlign: 'center', alignContent: 'center', fontFamily: 'Helvetica-Bold', color: '#646363' }}>Invoice Summary  ({formatDateIndian(new Date(start_date_ref.current))} To {formatDateIndian(new Date(end_date_ref.current))})</Text>
            </View>
            <hr style={{paddingBottom: "10px"}}/>
           
            <View style={styles.tableContainer}>
              
              <View style={styles.container}>
                <Text style={styles.sno}>S.No</Text>
                <Text style={styles.inv_no}>Inv.No</Text>
                <Text style={styles.inv_date}>Date</Text>
                <Text style={styles.customer_name}>Customer</Text>
                <Text style={styles.gst}>GST IN</Text>
                <Text style={styles.sub_total}>Sub Total</Text>
                <Text style={styles.cgst}>CGST</Text>
                <Text style={styles.sgst}>SGST</Text>
                <Text style={styles.total_amount}>Total Amount</Text>
                
              </View>

              <View style={{flexGrow: 1}}>
              {invoiceList.map((invoice, idx) => (
              <View style={styles.row} key="items-${idx}">
                    <Text style={styles.snoData}>{idx + 1}</Text>
                    <Text style={styles.inv_noData}>{invoice.order_no}</Text>
                    <Text style={styles.inv_dateData}>{formatDateIndian(new Date(invoice.order_date))}</Text>
                    <Text style={styles.customer_nameData}>{invoice.customer_name}</Text>
                    <Text style={styles.gstData}>{invoice.gst_number}</Text>
                    <Text style={styles.sub_totalData}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(invoice.amount_basic).toFixed(2)))}</Text>
                    <Text style={styles.cgstData}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(invoice.cgst).toFixed(2)))}</Text>
                    <Text style={styles.sgstData}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(invoice.sgst).toFixed(2)))}</Text>                    
                    <Text style={styles.total_amountData}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(invoice.amount_total).toFixed(2)))}</Text>
                    
              </View>
               ))}
              </View>
              
              </View>
              <View style={{...styles.row, borderBottomWidth: 1, borderBottomColor: '#a7a5a5'}} key="items-${idx}">
                    <Text style={styles.snoData}></Text>
                    <Text style={styles.inv_noData}></Text>
                    <Text style={{...styles.inv_dateData,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Total:</Text>
                    <Text style={styles.customer_nameData}></Text>
                    <Text style={{...styles.gstData,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}></Text>
                    <Text style={{...styles.sub_totalData,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(amount_basic_ref.current).toFixed(2)))}</Text>
                    <Text style={{...styles.cgstData,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(cgst_ref.current).toFixed(2)))}</Text>
                    <Text style={{...styles.sgstData,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(sgst_ref.current).toFixed(2)))}</Text>                    
                    <Text style={{...styles.total_amountData,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(amount_total_ref.current).toFixed(2)))}</Text>
                    
              </View>
              <hr style={{paddingBottom: "20px"}}/>


              
            <hr style={{paddingBottom: "40px"}}/>
             

            <View style={{flexDirection: 'row'}}>
            
            <Text style={{textAlign: 'left', flex: 1}} render={({ pageNumber, totalPages }) => (
                `Printed On: ${formatPrintDate(new Date())}`
            )} fixed />
             
             <Text style={{  textAlign: 'center', alignContent: 'center' }}>..... End of Report .....</Text>
           
              <Text style={{flex: 1, textAlign: 'right', justifyContent: 'flex-end', alignSelf: 'flex-end'}} render={({ pageNumber, totalPages }) => (
                `Page No : ${pageNumber} / ${totalPages}`
              )} fixed />
              </View>
              

          </Page>
        </Document>
      }
    </PDFViewer>
  );
}

export default PrintCustInv;