import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
   
    titleContainer:{
        flexDirection: 'row',
        //marginTop: 12,
        alignItems: 'center',
        textAlign: 'center'
    },
    reportTitle:{
        color: '#454545',
        //letterSpacing: 4,
        fontSize: 16,
        textAlign: 'center',
        alignItems: 'center',
        //textTransform: 'uppercase',
        fontFamily: 'Helvetica',
        //marginLeft: '25%'
    }
  });


  const InvoiceTitle = ({title}) => (
    <View style={styles.titleContainer}>
        <Text style={{...styles.reportTitle, flex: 1}}>{title}</Text>
    </View>
  );
  
  export default InvoiceTitle