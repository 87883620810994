import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Table,
  Spinner,
  Image
} from "react-bootstrap";
import { withRouter } from "react-router";
//import Sidebar from './Sidebar';
import Sidebar2 from "./Sidebar2";
import Footer from "./Footer";
//import Topmenu from "./Topmenu";
import OrderDetails from "./OrderDetails";
import "../Dashboard.css";

//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";

import { useNavigate } from "react-router-dom";

import * as Icon from "react-bootstrap-icons";
import axios from "axios";
import { client, OS_INVOICE_FOR_NDAYS } from "../config/Config";
import Select from "react-select";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';
import CsvGenerate from "../pages/CsvGenerate";



import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsArrowLeftCircle,
  BsCardText,
  BsFillPrinterFill
} from "react-icons/bs";

import { AiOutlineOrderedList } from "react-icons/ai";

import MenuOms from "./MenuOms";

import rmcBlueIcon from "../assets/images/ready_mix_blue.png";
import SidebarMain from "./SidebarMain";
import { CSVLink, CSVDownload } from "react-csv";

import {getDistrictList, getStateList, getProductApiCall, getProductBuy, getProductsSell, searchAllUniqLocations, formatDateIndian } from "./utils";

const ListCustInv = () => {
  /*
  csvData={customers}
            fileName={"cust-Inv_2024_01_17_11_34_55_88899.csv"}
            wscols={wscols}
  */          
  const initialized = useRef(false);
  let invArr = [
    
    {
      inv_no: "",
      inv_date: "",
      customer_name: "",
      address1: "",
      state: "",
      state_code: "",
      gst:"",
      sub_total:"",
      cgst:"",
      sgst:"",
      total_amount: "",
     
    },
  ];
  
  

  const [invList, setInvList] = useState([]);
  const [dcListFull, setDcListFull] = useState([]);
  const [invoiceExcelList, setInvoiceExcelList] = useState([]);
  //const [orderList, setOrderList] = useState(orderListLoad);
  //const [orderListFull, setOrderListFull] = useState([]);
  //const [orderListFull, setOrderListFull] = useState([]);
  const [orderDcFetched, setOrderDcFetched] = useState(false);
  
  function padTo2Digits(num) {
    //console.log("<<<<<<<<<<<<<< given number >>>>>>>>>>>>>>>> : ", num);
    var modNum = num.toString().padStart(2, "0");
    console.log("Modified  value : ", modNum);
    return modNum;
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }
  const intialValues = {
    start_date: formatDate(new Date(new Date().setDate(new Date().getDate() - OS_INVOICE_FOR_NDAYS))),
    end_date: formatDate(new Date()),
    gst_number: "",

  }

  

  const [deliveryChellan, setDeliveryChellan] = useState([]);
  const [prodList, setProdList] = useState([]);
  const [toRender, setToRender] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [customerListFull, setCustomerListFull] = useState([]);
  const [customerIdSelect, setCustomerIdSelect] = useState({value: 0, label: 'All Customers'});
  const [optCustomer, setOptCustomer] = useState([]);
  const [productList, setProductList] = useState([]);
  const [productListFull, setProductListFull] = useState([]);
  const [productIdSelect, setProductIdSelect] = useState({});
  const [optProduct, setOptProduct] = useState([]);
  const [formValues, setFormValues] = useState(intialValues);
  const [dcList, setDcList] = useState([]);
  


  const [optLocations, setOptLocations] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState({});

  const [exportInvList, setExportInvList] = useState([]);
  const navigate = useNavigate();

  const handleAdd = (e) => {
    e.preventDefault();
    console.log("Add or Update Order button clicked ...", e);
    navigate(`/addUpdateCustInv`, {
      state: { passed_element: [], action: "add" },
    });
  };
  

  

  const handleEdit = (e, data) => {
    console.log("Handle action edit Clicked : ", e);
    //console.log("Handle Clicked edit DATA : ", data);
    console.log("Handle action edit Clicked target : ", e.target);
    console.log(
      "Handle action edit Clicked target Current : ",
      e.currentTarget
    );
    console.log(
      "Handle action edit Clicked target Current id : ",
      e.currentTarget.id
    );
    console.log("Handle action edit Clicked target ID : ", e.target.id);
    //console.log("Handle action edit Clicked target NAME : ", e.target.name);
    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(
        0,
        clickedTagId.lastIndexOf("-")
      );
      console.log("current selected element name : ", nameOfElement);
      console.log("Current SELECTED id : ", curSelId);
      console.log("CUR SELECTED VALUE : ", invList[curSelId]);

      //navigate(`/${client.urlDelimiter}orderDetails`, {state : orderList[curSelId]});
      navigate(`/addUpdateCustInv`, {
        state: { passed_element: invList[curSelId], action: "edit" },
      });
    }
  };

  const handleView = async (e) => {
    console.log("handle view called : ", e);

    console.log("Handle action view Clicked target : ", e.target);
    console.log(
      "Handle action view Clicked target Current : ",
      e.currentTarget
    );
    console.log(
      "Handle action view Clicked target Current id : ",
      e.currentTarget.id
    );
    console.log("Handle action view Clicked target ID : ", e.target.id);
    //console.log("Handle action edit Clicked target NAME : ", e.target.name);

    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(
        0,
        clickedTagId.lastIndexOf("-")
      );
      console.log("current selected element name view : ", nameOfElement);
      console.log("Current SELECTED id view : ", curSelId);
      console.log("CUR SELECTED VALUE view : ", invList[curSelId]);
      console.log("Before call orderDetails page ...");
      navigate(`/addUpdateCustInv`, {
        state: { passed_element: invList[curSelId], action: "view" },
      });
    }
  };

  const actionDeleteHandle = (e, data) => {
    console.log("Handle action delete Clicked : ", e);
    //console.log("Handle Clicked delete DATA : ", data);
  };

  for (var ni = 0; ni < deliveryChellan.length; ni++) {
    console.log("IND DAtA Dashboard : ", deliveryChellan[ni]);
  }
 

  const getCustInvoiceList = async() => {
    try {
      var aIVal = localStorage.getItem("_aI");
      console.log("Authorization token : ", aIVal);

      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}

      console.log("Headers : ", headers);

      console.log("++++++++++++++++++++ CUSTOMER ID SELECT ----------------: ", customerIdSelect);

      
      var res = await axios.get(client.domain + `/getAllOrders?customer_id=${customerIdSelect.value}&start_date=${formValues["start_date"]}&end_date=${formValues["end_date"]}`, {headers}, {
        withCredentials: false,
      });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getOrdersList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getDistrictList : ", res.data.detail.res_data);
        //setOrderList(res.data.detail.data);
        //setOrderListFull(res.data.detail.data);

        if (res.data.detail.res_data.order_list.length > 0) {
          setInvList(res.data.detail.res_data.order_list);
          //setOrderListFull(res.data.detail.res_data.order_list)
        }
        setToRender(true);

       

        var invoiceExcelArr = [];
            res.data.detail.res_data.order_list.map((invoice) => {

              var invoiceInd = {
                "Inv.No": invoice.order_no,
                "Date": formatDateIndian(new Date(invoice.order_date)),
                "Customer": invoice.customer_name,
                //location : invoice.address_line1,
                "Address": invoice.address_line1,
                "State": invoice.state_name,
                "State Code": invoice.gst_number ? invoice.gst_number.substring(0, 2) : "",
                "GST IN": invoice.gst_number,
                "Sub Total": invoice.amount_basic.toFixed(2),                
                "CGST": invoice.cgst.toFixed(2),
                "SGST": invoice.sgst.toFixed(2),
                //igst: invoice.igst, 
                "Total Amount": invoice.amount_total.toFixed(2)
              }
              invoiceExcelArr.push(invoiceInd); 
            //setInvoiceExcelList(res.data.detail.res_data.order_list)
        })
        console.log("******EXCEL LIST:", invoiceExcelArr);
        setExportInvList(invoiceExcelArr);
        /*
        let invoiceExcelList = {
          inv_no: formValues.inv_no,
          inv_date: formValues.inv_date,
          gst: formValues.gst,
          invoice_array: invoiceExcelArr
          
          total_received: receipt_total_sum.current,
          current_balance: total_amount_sum.current,
          total_balance: total_balance_os.current,
          summary_array: summaryUpdateArr
          */
        

        /*
        res.data.detail.data.map((itemCustomer, idx) => {
          console.log("Ind val : ", itemCustomer);
          var customerInd = {
            value: itemCustomer.id,
            label: itemCustomer.name,
          };
          console.log("In customer modified : ", customerInd);
          customerArr.push(customerInd);
        });
        */
      }
      
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
	   setToRender(true);
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ",error.response.data.detail.res_data);
        }
      }
    }
    setToRender(true);
  };
  

  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect called ##########################"
      );
      ( async() => {
        var [retState, customerArr] = await getCustomerListDropdown();
        if ( retState ) {
          setOptCustomer(customerArr);
          setCustomerIdSelect(customerArr[0]);
        }

        await getCustInvoiceList();
      })();

      //setProductInfo(product_info);
      //getOrderList();
      
     
      
      initialized.current = true;
    }
  }, []);

  const handleBack = async (e) => {
    console.log("handle back called : ", e);
    navigate(-1);
  };
  const getCustomerListDropdown = async () => {
    console.log("getCustomerListDropdown method called ...");
    var retState = false;
    
  
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + "/crm/getAllCustomers", { headers }, { withCredentials: false });
      
      console.log("Response from server getCustomerList : ", res.data.detail.data);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getCustomerList : ", res.data.detail.data);
        
  
        var customerArr = [];
        var custAll = { value: 0, label: 'All Customers'};
        customerArr.push(custAll);
        res.data.detail.data.map((itemCustomer, idx) => {
          console.log("Ind val : ", itemCustomer);
          var customerInd = {
            value: itemCustomer.id,
            label: itemCustomer.name,
          };
          console.log("In customer modified : ", customerInd);
          customerArr.push(customerInd);
        });

        var keySearch = "order_date";
        
        
  
        console.log("Mod Full VALUE : ", customerArr);
        retState = true;
      }
     
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.data
          );
         
        }
      }
    }
    return [retState, customerArr];
  };
  
 
  const onChangeDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);
    var orderListFilter = []
   /*
    if ( e.value === 0 ) {
      setOrderList(orderListFull);
    }
    else {
      productListFull.map((date) => {
        if ( date.order_date === e.value ) {
          orderListFilter.push(date);
        }
      })
      setOrderList(orderListFilter);
    }
    
    */
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };
 

  const handleDateSearch = async() => {
    console.log("handleDateSearch called ...");
    console.log("++++++++++++ START DATE : %s, END DATE : %s >>>>>>>>>>>>>>>>", formValues["start_date"], formValues["end_date"]);

    const start = Date.parse(formValues["start_date"]);
    const end = Date.parse(formValues["end_date"]);
    
    /*
    var dcListFilter = [];
    dcListFull.map((dc) => {
      const d = Date.parse(dc.dc_date);
      if ( d >= start && d <= end ) {
        if ( selectedLocationId.label !== 'All' && customerIdSelect.value !== 0 ) {
          if ( dc.address_line1 === selectedLocationId.label && dc.customer_id === customerIdSelect.value ) {
            dcListFilter.push(dc);
          }
        }
        else if ( selectedLocationId.label === 'All' && customerIdSelect.value !== 0 ) {
          if ( dc.customer_id === customerIdSelect.value ) {
            dcListFilter.push(dc);
          }
        }
        else if ( selectedLocationId.label !== 'All' && customerIdSelect.value === 0 ) {
          if ( dc.address_line1 === selectedLocationId.label ) {
            dcListFilter.push(dc);
          }
        }
        else {
          dcListFilter.push(dc);
        }
      } 
    })
    setInvList(dcListFilter);
    */
    await getCustInvoiceList();
  };
  const handleCustomerSelect = (e, data) => {
    console.log("handleCustomerSelect called ...", e);

    /*
    var dcListFilter = []

    if ( e.value === 0 ) {
      //setOrderList(orderListFull);
      setDcList(dcListFull);
      setSelectedLocationId({value: "All", label: "All"});
    }
    else {
      dcListFull.map((dc) => {
        if ( selectedLocationId.label !== 'All' ) {
          if ( dc.customer_id === e.value && dc.address_line1 === selectedLocationId.label) {
            dcListFilter.push(dc);
          }
        }
        else {
          if ( dc.customer_id === e.value ) {
            dcListFilter.push(dc);
          }
        }
      })
      setInvList(dcListFilter);
    }
    */
    setCustomerIdSelect(e);
  };
  const handleFullPrint = async (e) => {
    //setPrintAction(true);
    //setFormValues({ ...formValues, summaryPrint: "ONLY"});
    //setPrintDialog(true);
    //console.log("Handle Edit clicked FULL : ", e);
    //console.log("Handle Edit clicked FULL currentTarget : ", e.currentTarget);
    //console.log("Handle Edit clicked FULL target : ", e.target);

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(
      urlStr.lastIndexOf(client.urlDelimiter) + 1
    );
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);

    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printCustInv?customer_id=${customerIdSelect.value}&start_date=${formValues["start_date"]}&end_date=${formValues["end_date"]}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0,0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
    //const { name, value } = e.currentTarget;
    //var currentId = name.substring(name.lastIndexOf("-") + 1);
    //console.log("SELECTED DC ROW ID FOR PRINT : ", currentId);
    //setSelectedDCRowForPrint(currentId);
  };

  const wscols = [
    
    {
      wch: Math.max(...invArr.map((customer) => customer.inv_no.length))
    },
    
    { wch: Math.max(...invArr.map((customer) => customer.inv_date.length)) },
    { wch: Math.max(...invArr.map((customer) => customer.customer_name.length)) },
    { wch: Math.max(...invArr.map((customer) => customer.gst.length)) },
    { wch: Math.max(...invArr.map((customer) => customer.sub_total.length)) },
    { wch: Math.max(...invArr.map((customer) => customer.cgst.length)) },
    { wch: Math.max(...invArr.map((customer) => customer.sgst.length)) },  
  ];

  const fileName = "text.csv";

 
  return (
    <Container>
     <SidebarMain />
     { toRender ?  
      <form>
        <Row>
        
        <Col xs={2} md={2} sm={2} lg={2}> 
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem" }}>Customer Invoice</span>
        </Col>
        <Col xs={2} md={2} sm={2} lg={2}>
              <Row>
              <Col>
              <Select
                    inline
                    id="customer"
                    name="customer"
                    value={{
                      value: customerIdSelect.value,
                      label: customerIdSelect.label,
                    }}
                    options={optCustomer}
                    defaultValue={{
                      value: customerIdSelect.value,
                      label: customerIdSelect.label,
                    }}
                    onChange={handleCustomerSelect}
                    //isDisabled={isReadOnly}
                  />
                  </Col>
                  </Row>    
            </Col>
           
            <Col xs={4} md={4} sm={4} lg={4}> 
            
            <Row>
             
            <Col>
                <Form.Control
                  type="date"
                  name="start_date"
                  placeholder="Start Date"
                  value={formValues.start_date}
                  onChange={onChangeDatePicker}
                  //readOnly={isReadOnly}
                  
                />
              
            </Col>
          
            <Col>
                <Form.Control
                  type="date"
                  name="end_date"
                  placeholder="End Date"
                  value={formValues.end_date}
                  onChange={onChangeDatePicker}
                  //readOnly={isReadOnly}
                  
                />
                 </Col>
                 </Row>
                 </Col>

                 <Col
            xs={1}
            md={1}
            sm={1}
            lg={1}
            className="d-flex justify-content-end float-end"
          >
            <Button id="search-order-id"
            size={"sm"}
            variant="success"
            onClick={(e) => handleDateSearch(e)}>
              Search
            </Button>

          </Col>
          <Col
            xs={3}
            md={3}
            sm={3}
            lg={3}
            className="d-flex justify-content-end float-end"
          >  
           <Button id="invPrint" name = "invPrint"  onClick={(e)  => handleFullPrint(e)}>
                <BsFillPrinterFill
                 size={18}
                 style={{ color: "white", cursor: "pointer" }}
                 name={`print-inv-icon`}
                 id={`print-inv-icon`}
                 />
                 </Button>
                 &nbsp;&nbsp;
                 {/*
                 <CSVLink 
                    //data={props.dataPass}
                    filename={"cust-invoice_2024_01_17_11_34_55_88899.csv"}
                   
                >
                 
                  </CSVLink>
                   */}
                   {/*
                <Button
                 variant="warning"
                 onClick={(e) =>ListCustInv(invArr, fileName, wscols)}>
                 Export Excel
                </Button>
                */}
                <CsvGenerate dataPass={exportInvList}/>
                &nbsp;&nbsp;
           <Button
             variant="warning"
            id="add-btn-id"
            name="add-btn-name"
            className=" float-end"
            onClick={(e) => handleAdd(e)}
            >
              New Invoice
            </Button> 
          </Col>
        </Row>
        <hr align="center" />
        
        
        { toRender &&
       
        <Table striped bordered hover size="sm">
          <thead>
            <tr className="table-primary">
              <th className="table-row-heading" key={"order-list-heading-key"}>
                S.No
              </th>
              <th className="table-row-heading">Inv No</th>
              <th className="table-row-heading" style={{width: '9%'}}>Date</th>
              <th className="table-row-heading">Customer</th>
              <th className="table-row-heading">Address</th>
              {/*<th className="table-row-heading">State</th>
              <th className="table-row-heading">State Code</th>*/}
              <th className="table-row-heading">GST IN</th>
              <th className="table-row-heading">Sub Total</th>
              {/*<th className="table-row-heading">CGST</th>
              <th className="table-row-heading">SGST</th>*/}
              <th className="table-row-heading">GST</th>
              <th className="table-row-heading">Tot Amount</th>
              
              <th className="table-row-heading" style={{ textAlign: "center" }}>...</th>
            </tr>
          </thead>

          <tbody>
            {invList.map((invInd, idx) => (
              <tr key={`inv-table-row-key-${idx}`}>
                <td style={{ textAlign: "right" }}
                  key={`inv-table-row-key-sno-${idx}`}
                  id={`inv-table-row-id-sno-${idx}`}
                  name={`inv-table-row-name-sno-${idx}`}
                >
                  {idx + 1}
                </td>
                <td style={{ textAlign: "right" }}
                  key={`inv-table-row-key-invno-${idx}`}
                  id={`inv-table-row-id-invno-${idx}`}
                  name={`inv-table-row-name-invno-${idx}`}
                >
                  {invInd.order_no}
                </td>
                
                <td style={{ textAlign: "left" }}
                  key={`inv-table-row-key-date-${idx}`}
                  id={`inv-table-row-id-date-${idx}`}
                  name={`inv-table-row-name-date-${idx}`}
                >
                  {formatDateIndian(new Date(invInd.order_date.split('T')[0]))}
                </td>
                <td
                style={{width:"16%"}}
                  key={`inv-table-row-key-customer-${idx}`}
                  id={`inv-table-row-id-customer-${idx}`}
                  name={`inv-table-row-name-customer-${idx}`}
                >
                  {invInd.customer_name}
                </td>

                <td
                  style={{width:"16%"}}
                  key={`inv-table-row-key-address1-${idx}`}
                  id={`inv-table-row-id-address1-${idx}`}
                  name={`inv-table-row-name-address1-${idx}`}
                >
                  {invInd.address_line1}
                </td>
                {/*
                <td
                  key={`inv-table-row-key-state-${idx}`}
                  id={`inv-table-row-id-state-${idx}`}
                  name={`inv-table-row-name-state-${idx}`}
                >
                  {invInd.state_name}
                </td>
                <td style={{ textAlign: "right" }}
                  key={`inv-table-row-key-state-code-${idx}`}
                  id={`inv-table-row-id-state-code-${idx}`}
                  name={`inv-table-row-name-state-code-${idx}`}
                >
                  { invInd.gst_number ? invInd.gst_number.substring(0, 2) : ""}
                </td>
                */}                
                <td
                style={{ padding: "5px", textAlign: "right", width:"8%" }}
                  key={`inv-table-row-key-gst-${idx}`}
                  id={`inv-table-row-id-gst-${idx}`}
                  name={`inv-table-row-name-gst-${idx}`}
                >
                  {invInd.gst_number}
                </td>
                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`inv-table-row-key-sub_total-${idx}`}
                  id={`inv-table-row-id-sub_total-${idx}`}
                  name={`inv-table-row-name-sub_total-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(invInd.amount_basic).toFixed(2)))}
                </td>
                {/*
                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`inv-table-row-key-cgst-${idx}`}
                  id={`inv-table-row-id-cgst-${idx}`}
                  name={`inv-table-row-name-cgst-${idx}`}
                >
                  {invInd.cgst.toFixed(2)}
                </td>
                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`inv-table-row-key-sgst-${idx}`}
                  id={`inv-table-row-id-sgst-${idx}`}
                  name={`inv-table-row-name-sgst-${idx}`}
                >
                  {invInd.sgst.toFixed(2)}
                </td>
              */}
              <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`inv-table-row-key-cgst-${idx}`}
                  id={`inv-table-row-id-cgst-${idx}`}
                  name={`inv-table-row-name-cgst-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(invInd.cgst + invInd.sgst).toFixed(2)))}
                </td>
                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`inv-table-row-key-total_amount-${idx}`}
                  id={`inv-table-row-id-total_amount-${idx}`}
                  name={`inv-table-row-name-total_amount-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(invInd.amount_total).toFixed(2)))}
                </td>
                
                <td style={{ textAlign: "center" }}
                  key={`inv-table-row-key-actions-${idx}`}
                  id={`inv-table-row-id-actions-${idx}`}
                  name={`inv-table-row-name-actions-${idx}`}
                >
                  <BsCardText
                    size={28}
                    style={{ color: "#0d6efd", cursor: "pointer" }}
                    onClick={(e) => handleEdit(e)}
                    name={`inv-view-icon-${idx}`}
                    id={`inv-view-icon-${idx}`}
                  />
                  &nbsp;&nbsp; 
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        }  
        </form>
       : 
       <div className="container-spinner">
      <div className="vertical-center-spinner">
       {/*<Button variant="primary" disabled>*/}
       <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{ width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
 
       {/*</Button>*/}
       </div>
       </div>
      }      
       
      
    </Container>
  );
};
//const Dashboard = withRouter(Dash);
export default ListCustInv;
