import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { COMPANY_NAME } from '../config/Config';

function Topmenu() {
  return (
    <Navbar key="sm" expand="sm" bg="primary" fluid="true" className="bg-body-tertiary mb-3 ms-auto">
            
          <Container fluid="true">
            <Navbar.Brand href="/">
            <img src="https://avinasoft.com/assets/img/logo.png" height="64" width="64" alt="Avinasoft"/>
            </Navbar.Brand>

            <Navbar.Brand href="#">{COMPANY_NAME}</Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-sm`}
              aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`}>
                  {COMPANY_NAME}
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3" style={{ width: "100%" }}>
                  <Nav.Link href="#action1">Home</Nav.Link>
                  <Nav.Link href="#action2">Invoice & Delivery</Nav.Link>
                  <Nav.Link href="#action2">Stocks</Nav.Link>
                  <NavDropdown
                    title="Bills"
                    id={`offcanvasNavbarDropdown-expand-sm`}
                  >
                    <NavDropdown.Item href="#action3">Supplier</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">Labour payment</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action5">
                      Other expenses
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="#action2">Reports</Nav.Link>
                  <Nav.Link href="#action2">HRM</Nav.Link>
                  <Nav.Link href="/profile">Profile</Nav.Link>
                </Nav>
                <Form className="d-flex">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                  />
                  <Button variant="outline-success">Search</Button>
                </Form>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
  );
}

export default Topmenu;