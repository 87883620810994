import React, { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { Page, Text, View, Document, StyleSheet, PDFViewer,Image } from '@react-pdf/renderer';

import { COMPANY_NAME, client } from '../config/Config';
import logo_left from '../logo_left.png';
import logo_right from '../logo_right.png';
import {getCompanyInfo, formatDateIndian, NumToWord, convertNumberToWords } from "./utils";

//import StockDocument from "./StockDocument";

const borderColor = '#a8a8a8';
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 9,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    //width: '100%',
    //height: '33%',
    width: '100%',
    height: '100%',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  logo_image2: {
    width: '15%',
    height: '98px',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  business_content: {
    width: '60%',
    height: '98px',
    //paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container2: {
    //paddingBottom:0,
    flexDirection: 'row',
    //borderBottomColor: '#bff0fd',
    //backgroundColor: '#bff0fd',
    //borderBottomWidth: 1,
    //alignItems: 'center',
    //height: 12,
    //textAlign: 'center',
    //fontStyle: 'bold',
    //flexGrow: 1,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 1,
    marginBottom: 5,
    borderWidth: 1,
    borderColor: '#a8a8a8',
  },
  container: {
    flexDirection: 'row',
    borderBottomColor: '#a8a8a8',
    backgroundColor: '#a8a8a8',
    //borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
  },
  
  row: {
    flexDirection: 'row',
    //borderBottomColor: '#a8a8a8',
    //borderBottmWidth: 1,
    alignItems: 'center',
    //height: 24,
    fontStyle: 'bold',
  },
 
  
});

const PrintEmpPayment = (props) => {
  const [reportData, setReportData] = useState({});
  const [toRender, setToRender] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({});
  const [paymentDetails, setPaymentDetails] = useState({});

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  const formateTime = (currTime) => {
    var h = (currTime.getHours() < 10 ? "0" : "") + currTime.getHours();
    var m = (currTime.getMinutes() < 10 ? "0" : "") + currTime.getMinutes();
    var hrMin = h + ":" + m;
    return hrMin;
  };

  const formatPrintDate = (currTime) => {
    var dateComponent = [
      padTo2Digits(currTime.getDate()),
      padTo2Digits(currTime.getMonth() + 1),
      currTime.getFullYear(),
    ].join("-");

    var timeComponent = [
      padTo2Digits(currTime.getHours() % 12 || 12 ),
      padTo2Digits(currTime.getMinutes())
    ].join(":");

    const ampm = currTime.getHours() >= 12 ? 'PM' : 'AM';

    return dateComponent + " " + timeComponent + " " + ampm;
  }
  /*
  const getReportList = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
     

      var res = await axios.get(
        client.domain + "/ims/getReportCurrentStock",
        { headers },
        { withCredentials: false }
      );

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getReportList : ", res.data.detail.data);
        setReportData(res.data.detail.data);

        //setShowMsgTitle("Report Info Added Successfully");
        setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        //setShowMsg("Please try after sometime");
        //setShowMsgTitle("Network Issue!");
        //setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg("Please try again ...");
          //setShowMsgTitle("Unable to process your request!");
          //setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          //setShowMsg(error.response.data.detail);
          //setShowMsgTitle("Unable to process your request at this moment!");
          //setShow(true);
        }
      }
    }
  };
  */

  const getReportList = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
     

      var res = await axios.get(
        client.domain + "/ims/getReportCurrentStock",
        { headers },
        { withCredentials: false }
      );

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getReportList : ", res.data.detail.data);
        setReportData(res.data.detail.data);

        //setShowMsgTitle("Report Info Added Successfully");
        setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        //setShowMsg("Please try after sometime");
        //setShowMsgTitle("Network Issue!");
        //setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg("Please try again ...");
          //setShowMsgTitle("Unable to process your request!");
          //setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          //setShowMsg(error.response.data.detail);
          //setShowMsgTitle("Unable to process your request at this moment!");
          //setShow(true);
        }
      }
    }
  };

  const getEmployeePaymentById = async(payment_id) => {
    var retState = false;
      var paymentDetails = {};
      console.log("getExpensePaymentById method called ...: ", payment_id);
  
      try {
        var aIVal = localStorage.getItem("_aI");
        var headers = { ...client.headers, Authorization: `Bearer ${aIVal}`}
        var res = await axios.get(client.domain + `/getEmployeePaymentById?payment_id=${payment_id}`, { headers }, { withCredentials: false });
        
        console.log("Response from server getEmployeePaymentById : ", res.data.detail.data);
        if (res.data.detail.status === "SUCCESS") {
          console.log("Response message from server getEmployeePaymentById : ", res.data.detail.data);        
          paymentDetails = res.data.detail.data;
          retState = true;
        }
       
      } catch (error) {
        console.log("FULL ERROR : ", error);
        if (error.code === "ERR_NETWORK") {
          console.log(
            "Not able to reach target server please try after sometime"
          );
        } else {
          console.log("ERROR : ", error.response.data.detail);
          if (error.response.data.detail.status === "FAILED") {
            console.log(
              "Request failed due to ",
              error.response.data.detail.res_data
            );
          }
        }
      }
      return [retState, paymentDetails];
  }
  

  useEffect(() => {
    console.log("useEffect method called ...");
    console.log("CALLED CURRENT PAGE : ", window.location.href);

    console.log("CALLED window locaion : ", window.location);
    let params = (new URL(window.location)).searchParams;
    console.log("COMPLETE PARAMS : ", params);
    let paymentIdParam = params.get("payment_id");
    console.log("PAYMENT ID FROM PARAM : ", paymentIdParam);

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.indexOf('?') + 1);
    var urlTmp = urlStr.substring(0, urlStr.indexOf("?"));

    console.log("URL ONLY : ", urlTmp);
    console.log("URL Params : ", paramsTmp);

    let searchParams = new URLSearchParams(paramsTmp);
    console.log("<<<<<<<<<<<<<SEARCH PARAMS:", searchParams);
    var paymentId = searchParams.get("payment_id");
    console.log("^^^^^^^^^^^^^^^^^^^ RECEIPT ID : ", paymentId);

    (async () => {
      var [retState, companyInfoTmp] = await getCompanyInfo();
      if (retState) {
        setCompanyInfo(companyInfoTmp);
      }

      var [retStatePayment, newPaymentDetails] = await getEmployeePaymentById(paymentId);
      if ( retStatePayment ) {
        console.log("Payment Details : ");
        setPaymentDetails(newPaymentDetails);
      }
      setToRender(true); 
    })();
    
  }, [])

  return (
    <PDFViewer style={styles.viewer}>
      {toRender === true &&
        <Document>
          <Page size="A4" orientation="portrait" style={styles.page}>
          

            {/* 
      <View style={styles.section}>
        <Text  className="text-center"style={{fontWeight: 'bold'}}  >{reportData.report_header.name}</Text>
   
      <View style={styles.headerContainer}>
        <Text style={{fontWeight: 'bold'}}>{reportData.report_header.gst_no}</Text>
        <Text>{reportData.report_header.address_line1} </Text>
        <Text>{reportData.report_header.address_line2}</Text>
        <Text>{reportData.report_header.district}</Text>
        <Text>{reportData.report_header.pincode}</Text>
    </View>
     </View>
            */}
           <View>
              <View style={{ flexDirection: 'row', borderBottomWidth: 1, borderBottomColor: '#a7a5a5', paddingBottom: '5px'}}>
                <View style={{ textAlign: 'right', width: '25%' }}>
                  <Image style={{ marginHorizontal: '10%', width: 'auto', height: 'auto', height: "80px", width: "80px" }} src={logo_left} />
                </View>
                <View style={{ width: "57%", alignItems: "center", /*paddingRight: "30px",*/ /*borderBottomWidth: 1,*/ borderBottomColor: '#a7a5a5'/*,marginTop: 1, marginBottom: 10, borderWidth: 1*/ }} key="address-key">
                  <Text style={{ fontSize: "20px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>{companyInfo.name}</Text>
                  <Text>{companyInfo.address_line1}</Text>
                  <Text>{companyInfo.address_line2}-{companyInfo.pincode}</Text>
                  <Text>{companyInfo.district_name}, {companyInfo.state_name}</Text>
                  <Text style={{ fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica'}}><Text style={{ fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>Mobile:</Text> {companyInfo.phone}</Text>
                </View>
                <View style={{ textAlign: 'right', width: '18%', alignItems: 'right', alignContent: 'right'}}>
                  <Image style={{ /*marginHorizontal: '10%',*/height: "80px", width: "80px", alignContent: 'right', alignItems: 'right'}} src={logo_right} />
                </View>
              </View>
            </View>
            <View style={{ width: "100%", alignItems: "center", textAlign: 'center',  paddingTop: "5px", borderBottomWidth: 1, borderBottomColor: '#a7a5a5' }}>
              <Text style={{ fontSize: "12px", textAlign: 'center', alignContent: 'center', fontFamily: 'Helvetica-Bold', color: '#646363' }}>Payment</Text>
            </View>
            <hr style={{paddingBottom: "10px"}}/>
            <View>
            <View style={{flexDirection: 'row',textAlign: 'left',paddingBottom: "20px"}}>
                <Text style={{fontFamily: 'Helvetica',  alignItems: "left",textAlign: 'left'}}>Date  </Text> 
                <Text style={{  alignItems: "left",textAlign: 'left', fontFamily: 'Helvetica-Bold',paddingLeft:"71px"}}> : {formatDateIndian(new Date(paymentDetails.payment_date))}</Text>     
                </View> 
                
                <View style={{flexDirection: 'row',textAlign: 'left',paddingBottom: "20px"}}>
                  <Text style={{fontFamily: 'Helvetica', alignItems: "left",textAlign: 'left'}}>Payment No  </Text>
                 
                  <Text style={{  alignItems: "left",textAlign: 'left', fontFamily: 'Helvetica-Bold',paddingLeft:"41px"}}>: {paymentDetails.payment_number}</Text>
                  </View> 
                  </View>
                  
            {/*
            <View style={{ width: "100%", alignItems: "left", textAlign: 'left' , borderBottomWidth: 1, paddingTop: "10px", borderBottomColor: '#646363'}}>
              <Text style={{ fontSize: "10px", textAlign: 'left', alignContent: 'left', fontFamily: 'Helvetica', borderBottomWidth: 1, paddingBottom: "2px", borderBottomColor: '#646363'}}>Paid To M/S. <Text style={{fontFamily: 'Helvetica-Bold', fontSize: "10px"}}>{paymentDetails.paid_to_name}</Text></Text> 
              <Text style={{ fontSize: "10px", textAlign: 'left', alignContent: 'left', fontFamily: 'Helvetica', borderBottomWidth: 1, paddingTop: "10px", borderBottomColor: '#646363' }}>a sum of Rupees <Text style={{fontFamily: 'Helvetica-Bold', fontSize: "10px"}}>{convertNumberToWords(paymentDetails.amount)}</Text></Text>
              <View style={{ flexDirection: 'row', borderBottomWidth: 1, paddingTop: "10px", borderBottomColor: '#646363'}}>
              <Text style={{ fontSize: "10px", textAlign: 'left', alignContent: 'left', fontFamily: 'Helvetica', width: '50%'  }}>by <Text style={{fontFamily: 'Helvetica-Bold', fontSize: "10px"}}>{paymentDetails.payment_mode}</Text> </Text>
              <Text style={{ fontSize: "10px", textAlign: 'left', alignContent: 'left', fontFamily: 'Helvetica', width: '15%'  }}>dated on </Text>
              <Text style={{ fontSize: "10px", textAlign: 'left', alignContent: 'left', fontFamily: 'Helvetica', width: '20%'  }}><Text style={{fontFamily: 'Helvetica-Bold', fontSize: "10px"}}>{formatDateIndian(new Date(paymentDetails.ref_date))}</Text></Text>
              </View>
              <Text style={{ fontSize: "10px", textAlign: 'left', alignContent: 'left', fontFamily: 'Helvetica', paddingTop: "10px",}}>For <Text style={{fontFamily: 'Helvetica-Bold', fontSize: "10px"}}>{paymentDetails.towards}</Text></Text>
            </View>
            */}

            <View style={{flexDirection: 'row', }}>
               <Text style={{width: '20%'}}> Paid To</Text>
               <Text style={{width: '80%', borderBottomWidth: 0.25, fontFamily: 'Helvetica-Bold'}}>:{paymentDetails.paid_to_name}</Text>
            </View>
            <hr style={{paddingBottom: "20px"}}/>

            <View style={{flexDirection: 'row'}}>
               <Text style={{width: '20%'}}> Amount in words </Text>
               <Text style={{width: '80%', borderBottomWidth: 0.25,  fontFamily: 'Helvetica-Bold'}}>:{convertNumberToWords(paymentDetails.amount)}</Text>
            </View>
            <hr style={{paddingBottom: "20px"}}/>

            <View style={{flexDirection: 'row'}}>
               <Text style={{width: '20%'}}> Payment Method </Text>
               <Text style={{width: '80%', borderBottomWidth: 0.25, fontFamily: 'Helvetica-Bold'}}>:{paymentDetails.payment_mode}</Text>
            </View>
            <hr style={{paddingBottom: "20px"}}/>

            <View style={{flexDirection: 'row'}}>
               <Text style={{width: '20%'}}> Paid For </Text>
               <Text style={{width: '80%', borderBottomWidth: 0.25, fontFamily: 'Helvetica-Bold'}}>:{paymentDetails.towards}</Text>
            </View>

            <hr style={{paddingBottom: "50px"}}/>


          <View style={{ flexDirection: 'row' }}>
          
          <View  style={{width: '20%', borderWidth:1}}> 
             <Text style={{fontSize: "10px", fontWeight: "bold", fontFamily: 'Helvetica-Bold',  alignItems: "left", paddingLeft: "20px", paddingTop: "5px",textAlign: "left"}}>Rs. {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(paymentDetails.amount).toFixed(2)))}</Text>
             </View>
             <Text style={{width: '40%', fontSize: "10px", textAlign: 'left', alignItems: 'left', fontFamily: 'Helvetica-Bold', paddingLeft: "123px",paddingBottom: "10px"}}>Paid By</Text>
             <Text style={{width: '40%', fontSize: "10px", textAlign: 'right', alignItems: 'right', fontFamily: 'Helvetica-Bold', paddingRight: "40px"}}>Received By</Text>
             
          </View>

          

          <View style={{ flexDirection: 'row' }}>
            <Text style={{width: '20%'}}></Text>
          <Text style={{width: '40%', fontSize: "8px", textAlign: 'right', alignItems: 'right', paddingTop: "2px" }}>For <Text style={{fontSize: "10px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold', alignItems: "left"}}>{COMPANY_NAME}</Text></Text>
          {/*<Text style={{width: '40%', fontSize: "8px", textAlign: 'right', alignItems: 'right', paddingRight: "40px", paddingTop: "2px", width: "50%" }}>For <Text style={{fontSize: "10px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold', alignItems: "left",width: "50%" }}>{paymentDetails.paid_to_name}</Text> </Text>*/}
          <Text style={{width: '40%'}}></Text>
          </View>

            {/*
            <View style={{ width: "100%", paddingBottom: "2px" }}>
            <Text style={{ fontSize: "10px", textAlign: 'left', alignContent: 'left', fontFamily: 'Helvetica', paddingLeft: "200px"}}>Prepared By</Text>
                <Text style={{fontSize: "10px", paddingLeft: "200px", alignItems: "left", fontFamily: 'Helvetica',paddingTop: "10px"}}>For <Text style={{fontSize: "10px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold', paddingLeft: "350px", alignItems: "left"}}>KMC CONCRETE</Text> </Text>

                <Text style={{ fontSize: "10px", textAlign: 'right', alignContent: 'right', fontFamily: 'Helvetica', paddingRight: "25px"}}>Prepared By</Text>
                <Text style={{fontSize: "10px", paddingRight: "25px", alignItems: "right", fontFamily: 'Helvetica',paddingTop: "10px",alignContent: 'right'}}>For <Text style={{fontSize: "10px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold', paddingRight: "20px", alignItems: "right"}}>KMC CONCRETE</Text> </Text>

          */}
           <View style={{ width: "100%", paddingBottom: "2px" }}>
            
            
             {/*<Text style={{fontSize: "8px",fontWeight: "bold", fontFamily: 'Helvetica-Bold', paddingRight: "15px", alignItems: "right"}}>*Cheques subject to realisation </Text>*/}
             </View>
             <View style={{ flexDirection: 'row' }}>
             <Text style={{width: '20%'}}></Text>
            <Text style={{width: '40%', fontSize: "10px", textAlign: 'left', alignItems: 'left',  paddingLeft: "110px", paddingTop: "35px", paddingBottom: '50px',fontFamily: 'Helvetica-Bold'}}>({paymentDetails.payment_by_name})</Text>
            <Text style={{width: '40%', fontSize: "10px", textAlign: 'right', alignItems: 'right', paddingRight: "33px", paddingTop: "35px", paddingBottom: '50px',fontFamily: 'Helvetica-Bold'}}>({paymentDetails.paid_to_name})</Text>
              </View>

              <View style={{flexDirection: 'row'}}>
            
            <Text style={{textAlign: 'left', flex: 1}} render={({ pageNumber, totalPages }) => (
                `Printed On: ${formatPrintDate(new Date())}`
            )} fixed />
             
             
              </View> 
           
            

           
           
              

          </Page>
        </Document>
      }
    </PDFViewer>
  );
}

export default PrintEmpPayment;