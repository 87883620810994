import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Table,
  Spinner,
  Image
} from "react-bootstrap";
import { withRouter } from "react-router";
//import Sidebar from './Sidebar';
import Sidebar2 from "./Sidebar2";
import Footer from "./Footer";
//import Topmenu from "./Topmenu";
import OrderDetails from "./OrderDetails";
import "../Dashboard.css";

//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";

import { useNavigate } from "react-router-dom";

import * as Icon from "react-bootstrap-icons";
import axios from "axios";
import { client } from "../config/Config";
import Select from "react-select";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';
import { LuRefreshCw } from "react-icons/lu";



import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsArrowLeftCircle,
  BsCardText,
} from "react-icons/bs";

import { AiOutlineOrderedList } from "react-icons/ai";

import MenuOms from "./MenuOms";

import rmcBlueIcon from "../assets/images/ready_mix_blue.png";
import SidebarMain from "./SidebarMain";

import {getDistrictList, getStateList, getProductApiCall, getProductBuy, getProductsSell, searchAllUniqLocations, formatDateIndian, getCustomerListDropdown } from "./utils";

const ListDCs = (props) => {
  const initialized = useRef(false);
  let dcArr = [
    {
      dc_date:"23-4-2023",
      dc_no:"475",
      customer_name: "Deepa",
      quantity: "8",
      total_quantity:"16",
      unit_price: "400",
      product_total:"6000",
      pumping_charges:"600",
      diesel_charges:"690",
      other_charges:"800",
      total_amount:"4500",
      active: false,
    },
    {
      dc_date:"4-4-2023",
      dc_no:"345",
      customer_name: "Kathir",
      quantity: "16",
      total_quantity:"22",
      unit_price: "800",
      product_total:"6000",
      pumping_charges:"450",
      diesel_charges:"700",
      other_charges:"860",
      total_amount:"5000",
      active: false,
    },
    {
      dc_date:"3-3-2023",
      dc_no:"445",
      customer_name: "Sneka",
      quantity: "6",
      total_quantity:"16",
      unit_price: "400",
      product_total:"6000",
      pumping_charges:"340",
      diesel_charges:"240",
      other_charges:"800",
      total_amount:"4500",
      active: false,
    },
    {
      dc_date:"5-3-2023",
      dc_no:"678",
      customer_name: "Saran",
      quantity: "1",
      total_quantity:"16",
      unit_price: "1600",
      product_total:"6000",
      pumping_charges:"780",
      diesel_charges:"400",
      other_charges:"830",
      total_amount:"5500",
      active: false,
    },
    
  ];
  var prodListLoad = [
    {
      prod_id: 1,
      product_name: "M-10",
      hsn_code: "HSN778328",
      unit_price: 4300.0,
      quantity: 10,
      product_price: 43000.0,
      gst: 7740.0,
      total_product_price: 50740.0,
    },
    {
      prod_id: 1,
      product_name: "M-20",
      hsn_code: "HSN778329",
      unit_price: 4700.0,
      quantity: 10,
      product_price: 47000.0,
      gst: 8460.0,
      total_product_price: 55460.0,
    },
    {
      prod_id: 1,
      product_name: "M-30",
      hsn_code: "HSN778378",
      unit_price: 4900.0,
      quantity: 10,
      product_price: 49000.0,
      gst: 8820.0,
      total_product_price: 57820.0,
    },
  ];
  var orderListLoad = [
    /*
        {order_id: 12, order_number:"ORD89494", customer_name: "Rockbreeze Technologies", order_date:"2023-06-12", order_price: 34000.00, gst: 3400.00, total_price: 38400.00, amount_paid: 30000.00, balance: 8400.00},
        {order_id: 13, order_number:"ORD85995", customer_name: "SRT tranports", order_date:"2023-05-09", order_price: 34000.00, gst: 3400.00, total_price: 38400.00, amount_paid: 30000.00, balance: 8400.00},
        {order_id: 14, order_number:"ORD67883", customer_name: "AVS Constructions", order_date:"2023-06-15", order_price: 34000.00, gst: 3400.00, total_price: 38400.00, amount_paid: 30000.00, balance: 8400.00},
        {order_id: 15, order_number:"ORD65583", customer_name: "New way traders", order_date:"2023-07-11", order_price: 34000.00, gst: 3400.00, total_price: 38400.00, amount_paid: 30000.00, balance: 8400.00},
        {order_id: 16, order_number:"ORD46378", customer_name: "Zero Plus promoters", order_date:"2023-07-01", order_price: 34000.00, gst: 3400.00, total_price: 38400.00, amount_paid: 30000.00, balance: 8400.00}
    */
  ];

  const [dcList, setDcList] = useState([]);
  const [dcListFull, setDcListFull] = useState([]);
  //const [orderList, setOrderList] = useState(orderListLoad);
  //const [orderListFull, setOrderListFull] = useState([]);
  //const [orderListFull, setOrderListFull] = useState([]);
  const [orderDcFetched, setOrderDcFetched] = useState(false);
  
  function padTo2Digits(num) {
    //console.log("<<<<<<<<<<<<<< given number >>>>>>>>>>>>>>>> : ", num);
    var modNum = num.toString().padStart(2, "0");
    console.log("Modified  value : ", modNum);
    return modNum;
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }
  const intialValues = {
    start_date: formatDate(new Date()),
    end_date: formatDate(new Date()),
  }

  

  const [deliveryChellan, setDeliveryChellan] = useState([]);
  const [prodList, setProdList] = useState(prodListLoad);
  const [toRender, setToRender] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [customerListFull, setCustomerListFull] = useState([]);
  const [customerIdSelect, setCustomerIdSelect] = useState({});
  const [optCustomer, setOptCustomer] = useState([]);
  const [productList, setProductList] = useState([]);
  const [productListFull, setProductListFull] = useState([]);
  const [productIdSelect, setProductIdSelect] = useState({});
  const [optProduct, setOptProduct] = useState([]);
  const [formValues, setFormValues] = useState(intialValues);

  const [optLocations, setOptLocations] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState({});
  const [searchError, setSearchError] = useState(false);
  const navigate = useNavigate();

  const handleAdd = (e) => {
    e.preventDefault();
    console.log("Add or Update Order button clicked ...", e);
    navigate(`/addUpdateDirectDC`, {
      state: { passed_element: [], action: "add" },
    });
  };
  

  

  const handleEdit = (e, data) => {
    console.log("Handle action edit Clicked : ", e);
    //console.log("Handle Clicked edit DATA : ", data);
    console.log("Handle action edit Clicked target : ", e.target);
    console.log(
      "Handle action edit Clicked target Current : ",
      e.currentTarget
    );
    console.log(
      "Handle action edit Clicked target Current id : ",
      e.currentTarget.id
    );
    console.log("Handle action edit Clicked target ID : ", e.target.id);
    //console.log("Handle action edit Clicked target NAME : ", e.target.name);
    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(
        0,
        clickedTagId.lastIndexOf("-")
      );
      console.log("current selected element name : ", nameOfElement);
      console.log("Current SELECTED id : ", curSelId);
      console.log("CUR SELECTED VALUE : ", dcList[curSelId]);

      //navigate(`/${client.urlDelimiter}orderDetails`, {state : orderList[curSelId]});
      navigate(`/addUpdateDirectDC`, {
        state: { passed_element: dcList[curSelId], action: "edit" },
      });
    }
  };

  const handleView = async (e) => {
    console.log("handle view called : ", e);

    console.log("Handle action view Clicked target : ", e.target);
    console.log(
      "Handle action view Clicked target Current : ",
      e.currentTarget
    );
    console.log(
      "Handle action view Clicked target Current id : ",
      e.currentTarget.id
    );
    console.log("Handle action view Clicked target ID : ", e.target.id);
    //console.log("Handle action edit Clicked target NAME : ", e.target.name);

    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(
        0,
        clickedTagId.lastIndexOf("-")
      );
      console.log("current selected element name view : ", nameOfElement);
      console.log("Current SELECTED id view : ", curSelId);
      console.log("CUR SELECTED VALUE view : ", dcList[curSelId]);
      console.log("Before call orderDetails page ...");
      navigate(`/addUpdateDirectDC`, {
        state: { passed_element: dcList[curSelId], action: "view" },
      });
    }
  };

  const actionDeleteHandle = (e, data) => {
    console.log("Handle action delete Clicked : ", e);
    //console.log("Handle Clicked delete DATA : ", data);
  };

  for (var ni = 0; ni < deliveryChellan.length; ni++) {
    console.log("IND DAtA Dashboard : ", deliveryChellan[ni]);
  }
 /*
  const getOrderList = async() => {
    try {
      var aIVal = localStorage.getItem("_aI");
      console.log("Authorization token : ", aIVal);

      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}

      console.log("Headers : ", headers);

      
      var res = await axios.get(client.domain + "/getAllOrders", {headers}, {
        withCredentials: false,
      });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getOrdersList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log(
          "Response message from server getDistrictList : ",
          res.data.detail.res_data
        );
        //setOrderList(res.data.detail.data);
        //setOrderListFull(res.data.detail.data);

        if (res.data.detail.res_data.order_list.length > 0) {
          setOrderList(res.data.detail.res_data.order_list);
          setOrderListFull(res.data.detail.res_data.order_list);
        }
        setToRender(true);
      }
      
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
	setToRender(true);
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ",error.response.data.detail.res_data);
        }
      }
    }
    setToRender(true);
  };
  */



  const getAllDCListFetch = async(order_id) => {
    try {
        var res = await axios.get(client.domain + `/getAllDCList`, { withCredentials: false });
        //.then((res) => {
            //console.log("ADD New user res:", res);
            //localStorage.setItem('_token', res.data.res_data.token);
            console.log("Response from server getOrdersList : ", res.data.detail);
            if ( res.data.detail.status === 'SUCCESS') {
              console.log("Response message from server OrderDetails : ", res.data.detail.res_data);
              console.log("Response message from server OrderDetails user_info : ", res.data.detail.res_data.user_info);
              console.log("Response message from server order items list : ", res.data.detail.res_data.dc_list);
              console.log("Length of district array : ", res.data.detail.res_data.dc_list.length);
              //optionsDistrict = res.data.detail.res_data.district_list

              //setOrderInfoDet(res.data.detail.res_data.order_info);
              
              if ( res.data.detail.res_data.dc_list.length > 0 ) {
                //setDeliveryChellan(res.data.detail.res_data.dc_list);
                setDcList(res.data.detail.res_data.dc_list);
                setDcListFull(res.data.detail.res_data.dc_list);
                //setProdList(res.data.detail.res_data.dc_list);
                //setOptDist(res.data.detail.res_data.district_list)
                //setDistrictIdSelect({value: res.data.detail.res_data.district_list[0].value, label: res.data.detail.res_data.district_list[0].label});
              }
              setOrderDcFetched(true);
            }
          }
          catch(error) {
            console.log("FULL ERROR : ", error);
            if ( error.code === 'ERR_NETWORK' ) {
              console.log("Not able to reach target server please try after sometime");
            }
            else{
              console.log("ERROR : ", error.response.data.detail);
              if (error.response.data.detail.status === 'FAILED') {
                console.log('Request failed due to ', error.response.data.detail.res_data);
                //setShowMsg(error.response.data.detail.res_data);
                //setShowMsgTitle('LOGIN FAILED!');
                //setShow(true);
              }
            }
            setOrderDcFetched(true);
          }
}

  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect called ##########################"
      );

      //setProductInfo(product_info);
      //getOrderList();
      
      ( async() => {
        var [retState, customerArr] = await getCustomerListDropdown();
        if ( retState ) {
          setOptCustomer(customerArr);
          setCustomerIdSelect(customerArr[0]);
        }

        var [retStateSellProd, newSellProdArr] = await getProductsSell();
        //countries.splice(0, 0, 'Kenya');
        var newSellProdArr1 = [{value: 0, label: "All Grades"}, ...newSellProdArr];
          if ( retStateSellProd ) {
            console.log("RETURNED SELL PRODUCT LIST : ", newSellProdArr1);
            setOptProduct(newSellProdArr1);
            setProductIdSelect(newSellProdArr1[0]);
          }

          await getAllDCListFetch();

          var [retStateLocList, locationList] = await searchAllUniqLocations();
          console.log("UNIQUE LOCATION FETCH STATE : ", retStateLocList);
          console.log("UNIQUE LOCATION LIST : ", locationList);
          var locationList1 = [{value: 0, label: "All Locations"}, ...locationList];
          if ( retStateLocList) {
            setOptLocations(locationList1);
            setSelectedLocationId(locationList1[0]);
          }
      })();
      
      
      initialized.current = true;
    }
  }, []);

  const handleBack = async (e) => {
    console.log("handle back called : ", e);
    navigate(0);
  };
 
  /*
  const getCustomerListDropdown = async () => {
    console.log("getCustomerListDropdown method called ...");
    var retState = false;
    
    var customerArr = [];
  
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + "/crm/getCustomersCheck", { headers }, { withCredentials: false });
      
      console.log("Response from server getCustomerList : ", res.data.detail.data);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getCustomerList : ", res.data.detail.data);
      
        customerArr = res.data.detail.data;
        var custAll = { value: 0, label: 'All Customers'};
        customerArr.splice(0, 0, custAll);       
  
        console.log("Mod Full VALUE : ", customerArr);
        retState = true;
      }
     
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.data
          );
         
        }
      }
    }
    return [retState, customerArr];
  };
  */

  const onChangeDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);
    var orderListFilter = []
   /*
    if ( e.value === 0 ) {
      setOrderList(orderListFull);
    }
    else {
      productListFull.map((date) => {
        if ( date.order_date === e.value ) {
          orderListFilter.push(date);
        }
      })
      setOrderList(orderListFilter);
    }
    
    */
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };
 

  const handleDateSearch = async() => {
    console.log("handleDateSearch called ...");
    console.log("++++++++++++ START DATE : %s, END DATE : %s >>>>>>>>>>>>>>>>", formValues["start_date"], formValues["end_date"]);

    const start = Date.parse(formValues["start_date"]);
    const end = Date.parse(formValues["end_date"]);
    
    var customerIdToPass = customerIdSelect.value;
    var locationNameToPass = selectedLocationId.label; 
    var startDateToPass = formValues["start_date"];
    var endDateToPass = formValues["end_date"];
    var productIdToPass = productIdSelect.value;
    console.log("CUSTOMER ID SELECTED : ", customerIdSelect.value);
    console.log("LOCATION ID SELECTED : ", selectedLocationId.label);
    console.log("START DATE SELECTED : ", formValues["start_date"]);
    console.log("END DATE SELECTED : ", formValues["end_date"]);
    console.log("PRODUCT ID SELECTED : ", productIdToPass);
    var [retDCSearchState, dcListBySearch, error_msg] = await getDCListBySearch(customerIdToPass, locationNameToPass, startDateToPass, endDateToPass, productIdToPass);
    if ( retDCSearchState ) {
        setDcList(dcListBySearch);
    }
    else {
      console.log("<<<<<<<<<<<<<<<<<<<< SEARCH FAILED >>>>>>>>>>>>>>>>>>>> ");
      setSearchError(true);
      setDcList([]);
    }
    /*
    var dcListFilter = [];
    dcListFull.map((dc) => {
      const d = Date.parse(dc.dc_date);
      if ( d >= start && d <= end ) {
        if ( selectedLocationId.label !== 'All' && customerIdSelect.value !== 0 ) {
          if ( dc.address_line1 === selectedLocationId.label && dc.customer_id === customerIdSelect.value ) {
            dcListFilter.push(dc);
          }
        }
        else if ( selectedLocationId.label === 'All' && customerIdSelect.value !== 0 ) {
          if ( dc.customer_id === customerIdSelect.value ) {
            dcListFilter.push(dc);
          }
        }
        else if ( selectedLocationId.label !== 'All' && customerIdSelect.value === 0 ) {
          if ( dc.address_line1 === selectedLocationId.label ) {
            dcListFilter.push(dc);
          }
        }
        else {
          dcListFilter.push(dc);
        }
      } 
    })
    setDcList(dcListFilter);
    */
  }

const getDCListBySearch = async(customerIdToPass, locationNameToPass, startDateToPass, endDateToPass, productIdToPass) => {
    var retState = false
    var dcListBySearch = [];
    var error_msg = "";
    try {
      var aIVal = localStorage.getItem("_aI");
      var reqParam = {
        params: {
        customer_id: customerIdToPass,
        location_name: locationNameToPass,
        start_date: startDateToPass,
        end_date: endDateToPass,
        product_id: productIdToPass
        }
      };
      console.log("REQUEST PARAM >>>>>>>>>>>>>> : ", reqParam);
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + `/getDCListBySearch?customer_id=${customerIdToPass}&location_name=${locationNameToPass}&start_date=${startDateToPass}&end_date=${endDateToPass}&product_id=${productIdToPass}`, { headers }, { withCredentials: false });
      
      console.log("Response from server getDCListBySearch : ", res.data.detail.res_data);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getDCListBySearch : ", res.data.detail.res_data);
        
        dcListBySearch = res.data.detail.res_data.dc_list;
        retState = true;
      }
      else {
        error_msg = "No Data";
      }
     
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        error_msg = "Unable to fetch data please try again";
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
        }
        error_msg = "No Data";
      }

    }
    return [retState, dcListBySearch, error_msg];
    
  }

  const handleCustomerSelect = async(e, data) => {
    console.log("handleCustomerSelect called ...", e);
    //var dcListFilter = []
    var customerIdToPass = 0;
      var locationNameToPass = ""; 
      var startDateToPass = "";
      var endDateToPass = "";
      var productIdToPass = 0;
      setSelectedLocationId({value: 0, label: "All Locations"});
      locationNameToPass = "All Locations";
    /*
    if ( e.value === 0 ) {
      setSelectedLocationId({value: 0, label: "All Locations"});
      locationNameToPass = "All Locations";
    }
    else {
      locationNameToPass = selectedLocationId.label;
    }
    */
    
      customerIdToPass = e.value;
      startDateToPass = formValues["start_date"];
      endDateToPass = formValues["end_date"];
      productIdToPass = productIdSelect.value;
      console.log("CUSTOMER ID SELECTED : ", e.value);
      console.log("LOCATION ID SELECTED : ", selectedLocationId.label);
      console.log("START DATE SELECTED : ", formValues["start_date"]);
      console.log("END DATE SELECTED : ", formValues["end_date"]);
      console.log("PRODUCT ID SELECTED : ", productIdToPass);
      /*
      var [retDCSearchState, dcListBySearch, error_msg] = await getDCListBySearch(customerIdToPass, locationNameToPass, startDateToPass, endDateToPass, productIdToPass);
      if ( retDCSearchState ) {
        setDcList(dcListBySearch);
      }
      else {
        console.log("<<<<<<<<<<<<<<<<<<<< SEARCH FAILED >>>>>>>>>>>>>>>>>>>> ");
        setSearchError(true);
        setDcList([]);
      }
      */
    
    /*
    if ( e.value === 0 ) {
      //setOrderList(orderListFull);
      //setDcList(dcListFull);
      //setSelectedLocationId({value: 0, label: "All Customers"});
      var customerIdToPass = customerIdSelect.value;
      var locationNameToPass = selectedLocationId.label; 
      var startDateToPass = formValues["start_date"];
      var endDateToPass = formValues["end_date"];
      console.log("CUSTOMER ID SELECTED : ", customerIdSelect.value);
      console.log("LOCATION ID SELECTED : ", selectedLocationId.label);
      console.log("START DATE SELECTED : ", formValues["start_date"]);
      console.log("END DATE SELECTED : ", formValues["end_date"]);
      var [retDCSearchState, dcListBySearch] = await getDCListBySearch(customerIdToPass, locationNameToPass, startDateToPass, endDateToPass);
      if ( retDCSearchState ) {
        setDcList(dcListBySearch);
      }
    }
    else {
      / *
      dcListFull.map((dc) => {
        if ( selectedLocationId.label !== 'All Customers' ) {
          if ( dc.customer_id === e.value && dc.address_line1 === selectedLocationId.label) {
            dcListFilter.push(dc);
          }
        }
        else {
          if ( dc.customer_id === e.value ) {
            dcListFilter.push(dc);
          }
        }
      })
      * /
      var customerIdToPass = e.value;
      var locationNameToPass = selectedLocationId.label; 
      var startDateToPass = formValues["start_date"];
      var endDateToPass = formValues["end_date"];
      console.log("CUSTOMER ID SELECTED 2 : ", customerIdSelect.value);
      console.log("LOCATION ID SELECTED 2 : ", selectedLocationId.label);
      console.log("START DATE SELECTED 2 : ", formValues["start_date"]);
      console.log("END DATE SELECTED 2 : ", formValues["end_date"]);
      var [retDCSearchState, dcListBySearch] = await getDCListBySearch(customerIdToPass, locationNameToPass, startDateToPass, endDateToPass);
      if ( retDCSearchState ) {
        setDcList(dcListBySearch);
      }
    }
    */
    setCustomerIdSelect(e);
  };

  const handleLocationSelect = async(e, data) => {
    console.log("handleLocationSelect called ...", e);

    var customerIdToPass = customerIdSelect.value;
    var locationNameToPass = e.label; 
    var startDateToPass = formValues["start_date"];
    var endDateToPass = formValues["end_date"];
    var productIdToPass = productIdSelect.value;
    console.log("CUSTOMER ID SELECTED : ", customerIdSelect.value);
    console.log("LOCATION ID SELECTED : ", e.label);
    console.log("START DATE SELECTED : ", formValues["start_date"]);
    console.log("END DATE SELECTED : ", formValues["end_date"]);
    console.log("PRODUCT ID SELECTED : ", productIdToPass);
    /*
    var [retDCSearchState, dcListBySearch, error_msg] = await getDCListBySearch(customerIdToPass, locationNameToPass, startDateToPass, endDateToPass, productIdToPass);
    if ( retDCSearchState ) {
        setDcList(dcListBySearch);
    }
    else {
      console.log("<<<<<<<<<<<<<<<<<<<< SEARCH FAILED >>>>>>>>>>>>>>>>>>>> ");
      setSearchError(true);
      setDcList([]);
    }
    */
    /*
    var dcListFilter = []

    if ( e.label === 'All' ) {
      //setCustomerIdSelect({value: 0, label: "All"});
      setDcList(dcListFull);
    }
    else {
      dcListFull.map((dc) => {
        console.log("<<<<<<<<<<<<<< DC INFO IND >>>>>>> : ", dc);
        if ( customerIdSelect.label !== 'All' ) {
          if( dc.address_line1 === e.label && dc.customer_id === customerIdSelect.value ) {
            dcListFilter.push(dc);
          } 
        }
        else { 
          if( dc.address_line1 === e.label ) {
            dcListFilter.push(dc);
          }
        }
      })
      setDcList(dcListFilter);
    }
    */
    setSelectedLocationId(e);
  }

  const handleProductSelect = async(e, data) => {
    console.log("handleProductSelect called ...", e);
    
    //var orderListFilter = []
    var customerIdToPass = customerIdSelect.value;
    var locationNameToPass = selectedLocationId.label; 
    var startDateToPass = formValues["start_date"];
    var endDateToPass = formValues["end_date"];
    var productIdToPass = e.value;

    console.log("CUSTOMER ID SELECTED  at handleProductSelect: ", customerIdSelect.value);
    console.log("LOCATION ID SELECTED at handleProductSelect: ", e.label);
    console.log("START DATE SELECTED at handleProductSelect: ", formValues["start_date"]);
    console.log("END DATE SELECTED at handleProductSelect: ", formValues["end_date"]);
    console.log("PRODUCT ID SELECTED at handleProductSelect: ", productIdToPass);
    /*
    var [retDCSearchState, dcListBySearch, error_msg] = await getDCListBySearch(customerIdToPass, locationNameToPass, startDateToPass, endDateToPass, productIdToPass);
    if ( retDCSearchState ) {
        setDcList(dcListBySearch);
    }
    else {
      console.log("<<<<<<<<<<<<<<<<<<<< SEARCH FAILED >>>>>>>>>>>>>>>>>>>> ");
      setSearchError(true);
      setDcList([]);
    }
    */
    setProductIdSelect(e);
  };
  
  return (
    <Container>
     <SidebarMain />
     { orderDcFetched ? 
     <Form>
        <Row>
        
        <Col xs={1} md={1} sm={1} lg={1}> 
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem" }}>DC List</span>
        </Col>
        
             <Col xs={5} md={5} sm={5} lg={5}>
              <Row>
              <Col>
              <Select
                    inline
                    size="xs"
                    id="customer"
                    name="customer"
                    value={{
                      value: customerIdSelect.value,
                      label: customerIdSelect.label,
                    }}
                    options={optCustomer}
                    defaultValue={{
                      value: customerIdSelect.value,
                      label: customerIdSelect.label,
                    }}
                    onChange={handleCustomerSelect}
                    //isDisabled={isReadOnly}
                  />
                  </Col>
                  
            
             
                
                <Col>
                  <Select
                    inline
                    size="xs"
                    id="location"
                    name="location"
                    value={{
                      value: selectedLocationId.value,
                      label: selectedLocationId.label,
                    }}
                    options={optLocations}
                    defaultValue={{
                      value: selectedLocationId.value,
                      label: selectedLocationId.label,
                    }}
                    onChange={handleLocationSelect}
                    //isDisabled={isReadOnly}
                  />
                  </Col>

                  <Col>
              
              <Select
                    inline
                    size="xs"
                    id="product"
                    name="product"
                    value={{
                      value: productIdSelect.value,
                      label: productIdSelect.label,
                    }}
                    options={optProduct}
                    defaultValue={{
                      value: productIdSelect.value,
                      label: productIdSelect.label,
                    }}
                    onChange={handleProductSelect}
                    //isDisabled={isReadOnly}
                  />
                  </Col>

                  </Row> 
            </Col>
            <Col xs={4} md={4} sm={4} lg={4}> 
            
            <Row>
             
            <Col>
                <Form.Control
                  type="date"
                  size="sm"
                  name="start_date"
                  placeholder="Start Date"
                  value={formValues.start_date}
                  onChange={onChangeDatePicker}
                  //readOnly={isReadOnly}
                  
                />
              
            </Col>
          
            <Col>
                <Form.Control
                  type="date"
                  name="end_date"
                  size="sm"
                  placeholder="End Date"
                  value={formValues.end_date}
                  onChange={onChangeDatePicker}
                  //readOnly={isReadOnly}
                  
                />
                 </Col>
                 
                 <Col>
                 <Button id="search-order-id"
            size={"md"}
            variant="success"
            onClick={(e) => handleDateSearch(e)}>
              Search
            </Button>
                 </Col>
                 </Row>
                 </Col>

                 <Col
            xs={2}
            md={2}
            sm={2}
            lg={2}
            className="d-flex justify-content-end float-end"
          >
            
            <Button variant="warning" className="btn btn-warning btn-md">
                <LuRefreshCw
                  key={`back-btn-receipt-key`}
                  size={24}
                  style={{ cursor: "pointer", color:"white" }}
                  onClick={(e) => handleBack(e)}
                  name={`back-icon-summary-name`}
                  id={`back-icon-summary-id`}
                />
              </Button>
              &nbsp;
           <Button
             variant="warning"
            id="add-btn-id"
            name="add-btn-name"
            className=" float-end"
            onClick={(e) => handleAdd(e)}
            >
              New DC
            </Button> 
          </Col>
        </Row>
        <hr align="center" />
        
        

       
        <Table striped bordered hover size="sm">
          <thead>
            <tr className="table-primary">
              <th className="table-row-heading" key={"order-list-heading-key"}>
                S.No
              </th>
              <th className="table-row-heading">DC No</th>
              <th className="table-row-heading" style={{width: '9%'}}>DC Dt</th>
              <th className="table-row-heading">Cust Name</th>
              <th className="table-row-heading">Site</th>
              <th className="table-row-heading">Grade</th>
              <th className="table-row-heading">Vol(M<sup>3</sup>)</th>
              <th className="table-row-heading">Tot Vol(M<sup>3</sup>)</th>
              <th className="table-row-heading">Unit Price</th>
              <th className="table-row-heading">Prod Total</th>
              {/*<th className="table-row-heading">Extra Charges</th>*/}
              <th className="table-row-heading">Total Price</th>
              <th className="table-row-heading">Details</th>
            </tr>
          </thead>

          <tbody>
            {dcList.map((dcInd, idx) => (
              <tr key={`dc-table-row-key-${idx}`}>
                <td style={{textAlign: "right"}}
                  key={`dc-table-row-key-sno-${idx}`}
                  id={`dc-table-row-id-sno-${idx}`}
                  name={`dc-table-row-name-sno-${idx}`}
                >
                  {idx + 1}
                </td>
                <td style={{textAlign: "right"}}
                  key={`dc-table-row-key-dcno-${idx}`}
                  id={`dc-table-row-id-dcno-${idx}`}
                  name={`dc-table-row-name-dcno-${idx}`}
                >
                  {dcInd.dc_no}
                </td>
                
                <td style={{textAlign: "left"}}
                  key={`dc-table-row-key-date-${idx}`}
                  id={`dc-table-row-id-date-${idx}`}
                  name={`dc-table-row-name-date-${idx}`}
                >
                  {formatDateIndian(new Date(dcInd.dc_date))}
                </td>
                <td style={{textAlign: "left"}}
                  key={`dc-table-row-key-name-${idx}`}
                  id={`dc-table-row-id-name-${idx}`}
                  name={`dc-table-row-name-name-${idx}`}
                >
                  {dcInd.customer_name}
                </td>
                <td
                  style={{ padding: "5px", textAlign: "left" }}
                  key={`dc-table-row-key-addressline1-${idx}`}
                  id={`dc-table-row-id-addressline1-${idx}`}
                  name={`dc-table-row-name-addressline1-${idx}`}
                >
                  {dcInd.address_line1}
                </td>
                <td
                  style={{ padding: "5px", textAlign: "left" }}
                  key={`dc-table-row-key-grade-${idx}`}
                  id={`dc-table-row-id-grade-${idx}`}
                  name={`dc-table-row-name-grade-${idx}`}
                >
                  {dcInd.items[0].product_name}
                </td>
                <td style={{textAlign: "right"}}
                  key={`dc-table-row-key-volume-${idx}`}
                  id={`dc-table-row-id-volume-${idx}`}
                  name={`dc-table-row-name-volume-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(dcInd.items[0].quantity).toFixed(2)))}
                </td>                
                <td style={{textAlign: "right"}}
                  key={`dc-table-row-key-totalvolume-${idx}`}
                  id={`dc-table-row-id-totalvolume-${idx}`}
                  name={`dc-table-row-name-totalvolume-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(dcInd.items[0].total_quantity).toFixed(2)))}
                </td>                
                <td 
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`dc-table-row-key-unitprice-${idx}`}
                  id={`dc-table-row-id-unitprice-${idx}`}
                  name={`dc-table-row-name-unitprice-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(dcInd.items[0].unit_price).toFixed(2)))}
                </td>
                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`dc-table-row-key-product_total-${idx}`}
                  id={`dc-table-row-id-product_total-${idx}`}
                  name={`dc-table-row-name-product_total-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(dcInd.amount_basic).toFixed(2)))}
                </td>
                {/*
                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`dc-table-row-key-pumpingcharges-${idx}`}
                  id={`dc-table-row-id-pumpingcharges-${idx}`}
                  name={`dc-table-row-name-pumpingcharges-${idx}`}
                >
                  {(dcInd.pumping_charges + dcInd.diesel_charges + dcInd.others_charges1).toFixed(2)}
                </td>
                */}
                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`dc-table-row-key-total-${idx}`}
                  id={`dc-table-row-id-total-${idx}`}
                  name={`dc-table-row-name-total-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(dcInd.amount_total).toFixed(2)))}
                </td>
                
                <td style={{textAlign: "center"}}
                  key={`dc-table-row-key-actions-${idx}`}
                  id={`dc-table-row-id-actions-${idx}`}
                  name={`dc-table-row-name-actions-${idx}`}
                >
                  <BsCardText
                    size={28}
                    style={{ color: "#0d6efd", cursor: "pointer" }}
                    onClick={(e) => handleEdit(e)}
                    name={`dc-view-icon-${idx}`}
                    id={`dc-view-icon-${idx}`}
                  />
                  &nbsp;&nbsp;
                   {/*
                  <Button
                    variant="light"
                    size="sm"
                    onClick={(e) => handleView(e)}
                    name={`dc-edit-icon-${idx}`}
                    id={`dc-edit-icon-${idx}`}
                  >
                   
                    <img
                      src={rmcBlueIcon}
                      alt="teeth icon"
                      width={"28px"}
                      height={"28px"}
                    />
                   
                  </Button>
                  */}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        
        </Form>
      : 
      <div className="container-spinner">
     <div className="vertical-center-spinner">
      <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
      </div>
      </div>
  }
    </Container>
  );
};
//const Dashboard = withRouter(Dash);
export default ListDCs;
