import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#0d0d0d'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: borderColor,
        borderBottomWidth: 1,
        alignItems: 'center',
        //height: 24,
        fontStyle: 'bold',
    },
    sno: {
        width: '10%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    description: {
        width: '20%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    other_desc: {
        width: '30%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 5,
        textAlign: 'right'
    },
    qty: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    qty_desc: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    rate: {
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    amount: {
        width: '20%',
        textAlign: 'right',
        paddingRight: 8,
    },
});


const InvoiceTableRow = (props) => {
    console.log("Items Full : ", props);
    const rows = props.items.map((item, idx) =>
        <View style={styles.row} key="items">
            <Text style={styles.sno}>{idx + 1}</Text>
            <Text style={styles.description}>{item.product_name}</Text>
            <Text style={styles.qty}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.quantity).toFixed(2)))} CUM</Text>
            <Text style={styles.qty}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.total_quantity).toFixed(2)))} CUM</Text>
            <Text style={styles.rate}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.unit_price).toFixed(2)))}</Text>
            <Text style={styles.amount}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format((parseFloat(item.total_quantity) * parseFloat(item.unit_price)).toFixed(2)))}</Text>
        </View>
    )

    const dieselCharges = (
        <View style={styles.row} key="items">
            <Text style={styles.sno}></Text>
            <Text style={styles.other_desc }>Diesel Charges</Text>
            <Text style={styles.qty_desc}></Text>
            <Text style={styles.qty_desc}></Text>
            <Text style={styles.rate}></Text>
            <Text style={styles.amount}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(props.invoice.dc_info.diesel_charges).toFixed(2)))}</Text>
        </View>
    );

    const pumpingCharges = (
        <View style={styles.row} key="items">
            <Text style={styles.sno}></Text>
            <Text style={styles.other_desc}>Pumping Charges</Text>
            <Text style={styles.qty_desc}></Text>
            <Text style={styles.qty_desc}></Text>
            <Text style={styles.rate}></Text>
            <Text style={styles.amount}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(props.invoice.dc_info.pumping_charges).toFixed(2)))}</Text>
        </View>
    );

    return (<Fragment>{rows}{dieselCharges}{pumpingCharges}</Fragment>)
}

export default InvoiceTableRow