import React, { Fragment } from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#a8a8a8'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        //borderBottomColor: '#a8a8a8',
        //borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: 12,
        fontStyle: 'bold',
    },
    amount_words: {
        //width: '85%',
        height: 24,
        textAlign: 'right',
        //borderRightColor: borderColor,
        //borderRightWidth: 1,
        paddingRight: 8,
    },
    description: {
        //width: '85%',
        height: 24,
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
    },
    total: {
        //width: '15%',
        textAlign: 'right',
        //paddingRight: 8,
    },
  });

 
const OrderTableFooter = (props) => {
    console.log("Props at InvoiceTableFooter : ", props);
    let total = 0.00;
    //props.items.map(item => item.prodInfo.quantity * item.prodInfo.unit_price).reduce((accumulator, currentValue) => accumulator + currentValue , 0)
    
    props.items.map((item) => {
            console.log("++++++++++++++ INDIVIDUAL PRODUCT ITEM VALUE : ", item);
            if ( item.prodInfo.product_type === 'PRODUCT') {
                total = total + (item.prodInfo.quantity * item.prodInfo.unit_price);
            }
            else {
                total = total + item.prodInfo.total_item_price;
            }
            console.log("total prodcut amount in each iterate at InvoiceTableFooter: ", total);
        });
        
    console.log("Total price at InvoiceTableFooter: ", total);


    let cgst_amount = 0.00;
    if (props.invoice.order_info.bill_gst_include === true ) {
        props.items.map((item) => {
        if ( item.prodInfo.product_type === 'PRODUCT') {
            cgst_amount = cgst_amount + (item.prodInfo.quantity * item.prodInfo.unit_price * (item.prodInfo.cgst_perc / 100));
        }
        else {
            cgst_amount = cgst_amount + (item.prodInfo.total_item_price * (item.prodInfo.cgst_perc / 100));
        }
    })
    }
    console.log("CGST AMOUNT at OrderTableFooter.js : ", cgst_amount);

    let sgst_amount = 0.00;
    if (props.invoice.order_info.bill_gst_include === true ) {
        props.items.map((item) => {
        if ( item.prodInfo.product_type === 'PRODUCT') {
            sgst_amount = sgst_amount + (item.prodInfo.quantity * item.prodInfo.unit_price * (item.prodInfo.cgst_perc / 100));
        }
        else {
            sgst_amount = sgst_amount + (item.prodInfo.total_item_price * (item.prodInfo.sgst_perc / 100));
        }
    })
    }
    console.log("SGST AMOUNT at OrderTableFooter.js : ", sgst_amount);

    let igst_amount = 0.00;
    if (props.invoice.order_info.bill_gst_include === true ) {
        props.items.map((item) => {
        if ( item.prodInfo.product_type === 'PRODUCT') {
            igst_amount = igst_amount + (item.prodInfo.quantity * item.prodInfo.unit_price * (item.prodInfo.igst_perc / 100));
        }
        else {
            igst_amount = igst_amount + (item.prodInfo.total_item_price * (item.prodInfo.igst_perc / 100));
        }
    })
    }
    console.log("IGST AMOUNT at OrderTableFooter.js : ", igst_amount);

    let total_gst = cgst_amount + sgst_amount + igst_amount;
    
    total = total + props.invoice.order_info.diesel_charges + props.invoice.order_info.pumping_charges + total_gst;

    //console.log(NumToWord(total));
    //var total = 7793.34;
    //var testTotal = total.toLocaleString('en-IN', {maximumFractionDigits: 2, currency: 'INR'});
    var total_tmp = new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(Math.round(total).toFixed(2)))
    // Math.round(total).toLocaleString('en-IN', {maximumFractionDigits: 2, currency: 'INR'})
    console.log("TOTAL IN COMMA SEPERATED : ", total_tmp);

    return(
        <Fragment>
            <View style={styles.row}>
                <Text style={{ ...styles.amount_words, width: '60%'}}></Text>
            <Text style={{...styles.description, fontWeight: 'bold', fontFamily: 'Helvetica-Bold', width: '25%', textAlign: 'right', paddingTop: '5px', paddingRight: '8px'}}>Net Amount</Text>
            <Text style={{...styles.total, fontWeight: 'bold', fontFamily: 'Helvetica-Bold', width: '15%', textAlign: 'right', paddingTop: '5px', paddingRight: '4px'}}>{total_tmp}</Text>
            </View>
        </Fragment>
    )
};
  
  export default OrderTableFooter