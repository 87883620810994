import React, { useState, useEffect, useRef, Fragment } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    Form,
    Button,
    Table,
    Spinner,
    Image
} from "react-bootstrap";
import { withRouter } from "react-router";
//import Sidebar from './Sidebar';
import Sidebar2 from "./Sidebar2";
import Footer from "./Footer";
//import Topmenu from "./Topmenu";
import OrderDetails from "./OrderDetails";
import "../Dashboard.css";

//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";

import { useNavigate } from "react-router-dom";

import * as Icon from "react-bootstrap-icons";
import axios from "axios";
import { client } from "../config/Config";
import Select from "react-select";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';



import {
    BsFillTrashFill,
    BsPencilSquare,
    BsFileRuled,
    BsArrowLeftCircle,
    BsCardText,
    BsReceipt 
} from "react-icons/bs";

import { AiOutlineOrderedList } from "react-icons/ai";

import MenuOms from "./MenuOms";

import rmcBlueIcon from "../assets/images/ready_mix_blue.png";
import SidebarMain from "./SidebarMain";

import { getDistrictList, getStateList, getProductApiCall, getProductBuy, getProductsSell, formatDate } from "./utils";

const ListReceipts = (props) => {
    const initialized = useRef(false);
    const [toRender, setToRender] = useState(false);
    const [optCustomer, setOptCustomer] = useState([]);
    const [customerIdSelect, setCustomerIdSelect] = useState({});
    const [receiptList, setReceiptList] = useState([]);
    const [receiptListFull, setReceiptListFull] = useState([]);
    const navigate = useNavigate();

    const getCustomerListDropdown = async () => {
        console.log("getCustomerListDropdown method called ...");
        var retState = false;
        
        try {
          var aIVal = localStorage.getItem("_aI");
          var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
          var res = await axios.get(
            client.domain + "/crm/getAllCustomers",
            { headers },
            { withCredentials: false }
          );
          
          console.log(
            "Response from server getCustomerList : ",
            res.data.detail.data
          );
          if (res.data.detail.status === "SUCCESS") {
            console.log(
              "Response message from server getCustomerList : ",
              res.data.detail.data
              
            );
            
            var customerArr = [];
            var custAll = { value: 0, label: 'All'};
            customerArr.push(custAll);
            res.data.detail.data.map((itemCustomer, idx) => {
              console.log("Ind val : ", itemCustomer);
              var customerInd = {
                value: itemCustomer.id,
                label: itemCustomer.name,
              };
              console.log("In customer modified : ", customerInd);
              customerArr.push(customerInd);
            });
    
            console.log("Mod Full VALUE : ", customerArr);
            retState = true;
          }
         
        } catch (error) {
          console.log("FULL ERROR : ", error);
          if (error.code === "ERR_NETWORK") {
            console.log(
              "Not able to reach target server please try after sometime"
            );
          } else {
            console.log("ERROR : ", error.response.data.detail);
            if (error.response.data.detail.status === "FAILED") {
              console.log(
                "Request failed due to ",
                error.response.data.detail.data
              );
             
            }
          }
        }
        return [retState, customerArr];
      };


      const getReceiptList = async() => {
        try {
          var aIVal = localStorage.getItem("_aI");
          console.log("Authorization token : ", aIVal);
    
          var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
    
          console.log("Headers : ", headers);
    
          
          var res = await axios.get(client.domain + "/getAllReceipts", {headers}, {
            withCredentials: false,
          });
          //.then((res) => {
          //console.log("ADD New user res:", res);
          //localStorage.setItem('_token', res.data.res_data.token);
          console.log("Response from server getReceiptList : ", res.data.detail);
          if (res.data.detail.status === "SUCCESS") {
            console.log("Response message from server getReceiptList : ", res.data.detail.data);
           if (res.data.detail.data.length > 0) {
              setReceiptList(res.data.detail.data);
              setReceiptListFull(res.data.detail.data);
            }
            setToRender(true);
          }
          
        } catch (error) {
          console.log("FULL ERROR : ", error);
          if (error.code === "ERR_NETWORK") {
            console.log("Not able to reach target server please try after sometime");
        setToRender(true);
          } else {
            console.log("ERROR : ", error.response.data.detail);
            if (error.response.data.detail.status === "FAILED") {
              console.log("Request failed due to ",error.response.data.detail.res_data);
            }
          }
        }
        setToRender(true);
      };

      const handleViewReceipt = async(e) => {
        console.log("handleViewReceipt called :", e);
      }

    useEffect(() => {
        if (!initialized.current) {
            console.log("*************** useEffect called ##########################");

            (async () => {
                await getReceiptList();
                var [retState, customerArr] = await getCustomerListDropdown();
                if ( retState ) {
                    setOptCustomer(customerArr);
                    setCustomerIdSelect(customerArr[0]);
                }
                setToRender(true);
            })();

            initialized.current = true;
        }
    }, []);



    const intialValues = {
        start_date: formatDate(new Date()),
        end_date: formatDate(new Date())
    };

    const [formValues, setFormValues] = useState(intialValues);

    const handleCustomerSelect = async (e) => {
        console.log("Handle customer select called : ", e);
    }

    const onChangeDatePicker = async (e) => {
        console.log("onchangeDatePicker called : ", e);
    }

    const handleDateSearch = async (e) => {
        console.log("handleDateSearch called : ", e);
    }

    const handleReceiptAdd = async (e) => {
        console.log("handleClickAddOrder called : ", e);

        navigate(`/addUpdateReceipt`, {
            state: { passed_element: [], action: "add" },
          });
    }

    const handleReceiptView = async(e) => {
      console.log("handleReceiptView called : ", e);
      console.log("Handle action edit Clicked target : ", e.currentTarget);

      var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(
        0,
        clickedTagId.lastIndexOf("-")
      );
      console.log("current selected element name : ", nameOfElement);
      console.log("Current SELECTED id : ", curSelId);
      console.log("CUR SELECTED VALUE : ", receiptList[curSelId]);

      navigate(`/viewReceipt`, {
        state: { passed_element: receiptList[curSelId], action: "edit" },
      });
    }
  }

    return (
        <Container>
            <SidebarMain />
            {toRender ?
                <form>
                    <Row>

                        <Col xs={3} md={3} sm={3} lg={3}>
                            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem" }}>Receipts</span>
                        </Col>
                        <Col xs={2} md={2} sm={2} lg={2}>
                            <Row>
                                <Col>
                                    <Select
                                        inline
                                        id="customer"
                                        name="customer"
                                        value={{
                                            value: customerIdSelect.value,
                                            label: customerIdSelect.label,
                                        }}
                                        options={optCustomer}
                                        defaultValue={{
                                            value: customerIdSelect.value,
                                            label: customerIdSelect.label,
                                        }}
                                        onChange={handleCustomerSelect}
                                    />
                                </Col>

                            </Row>
                        </Col>

                        <Col xs={5} md={5} sm={5} lg={5}>
                            {/*<span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1rem",verticalAlign:"bottom" }}>StartDate:</span>*/}
                            <Row>

                                <Col>
                                    <Form.Control
                                        type="date"
                                        name="start_date"
                                        placeholder="Start Date"
                                        value={formValues.start_date}
                                        onChange={onChangeDatePicker}
                                    //readOnly={isReadOnly}

                                    />

                                </Col>

                                <Col>
                                    <Form.Control
                                        type="date"
                                        name="end_date"
                                        placeholder="End Date"
                                        value={formValues.end_date}
                                        onChange={onChangeDatePicker}
                                    //readOnly={isReadOnly}

                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col
                            xs={1}
                            md={1}
                            sm={1}
                            lg={1}
                            className="d-flex justify-content-end float-end"
                        >
                            <Button id="search-order-id"
                                size={"sm"}
                                variant="success"
                                onClick={(e) => handleDateSearch(e)}>
                                Search
                            </Button>

                        </Col>
                        <Col
                            xs={1}
                            md={1}
                            sm={1}
                            lg={1}
                            className="d-flex justify-content-end float-end"
                        >

                            <Button id="add-order-id"
                                size={"sm"}
                                variant="warning"
                                onClick={(e) => handleReceiptAdd(e)}>
                                New Order
                            </Button>
                        </Col>
                    </Row>

                    <hr align="center" />

                    <Table striped bordered hover size="sm">
          <thead>
            <tr className="table-primary">
              <th className="table-row-heading" key={"order-list-heading-key"}>
                S.No
              </th>
              <th className="table-row-heading">Receipt Number</th>
              <th className="table-row-heading">Receipt Date</th>
              <th className="table-row-heading">Received Mode</th>
              <th className="table-row-heading">Received From</th>
              <th className="table-row-heading">Amount</th>
              <th className="table-row-heading">Towards</th>
              <th className="table-row-heading">...</th>
            </tr>
          </thead>

          <tbody>
            {receiptList.map((receiptInd, idx) => (
              <tr key={`receipt-table-row-key-${idx}`}>
                
                <td
                  key={`receipt-table-row-key-sno-${idx}`}
                  id={`receipt-table-row-id-sno-${idx}`}
                  name={`receipt-table-row-name-sno-${idx}`}
                >
                  {idx + 1}
                </td>

                <td
                  key={`receipt-table-row-key-ono-${idx}`}
                  id={`receipt-table-row-id-ono-${idx}`}
                  name={`rceipt-table-row-name-ono-${idx}`}
                >
                  {receiptInd.receipt_number}
                </td>

                <td
                  key={`receipt-table-row-key-date-${idx}`}
                  id={`receipt-table-row-id-date-${idx}`}
                  name={`rceipt-table-row-name-date-${idx}`}
                >
                  {receiptInd.receipt_date}
                </td>

                <td
                  key={`receipt-table-row-key-mode-${idx}`}
                  id={`receipt-table-row-id-mode-${idx}`}
                  name={`rceipt-table-row-name-mode-${idx}`}
                >
                  {receiptInd.received_mode}
                </td>

                <td
                  key={`receipt-table-row-key-from-${idx}`}
                  id={`receipt-table-row-id-from-${idx}`}
                  name={`rceipt-table-row-name-from-${idx}`}
                >
                  {receiptInd.customer_name}
                </td>

                <td
                  key={`receipt-table-row-key-amount-${idx}`}
                  id={`receipt-table-row-id-amount-${idx}`}
                  name={`rceipt-table-row-name-amount-${idx}`}
                >
                  {receiptInd.amount}
                </td>

                <td
                  key={`receipt-table-row-key-towards-${idx}`}
                  id={`receipt-table-row-id-towards-${idx}`}
                  name={`rceipt-table-row-name-towards-${idx}`}
                >
                  {receiptInd.towards}
                </td>

                <td
                  key={`order-table-row-key-actions-${idx}`}
                  id={`order-table-row-id-actions-${idx}`}
                  name={`order-table-row-name-actions-${idx}`}
                >
                  <Button
                    variant="light"
                    size="sm"
                    onClick={(e) => handleReceiptView(e)}
                    name={`order-edit-icon-${idx}`}
                    id={`order-edit-icon-${idx}`}
                  >
                    <BsReceipt
                    size={28}
                    style={{ color: "#0d6efd", cursor: "pointer" }}
                    name={`order-view-icon-${idx}`}
                    id={`order-view-icon-${idx}`}
                    />
                  </Button>
                </td>
                </tr>
            ))}
            </tbody>
            </Table>
                
                </form>
                : 
                <div className="container-spinner">
               <div className="vertical-center-spinner">
                {/*<Button variant="primary" disabled>*/}
                <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
                   <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{ width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
                       <span className="visually-hidden">Loading...</span>
                     </Spinner>
          
                {/*</Button>*/}
                </div>
                </div>
          }
                
                
        </Container>
    );

}

export default ListReceipts;