import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    headerContainer: {
        //marginTop: 12,
        borderBottomColor: '#bff0fd',
        marginLeft: '40%',
        flexDirection: 'column', 
        backgroundColor: '#FFFFFF', 
        margin: 10, 
        borderTop: 3, 
        borderBottom: 3, 
        borderLeft: 3, 
        borderRight: 3, 
        borderColor: '#22618F', 
        BorderStyle: 'solid',
    },
    billTo: {
        //marginTop: 24,
        paddingBottom: 3,
        //fontFamily: 'Helvetica-Oblique'
        fontFamily: 'Helvetica-Bold'
    },
    right3: {
      padding: 5,
      // width: '66%', //<- working alternative
      flexShrink: 1,
      flexGrow: 2,
      margin: 2, 
      borderTop: 1, 
      borderBottom: 1, 
      borderLeft: 1, 
      borderRight: 1, 
      borderColor: '#a8a8a8', 
      BorderStyle: 'double',
      height: '120px',
      borderRadius: 5,
      //position: 'absolute', 
      //right: 10
    },
    right4: {
      padding: 5,
      // width: '66%', //<- working alternative
      flexShrink: 1,
      flexGrow: 2,
      margin: 2, 
      borderTop: 1, 
      borderBottom: 1, 
      borderLeft: 1, 
      borderRight: 1, 
      borderColor: '#a8a8a8', 
      BorderStyle: 'double',
      height: '120px',
      borderRadius: 5,
      position: 'absolute',
    },
  });

  /*
  function split(str, maxWidth) {
    const newLineStr = "\n";
    var done = false;
    var res = '';
    var found = false;
    do {
      found = false;
      // Inserts new line at first whitespace of the line
      for (var i = maxWidth - 1; i >= 0; i--) {
        / *
        if (testWhite(str.charAt(i))) {
          res = res + [str.slice(0, i), newLineStr].join('{"\n"}');
          str = str.slice(i + 1);
          //found = true;
          break;
        }
        * /
      }
      // Inserts new line at maxWidth position, the word is too long to wrap
      if (!found) {
        res += [str.slice(0, maxWidth), newLineStr].join('');
        str = str.slice(maxWidth);
      }
  
      if (str.length < maxWidth)
        done = true;
    } while (!done);
  
    return res + str;
  }
  */

  function testWhite(x) {
    const white = new RegExp(/^\s$/);
    return white.test(x.charAt(0));
  };

  function splitString (n,str){
    let arr = str?.split(' ');
    let result=[]
    let subStr=arr[0]
    for(let i = 1; i < arr.length; i++){
        let word = arr[i]
        if(subStr.length + word.length + 1 <= n){
            subStr = subStr + ' ' + word
        }
        else{
            result.push(subStr);
            subStr = word
        }
    }
    if(subStr.length){result.push(subStr)}
    return result
}

  //var sa = chunkString(amountInWords, 60);
  //var sa = amountInWords.split(/(.{30,}?)(?:,|$)/g).filter(Boolean)

  
  

  //const BillTo = ({invoice}) => {
  const OrderBillTo = (props) => {
    console.log("PROPS DC LOAD at BillTo : ", props.orderLoad);
    var newAddr1 = props.orderLoad.address_info.billing_address.address_line1;
    console.log("ORIGINAL ADDRESS LINE 1 : ", newAddr1);
    //var addr1Mod = split(newAddr1, 35);
    var addr1Mod = splitString(35, newAddr1);
    console.log("AFTER SPLITTED : ", addr1Mod);
    //console.log("NEW MOD TEXT : ", addr1Mod);

    //var addr1Mod = splitString(25, newAddr1);

    //var newAddr2 = props.orderLoad.address_info.billing_address.address_line2 + "-" + props.orderLoad.address_info.billing_address.pincode;
    var newAddr2 =  props.orderLoad.address_info.billing_address.address_line2 ? `${props.orderLoad.address_info.billing_address.address_line2} - ${props.orderLoad.address_info.billing_address.pincode}` : `${props.orderLoad.address_info.billing_address.pincode}` 
    console.log("ORIGINAL ADDRESS LINE 2 : ", newAddr2);
    var addr2Mod = splitString(35, newAddr2);
    //console.log("NEW MOD TEXT : ", addr1Mod);

    //var addr2Mod = splitString(20, newAddr2);

    var newAddr3 = props.orderLoad.address_info.billing_address.district_name + "," + props.orderLoad.address_info.billing_address.state_name;
    console.log("ORIGINAL ADDRESS LINE 3 : ", newAddr3);
    var addr3Mod = splitString(35, newAddr3);

  return (
    <View style={styles.right3}>
        <Text style={{ ...styles.billTo, fontSize: "12px"}}>BILLED TO PARTY:</Text>
        <Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica', fontSize: "11px"}}>{props.orderLoad.order_info.customer_name}</Text>
        <Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica', fontSize: "11px"}}>{props.orderLoad.address_info.billing_address.contact_person}</Text>
        {/*<Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica', fontSize: "11px"}}>{addr1Mod}</Text>*/}
        {addr1Mod.map((addr1, idx) => (
          <Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica', fontSize: "11px"}}>{addr1}</Text>
        ))
        }
        {addr2Mod.map((addr2, idx) => (
          <Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica', fontSize: "11px"}}>{addr2}</Text>
        ))
        }
        {addr3Mod.map((addr3, idx) => (
          <Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica', fontSize: "11px"}}>{addr3}</Text>
        ))
        }
        <Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica', fontSize: "11px"}}><Text style={{fontSize: "12px"}}>Mobile:</Text> {props.orderLoad.address_info.billing_address.contact_number}</Text>
        <Text style={{fontSize: "12px"}}><Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold', fontSize: "12px"}}>GSTIN : </Text>{props.orderLoad.order_info.gst_no}</Text>
        <Text style={{fontSize: "12px"}}><Text inline style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold', fontSize: "12px"}}>State :</Text> TAMIL NADU &nbsp;&nbsp; &nbsp;
        <Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold', fontSize: "12px"}}>State Code : </Text>{props.orderLoad.company_info.gst_no.substring(0, 2)}</Text>
        {/*<View style={styles.right4}>*/}
        {/*
        <Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica' }}>
          hfku  hfjksdhfjsfhjshfkjsh fjk fhsj hfsjkfh sjkf {"\n"} hi7ryr87yreiwyiweyfjhsdjkh fsj hfsj hfsjk hfkjs fhskjfhkj fh sdjk {"\n"}fhdsa kfhsdkj fhsdakjfhsjkdfhsdakfhsdkjfh kshfdshf sadjhfhsd huds
        </Text>
        */}
        {/*</View>*/}
        
        {/*
        <View style={{ position: 'absolute', right: 20 }}>
          <Text style={{ textAlign: 'right' }}>
               Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam faucibus at lacus nec facilisis. 
               Pellentesque eu nulla elit. In placerat mi sed suscipit feugiat. Aliquam at egestas nulla. Fusce 
               ornare massa a finibus faucibus.
          </Text>
        </View>
        */}
    </View>
  );
  }
  export default OrderBillTo
