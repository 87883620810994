import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  FormControl,
  Spinner,
  Modal,
  Image
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
import Sidebar from "./Sidebar";
import Sidebar2 from "./Sidebar2";
import Topmenu from "./Topmenu";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";

import { useNavigate, useLocation } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client, ConfigMinValue, configMaxValue, appGlobal} from "../config/Config";
import axios from "axios";

import Footer from "./Footer";

import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "bootstrap/dist/css/bootstrap.min.css";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';

import {
  BsCartX,
  BsArrowLeftCircle,
  BsFillPenFill,
  Bs3Circle,
  BsFillXCircleFill,
  BsPencilSquare,
} from "react-icons/bs";
import SidebarMain from "./SidebarMain";
import {getDistrictList, getStateList, getProductApiCall, getProductBuy, getProductsSell } from "./utils";

const AddUpdateOrder = (props) => {
  const initialized = useRef(false);
  const navigate = useNavigate();
  const { state } = useLocation();

  const [passedValue, setPassedValue] = useState(state.passed_element);
  const [submitAction, setSubmitAction] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertPurpose, setAlertPurpose] = useState('success');
  const [alertDialogTitle, setAlertDialogTitle] = useState('SUCCESSS');
  const [alertDialogBody, setAlertDialogBody] = useState('Order Saved Successfully');
  /*
  let initialItems = [
    {id:1, product: {value:3, label:"M15"}, price: "4900", qty: 5, "product_cost": "24500", "gst": "4410", "total_cost":"28910", gst:{cgst: 2205.00, sgst:2205.00, igst:0.00, totGst: 4410.00}}, 
    {id:1, product: {value:6, label:"M25"}, price: "4800", qty: 7, "product_cost": "215001", "gst": "38701", "total_cost":"253701", gst:{cgst: 2205.00, sgst:2205.00, igst:0.00, totGst: 4410}}
  ]
  */
  let initialItems = [];

  /*
  const options = [
    { value: 1, label: 'M5', stock_id: 1 },
    { value: 2, label: 'M7.5', stock_id: 13 },
    { value: 6, label: 'M10', stock_id: 14 },
    { value: 7, label: 'M15', stock_id: 15 },
    { value: 8, label: 'M20', stock_id: 16 },
    { value: 9, label: 'M25', stock_id: 17 },
    { value: 10, label: 'M30', stock_id: 18 },
    { value: 13, label: 'M30', stock_id: 19 },
  ];
  */

  /*
  let product_list = [
    {
      value: 1,
      label: "M5",
      product_id: 1,
      stock_id: 1,
      product_name: "M5",
      unit_cost: 3900,
      gst: { cgst: 9.0, sgst: 9.0, igst: 0.0 },
    },
    {
      value: 2,
      label: "M7.5",
      product_id: 2,
      stock_id: 13,
      product_name: "M7.5",
      unit_cost: 3900,
      gst: { cgst: 9.0, sgst: 9.0, igst: 0.0 },
    },
    {
      value: 6,
      label: "M10",
      product_id: 6,
      stock_id: 14,
      product_name: "M10",
      unit_cost: 4200,
      gst: { cgst: 9.0, sgst: 9.0, igst: 0.0 },
    },
    {
      value: 7,
      label: "M15",
      product_id: 7,
      stock_id: 15,
      product_name: "M15",
      unit_cost: 4600,
      gst: { cgst: 9.0, sgst: 9.0, igst: 0.0 },
    },
    {
      value: 8,
      label: "M20",
      product_id: 8,
      stock_id: 16,
      product_name: "M20",
      unit_cost: 5000,
      gst: { cgst: 9.0, sgst: 9.0, igst: 0.0 },
    },
    {
      value: 9,
      label: "M25",
      product_id: 9,
      stock_id: 17,
      product_name: "M25",
      unit_cost: 5400,
      gst: { cgst: 9.0, sgst: 9.0, igst: 0.0 },
    },
    {
      value: 10,
      label: "M30",
      product_id: 10,
      stock_id: 18,
      product_name: "M30",
      unit_cost: 5800,
      gst: { cgst: 9.0, sgst: 9.0, igst: 0.0 },
    },
    {
      value: 13,
      label: "M40",
      product_id: 13,
      stock_id: 19,
      product_name: "M40",
      unit_cost: 6000,
      gst: { cgst: 9.0, sgst: 9.0, igst: 0.0 },
    },
  ];
*/
  /*
  const optionsDistrict = [
    { value: 1, label: "Coimbatore" },
    { value: 2, label: "Tiruppur" },
    { value: 3, label: "Erode" },
    { value: 4, label: "Karur" },
    { value: 5, label: "Namakkal" },
    { value: 6, label: "Ooty" },
    { value: 7, label: "Salem" },
  ];
  */
  /*
  const optionsState = [
    { value: 1, label: "Tamil Nadu" },
    { value: 2, label: "Kerala" },
    { value: 3, label: "Karanataka" },
    { value: 4, label: "Andra Pradesh" },
    { value: 5, label: "Telungana" },
    { value: 6, label: "Pondichery" },
  ];
  */

  const [optionsState, setOptionsState] = useState([]);
  const handleStateSelect = async (e) => {
    console.log("handleStateSelect called ...", e);
    setStateIdSelect(e);

    // var stateIdInput = e.value;
    //var stateNameInput = e.label;
    var [retStateDist, distArrRet] = await getDistrictList(e.value);
    if (retStateDist) {
      setOptDist(distArrRet);
      setDistrictIdSelect(distArrRet[0]);
    }
  };

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  //const intialValues = {company:"", address: "", city:"", zip:"", gst_number: "", phone: "", district: "", state: "", product:{value:"m15", label:"M15"}};
  const intialValues = {
    customer_name: "",
    customer_disp_name: "",
	  order_date: formatDate(new Date()),  
    gst_number: "",
    phone: "",
	  address1: "",
    address2: "",
    pincode: "",
	  district_id: 1,
    state_id: 1,
	  contact_person: "",
    contact_number: "",
	  email: "",
    website: "",
    product: { value: "", label: "" },
  
    shipContactName: "",
    shipContactNumber: "",
    shipAddress1: "",
    shipAddress2: "",
    shipPincode: "",

    billContactName: "",
    billContactNumber: "",
    billAddress1: "",
    billAddress2: "",
    billPincode: "",

    
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [productMaster, setProductMaster] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [stateIdSelect, setStateIdSelect] = useState({});
  const [toRender, setToRender] = useState(false);

  const [districtIdSelect, setDistrictIdSelect] = useState({});

  const [billDistrictIdSelect, setBillDistrictIdSelect] = useState({});

  const [optDist, setOptDist] = useState([]);

  const [optionsSearch, setOptionsSearch] = useState([]);

  const [customerSearchText, setCustomerSearchText] = useState("");

  const [searchedCustomer, setSearchedCustomer] = useState({});

  const [orderAddress, setOrderAddress] = useState({});
  const [orderInfoDet, setOrderInfoDet] = useState({});

  const [addressFetched, setAddressFetched] = useState(false);
  const [orderDetFetched, setOrderDetFetched] = useState(false);
  const [orderItemFetched, setOrderItemFetched] = useState(false);
  const [orderDcFetched, setOrderDcFetched] = useState(false);
  const [productList, setProductList] = useState();
  const [editAction, setEditAction] = useState(false);


  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("");
  const [msgType, setMsgType] = useState("success");

  var validateErr = false;

  var prodListLoad = [
    //{prod_id: 1, product_name: "M-10", hsncode:"HSN778328", unit_price:4300.00, quantity:10, total_item_price: 43000.00, gst: 7740.00, total_price:50740.00},
    //{prod_id: 1, product_name: "M-20", hsncode:"HSN778329", unit_price:4700.00, quantity:10, total_item_price: 47000.00, gst: 8460.00, total_price:55460.00},
    //{prod_id: 1, product_name: "M-30", hsncode:"HSN778378", unit_price:4900.00, quantity:10, total_item_price: 49000.00, gst: 8820.00, total_price:57820.00}
  ];
  var dcLoad = [
    //{dc_id: 12, dc_number:"DC102091Z1", dc_date:"2023-08-01", grade_id:1, grade_name:"M-20", loading_time:"2023-08-01", vehicle_number:"TN 39 DX 9927", total_volume:"18 cum", volume: "10 cum", total_amount:4200, driver_name:"Azhagu Raja"},
    //{dc_id: 11, dc_number:"DC10209879", dc_date:"2023-07-31", grade_id:2, grade_name:"M-15", loading_time:"2023-07-31", vehicle_number:"TN 39 DY 1254", total_volume:"28 cum", volume: "18 cum", total_amount:42000, driver_name:"Mahesh C"}
  ];

  const [deliveryChellan, setDeliveryChellan] = useState(dcLoad);
  const [prodList, setProdList] = useState(prodListLoad);

  const [gstBillStatus, setGstBillStatus] = useState(true);

  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isEditable, setIsEditable] = useState(true);

  const [showConfigDialog, setShowConfigDialog] = useState(false);
  const [uomIdSelect, setUomIdSelect] = useState({});
  const [productCompositionList, setProductCompositionList] = useState([]);

  const [compositionUpdate, setCompositionUpdate] = useState(false);

  const [selectedCompositionId, setSelectedCompositionId] = useState(-1);

  //const [searchText, setSearchText] = useState('');

  const handleValueChange = async (e, data) => {
    e.preventDefault();
    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    //console.log("Event code : ", e.keyCode);

    if (
      name === "phone" ||
      name === "contact_number" ||
      name === "shipContactNumber" ||
      name === "billContactNumber" ||
      name === "pincode" ||
      name === "shipPincode" ||
      name === "billPincode"
    ) {
      console.log("Inside check ******************");
      //const numericRegExp = /^(?:[1-9]\d*|\d)$/;
      if (e.target.value.match(appGlobal.wholeNumberRegExp)) {
        //var valueTmp = Math.round(e.target.value.replace(/\D/g, ""));
        setFormValues({ ...formValues, [name]: e.target.value });
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
      /*
      if ( name === 'contact_person' && addrCopyCheck) {
        setFormValues({ ...formValues, ["shipContactName"]: value });
      }
      */
    }
  };

  const handleSearch = (e, dat) => {
    console.log("Handled search method called ...", e);

   
   // getDistrictList(stateIdInput);
  };

  const handleDistrictSelect = (e, data) => {
    console.log("handleDistrictSelect called ...", e);

    setDistrictIdSelect(e);
  };

  const handleBillDistrictSelect = async (e) => {
    console.log("handle bill district : ", e);

    setBillDistrictIdSelect(e);
  };



  const handleChange = (e, data) => {
    //const min = 0;
    //const max = 10000;
    console.log("Handle Change clicked : ", e);
    console.log("Handle Change clicked target : ", e.target);
    //console.log("HTML tag : ", data);
    const { name, value } = e.target;
    console.log("Captured Quantity name : ", name);
    console.log("Captured Quantity value : ", value);
    //setFormValues({...formValues, [name]: value});
    console.log("FORM VALUES at handleChange func : ", formValues);

    var currentId = name.substring(name.lastIndexOf("-") + 1);
    var nameOfElement = name.substring(0, name.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    var itemSelected = items[currentId];

    console.log("Product: ", itemSelected.product);
    console.log("Product Name : ", itemSelected.product_name);
    console.log("Price : ", itemSelected.unit_price);
    console.log("Product cost : ", itemSelected.total_item_price);
    console.log("GST : ", parseFloat(itemSelected.cgst + itemSelected.sgst + itemSelected.igst));
    console.log("Total cost : ", itemSelected.total_price);
    console.log("Qty : ", itemSelected.quantity);

    console.log("Product Info : ", productMaster);
    const currentProductIndex = productMaster.findIndex(
      (productInd) => productInd.name === itemSelected.product_name);
    console.log("Currently selected row product id : ", currentProductIndex);
    if (currentProductIndex < 0) {
      itemSelected.error = "Please select product from list";

      const newitems = items.slice();
      newitems[currentId] = itemSelected;
      setItems(newitems);
      return 0;
    } else {
      itemSelected.error = "";
    }

    console.log("Currently selected row product value : ", productMaster[currentProductIndex]);

    //console.log("%%%%%%%%%%%%%% GST INFO ++++++++++++++ : ", productMaster[currentProductIndex]["gst"].cgst);

    /*
    itemSelected.product = {
      value: productMaster[currentProductIndex].product_id,
      label: productMaster[currentProductIndex].product_name,
      stock_id: productMaster[currentProductIndex].stock_id,
      cgst: productMaster[currentProductIndex]["gst"].cgst,
      sgst: productMaster[currentProductIndex]["gst"].sgst,
      igst: productMaster[currentProductIndex]["gst"].igst,
    };
    */

    itemSelected.cgst_perc = productMaster[currentProductIndex].cgst;
    itemSelected.sgst_perc = productMaster[currentProductIndex].sgst;
    itemSelected.sgst_perc = productMaster[currentProductIndex].sgst;
    //itemSelected.unit_cost = productMaster[currentProductIndex].unit_cost;

    if (nameOfElement === "quantity" || nameOfElement === "unit_price") {
      console.log("Product Quantity item edited ...");
      /*
      const valueTmp = Math.max(
        ConfigMinValue,
        Math.min(configMaxValue, Number(e.target.value))
      );
      */
      //var valueTmp = e.target.value.replace(/[^0-9.]/g, "");
      //var valueTmp = e.target.value.replace(/^-?\d+\.?\d*$/g, "");
      //var numericRegExp = new RegExp("^[0-9]+$");
      //const numericRegExp = /^[\+\-]?\d*\.?\d+(?:[Ee][\+\-]?\d+)?$/;
      //const numericRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      //const numericRegExp = new RegExp(/^-?\d*[\.]?\d+$/);

      // const numericRegExp = /^\d*\.?\d*$/;//original
      ////^([0-9]+-)*([0-9]+)$///.replace(/(\..*)\./g, "$1");
      //console.log("Matched value >>>>>>>>>>>>>>> : ", valueTmp);
      //if (numericRegExp.match(e.target.value)) {
      if (e.target.value.match(appGlobal.numericRegExp)) {
        itemSelected[nameOfElement] = e.target.value;
      }
    } else {
      console.log("Other than handled option clicked so skipping it ...");
    }
    //itemSelected.product_cost = productMaster[currentProductIndex].unit_cost * itemSelected.qty;
    //itemSelected.unit_cost = productMaster[currentProductIndex].unit_cost;
    itemSelected.total_item_price = itemSelected.unit_price * itemSelected.quantity;

    var cgstCalculate = 0.0;
    if (gstBillStatus) {
      cgstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].cgst) / 100;
    }
    itemSelected.cgst = cgstCalculate;

    var sgstCalculate = 0.0;

    if (gstBillStatus) {
      sgstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].sgst) / 100;
    }
    itemSelected.sgst = sgstCalculate;

    var igstCalculate = 0.0;
    if (gstBillStatus) {
      igstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].igst) / 100;
    }
    itemSelected.igst = igstCalculate;
    //let totalGst = cgstTmp + sgstTmp + igstTmp;

    //var gst = { cgst: cgstTmp, sgst: sgstTmp, igst: igstTmp, totGst: totalGst }
    //itemSelected.gst = gst;
    //itemSelected.product_cost = productMaster[currentProductIndex].unit_cost * itemSelected.qty;
    console.log("CGST STATE 501 : ", itemSelected.cgst);
    console.log("SGST STATE 501 : ", itemSelected.sgst);
    console.log("IGST STATE 501 : ", itemSelected.igst);

    itemSelected.total_price = parseFloat(itemSelected.total_item_price) + parseFloat(itemSelected.cgst + itemSelected.sgst + itemSelected.igst);

    console.log("Modified unit cost : ", itemSelected.unit_price);
    console.log("Modified Product cost : ", itemSelected.total_item_price);
    console.log("Modified Total cost : ", itemSelected.total_price);

    const newitems = items.slice();
    newitems[currentId] = itemSelected;
    setItems(newitems);
  };
 
 
  const getOrderAddress = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + `/getOrderAddress?order_id=${passedValue.order_id}`, { headers },{ withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getOrderAddress : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getOrderAddress : ",res.data.detail.res_data);
        setOrderAddress(res.data.detail.res_data.order_address);

        formValues["shipContactName"] = res.data.detail.res_data.order_address.delivery_address.contact_name;
        formValues["shipContactNumber"] = res.data.detail.res_data.order_address.delivery_address.contact_number;
        formValues["shipAddress1"] = res.data.detail.res_data.order_address.delivery_address.address_line1;
        formValues["shipAddress2"] = res.data.detail.res_data.order_address.delivery_address.address_line2;
        formValues["shipPincode"] = res.data.detail.res_data.order_address.delivery_address.pincode;

        console.log("Delivery contact : ", formValues["shipContactName"]);

        formValues["billContactName"] = res.data.detail.res_data.order_address.billing_address.contact_name;
        formValues["billContactNumber"] = res.data.detail.res_data.order_address.billing_address.contact_number;
        formValues["billAddress1"] = res.data.detail.res_data.order_address.billing_address.address_line1;
        formValues["billAddress2"] = res.data.detail.res_data.order_address.billing_address.address_line2;
        formValues["billPincode"] = res.data.detail.res_data.order_address.billing_address.pincode;

        console.log("Billing : ", formValues["billContactName"]);

        setAddressFetched(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
  };

  const getOrderDetails = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + `/getOrderById?order_id=${passedValue.order_id}`, { headers }, { withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getOrdersList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getOrderDetails : ", res.data.detail.res_data);
        console.log("Response message from server getOrderDetails user_info : ", res.data.detail.res_data.user_info);
        console.log("Response message from server getOrderDetails order info : ", res.data.detail.res_data.order_info);
        console.log("Length of orderInfo array : ", res.data.detail.res_data.order_info.length);
        setOrderDetFetched(true);
        //optionsDistrict = res.data.detail.res_data.district_list

        setOrderInfoDet(res.data.detail.res_data.order_info);
        var dd = new Date(res.data.detail.res_data.order_info.order_date);
        formValues["order_date"] = formatDate(dd);
        console.log("Date of order_date : ", dd.getMonth());
        setGstBillStatus(res.data.detail.res_data.order_info.bill_gst_include);
        //console.log("CUSTOMER ID : ", res.data.detail.res_data.order_info.customer_id);

        /*
        if ( res.data.detail.res_data.order_list.length > 0 ) {
          setOrderList(res.data.detail.res_data.order_list);
          //setOptDist(res.data.detail.res_data.district_list)
          //setDistrictIdSelect({value: res.data.detail.res_data.district_list[0].value, label: res.data.detail.res_data.district_list[0].label});
        }
        */
        //setDistrictIdSelect(res.data.detail.res_data.district_list);

        //localStorage.setItem('_aI', res.data.detail.res_data.aI);
        //localStorage.setItem('userRoleId', userRoleId);
        //localStorage.setItem('username', res.data.detail.res_data.user_info.username);

        //navigate("/dashboard");
      }
      /*
      if (res.data.status === "SUCCESS" ) {
        this.setState ({
        tokenJwt: res.data.res_data.token,
        isLoaded: true,
        openOTP: true,
        })
          // this.props.history.push({pathname: client.taskViewPath, state:{pageList: this.state.page, orgName: this.state.orgName} });
      }
      else {
      }
    */
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
  };

  const getOrderItemsByOrderId = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + `/getOrderItemsByOrderId?order_id=${passedValue.order_id}`, { headers }, { withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getOrdersList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server OrderDetails : ", res.data.detail.res_data);
        console.log("Response message from server OrderDetails user_info : ", res.data.detail.res_data.user_info);
        console.log("Response message from server order items list : ", res.data.detail.res_data.order_item_list);
        console.log("Length of district array : ", res.data.detail.res_data.order_item_list.length);
        //optionsDistrict = res.data.detail.res_data.district_list

        //setOrderInfoDet(res.data.detail.res_data.order_info);

        if (res.data.detail.res_data.order_item_list.length > 0) {
          //setProdList(res.data.detail.res_data.order_item_list);
          setItems(res.data.detail.res_data.order_item_list);
          //setOptDist(res.data.detail.res_data.district_list)
          //setDistrictIdSelect({value: res.data.detail.res_data.district_list[0].value, label: res.data.detail.res_data.district_list[0].label});
        }

        setOrderItemFetched(true);
        //setDistrictIdSelect(res.data.detail.res_data.district_list);

        //localStorage.setItem('_aI', res.data.detail.res_data.aI);
        //localStorage.setItem('userRoleId', userRoleId);
        //localStorage.setItem('username', res.data.detail.res_data.user_info.username);

        //navigate("/dashboard");
      }
      /*
      if (res.data.status === "SUCCESS" ) {
        this.setState ({
        tokenJwt: res.data.res_data.token,
        isLoaded: true,
        openOTP: true,
        })
          // this.props.history.push({pathname: client.taskViewPath, state:{pageList: this.state.page, orgName: this.state.orgName} });
      }
      else {
      }
    */
      //setOrderItemFetched(true);
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
  };

  const getDCListByOrderId = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + `/getDCListByOrderId?order_id=${passedValue.order_id}`, { headers }, { withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getOrdersList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server OrderDetails : ", res.data.detail.res_data);
        console.log("Response message from server OrderDetails user_info : ", res.data.detail.res_data.user_info);
        console.log("Response message from server order items list : ", res.data.detail.res_data.dc_list);
        console.log("Length of district array : ", res.data.detail.res_data.dc_list.length);
        //optionsDistrict = res.data.detail.res_data.district_list

        //setOrderInfoDet(res.data.detail.res_data.order_info);

        if (res.data.detail.res_data.dc_list.length > 0) {
          setDeliveryChellan(res.data.detail.res_data.dc_list);
          //setProdList(res.data.detail.res_data.dc_list);
          //setOptDist(res.data.detail.res_data.district_list)
          //setDistrictIdSelect({value: res.data.detail.res_data.district_list[0].value, label: res.data.detail.res_data.district_list[0].label});
        }

        //setDistrictIdSelect(res.data.detail.res_data.district_list);

        //localStorage.setItem('_aI', res.data.detail.res_data.aI);
        //localStorage.setItem('userRoleId', userRoleId);
        //localStorage.setItem('username', res.data.detail.res_data.user_info.username);

        //navigate("/dashboard");
        setOrderDcFetched(true);
      }
      /*
      if (res.data.status === "SUCCESS" ) {
        this.setState ({
        tokenJwt: res.data.res_data.token,
        isLoaded: true,
        openOTP: true,
        })
          // this.props.history.push({pathname: client.taskViewPath, state:{pageList: this.state.page, orgName: this.state.orgName} });
      }
      else {
      }
    */
      //setOrderDcFetched(true);
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
    setOrderDcFetched(true);
  };
  
  /*
  const getProductApiCall = async () => {
    console.log("getTradeType method called ...");
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {
        ...client.headers,
        Authorization: ` ${aIVal}`
      }
      var res = await axios.get(
        client.domain + "/ims/getProductsTTSell",
        { headers },
        { withCredentials: false }
      );

      console.log("Response from server getProducts : ", res.data.detail.data);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getProducts : ", res.data.detail.data);

        var newSellProdArr = [];
        res.data.detail.data.map((prodItem) => {
          var newSellProd = {
            "value": prodItem.id,
            "label": prodItem.name,
            "product_id": prodItem.id,
            "product_name": prodItem.name,
            "stock_id": 1,
            "uom": prodItem.uom_value,
            "unit_cost": prodItem.unit_price,
            "gst": { "cgst": prodItem["cgst"], "sgst": prodItem["sgst"], "igst": prodItem["igst"] }

          }
          newSellProdArr.push(newSellProd);
        })
        setProductMaster(newSellProdArr);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.data);

        }
      }
    }
  };
  */

  useEffect(() => {
    if (!initialized.current) {
      console.log("*************** useEffect called ##########################");
      console.log(formErrors);
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        console.log(formErrors);
      }

      if (state === null) {
        navigate("/listOrders");
      } else {
        console.log("PASSED VALUE THROUGH STATE variable : ", state);

        //setProductMaster(product_list);
        //getProductApiCall();
        
        /*
        var stateIdInput = 1;
        var stateNameInput = "TAMIL NADU";
        const getDistrictListWrapper = async () => {
          await getDistrictList(stateIdInput, stateNameInput);
        }
        getDistrictListWrapper();
        
        var country_id = appGlobal.COUNTRY_ID;
        var retStateStList = false;
        var stateArrRet = [];
        const getStateListWrapper =  async() => {
          var [retStateStList, stateArrRet] = await getStateList(country_id);
          if ( retStateStList) {
            console.log("RETURNED STATE LIST : ", stateArrRet);
            setOptionsState(stateArrRet);
            if ( state.action === 'add') {
              setStateIdSelect(stateArrRet[0]);
            }
            else {
            //stateListRef.current =  stateArrRet;
            var findState = stateArrRet.find(element => element.value === state.passed_element.state_id);
            if (findState ) {
              setStateIdSelect({value: state.passed_element.state_id, label: findState.label});
            }
            }
          }
        }
        */

        //getStateListWrapper();
        (async () => {
          var stateIdInput = 1;
          var retStateCustomer = false;
          var customerInfo = {};
          var [retStateSellProd, newSellProdArr] = await getProductsSell();
          if ( retStateSellProd ) {
            console.log("RETURNED SELL PRODUCT LIST : ", newSellProdArr);
            setProductMaster(newSellProdArr);
          }
          
          if (state.action === "edit" || state.action === "view") {
            [retStateCustomer, customerInfo] = await getCustomerDetails();
            await getOrderAddress();
            await getOrderDetails();
            await getOrderItemsByOrderId();
            await getDCListByOrderId();
          }
          else {
            setIsReadOnly(false);
          }

          if (state.action === 'view' || state.action === 'edit') {
            stateIdInput = customerInfo.state_id;
          }
          var retStateDist = false;
          var distArrRet = [];
          [retStateDist, distArrRet] = await getDistrictList(stateIdInput);

          if (retStateDist) {
            setOptDist(distArrRet);
            if (state.action === 'add') {
              //setDistrictIdSelect(distArrRet[0]);
              var findDist = distArrRet.find(element => element.label === appGlobal.DEFAULT_DISTRICT);
              if (findDist !== undefined) {
                console.log("++++++++++++++++++------------------- FOUND DISTRICT : ", findDist);
                setDistrictIdSelect(findDist);
              }
            }
            else {
              var findDist = distArrRet.find(element => element.value === customerInfo.district_id);
              if (findDist !== undefined) {
                console.log("++++++++++++++++++------------------- FOUND DISTRICT : ", findDist);
                //setDistrictIdSelect({ value: customerInfo.district_id, label: findDist.label });
                setDistrictIdSelect(findDist);
              }
            }
          }

          var country_id = appGlobal.COUNTRY_ID;
          var [retStateStList, stateArrRet] = await getStateList(country_id);
          if (retStateStList) {
            setOptionsState(stateArrRet);
            if (state.action === 'add') {
              setStateIdSelect(stateArrRet[0]);
            }
            else {
              var findState = stateArrRet.find(element => element.value === customerInfo.state_id);
              if (findState) {
                console.log("STATE FOUND OBJECT : ", findState);
                setStateIdSelect({ value: customerInfo.state_id, label: findState.label });
              }
            }
          }
          setToRender(true);
        })();

        initialized.current = true;
      }
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit called ...");

    console.log("FORM VALUES at handleSubmit: ", formValues);
    console.log("LINE ITEMS : ", items);
    console.log("Form submit called ...");

    console.log("FORM VALUES at handleChange func : ", formValues);

    console.log("CUSTOMER : ", formValues["customer_name"]);

    console.log("GST Number : ", formValues["gst_number"]);
    console.log("Phone Number : ", formValues["phone"]);

    console.log("Tot number of items : ", total_items);
    console.log("Sum of Tot Product Cost : ", sum_product_cost);
    console.log("Sum of Tot GST : ", sum_product_gst);
    console.log("Sum of total cost : ", sum_product_total);

    console.log("SEARCHED CUSTOMER : ", searchedCustomer);

    console.log("FORM VALUES : ", formValues);

    formErrors.alertMsg = "";
    var customer_name_tmp = "";
    var customer_id_tmp = 0;

    if (!searchedCustomer || Object.keys(searchedCustomer).length < 1) {
      customer_name_tmp = customerSearchText;
      customer_id_tmp = 0;
    } else {
      customer_name_tmp = searchedCustomer.label;
      customer_id_tmp = searchedCustomer.value;
    }

    console.log("Customer name to submit : ", customer_name_tmp);
    setFormValues({ ...formValues, ["customer_name"]: customer_name_tmp });
    
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log("After validateErrors and setsubmit call ...");

    if (validateErr) {
      console.log("Validate error occured ...");
      return 0;
    }
    

    var orderIdTmp = 0;
    var actionTmp = "add";
    var billingAddrIdTmp = 0;
    var shipAddrIdTmp = 0;
    var customerId = 0;
    var orderNoTmp = "";
    if (state.action === "view" || state.action === "edit") {
      orderIdTmp = orderInfoDet.order_id;
      orderNoTmp = orderInfoDet.order_no;
      billingAddrIdTmp = orderAddress.billing_address.address_id;
      shipAddrIdTmp = orderAddress.delivery_address.address_id;

      customer_id_tmp = formValues["customer_id"];
      formValues["order_date"] = orderInfoDet.order_date;
      actionTmp = "edit";
    }

    if (items.length < 1) {
      //setShowMsg("Please add atleast one product in order");
      //setMsgType("warning");
      //setShow(true);
      console.log("Empty product list ...");
      setFormErrors({ ...formErrors, ["alertMsg"]: "Add atleast one item" });
      return 0;
    }

    var emptyItem = false;
    items.map((item) => {
      console.log("Item value : ", item);
      if (item.product_id < 1 || !item.product_name) {
        item.error = "Please select product from list";
        //setShowMsg("Please selecyt product from dropdown");
        //setMsgType("warning");
        //setShow(true);
        emptyItem = true;
        return 0;
      } else {
        item.error = "";
      }
    });

    if ( emptyItem === false ) {
    emptyItem = false;
    items.map((item) => {
      console.log("Item value : ", item);
      if ( !item.quantity || item.quantity < 1 ) {
        item.error_qty = "Please select valid quantity";
        //setShowMsg("Please selecyt product from dropdown");
        //setMsgType("warning");
        //setShow(true);
        emptyItem = true;
        return 0;
      } else {
        item.error = "";
      }
    });
  }

    console.log("<<<<<<<<<<<<<<<< API calling continue >>>>>>>>>>>>>>>>>>");
    if (emptyItem) {
      console.log("unselcted product item so exit from submission ...");
      return 0;
    }

    var dispNameTmp = "";
    var dispNameFromField = formValues["customer_disp_name"];
    
    
    if (dispNameFromField === "" || dispNameFromField === null || dispNameFromField === undefined) {
      dispNameTmp = customer_id_tmp;
      
    }
    else {
      dispNameTmp = dispNameFromField;
    }
    
    let reqValidateJwt = {
      /*
      company: formValues["company"],
      address: formValues["address"],
      city: formValues["city"],
      zip: formValues["zip"],

      phone: formValues["phone"],
      gst_number: formValues["gst_number"],

      district: formValues["district"],
      state: formValues["state"],
      */

      customer_name: customer_name_tmp,
      customer_id: customer_id_tmp,
      customer_disp_name: dispNameTmp,
      gst_number: formValues["gst_number"],
      phone: formValues["phone"] || "",
      primary_number: formValues["primary_number"],
      secondary_number: formValues["secondary_number"],
      contact_person: formValues["contact_person"] || "",
      contact_number: formValues["contact_number"] || "",
      email: formValues["email"],
      address1: formValues["address1"],
      address2: formValues["address2"],
      distId: districtIdSelect.value,
      stateId: stateIdSelect.value,
      pincode: formValues["pincode"] || "",
      website: formValues["website"],

      total_items: total_items,
      sum_product_cost: sum_product_cost,
      sum_product_gst: sum_product_gst,

      sum_product_cgst: sum_product_cgst,
      sum_product_sgst: sum_product_sgst,
      sum_product_igst: sum_product_igst,

      amount_discount: amount_discount,

      sum_product_total: sum_product_total,

      orderItems: JSON.stringify(items),

      order_id: orderIdTmp,
      order_no: orderNoTmp,

      ship_addr_id: shipAddrIdTmp,
      ship_contact_name: formValues["shipContactName"] || "",
      ship_contact_number: formValues["shipContactNumber"] || "",
      ship_address1: formValues["shipAddress1"],
      ship_address2: formValues["shipAddress2"],
      ship_pincode: formValues["shipPincode"] || "",

      bill_addr_id: billingAddrIdTmp,
      bill_contact_name: formValues["billContactName"] || "",
      bill_contact_number: formValues["billContactNumber"] || "",
      bill_address1: formValues["billAddress1"],
      bill_address2: formValues["billAddress2"],
      bill_pincode: formValues["billPincode"] || "",

      bill_gst_include: gstBillStatus,
    };

   
    console.log("payload:", reqValidateJwt);

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers ,Authorization: `Bearer ${aIVal}`}
      setSubmitAction(true);
      var res = await axios.post(client.domain + "/insertOrder", reqValidateJwt, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        setAlertPurpose('success');
        setSubmitAction(false);
        console.log("Response message from server : ", res.data.detail.res_data);
        setShowAlert(true);
      }
      else {
        setSubmitAction(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      setAlertPurpose('failed');
      setShowAlert(true);
      setAlertDialogBody("FAILED!");
      setAlertDialogTitle("Failed to save the order please try again");
      /*
      if ( error.code === 'ERR_NETWORK' ) {
        console.log("Not able to reach target server please try after sometime");
        setShowMsg('Please try after sometime');
        setShowMsgTitle('Network Issue!');
        setShow(true);
      }
      else{
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === 'FAILED') {
          console.log('Request failed due to ', error.response.data.detail.res_data);
          //setShowMsg('Please Re-Login');
          //setShowMsgTitle('Invalid Session!');
          //setShow(true);
          navigate("/auth");
        }
      }
      */
      setSubmitAction(false);
    }
  };
  const validate = (values) => {
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    //if (!customerSearchText && !searchedCustomer) {
    if (!values.customer_name){
      console.log("Customer name is missing ...", formValues["customer_name"]);
      errors.customer_name = "Customer name is required!";
      validateErr = true;
    }

    if (!values.customer_disp_name) {
      errors.customer_disp_name = "Display name is required!";
      validateErr = true;
    }
    /*
    if (!values.phone) {
      errors.phone = "Phone  is required!";
      validateErr = true;
    }
    */

    if (values.phone && values.phone.length != 10) {
      errors.phone = "Phone number should be 10 digits";
      validateErr = true;
    }
      /*
      if (!values.contact_person) {
        errors.contact_person = "Contact person is required!";
        validateErr = true;
      }
     

      if (!values.contact_number) {
        errors.contact_number = "Contact person number is required!";
        validateErr = true;
      }
       */
    if (!values.address1) {
      errors.address1 = "Street / Location is required!";
      validateErr = true;
    }
    {
      /*
    if (!values.address2) {
      errors.address2 = "City or Town name is required!";
      validateErr = true;
    }
  */
    }
    /*
    if (!values.pincode) {
      errors.pincode = "Pincode is required!";
      validateErr = true;
    }
    /*

    if (values.pincode && values.pincode.length != 6) {
      errors.pincode = "Pincode should be 6 digits";
      validateErr = true;
    }
    /*
    if (!values.primary_number) {
      errors.primary_number = "Mobile number is required";
      validateErr = true;
    }

    if (values.primary_number && values.primary_number.length != 10) {
      errors.primary_number = "Mobile number should be 10 digits";
      validateErr = true;
    }
    */
    // Delivery address validation
    /*
    if (!formValues.shipContactName) {
      errors.shipContactName = "Contact name is required!";
      validateErr = true;
    }

    if (!formValues.shipContactNumber) {
      errors.shipContactNumber = "Contact number is required!";
      validateErr = true;
    }
    */

    if (!formValues.shipAddress1) {
      errors.shipAddress1 = "Street Or Location is required!";
      validateErr = true;
    }
    /*
    if (!formValues.shipPincode) {
      errors.shipPincode = " Pincode is required!";
      validateErr = true;
    }
    */

    /*
    if (!formValues.shipAddress2) {
      errors.shipAddress2 = "Town / City is required!";
      validateErr = true;
    }
    */

    // Billing address validation
    /*
    if (!formValues.billContactName) {
      errors.billContactName = "Contact name is required!";
      validateErr = true;
    }

    if (!formValues.billContactNumber) {
      errors.billContactNumber = "Contact number is required!";
      validateErr = true;
    }
    */

    
    if (!formValues.billAddress1) {
      errors.billAddress1 = "Street / Location is required!";
      validateErr = true;
    }
    

    /*
    if (!formValues.billAddress2) {
      errors.billAddress2 = "Town / City is required!";
      validateErr = true;
    }
    */
    /*
    if (!formValues.billPincode) {
      errors.billPincode = "Pincode is required!";
      validateErr = true;
    }
    */
    return errors;
  };

  const saveHandler = async (event) => {
    // Prevent page reload
    event.preventDefault();

    console.log("Form submit clicked ...");

    console.log("DATA : ", document.forms[0]);

    /*
    var { username, password } = document.forms[0];
    console.log("username : ", username.value);
    console.log("pasword : ", password.value);
    */
  };

  const backHandler = async (event) => {
    event.preventDefault();
    console.log("Back button clicked ...");
  };

  const newItemHandle = async (event) => {
    event.preventDefault();
    console.log("New item addition button clicked ...");

    const newitems = items.slice();
    //let itemNew = { id: 1, product: { value: 0, label: "" }, unit_cost: "", qty: 1, "product_cost": "", "total_cost": "", gst: { cgst: 0.00, sgst: 0.00, igst: 0.00, totGst: 0.00 } };
    let itemNew = {
      id: 0,
      product_id: 0,
      product_name: "",
      unit_price: "",
      quantity: "",
      total_item_price: 0.0,
      total_price: 0.0,
      cgst: 0.0,
      sgst: 0.0,
      igst: 0.0,
      editAction: false,
      enableState: false,
      composition: [],
      is_product_cc: false,
    };
    newitems.push(itemNew);
    setItems(newitems);
  };

  const handleProductSelect = (e, data) => {
    console.log(">>>>>>>>>>>>>>>>>>>>>>> HandleProductSelect called *******************");
    console.log("SELECTED Dropdown value : ", e);
    console.log("SELECTED element id : ", data);
    var currentId = data.name.substring(data.name.lastIndexOf("-") + 1);
    console.log("Current id : ", currentId);

    var itemSelected = items[currentId];
    //console.log("Product: ", itemSelected.product);
    console.log("<<<<<<<<<<<<<<<<<<<<< ITEM ID >>>>>>>>>>>>>>>>>>: ", itemSelected.id);
    console.log("Price : ", itemSelected.unit_price);
    console.log("Product cost : ", itemSelected.total_item_price);
    console.log("GST : ", parseFloat(itemSelected.cgst + itemSelected.sgst + itemSelected.igst));
    console.log("Total cost : ", itemSelected.total_price);
    console.log("Qty : ", itemSelected.quantity);

    console.log("Product Info : ", productMaster);
    const currentProductIndex = productMaster.findIndex((productInd) => productInd.name === e.label);
    console.log("Currently selected row product id : ", currentProductIndex);
    console.log("Currently selected row product value : ", productMaster[currentProductIndex]);

    itemSelected.product_id = e.value;
    itemSelected.product_name = e.label;
    itemSelected.enableState = true;
    //itemSelected.isEditable = true;
    itemSelected.editAction = false;
    //itemSelected.product.label = e.label;
    /*
    var productNew = {
      label: e.label,
      value: e.value,
      product_id: e.product_id,
      product_name: e.product_name,
      stock_id: e.stock_id,
      unit_cost: e.unit_cost,
      cgst: e.gst.cgst,
      sgst: e.gst.sgst,
      igst: e.gst.igst,
    };
    itemSelected.product = productNew;
    */
    //itemSelected.price = productMaster[currentProductIndex].unit_cost;
    itemSelected.cgst_perc = productMaster[currentProductIndex].cgst;
    itemSelected.sgst_perc = productMaster[currentProductIndex].sgst;
    itemSelected.igst_perc = productMaster[currentProductIndex].igst;

    itemSelected.unit_price = productMaster[currentProductIndex].unit_price;
    itemSelected.total_item_price =
      productMaster[currentProductIndex].unit_price * itemSelected.quantity;

    var cgstCalculate = 0.0;
    if (gstBillStatus) {
      cgstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].cgst) / 100;
    }
    itemSelected.cgst = cgstCalculate;

    var sgstCalculate = 0.0;
    if (gstBillStatus) {
      sgstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].sgst) / 100;
    }
    itemSelected.sgst = sgstCalculate;

    var igstCalculate = 0.0;
    if (gstBillStatus) {
      igstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].igst) / 100;
    }
    itemSelected.igst = igstCalculate;
    //let totalGst = cgstTmp + sgstTmp + igstTmp;

    //var gst = { cgst: cgstTmp, sgst: sgstTmp, igst: igstTmp, totGst: totalGst }
    //itemSelected.gst = gst
    //itemSelected.product_cost = productMaster[currentProductIndex].unit_cost * itemSelected.qty;

    itemSelected.total_price =
      parseFloat(itemSelected.total_item_price) +
      parseFloat(itemSelected.cgst + itemSelected.sgst + itemSelected.igst);

    console.log("Modified unit cost : ", itemSelected.unit_price);
    console.log("Modified Product cost : ", itemSelected.total_item_price);
    console.log("Modified Total cost : ", itemSelected.total_price);

    const newitems = items.slice();
    newitems[currentId] = itemSelected;
    setItems(newitems);
    //var fieldValue = "product";
    //setFormValues({ ...formValues, [fieldValue]: e })
    setIsEditable(false);
  };

  /*
  const searchActionFunc = (json) => {
    setOptionsSearch(json.detail.res_data.district_list);
    setIsLoading(false)
    console.log("SEARCHED VALUE inside searchActionFunc : ", json.detail.res_data.district_list);
  }
  */

  const customerSearchSelectFunc = async (selected) => {
    console.log("**************** User selected value from search result +++++++++++++ : ", selected);
    if (selected.length > 0) {
      setSearchedCustomer(selected[0]);
      
      const response = await fetch(`${client.domain}/getCustomerInfo?customer_id=${selected[0].value}`);
      const customer_info = await response.json();
      console.log("CUSTOMER INFO : ", customer_info.detail.res_data.customer_info);
      //setIsLoading(false);

      //setFormValues({...formValues, [name]: value});
      //let entries = Object.entries(company_info.detail.res_data.company_info)
      //entries.map( ([key, val] = entry) => {
      //entries.map( ([key, val] = entry) => {
      for (const [key, value] of Object.entries(
        customer_info.detail.res_data.customer_info
      )) {
        //console.log("KEY : ", key);
        //console.log("VAL : ", val);
        console.log("KEY : %s, VAL: %s", key, value);

        //setFormValues({...formValues, [key]: value});
      }

      setFormValues(customer_info.detail.res_data.customer_info);
      //setIsLoading(true);

      console.log("STATE ID : ", customer_info.detail.res_data.customer_info["state_id"]);
      setStateIdSelect({
        value: customer_info.detail.res_data.customer_info["state_id"],
        label: customer_info.detail.res_data.customer_info["state_name"],
      });
      console.log("DISTRICT ID : ", customer_info.detail.res_data.customer_info["dist_id"]);
      setDistrictIdSelect({
        value: customer_info.detail.res_data.customer_info["dist_id"],
        label: customer_info.detail.res_data.customer_info["district_name"],
      });

      //setFormValues({...formValues, ["company_disp_name"]: "ABC"});
      //setFormValues({...formValues, ["pincode"]: "641654"});

      console.log("FORMVALUES 2 : ", formValues);
    }
  };

  const getCustomerDetails = async () => {
    var retState = false;
    //console.log("**************** User selected value from search result +++++++++++++ : ", selected);
    //if (selected.length > 0) {
    //setSearchedCompany(selected[0])
    var customerId = passedValue.customer_id;

    const response = await fetch(`${client.domain}/getCustomerInfo?customer_id=${customerId}`);
    const customer_info = await response.json();
    retState = true;
    console.log("CuSTOMER INFO : ", customer_info.detail.res_data.customer_info);

    //setFormValues({...formValues, [name]: value});
    //let entries = Object.entries(company_info.detail.res_data.company_info)
    //entries.map( ([key, val] = entry) => {
    //entries.map( ([key, val] = entry) => {
    for (const [key, value] of Object.entries(customer_info.detail.res_data.customer_info)) {
      //console.log("KEY : ", key);
      //console.log("VAL : ", val);
      console.log("KEY : %s, VAL: %s", key, value);

      //setFormValues({...formValues, [key]: value});
    }

    //setFormValues(customer_info.detail.res_data.customer_info);

    formValues["customer_id"] = customer_info.detail.res_data.customer_info.customer_id;
    formValues["customer_name"] = customer_info.detail.res_data.customer_info.customer_name;
    formValues["customer_disp_name"] = customer_info.detail.res_data.customer_info.customer_disp_name;
    formValues["contact_person"] = customer_info.detail.res_data.customer_info.contact_person;
    formValues["contact_number"] = customer_info.detail.res_data.customer_info.contact_number;
    formValues["gst_number"] = customer_info.detail.res_data.customer_info.gst_number;
    formValues["phone"] = customer_info.detail.res_data.customer_info.phone;
    formValues["email"] = customer_info.detail.res_data.customer_info.email;
    formValues["website"] = customer_info.detail.res_data.customer_info.website;
    formValues["address1"] = customer_info.detail.res_data.customer_info.address1;
    formValues["address2"] = customer_info.detail.res_data.customer_info.address2;
    /*
      formValues["dist_id"] = customer_info.detail.res_data.customer_info.dist_id;
      formValues["district_name"] = customer_info.detail.res_data.customer_info.district_name;
      formValues["state_id"] = customer_info.detail.res_data.customer_info.state_id;
      formValues["state_name"] = customer_info.detail.res_data.customer_info.state_name;
      */
    formValues["pincode"] = customer_info.detail.res_data.customer_info.pincode;
    formValues["primary_number"] = customer_info.detail.res_data.customer_info.primary_number;
    formValues["secondary_number"] = customer_info.detail.res_data.customer_info.secondary_number;

    if (state.action === "edit" || state.action === "view") {
      console.log(
        "######################## Condition met for view or edit %%%%%%%%%%%%%%%%%%%%"
      );
      setCustomerSearchText(customer_info.detail.res_data.customer_info.customer_name);
      //setIsLoading(true);
    }

    console.log("STATE ID : ", customer_info.detail.res_data.customer_info["state_id"]);
    setStateIdSelect({
      value: customer_info.detail.res_data.customer_info["state_id"],
      label: customer_info.detail.res_data.customer_info["state_name"],
    });
    console.log("DISTRICT ID : ", customer_info.detail.res_data.customer_info["dist_id"]);
    setDistrictIdSelect({
      value: customer_info.detail.res_data.customer_info["dist_id"],
      label: customer_info.detail.res_data.customer_info["district_name"],
    });

    //setFormValues({...formValues, ["company_disp_name"]: "ABC"});
    //setFormValues({...formValues, ["pincode"]: "641654"});

    console.log("FORMVALUES 2 : ", formValues);
    //}
    return [retState, customer_info.detail.res_data.customer_info];
  };

  const searchHandleFunc = async (query) => {
    setIsLoading(true);

    fetch(`${client.domain}/search/customers?q=${query}`)
      .then((resp) => resp.json())
      .then((json) => {
        //searchActionFunc(json)
        setOptionsSearch(json.detail.res_data.customer_list);
        setIsLoading(false);
        console.log("SEARCHED VALUE inside searchActionFunc : ", json.detail.res_data.customer_list);
      });
    formValues["customer_name"] = query;
    setFormValues({ ...formValues, ["customer_disp_name"]: query });
    /*
    if ( addrCopyCheck && !formValues.contact_person) {
      setFormValues({ ...formValues, ["shipContactName"]: query });
    }
    */
    //setFormValues({ ...formValues, ["customer_name"]: query });
    setCustomerSearchText(query);
  }

  console.log("FORM VALUES Before rendering :", formValues);

  var total_items = 0;
  var sum_product_cost = 0.0;
  var sum_product_gst = 0.0;
  var sum_product_cgst = 0.0;
  var sum_product_sgst = 0.0;
  var sum_product_igst = 0.0;

  var sum_product_total = 0.0;
  var amount_discount = 0.0;

  console.log("<<<<<<<<<<<<<<<<+++++++++++++++ Length of items : ", items.length);
  items.map((item) => {
    console.log("IND ITEM : ", item);
    console.log("Product Cost : ", item.total_item_price);
    var totGst = item.cgst + item.sgst + item.igst;
    console.log("GST  : ", totGst);

    //console.log("GST  : ", item.gst.cgst);
    //console.log("GST  : ", item.gst.sgst);
    //console.log("GST  : ", item.gst.igst);

    console.log("Total Price : ", item.total_price);
    total_items = total_items + 1;
    sum_product_cost = sum_product_cost + item.total_item_price;

    //sum_product_gst = sum_product_gst + item.gst.totGst;
    sum_product_cgst = sum_product_cgst + item.cgst;
    sum_product_sgst = sum_product_sgst + item.sgst;
    sum_product_igst = sum_product_igst + item.igst;

    //sum_product_total = sum_product_total + item.total_cost;
    //sum_product_total =  sum_product_total +
  });

  sum_product_gst = sum_product_cgst + sum_product_sgst + sum_product_igst;
  sum_product_total = sum_product_cost + sum_product_gst;

  sum_product_total = sum_product_total - amount_discount;

  console.log("Tot number of items : ", total_items);
  console.log("Sum of Tot Product Cost : ", sum_product_cost);

  console.log("Sum of Tot CGST : ", sum_product_cgst);
  console.log("Sum of Tot SGST : ", sum_product_sgst);
  console.log("Sum of Tot iGST : ", sum_product_igst);

  console.log("Sum of Tot GST : ", sum_product_gst);

  console.log("Sum of total cost : ", sum_product_total);

  console.log("Currently selected district : ", districtIdSelect);
  console.log("opt district : ", optDist);
  //console.log("*********** opt district value ############# : ", optDist.value);

  console.log("Currently selected state : ", stateIdSelect);
  console.log("+++++++++++++++ Customer search text ^^^^^^^^^^^^^^^^^^^^  :", customerSearchText);
  console.log("Searched customer <<<<<<<<<<<<<<<<<<< : ", searchedCustomer);

  const [addrCopyCheck, setAddrCopyCheck] = useState(false);

  const [refresh, setRefresh] = useState(false);

  const handleCopyToShipping = async (e) => {
    console.log("handleCopyToShipping called : ", e);
    console.log("handleCopyToShipping called CHECKED : ", e.target.checked);
    
    //var contactPersontmp = formValues["contact_person"];
    //var contactPersonAssign = "";
    //var contactNumbertmp = formValues["contact_number"];
    //var contactNumberAssign = "";
    //setFormValues({...formValues, ["addrCopyCheck"]: e.currentTarget.checked});
      if (e.target.checked === true) {
        if ( !formValues.contact_person ) {
         formValues["shipContactName"] = formValues["customer_name"]; 
        }
        else {
          formValues["shipContactName"] = formValues["contact_person"];
        }

        if ( !formValues.contact_number ) {
          formValues["shipContactNumber"] = formValues["phone"];
        }
        else {
          formValues["shipContactNumber"] = formValues["contact_number"];
        }
        formValues["shipAddress1"] = formValues["address1"];
        formValues["shipAddress2"] = formValues["address2"];
        setFormValues({ ...formValues, ["shipPincode"]: formValues["pincode"] });
        //setFormValues(e.target.checked);
      }
      setAddrCopyCheck(e.target.checked);
      setRefresh(true);
    };
      
      /*
      var contactPersontmp = formValues["contact_person"];
      var contactPersonAssign = "";

      console.log("Customer contact name before check : ", contactPersontmp);

      console.log("Customer contact name length before check : ", contactPersontmp.length);

      if (contactPersontmp.length > 0) {
        contactPersonAssign = contactPersontmp;
      } else {
        var customer_name_tmp = "";
        var customer_id_tmp = 0;

        console.log("searched customer array : ", searchedCustomer);
        console.log("searched customer length : ", searchedCustomer.length);
        console.log("searched customer length Object : ", Object.keys(searchedCustomer).length);
        //if (!searchedCustomer || searchedCustomer.length < 1) {
        if (Object.keys(searchedCustomer).length < 1) {
          console.log("Inside IF condition");
          customer_name_tmp = customerSearchText;
          customer_id_tmp = 0;
        } else {
          console.log("Inside ELSE conditiob");
          customer_name_tmp = searchedCustomer.label;
          customer_id_tmp = searchedCustomer.value;
        }

        console.log("Customer name to submit : ", customer_name_tmp);

        contactPersonAssign = customer_name_tmp;
      }

      var contactNumbertmp = formValues["contact_number"];
      var contactNumberAssign = "";

      console.log("Contact number : ", contactNumbertmp);
      if (contactNumbertmp) {
        contactNumberAssign = contactNumbertmp;
      } else {
        contactNumberAssign = formValues["phone"];
      }

      formValues["shipContactName"] = contactPersonAssign;
      formValues["shipContactNumber"] = contactNumberAssign;
      formValues["shipAddress1"] = formValues["address1"];
      formValues["shipAddress2"] = formValues["address2"];
      //formValues["billPincode"] =  formValues["pincode"];
      //setFormValues({...formValues, ["billContactName"]: formValues["contact_person"]});
      //setFormValues({...formValues, ["billContactNumber"]: formValues["contact_number"]});
      //setFormValues({...formValues, ["billAddress1"]: formValues["address1"]});
      //setFormValues({...formValues, ["billAddress2"]: formValues["Address2"]});
      setFormValues({ ...formValues, ["shipPincode"]: formValues["pincode"] });

      //setBillDistrictIdSelect(districtIdSelect);
    }
  };
   */
  const [addrCopyBillCheck, setAddrCopyBillCheck] = useState(false);

  const handleCopyToBilling = async (e) => {
    console.log("handle copy to billing called :", e);
    console.log("handle copy to billing called CHECKED :", e.target.checked);
    setAddrCopyBillCheck(e.target.checked);

    if (e.target.checked === true) {
      formValues["billContactName"] = formValues["shipContactName"];
      formValues["billContactNumber"] = formValues["shipContactNumber"];
      formValues["billAddress1"] = formValues["shipAddress1"];
      formValues["billAddress2"] = formValues["shipAddress2"];
      setFormValues({...formValues, ["billPincode"]: formValues["shipPincode"]});
    }
    setRefresh(true);
  };

  const handleItemDelete = (e) => {
    console.log("handle item delete called ...", e);
    console.log("handle item delete e.currentTarget : ", e.currentTarget.id);
    console.log("handle item delete e.target : ", e.target.id);

    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(
        0,
        clickedTagId.lastIndexOf("-")
      );
      console.log("current selected DELETE element name : ", nameOfElement);
      console.log("Current SELECTED DELETE id : ", curSelId);
      console.log("CUR SELECTED DELETE VALUE : ", items[curSelId]);

      let sliced = items.splice(curSelId, 1);
      console.log("Removed item from order items: ", sliced);
      var newItem = items.slice();
      setItems(newItem);

      //setRefresh(true);
      //console.log("Removed item from order items: ", sliced);
      //setRefresh(false);
    }
  };

  const handleBack = async (e) => {
    console.log("handle back called : ", e);
    navigate(-1);
  };
  /*

  const handleClose = async (e) => {
    setShow(false);
    //navigate(-1);
  };
  */

  const handleGSTBillStatus = async (e) => {
    console.log("HandleGSTBillStatus called : ", e);
    console.log("target checked : ", e.target.checked);

    var itemModNew = [];
    items.map((item) => {
      console.log("IND ITEM : ", item);

      var cgstCalculate = 0.0;
      var sgstCalculate = 0.0;
      var igstCalculate = 0.0;

      if (e.target.checked) {
        //if (item["product"] !== undefined) {
          console.log("CGST FROM EXISTING : ", item.cgst_perc);
          console.log(
            "TOTAL ITEM PRICE FROM EXISTING : ",
            item.total_item_price
          );
          cgstCalculate =
            (item.total_item_price * parseFloat(item.cgst_perc)) / 100;
          sgstCalculate =
            (item.total_item_price * parseFloat(item.sgst_perc)) / 100;
          igstCalculate =
            (item.total_item_price * parseFloat(item.igst_perc)) / 100;
        //}
      }
      item.cgst = cgstCalculate;
      item.sgst = sgstCalculate;
      item.igst = igstCalculate;

      console.log("CALCULATED CGST : ", cgstCalculate);
      console.log("CALCULATED SGST : ", sgstCalculate);
      console.log("CALCULATED IGST : ", igstCalculate);
      item.total_price =
        item.total_item_price + cgstCalculate + sgstCalculate + igstCalculate;
      console.log("CALCULATED TOTAL : ", item.total_price);

      itemModNew.push(item);
    });

    setGstBillStatus(e.target.checked);
    setItems(itemModNew);
  };

  const handleEdit = async (e) => {
    console.log("Handle Edit clicked : ", e);
    console.log("Handle Edit clicked current target ID: ", e.currentTarget.id);

    setIsReadOnly(false);
  };

  const getProductForLoad = async (productIdInput, prodIdIdx) => {
    var retState = false;
    var prodRes = {};
    console.log("get product for load called ... ");

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`, SerialKey: 'HNGD8743JESWE'};
      var res = await axios.get(client.domain + "/ims/getProduct?id=" + productIdInput, { headers }, { withCredentials: false });
      console.log("Response from server getProductForLoad : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getProductForLoad : ", res.data.detail.data);
        console.log("Response message from server getproductForLoad (Products) : ", res.data.detail.data);

        //setFormValues(res.data.detail.data);
        /*
        if ( res.data.detail.data.is_composite === true && res.data.detail.data.tt_key === 'SELL' && 'composite' in res.data.detail.data && res.data.detail.data.composite.length > 0 ) {
          setProductCompositionList(res.data.detail.data.composite);
        }
        */
        //setProductCompositionList(res.data.detail.data.composite);
        console.log("SELECTED PROD INDEX ID : ", prodIdIdx);
        console.log("SELECTED PROD DATA : ", items[prodIdIdx]);

        var selectedProdTmp2 = items[prodIdIdx];

        selectedProdTmp2.composition = res.data.detail.data.composite;

        var newItemsTmp2 = items.slice();
        newItemsTmp2[prodIdIdx] = selectedProdTmp2;
        setItems(newItemsTmp2);
        //setProductCompositionList(res.data.detail.data.composite);
        /*
        console.log("UOM array : ", uomOpt);
        var findUom = uomRef.current.find(element => element.value === res.data.detail.data.uom_key);
        console.log("**************** findUOM value ++++++++++++ : ", findUom);
        if (findUom !== undefined) {
          setUomIdSelect({ label: findUom.label, value: findUom.value })
        }
        */
        //setProdDetLoaded(true);
        //setToRender(true);
        //setEditAction(false);
        setShowConfigDialog(true);

        retState = true;
        prodRes = res.data.detail.data;
        console.log("PRODUCT LOAD STATUS SET TO TRUE")
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");

      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );

        }
      }
    }
    return [retState, prodRes];
  }

  const handleCompositeEdit = async (e, rowData) => {
    setEditAction(true);
    console.log("Handle Edit clicked FULL : ", e);
    console.log("Handle Edit clicked FULL currentTarget : ", e.currentTarget);
    console.log("Handle Edit clicked FULL target : ", e.target);
    var { id, name } = e.currentTarget;
    //var {id1, name1 } = e.currentTarget;
    console.log("id : ", id , ", name : " , name);
    //console.log("id : ", id, ", name : " , name);
    console.log("Handle Edit clicked FULL : ", rowData);
    
    //console.log("Handle Edit clicked currentTarget: ", rowData.currentTarget);
    //console.log("Handle Edit clicked target : ", rowData.target);
    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var productIdInput = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(0, clickedTagId.lastIndexOf("-"));
      console.log("current selected Edit element name : ", nameOfElement);
      console.log("Current SELECTED Edit id : ", productIdInput);
      console.log("CUR SELECTED Edit VALUE : ", items[productIdInput]);


      setSelectedCompositionId(productIdInput);
      /*
      let sliced = items.splice(productIdInput, 1);
      console.log("Edit item from order items: ", sliced);
      var newItem = items.slice();
      setItems(newItem);
      */
    }
    

    // get composition from api call
    //https://api.erp.avinasoft.in/ims/getProduct?id=6

    
    var selectedItem = items[productIdInput];
    var [retStateProdBuy, buyProdArr] =  await getProductBuy();
    if ( retStateProdBuy ) {
      setProductList(buyProdArr);
    }
    var productIdPass = items[productIdInput].product_id;
    selectedItem.enableState = false;
    selectedItem.editAction = true;
    if ( selectedItem.composition.length < 1 ) { 
      await getProductForLoad(productIdPass, productIdInput);
    }
    else {
      setShowConfigDialog(true);
    }

    setEditAction(false);
    selectedItem.enableState = true;
    //selectedItem.isEditable=true;
    selectedItem.editAction = false;

    if (!selectedItem.hasOwnProperty("composition") ) {
      console.log("********************** Product composition doesn't exist +++++++++++++++++ ");
      //selectedItem.composition = [];
    }
    else {
      console.log("******************* Product composition already exist in items ++++++++++++++");
    }

    const newitems = items.slice();
    newitems[productIdInput] = selectedItem;
    setItems(newitems);
  };


  const handleClear = async (e) => {
    e.preventDefault();
    console.log("handle clear called : ", e);
    console.log("Handle clear clicked current target ID: ", e.currentTarget.id);
    navigate(0);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    console.log("handle save called : ", e);
    console.log("Handle save clicked current target ID: ", e.currentTarget.id);
  };

  const handleKeyDown = async (e) => {
    console.log("handle key down called : ", e);
    return e.keyCode !== 190;
  };

  const onChangeDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);

    setFormValues({ ...formValues, ["order_date"]: e.target.value });
  };

  const newItemHandleComposition = async (event) => {
    event.preventDefault();
    console.log("New item addition button clicked : ", event);
    console.log("New item addition button clicked target :", event.target);
    console.log("New item addition button clicked currentTarget : ", event.currentTarget);

    /*
    var { name, id } = event.currentTarget;
    var currentId = name.substring(name.lastIndexOf("-") + 1);
    var nameOfElement = name.substring(0, name.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    var itemSelected = items[currentId];

    //console.log("Product: ", itemSelected.product);
    console.log("Product Name : ", itemSelected.name);
    console.log("Price : ", itemSelected.unit_price);
    console.log("Product cost : ", itemSelected.total_item_price);
    console.log("GST : ", parseFloat(itemSelected.cgst + itemSelected.sgst + itemSelected.igst));
    console.log("Total cost : ", itemSelected.total_price);
    console.log("Qty : ", itemSelected.quantity);

    console.log("Product Info : ", productMaster);
    const currentProductIndex = productMaster.findIndex(
      (productInd) => productInd.name === itemSelected.product_name);
    console.log("Currently selected row product id : ", currentProductIndex);
    if (currentProductIndex < 0) {
      itemSelected.error = "Please select product from list";
    }
    */
    //const newProductCompositionList = productCompositionList.slice();
    var selectedSellProdItem = items[selectedCompositionId];
    
    //let itemNew = { id: 1, product: { value: 0, label: "" }, unit_cost: "", qty: 1, "product_cost": "", "total_cost": "", gst: { cgst: 0.00, sgst: 0.00, igst: 0.00, totGst: 0.00 } };
    let itemNewComposite = {
      id: 0,
      product_id: 0,
      product_name: "",
      quantity: 0.00,
    };
    //selectedSellProdItem.composition = [];
    /*
    if ( selectedSellProdItem.composition.length < 1 ) {
      selectedSellProdItem.composition = [];
    }
    */
    selectedSellProdItem.composition.push(itemNewComposite);
    //selectedSellProdItem.composition.push();
    //newProductCompositionList.push(itemNew);
    //setProductCompositionList(newProductCompositionList);
    //itemNew.composition.push();
    //itemsNew[selectedCompositionId].composition.push(itemNewComposite);
    const itemsNew = items.slice();
    itemsNew[selectedCompositionId] = selectedSellProdItem;
    console.log("modified with composition array : ", selectedSellProdItem);
    console.log("item array : ", itemsNew);
    setItems(itemsNew);
  };
  const handleProductSelectComposition = (e, item, idx) => {
    console.log(">>>>>>>>>>>>>>>>>>>>>>> HandleProductSelect called *******************");
    console.log("SELECTED Dropdown value : ", e);
    console.log("SELECTED element full : ", item);
    console.log("SELECTED element idx : ", idx);
    //var currentId = data.name.substring(data.name.lastIndexOf("-") + 1);
    //console.log("Current id : ", currentId);
   // console.log("Product Info : ", productMaster);

    var itemSelected = items[idx];
    console.log("Passed element item : ", item);
    console.log("Item selected : ", itemSelected);


    items[selectedCompositionId].composition.push();
    /*
    if (itemSelected.product) {
      console.log("Product: ", itemSelected.product);
    }
    */
    //console.log("<<<<<<<<<<<<<<<<<<<<< ITEM ID >>>>>>>>>>>>>>>>>>: ", itemSelected.id);

    /*
    console.log("Product Info : ", productMaster);
    const currentProductIndex = productCompositionList.findIndex(
      (productInd) => productInd.name === e.label
    );
    */

    //console.log("Currently selected row product id : ", currentProductIndex);
    //console.log("Currently selected row product value : ", productCompositionList[currentProductIndex]);

    //itemSelected.product_id = e.id;
    //itemSelected.product_name = e.name;
    //itemSelected.uom = e.uom;
    //itemSelected.product.label = e.label;
    /*
    var productNew = {
      id: e.id,
      name: e.name,
    };
    console.log("Product Value : ", productNew);
    itemSelected.product = productNew;
    */

    /*
    const newProductCompositionList = productCompositionList.slice();
    newProductCompositionList[currentId] = itemSelected;
    setProductCompositionList(newProductCompositionList);
    */
    //setProductMaster(newSellProdArr);


  };
  const handleItemDeleteComposition = (e) => {
    console.log("handle item delete called ...", e);
    console.log("handle item delete e.currentTarget : ", e.currentTarget.id);
    console.log("handle item delete e.target : ", e.target.id);

    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(
        0,
        clickedTagId.lastIndexOf("-")
      );
      console.log("current selected DELETE element name : ", nameOfElement);
      console.log("Current SELECTED DELETE id : ", curSelId);
      console.log("CUR SELECTED DELETE VALUE : ", productCompositionList[curSelId]);

      let sliced = productCompositionList.splice(curSelId, 1);
      console.log("Removed item from order items: ", sliced);
      var newProductCompositionList = productCompositionList.slice();
      setProductCompositionList(newProductCompositionList);

    }
  };

  const handleCloseComposition = async () => {
    console.log("handle close composition called ...");
    setShowConfigDialog(false);
  }

  const handleSaveComposition = async () => {
    console.log("Handle Save compostion called ...");
    setShowConfigDialog(false);
    setCompositionUpdate(true);

    var selectedItem = items[selectedCompositionId];
    selectedItem.compositionUpdate = true;
    selectedItem.is_product_cc = true;

    var newItemsTmp = items.slice();
    newItemsTmp[selectedCompositionId] = selectedItem;
    setItems(newItemsTmp);
  }
  const handleCloseCompositionProduct = async () => {
    console.log("handle close composition  product called ...");
    navigate(0);

  }

  const handleOnItemChangeComposition = (e) => {
    //e.preventDefault();
    console.log("Button clicked : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);


    var currentId = name.substring(name.lastIndexOf("-") + 1);
    var nameOfElement = name.substring(0, name.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    var itemSelected = items[selectedCompositionId];
    console.log("selected composition for M grade products : ", itemSelected.composition);
    
    console.log("SELECTED COMPOSITION COMPONENt element : ", itemSelected.composition[currentId]);
    /*
    //console.log("Product: ", itemSelected.product);
    console.log("Product Name : ", itemSelected.product_name);
    //setFormValues({ ...formValues, [name]: value });
    console.log("Product Info : ", productCompositionList);
    const currentProductIndex = productCompositionList.findIndex(
      (productInd) => productInd.product_name === itemSelected.product_name
    );
    
    console.log("Currently selected row product id : ", currentProductIndex);
    if (currentProductIndex < 0) {
      itemSelected.error = "Please select product from list";

      const newProductCompositionList = productCompositionList.slice();
      newProductCompositionList[currentId] = itemSelected;
      setProductCompositionList(newProductCompositionList);
      return 0;
    } else {
      itemSelected.error = "";
    }

    console.log("Currently selected row product value : ", productCompositionList[currentProductIndex]);
    */

    /*
    itemSelected.product = {
      id: productList[currentProductIndex].id,
      name: productList[currentProductIndex].name,
      
    };
    */
    //itemSelected.unit_cost = productMaster[currentProductIndex].unit_cost;

    if (nameOfElement === "quantity") {
      console.log("Product Quantity item edited ...");

      // const regex =  /^[0-9\b]+$/;
      /*
       const numericRegExp = /^\d*\.?\d*$/;
       //if (numericRegExp.match(e.target.value)) {
       if (e.currentTarget.value.match(numericRegExp)) {
         itemSelected[nameOfElement] = e.currentTarget.value;
       }
       */
      //const numericRegExp = /^\d*\.?\d*$/;
      if (e.currentTarget.value.match(appGlobal.numericRegExp)) {
        //itemSelected[nameOfElement] = e.currentTarget.value.replace(/^0+/, '');
        itemSelected.composition[currentId].quantity = value;
      }
    } else {
      console.log("Other than handled option clicked so skipping it ...");
    }

    //const newProductCompositionList = productCompositionList.slice();
    //newProductCompositionList[currentId] = itemSelected;
    //setProductCompositionList(newProductCompositionList);
    var newItemsTmp = items.slice();
    newItemsTmp[selectedCompositionId] = itemSelected;
    setItems(newItemsTmp);
  };

  const handleAlertClose = () => {
    console.log("Handle alert close called ...");
    setShowAlert(false);
    if ( alertPurpose === 'success') {
      navigate("/listOrders");
    }
  }

  const handleAlertOK = () => {
    console.log("Handle alert close called ...");
    setShowAlert(false);
    if ( alertPurpose === 'success') {
      navigate("/listOrders");
    }
    else if( alertPurpose === 'confirmation') {
      //navigate(0);
      formValues["customer_name"] = "";
      formValues["customer_disp_name"] = "";
      formValues["gst_number"] = "";
      formValues["phone"] = "";
      formValues["primary_number"] = "";
      formValues["secondary_number"] = "";
      formValues["contact_person"] = "";
      formValues["contact_number"] = "";
      formValues["email"] = "";
      formValues["address1"] = "";
      formValues["address2"] = "";
      //formValues["distId"] = "";
      //formValues["stateId"] = "";
      formValues["pincode"] = "";
      formValues["website"] = "";
      formValues["shipContactName"] = "";
      formValues["shipContactNumber"] = "";
      formValues["shipAddress1"] = "";
      formValues["shipAddress2"] = "";
      formValues["shipPincode"] = "";
      formValues["billContactName"] = "";
      formValues["billContactNumber"] = "";
      formValues["billAddress1"] = "";
      formValues["billAddress2"] = "";
      setFormValues({ ...formValues, ["billPincode"]: ""});
    }
  }
  const handleCloseDialog = () => {
    console.log("Dilaog canceled ....");
    setShowAlert(false);
  }
  const handleReset = async (e) => {
    e.preventDefault();
    console.log("handle clear called : ", e);
    console.log("Handle clear clicked current target ID: ", e.currentTarget.id);
    setDistrictIdSelect(optDist[0]);
    setStateIdSelect(optionsState[0]);
    setAlertPurpose('confirmation');
      setShowAlert(true);
      setAlertDialogTitle("Confirmation");
      setAlertDialogBody("Are you sure want to Reset  Customer Details and Address");
      
    //navigate(0);
  };
 
  return (
    <>
      {((addressFetched && orderDetFetched && orderItemFetched) ||
        state.action === "add") && (
          <Container>
            <SidebarMain />
            { toRender ?
        (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col xs={4} md={4} sm={4} lg={4}>
                  <span style={{ fontSize: "1.5rem", fontWeight: "500" }}>
                    Order
                  </span>
                  {(state.action === "view" || state.action === "edit") && (
                    <>
                      &nbsp;&nbsp;
                      <Badge pill bg="success">
                        <h6>{orderInfoDet.order_no}</h6>
                      </Badge>
                    </>
                  )}
                </Col>
                <Col
                  xs={8}
                  md={8}
                  sm={8}
                  lg={8}
                  className="d-flex justify-content-end float-end"
                >
                  <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                    <BsArrowLeftCircle
                      key={`back-btn-order-key`}
                      size={24}
                      style={{ cursor: "pointer" }}

                      name={`back-icon-order-name`}
                      id={`edit-icon-order-id`}
                    />
                  </Button>
                  {(state.action === "edit" || state.action === "view") && (
                    <>
                      &nbsp;&nbsp;
                      <Button
                        variant="primary"
                        className="btn btn-primary btn-md"
                        onClick={(e) => handleEdit(e)}

                      >
                        <BsPencilSquare
                          key={`edit-btn-order-key`}
                          size={24}
                          style={{ cursor: "pointer" }}

                          name={`edit-btn-order-name`}
                          id={`edit-btn-order-id`}
                        />
                      </Button>
                    </>
                  )}
                </Col>
              </Row>

              <p></p>

              <div className="ui-form">
                <hr align="center" />

                <Row>
                  <Col xs={6} md={6} sm={6} lg={6}>
                    <h4 style={{ color: "#4d4d4d" }}>
                      <Badge pill bg="secondary">
                        Customer Details
                      </Badge>
                    </h4>
                  </Col>
                </Row>
                <hr align="center" />

                <Row>
                  <Col xs={3} md={3} sm={3} lg={3}>
                    <Form.Group
                      className="mb-3"
                      controlId="orderform.customer_name"
                    >
                      <Form.Label className="field required">Customer Name</Form.Label>
                      <AsyncTypeahead
                        id="customer-search-fd-id"
                        name="customer_name"
                        isLoading={isLoading}
                        onSearch={(query) => {
                          searchHandleFunc(query);
                        }}
                        onChange={(selected) => {
                          customerSearchSelectFunc(selected);
                        }}
                        options={optionsSearch}
                        placeholder="Search Customer ..."
                        readOnly={state.action === 'add' ? isReadOnly : true}
                        style={{
                          /*border: !isReadOnly && "0px solid gray",*/
                          borderColor: "gray",
                          /*borderStyle: "solid",*/
                        }}
                        className="rounded-sm"
                      />
                    </Form.Group>

                    <p className="error-msg">{formErrors.customer_name}</p>
                    </Col>
                    
                    <Col xs={1} md={1} sm={1} lg={1}>
                    <Button
                        className="btn btn-secondary"
                        id="btn-clear-order-id"
                        name="btn-clear-order-name"
                        onClick={handleReset}
                        size={"sm"}
                      >
                        Clear
                      </Button>
                  </Col>
                  
                  <Col xs={2} md={2} sm={2} lg={2}>
                    {/*
                  <div className="field ">
                    <label>Display Name :</label>
                  </div>
                  */}
                    <Form.Group className="mb-3" controlId="orderform.dispName">
                      <Form.Label className="field required">Display Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="customer_disp_name"
                        placeholder="Display Name"
                        value={formValues.customer_disp_name}
                        onChange={handleValueChange}
                        readOnly={state.action === 'add' ? isReadOnly : true}
                        disabled={state.action !== 'add' || isLoading }
                        style={{
                          border: !isReadOnly && "1px solid gray",
                        }}
                      />
                    </Form.Group>
                    <p className="error-msg">{formErrors.customer_disp_name}</p>
                  </Col>
                  <Col xs={2} sm={2} md={2} lg={2}>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="orderform.orderDate"
                    >
                      <Form.Label>Order date</Form.Label>
                      <Form.Control
                        type="date"
                        name="order_date"
                        placeholder="Order Date"
                        value={formValues.order_date}
                        onChange={onChangeDatePicker}
                        readOnly={state.action === 'add' ? isReadOnly : true}
                        disabled={state.action !== 'add' || isLoading}
                        style={{
                          border: !isReadOnly && "1px solid gray",
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col xs={2} md={2} sm={2} lg={2}>
                    <Form.Group className="mb-3" controlId="orderform.gst_number">
                      <Form.Label>GST / PAN Number</Form.Label>

                      <Form.Control
                        type="text"
                        name="gst_number"
                        placeholder="GST / PAN Number"
                        value={formValues.gst_number}
                        onChange={handleValueChange}
                        readOnly={state.action === 'add' ? isReadOnly : true}
                        disabled={state.action !== 'add' || isLoading}
                        style={{
                          border: !isReadOnly && "1px solid gray",
                        }}
                      />
                    </Form.Group>

                    <p className="error-msg">{formErrors.gst_number}</p>
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2}>
                    <Form.Group className="mb-3" controlId="orderform.phone">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        type="text"
                        name="phone"
                        placeholder="Phone"
                        value={formValues.phone}
                        onChange={handleValueChange}
                        maxLength={10}
                        readOnly={state.action === 'add' ? isReadOnly : true}
                        disabled={state.action !== 'add' || isLoading}
                        style={{
                          border: !isReadOnly && "1px solid gray",
                        }}
                      />
                    </Form.Group>

                    <p className="error-msg">{formErrors.phone}</p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3} md={3} sm={3} lg={3}>
                    <Form.Group className="mb-3" controlId="orderform.address1">
                      <Form.Label className="field required">
                        Street / Location
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="address1"
                        placeholder="Building No / Street Name"
                        value={formValues.address1}
                        onChange={handleValueChange}
                        readOnly={state.action === 'add' ? isReadOnly : true}
                        disabled={state.action !== 'add' || isLoading}
                        style={{
                          border: !isReadOnly && "1px solid gray",
                        }}
                      />
                    </Form.Group>

                    <p className="error-msg">{formErrors.address1}</p>
                  </Col>

                  <Col xs={3} md={3} sm={3} lg={3}>
                    <Form.Group className="mb-3 " controlId="orderform.address2">
                      <Form.Label className="field">City / Town</Form.Label>

                      <Form.Control
                        type="text"
                        name="address2"
                        placeholder="City / Town"
                        value={formValues.address2}
                        onChange={handleValueChange}
                        readOnly={state.action === 'add' ? isReadOnly : true}
                        disabled={state.action !== 'add' || isLoading}
                        style={{
                          border: !isReadOnly && "1px solid gray",
                        }}
                      />
                    </Form.Group>

                    <p className="error-msg">{formErrors.address2}</p>
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2}>
                    <Form.Group className="mb-3" controlId="orderform.district">
                      <Form.Label>District</Form.Label>

                      <Select
                        id="district"
                        name="district"
                        value={{
                          value: districtIdSelect.value,
                          label: districtIdSelect.label,
                        }}
                        options={optDist}
                        defaultValue={{
                          value: districtIdSelect.value,
                          label: districtIdSelect.label,
                        }}
                        onChange={handleDistrictSelect}
                        readOnly={state.action === 'add' ? isReadOnly : true}
                        isDisabled={state.action !== 'add' || isLoading}
                        style={{
                          border: !isReadOnly && "1px solid gray",
                        }}
                      />
                    </Form.Group>

                    <p className="error-msg">{formErrors.district}</p>
                  </Col>
                  

                  <Col xs={2} md={2} sm={2} lg={2}>
                    <Form.Group className="mb-3" controlId="orderform.state">
                      <Form.Label>State</Form.Label>

                      <Select
                        id="state"
                        name="state"
                        value={{
                          value: stateIdSelect.value,
                          label: stateIdSelect.label,
                        }}
                        options={optionsState}
                        defaultValue={{
                          value: stateIdSelect.value,
                          label: stateIdSelect.label,
                        }}
                        onChange={handleStateSelect}
                        readOnly={state.action === 'add' ? isReadOnly : true}
                        isDisabled={state.action !== 'add' || isLoading}
                        style={{
                          border: !isReadOnly && "1px solid gray",
                        }}
                      />
                    </Form.Group>

                    <p className="error-msg">{formErrors.state}</p>
                  </Col>
                  <Col xs={2} md={2} sm={2} lg={2}>
                    <Form.Group className="mb-3" controlId="orderform.pincode">
                      <Form.Label>Pincode</Form.Label>

                      <Form.Control
                        type="text"
                        name="pincode"
                        placeholder="Pincode"
                        value={formValues.pincode}
                        onChange={handleValueChange}
                        maxLength={6}
                        readOnly={state.action === 'add' ? isReadOnly : true}
                        disabled={state.action !== 'add' || isLoading}
                        style={{
                          border: !isReadOnly && "1px solid gray",
                        }}
                      />
                    </Form.Group>

                    <p className="error-msg">{formErrors.pincode}</p>
                  </Col>
                </Row>

                <Row>
                  <Col xs={3} md={3} sm={3} lg={3}>
                    <Form.Group
                      className="mb-3 "
                      controlId="orderform.contact_person"
                    >
                      <Form.Label>Contact Person</Form.Label>

                      <Form.Control
                        type="text"
                        name="contact_person"
                        placeholder="Contact Person Name"
                        value={formValues.contact_person}
                        onChange={handleValueChange}
                        readOnly={state.action === 'add' ? isReadOnly : true}
                        disabled={state.action !== 'add' || isLoading}
                        style={{
                          border: !isReadOnly && "1px solid gray",
                        }}
                      />
                    </Form.Group>

                    <p className="error-msg">{formErrors.contact_person}</p>
                  </Col>
                  <Col xs={3} md={3} sm={3} lg={3}>
                    <Form.Group
                      className="mb-3 "
                      controlId="orderform.contact_number"
                    >
                      <Form.Label>Contact Number</Form.Label>

                      <Form.Control
                        type="text"
                        name="contact_number"
                        placeholder="Contact  Person Number"
                        value={formValues.contact_number}
                        onChange={handleValueChange}
                        maxLength={10}
                        readOnly={state.action === 'add' ? isReadOnly : true}
                        disabled={state.action !== 'add' || isLoading}
                        style={{
                          border: !isReadOnly && "1px solid gray",
                        }}
                      />
                    </Form.Group>

                    <p className="error-msg">{formErrors.contact_number}</p>
                  </Col>

                  <Col xs={3} md={3} sm={3} lg={3}>
                    <Form.Group className="mb-3" controlId="orderform.email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder="Email"
                        value={formValues.email}
                        onChange={handleValueChange}
                        readOnly={state.action === 'add' ? isReadOnly : true}
                        disabled={state.action !== 'add' || isLoading}
                        style={{
                          border: !isReadOnly && "1px solid gray",
                        }}
                      />
                    </Form.Group>

                    <p className="error-msg">{formErrors.email}</p>
                  </Col>

                  <Col xs={3} md={3} sm={3} lg={3}>
                    <Form.Group className="mb-3" controlId="orderform.website">
                      <Form.Label>Website</Form.Label>
                      <Form.Control
                        type="text"
                        name="website"
                        placeholder="Website"
                        value={formValues.website}
                        onChange={handleValueChange}
                        readOnly={state.action === 'add' ? isReadOnly : true}
                        disabled={state.action !== 'add' || isLoading}
                        style={{
                          border: !isReadOnly && "1px solid gray",
                        }}
                      />
                    </Form.Group>

                    <p className="error-msg">{formErrors.website}</p>
                  </Col>
                </Row>
                <p></p>

                <div className="ui-divider"></div>
                <div className="ui-form"></div>

                <Row className="show-grid">
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <Row>
                      <Col xs={6} md={6} sm={6} lg={6}>
                        <h4 style={{ color: "#4d4d4d" }}>
                          <Badge pill bg="secondary">
                            Delivery Address
                          </Badge>
                        </h4>
                      </Col>
                      <Col
                        xs={6}
                        md={6}
                        sm={6}
                        lg={6}
                        className="float-center justify-content-center"
                      >
                        <div className="d-flex justify-content-center">
                          <Form.Check
                            label="Same as Customer Address"
                            name="address-copy"
                            id="id-addr-copy-ship"
                            //defaultChecked= {addrCopyCheck}
                            type="checkbox"
                            className="checkCopy"
                            checked={addrCopyCheck}
                            onChange={handleCopyToShipping}
                            ></Form.Check>
                            {/*
                            style={{ 
                              //borderBottomWidth: 1,borderLeftWidth: 1,borderRightWidth:1,borderTopWidth:1,
                              //borderRadius: "8px",
                              //borderColor: "black",
                              //marginLeft: "15px",
                              //marginRight: "15px",
                              //width: "0.30em",
                              //height: "0.30em",
                              //borderRadius: "0.15em",
                              //marginRight: "0.1em",
                              //border: "0.15em solid #007a7e",
                              //outline: "none",
                              boxShadow: "0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 255, 0, 0.25"
                              //cursor: pointer;
                            
                              }}
                            */}  
                          
                        </div>
                      </Col>
                    </Row>
                    <hr align="center" />

                    <Row>
                      <Col xs={6} sm={6} md={6} lg={6}>
                        <Form.Group
                          className="mb-3"
                          controlId="orderform.shipContactName"
                        >
                          <Form.Label>
                            Contact Name
                          </Form.Label>

                          <Form.Control
                            className="col-md-3 "
                            type="text"
                            name="shipContactName"
                            placeholder="Contact   Name"
                            value={formValues.shipContactName}
                            onChange={handleValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: !isReadOnly && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrors.shipContactName}</p>
                      </Col>
                      <Col xs={6} sm={6} md={6} lg={6}>
                        <Form.Group
                          className="mb-3 "
                          controlId="orderform.shipContactNumber"
                        >
                          <Form.Label>
                            Contact Number
                          </Form.Label>

                          <Form.Control
                            className="col-md-3"
                            type="text"
                            name="shipContactNumber"
                            placeholder="Contact Number"
                            value={formValues.shipContactNumber}
                            onChange={handleValueChange}
                            readOnly={isReadOnly}
                            maxLength={10}
                            style={{
                              border: !isReadOnly && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">
                          {formErrors.shipContactNumber}
                        </p>
                      </Col>
                    </Row>

                    <div className="ui-divider"></div>
                    <Row>
                      <Col xs={6} sm={6} md={6} lg={6}>
                        <Form.Group
                          className="mb-3"
                          controlId="orderform.shipAddress1"
                        >
                          <Form.Label
                            className="col-md-6 field required"
                            style={{ paddingTop: "10px" }}
                          >
                            Street / Location
                          </Form.Label>

                          <Form.Control
                            className="col-md-3"
                            type="text"
                            name="shipAddress1"
                            placeholder="Building No / Street Name"
                            value={formValues.shipAddress1}
                            onChange={handleValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: !isReadOnly && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrors.shipAddress1}</p>
                      </Col>
                      <Col xs={6} sm={6} md={6} lg={6}>
                        <Form.Group
                          className="mb-3 "
                          controlId="orderform.shipAddress2"
                        >
                          <Form.Label
                            className="col-md-6 field"
                            style={{ paddingTop: "10px" }}
                          >
                            City / Town
                          </Form.Label>

                          <Form.Control
                            className="col-md-3"
                            type="text"
                            name="shipAddress2"
                            placeholder="City / Town"
                            value={formValues.shipAddress2}
                            onChange={handleValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: !isReadOnly && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrors.shipAddress2}</p>
                      </Col>
                    </Row>

                    {/*<label className="col-md-2" style={{paddingTop: "10px"}}>District: </label>*/}

                    <Row>
                      <Col
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                      //style={{ paddingTop: "2px" }}
                      >
                        <Form.Group
                          className="mb-3"
                          controlId="orderform.pincode"
                        >
                          <Form.Label column sm={2} >
                            Pincode
                          </Form.Label>

                          <Form.Control
                            type="text"
                            name="shipPincode"
                            placeholder="Pincode"
                            value={formValues.shipPincode}
                            onChange={handleValueChange}
                            maxLength={6}
                            readOnly={isReadOnly}
                            style={{
                              border: !isReadOnly && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrors.shipPincode}</p>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={6} sm={6} md={6} lg={6}>
                    <Row>
                      <Col xs={6} md={6} sm={6} lg={6}>
                        <h4 style={{ color: "#4d4d4d" }}>
                          <Badge pill bg="secondary">
                            Billing Address
                          </Badge>
                        </h4>
                      </Col>
                      <Col
                        xs={6}
                        md={6}
                        sm={6}
                        lg={6}
                        className="float-center justify-content-center"
                      >
                        <div className="d-flex justify-content-center">
                          <Form.Check
                            label="Same as Delivery Address"
                            name="address-copy"
                            id="id-addr-copy-bill"
                            type="checkbox"
                            className="checkCopy"
                            //defaultChecked=
                            checked={addrCopyBillCheck}
                            onChange={handleCopyToBilling}
                          ></Form.Check>
                        </div>
                      </Col>
                    </Row>
                    {/*
                  <Row style={{ height: props.height, paddingLeft: "10px" }}>
                    <hr />
                  </Row>
                  */}
                    <hr align="center" />
                    <Row>
                      <Col xs={6} sm={6} md={6} lg={6}>
                        <Form.Group
                          className="mb-3"
                          controlId="orderform.billContactName"
                        >
                          <Form.Label>
                            Contact Name
                          </Form.Label>

                          <Form.Control
                            className="col-md-3"
                            type="text"
                            name="billContactName"
                            placeholder="Contact Name"
                            value={formValues.billContactName}
                            onChange={handleValueChange}
                            maxLength={10}
                            readOnly={isReadOnly}
                            style={{
                              border: !isReadOnly && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrors.billContactName}</p>
                      </Col>
                      <Col xs={6} sm={6} md={6} lg={6}>
                        <Form.Group
                          className="mb-3"
                          controlId="orderform.billContactNumber"
                        >
                          <Form.Label>
                            Contact Number
                          </Form.Label>

                          <Form.Control
                            className="col-md-3"
                            type="text"
                            name="billContactNumber"
                            placeholder="Contact Number"
                            value={formValues.billContactNumber}
                            maxLength={10}
                            onChange={handleValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: !isReadOnly && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">
                          {formErrors.billContactNumber}
                        </p>
                      </Col>
                    </Row>

                    <div className="ui-divider"></div>
                    <Row>
                      <Col xs={6} sm={6} md={6} lg={6}>
                        <Form.Group
                          className="mb-2"
                          controlId="orderform.billAddress1"
                        >
                          <Form.Label
                            className="col-md-6 field required"
                            style={{ paddingTop: "10px" }}
                          >
                            Street / Location
                          </Form.Label>

                          <Form.Control
                            className="col-md-3 field"
                            type="text"
                            name="billAddress1"
                            placeholder="Building No / Street Name"
                            value={formValues.billAddress1}
                            onChange={handleValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: !isReadOnly && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrors.billAddress1}</p>
                      </Col>
                      <Col xs={6} sm={6} md={6} lg={6}>
                        <Form.Group
                          className="mb-3"
                          controlId="orderform.billAddress2"
                        >
                          <Form.Label
                            className="col-md-6 field"
                            style={{ paddingTop: "10px" }}


                          >
                            City / Town
                          </Form.Label>

                          <Form.Control
                            className="col-md-3"
                            type="text"
                            name="billAddress2"
                            placeholder="City / Town"
                            value={formValues.billAddress2}
                            onChange={handleValueChange}
                            readOnly={isReadOnly}
                            style={{
                              border: !isReadOnly && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrors.billAddress2}</p>
                      </Col>
                    </Row>

                    {/*<label className="col-md-2" style={{paddingTop: "10px"}}>District: </label>*/}
                    <Row>
                      <Col
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                      // style={{ paddingTop: "10px" }}
                      >
                        <Form.Group
                          className="mb-3"
                          controlId="orderform.billPincode"
                        >
                          <Form.Label column sm={2}>
                            Pincode
                          </Form.Label>

                          <Form.Control
                            type="text"
                            name="billPincode"
                            placeholder="Pincode"
                            value={formValues.billPincode}
                            onChange={handleValueChange}
                            maxLength={6}
                            readOnly={isReadOnly}
                            style={{
                              border: !isReadOnly && "1px solid gray",
                            }}
                          />
                        </Form.Group>
                        <p className="error-msg">{formErrors.billPincode}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <p></p>

                <Row>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <div className="d-flex justify-content-start">
                      <h4>
                        <Badge pill bg="secondary">
                          Item Details
                        </Badge>
                      </h4>
                      <p
                        className="error-msg"
                        style={{
                          position: "relative",
                          //top: "5px",
                          fontWeight: "bold",
                        }}
                      >
                        {formErrors.alertMsg}
                      </p>
                    </div>
                  </Col>

                  <Col xs={6} md={6} sm={6} lg={6}>
                    <div className="d-flex justify-content-end">
                      <Form.Check
                        style={{ paddingTop: "5px" }}
                        label="GST Bill"
                        name="gst-bill-status-name"
                        id="gst-bill-status-id"
                        checked={gstBillStatus}
                        onChange={handleGSTBillStatus}
                      ></Form.Check>
                      &nbsp;&nbsp;
                      <Button
                        className="btn btn-warning"
                        style={{ alignItems: "self-end" }}
                        onClick={newItemHandle}
                      >
                        Add Item
                      </Button>
                    </div>
                  </Col>
                </Row>
                <hr align="center" />
                <p></p>

                {items.length > 0 && (
                  <>
                    <Table striped bordered hover>
                      <thead>
                        <tr className="table-primary">
                          <th className="table-row-heading">S.No</th>
                          <th className="table-row-heading">Product</th>
                          <th className="table-row-heading">Qty</th>
                          <th className="table-row-heading">Unit Price</th>
                          <th className="table-row-heading">Product Price</th>
                          <th className="table-row-heading">GST</th>
                          <th className="table-row-heading">Total Price</th>
                          <th className="table-row-heading">***</th>
                        </tr>
                      </thead>

                      <tbody>
                        {items.map((item, idx) => (
                          <tr>
                            <td>{idx + 1}</td>
                            <td className="col-2"
                            >
                              <Row>
                                <Col sm={9} md={9} xs={12} lg={9}>
                                  <Select
                                    className="mb-1"
                                    id={`product-item-${idx}`}
                                    name={`product-item-${idx}`}
                                    //value={options.value}
                                    value={{
                                      value: item.product_id,
                                      label: item.product_name,
                                    }}
                                    options={productMaster}
                                    defaultValue={{
                                      value: item.product_id,
                                      label: item.product_name,
                                    }}
                                    onChange={handleProductSelect}
                                  />
                                </Col>
                                <Col sm={3} md={3} xs={12} lg={3}>
                                  <Button
                                    column sm="3"
                                    variant="primary"
                                    className="btn btn-primary btn-sm"
                                    onClick={handleCompositeEdit}
                                    name={`edit-btn-order-name-${idx}`}
                                    id={`edit-btn-order-id-${idx}`}
                                    disabled={(item.enableState || (!isReadOnly && (state.action === 'view' || state.action === 'edit'))) ? false : true}
                                  //disabled={submitAction ? true: false}

                                  >
                                    {item.editAction ?
                                      <>
                                        <Spinner
                                          as="span"
                                          animation="border"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                        <span className="visually-hidden">Loading...</span>
                                      </> :
                                      ''}
                                    {!item.editAction &&
                                      <BsPencilSquare
                                        key={`edit-btn-order-key`}
                                        size={12}
                                        style={{ cursor: "pointer" }}
                                      />
                                    }
                                  </Button>
                                </Col>
                              </Row>
                              <p className="error-msg">{item.error}</p>
                            </td>

                            <td className="col-2">
                              {/*
                            <Form.Group as={Row} controlId="m3">
                              <Form.Control
                                style={{ padding: "5px", textAlign: "right" }}
                                type="text"
                                name={`quantity-${idx}`}
                                placeholder="Quantity"
                                value={item.quantity}
                                //max="100000"
                                maxLength={5}
                                onChange={handleChange}
                              />
                              <Form.Label column sm={2}>
                                m3
                              </Form.Label>
                            </Form.Group>
                            */}
                              {/*
                            <Form.Group
                              as={Row}
                              controlId="m3"
                              className="w-50"
                            >
                              <Form.Control
                                width={"50%"}
                                style={{ padding: "5px", textAlign: "right" }}
                                type="text"
                                name={`quantity-${idx}`}
                                placeholder="Quantity"
                                value={item.quantity}
                                maxLength={5}
                                onChange={handleChange}
                              ></Form.Control>
                              <Form.Label>
                                M<sup>3</sup>
                              </Form.Label>
                            </Form.Group>
                            */}
                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formPlaintextEmail"
                              >
                                <Col sm="9">
                                  <Form.Control
                                    style={{ padding: "5px", textAlign: "right" }}
                                    type="text"
                                    name={`quantity-${idx}`}
                                    placeholder="Quantity"
                                    value={item.quantity}
                                    maxLength={5}
                                    onChange={handleChange}
                                    defaultValue={item.quantity}
                                  />
                                </Col>
                                <Form.Label column sm="3">
                                  M<sup>3</sup>
                                </Form.Label>
                              </Form.Group>
                              <p className="error-msg">{item.error_qty}</p>
                            </td>

                            <td className="col-2">
                              <Form.Control
                                style={{ padding: "5px", textAlign: "right" }}
                                type="text"
                                name={`unit_price-${idx}`}
                                placeholder="Unit Price"
                                value={item.unit_price}
                                maxLength={10}
                                onChange={handleChange}
                              />
                            </td>

                            <td
                              style={{
                                width: "120px",
                                padding: "5px",
                                textAlign: "right",
                              }}
                            >
                              {item.total_item_price.toFixed(2)}
                            </td>
                            <td
                              style={{
                                width: "100px",
                                padding: "5px",
                                textAlign: "right",
                                //overflow: "auto",
                                //display: "block",
                                //width: "auto !important",
                                //tableLayout: "auto !important",
                              }}
                            >
                              {(item.cgst + item.sgst + item.igst).toFixed(2)}
                            </td>
                            <td
                              style={{
                                width: "120px",
                                padding: "5px",
                                textAlign: "right",
                              }}
                            >
                              {item.total_price.toFixed(2)}
                            </td>
                            <td>
                              <BsFillXCircleFill
                                size={28}
                                style={{ color: "red", cursor: "pointer" }}
                                onClick={(e) => handleItemDelete(e)}
                                name={`item-delete-icon-${idx}`}
                                id={`item-delete-icon-${idx}`}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    <Row>
                      <Col xs={3} md={3} sm={3}>
                        <div>
                          <span style={{ fontWeight: "bold" }}>
                            Total Items :
                          </span>
                          <span> {total_items.toFixed(2)}</span>
                        </div>
                      </Col>
                      <Col xs={3} md={3} sm={3}>
                        <div>
                          <span style={{ fontWeight: "bold" }}>
                            Total Product Cost :
                          </span>
                          <span>{sum_product_cost.toFixed(2)}</span>
                        </div>
                      </Col>
                      <Col xs={3} md={3} sm={3}>
                        <div>
                          <span style={{ fontWeight: "bold" }}>Total GST :</span>
                          <span>{sum_product_gst.toFixed(2)}</span>
                        </div>
                      </Col>
                      <Col xs={3} md={3} sm={3}>
                        <div>
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            Total Cost :
                          </span>
                          <span>{sum_product_total.toFixed(2)}</span>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
                <Row>
                  <Col xs={12} md={12} sm={12}>
                    <div className="d-flex justify-content-end">
                      <Button
                        className="btn btn-secondary"
                        id="btn-clear-order-id"
                        name="btn-clear-order-name"
                        onClick={handleClear}
                      >
                        Clear
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        type="submit"
                        className="btn btn-primary"
                        id="btn-save-order-id"
                        name="btn-save-order-name"
                        disabled={submitAction ? true: false}
                        //onClick={(e) => handleSave(e)}

                      >
                        {submitAction &&
                      <>
                      <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                     <span className="visually-hidden">Loading...</span>
                     </> 
                      }
                     Save Order     
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Form>
             )
             :
             <div className="container-spinner">
             <div className="vertical-center-spinner">
              {/*<Button variant="primary" disabled>*/}
              <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
                 <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
                     <span className="visually-hidden">Loading...</span>
                   </Spinner>
        
              {/*</div></Button>*/}
              </div>
              </div>
           }
          </Container>
        )}
       
      <Modal show={showConfigDialog}>
        <Modal.Header>
          <Modal.Title>Composition Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col xs={8} md={8} sm={8} lg={8}>
                <h5>Composite  product - <span style={{color: 'green', fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{ selectedCompositionId > -1 && items[selectedCompositionId].product_name }</span></h5>
              </Col>
              {/*
              <Col xs={6} md={6} sm={6}>
                <Button
                  className="btn btn-sm btn-warning d-flex justify-content-end pull-right float-end"
                  style={{ alignItems: "self-end" }}
                  onClick={newItemHandleComposition}
                //disabled={(disableComposite || isReadOnly) ? true : false}
                >
                  Add Item
                </Button>

              </Col>
              */}
            </Row>
            <Table
              striped bordered hover
              size="sm">
              <thead>
                <tr className="table-primary">
                  <th className="table-row-heading">S.No</th>
                  <th className="table-row-heading">Product</th>
                  <th className="table-row-heading">Qty</th>
                  <th className="table-row-heading">Uom</th>
                  {/*<th className="table-row-heading">...</th>*/}
                </tr>
              </thead>

              <tbody>
                {/*{productCompositionList.map((item, idx) => (*/}
                {(selectedCompositionId > -1 && 'composition' in items[selectedCompositionId]) &&
                items[selectedCompositionId].composition.map((item, idx) => (
                  <tr key={`prod-composition-table-${idx}`}>
                    <td className="w-25">{idx + 1}</td>
                    <td className="w-50">
                      <Select
                        id={`composite-product-item-${idx}`}
                        name={`composite-product-item-${idx}`}
                        //value={options.value}
                        value={{
                          value: item.product_id,
                          label: item.product_name,
                        }}
                        options={productList}
                        defaultValue={{
                          value: item.product_id,
                          label: item.product_name,
                        }}
                        //isDisabled={(disableComposite || isReadOnly)? true: false}
                        isDisabled={true}
                        onChange={(e, item) => handleProductSelectComposition(e, item, idx)}
                      />
                      <p className="error-msg">{item.error}</p>
                    </td>
                    <td className="w-25">
                      <Form.Group className="mb-3">
                        <Form.Control
                          //pattern="^[0-9]*\.[0-9]{2}$"
                          //step=".01"
                          type="text"
                          size="sm"
                          id={`quantity-${idx}`}
                          name={`quantity-${idx}`}
                          placeholder="Quantity"
                          onChange={(e) => handleOnItemChangeComposition(e)}
                          readOnly={isReadOnly}
                          value={item.quantity}
                          //disabled={(disableComposite || isReadOnly)? true: false}
                          //defaultValue={item.quantity}

                          style={{
                            border: !isReadOnly && "1px solid gray",
                          }}

                        />
                      </Form.Group>
                    </td>
                    <td>{item.uom}</td>
                    {/*
                    <td>
                      <BsFillXCircleFill
                        size={28}
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={(e) => handleItemDeleteComposition(e)}
                        name={`item-delete-icon-${idx}`}
                        id={`item-delete-icon-${idx}`}
                      />
                    </td>
                    */}
                  </tr>
                ))
                }
              </tbody>
            </Table>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseComposition}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveComposition}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAlert}>
        <Modal.Header>
          <Modal.Title>{alertDialogTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{alertDialogBody}</Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseDialog}>Cancel</Button>
          <Button variant="primary" onClick={handleAlertOK}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddUpdateOrder;
