
import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  Label,
  Badge,
  Table,
  Spinner,
  Image,
  Modal
} from "react-bootstrap";
import "../Dashboard.css";
import "../App.css";
import { useNavigate } from "react-router-dom";

import Select from "react-select";

import { client, ConfigMinValue, configMaxValue, appGlobal } from "../config/Config";
import axios from "axios";

import { useLocation } from "react-router-dom";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import { BsCartX, BsArrowLeftCircle, BsFillXCircleFill, BsFillPrinterFill } from "react-icons/bs";
import SidebarMain from "./SidebarMain";

import { formatDate, formatTime } from "./utils";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';

const AddUpdateReceipt = (async) => {
    const intialValues = {
        receipt_number: "RCPT-1001",
        received_from: "",
        received_amount: 0.00,
        received_towards: "",
        received_against: "",
        receipt_date: formatDate(new Date()),
        receipt_time: formatTime(new Date()),
        received_mode: "CASH"
      };
      const [formValues, setFormValues] = useState(intialValues);
      const [formErrors, setFormErrors] = useState({});
      const [isSubmit, setIsSubmit] = useState(false);
      const [receivedSaved, setReceivedSaved] = useState(false);
      const [toRender, setToRender] = useState(false);
      const [pendingOrderList, setPendingOrderList] = useState([]);
      const [orderPendingLoaded, setOrderPendingLoaded] = useState(false);

      const [orderSelectedIndex, setOrderSelectedIndex] = useState([])
      const initialized = useRef(false);
      const navigate = useNavigate();
      const { state } = useLocation();

      const [showDialog, setShowDialog]  = useState(false);
      const [showMsgTitle, setShowMsgTitle] = useState("FAILED!");
      const [showMsg, setShowMsg] = useState("Unable to process ...");
      const [dialogResStatus, setDialogResStatus] = useState("failure");

      var validateErr = false;


      const receivedModeList = [
        {value: "Cheque", label: "CHEQUE"},
        {value: "Cash", label: "CASH"},
        {value: "DD", label: "Demand Draft"},
        {value: "NetBanking", label: "Net Banking"}
      ]

      const [optReceivedMode, setOptReceivedMode] = useState(receivedModeList);
      const [receivedModeIdSelect, setReceivedModeIdSelect] = useState({value: "Cash", label: "CASH"});


      const receivedFromList = [
        {value: "techm", label: "TECH MAHINDRA"},
        {value: "comviva", label: "COMVIVA"},
        {value: "rockbreeze", label: "ROCKBREEZE"},
        {value: "mthsense", label: "Mth Sense"}
      ];
      const [optReceivedFrom, setOptReceivedFrom] = useState([]);
      const [receivedFromIdSelect, setReceivedFromIdSelect] = useState({value: 0, label: "Please select"});

      const getCustomers = async() => {
        try {
            var aIVal = localStorage.getItem("_aI");
            var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
            var res = await axios.get(client.domain + "/crm/getCustomers", { headers }, { withCredentials: false });
            console.log("Response from server getCustomers : ", res.data.detail);
      
            if (res.data.detail.status === "SUCCESS") {
              console.log("Response message from server getCustomers : ", res.data.detail.data);

              var custArr = [];
              res.data.detail.data.map((cust) => {
                var custInd = { value: cust.id, label: cust.name, id: cust.id, name: cust.name};
                custArr.push(custInd);
              })
              setOptReceivedFrom(custArr);
              //setReceivedFromIdSelect(custArr[0]);
              setToRender(true);
            }
          } catch (error) {
            console.log("FULL ERROR : ", error);
            if (error.code === "ERR_NETWORK") {
              console.log("Not able to reach target server please try after sometime");
             
            } else {
              console.log("ERROR : ", error.response.data.detail);
              if (error.response.data.detail.status === "FAILED") {
                console.log("Request failed due to ", error.response.data.detail.res_data);
               
              }
            }
          }
        }

        const getReceivedMode = async() => {
            try {
                var aIVal = localStorage.getItem("_aI");
                var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
                var res = await axios.get(client.domain + "/getReceivedMode", { headers }, { withCredentials: false });
                console.log("Response from server getReceivedMode : ", res.data.detail);
          
                if (res.data.detail.status === "SUCCESS") {
                  console.log("Response message from server getReceivedMode : ", res.data.detail.data);
    
                  
                  var receivedModeArr = [];
                  res.data.detail.data.map((cust) => {
                    var modeInd = { value: cust, label: cust};
                    receivedModeArr.push(modeInd);
                  })
                  
                  //setOptReceivedFrom(custArr);
                  //setReceivedFromIdSelect(custArr[0]);
                  setOptReceivedMode(receivedModeArr);
                  setReceivedModeIdSelect(receivedModeArr[0]);
                  setToRender(true);
                }
              } catch (error) {
                console.log("FULL ERROR : ", error);
                if (error.code === "ERR_NETWORK") {
                  console.log("Not able to reach target server please try after sometime");
                 
                } else {
                  console.log("ERROR : ", error.response.data.detail);
                  if (error.response.data.detail.status === "FAILED") {
                    console.log("Request failed due to ", error.response.data.detail.res_data);
                   
                  }
                }
              } 
        }

        const getPendingOrders = async(customer_id) => {
            
            try {
                //var customer_id = 227;
                var aIVal = localStorage.getItem("_aI");
                var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
                var res = await axios.get(client.domain + "/getPendingOrders?" + "customer_id=" + customer_id, { headers }, { withCredentials: false });
                console.log("Response from server getPendingOrders : ", res.data.detail);
          
                if (res.data.detail.status === "SUCCESS") {
                  console.log("Response message from server getPendingOrders : ", res.data.detail.data);
    
                  setPendingOrderList(res.data.detail.data);
                  setOrderPendingLoaded(true);
                  /*
                  var receivedModeArr = [];
                  res.data.detail.data.map((cust) => {
                    var modeInd = { value: cust, label: cust};
                    receivedModeArr.push(modeInd);
                  })
                  
                  //setOptReceivedFrom(custArr);
                  //setReceivedFromIdSelect(custArr[0]);
                  setOptReceivedMode(receivedModeArr);
                  setReceivedModeIdSelect(receivedModeArr[0]);
                  setToRender(true);
                  */
                }
              } catch (error) {
                console.log("FULL ERROR : ", error);
                if (error.code === "ERR_NETWORK") {
                  console.log("Not able to reach target server please try after sometime");
                 
                } else {
                  console.log("ERROR : ", error.response.data.detail);
                  if (error.response.data.detail.status === "FAILED") {
                    console.log("Request failed due to ", error.response.data.detail.res_data);
                   
                  }
                }
              }
        }

    useEffect(() => {
        if (!initialized.current) {
            if ( state !== null ) {
                console.log("PASSED VALUE : ", state);
            }

            ( async() =>{
                await getCustomers();

                await getReceivedMode();

                if ( receivedFromIdSelect && receivedFromIdSelect.value > 0 ) {
                console.log("customer Id selected : ", receivedFromIdSelect);
                await getPendingOrders(receivedFromIdSelect.value);
                }
                setToRender(true);
            })();
            initialized.current = true;
        }
    }, []);


    const onChangeDatePicker = (e) => {
        console.log("Date onChange called ...", e);
        console.log("Date onChange DATE called ...", e.target.value);
    
        setFormValues({ ...formValues, ["receipt_date"]: e.target.value });
      };

      const onChangeReceivedTime = (e) => {
        console.log("Date onChange Loading time called ...", e);
        console.log("Date onChange Loading time TIME called ...", e.target.value);
        
        setFormValues({ ...formValues, ["receipt_time"]: e.target.value });
      };

      const handleBack = async (e) => {
        console.log("handle back called : ", e);
        navigate(-1);
      };

      const handleReceivedMode = (e) => {
        console.log("Handle Received Mode Select Called : ", e);
        setReceivedModeIdSelect({ value: e.value, label: e.label });
      }

      const handleReceivedFrom = (e) => {
        console.log("Handle Received From Select Called : ", e);
        setReceivedFromIdSelect({ value: e.value, label: e.label });

        setOrderPendingLoaded(false);
        var customer_id = e.value;
        getPendingOrders(customer_id);
      }

      const handleChange = (e, data) => {
        console.log("Handle Change clicked : ", e);
        console.log("Handle Change clicked target : ", e.currentTarget);
        //console.log("HTML tag : ", data);
        const { name, value } = e.currentTarget;
        console.log("Captured Quantity name : ", name);
        console.log("Captured Quantity value : ", value);

        if (name === "received_amount") {
            console.log("Received_amount item edited ...");
            if (e.currentTarget.value.match(appGlobal.numericRegExp)) {
              setFormValues({ ...formValues, [name]: e.currentTarget.value.replace(/^0+/, '')});
            }
          } else {
            setFormValues({ ...formValues, [name]: value});
          }
      }

      const handleClear = async (e) => {
        e.preventDefault();
        console.log("handle clear called : ", e);
        console.log("Handle clear clicked current target ID: ", e.currentTarget.id);
        navigate(0);
      };

      const validate = (values) => {
        const errors = {};
        const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;
    
        console.log("validateErr before start processing : ", validateErr);
        console.log("Validate called ...", values);
    
        if (!values.vehicle_info) {
          errors.received_from = "Received from is required";
          validateErr = true;
        }
    
        console.log("BEFORE RETURN validate errors ...", validateErr);
        return errors;
      };

      const callApiToSubmitReceipt = async(settledOrderIds) => {
        console.log("RECEIVED MODE SELECT : ", receivedModeIdSelect.value);
        var reqReceipt = {
            "receipt_id": 0,
            "receipt_date": formValues.receipt_date,
            "received_mode" : receivedModeIdSelect.value,
            "received_from" : receivedFromIdSelect.value,
            "received_amount" : formValues.received_amount,
             "received_towards": formValues.received_towards,
             "settledOrders": settledOrderIds
        };

        console.log("Receipt settle Request payload : ", reqReceipt);

        try {
            var aIVal = localStorage.getItem("_aI");
            var headers = {...client.headers ,Authorization: `Bearer ${aIVal}`}
            var res = await axios.post(client.domain + "/insertReceipt", reqReceipt, { headers }, { withCredentials: false });
            console.log("Response from server : ", res.data.detail);
      
            if (res.data.detail.status === "SUCCESS") {
              console.log("Response message from server : ", res.data.detail.data);

              showMsgTitle("Success");
              setShowMsg("Receipt Inserted Successfully ...");
              setDialogResStatus("success");
              setShowDialog(true);
            }
            else {
                console.log("FAILED RECEIPT INSERTION ...");
                setShowMsgTitle("Failure");
                setShowMsg("Receipt Insertion Failed please check input ...");
                setDialogResStatus("failure");
                setShowDialog(true);
            }
          } catch (error) {
            console.log("FULL ERROR : ", error);
            
            if ( error.code === 'ERR_NETWORK' ) {
              console.log("Not able to reach target server please try after sometime");
              //setShowMsg('Please try after sometime');
              //setShowMsgTitle('Network Issue!');
              //setShow(true);
            }
            else{
              console.log("ERROR : ", error.response.data.detail);
              if (error.response.data.detail.status === 'FAILED') {
                console.log('Request failed due to ', error.response.data.detail.res_data);
                //setShowMsg('Please Re-Login');
                //setShowMsgTitle('Invalid Session!');
                //setShow(true);
                //navigate("/auth");
              }
            }
            setShowMsgTitle("FAILURE!");
            setShowMsg("Unable to reach server please check connectivity ...");
            setDialogResStatus("failure");
            setShowDialog(true);
          }
      }

      const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("handleSubmit called ...");
        validateErr = false;
        setFormErrors(validate(formValues));
        setIsSubmit(true);
        console.log("^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ", validateErr);

        var settledOrderIds = [];
        var totalSettleAmount = 0.00
        pendingOrderList.map((pendingOrder) => {
            if ( pendingOrder.selected === true ) {
            totalSettleAmount = totalSettleAmount + pendingOrder.amount_balance;

            settledOrderIds.push(pendingOrder);
            }
        })
        console.log("Total settlement amount selected : ", totalSettleAmount);

        console.log("Receipt amount entered : ", formValues.received_amount);

        await callApiToSubmitReceipt(settledOrderIds);

        setReceivedSaved(true);
      }

      const handlePrintReceipt = async() => {
        console.log("Handle print receipt called ...");
      }

      const handleCheckBoxOnChange = async(e, data) => {
        console.log("handleCheckBoxOnChange called event : ", e);
        console.log("handleCheckBoxOnChange called currentTarget : ", e.currentTarget);
        console.log("handleCheckBoxOnChange called currentTarget CHECKED STATE : ", e.currentTarget.checked);

        var clickedTagId = e.currentTarget.id;
        if (clickedTagId) {
            var curSelRowId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
            var nameOfElement = clickedTagId.substring(0, clickedTagId.lastIndexOf("-"));

            console.log("current selected element name view : ", nameOfElement);
            console.log("Current SELECTED row id : ", curSelRowId);

            var selectedPendingOrder = pendingOrderList[curSelRowId];
            console.log("SELECTED PENDING ORDER LIST : ", selectedPendingOrder);
            
            if ( e.currentTarget.checked === true ) {
                selectedPendingOrder.selected = true; 
            }
            else {
                selectedPendingOrder.selected = false;
            }

            var pendingOrderListNew = pendingOrderList.slice();
            pendingOrderListNew[curSelRowId] = selectedPendingOrder;
            setPendingOrderList(pendingOrderListNew);
        }
    }

    const handlePostSaveOk = async() => {
      setShowDialog(false);
      console.log("handleSaveOk clicked ...");
      if ( dialogResStatus === 'success' ) {
        navigate(-1);
      }
    }
  
    const handleCloseDialog = async() => {
      setShowDialog(false);
      console.log("handleCloseDialog clicked ...");
      if ( dialogResStatus === 'success' ) {
        navigate(-1);
      }
    }

    return (
        <Container>
            <SidebarMain />
            { toRender ?
        (
            <Form>
                <Row>
                    <Col xs={4} md={4} sm={4} lg={4}>
                        <h5>Collection Receipt</h5>
                    </Col>
                    <Col
              xs={8}
              md={8}
              sm={8}
              lg={8}
              className="d-flex justify-content-end float-end"
            >
                { receivedSaved &&
                <>
                <Button id="orderPrint" name = "orderPrint"  onClick={(e)  => handlePrintReceipt(e)}>
                <BsFillPrinterFill
                  size={18}
                  style={{ color: "white", cursor: "pointer" }}
                  name={`print-order-dc-icon`}
                  id={`print-order-dc-icon`}
                />
                &nbsp;Print Receipt</Button>
                &nbsp;
                </>
                }

              <Button variant="warning" className="btn btn-warning btn-md">
                <BsArrowLeftCircle
                  key={`back-btn-receipt-key`}
                  size={24}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleBack(e)}
                  name={`back-icon-receipt-name`}
                  id={`back-icon-receipt-id`}
                />
              </Button>
            </Col>
            </Row>

            <p></p>
            <hr align="center" />

            <Row>
                    
                    <Col xs={3} md={3} sm={3} lg={3}>
                    <Form.Group className="mb-3">
                  <Form.Label>Receipt Date</Form.Label>
                  <Form.Control
                    type="date"
                    id="receipt_date"
                    name="receipt_date"
                    placeholder="Receipt Date"
                    value={formValues.receipt_date}
                    onChange={onChangeDatePicker}
                    readOnly={receivedSaved}
                  />
                </Form.Group>
                    </Col>

                    <Col xs={3} md={3} sm={3} lg={3}>
                    <Form.Group className="mb-3">
                  <Form.Label>Receipt time </Form.Label>


                  <Form.Control
                    type="time"
                    id="receipt_time"
                    name="receipt_time"
                    value={formValues.receipt_time}
                    onChange={onChangeReceivedTime}
                    readOnly={receivedSaved}
                  />
                </Form.Group>
                </Col>

                <Col xs={3} sm={3} md={3} lg={3}>

                <Form.Group className="mb-3">
                  <Form.Label className="field required">Received By</Form.Label>
                  <Select

                    id="received_mode"
                    name="received_mode"
                    value={{
                      value: receivedModeIdSelect.value,
                      label: receivedModeIdSelect.label,
                    }}
                    options={optReceivedMode}
                    defaultValue={{
                      value: receivedModeIdSelect.value,
                      label: receivedModeIdSelect.label,
                    }}
                    onChange={handleReceivedMode}
                    readOnly={receivedSaved}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.received_mode}</p>

              </Col>

              
                    <Col xs={3} md={3} sm={3} lg={3}>
                

              <Form.Group className="mb-3">
                  <Form.Label className="field required">Received From</Form.Label>
                  <Select

                    id="received_from"
                    name="received_from"
                    value={{
                      value: receivedFromIdSelect.value,
                      label: receivedFromIdSelect.label,
                    }}
                    options={optReceivedFrom}
                    defaultValue={{
                      value: receivedFromIdSelect.value,
                      label: receivedFromIdSelect.label,
                    }}
                    onChange={handleReceivedFrom}
                    readOnly={receivedSaved}
                  />
                </Form.Group>
                </Col>

                </Row>


              <Row>  


                    <Col xs={3} md={3} sm={3} lg={3}>
                    <Form.Group className="mb-3">
                <Form.Label>Sum of Rs </Form.Label>
                <Form.Control
                  type="text"
                  name="received_amount"
                  id="received_amount"
                  placeholder="Sum of"
                  value={formValues.received_amount}
                  maxLength={10}
                  onChange={handleChange}
                  readOnly={receivedSaved}
                />
              </Form.Group>
                    </Col>


                    <Col xs={3} md={3} sm={3} lg={3}>
                    <Form.Group className="mb-3">
                <Form.Label>Towards </Form.Label>
                <Form.Control
                  as="textarea" rows={2}
                  name="received_towards"
                  id="received_towards"
                  placeholder="Received Towards"
                  value={formValues.received_towards}
                  onChange={handleChange}
                  readOnly={receivedSaved}
                />
              </Form.Group>
                    </Col>

                </Row>

                <hr align="center" />

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Order Date</th>
                            <th>Order Number</th>
                            <th>Order Amount</th>
                            <th>Received Amount</th>
                            <th>Balance Amount</th>
                            <th>...</th>
                        </tr>
                    </thead>

                    <tbody>
                    {orderPendingLoaded && pendingOrderList.map((pendingOrdInd, idx) => (
                        <tr key={`tbody-key-${idx}`}>
                            <td>{idx + 1}</td>
                            <td>{formatDate(new Date(pendingOrdInd.order_date))}</td>
                            <td>{pendingOrdInd.order_no}</td>
                            <td>{pendingOrdInd.amount_total}</td>
                            <td>{pendingOrdInd.amount_received}</td>
                            <td>{pendingOrdInd.amount_balance}</td>
                            <td>
                            <Form.Check // prettier-ignore
                                key={`receipt-item-ctrl-key-${idx}`}
                                id={`receipt-action-id-${idx}`}
                                //defaultChecked={"products" in formValues && formValues["products"].find(element => element.id === productInd.id) !== undefined ? true : false }
                                //checked={"products" in formValues && formValues["products"].find(element => element.id === productInd.id) !== undefined ? true : false }
                                name="receipts"
                                type="Checkbox"
                                //label="Check"
                                //label={"products" in formValues && formValues["products"].find(element => element.id === productInd.id) !== undefined ? 'checked': productInd.name}
                                onChange={handleCheckBoxOnChange}
                            />
                            </td>
                        </tr>
                    ))}
                     </tbody>
                </Table>

                <Row>
              <Col xs={12} md={12} sm={12}>
                <div className="d-flex justify-content-end">
                  <Button
                    className="btn btn-secondary"
                    id="btn-clear-receipt-id"
                    name="btn-clear-receipt-name"
                    onClick={handleClear}
                  >
                    Clear
                  </Button>
                  &nbsp;&nbsp;
                  <button className="btn btn-primary" onClick={handleSubmit}>Save Receipt</button>
                </div>
              </Col>
            </Row>
            <Modal
              show={showDialog}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>{showMsgTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{showMsg}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handlePostSaveOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            </Form>
            )
        :
        <div className="container-spinner">
        <div className="vertical-center-spinner">
         {/*<Button variant="primary" disabled>*/}
         <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
            <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{ width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
                <span className="visually-hidden">Loading...</span>
              </Spinner>
   
         {/*</Button>*/}
         </div>
         </div>
      }
        </Container>
    );

}

export default AddUpdateReceipt;