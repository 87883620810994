import { Fragment, useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Badge,
  Table,
  Modal,
  Spinner,
  Image
} from "react-bootstrap";

import Sidebar2 from "./Sidebar2";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import "../Dashboard.css";
import "../App.css";
import { appGlobal, client } from "../config/Config";
import axios from "axios";

import MenuIms from "./MenuIms";

import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsTrashFill,
  BsArrowLeftCircle,
  BsFillPenFill,
  BsFillXCircleFill
} from "react-icons/bs";
import SidebarMain from "./SidebarMain";
import {getProductBuy } from "./utils";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';


const AddUpdateProduct = (props) => {
  const uomRef = useRef([])
  const intialValues = {
    name: "",
    description: "",
    hsncode: "",
    uom: "",
    unit_price: "",
    cgst: "",
    sgst: "",
    igst: "",
    tt_value: "SELL",
    tt_key: "SELL",
    product: { value: "", label: "" },
    is_composite: true, 
    product_type: "PRODUCT"
  };
  const [formValues, setFormValues] = useState(intialValues);
  const initialized = useRef(false);
  const navigate = useNavigate();
  const { state } = useLocation();

  let initialItems = [];
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [productDisable, setProductDisable] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  //const [productMaster, setProductMaster] = useState([]);
  const [items, setItems] = useState(initialItems);
  const [showMsg, setShowMsg] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  //const [showMsgTitle, setShowMsgitle] = useState();
  //const [dialogBody, setDialogBody] = useState();
  const [toRender, setToRender] = useState(false);
  const [validateError, setValidateError] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  //const [selectedId, setSelectedId] = useState(-1);
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );
  const [submitAction, setSubmitAction] = useState(false);
  var validateErr = false;
 
  const [productCompositionList, setProductCompositionList] = useState([]);

  const [refresh, setRefresh] = useState(false);
  const [priceDisableState, setPriceDisableState] = useState(false);

  const [disableComposite, setDisableComposite] = useState(true);
  const [dialogAction, setDialogAction] = useState("None");

  const [uomOpt, setUomOpt] = useState([]);
  const [uomIdSelect, setUomIdSelect] = useState({});

  const [uomLoaded, setUomLoaded] = useState(false);
  const [prodDetLoaded, setProdDetLoaded] = useState(false);
  //var priceDisableState = false;

  const handleOnChange = async (e) => {
    console.log("handleOnChange called : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("element name handleOnChange : ", name);
    console.log("element value handleOnChange : ", value);

    if (e.currentTarget.value && name === "unit_price" || name === "cgst" || name === "sgst" || name === "igst") {
      console.log("Inside check ******************");
      //const numericRegExp = /^(?:[1-9]\d*|\d)$/;
      //const numericRegExp = /^[1-9]\d{9}$/;
      //const numericRegExp = /^\d*\.?\d*$/;
      //const numericRegExp = /^[-+]?[0-9]+$/;
      //const numericRegExp = /^[0-9]*[.,]?[0-9]*$/;
      //var valueTmp = e.currentTarget.value.replace(/[^\d]/g, '');
      //if (e.currentTarget.value.match(numericRegExp)) {
      // if (numericRegExp.test(e.currentTarget.value)) {
      //var valueTmp = Math.round(e.target.value.replace(/\D/g, ""));
      //const numericRegExp = /^\d*\.?\d*$/; // Original
      //const numericRegExp = /^\d*(\.\d{0,2})?$/;

      //console.log("NaN check : ", isNaN(e.currentTarget.value));
      if (e.currentTarget.value.match(appGlobal.numericRegExp)) {
        //setFormValues({ ...formValues, [name]: e.currentTarget.value.replace(/^0+/, '') });
        setFormValues({ ...formValues, [name]: e.currentTarget.value});
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }

    /*
    var currentId = name.substring(name.lastIndexOf("-") + 1);
    var nameOfElement = name.substring(0, name.lastIndexOf("-"));
    console.log("Name of element 2 : ", nameOfElement);
    console.log("Current id 2 : ", currentId);
    */
  }

  const handleOnItemChange = (e) => {
    //e.preventDefault();
    console.log("Button clicked : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);


    var currentId = name.substring(name.lastIndexOf("-") + 1);
    var nameOfElement = name.substring(0, name.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    var itemSelected = productCompositionList[currentId];
    //console.log("Product: ", itemSelected.product);
    console.log("Product Name : ", itemSelected.product_name);
    //setFormValues({ ...formValues, [name]: value });
    console.log("Product Info : ", productListFull);
    const currentProductIndex = productListFull.findIndex(
      (productInd) => productInd.name === itemSelected.product_name
    );
    console.log("Currently selected row product id : ", currentProductIndex);
    if (currentProductIndex < 0) {
      itemSelected.error = "Please select product from list";

      const newProductCompositionList = productCompositionList.slice();
      newProductCompositionList[currentId] = itemSelected;
      setProductCompositionList(newProductCompositionList);
      return 0;
    } else {
      itemSelected.error = "";
    }

    console.log("Currently selected row product value : ", productListFull[currentProductIndex]);

    /*
    itemSelected.product = {
      id: productList[currentProductIndex].id,
      name: productList[currentProductIndex].name,
      
    };
    */
    //itemSelected.unit_cost = productMaster[currentProductIndex].unit_cost;

    if (nameOfElement === "quantity") {
      console.log("Product Quantity item edited ...");

      // const regex =  /^[0-9\b]+$/;
      /*
       const numericRegExp = /^\d*\.?\d*$/;
       //if (numericRegExp.match(e.target.value)) {
       if (e.currentTarget.value.match(numericRegExp)) {
         itemSelected[nameOfElement] = e.currentTarget.value;
       }
       */
     // const numericRegExp = /^\d*\.?\d*$/;
      if (e.currentTarget.value.match(appGlobal.numericRegExp)) {
        itemSelected[nameOfElement] = e.currentTarget.value;
      }
    } else {
      console.log("Other than handled option clicked so skipping it ...");
    }

    const newProductCompositionList = productCompositionList.slice();
    newProductCompositionList[currentId] = itemSelected;
    setProductCompositionList(newProductCompositionList);
  };


  const validate = (values) => {
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!values.name) {
      errors.name = "Name is required!";
      validateErr = true;
      //setValidateError(true);
    }
    /*
    if (!values.hsncode) {
      errors.hsncode = "HSN Code is required!";
      validateErr = true;
      //setValidateError(true);
    }
    */
    //if (!values.uom) {
    
  
     if ( formValues["product_type"] === 'PRODUCT') {
      if (uomIdSelect.value < 0) {
        errors.uom = "UOM is required!";
        validateErr = true;
        //setValidateError(true);
      }

      if (formValues["is_composite"] && productCompositionList.length < 1 ) {
       errors.is_composite = "Please Add atleast One Composite" ;
       validateErr = true;
       setValidateError(true);
      }
      else if (formValues["tt_key"] === 'SELL'){
        if (!values.unit_price) {
          errors.unit_price = "Unit Price is required!";
          validateErr = true;
          setValidateError(true);
        }
     
      
      //else {
        // Service Type
      //}
        if (!values.cgst) {
        errors.cgst = "CGST is required!";
        validateErr = true;
        setValidateError(true);
      }
      if (!values.sgst) {
        errors.sgst = "SGST is required!";
        validateErr = true;
        setValidateError(true);
      }
      if (!values.igst) {
        errors.igst = "IGST is required!";
        validateErr = true;
        setValidateError(true);
      }
    }
    

     }
    /*
        if (!customerSearchText && !searchedCustomer) {
          //if (!values.customer){
          errors.customer_name = "Customer name is required!";
        }
    
        if (!values.customer_disp_name) {
          errors.customer_disp_name = " Display name is required!";
          validateErr = true;
        }
        */
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };


  const handleOnRadioChange = async (e) => {
    console.log("Radio button clicked : ", e);

    const { name, id } = e.currentTarget;
    console.log("Captured radio name : ", name);
    console.log("Captured radio id : ", id);

    var currentRowName = id.substring(id.lastIndexOf("-") + 1);
    var nameOfElement = id.substring(0, id.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current row id : ", currentRowName);

    if ( name == 'tt_key' ) {
    if (currentRowName === 'BUY') {
      setPriceDisableState(true);
    }
    else if (currentRowName === 'SELL') {
      setPriceDisableState(false);
    }
    else {
      console.log("************ INVALID trade TYPE+++++++++++++++ at handleOnRadioChange : ", currentRowName);
    }
  }

    if ( name === 'is_composite' ) {
      if ( currentRowName === 'YES' && formValues["tt_key"] === 'SELL') {
        setDisableComposite(false);
      }
      else {
        setDisableComposite(true);
      // check if there is any item in it item list ask them confirm to remove
        if (productCompositionList.length > 0) {
          setShowMsg("Are you sure want to discard product compostiion?")
          setShowMsgTitle("Confirmation");
          setDialogAction("composite");
          setShowDialog(true);
        //setProductCompositionList([]);
      }
    }
  }

    var isCompositeTmp = false;
    if (name === 'is_composite') {
      console.log("is composite state : ", e.currentTarget.checked);
      if (currentRowName === 'YES' && e.currentTarget.checked === true) {
        isCompositeTmp = true;
      }
      setFormValues({ ...formValues, [nameOfElement]: isCompositeTmp });
    }
    else {
      setFormValues({ ...formValues, [nameOfElement]: currentRowName });
    }
  }

  /*
  const handleValueRadioChange = async (e) => {
    console.log("handleValueRadioChange clicked : ", e);

    var tagId = e.target.id;
    var currentRowName = tagId.substring(tagId.lastIndexOf("-") + 1);
    var nameOfElement = tagId.substring(0, tagId.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current row id : ", currentRowName);

    //setFormValues({ ...formValues, ["gender"]: currentRowName.toUpperCase() });
  };
  */

  const handleSelect = (e, data) => {
    console.log("SELECTED Dropdown value : ", e);
    console.log("SELECTED element id : ", data);
    var currentId = data.name.substring(data.name.lastIndexOf("-") + 1);
    console.log("Current id : ", currentId);
  };
 
  const [TradeTypeIdSelect, setTradeTypeIdSelect] = useState({});
  /*
  var optionsUom = [
    { value: 1, label: "m3" },
    { value: 2, label: "m40" },
    { value: 3, label: "kg" },
  ];
  */
  /*
  const uomArr = [
    {
      "id": "TONE",
      "name": "Tone"
    },
    {
      "id": "MCUBE",
      "name": "m3"
    },
    {
      "id": "UNIT",
      "name": "Unit"
    },
    {
      "id": "KG",
      "name": "Kilogram"
    },
    {
      "id": "LITTER",
      "name": "Litter"
    }
  ];
  */

  //const [uomIdSelect, setUomIdSelect] = useState({});
/*

  const buyProdArr = [
    {
      "id": 14,
      "name": "RMCM05",
      "description": "RMC M05555",
      "hsncode": "123",
      "uom": "m3",
      "unit_price": 4500,
      "cgst": 100,
      "sgst": 200,
      "igst": 250
    },
    {
      "id": 12,
      "name": "flyash",
      "description": "flyash",
      "hsncode": "68159990",
      "uom": "kg",
      "unit_price": 0,
      "cgst": 0,
      "sgst": 0,
      "igst": 0
    },
    {
      "id": 11,
      "name": "admixture",
      "description": "admixture",
      "hsncode": "38244090",
      "uom": "kg",
      "unit_price": 0,
      "cgst": 0,
      "sgst": 0,
      "igst": 0
    },
    {
      "id": 5,
      "name": "aggregate",
      "description": "",
      "hsncode": "25171010",
      "uom": "unit",
      "unit_price": 0,
      "cgst": 0,
      "sgst": 0,
      "igst": 0
    },
    {
      "id": 4,
      "name": "cement",
      "description": "",
      "hsncode": "252321",
      "uom": "kg",
      "unit_price": 0,
      "cgst": 0,
      "sgst": 0,
      "igst": 0
    },
    {
      "id": 3,
      "name": "msand",
      "description": "",
      "hsncode": "441039",
      "uom": "unit",
      "unit_price": 0,
      "cgst": 0,
      "sgst": 0,
      "igst": 0
    }
  ]
  */

  const [productList, setProductList] = useState([]);
  const [productListFull, setProductListFull] = useState([]);

  /*
  let product_list = [
    {
      value: 14,
      label: "RMCM05",
      product_id:14,
      product_name: "RMCM05",
    },
    {
      value: 12,
      label: "flyash",
      product_id:12,
      product_name: "flyash",
    },
    {
      value: 11,
      label: "admixture",
      product_id:11,
      product_name: "admixture",
    },
    {
      value: 4,
      label: "cement",
      product_id:4,
      product_name: "cement",
    },
  ]
  */
/*
  const tradeTypeArr = [
    {
      "id": "BUY",
      "name": "Buy"
    },
    {
      "id": "SELL",
      "name": "Sell"
    }
  ];
  const compositeProductArr = [
    {
      "id": "BUY",
      "name": "Buy"
    },
    {
      "id": "SELL",
      "name": "Sell"
    }
  ];

*/
  const [tradeTypeList, setTradeTypeList] = useState([]);



  //const [compositeProductList, setCompositeProductList] = useState(compositeProductArr);

  const newItemHandle = async (event) => {
    event.preventDefault();
    console.log("New item addition button clicked ...");

    if ( productList.length > 0 ) {
    const newProductCompositionList = productCompositionList.slice();
    //let itemNew = { id: 1, product: { value: 0, label: "" }, unit_cost: "", qty: 1, "product_cost": "", "total_cost": "", gst: { cgst: 0.00, sgst: 0.00, igst: 0.00, totGst: 0.00 } };
    let itemNew = {
      id: 0,
      product_id: 0,
      product_name: "",
      quantity: "",
    };
    newProductCompositionList.push(itemNew);
    setProductCompositionList(newProductCompositionList);
  }
  else {
    setDialogAction('new_prod_in_comp');
    setShowDialog(true);
    setShowMsgTitle('FAILED');
    setShowMsg('Unable to add new product to composition due to empty product list');
  }
  };
  const handleProductSelect = (e, data) => {
    console.log(
      ">>>>>>>>>>>>>>>>>>>>>>> HandleProductSelect called *******************"
    );
    console.log("SELECTED Dropdown value : ", e);
    console.log("SELECTED element id : ", data);
    var currentId = data.name.substring(data.name.lastIndexOf("-") + 1);
    console.log("Current id : ", currentId);

    var itemSelected = productCompositionList[currentId];
    console.log("Item selected : ", itemSelected);
    if (itemSelected.product) {
      console.log("Product: ", itemSelected.product);
    }
    console.log(
      "<<<<<<<<<<<<<<<<<<<<< ITEM ID >>>>>>>>>>>>>>>>>>: ",
      itemSelected.id
    );


    console.log("PRODUCT LIST FULL at product selection 101: ", productListFull);
    //console.log("Product Info : ", productMaster);
    const currentProductIndex = productList.findIndex(
      (productInd) => productInd.name === e.label
    );

    console.log("Currently selected row product id : ", currentProductIndex);
    console.log("Currently selected row product value : ", productList[currentProductIndex]);
    productList.splice(currentProductIndex, 1)
    var newItems = productList.slice();
    setProductList(newItems);
    itemSelected.product_id = e.id;
    itemSelected.product_name = e.name;
    itemSelected.uom = e.uom;
    //itemSelected.product.label = e.label;
    /*
    var productNew = {
      id: e.id,
      name: e.name,
    };
    console.log("Product Value : ", productNew);
    itemSelected.product = productNew;
    */

    console.log("PRODUCT LIST FULL at product selection 102: ", productListFull);
    const newProductCompositionList = productCompositionList.slice();
    newProductCompositionList[currentId] = itemSelected;
    setProductCompositionList(newProductCompositionList);

  };
  const handleItemDelete = (e) => {
    console.log("handle item delete called ...", e);
    console.log("handle item delete e.currentTarget : ", e.currentTarget.id);
    console.log("handle item delete e.target : ", e.target.id);

    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(
        0,
        clickedTagId.lastIndexOf("-")
      );
      console.log("current selected DELETE element name : ", nameOfElement);
      console.log("Current SELECTED DELETE id : ", curSelId);
      console.log("CUR SELECTED DELETE VALUE : ", productCompositionList[curSelId]);

      var deletedProd = { value : productCompositionList[curSelId].product_id, label: productCompositionList[curSelId].product_name, id : productCompositionList[curSelId].product_id, name: productCompositionList[curSelId].product_name, uom: productCompositionList[curSelId].uom };
      productList.push(deletedProd);

      let sliced = productCompositionList.splice(curSelId, 1);
      console.log("Removed item from order items: ", sliced);
      var newProductCompositionList = productCompositionList.slice();
      setProductCompositionList(newProductCompositionList);

    }
  };
  const getProductForLoad = async () => {
    var retState = false;
    var prodRes = {};
    console.log("get product for load called ... ");

   

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + "/ims/getProduct?id=" + state.passed_element.id, { headers }, { withCredentials: false });
      console.log("Response from server getProductForLoad : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getProductForLoad : ", res.data.detail.data);
        console.log("Response message from server getproductForLoad (Products) : ", res.data.detail.data);

        setFormValues(res.data.detail.data);

        if ( res.data.detail.data.is_composite === true && res.data.detail.data.tt_key === 'SELL' && 'composite' in res.data.detail.data && res.data.detail.data.composite.length > 0 ) {
          setProductCompositionList(res.data.detail.data.composite);
        }

        console.log("UOM array : ", uomOpt);
        var findUom = uomRef.current.find(element => element.value === res.data.detail.data.uom_key);
        console.log("**************** findUOM value ++++++++++++ : ", findUom);
        if (findUom !== undefined) {
          setUomIdSelect({ label: findUom.label, value: findUom.value })
        }
       
        setProdDetLoaded(true);
        setToRender(true);

        retState = true;
        prodRes = res.data.detail.data;
        console.log("PRODUCT LOAD STATUS SET TO TRUE")
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );

      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);

        }
      }
    }
    return [retState, prodRes];
  }

  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::addUpdateProduct called ##########################"
      );

      /*
      if (state === null) {
        navigate("/listProducts");
      } else*/ {
        //console.log("PASSED STATE VALUE IN Product Add/Update page : ", state);
        /*
        if (state.action === "add") {
          setFormValues({ ...formValues, product_type: 'PRODUCT' });
          setIsReadOnly(true);
        }
        */
  
        if (state && state.action === "add") {
          (async () => {
            const [retState, uomArr] = await getUomList();
            if ( retState ) {
              uomRef.current = uomArr;
            }
            var [retStateProd, productArrRet] = await getProductBuy();
              if ( retStateProd) {
                console.log("compositeProductArray :", productArrRet);
                //setProductCompositionList(productArrRet);
                var prodList1 = productArrRet.slice();
                setProductList(prodList1);
                
                var prodList2 = productArrRet.slice();
                setProductListFull(prodList2);
                
              }
          })();
          console.log("UOM LIST 2 : ", uomOpt);
         
          setIsReadOnly(false);
          //setUomIdSelect(uomOpt[0]);
          //setToRender(true);
          if (formValues["is_composite"] === true && formValues["tt_key"] === 'SELL') {
            console.log("Product SELECTION DETECTED : ", formValues);
            setDisableComposite(false);
            //setProductList(productArrRet);
        }
        else if (formValues["is_composite"] === false && formValues["tt_key"] === 'SELL') {
          setPriceDisableState(false);
          setDisableComposite(true);
        }
       
        else {
          setDisableComposite(true);
          setPriceDisableState(true);
        }
        }
        else if (state && (state.action === "view" || state.action === "edit")) {
            (async () => {
              const [retStateUom, uomArr] = await getUomList();
              if ( retStateUom ) {
                uomRef.current = uomArr;
              }
              var [retStateProdBuy, productArrRet] = await getProductBuy();
              if ( retStateProdBuy) {
                console.log("compositeProductArray :", productArrRet);

                var prodList1 = productArrRet.slice();
                setProductList(prodList1);
                
                var prodList2 = productArrRet.slice();
                setProductListFull(prodList2);  
              }

              const [retStateProd, prodRes] = await getProductForLoad();
              if ( retStateProd ) {
                // Check condition while loading exising values for edit or view
                console.log("Full formValues : ", prodRes);
                if (/*prodRes["is_composite"] === true*/ prodRes["product_type"] === "PRODUCT" && prodRes["tt_key"] === 'SELL') {
                    setDisableComposite(false);
                    //setProductList(productArrRet);
                    setProductDisable(false);
                }
                else if (/*prodRes["is_composite"] === false*/ prodRes["product_type"] === "SERVICE" && prodRes["tt_key"] === 'SELL') {
                  setPriceDisableState(false);
                  setDisableComposite(true);
                  setProductDisable(true);
                }
                else {
                  setDisableComposite(true);
                  setPriceDisableState(true);
                }
              }
             
            })();
            
          // setFormValues(state.passed_element);
        }
        else {
            const getUomWrapper = async() => { 
              await getUomList();
            }
            getUomWrapper();
          setIsReadOnly(false);
          //setUomIdSelect(uomOpt[0]);
        }
      }

      

      /*
      var newBuyProdArr = [];
      buyProdArr.map((prodItem) =>{
        var newBuyProd = {
          "value": prodItem.id,
          "label": prodItem.name,
          "id": prodItem.id,
          "name": prodItem.name,
          "description": prodItem.description,
          "hsncode": prodItem.hsncode,
          "uom": prodItem.uom,
          "unit_price": prodItem.unit_price,
          "cgst": prodItem.cgst,
          "sgst": prodItem.sgst,
          "igst": prodItem.igst
        }
        newBuyProdArr.push(newBuyProd);
      })
      setProductList(newBuyProdArr);
      */
     
      getTradeType();
      //getProductBuy();
      initialized.current = true;
    }
  }, []);


  const callUpdateApi = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
    var headers = {
      ...client.headers,
      Authorization: `Bearer ${aIVal}`
    }
    var apiToCall = "";
    var reqParam = {};
    if (formValues["tt_key"] === 'BUY') {
      reqParam.product_type = formValues["product_type"];
      reqParam.name = formValues["name"];
      reqParam.description = formValues["description"];
      reqParam.hsncode = formValues["hsncode"];
      reqParam.uom_key = uomIdSelect.value;
      if (state.action === "add") {
        apiToCall = "/ims/addProductTTBuy";
      } else if (state.action === "view") {
        reqParam.id = state.passed_element.id;
        apiToCall = "/ims/updateProductTTBuy";
      } else {
        apiToCall = "/ims/addProductTTBuy";
      }
    }
    else if (formValues["tt_key"] === 'SELL') {
      reqParam.product_type = formValues["product_type"];
      reqParam.name = formValues["name"];
      reqParam.description = formValues["description"];
      reqParam.hsncode = formValues["hsncode"];
      reqParam.uom_key = uomIdSelect.value;

      reqParam.unit_price = parseFloat(formValues["unit_price"]) || 0.00;
      reqParam.cgst = parseFloat(formValues["cgst"]) || 0.00;
      reqParam.sgst = parseFloat(formValues["sgst"]) || 0.00;
      reqParam.igst = parseFloat(formValues["igst"]) || 0.00;
      if (state.action === "add") {
        apiToCall = "/ims/addProductTTSell";
      } else if (state.action === "view" || state.action === "edit") {
        reqParam.id = state.passed_element.id;
        apiToCall = "/ims/updateProductTTSell";
      } else {
        apiToCall = "/ims/addProductTTSell";
      }
      reqParam.is_composite = formValues["is_composite"];
      
      if ( formValues["is_composite"] && productCompositionList.length > 0 ) {
        var sendProdArr = [];
        var validateIssue = false;
        productCompositionList.map((prodComposition, idx) => {
          if ( prodComposition.product_id > 0 ) {
            var prodCompInd = {"product_id": prodComposition.product_id, "quantity": parseFloat(prodComposition.quantity)};
            sendProdArr.push(prodCompInd);
          }
          else {
            prodComposition.error = "Please select valid product";
            validateIssue = true;
          }
        })
        if ( validateIssue === true ) {
          setProductCompositionList(productCompositionList);
          return 0;
        }
        reqParam.composites = sendProdArr;
      }
    }
    else {
      console.log("Invalid trade type at callUpdateApi : ", formValues["tt_key"]);
      // Open pop up to show error message
      setShowMsgTitle("Invalid Trade Type!");
      setShowMsg("Please select valid trade type");
      setShowDialog(true);
    }


    /*
    var reqParam = {
      // only for update either type(BUY/SELL)
      id: formValues["id"],
      // for all requests
      name: formValues["name"],
      description: formValues["description"],
      hsncode: formValues["hsncode"],
      uom: uomIdSelect.value,
      // only for SELL
      unit_price: formValues["unit_price"],
      cgst: formValues["cgst"],
      sgst: formValues["sgst"],
      igst: formValues["igst"],

      is_composite: formValues["is_composite"]
    };
    */

    setSubmitAction(true);
    console.log("req parameter :", reqParam);
      var res = await axios.post(
        client.domain + apiToCall,
        reqParam,
        { headers },
        { withCredentials: false }
      );

      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        
        //setShow(true);
        setShowDialog(true);
        setSubmitAction(false);
        setShowMsgTitle("Success");
        setShowMsg(res.data.detail.data.message);
        setShowDialog(true);
        setDialogAction("update_api");
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          
        }
      }
      setSubmitAction(false);
    }

  };

  const handleTradeTypeSelect = (e, data) => {
    console.log("handleOnRadioChange called ...", e);

    setTradeTypeIdSelect(e);
  };
  const getTradeType = async () => {
    console.log("getTradeType method called ...");

   

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(
        client.domain + "/ims/getTradeTypes",
        { headers },
        { withCredentials: false }
      );

      console.log(
        "Response from server getTradeType : ",
        res.data.detail.data
      );
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getTradeType : ", res.data.detail.data);

        
        setTradeTypeList(res.data.detail.data);

        if (state && state.action === "add") {
         // setTradeTypeIdSelect(tradeTypeArr[0]);
        } else if (state && (state.action === "view" || state.action === 'edit')) {
          setTradeTypeIdSelect({
            value: formValues["tt_key"],
            label: formValues["tt_value"],
          });
        }
        else {
          //setTradeTypeIdSelect({});
        }

      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.data
          );

        }
      }
    }
  };
  
  
  const handleUomSelect = async (e, data) => {
    console.log("handleUomSelect called ...", e);
    setUomIdSelect(e);
  };

  const getUomList = async () => {
    var retState = false;
    //console.log("getUomList called ...", e);
   
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + "/ims/getUOM", { headers }, { withCredentials: false });
      console.log("Response from server getUomList : ", res.data.detail.data);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getUomList : ", res.data.detail.data);


        var uomArr = [];
        res.data.detail.data.map((uomType, idx) => {
          console.log("Ind val : ", uomType);
          var uomTypeInd = {
            value: uomType.key,
            label: uomType.value,
          };
          console.log("In UOM TYPE modified : ", uomTypeInd);
          uomArr.push(uomTypeInd);
        });

        console.log("Mod UOM Full VALUE : ", uomArr);

        //setUomOpt(uomArr);
        setUomOpt(uomOpt => ([...uomOpt, ...uomArr]));
        uomRef.current = uomArr;
        //setUomIdSelect(uomArr[0]);
        if (state.action === "add") {
          setUomIdSelect(uomArr[0]);

          //setToRender(true);
        } 
        /*
        else {
          setUomIdSelect({
            value: state.passed_element.uom_id,
            label: state.passed_element.uom,
          });
        }
        */
        setUomLoaded(true);
        setToRender(true);
        retState = true;
        console.log("UOM LOAD STATUS SET TO TRUE")
      }

    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.data
          );

        }
      }
    }
    return [retState, uomArr];
  };

  const handleSave = async (e) => {
    e.preventDefault();
    console.log("handle save clicked : ", e);

    console.log("Product Info : ", formValues);
    console.log("COmposition list : ", productCompositionList);
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    if (validateErr === false) {
      callUpdateApi();
    }
    else{
      setShowMsgTitle("FAILED!")
      setShowMsg("Unable to Save Product Please check input");
      setShowDialog(true);
    }

  };



  const handleDelete = async (e) => {
    console.log("handle delete clicked : ", e);
  };

  const handleReset = async (e) => {
    console.log("handle reset clicked : ", e);
  };
  const handleBack = async (e) => {
    console.log("handle back clicked : ", e);
    navigate(-1);
  };
  const handleEdit = async (e) => {
    console.log("handle edit clicked : ", e);
    setIsReadOnly(false);
  };
  const handleCloseDialog = () => {
    console.log("Dilaog canceled ....");
    setShowDialog(false);

    if (dialogAction === "composite") {
      console.log("Inside composite check ...");
      //setFormValues({ ...formValues, ["is_composite"] : true});
      formValues["is_composite"] = true;
      setFormValues({ ...formValues, ["tt_key"]: 'SELL' });
    }
    else if ( dialogAction === 'new_prod_in_comp') {
      setShowDialog(false);
    }    
    else if (dialogAction === 'update_api') {
      setShowDialog(false);
      navigate("/listProducts");
    }
    
  };

  const handlePostSaveOk = async (e) => {
    console.log("handle post save ok called : ", e);
    setShowDialog(false);

    console.log("Dialog Action : ", dialogAction);
    if (dialogAction === "composite") {
      setProductCompositionList([]);
    }
    else if ( dialogAction === 'new_prod_in_comp') {
      setShowDialog(false);
    }
    else if (dialogAction === 'update_api'){
      setShowDialog(false);
      navigate('/listProducts');
    }
  }

  console.log("uomLoaded :", uomLoaded);
  console.log("Prod Det Loaded : ", prodDetLoaded);
  console.log("UOM List :", uomOpt);

  function validateFloatKeyPress(el, evt) {
    console.log("el value : ", el);
    console.log("evt value: ", evt);
    var charCode = (evt.which) ? evt.which : evt.keyCode;

    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }

    if (charCode == 46 && el.value.indexOf(".") !== -1) {
        return false;
    }

    if (el.value.indexOf(".") !== -1)
    {
        var range = document.selection.createRange();

        if (range.text != ""){
        }
        else
        {
            var number = el.value.split('.');
            if (number.length == 2 && number[1].length > 1)
                return false;
        }
    }
    console.log("return TRUE");
    return true;
    //formValues({ ...formValues, [el]})
};

const handleProductRadioChange = async (e) => {
  console.log("Value button clicked : ", e);

  const { name, id } = e.currentTarget;
  console.log("Captured value name : ", name);
  console.log("Captured value id : ", id);

  var currentRowName = id.substring(id.lastIndexOf("-") + 1);
  var nameOfElement = id.substring(0, id.lastIndexOf("-"));
  console.log("Name of element : ", nameOfElement);
  console.log("Current row id : ", currentRowName);
  var tt_key_tmp = formValues["tt_key"];
  var tt_value_tmp = formValues["tt_value"];
  if ( currentRowName === 'service' ) {
    tt_key_tmp = 'SELL';
    tt_value_tmp = 'SELL';
  }
  setFormValues({ ...formValues, product_type: currentRowName.toUpperCase(), tt_key: tt_key_tmp, tt_value: tt_value_tmp });

  if ( currentRowName === 'service' ) {
    setProductDisable(true);
  }
  else{
    setProductDisable(false);
  }

  
};

console.log("FORM VALUESSS S S S S BEFORE E E E E E RENDER R R R R R : ", formValues);
console.log("DISABLE COMPOSITE STATE S S S S BEFORE E E E E E RENDER R R R R R : ", disableComposite);

  return (
    <Container>
      <SidebarMain />
      { toRender ?
        (
        <Form>
        {((uomLoaded && state.action === 'add') || ((state.action === 'edit' || state.action === 'view') && uomLoaded && prodDetLoaded)) ?
        <>
          <Row>
            <Col xs={4} md={4} sm={4} lg={4}>
              <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem", verticalAlign: "bottom" }}>Product</span>
              {(state && (state.action === 'view' || state.action === 'edit')) &&
                <>
                  &nbsp;&nbsp;

                  <Button variant={state.passed_element.is_active ? "outline-success" : "outline-danger"} size="sm"><span style={{ cursor: "default", fontWeight: "500" }}>{state.passed_element.status}</span></Button>
                </>
              }
            </Col>
            <Col
              xs={8}
              md={8}
              sm={8}
              lg={8}
              className="float-end justify-content-end"
            >
              <div className="d-flex justify-content-end">
                <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                  <BsArrowLeftCircle
                    key={`back-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}

                    name={`back-icon-patient-name`}
                    id={`edit-icon-patient-id`}
                  />
                </Button>
                {(state && (state.action === "edit" || state.action === "view")) && (
                  <>
                    &nbsp;&nbsp;
                    <Button variant="primary" className="btn btn-primary btn-md" onClick={(e) => handleEdit(e)}>
                      <BsPencilSquare
                        key={`edit-btn-patient-key`}
                        size={24}
                        style={{ cursor: "pointer" }}

                        name={`edit-icon-paBiSolidEditAlttient-name`}
                        id={`edit-icon-doctor-id`}
                      />
                    </Button>
                  </>
                )}
              </div>
            </Col>
          </Row>
          <div className="ui-divider"></div>
          <div className="ui-form">
            <hr align="center" />
            {/*
            <Row>
            <Col xs={12} md={12} sm={12}>
                <Button
                    className="btn btn-sm btn-warning d-flex justify-content-end pull-right float-end"
                    style={{ alignItems: "self-end" }}
                   // onClick={newItemHandle}
                  >
                    Add Item
                  </Button>
               
              </Col>
            </Row>
            */}
            <Row>

            <Col xs={3} md={3} sm={3} lg={3}>
                <div key={`inline-radio`} className="mb-3" style={{ paddingTop: '35px' }}>
                  <Form.Group>
                    <Form.Label>Product Type &nbsp;&nbsp;</Form.Label>
                    <Form.Check
                      disabled={isReadOnly}
                      inline
                      label="Product"
                      name="product_type"
                      type={"radio"}
                      id={`inline-radio-product`}
                      onChange={(e) => handleProductRadioChange(e)}
                      checked={formValues["product_type"] === 'PRODUCT' ? true : false}
                    />
                    <Form.Check
                      disabled={isReadOnly}
                      inline
                      label="Service"
                      name="product_type"
                      type={"radio"}
                      id={`inline-radio-service`}
                      onChange={(e) => handleProductRadioChange(e)}
                      checked={formValues["product_type"] === 'SERVICE' ? true : false}
                    />
                  </Form.Group>
                </div>
              </Col>
            
            </Row>


            <Row>
              <Col xs={4} md={4} sm={4} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Product Name"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["name"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.name}</p>

                <Form.Group className="mb-3">
                  <Form.Label >Description</Form.Label>
                  <Form.Control
                    type="text"
                    id="description"
                    name="description"
                    placeholder="Description"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["description"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.description}</p>

                <Form.Group className="mb-3">
                  <Form.Label className="field">HSN Code</Form.Label>
                  <Form.Control
                    type="text"
                    id="hsncode"
                    name="hsncode"
                    placeholder="HSN Code"
                    onChange={(e) => handleOnChange(e)}
                    maxLength={10}
                    readOnly={isReadOnly}
                    value={formValues["hsncode"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.hsncode}</p>
                {!productDisable && ( 
                <Form.Group className="mb-3">
                  <Form.Label className="field required">UOM</Form.Label>
                  <Select
                    id="uom"
                    name="uom"
                    value={{
                      value: uomIdSelect.value,
                      label: uomIdSelect.label,
                    }}
                    options={uomOpt}
                    defaultValue={{
                      value: uomIdSelect.value,
                      label: uomIdSelect.label,
                    }}
                    onChange={handleUomSelect}
                    isDisabled={isReadOnly}
                  />
                </Form.Group>
                )}
                <p className="error-msg">{formErrors.uom}</p>

                <div key={`inline-radio-trade-type`} className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label >Trade Type &nbsp;&nbsp;</Form.Label>
                    {tradeTypeList.map((tradeType, idx) => (
                      <Form.Check
                        key={`trade-type-radio-key-${tradeType.key}`}
                        //disabled={isReadOnly}
                        inline
                        label={tradeType.value}
                        name="tt_key"
                        type={"radio"}
                        //checked={(state.action === 'add' && tradeType.key === 'BUY') ? true : tradeType.key === formValues["tt_key"] ? true : false}
                        //checked={(tradeType.key === formValues["tt_key"] || (state.action === 'add' && tradeType.key === 'BUY')) ? true : false }
                        checked={tradeType.key === formValues["tt_key"] ? true : false }
                        id={`tt_key-${tradeType.key}`}
                        onChange={handleOnRadioChange}
                        disabled={ (isReadOnly || productDisable) ? true : false}
                      />
                    ))}
                  </Form.Group>
                </div>
                 
                {!productDisable && ( 
                <div key={`inline-radio-is-composite`} className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label>Composite Product &nbsp;&nbsp;</Form.Label>
                    <Form.Check
                      // disabled={isReadOnly}()
                      inline
                      key="composite-status-key-yes"
                      label="Yes"
                      name="is_composite"
                      type={"radio"}
                      id={`is_composite-YES`}
                      onChange={handleOnRadioChange}
                      checked={formValues["is_composite"] === true ? true : false}
                      disabled={isReadOnly}
                    />
                    <Form.Check
                      //disabled={isReadOnly}
                      inline
                      key="composite-status-key-no"
                      label="No"
                      name="is_composite"
                      type={"radio"}
                      id={`is_composite-NO`}
                      onChange={(e) => handleOnRadioChange(e)}
                      checked={formValues["is_composite"] === false ? true : false}
                      disabled={isReadOnly}
                    />
                  </Form.Group>
                </div>
                )}
              </Col>

                      
               <Col xs={4} md={4} sm={4} lg={4}>
               {!productDisable && ( 
                <>
                <Form.Group className="mb-3">
                  <Form.Label className={formValues["tt_key"] === 'SELL' ? 'field required' : 'mb-3'} >Unit Price</Form.Label>
                  <Form.Control
                    
                    type="text"
                    id="unit_price"
                    name="unit_price"
                    placeholder="Unit Price"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    maxLength={10}
                    value={formValues["unit_price"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                    disabled={priceDisableState}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.unit_price}</p>
                </>
               )}
                
                <Form.Group className="mb-3">
                  <Form.Label className={formValues["tt_key"] === 'SELL' ? 'field required' : 'mb-3'}>CGST</Form.Label>
                  <Form.Control
                    
                    type="text"
                    id="cgst"
                    name="cgst"
                    placeholder="CGST"
                    onChange={(e) => handleOnChange(e)}
                    maxLength={10}
                    readOnly={isReadOnly}
                    value={formValues["cgst"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                    disabled={priceDisableState}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.cgst}</p>
                <Form.Group className="mb-3">
                  <Form.Label className={formValues["tt_key"] === 'SELL' ? 'field required' : 'mb-3'}>SGST</Form.Label>
                  <Form.Control
                   
                    type="text"
                    id="sgst"
                    name="sgst"
                    placeholder="SGST"
                    onChange={(e) => handleOnChange(e)}
                    maxLength={10}
                    readOnly={isReadOnly}
                    value={formValues["sgst"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                    disabled={priceDisableState}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.sgst}</p>
                <Form.Group className="mb-3">
                  <Form.Label className={formValues["tt_key"] === 'SELL' ? 'field required' : 'mb-3'}>IGST</Form.Label>
                  <Form.Control
                  
                    type="text"
                    id="igst"
                    name="igst"
                    placeholder="IGST"
                    onChange={(e) => handleOnChange(e)}
                    //onChange={(e, event) => validateFloatKeyPress(e, event)}
                    maxLength={10}
                    readOnly={isReadOnly}
                    value={formValues["igst"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                    disabled={priceDisableState}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.igst}</p>
              </Col>
              
              {!productDisable && ( 
              <Col xs={4} md={4} sm={4} lg={4}>
                <Row>
                  <Col xs={6} md={6} sm={6}>
                    <h5>Composite  product</h5>
                  </Col>
                  <Col xs={6} md={6} sm={6}>
                    <Button
                      className="btn btn-sm btn-warning d-flex justify-content-end pull-right float-end"
                      style={{ alignItems: "self-end" }}
                      onClick={newItemHandle}
                      disabled={(disableComposite || isReadOnly) ? true : false}
                    >
                      Add Item
                    </Button>

                  </Col>
                </Row>
                <Table
                  striped bordered hover
                  size="sm">
                  <thead>
                    <tr className="table-primary">
                      <th className="table-row-heading">S.No</th>
                      <th className="table-row-heading">Product</th>
                      <th className="table-row-heading">Qty</th>
                      <th className="table-row-heading">Uom</th>
                      <th className="table-row-heading">...</th>
                    </tr>
                  </thead>

                  <tbody>
                    {productCompositionList.map((item, idx) => (
                      <tr key={`prod-composition-table-${idx}`}>
                        <td className="w-25">{idx + 1}</td>
                        <td className="w-50">
                          <Select
                            id={`product-item-${idx}`}
                            name={`product-item-${idx}`}
                            //value={options.value}
                            value={{
                              value: item.product_id,
                              label: item.product_name,
                            }}
                            options={productList}
                            defaultValue={{
                              value: item.product_id,
                              label: item.product_name,
                            }}
                            isDisabled={(disableComposite || isReadOnly)? true: false}
                            onChange={handleProductSelect}
                          />
                          <p className="error-msg">{item.error}</p>
                        </td>
                        <td className="w-25">
                          <Form.Group className="mb-3">
                            <Form.Control
                            
                              type="text"
                              size="sm"
                              id={`quantity-${idx}`}
                              name={`quantity-${idx}`}
                              placeholder="Quantity"
                              onChange={(e) => handleOnItemChange(e)}
                              readOnly={isReadOnly}
                              value={item.quantity}
                              disabled={(disableComposite || isReadOnly)? true: false}
                              //defaultValue={item.quantity}
                              style={{
                                border: !isReadOnly && "1px solid gray",
                              }}
                            />
                          </Form.Group>
                        </td>
                        <td>{item.uom}</td>
                        <td>
                          <BsFillXCircleFill
                            size={28}
                            style={{ color: "red", cursor: "pointer" }}
                            onClick={(e) => handleItemDelete(e)}
                            name={`item-delete-icon-${idx}`}
                            id={`item-delete-icon-${idx}`}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <p className="error-msg">{formErrors.is_composite}</p>
               
              </Col>
                )}
            </Row>
             
            {!isReadOnly && (
            <Row>
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                style={{ disply: "flex", justifyContent: "right" }}
              >
                <div className="d-flex justify-content-end">
                  <Button
                    variant="secondary"
                    id="Reset"
                    onClick={handleReset}
                  >
                    Clear
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    type="submit"
                    id="save"
                    onClick={handleSave}

                  disabled={submitAction ? true: false}
                  >
                      {submitAction &&
                      <>
                      <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                     <span className="visually-hidden">Loading...</span>
                     </>
                    } 
                    Save
                   
                  </Button>
                </div>
              </Col>

            </Row>
            )}
            <Modal
              show={showDialog}
              onHide={handleCloseDialog}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>{showMsgTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{showMsg}</Modal.Body>
              <Modal.Footer>
                { dialogAction !== 'update_api' &&
                  <Button variant="secondary" onClick={handleCloseDialog}>Cancel</Button>
                }
                <Button variant="primary" onClick={handlePostSaveOk}>Ok</Button>
              </Modal.Footer>
            </Modal>
          </div>
        </>
        :
        <></>
      }
      </Form>
      )
      :
      <div className="container-spinner">
      <div className="vertical-center-spinner">
       {/*<Button variant="primary" disabled>*/}
       <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
 
       {/*</Button>*/}
       </div>
       </div>
    }
    </Container>
  );
};
export default AddUpdateProduct;
