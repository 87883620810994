import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Form, Button, View,Modal,Spinner, Image } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
import Sidebar2 from "./Sidebar2";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
//import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
//import DateTimeField from "react-bootstrap-datetimepicker"
//import DateTimePicker from "react-datetime-picker";

import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client } from "../config/Config";
import axios from "axios";

import { useLocation } from "react-router-dom";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import { BsFillPenFill, BsFillTrashFill,BsPencilSquare,BsArrowLeftCircle } from "react-icons/bs";

import DateTimePicker from "react-datetime-picker";

import MenuSettings from "./MenuSettings";
import SidebarMain from "./SidebarMain";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';

//import * as bs from 'bootstrap/dist/css/bootstrap.css';
//import * as bst from 'bootstrap/dist/css/bootstrap-theme.css';


const AddUpdateBill = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  //console.log("PASSED STATE VALUE IN Patient Add/Update page : ", state);

  function padTo2Digits(num) {
    console.log("<<<<<<<<<<<<<< given number >>>>>>>>>>>>>>>> : ", num);
    var modNum = num.toString().padStart(2, "0");
    console.log("Modified  value : ", modNum);
    return modNum;
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  let initialItems = [];
  const optionsPayment = [
    { value: 1, label: "Cash" },
    { value: 2, label: "Cheque" },
  ];
  
  const intialValues = {
   no: "",
   date: formatDate(new Date()),
   received: "",
   sum: "",
   amount: 0,
   towards: "",
   rupees: ""
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [toRender, setToRender] = useState(false);
  const [validateError, setValidateError] = useState(false);
  
  var validateErr = false;

 

  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );
  const [submitAction, setSubmitAction] = useState(false);
  const [paymentIdSelect, setPaymentIdSelect] = useState(optionsPayment[0]);
  const [paymentOpt, setPaymentOpt] = useState(optionsPayment);

  const handleOnChange = (e) => {
    //e.preventDefault();
    console.log("handleOnChange : ", e);

    console.log("Button clicked : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
/*
    setFormValues({ ...formValues, [name]: value});
    if (name === "phone" || name === "pincode" || name === "contact_number_primary" || name === "contact_number_secondary" ) {
      console.log("Inside check ******************");
      var valueTmp = e.currentTarget.value.replace(/[^\d]/g, '');
      setFormValues({ ...formValues, [name]: valueTmp });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
    */
    setFormValues({ ...formValues, [name]: value });
  }
  
  
  /*
  const changePassword = async () => {
    console.log("changePassword api called ..");
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}` };

      var reqParam = {
        old_password: formValues["old_password"],
        new_password: formValues["new_password"]
      };
     

      var res = await axios.post(
        client.domain + "/core/changePassword",
        reqParam,
        { headers },
        { withCredentials: false }
      );

      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.data[0]);
        setFormValues(res.data.detail.data[0]);
        setShowMsg("password Changed successfully...!");
        setShowMsgTitle("Success");
        setShowDialog(true);
        setSubmitAction(false);
      }
      else {
        setShowMsg(res.data.detail.data.message);
        setShowMsgTitle("Alert!");
        setShowDialog(true);
        setSubmitAction(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          setShowMsg(error.response.data.detail);
          setShowMsgTitle("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
  };
  */
  
  const handleValueChange = async (e) => {
    console.log("On change clicked : ", e);

    var tagId = e.target.id;
    var currentRowName = tagId.substring(tagId.lastIndexOf("-") + 1);
    var nameOfElement = tagId.substring(0, tagId.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current row id : ", currentRowName);

   
  };
  useEffect(() => {
    if (!initialized.current) {
      console.log("*************** useEffect::addUpdateProfile called ##########################");
      /*
      if ( state !== null ) {
        if ( state.action === 'add') {
          setPaymentIdSelect(optionsPayment[1]);
        }
      }
      */
      setPaymentIdSelect(optionsPayment[1]);
      //console.log("PASSED STATE VALUE IN Service Add/Update page : ", state);
      setToRender(true); 
      initialized.current = true;
      
    }
  }, []);
  

 

 
  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;
    if (!values.new_password) {
      errors.new_password = " New Password is required";
      validateErr = true;
      setValidateError(true);
    }

    if (!values.confirm_password) {
      errors.confirm_password = "Confirm Password should not be empty";
      validateErr = true;
    }

    if ( values.new_password !== values.confirm_password ) {
      errors.new_password = " New password and confirm password should be same";
      errors.confirm_password = "New password and confirm password should be same";
      validateErr = true;
    }

    

    console.log("BEFORE RETURN validate errors ...", validateError);
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };
  
  const handleEdit = async (e) => {
    console.log("handle edit clicked at AddUpdateCompany : ", e);
    setIsReadOnly(false);
  };
  
  const handleReset = async (e) => {
    console.log("handle reset clicked : ", e);
    navigate(0);
  };
  
  const handleSave = async (e) => {
    e.preventDefault();
    console.log("Handle Click save called : ", e);
    console.log("Formvalues : ", formValues);

    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    if (validateErr === false) {
      setSubmitAction(true);
      //changePassword();
    }
  
  };
  const handlePostSaveOk = () => {
    console.log("user confirmed to customer added ...");
    //console.log("user selected id for action is : ", selectedId);

    // call update API
    // updateCustomerStatus(selectedId);
    setShowDialog(false);
    //navigate("/listCustomers");
  };
  const handleCloseDialog = () => {
    setShowDialog(false);
    //navigate("/ListCustomers");
  };
  const handleBack = async (e) => {
    console.log("handle back clicked : ", e);
    navigate(-1);
  };
  const onChangeDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);

    setFormValues({ ...formValues, ["date"]: e.target.value });
  };
  const toSqlDatetime = (inputDate) => {
    const date = new Date(inputDate);
    const dateWithOffest = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    return dateWithOffest.toISOString().slice(0, 19).replace("T", " ");
  };

  const handlePaymentSelect = (e, data) => {
    console.log("handlePaymentSelect called ...", e);
    setPaymentIdSelect(optionsPayment[1]);
  };
  
 

  return (
    <>
      <Container>
        <SidebarMain />
        { toRender ?
        (
        <Form>
          <Row>
          <Col xs={4} md={4} sm={4} lg={4}>  
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>Cash Voucher</span>
            </Col>
            <Col
              xs={8}
              md={8}
              sm={8}
              lg={8}
            >
              
              <div className="d-flex justify-content-end">
              <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                  <BsArrowLeftCircle
                    key={`back-btn-bill-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                   
                    name={`back-icon-bill-name`}
                    id={`back-icon-bill-id`}
                  />
                </Button>
                &nbsp;&nbsp;
                
              <Button variant="primary" className="btn btn-primary btn-md"
              key={`edit-btn-company-key`}
              style={{ cursor: "pointer" }}
              onClick={handleEdit}
              name={`edit-icon-password-name`}
              id={`edit-icon-password-id`}>
                  <BsPencilSquare
                    size={24}
                  />
                </Button>
                </div>
                
                </Col> 
          </Row>
          <div className="ui-divider"></div>
          <div className="ui-form">
          <hr align="center" />
           
              
            <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Bill No</Form.Label>
                  <Form.Control
                    type="text"
                    id="no"
                    name="no"
                    placeholder="Bill No"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["no"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.no}</p>
              </Col> 
              <Col xs={3} sm={3} md={3} lg={3}>
                    <Form.Group className="mb-1" >
                      <Form.Label>Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="date"
                        id="date"
                        placeholder="Date"
                        value={formValues.date}
                        onChange={onChangeDatePicker}
                        style={{
                          border: !isReadOnly && "1px solid gray",
                        }}
                      />
                    </Form.Group>
                  </Col>
                  
                 <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label >Amount paid to</Form.Label>
                  <Form.Control
                    type="text"
                    id="received"
                    name="received"
                    placeholder="Amount paid to"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["received"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.received}</p>
              </Col> 
             
            
            <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Sum of Rupees</Form.Label>
                  <Form.Control
                    type="text"
                    id="sum"
                    name="sum"
                    placeholder="Sum of Rupees"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["sum"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.sum}</p>
              </Col> 
              </Row>
              <Row>
              <Col xs={3} md={3} sm={3} lg={3}>
            <Form.Group className="mb-3">
             <Form.Label>By</Form.Label>
                <Select
                
                  name="paymentmode"
                  id="paymentmode"
                  value={{
                    value: paymentIdSelect.value,
                    label: paymentIdSelect.label,
                  }}
                  options={paymentOpt}
                  defaultValue={{
                    value: paymentIdSelect.value,
                    label: paymentIdSelect.label,
                  }}
                  onChange={handlePaymentSelect}
                  //isDisabled={isReadOnly}
                  style={{
                    border: !isReadOnly && "1px solid gray",
                  }}
                />
                </Form.Group>
              
              <p className="error-msg">{formErrors.payment}</p>
            </Col>
           
            <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label >towards</Form.Label>
                  <Form.Control
                    type="text"
                    id="towards"
                    name="towards"
                    placeholder="towards"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["towards"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.towards}</p>
              </Col> 
              
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label >Rs.</Form.Label>
                  <Form.Control
                    type="text"
                    id="rupees"
                    name="rupees"
                    placeholder="Rs."
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["rupees"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.rupees}</p>
              </Col> 
            </Row>
           
            {!isReadOnly && (
            <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ disply: "flex", justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleReset(e)}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      //type="submit"
                      id="save"
                      onClick={(e) => handleSave(e)}
                      disabled={submitAction ? true: false}
                    >
                      {submitAction && 
                      <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Loading...</span>
                        </>  
                      }
                     Save
                      
                    </Button>
                  </div>
                </Col>  
              </Row>
            )} 
            
             <Modal
              show={showDialog}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>{showMsgTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{showMsg}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handlePostSaveOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal> 
            
          </div>
        </Form>
         )
         :
         <div className="container-spinner">
         <div className="vertical-center-spinner">
          {/*<Button variant="primary" disabled>*/}
          <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
             <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{ width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
                 <span className="visually-hidden">Loading...</span>
               </Spinner>
    
          {/*</Button>*/}
          </div>
          </div>
       }
      </Container>
    </>
  );
};

export default AddUpdateBill;
