import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Table,
  Spinner,
  Image
} from "react-bootstrap";
import { withRouter } from "react-router";
//import Sidebar from './Sidebar';
import Sidebar2 from "./Sidebar2";
import Footer from "./Footer";
//import Topmenu from "./Topmenu";
import OrderDetails from "./OrderDetails";
import "../Dashboard.css";

//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";

import { useNavigate } from "react-router-dom";

import * as Icon from "react-bootstrap-icons";
import axios from "axios";
import { client } from "../config/Config";
import Select from "react-select";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';



import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsArrowLeftCircle,
  BsCardText,
} from "react-icons/bs";

import { AiOutlineOrderedList } from "react-icons/ai";

import MenuOms from "./MenuOms";

import rmcBlueIcon from "../assets/images/ready_mix_blue.png";
import SidebarMain from "./SidebarMain";

import {getDistrictList, getStateList, getProductApiCall, getProductBuy, getProductsSell, formatDateIndian } from "./utils";

const ListInvoice = (props) => {
  const initialized = useRef(false);
  var orderListLoad = [
    /*
        {order_id: 12, order_number:"ORD89494", customer_name: "Rockbreeze Technologies", order_date:"2023-06-12", order_price: 34000.00, gst: 3400.00, total_price: 38400.00, amount_paid: 30000.00, balance: 8400.00},
        {order_id: 13, order_number:"ORD85995", customer_name: "SRT tranports", order_date:"2023-05-09", order_price: 34000.00, gst: 3400.00, total_price: 38400.00, amount_paid: 30000.00, balance: 8400.00},
        {order_id: 14, order_number:"ORD67883", customer_name: "AVS Constructions", order_date:"2023-06-15", order_price: 34000.00, gst: 3400.00, total_price: 38400.00, amount_paid: 30000.00, balance: 8400.00},
        {order_id: 15, order_number:"ORD65583", customer_name: "New way traders", order_date:"2023-07-11", order_price: 34000.00, gst: 3400.00, total_price: 38400.00, amount_paid: 30000.00, balance: 8400.00},
        {order_id: 16, order_number:"ORD46378", customer_name: "Zero Plus promoters", order_date:"2023-07-01", order_price: 34000.00, gst: 3400.00, total_price: 38400.00, amount_paid: 30000.00, balance: 8400.00}
    */
  ];

  const [orderList, setOrderList] = useState(orderListLoad);
  const [orderListFull, setOrderListFull] = useState([]);
  
  function padTo2Digits(num) {
    console.log("<<<<<<<<<<<<<< given number >>>>>>>>>>>>>>>> : ", num);
    var modNum = num.toString().padStart(2, "0");
    console.log("Modified  value : ", modNum);
    return modNum;
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }
  function formatIndianDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("-");
  }
  const intialValues = {
    start_date: formatDate(new Date()),
    end_date: formatDate(new Date()),
    //invoice_date: formatDate()
  }

  var prodListLoad = [
    {
      prod_id: 1,
      product_name: "M-10",
      hsn_code: "HSN778328",
      unit_price: 4300.0,
      quantity: 10,
      product_price: 43000.0,
      gst: 7740.0,
      total_product_price: 50740.0,
    },
    {
      prod_id: 1,
      product_name: "M-20",
      hsn_code: "HSN778329",
      unit_price: 4700.0,
      quantity: 10,
      product_price: 47000.0,
      gst: 8460.0,
      total_product_price: 55460.0,
    },
    {
      prod_id: 1,
      product_name: "M-30",
      hsn_code: "HSN778378",
      unit_price: 4900.0,
      quantity: 10,
      product_price: 49000.0,
      gst: 8820.0,
      total_product_price: 57820.0,
    },
  ];
  var dcLoad = [
    {
      dc_id: 12,
      dc_number: "DC102091Z1",
      dc_date: "2023-08-01",
      grade_id: 1,
      grade_name: "M-20",
      loading_time: "2023-08-01",
      vehicle_number: "TN 39 DX 9927",
      total_volume: "18 cum",
      volume: "10 cum",
      total_amount: 4200,
      driver_name: "Azhagu Raja",
    },
    {
      dc_id: 11,
      dc_number: "DC10209879",
      dc_date: "2023-07-31",
      grade_id: 2,
      grade_name: "M-15",
      loading_time: "2023-07-31",
      vehicle_number: "TN 39 DY 1254",
      total_volume: "28 cum",
      volume: "18 cum",
      total_amount: 42000,
      driver_name: "Mahesh C",
    },
  ];
  
  const productArrHc = [{value: 1, label: "M10 Grade"}, {value: 2, label: "M20 Grade"}, {value: 3, label: "M30 Grade"}];

  const [deliveryChellan, setDeliveryChellan] = useState([]);
  const [prodList, setProdList] = useState([]);
  const [toRender, setToRender] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [customerListFull, setCustomerListFull] = useState([]);
  const [customerIdSelect, setCustomerIdSelect] = useState({});
  const [optCustomer, setOptCustomer] = useState([]);
  const [productList, setProductList] = useState([]);
  const [productListFull, setProductListFull] = useState([]);
  const [productIdSelect, setProductIdSelect] = useState({});
  const [optProduct, setOptProduct] = useState([]);
  const [formValues, setFormValues] = useState(intialValues);
  const navigate = useNavigate();

  const handleClickAddOrder = (e) => {
    e.preventDefault();
    console.log("Add or Update Order button clicked ...", e);
    navigate(`/addUpdateInvoice`, {
      state: { passed_element: [], action: "add" },
    });
  };
  

  

  const handleEdit = (e, data) => {
    console.log("Handle action edit Clicked : ", e);
    //console.log("Handle Clicked edit DATA : ", data);
    console.log("Handle action edit Clicked target : ", e.target);
    console.log(
      "Handle action edit Clicked target Current : ",
      e.currentTarget
    );
    console.log(
      "Handle action edit Clicked target Current id : ",
      e.currentTarget.id
    );
    console.log("Handle action edit Clicked target ID : ", e.target.id);
    //console.log("Handle action edit Clicked target NAME : ", e.target.name);
    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(
        0,
        clickedTagId.lastIndexOf("-")
      );
      console.log("current selected element name : ", nameOfElement);
      console.log("Current SELECTED id : ", curSelId);
      console.log("CUR SELECTED VALUE : ", orderList[curSelId]);

      //navigate(`/${client.urlDelimiter}orderDetails`, {state : orderList[curSelId]});
      navigate(`/addUpdateInvoice`, {
        state: { passed_element: orderList[curSelId], action: "edit" },
      });
    }
  };

  const handleView = async (e) => {
    console.log("handle view called : ", e);

    console.log("Handle action view Clicked target : ", e.target);
    console.log(
      "Handle action view Clicked target Current : ",
      e.currentTarget
    );
    console.log(
      "Handle action view Clicked target Current id : ",
      e.currentTarget.id
    );
    console.log("Handle action view Clicked target ID : ", e.target.id);
    //console.log("Handle action edit Clicked target NAME : ", e.target.name);

    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(
        0,
        clickedTagId.lastIndexOf("-")
      );
      console.log("current selected element name view : ", nameOfElement);
      console.log("Current SELECTED id view : ", curSelId);
      console.log("CUR SELECTED VALUE view : ", orderList[curSelId]);
      console.log("Before call orderDetails page ...");
      navigate(`/invoiceDetails`, {
        state: { passed_element: orderList[curSelId], action: "view" },
      });
    }
  };

  const actionDeleteHandle = (e, data) => {
    console.log("Handle action delete Clicked : ", e);
    //console.log("Handle Clicked delete DATA : ", data);
  };

  for (var ni = 0; ni < deliveryChellan.length; ni++) {
    console.log("IND DAtA Dashboard : ", deliveryChellan[ni]);
  }

  const getOrderList = async() => {
    try {
      var aIVal = localStorage.getItem("_aI");
      console.log("Authorization token : ", aIVal);

      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}

      console.log("Headers : ", headers);

      
      var res = await axios.get(client.domain + "/getAllOrders", {headers}, {
        withCredentials: false,
      });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getOrdersList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log(
          "Response message from server getDistrictList : ",
          res.data.detail.res_data
        );
        //setOrderList(res.data.detail.data);
        //setOrderListFull(res.data.detail.data);

        if (res.data.detail.res_data.order_list.length > 0) {
          setOrderList(res.data.detail.res_data.order_list);
          setOrderListFull(res.data.detail.res_data.order_list);
        }
        setToRender(true);
      }
      
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
	setToRender(true);
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ",error.response.data.detail.res_data);
        }
      }
    }
    setToRender(true);
  };

  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect called ##########################"
      );

      //setProductInfo(product_info);
      getOrderList();
      
      ( async() => {
        var [retState, customerArr] = await getCustomerListDropdown();
        if ( retState ) {
          setOptCustomer(customerArr);
          setCustomerIdSelect(customerArr[0]);
        }

        var [retStateSellProd, newSellProdArr] = await getProductsSell();
        //countries.splice(0, 0, 'Kenya');
        var newSellProdArr1 = [{value: 0, label: "All Products"}, ...newSellProdArr];
          if ( retStateSellProd ) {
            console.log("RETURNED SELL PRODUCT LIST : ", newSellProdArr1);
            setOptProduct(newSellProdArr1);
            setProductIdSelect(newSellProdArr1[0]);
          }
      })();
      initialized.current = true;
    }
  }, []);

  const handleBack = async (e) => {
    console.log("handle back called : ", e);
    navigate(-1);
  };
  const getCustomerListDropdown = async () => {
    console.log("getCustomerListDropdown method called ...");
    var retState = false;
    
  
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(
        client.domain + "/crm/getAllCustomers",
        { headers },
        { withCredentials: false }
      );
      
      console.log(
        "Response from server getCustomerList : ",
        res.data.detail.data
      );
      if (res.data.detail.status === "SUCCESS") {
        console.log(
          "Response message from server getCustomerList : ",
          res.data.detail.data
          
        );
        
        
  
        var customerArr = [];
        var custAll = { value: 0, label: 'All Customers'};
        customerArr.push(custAll);
        res.data.detail.data.map((itemCustomer, idx) => {
          console.log("Ind val : ", itemCustomer);
          var customerInd = {
            value: itemCustomer.id,
            label: itemCustomer.name,
          };
          console.log("In customer modified : ", customerInd);
          customerArr.push(customerInd);
        });

        var keySearch = "order_date";
        
        
  
        console.log("Mod Full VALUE : ", customerArr);
        retState = true;
      }
     
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.data
          );
         
        }
      }
    }
    return [retState, customerArr];
  };
  const handleCustomerSelect = (e, data) => {
    console.log("handleCustomerSelect called ...", e);

    var orderListFilter = []

    if ( e.value === 0 ) {
      setOrderList(orderListFull);
    }
    else {
      orderListFull.map((cust) => {
        if ( cust.customer_id === e.value ) {
          orderListFilter.push(cust);
        }
      })
      setOrderList(orderListFilter);
    }
    setCustomerIdSelect(e);
  };
  const handleProductSelect = (e, data) => {
    console.log("handleProductSelect called ...", e);
    
    var orderListFilter = []
    /*
    if ( e.value === 0 ) {
      setOrderList(orderListFull);
    }
    else {
      orderListFull.map((prod) => {
        if ( prod.id === e.value ) {
          orderListFilter.push(prod);
        }
      })
      setOrderList(orderListFilter);
    }
    */
    setProductIdSelect(e);

  };
  const onChangeDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);
    var orderListFilter = []
   /*
    const start = Date.parse('');
    const end = Date.parse('');
    const d = Date.parse('');
    d.valueOf() >= start.valueOf() && d.valueOf() <= end.valueOf()//true
    */
    if ( e.value === 0 ) {
      setOrderList(orderListFull);
    }
    else {
      productListFull.map((date) => {
        if ( date.order_date === e.value ) {
          orderListFilter.push(date);
        }
      })
      setOrderList(orderListFilter);
    }
    
    
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };
 

  const handleDateSearch = async() => {
    console.log("handleDateSearch called ...");
  }
  /*
  const dateFormatter(invoice_date) {
    if (!invoice_date) {
          return "";
    }
    return `${moment(invoice_date).format("DD-MM-YYYY")? moment(invoice_date).format("DD-MM-YYYY"):moment(cell).format("DD-MM-YYYY") }`;
}
*/
  return (
    <Container>
     <SidebarMain />
      { toRender ?  
      <form>
        <Row>
        
        <Col xs={1} md={1} sm={1} lg={1}> 
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem" }}>Invoice</span>
        </Col>
             <Col xs={2} md={2} sm={2} lg={2}>
              <Row>
              
              
              <Col>
              <Select
                    inline
                    id="customer"
                    name="customer"
                    value={{
                      value: customerIdSelect.value,
                      label: customerIdSelect.label,
                    }}
                    options={optCustomer}
                    defaultValue={{
                      value: customerIdSelect.value,
                      label: customerIdSelect.label,
                    }}
                    onChange={handleCustomerSelect}
                    //isDisabled={isReadOnly}
                  />
                  </Col>
                  
                  </Row>    
            </Col>
            <Col xs={2} md={2} sm={2} lg={2}> 
            {/*
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1rem",verticalAlign:"bottom" }}>Product:</span>
            </Col>
            */}
            <Row>
            
            
             <Col style={{paddingRight: '40px'}}>
              <Select
                    inline
                    id="product"
                    name="product"
                    value={{
                      value: productIdSelect.value,
                      label: productIdSelect.label,
                    }}
                    options={optProduct}
                    defaultValue={{
                      value: productIdSelect.value,
                      label: productIdSelect.label,
                    }}
                    onChange={handleProductSelect}
                    //isDisabled={isReadOnly}
                  />
                  </Col> 
                
                  </Row> 
            </Col>

            <Col xs={5} md={5} sm={5} lg={5}> 
            {/*<span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1rem",verticalAlign:"bottom" }}>StartDate:</span>*/}
            <Row>
             
            <Col>
                <Form.Control
                  type="date"
                  name="start_date"
                  placeholder="Start Date"
                  value={formValues.start_date}
                  onChange={onChangeDatePicker}
                  //readOnly={isReadOnly}
                  
                />
              
            </Col>
          
            <Col>
                <Form.Control
                  type="date"
                  name="end_date"
                  placeholder="End Date"
                  value={formValues.end_date}
                  onChange={onChangeDatePicker}
                  //readOnly={isReadOnly}
                  
                />
                 </Col>
                 </Row>
                 </Col>

                 <Col
            xs={2}
            md={2}
            sm={2}
            lg={2}
            className="d-flex justify-content-end float-end"
            
          >
            <Button id="search-order-id"
            size={"sm"}
            variant="success"
            onClick={(e) => handleDateSearch(e)}>
              Search
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button id="add-order-id"
            size={"sm"}
            variant="warning"
            onClick={(e) => handleClickAddOrder(e)}>
              New Invoice
            </Button>

          </Col>
          
        </Row>
        <br></br>

        {/*<Row style={{height:props.height}}><br /></Row>*/}
        {/*
      <ListGroup>
      <ListGroup.Item>
        <Row>
        <Col xs={1} md={1} sm={1} className="dc-heading-invoice"><div>#</div></Col>    
        <Col xs={2} md={2} sm={2} className="dc-heading-invoice"><div>Order#</div></Col>
        <Col xs={2} md={2} sm={2} className="dc-heading-invoice"><div>Customer</div></Col>
        <Col xs={1} md={1} sm={1} className="dc-heading-invoice"><div>Product Price</div></Col>
        <Col xs={1} md={1} sm={1} className="dc-heading-invoice"><div>GST</div></Col>
        <Col xs={1} md={1} sm={1} className="dc-heading-invoice"><div>Order Amount</div></Col>
        <Col xs={1} md={1} sm={1} className="dc-heading-invoice"><div>Amount Received</div></Col>
        <Col xs={1} md={1} sm={1} className="dc-heading-invoice"><div>Balance</div></Col>
        <Col xs={2} md={2} sm={2} className="dc-heading-invoice"><div>...</div></Col>
        </Row>
        </ListGroup.Item>
        </ListGroup>

        <Row style={{height:props.height}}><br /></Row>
        

        <ListGroup variant="flush">
      {orderList.map((ordInd, idx) => (
        <ListGroup.Item id={`order-id-listItem-${idx}`} name={`order-name-listItem-${idx}`}><Row>
        <Col xs={1} md={1} sm={1}><div>{idx + 1}{"."}</div></Col>    
        <Col xs={2} md={2} sm={2}><div>{ordInd.order_no}</div></Col>
        <Col xs={2} md={2} sm={2}><div>{ordInd.customer_name}</div></Col>
        <Col xs={1} md={1} sm={1}><div>{ordInd.amount_basic}</div></Col>
        <Col xs={1} md={1} sm={1}><div>{ordInd.cgst + ordInd.sgst + ordInd.igst}</div></Col>
        <Col xs={1} md={1} sm={1}><div>{ordInd.amount_total}</div></Col>
        <Col xs={1} md={1} sm={1}><div>{ordInd.amount_received}</div></Col>
        <Col xs={1} md={1} sm={1}><div>{ordInd.amount_balance}</div></Col>
        <Col xs={2} md={2} sm={2} className="dc-heading-invoice">
          <BsPencilSquare size={28} style={{color:"#0d6efd", cursor: 'pointer'}} onClick={(e) => handleEdit(e)} name={`order-view-icon-${idx}`} id={`order-view-icon-${idx}`}/>&nbsp;&nbsp;
          <AiOutlineOrderedList size={28} onClick={(e) => handleView(e)} style={{color:"red", cursor: 'pointer'}} name={`order-edit-icon-${idx}`} id={`order-edit-icon-${idx}`} /></Col>
        </Row>
        </ListGroup.Item>
      ))
      }
      </ListGroup>
    */}

        <Table striped bordered hover size="sm">
          <thead>
            <tr className="table-primary">
              <th className="table-row-heading" key={"order-list-heading-key"}>
                S.No
              </th>
              <th className="table-row-heading">Invoice Number</th>
              <th className="table-row-heading">Customer</th>
              <th className="table-row-heading">Invoice Date</th>
              <th className="table-row-heading">Product Price</th>
              <th className="table-row-heading">GST</th>
              <th className="table-row-heading">Invoice Amount</th>
              {/*
              <th className="table-row-heading">Amount Received</th>
              <th className="table-row-heading">Balance</th>
              */}
              <th className="table-row-heading">...</th>
            </tr>
          </thead>

          <tbody>
            {orderList.map((ordInd, idx) => (
              <tr key={`order-table-row-key-${idx}`}>
                <td
                  key={`order-table-row-key-sno-${idx}`}
                  id={`order-table-row-id-sno-${idx}`}
                  name={`order-table-row-name-sno-${idx}`}
                >
                  {idx + 1}
                </td>
                <td
                  key={`order-table-row-key-ono-${idx}`}
                  id={`order-table-row-id-ono-${idx}`}
                  name={`order-table-row-name-ono-${idx}`}
                >
                  {ordInd.order_no}
                </td>
                <td
                  key={`order-table-row-key-name-${idx}`}
                  id={`order-table-row-id-name-${idx}`}
                  name={`order-table-row-name-name-${idx}`}
                >
                  {ordInd.customer_name}
                </td>
                <td
                  key={`order-table-row-key-date-${idx}`}
                  id={`order-table-row-id-date-${idx}`}
                  name={`order-table-row-name-date-${idx}`}
                >
                  {formatDateIndian(new Date(ordInd.order_date))}
                  {/*{ordInd.order_date}*/}
                </td>
                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`order-table-row-key-amount-${idx}`}
                  id={`order-table-row-id-amount-${idx}`}
                  name={`order-table-row-name-amount-${idx}`}
                >
                  {ordInd.amount_basic}
                </td>
                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`order-table-row-key-gst-${idx}`}
                  id={`order-table-row-id-gst-${idx}`}
                  name={`order-table-row-name-gst-${idx}`}
                >
                  {parseFloat(ordInd.cgst + ordInd.sgst + ordInd.igst).toFixed(2)}
                </td>
                
                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`order-table-row-key-total-${idx}`}
                  id={`order-table-row-id-total-${idx}`}
                  name={`order-table-row-name-total-${idx}`}
                >
                  {ordInd.amount_total}
                </td>
                {/*
                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`order-table-row-key-received-${idx}`}
                  id={`order-table-row-id-received-${idx}`}
                  name={`order-table-row-name-received-${idx}`}
                >
                  {ordInd.amount_received}
                </td>
                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`order-table-row-key-balance-${idx}`}
                  id={`order-table-row-id-balance-${idx}`}
                  name={`order-table-row-name-balance-${idx}`}
                >
                  {ordInd.amount_balance}
                </td>
                */}
                <td
                  key={`order-table-row-key-acrtions-${idx}`}
                  id={`order-table-row-id-actions-${idx}`}
                  name={`order-table-row-name-actions-${idx}`}
                >
                  <BsCardText
                    size={28}
                    style={{ color: "#0d6efd", cursor: "pointer" }}
                    onClick={(e) => handleEdit(e)}
                    name={`order-view-icon-${idx}`}
                    id={`order-view-icon-${idx}`}
                  />
                  &nbsp;&nbsp;
                  {/*<AiOutlineOrderedList size={28} onClick={(e) => handleView(e)} style={{color:"red", cursor: 'pointer'}} name={`order-edit-icon-${idx}`} id={`order-edit-icon-${idx}`} />*/}
                  {/*
                  <Button
                    variant="light"
                    size="sm"
                    onClick={(e) => handleView(e)}
                    name={`order-edit-icon-${idx}`}
                    id={`order-edit-icon-${idx}`}
                  >
                    <img
                      src={rmcBlueIcon}
                      alt="teeth icon"
                      width={"28px"}
                      height={"28px"}
                    />
                  </Button>
                 */}
                 
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        </form>
      : 
      <div className="container-spinner">
     <div className="vertical-center-spinner">
      {/*<Button variant="primary" disabled>*/}
      <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
      {/*</div></Button>*/}
      </div>
      </div>
}
    </Container>
  );
};
//const Dashboard = withRouter(Dash);
export default ListInvoice;
