import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Table,
  Spinner,
  Image
} from "react-bootstrap";
import { withRouter } from "react-router";
import Sidebar from "./Sidebar";
import Sidebar2 from "./Sidebar2";
import Topmenu from "./Topmenu";
import "../Dashboard.css";
import Badge from "react-bootstrap/Badge";

//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";

import { useNavigate, useLocation } from "react-router-dom";

import * as Icon from "react-bootstrap-icons";
import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFillPrinterFill,
  BsArrowLeftCircle,
} from "react-icons/bs";

import axios from "axios";
import { client } from "../config/Config";
import MenuOms from "./MenuOms";
import SidebarMain from "./SidebarMain";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';

//const path = require('path');

const InvoiceDetails = (props) => {
  const initialized = useRef(false);
  console.log("PROPS Route : ", props);
  const { state, location } = useLocation();
  console.log("PASSED STATE VALUE : ", state);

  //console.log("PROPS Navigation : ", navigation);

  var prodListLoad = [
    //{prod_id: 1, product_name: "M-10", hsncode:"HSN778328", unit_price:4300.00, quantity:10, total_item_price: 43000.00, gst: 7740.00, total_price:50740.00},
    //{prod_id: 1, product_name: "M-20", hsncode:"HSN778329", unit_price:4700.00, quantity:10, total_item_price: 47000.00, gst: 8460.00, total_price:55460.00},
    //{prod_id: 1, product_name: "M-30", hsncode:"HSN778378", unit_price:4900.00, quantity:10, total_item_price: 49000.00, gst: 8820.00, total_price:57820.00}
  ];
  var dcLoad = [
    //{dc_id: 12, dc_number:"DC102091Z1", dc_date:"2023-08-01", grade_id:1, grade_name:"M-20", loading_time:"2023-08-01", vehicle_number:"TN 39 DX 9927", total_volume:"18 cum", volume: "10 cum", total_amount:4200, driver_name:"Azhagu Raja"},
    //{dc_id: 11, dc_number:"DC10209879", dc_date:"2023-07-31", grade_id:2, grade_name:"M-15", loading_time:"2023-07-31", vehicle_number:"TN 39 DY 1254", total_volume:"28 cum", volume: "18 cum", total_amount:42000, driver_name:"Mahesh C"}
  ];

  const [deliveryChellan, setDeliveryChellan] = useState(dcLoad);
  const [toRender, setToRender] = useState(false)
  const [prodList, setProdList] = useState(prodListLoad);
  console.log("PASSED ELEMENT : ", state.passed_element);
  console.log("PASSED ACTION: ", state.action);

  const [passedValue, setPassedValue] = useState(state.passed_element);

  const [orderInfoDet, setOrderInfoDet] = useState({});

  const [orderAddress, setOrderAddress] = useState({});

  const [addressFetched, setAddressFetched] = useState(false);
  const [orderDetFetched, setOrderDetFetched] = useState(false);
  const [orderItemFetched, setOrderItemFetched] = useState(false);
  const [orderDcFetched, setOrderDcFetched] = useState(false);

  const navigate = useNavigate();

  const handleClickAddInvoice = (e) => {
    e.preventDefault();
    console.log("Add or Update Invoice button clicked ...", e);
    navigate("/addUpdateInvoice");
  };

  const actionEditHandle = (e, data) => {
    console.log("Handle action edit Clicked : ", e);

    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(
        0,
        clickedTagId.lastIndexOf("-")
      );

      console.log(
        "current selected element name OrderDetails : ",
        nameOfElement
      );
      console.log("Current SELECTED id OrderDetails : ", curSelId);
      console.log(
        "CUR SELECTED VALUE OrderDetails : ",
        deliveryChellan[curSelId]
      );

      //console.log("Handle Clicked edit DATA : ", data);
      navigate("/addUpdateDC", {
        state: {
          passed_element: {
            dc_id: deliveryChellan[curSelId].dc_id,
            order_id: passedValue.order_id,
            customer_id: passedValue.customer_id,
            prod_list: prodList,
            dcInfo: deliveryChellan[curSelId],
            dc_list: deliveryChellan,
            order_info: orderInfoDet,
            order_address: orderAddress
          },
          action: "edit",
        },
      });
    }
  };

  const actionDeleteHandle = (e, data) => {
    console.log("Handle action delete Clicked : ", e);
    //console.log("Handle Clicked delete DATA : ", data);
  };

  const actionPrintHandle = (e, orderId, dcId) => {
    e.preventDefault();
    console.log("Handle action print Clicked : ", e);

    console.log("CALLED CURRENT PAGE OrderDetails : ", window.location.href);

    console.log("CALLED window locaion OrderDetails : ", window.location);
    //console.log("ORDER ID : ", orderId);
    //console.log("DC ID : ", dcId);

    //const navigate = useNavigate();

    //navigate("/printDC");
    //navigate("/printDocument", {state:{orderId: orderId, dcId: dcId}}, { replace: true });

    //navigate("/renderInWindow", {state:{orderId: orderId, dcId: dcId}}, { replace: true });
    console.log("LOCATION PATH DIRECTORY: ", __dirname);

    //navigate("/newWindow", {state:{orderId: orderId, dcId: dcId}}, { replace: true });

    //window.open(`http://localhost:3000/#/printDocument?order_id=${orderId}&dc_id=${dcId}`);
    //window.open(`http://${window.location.host}/#/printDocument?order_id=${orderId}&dc_id=${dcId}`);

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(
      urlStr.lastIndexOf(client.urlDelimiter) + 1
    );
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);

    //window.open(`${urlTmp}${client.urlDelimiter}/printDC?order_id=${orderId}&dc_id=${dcId}`);
    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printDC?order_id=${orderId}&dc_id=${dcId}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0,0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
      //var ulrToCall = `/#/printDocument?order_id=${orderId}&dc_id=${dcId}`
      //window.open(`file://${path.join(__dirname, ulrToCall)}`);

      //window.open("/printDocument")
    }

    for(var ni = 0; ni < deliveryChellan.length; ni++){
        console.log("IND DELIVERY CHELLAN Details : ", deliveryChellan[ni]);
    }

    const getOrderAddress = async(order_id) => {
      try {
          var res = await axios.get(client.domain + `/getOrderAddress?order_id=${order_id}`, { withCredentials: false });
          //.then((res) => {
              //console.log("ADD New user res:", res);
              //localStorage.setItem('_token', res.data.res_data.token);
              console.log("Response from server getOrderAddress : ", res.data.detail);
              if ( res.data.detail.status === 'SUCCESS') {
                console.log("Response message from server getOrderAddress : ", res.data.detail.res_data);
                setOrderAddress(res.data.detail.res_data.order_address);
                setAddressFetched(true);
              }
            }
            catch(error) {
              console.log("FULL ERROR : ", error);
              if ( error.code === 'ERR_NETWORK' ) {
                console.log("Not able to reach target server please try after sometime");
              }
              else{
                console.log("ERROR : ", error.response.data.detail);
                if (error.response.data.detail.status === 'FAILED') {
                  console.log('Request failed due to ', error.response.data.detail.res_data);
                  //setShowMsg(error.response.data.detail.res_data);
                  //setShowMsgTitle('LOGIN FAILED!');
                  //setShow(true);
                }
              }
            }
  }

    const getOrderDetails = async(order_id) => {
        try {
            var res = await axios.get(client.domain + `/getOrderById?order_id=${order_id}`, { withCredentials: false });
            //.then((res) => {
                //console.log("ADD New user res:", res);
                //localStorage.setItem('_token', res.data.res_data.token);
                console.log("Response from server getOrdersList : ", res.data.detail);
                if ( res.data.detail.status === 'SUCCESS') {
                  console.log("Response message from server getDistrictList : ", res.data.detail.res_data);
                  console.log("Response message from server getDistrictList user_info : ", res.data.detail.res_data.user_info);
                  console.log("Response message from server getDistrictList dist list : ", res.data.detail.res_data.order_info);
                  console.log("Length of district array : ", res.data.detail.res_data.order_info.length);
                  setOrderDetFetched(true);
                  //optionsDistrict = res.data.detail.res_data.district_list
    
                  setOrderInfoDet(res.data.detail.res_data.order_info);
                  /*
                  if ( res.data.detail.res_data.order_list.length > 0 ) {
                    setOrderList(res.data.detail.res_data.order_list);
                    //setOptDist(res.data.detail.res_data.district_list)
                    //setDistrictIdSelect({value: res.data.detail.res_data.district_list[0].value, label: res.data.detail.res_data.district_list[0].label});
                  }
                  */
        //setDistrictIdSelect(res.data.detail.res_data.district_list);

        //localStorage.setItem('_aI', res.data.detail.res_data.aI);
        //localStorage.setItem('userRoleId', userRoleId);
        //localStorage.setItem('username', res.data.detail.res_data.user_info.username);

        //navigate("/dashboard");
      }
      /*
                if (res.data.status === "SUCCESS" ) {
                  this.setState ({
                  tokenJwt: res.data.res_data.token,
                  isLoaded: true,
                  openOTP: true,
                  })
                    // this.props.history.push({pathname: client.taskViewPath, state:{pageList: this.state.page, orgName: this.state.orgName} });
                }
                else {
                }
              */
              }
              catch(error) {
                console.log("FULL ERROR : ", error);
                if ( error.code === 'ERR_NETWORK' ) {
                  console.log("Not able to reach target server please try after sometime");
                }
                else{
                  console.log("ERROR : ", error.response.data.detail);
                  if (error.response.data.detail.status === 'FAILED') {
                    console.log('Request failed due to ', error.response.data.detail.res_data);
                    //setShowMsg(error.response.data.detail.res_data);
                    //setShowMsgTitle('LOGIN FAILED!');
                    //setShow(true);
                  }
                }
              }
    }

    const getOrderItemsByOrderId = async(order_id) => {
        try {
            var res = await axios.get(client.domain + `/getOrderItemsByOrderId?order_id=${order_id}`, { withCredentials: false });
            //.then((res) => {
                //console.log("ADD New user res:", res);
                //localStorage.setItem('_token', res.data.res_data.token);
                console.log("Response from server getOrdersList : ", res.data.detail);
                if ( res.data.detail.status === 'SUCCESS') {
                  console.log("Response message from server OrderDetails : ", res.data.detail.res_data);
                  console.log("Response message from server OrderDetails user_info : ", res.data.detail.res_data.user_info);
                  console.log("Response message from server order items list : ", res.data.detail.res_data.order_item_list);
                  console.log("Length of district array : ", res.data.detail.res_data.order_item_list.length);
                  //optionsDistrict = res.data.detail.res_data.district_list
    
                  //setOrderInfoDet(res.data.detail.res_data.order_info);
                  
                  if ( res.data.detail.res_data.order_item_list.length > 0 ) {
                    setProdList(res.data.detail.res_data.order_item_list);
                    //setOptDist(res.data.detail.res_data.district_list)
                    //setDistrictIdSelect({value: res.data.detail.res_data.district_list[0].value, label: res.data.detail.res_data.district_list[0].label});
                  }
                  
                  setOrderItemFetched(true);
                  //setDistrictIdSelect(res.data.detail.res_data.district_list);
      
                  //localStorage.setItem('_aI', res.data.detail.res_data.aI);
                  //localStorage.setItem('userRoleId', userRoleId);
                  //localStorage.setItem('username', res.data.detail.res_data.user_info.username);
      
                  //navigate("/dashboard");
                }
                /*
                if (res.data.status === "SUCCESS" ) {
                  this.setState ({
                  tokenJwt: res.data.res_data.token,
                  isLoaded: true,
                  openOTP: true,
                  })
                    // this.props.history.push({pathname: client.taskViewPath, state:{pageList: this.state.page, orgName: this.state.orgName} });
                }
                else {
                }
              */
              }
              catch(error) {
                console.log("FULL ERROR : ", error);
                if ( error.code === 'ERR_NETWORK' ) {
                  console.log("Not able to reach target server please try after sometime");
                }
                else{
                  console.log("ERROR : ", error.response.data.detail);
                  if (error.response.data.detail.status === 'FAILED') {
                    console.log('Request failed due to ', error.response.data.detail.res_data);
                    //setShowMsg(error.response.data.detail.res_data);
                    //setShowMsgTitle('LOGIN FAILED!');
                    //setShow(true);
                  }
                }
              }
    }

    const getDCListByOrderId = async(order_id) => {
        try {
            var res = await axios.get(client.domain + `/getDCListByOrderId?order_id=${order_id}`, { withCredentials: false });
            //.then((res) => {
                //console.log("ADD New user res:", res);
                //localStorage.setItem('_token', res.data.res_data.token);
                console.log("Response from server getOrdersList : ", res.data.detail);
                if ( res.data.detail.status === 'SUCCESS') {
                  console.log("Response message from server OrderDetails : ", res.data.detail.res_data);
                  console.log("Response message from server OrderDetails user_info : ", res.data.detail.res_data.user_info);
                  console.log("Response message from server order items list : ", res.data.detail.res_data.dc_list);
                  console.log("Length of district array : ", res.data.detail.res_data.dc_list.length);
                  //optionsDistrict = res.data.detail.res_data.district_list
    
                  //setOrderInfoDet(res.data.detail.res_data.order_info);
                  
                  if ( res.data.detail.res_data.dc_list.length > 0 ) {
                    setDeliveryChellan(res.data.detail.res_data.dc_list);
                    //setProdList(res.data.detail.res_data.dc_list);
                    //setOptDist(res.data.detail.res_data.district_list)
                    //setDistrictIdSelect({value: res.data.detail.res_data.district_list[0].value, label: res.data.detail.res_data.district_list[0].label});
                  }
                  
                  //setDistrictIdSelect(res.data.detail.res_data.district_list);
      
                  //localStorage.setItem('_aI', res.data.detail.res_data.aI);
                  //localStorage.setItem('userRoleId', userRoleId);
                  //localStorage.setItem('username', res.data.detail.res_data.user_info.username);
      
                  //navigate("/dashboard");
                  setOrderDcFetched(true);
                }
                /*
                if (res.data.status === "SUCCESS" ) {
                  this.setState ({
                  tokenJwt: res.data.res_data.token,
                  isLoaded: true,
                  openOTP: true,
                  })
                    // this.props.history.push({pathname: client.taskViewPath, state:{pageList: this.state.page, orgName: this.state.orgName} });
                }
                else {
                }
              */
                //setOrderDcFetched(true);
              }
              catch(error) {
                console.log("FULL ERROR : ", error);
                if ( error.code === 'ERR_NETWORK' ) {
                  console.log("Not able to reach target server please try after sometime");
                }
                else{
                  console.log("ERROR : ", error.response.data.detail);
                  if (error.response.data.detail.status === 'FAILED') {
                    console.log('Request failed due to ', error.response.data.detail.res_data);
                    //setShowMsg(error.response.data.detail.res_data);
                    //setShowMsgTitle('LOGIN FAILED!');
                    //setShow(true);
                  }
                }
                //setOrderDcFetched(true);
              }
    }
   /*
    const handleClickAddDC = async(e) => {
        console.log("NEW dc button clicked ....", e);
        //navigate("/addUpdateDC");
        navigate("/addUpdateDC", {state : {passed_element: {
          order_id: passedValue.order_id, 
          customer_id: passedValue.customer_id, 
          prod_list: prodList, 
          dc_list: deliveryChellan,
          order_info: orderInfoDet,
          order_address: orderAddress
        }, action: "add"
        }});
    }
   */
    useEffect(() => {
        if (!initialized.current && passedValue !== null) {
          console.log("*************** useEffect called ##########################");

          //setProductInfo(product_info);
          
          getOrderAddress(passedValue.order_id);
          getOrderDetails(passedValue.order_id);
          getOrderItemsByOrderId(passedValue.order_id);
          getDCListByOrderId(passedValue.order_id);
          setToRender(true);
          initialized.current = true;
        }
      }, []);
    /*
    const handlePrintOrder = async(e) => {
      console.log("Handle print order clicked : ", e);
      //passedValue.order_id

      e.preventDefault();
      console.log("Handle action print Clicked : ", e);

      console.log("CALLED CURRENT PAGE OrderDetails PRINT DOCUMENT : ", window.location.href);

      console.log("CALLED window locaion OrderDetails PRINT DOCUMENT : ", window.location);
      //console.log("ORDER ID : ", orderId);
      //console.log("DC ID : ", dcId);

      //const navigate = useNavigate();

      //navigate("/printDC");
      //navigate("/printDocument", {state:{orderId: orderId, dcId: dcId}}, { replace: true });

      //navigate("/renderInWindow", {state:{orderId: orderId, dcId: dcId}}, { replace: true });
      console.log("LOCATION PATH DIRECTORY PRINT DOCUMENT : ", __dirname);

      //navigate("/newWindow", {state:{orderId: orderId, dcId: dcId}}, { replace: true });

      //window.open(`http://localhost:3000/#/printDocument?order_id=${orderId}&dc_id=${dcId}`);
      //window.open(`http://${window.location.host}/#/printDocument?order_id=${orderId}&dc_id=${dcId}`);

      var urlStr = window.location.href;
      var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
      var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

      console.log("CALLED URL : ", urlTmp);
      console.log("CALLED PAGE : ", paramsTmp);

      var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printOrder?order_id=${passedValue.order_id}`, "width=" + window.screen.width + ",height=" + window.screen.height);
      //var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printOrder?order_id=${passedValue.order_id}`,"popup", "fullscreen");
      winPrint.moveTo(0,0);
      winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
    }
    */


    const handlePrintFullOrder = async(e) => {
      console.log("Handle print order clicked : ", e);
      //passedValue.order_id

      e.preventDefault();
      console.log("Handle action print Clicked : ", e);

      console.log("CALLED CURRENT PAGE OrderDetails PRINT DOCUMENT : ", window.location.href);

      console.log("CALLED window locaion OrderDetails PRINT DOCUMENT : ", window.location);
      //console.log("ORDER ID : ", orderId);
      //console.log("DC ID : ", dcId);

      //const navigate = useNavigate();

      //navigate("/printDC");
      //navigate("/printDocument", {state:{orderId: orderId, dcId: dcId}}, { replace: true });

      //navigate("/renderInWindow", {state:{orderId: orderId, dcId: dcId}}, { replace: true });
      console.log("LOCATION PATH DIRECTORY PRINT DOCUMENT : ", __dirname);

      //navigate("/newWindow", {state:{orderId: orderId, dcId: dcId}}, { replace: true });

      //window.open(`http://localhost:3000/#/printDocument?order_id=${orderId}&dc_id=${dcId}`);
      //window.open(`http://${window.location.host}/#/printDocument?order_id=${orderId}&dc_id=${dcId}`);

      var urlStr = window.location.href;
      var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
      var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

      console.log("CALLED URL : ", urlTmp);
      console.log("CALLED PAGE : ", paramsTmp);

      //window.open(`${urlTmp}${client.urlDelimiter}/printFullOrder?order_id=${passedValue.order_id}`);
      var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printFullOrder?order_id=${passedValue.order_id}`, "width=" + window.screen.width + ",height=" + window.screen.height);
      winPrint.moveTo(0,0);
      winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
    }

    const handleBack = async() => {
      console.log("handle back called ...");
      navigate(-1);
    }

    return (
      <>
      { (addressFetched && orderDetFetched &&  orderItemFetched) &&
        
        <Container>
          <SidebarMain />
          { toRender ?
        (
          <div>
            {/*    
        <Row>
            <Col xs={12} md={12} sm={12}>
        <Button id="addUpdateId" className="float-end" onClick={(e)  => handleClickAddInvoice(e)}>New Invoice
        </Button>
        </Col>
        </Row>
        */}
            {/*<br></br>*/}

            <Row>
              <Col xs={2} md={2} sm={2} lg={2}>
                <Col />
                <h4>Invoice Details</h4>
              </Col>
              <Col
                xs={10}
                md={10}
                sm={10}
                lg={10}
                className="d-flex justify-content-end float-end"
              >
                <Button variant="warning" className="btn btn-warning btn-md">
                  <BsArrowLeftCircle
                    key={`back-btn-doctor-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleBack(e)}
                    name={`back-icon-doctor-name`}
                    id={`edit-icon-doctor-id`}
                  />
                </Button>
              </Col>
            </Row>

            <br></br>

            <Card>
              <Card.Header>
                {/*
                <Badge pill bg="success" size="sm">
                  <h6 style={{paddingTop: '8px'}}>{orderInfoDet.order_no}</h6>
                </Badge>
                */}
                <Row>
                <Col xs={4} sm={4} md={4} lg={4}>
                <Badge pill bg="primary"><h6 style={{paddingTop: '4px'}}>{orderInfoDet.order_no}</h6></Badge>
                </Col>
                {/*<Button variant="primary">View Invoice</Button>*/}
                <Col xs={8} sm={8} md={8} lg={8}>
                <div className="float-end justify-content-end">
                  {/*
                <Button id="orderPrint" 
                name = "orderPrint"  
                onClick={(e)  => handlePrintOrder(e)}
                disabled={deliveryChellan.length > 0 ? false: true}
                >
                  <BsFillPrinterFill
                    size={18}
                    style={{ color: "white", cursor: "pointer" }}
                    name={`print-order-dc-icon`}
                    id={`print-order-dc-icon`}
                  />
                &nbsp;
                Print Order from DC
                </Button>
                &nbsp;&nbsp;
              */}
                <Button id="fullOrderPrint" name = "fullorderPrint"  onClick={(e)  => handlePrintFullOrder(e)}>
                <BsFillPrinterFill
                  size={18}
                  style={{ color: "white", cursor: "pointer" }}
                  name={`print-order-dc-icon`}
                  id={`print-order-dc-icon`}
                />
                &nbsp;
                  Print Order Only</Button>
                </div>
                </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <Row>
                    <Col xs={6} md={6} sm={6}>
                      <h5>Delivery Address:</h5>
                      {orderAddress.billing_address.contact_name}
                      <br></br>
                      {orderAddress.billing_address.contact_number}
                      <br></br>
                      {orderAddress.billing_address.address_line1}
                      <br></br>
                      {orderAddress.billing_address.address_line2}-
                      {orderAddress.billing_address.pincode}
                    </Col>
                    <Col xs={6} md={6} sm={6}>
                      <h5>Billing Address:</h5>
                      {orderAddress.delivery_address.contact_name}
                      <br></br>
                      {orderAddress.delivery_address.contact_number}
                      <br></br>
                      {orderAddress.delivery_address.address_line1}
                      <br></br>
                      {orderAddress.delivery_address.address_line2}-
                      {orderAddress.delivery_address.pincode}
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>

      
                {/*
        <Card.Text>
        
        <Row><Col xs={12} md={12} sm={12} className="dc-heading-invoice"><div>Items</div></Col></Row>
        <Row>
        <Col xs={1} md={1} sm={1} className="dc-heading-invoice"><div>#</div></Col>    
        <Col xs={2} md={2} sm={2} className="dc-heading-invoice"><div>Product</div></Col>
        <Col xs={2} md={2} sm={2} className="dc-heading-invoice"><div>HSN Code</div></Col>
        <Col xs={1} md={1} sm={1} className="dc-heading-invoice"><div>Unit Price</div></Col>
        <Col xs={1} md={1} sm={1} className="dc-heading-invoice"><div>Quantity</div></Col>
        <Col xs={2} md={2} sm={2} className="dc-heading-invoice"><div>Product Price</div></Col>
        <Col xs={1} md={1} sm={1} className="dc-heading-invoice"><div>GST Price</div></Col>
        <Col xs={2} md={2} sm={2} className="dc-heading-invoice"><div>Total Price</div></Col>
        </Row>

        <Row style={{height:props.height}}><br /></Row>
        

      {prodList.map((prodInd, idx) => (
        <Row>
        <Col xs={1} md={1} sm={1}><div>{idx + 1}{"."}</div></Col>    
        <Col xs={2} md={2} sm={2}><div>{prodInd.product_name}</div></Col>
        <Col xs={2} md={2} sm={2}><div>{prodInd.hsncode}</div></Col>
        <Col xs={1} md={1} sm={1}><div>{prodInd.unit_price}</div></Col>
        <Col xs={1} md={1} sm={1}><div>{prodInd.quantity}</div></Col>
        <Col xs={2} md={2} sm={2}><div>{prodInd.total_item_price}</div></Col>
        <Col xs={1} md={1} sm={1}><div>{prodInd.cgst + prodInd.sgst + prodInd.igst}</div></Col>
        <Col xs={2} md={2} sm={2}><div>{prodInd.total_price}</div></Col>
        </Row>
      ))
      }
      </Card.Text>
      </Card.Body>
          
       
      {/*<Row style={{height:props.height, paddingLeft: "30px", width: "100%"}}><hr style={{height:"1px", color:"gray", backgroundColor:"gray"}} /></Row>*/}

      <Card.Body>
        <Card.Text>
          
        
        
        
    
        
        
                {/*<Row>*/}
                  {/*<Col xs={6} md={6} sm={6} lg={6}>*/}
                    {/*<div className="d-flex justify-content-start">*/}
                      <Badge pill bg="secondary">
                        <h6>Product Item List</h6>
                      </Badge>
                    {/*</Col></div>*/}
                  {/*</Row></Col>*/}
                {/*</Row>*/}

                {/*
                <div className="float-end">
                <Button id="orderPrint" name = "orderPrint"  onClick={(e)  => handlePrintOrder(e)}>
                <BsFillPrinterFill
                  size={24}
                  style={{ color: "white", cursor: "pointer" }}
                  name={`print-order-dc-icon`}
                  id={`print-order-dc-icon`}
                />
                &nbsp;
                  Print Order from DC</Button>
                &nbsp;&nbsp;
                <Button id="fullOrderPrint" name = "fullorderPrint"  onClick={(e)  => handlePrintFullOrder(e)}>
                <BsFillPrinterFill
                  size={24}
                  style={{ color: "white", cursor: "pointer" }}
                  name={`print-order-dc-icon`}
                  id={`print-order-dc-icon`}
                />
                &nbsp;
                  Print Order Only</Button>
                </div>
                */}
        </Card.Text>

                <Table striped bordered hover size="sm">
                  <thead>
                    <tr className="table-primary">
                      <th>#</th>
                      <th>Product</th>
                      <th>HSN Code</th>
                      <th>Unit Price</th>
                      <th>Quantity</th>
                      <th>Product Price</th>
                      <th>GST Price</th>
                      <th>Total Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {prodList.map((prodInd, idx) => (
                      <tr key={`dc-table-row-key-${idx}`}>
                        <td
                          key={`dc-table-row-key-sno-${idx}`}
                          id={`dc-table-row-id-sno-${idx}`}
                          name={`dc-table-row-name-sno-${idx}`}
                        >
                          {idx + 1}
                          {"."}
                        </td>
                        <td
                          key={`dc-table-row-key-$product-${idx}`}
                          id={`dc-table-row-id-product-${idx}`}
                          name={`dc-table-row-name-product-${idx}`}
                        >
                          {prodInd.product_name}
                        </td>
                        <td
                          key={`dc-table-row-key-hsn-${idx}`}
                          id={`dc-table-row-id-hsn-${idx}`}
                          name={`dc-table-row-name-hsn-${idx}`}
                        >
                          {prodInd.hsncode}
                        </td>
                        <td
                          key={`dc-table-row-key-unit-{idx}`}
                          id={`dc-table-row-id-unit-${idx}`}
                          name={`dc-table-row-name-unit-${idx}`}
                        >
                          {prodInd.unit_price}
                        </td>
                        <td
                          key={`dc-table-row-key-quantity-${idx}`}
                          id={`dc-table-row-id-quantity-${idx}`}
                          name={`dc-table-row-name-quantity-${idx}`}
                        >
                          {prodInd.quantity}
                        </td>
                        <td
                          key={`dc-table-row-key-price-${idx}`}
                          id={`dc-table-row-id-price-${idx}`}
                          name={`dc-table-row-name-price-${idx}`}
                        >
                          {prodInd.total_item_price}
                        </td>
                        {/*<td key={`dc-table-row-key-qty-${idx}`} id={`dc-table-row-id-qty-${idx}`} name={`dc-table-row-name-qty-${idx}`}>{dcInd.quantity}</td>*/}
                        <td
                          key={`dc-table-row-key-gstprice-${idx}`}
                          id={`dc-table-row-id-gstprice-${idx}`}
                          name={`dc-table-row-name-gstprice-${idx}`}
                        >
                          {prodInd.cgst + prodInd.sgst + prodInd.igst}
                        </td>
                        <td
                          key={`dc-table-row-key-totalprice-${idx}`}
                          id={`dc-table-row-id-totalprice-${idx}`}
                          name={`dc-table-row-name-totalprice-${idx}`}
                        >
                          {prodInd.total_price}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>

              <Row
                style={{
                  height: props.height,
                  paddingLeft: "30px",
                  width: "100%",
                }}
              >
                <hr
                  style={{
                    height: "2px",
                    color: "gray",
                    backgroundColor: "gray",
                  }}
                />
              </Row>

              <Card.Body>
                <Card.Text>
                  <Badge pill bg="secondary">
                    <h6>DC List</h6>
                  </Badge>
                 {/*
                  <Button
                    id="addNewDC"
                    name="addNewDC"
                    className="float-end"
                    onClick={(e) => handleClickAddDC(e)}
                  >
                    New DC
                  </Button>
                */}
                  <br></br>
                </Card.Text>
                
                <Card.Text>
                  
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr className="table-primary">
                        <th>#</th>
                        <th>DC#</th>
                        <th>DC Date</th>
                        <th>Product</th>
                        <th>Vehicle</th>
                        <th>Driver</th>
                        <th>...</th>
                      </tr>
                    </thead>

                    <tbody>
                      {deliveryChellan.map((dcInd, idx) => (
                        <tr key={`dc-table-row-key-${idx}`}>
                          <td
                            key={`dc-table-row-key-sno-${idx}`}
                            id={`dc-table-row-id-sno-${idx}`}
                            name={`dc-table-row-name-sno-${idx}`}
                          >
                            {idx + 1}
                            {"."}
                          </td>
                          <td
                            key={`dc-table-row-key-dcno-${idx}`}
                            id={`dc-table-row-id-dcno-${idx}`}
                            name={`dc-table-row-name-dcno-${idx}`}
                          >
                            {dcInd.dc_no}
                          </td>
                          <td
                            key={`dc-table-row-key-date-${idx}`}
                            id={`dc-table-row-id-date-${idx}`}
                            name={`dc-table-row-name-date-${idx}`}
                          >
                            {dcInd.dc_date}
                          </td>
                          <td
                            key={`dc-table-row-key-$prod-{idx}`}
                            id={`dc-table-row-id-prod-${idx}`}
                            name={`dc-table-row-name-prod-${idx}`}
                          >
                            <Table striped bordered hover size="sm">
                              <thead>
                                <tr className="table-primary">
                                  <th>#</th>
                                  <th>Product</th>
                                  <th>Quantity</th>
                                </tr>
                              </thead>
                              <tbody>
                                {dcInd.items.map((dcItem, idxItem) => (
                                  <tr key={`key-dc-item-row-${idxItem}`}>
                                    <td key={`key-dc-item-sno-${idxItem}`}>
                                      {idxItem + 1}
                                    </td>
                                    <td key={`key-dc-item-name-${idxItem}`}>
                                      {dcItem.product_name}
                                    </td>
                                    <td key={`key-dc-item-qty-${idxItem}`}>
                                      {dcItem.quantity}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </td>
                          <td
                            key={`dc-table-row-key-regno-${idx}`}
                            id={`dc-table-row-id-regno-${idx}`}
                            name={`dc-table-row-name-regno-${idx}`}
                          >
                            {dcInd.vehicle_reg_number}
                          </td>
                          <td
                            key={`dc-table-row-key-driver-${idx}`}
                            id={`dc-table-row-id-driver-${idx}`}
                            name={`dc-table-row-name-driver-${idx}`}
                          >
                            {dcInd.first_name}
                          </td>
                          
                          <td
                            key={`dc-table-row-key-action-${idx}`}
                            id={`dc-table-row-id-action-${idx}`}
                            name={`dc-table-row-name-action-${idx}`}
                          >
                            <BsPencilSquare
                              size={28}
                              style={{ color: "#0d6efd", cursor: "pointer" }}
                              onClick={actionEditHandle}
                              name={`edit-icon-${idx}`}
                              id={`edit-icon-${idx}`}
                            />
                            &nbsp;&nbsp;
                            <BsFillPrinterFill
                              size={28}
                              onClick={(event) =>
                                actionPrintHandle(
                                  event,
                                  passedValue.order_id,
                                  dcInd.dc_id
                                )
                              }
                              style={{ color: "#0d6efd", cursor: "pointer" }}
                              name={`print-icon-${idx}`}
                              id={`print-icon-${idx}`}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Text>

                <Row style={{ height: props.height }}>
                  <hr
                    style={{
                      height: "1.5px",
                      borderWidth: "0",
                      color: "gray",
                      backgroundColor: "gray",
                    }}
                  />
                </Row>

                {/*<Row style={{height:props.height, paddingLeft: "30px", width: "100%"}}><hr style={{height:"2px", color:"gray"}} /></Row>*/}

                <Card.Text>
                  <Row>
                    <Col xs={4} md={4} sm={4} style={{ textAlign: "left" }}>
                      Total Price : {orderInfoDet.amount_basic}
                    </Col>
                    <Col xs={4} md={4} sm={4} style={{ textAlign: "right" }}>
                      Total GST :{" "}
                      {orderInfoDet.cgst +
                        orderInfoDet.sgst +
                        orderInfoDet.igst}
                    </Col>
                    <Col xs={4} md={4} sm={4} style={{ textAlign: "right" }}>
                      Final Total : {orderInfoDet.amount_total}
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
            <br></br>
          </div>
          )
          :
          <div className="container-spinner">
          <div className="vertical-center-spinner">
           <Button variant="primary" disabled>
           <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
              <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{ width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
     
           </Button>
           </div>
           </div>
        }
        </Container>
      }
    </>
  );
}
//const Dashboard = withRouter(Dash);
export default InvoiceDetails;
