import { Fragment, useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  Badge,
  Modal,
  Spinner,
  Image
} from "react-bootstrap";

import Sidebar2 from "./Sidebar2";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import "../Dashboard.css";
import "../App.css";
import { client,appGlobal } from "../config/Config";
import axios from "axios";


import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsTrashFill,
  BsArrowLeftCircle,
  BsFillPenFill,
} from "react-icons/bs";
import SidebarMain from "./SidebarMain";
import {getDistrictList, getStateList } from "./utils";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';



//function AddUpdateCustomer () {
const AddUpdateCustomer = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  let initialItems = [];
  const intialValues = {
    name: "",
    contact_person: "",
    contact_person_number: "",
    gst_no: "",
    phone: "",
    email: "",
    website: "",
    address_line1: "",
    address_line2: "",
    state_id: 1,
    district_id: 1,
    pincode: "",
    opening_balance: 0.00
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});

  const [validateError, setValidateError] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);

  const [isReadOnly, setIsReadOnly] = useState(true);
  const [toRender, setToRender] = useState(false);

  const [show, setShow] = useState(false);
 // const [showMsg, setShowMsg] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  
  //const [selectedId, setSelectedId] = useState(-1);
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );
  const [submitAction, setSubmitAction] = useState(false);
  const [showMsg, setShowMsg] = useState(false);

  var validateErr = false;

  const handleOnChange = (e) => {
    //e.preventDefault();
    console.log("Button clicked : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);

    if (name === "phone" || name === "contact_person_number" || name === "pincode")  {
      console.log("Inside check ******************");
      //const numericRegExp = /^(?:[1-9]\d*|\d)$/;
      if (e.target.value.match(appGlobal.wholeNumberRegExp)) {
        //var valueTmp = Math.round(e.target.value.replace(/\D/g, ""));
        setFormValues({ ...formValues, [name]: e.target.value });
      }
    } 
    else if (name === "opening_balance") {
     if ( e.target.value.match(appGlobal.numericRegExp) ) {
      setFormValues({ ...formValues, [name]: value });
     }
    }
    else {
      setFormValues({ ...formValues, [name]: value });
    }
    /*
    {
      console.log("Inside check ******************");
     
      var valueTmp = e.currentTarget.value.replace(/[^\d]/g, '');
    
      setFormValues({ ...formValues, [name]: valueTmp });
      //}
    } else {
      setFormValues({ ...formValues, [name]: value });

    }

    */
    
  };
  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    if ( values.phone ) {
    console.log("phone number length : ", values["phone"].length);
    }
    if ( values.pincode ) {
      console.log("pincode number length : ", values["pincode"].length);
    }

    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!values.name) {
      errors.name = "Name is required!";
      validateErr = true;
      //setValidateError(true);
    }
    /*
    if (!values.contact_person) {
      errors.contact_person = "Contact person is required!";
      validateErr = true;
      //setValidateError(true);
    }
    */

    if (!values.address_line1) {
      errors.address_line1 = "Street Or Location is required!";
      validateErr = true;
      //setValidateError(true);
    }
    /*
    if (!values.pincode) {
      errors.pincode = "Pincode is required!";
      validateErr = true;
      setValidateError(true);
    }
    */
    if (values.pincode && values.pincode.length != 6) {
      errors.pincode = "Pincode should be 6 digits!";
      validateErr = true;
      setValidateError(true);
    }
    /*
    if (!values.phone) {
      errors.phone = "Phone number is required!";
      validateErr = true;
      //setValidateError(true);
    }
    if (!values.contact_person_number) {
      errors.contact_person_number = "Contact Number is required!";
      validateErr = true;
      setValidateError(true);
    }
    */

    if (values.phone && values.phone.length != 10) {
      errors.phone = "Phone number should be 10 digits!";
      validateErr = true;
      //setValidateError(true);
    }
    

    

    if (values.contact_person_number && values.contact_person_number.length != 10) {
      errors.contact_person_number = "Contact Number should be 10 digits!";
      validateErr = true;
      setValidateError(true);
    }
  
 
    //console.log("BEFORE RETURN validate errors ...", validateError);
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit called ...");
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ",
      validateErr
    );
    if (validateErr === false) {
      console.log("FORM VALUES at handleSubmit: ", formValues);
      //console.log("LINE ITEMS : ", items);
      callUpdateApi();
     
    }
  };
    const handleSelect = (e, data) => {
    console.log("SELECTED Dropdown value : ", e);
    console.log("SELECTED element id : ", data);
    var currentId = data.name.substring(data.name.lastIndexOf("-") + 1);
    console.log("Current id : ", currentId);
  };
  const handleSearch = (e, dat) => {
    console.log("Handled search method called ...", e);

    //var stateIdInput = 1;
   // var stateNameInput = "TAMIL NADU";
    //getDistrictList(stateIdInput, stateNameInput);
  };

  
  const handleStateSelect = async(e) => {
    console.log("handleStateSelect called ...", e);
    setStateIdSelect(e);

    // var stateIdInput = e.value;
    //var stateNameInput = e.label;
    var [retStateDist, distArrRet] = await getDistrictList(e.value, e.label);
    if ( retStateDist ){
      setDistrictIdSelect(distArrRet[0]);
      setOptDist(distArrRet);
    }
  };
  
  const [stateIdSelect, setStateIdSelect] = useState({});
  const [optionsState, setOptionsState] = useState([]);
  const [districtIdSelect, setDistrictIdSelect] = useState({});
  const [optDist, setOptDist] = useState([]);
  const handleDistrictSelect = (e, data) => {
    console.log("handleDistrictSelect called ...", e);
    setDistrictIdSelect(e);
  };
  const handleEdit = async (e) => {
    console.log("handle edit  clicked : ", e);
    setIsReadOnly(false);
  };
  const handleBack = async (e) => {
    console.log("handle back clicked : ", e);
    navigate(-1);
  };

  const handleReset = async (e) => {
    console.log("handle reset clicked : ", e);
    navigate(0);
  };
  
  const handleCloseDialog = () => {
    setShowDialog(false);
    navigate("/ListCustomers");
  };
  
 


  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::addUpdateCustomer called ##########################"
      );

      if (state === null) {
        navigate("/listCustomers");
      } else {
        console.log("PASSED STATE VALUE IN Employee Add/Update page : ", state);
         console.log("Inside else for view or edit ...");
            //setFormValues(state.passed_element);
        if (state.action === "add") {
          setIsReadOnly(true);
        }
        else {
          setFormValues(state.passed_element);
        }
        

        ( async() => {
          //await getDistrictListWrapper();
          var stateIdTmp = 1;
          if ( state.action === 'view' || state.action === 'edit') {
            stateIdTmp = state.passed_element.state_id;
          }
          var [retStateDist, distArrRet] = await getDistrictList(stateIdTmp);
          if ( retStateDist) {
            setOptDist(distArrRet);
            if ( state.action === 'add') {
            //setDistrictIdSelect(distArrRet[0]);
            var findDist = distArrRet.find(element => element.label === appGlobal.DEFAULT_DISTRICT);
              if (findDist !== undefined) {
                console.log("++++++++++++++++++------------------- FOUND DISTRICT : ", findDist);
                setDistrictIdSelect(findDist);
              }
            }
            else {
              var findDist = distArrRet.find(element => element.value === state.passed_element.district_id);
              if ( findDist !== undefined ) {
                console.log("++++++++++++++++++------------------- FOUND DISTRICT : ", findDist);
                setDistrictIdSelect({value: state.passed_element.district_id, label: findDist.label});
              }
            }
          }

          var country_id = appGlobal.COUNTRY_ID;
          var [retStateStList, stateArrRet] = await getStateList(country_id);
          if ( retStateStList ) {
            setOptionsState(stateArrRet);
            if ( state.action === 'add' ) {
              setStateIdSelect(stateArrRet[0]);
            }
            else {
              var findState = stateArrRet.find(element => element.value === state.passed_element.state_id);
              if ( findState ) {
                console.log("STATE FOUND OBJECT : ", findState);
                setStateIdSelect({value: state.passed_element.state_id, label: findState.label});
              }
          }
          }  
          setToRender(true);  
        })();
         
       
        console.log("newObj :", formValues);
        if (state.action === "add") {
          setIsReadOnly(false);
        }
      }
      
      initialized.current = true;
    }
  }, []);

 
  const callUpdateApi = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var apiToCall = "";
      if (state.action === "add") {
        apiToCall = "/crm/addCustomer";
      } else if (state.action === "view") {
        apiToCall = "/crm/updateCustomer";
      } else {
        apiToCall = "/crm/addCustomer";
      }

      var reqParam = {
        id: formValues["id"],
        name: formValues["name"] || "",
        display_name: formValues["name"] || "",
        contact_person: formValues["contact_person"],
        contact_person_number: formValues["contact_person_number"],
        gst_no: formValues["gst_no"] || "",
        phone: formValues["phone"] || "",
        contact_number_primary: formValues["contact_number_primary"] || "",
        Contact_number_secondary: formValues["contact_number_secondary"] || "",
        email: formValues["email"] || "",
        website: formValues["website"] || "",
        address_line1: formValues["address_line1"] || "",
        address_line2: formValues["address_line2"] || "",
        district_id: districtIdSelect.value,
        pincode: formValues["pincode"] || "",
        opening_balance: formValues["opening_balance"] || 0.00
      };
      var res = await axios.post(client.domain + apiToCall, reqParam, { headers }, { withCredentials: false });
     
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        setShowMsg(res.data.detail.data.message);
        setShowMsgTitle("Success");
        setShowDialog(true);
        setSubmitAction(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          
        }
      }
      setSubmitAction(false);
    }
  };
  const handlePostSaveOk = () => {
    console.log("user confirmed to customer added ...");
    setShowDialog(false);
    navigate("/listCustomers");
  };
  const handleSave = async (e) => {
    e.preventDefault();
    console.log("Handle Click save called : ", e);
    console.log("Formvalues : ", formValues);

    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    if (validateErr === false) {
      setSubmitAction(true);
      callUpdateApi();
     
    }
  };

  console.log("formvalues FULL : ", formValues);

 

  return (
      <Container>
        <SidebarMain />
        { toRender ?
        (
        <Form>
          <Row>
            <Col xs={4} md={4} sm={4} lg={4}>  
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>Customer</span>
            { (state.action === 'view' || state.action === 'edit') &&
              <>
              &nbsp;&nbsp;
             
            <Button variant={state.passed_element.is_active ? "outline-success" : "outline-danger"} size="sm"><span style={{cursor: "default", fontWeight: "500"}}>{state.passed_element.status}</span></Button>
            </>
            }
            
            </Col>
            
            <Col
              xs={8}
              md={8}
              sm={8}
              lg={8}
              className="float-end justify-content-end"
            >
              <div className="d-flex justify-content-end">
                <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                  <BsArrowLeftCircle
                    key={`back-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                   
                    name={`back-icon-patient-name`}
                    id={`edit-icon-patient-id`}
                  />
                </Button>
                {(state.action === "edit" || state.action === "view") && (
                  <>
                &nbsp;&nbsp;
                <Button variant="primary" className="btn btn-primary btn-md"  onClick={(e) => handleEdit(e)}>
                  <BsPencilSquare
                    key={`edit-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                  
                    name={`edit-icon-paBiSolidEditAlttient-name`}
                    id={`edit-icon-doctor-id`}
                  />
                </Button>
                </>
                )}
              </div>
            </Col>
          </Row>

          <div className="ui-divider"></div>
          <div className="ui-form">
            <hr align="center" />
           

            <Row>
              <Col xs={5} md={5} sm={5} lg={5}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="name-id"
                    name="name"
                    placeholder="Customer Name"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["name"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.name}</p>
              </Col>
             
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    GST / PAN Number
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="gst-no-id"
                    name="gst_no"
                    placeholder="GST / PAN Number"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["gst_no"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.gst_no}</p>
              </Col>
              <Col xs={2} md={2} sm={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="text"
                    id="phone"
                    name="phone"
                    //className="mobNum"
                    placeholder="Phone Number"
                    onChange={handleOnChange}
                    readOnly={isReadOnly}
                    maxLength={10}
                    value={formValues["phone"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.phone}</p>
              </Col>
              <Col xs={2} md={2} sm={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>Opening Balance</Form.Label>
                  <Form.Control
                    type="text"
                    id="opening_balance"
                    name="opening_balance"
                    placeholder="Opening Balance"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["opening_balance"]}
                    style={{
                      border: !isReadOnly && "1px solid gray", textAlign: 'right'
                    }}
                  />
                </Form.Group>
              </Col>
              <p></p>
            </Row>
            <h5>Contact Details</h5>
            <hr align="center" />

            <Row>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">
                    Street / Location
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="address-1-id"
                    name="address_line1"
                    placeholder="Street / Location"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["address_line1"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.address1}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field">
                    City / Town
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="address-2-id"
                    name="address_line2"
                    placeholder="City / Town"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["address_line2"]}
                    style={{
                      border: !isReadOnly && "1px solid gray"
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.address_line2}</p>
              </Col>
              <Col xs={2} sm={2} md={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>District</Form.Label>
                  <Select
                    id="district"
                    name="district"
                    value={{
                      value: districtIdSelect.value,
                      label: districtIdSelect.label,
                    }}
                    options={optDist}
                    defaultValue={{
                      value: districtIdSelect.value,
                      label: districtIdSelect.label,
                    }}
                    onChange={handleDistrictSelect}
                    isDisabled={isReadOnly}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.district_id}</p>
              </Col>

              <Col xs={2} sm={2} md={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>State</Form.Label>
                  <Select
                    id="state"
                    name="state"
                    value={{
                      value: stateIdSelect.value,
                      label: stateIdSelect.label,
                    }}
                    defaultValue={{
                      value: stateIdSelect.value,
                      label: stateIdSelect,
                    }}
                    options={optionsState}
                    onChange={handleStateSelect}
                    isDisabled={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.state_id}</p>
              </Col>

              <Col xs={2} sm={2} md={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>Pincode</Form.Label>
                  <Form.Control
                    type="text"
                    id="pincode-id"
                    name="pincode"
                    placeholder="Pincode"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    maxLength={6}
                    value={formValues["pincode"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>

                <p className="error-msg">{formErrors.pincode}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Contact Person
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="contact_person"
                    name="contact_person"
                    placeholder="Contact Person"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["contact_person"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.contact_person}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Contact Number
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="cont-person-mobile-id"
                    name="contact_person_number"
                    className="mobNum"
                    placeholder=" Contact Number"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["contact_person_number"]}
                    maxLength={10}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.contact_person_number}</p>
              </Col>
             
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    id="email-id"
                    name="email"
                    placeholder="Email"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["email"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.email}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Website</Form.Label>
                  <Form.Control
                    type="text"
                    id="Web-id"
                    name="website"
                    placeholder="Website"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["website"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.website}</p>
              </Col>

              

            </Row>
            {!isReadOnly && (
              <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ disply: "flex", justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleReset(e)}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
              
            
                    <Button
                      type="submit"
                      id="save"
                      onClick={(e) => handleSave(e)}
                      disabled={submitAction ? true: false}
                    >
                     {submitAction && 
                      <>
                      <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                     <span className="visually-hidden">Loading...</span>
                     </>
                    }
                      
                     Save
                    </Button>
                  </div>
                </Col>
                
              </Row>
            )}
            
           
            <Modal
              show={showDialog}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>{showMsgTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{showMsg}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handlePostSaveOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </Form>
        )
        :
        <div className="container-spinner">
        <div className="vertical-center-spinner">
         {/*<Button variant="primary" disabled>*/}
         <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
            <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
                <span className="visually-hidden">Loading...</span>
              </Spinner>
   
         {/*</Button>*/}
         </div>
         </div>
      }
      </Container>
  );
};

export default AddUpdateCustomer;
