import React, { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';

import { client } from '../config/Config';

import StockDocument from "./StockDocument";
import {getCompanyInfo, formatDateIndian } from "./utils";

import logo_left from '../logo_left.png';
import logo_right from '../logo_right.png';

const borderColor = '#55585a';
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 9,
    paddingTop: 30,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    //width: '100%',
    //height: '33%',
    width: '100%',
    height: '100%',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  logo_image2: {
    width: '15%',
    height: '98px',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  business_content: {
    width: '60%',
    height: '98px',
    //paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container2: {
    //paddingBottom:0,
    flexDirection: 'row',
    //borderBottomColor: '#bff0fd',
    //backgroundColor: '#bff0fd',
    //borderBottomWidth: 1,
    //alignItems: 'center',
    //height: 12,
    //textAlign: 'center',
    //fontStyle: 'bold',
    //flexGrow: 1,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 1,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#a8a8a8',

  },
  container: {
    flexDirection: 'row',
    borderBottomColor: '#a8a8a8',
    backgroundColor: '#a8a8a8',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    //flexGrow: 1,
    //flexWrap: 'wrap',
    //marginTop: 1,
    //marginBottom: 10,
    //borderWidth: 1,
    //borderWidth: 1,
    //marginBottom: 5,
  },
  row: {
    flexDirection: 'row',
    //borderBottomColor: '#a8a8a8',
    //borderBottmWidth: 1,
    alignItems: 'center',
    //height: 24,
    fontStyle: 'bold',
  },
  
  snoReceipt: {
    width: '5%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left' 
  },
  noReceipt: {
    width: '7%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'center' 
  },
  dateReceipt: {
    width: '8%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'center' 
  },
  customerReceipt: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'center'  
  },
  amountReceipt: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right' 
  },
  received_modeReceipt: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right'  
  },
  ref_numberReceipt: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right'  
  },
  received_byReceipt: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right'  
  },
  towardsReceipt: {
    width: '15%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    paddingLeft: "20px"  
  },
  addl_notesReceipt: {
    width: '15%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left', 
    paddingLeft: "20px"  
  },
  snoReceiptData: {
    width: '5%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    paddingRight:30,
    textAlign: 'right' 
  },
  noReceiptData: {
    width: '7%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right' 
  },
  dateReceiptData: {
    width: '8%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    paddingLeft: 10
  
  },
  customerReceiptData: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    paddingLeft: 20  
  },
  amountReceiptData: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right', 
    //paddingRight:"70px"
  },
  received_modeReceiptData: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    //paddingLeft: "90px"
    paddingLeft: 10    
  },
  ref_numberReceiptData: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    //paddingLeft: "90px"  
  },
  received_byReceiptData: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    paddingLeft: 30  
    //paddingLeft: "130px"
  },
  towardsReceiptData: {
    width: '15%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    paddingLeft: "20px"  
  },
  addl_notesReceiptData: {
    width: '15%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    //paddingLeft: "50px" 
    paddingLeft: "20px"  
  },


});

const PrintFullReceipt = (props) => {
  let receiptArr = [
    {
    sno: "1",
    no: "RCPT001",
    date: "01-01-2024",
    customer: "RRR Constructions",
    amount: "5000.00",
    received_mode: "Cash",
    received_by: "Joel"  
    },
    {
      sno: "2",
      no: "RCPT002",
      date: "04-02-2024",
      customer: "SMS Readymix",
      amount: "8000.00",
      received_mode: "Cheque",
      received_by: "Manoj"  
      },
      {
        sno: "3",
        no: "RCPT003",
        date: "08-04-2024",
        customer: "ABC Constructions",
        amount: "1000.00",
        received_mode: "Net Bank",
        received_by: "Mariya"  
        },
        {
          sno: "4",
          no: "RCPT004",
          date: "07-08-2024",
          customer: "DCR Readymix",
          amount: "9000.00",
          received_mode: "Cash",
          received_by: "Bala"  
          },
          {
            sno: "5",
            no: "RCPT005",
            date: "02-05-2024",
            customer: "MMC Constructions",
            amount: "6000.00",
            received_mode: "Cheque",
            received_by: "Farina"  
            },

  ]

 
 

  const [reportData, setReportData] = useState({});
  const [toRender, setToRender] = useState(false);
  const [receiptList, setReceiptList] = useState([]);
  const [companyInfo, setCompanyInfo] = useState({});
  var sum_amount = useRef(0.00);

  var customer_id_ref = useRef(0);
  var start_date_ref = useRef("");
  var end_date_ref = useRef("");

  
  /*
  console.log("CALLED CURRENT PAGE : ", window.location.href);

    console.log("CALLED window locaion : ", window.location);
    let params = (new URL(window.location)).searchParams;
    console.log("COMPLETE PARAMS : ", params);
    let orderIdParam = params.get("order_id");
    console.log("ORDER ID FROM PARAM : ", orderIdParam);

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.indexOf('?') + 1);
    var urlTmp = urlStr.substring(0, urlStr.indexOf("?"));

    console.log("URL : ", urlTmp);
    console.log("URL : ", paramsTmp);

    let searchParams = new URLSearchParams(paramsTmp);
    var customerIdTemp2 = searchParams.get("customer_id");
    console.log("CUSTOMED ID : ", customerIdTemp2);
    var productIdTemp2 = searchParams.get("product_id");
    console.log("PRODUCT ID : ", productIdTemp2);
    var startDateStr = searchParams.get("start_date");
    console.log("START DATE : ", startDateStr);
    var endDateStr = searchParams.get("end_date");
    console.log("END DATE : ", endDateStr);

    console.log("Extracted CUSTOMER ID  : ", customerIdTemp2);
    console.log("Extracted PRODUCT ID : ", productIdTemp2);
   */

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  const formateTime = (currTime) => {
    var h = (currTime.getHours() < 10 ? "0" : "") + currTime.getHours();
    var m = (currTime.getMinutes() < 10 ? "0" : "") + currTime.getMinutes();
    var hrMin = h + ":" + m;
    return hrMin;
  };

  const formatPrintDate = (currTime) => {
    var dateComponent = [
      padTo2Digits(currTime.getDate()),
      padTo2Digits(currTime.getMonth() + 1),
      currTime.getFullYear(),
    ].join("-");

    var timeComponent = [
      padTo2Digits(currTime.getHours() % 12 || 12 ),
      padTo2Digits(currTime.getMinutes())
    ].join(":");

    const ampm = currTime.getHours() >= 12 ? 'PM' : 'AM';

    return dateComponent + " " + timeComponent + " " + ampm;
  }
  /*
  const getAllSummaryList = async () => {
    var retState = false;
    var deliverySummaryList = [];
    try {
      
      var res = await axios.get(client.domain + `/getDeliverySummaryList?customer_id=${customerIdTemp2}&start_date=${startDateStr}&end_date=${endDateStr}&product_id=${productIdTemp2}`, { withCredentials: false });
      console.log("Response from server getAllSummaryList : ", res.data.detail);
      if (res.data.detail.status === 'SUCCESS') {
        console.log("Response message from server SummaryList : ", res.data.detail.data);
        retState = true;
        if (res.data.detail.data.length > 0) {
          deliverySummaryList = res.data.detail.data; 
        }
        
      }
    }
    catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === 'ERR_NETWORK') {
        console.log("Not able to reach target server please try after sometime");
      }
      else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === 'FAILED') {
          console.log('Request failed due to ', error.response.data.detail.res_data);
         
        }
      }
    }
    return [retState, deliverySummaryList];
  }
  
    */

  const getReceiptList = async(customer_id, start_date, end_date) => {
    console.log("getReceiptList method called ...");
    console.log("start date   : ", start_date);
    console.log("end date     : ", end_date);
    console.log("customer id  : ", customer_id);
    var retState = false;
    var retReceiptList = [];
    try {
      var aIVal = localStorage.getItem("_aI");
      console.log("Authorization token : ", aIVal);

      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}

      console.log("Headers : ", headers);

      
      var res = await axios.get(client.domain + `/getAllReceiptsByFilter?customer_id=${customer_id}&start_date=${start_date}&end_date=${end_date}`, {headers}, {
        withCredentials: false,
      });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getReceiptList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getReceiptList : ", res.data.detail.data);
       if (res.data.detail.data.length > 0) {
          setReceiptList(res.data.detail.data);
          //setReceiptListFull(res.data.detail.data);
          retReceiptList = res.data.detail.data;
        }
        retState = true;
        setToRender(true);
      }
      
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
    setToRender(true);
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ",error.response.data.detail.res_data);
        }
      }
    }
    setToRender(true);

    return [retState, retReceiptList];
  };
  
  useEffect(() => {
    console.log("useEffect method called ...");
    (async () => {
      //var [retState, deliverySummaryList] = await getAllSummaryList();
      /*
      if (retState) {
        setSummaryList(deliverySummaryList);
        //setDcListFetched(true);
        setToRender(true); 
      }
      */
      
      var [retState, companyInfoTmp] = await getCompanyInfo();
      if (retState) {
        setCompanyInfo(companyInfoTmp);
      }

      console.log("CALLED CURRENT PAGE : ", window.location.href);

    console.log("CALLED window locaion : ", window.location);
    let params = (new URL(window.location)).searchParams;
    console.log("COMPLETE PARAMS : ", params);
    let receiptIdParam = params.get("customer_id");
    console.log("RECEIPT ID FROM PARAM : ", receiptIdParam);

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.indexOf('?') + 1);
    var urlTmp = urlStr.substring(0, urlStr.indexOf("?"));

    console.log("URL : ", urlTmp);
    console.log("URL : ", paramsTmp);

    let searchParams = new URLSearchParams(paramsTmp);
    var customer_id = searchParams.get("customer_id");
    console.log("^^^^^^^^^^^^^^^^^^^ CUSTOMER ID : ", customer_id);
    customer_id_ref.current = customer_id; 

    var start_date = searchParams.get("start_date");
    console.log("^^^^^^^^^^^^^^^^^^^ START DATE : ", start_date);
    start_date_ref.current = start_date;

    var end_date = searchParams.get("end_date");
    console.log("^^^^^^^^^^^^^^^^^^^ END DATE : ", end_date);
    end_date_ref.current = end_date;


    var [retState, retReceiptList] = await getReceiptList(customer_id, start_date, end_date);

    sum_amount.current = 0.00;
    retReceiptList.map((receipt) => {
      console.log("EACH RECEIPT : ", receipt);
      sum_amount.current =  sum_amount.current + receipt.amount; 
    })
    console.log("SUM OF ALL RECEIPTS : ", sum_amount.current);

    setToRender(true);
    })();
    
  }, [])

  return (
    <PDFViewer style={styles.viewer}>
      {toRender === true &&
        <Document>
          <Page size="A4" orientation="landscape" style={styles.page}>
            <View>
              <View style={{ flexDirection: 'row', borderBottomWidth: 1, borderBottomColor: '#a7a5a5' }}>
                <View style={{ textAlign: 'right', width: '25%' }}>
                  <Image style={{ marginHorizontal: '10%', width: 'auto', height: 'auto', height: "64px", width: "64px" }} src={logo_left} />
                </View>
                <View style={{ width: "50%", alignItems: "center", /*paddingRight: "30px",*/ /*borderBottomWidth: 1,*/ borderBottomColor: '#a7a5a5'/*,marginTop: 1, marginBottom: 10, borderWidth: 1*/ }} key="address-key">
                  <Text style={{ fontSize: "20px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>{companyInfo.name}</Text>
                  <Text>{companyInfo.address_line1}</Text>
                  <Text>{companyInfo.address_line2}-{companyInfo.pincode}</Text>
                  <Text>{companyInfo.district_name}, {companyInfo.state_name}</Text>
                  <Text style={{ fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica'}}><Text style={{ fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>Mobile:</Text> {companyInfo.phone}</Text>
                </View>
                <View style={{ textAlign: 'right', width: '25%',paddingLeft: '140px', alignItems: 'right'}}>
                  <Image style={{ /*marginHorizontal: '10%',*/ width: 'auto', height: 'auto', height: "64px", width: "64px"}} src={logo_right} />
                </View>
              </View>
            </View>
           
            
            <View style={{ width: "100%", alignItems: "center",/* paddingLeft: "20px",*/ textAlign: 'center', borderBottomWidth: 1, borderBottomColor: '#a7a5a5', paddingTop: "5px" }}>
              <Text style={{ fontSize: "12px", textAlign: 'center', alignContent: 'center', fontFamily: 'Helvetica-Bold', color: '#646363' }}>Receipt Summary  ({formatDateIndian(new Date(start_date_ref.current))} To {formatDateIndian(new Date(end_date_ref.current))})</Text>
            </View>
            <hr style={{paddingBottom: "10px"}}/>

            <View style={styles.tableContainer}>
            
            <View style={styles.container}>
                <Text style={styles.snoReceipt}>S.No</Text>
                <Text style={styles.noReceipt}>No</Text>
                <Text style={styles.dateReceipt}>Date</Text>
                <Text style={styles.customerReceipt}>Customer</Text>
                <Text style={styles.amountReceipt}>Amount</Text>
                <Text style={styles.received_modeReceipt}>Received Mode</Text>
                <Text style={styles.ref_numberReceipt}>Reference No</Text>
                <Text style={styles.received_byReceipt}>Received By</Text>
                <Text style={styles.towardsReceipt}>Notes</Text>
                <Text style={styles.addl_notesReceipt}>Additional Notes</Text>
              </View>

              <View style={{flexGrow: 1}}>
              {receiptList.map((receipt, idx) => (
              <View style={styles.row} key="items-${idx}">
                    <Text style={styles.snoReceiptData}>{idx + 1}</Text>
                    <Text style={styles.noReceiptData}>{receipt.receipt_number}</Text>
                    <Text style={styles.dateReceiptData}>{formatDateIndian(new Date(receipt.receipt_date))}</Text>
                    <Text style={styles.customerReceiptData}>{receipt.customer_name}</Text>
                    <Text style={styles.amountReceiptData}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(receipt.amount).toFixed(2)))}</Text>
                    <Text style={styles.received_modeReceiptData}>{receipt.received_mode}</Text>
                    <Text style={styles.ref_numberReceiptData}>{receipt.ref_number}</Text>
                    <Text style={styles.received_byReceiptData}>{receipt.received_by_name}</Text>
                    <Text style={styles.towardsReceiptData}>{receipt.towards}</Text>
                    <Text style={styles.addl_notesReceiptData}>{receipt.addl_notes}</Text>
                    
                    

              </View>
               ))}
              </View>
            
              </View>
              <View style={{...styles.row,borderBottomWidth: 1, borderBottomColor: '#a7a5a5'}} key="items-${idx}">
                    <Text style={styles.snoReceiptData}></Text>
                    <Text style={styles.noReceiptData}></Text>
                    <Text style={{...styles.dateReceiptData,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Total:</Text>
                    <Text style={styles.customerReceiptData}></Text>
                    <Text style={{...styles.amountReceiptData,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{sum_amount.current.toFixed(2)}</Text>
                    <Text style={styles.ref_numberReceiptData}></Text>
                    <Text style={styles.received_modeReceiptData}></Text>
                    <Text style={styles.received_byReceiptData}></Text>
                    <Text style={styles.towardsReceiptData}></Text>
                    <Text style={styles.addl_notesReceiptData}></Text>
                    
                    

              </View>
              {/*
              <View style={{ width: "50%", alignItems: "right", textAlign: 'right', borderBottomWidth: 1, borderBottomColor: '#a7a5a5', paddingTop: "5px" }}>
              <Text style={{ fontSize: "12px", textAlign: 'right', alignContent: 'right', fontFamily: 'Helvetica-Bold', color: '#646363' }}>OUTSTANDING STATEMENT</Text>
            </View>
            */}
           
            {/*
            <View style={{width: '20%', alignContent: 'right'}}>
            <Text style={{ fontSize: "12px", fontFamily: 'Helvetica-Bold', color: '#646363' }}>Outstanding Statement</Text>
            
            <View style={{flexDirection: 'row', alignContent: 'right', alignItems: 'right', justifyContent: 'front-end !important'}}><Text style={{ fontSize: "12px",fontWeight: 'bold', fontFamily: 'Helvetica-Bold',alignContent: 'right', alignItems: 'right',}}>Previous Balance: </Text> <Text style={{fontSize: "12px", textAlign: 'right !important', alignContent: 'right !important', fontFamily: 'Helvetica'}}>1000.00</Text></View>
            <View style={{flexDirection: 'row'}}><Text style={{ fontSize: "12px",fontWeight: 'bold', fontFamily: 'Helvetica-Bold',alignContent: 'right', alignItems: 'right',}}>Previous Balance: </Text> <Text style={{fontSize: "12px", textAlign: 'right !important', alignContent: 'right !important', fontFamily: 'Helvetica'}}>10000.00</Text></View>
            
            <Text style={{ fontSize: "10px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}> Total Received <Text style={{fontSize: "12px", textAlign: 'right', alignContent: 'right', fontFamily: 'Helvetica'}}>: 1000.00</Text></Text>
            <View style={{flexDirection: 'row'}}><Text style={{ fontSize: "10px", paddingBottom: "5px",fontWeight: 'bold', fontFamily: 'Helvetica-Bold',paddingLeft: "7px",}}>Current Balance</Text> <Text style={{fontSize: "12px", textAlign: 'right', alignContent: 'right', fontFamily: 'Helvetica'}}>: 3000.00</Text></View>
            <View style={{flexDirection: 'row'}}><Text style={{ fontSize: "10px", alignContent: 'left', paddingBottom: "5px",fontWeight: 'bold', fontFamily: 'Helvetica-Bold',paddingLeft: "21px"}}>Current Balance</Text> <Text style={{fontSize: "12px", fontFamily: 'Helvetica'}}>: 5000.00</Text></View>
            <View style={{flexDirection: 'row'}}><Text style={{ fontSize: "10px", alignContent: 'left', paddingBottom: "5px",fontWeight: 'bold', fontFamily: 'Helvetica-Bold',paddingLeft: "21px"}}>Total Balance</Text> <Text style={{fontSize: "12px", fontFamily: 'Helvetica', alignContent: 'right !important', alignItems: 'right !important', textAlign: 'right !important', alignSelf: 'flex-end !important'}}>: 60000.00</Text></View>
          
            </View>
            */}

            
           
            <hr style={{paddingBottom: "40px"}}/>
             

            <View style={{flexDirection: 'row'}}>
            
            <Text style={{textAlign: 'left', flex: 1}} render={({ pageNumber, totalPages }) => (
                `Printed On: ${formatPrintDate(new Date())}`
            )} fixed />
             
             <Text style={{  textAlign: 'center', alignContent: 'center' }}>..... End of Report .....</Text>
           
              <Text style={{flex: 1, textAlign: 'right', justifyContent: 'flex-end', alignSelf: 'flex-end'}} render={({ pageNumber, totalPages }) => (
                `Page No : ${pageNumber} / ${totalPages}`
              )} fixed />
              </View>
              

          </Page>
        </Document>
      }
    </PDFViewer>
  );
}

export default PrintFullReceipt;