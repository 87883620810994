import React, { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';

import { client } from '../config/Config';

import StockDocument from "./StockDocument";
import {getCompanyInfo, formatDateIndian } from "./utils";

import logo_left from '../logo_left.png';
import logo_right from '../logo_right.png';

const borderColor = '#55585a';
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 9,
    paddingTop: 30,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    //width: '100%',
    //height: '33%',
    width: '100%',
    height: '100%',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  logo_image2: {
    width: '15%',
    height: '98px',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  business_content: {
    width: '60%',
    height: '98px',
    //paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container2: {
    //paddingBottom:0,
    flexDirection: 'row',
    //borderBottomColor: '#bff0fd',
    //backgroundColor: '#bff0fd',
    //borderBottomWidth: 1,
    //alignItems: 'center',
    //height: 12,
    //textAlign: 'center',
    //fontStyle: 'bold',
    //flexGrow: 1,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 1,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#a8a8a8',

  },
  container: {
    flexDirection: 'row',
    borderBottomColor: '#a8a8a8',
    backgroundColor: '#a8a8a8',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    //flexGrow: 1,
    //flexWrap: 'wrap',
    //marginTop: 1,
    //marginBottom: 10,
    //borderWidth: 1,
    //borderWidth: 1,
    //marginBottom: 5,
  },
  sno: {
    width: '5%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left'
   
  },
  delivery_date: {
    width: '8%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'center',
    //paddingRight:2
  },
  
  
  address_line1: {
    width: '16%',
    fontSize: "10px",
    borderRightColor: borderColor,
    textAlign: 'left',
    //borderRightWidth: 1,
  },
  product_name: {
    width: '8%',
    fontSize: "10px",
    borderRightColor: borderColor,
    textAlign: 'right',
    //borderRightWidth: 1,
  },
  total_quantity: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    textAlign: 'right',
    //borderRightWidth: 1,
    
  },
  unit_price: {
    width: '13%',
    fontSize: "10px",
    borderRightColor: borderColor,
    textAlign: 'right',
    //borderRightWidth: 1,
  },
  product_price: {
    width: '10%',
    borderRightColor: borderColor,
    fontSize: "10px",
    textAlign: 'right',
    //borderRightWidth: 1,
  },
  extra_charges: {
    width: '15%',
    borderRightColor: borderColor,
    fontSize: "10px",
    textAlign: 'right',

    //borderRightWidth: 1,
  },
  total_amount: {
    width: '15%',
    borderRightColor: borderColor,
    fontSize: "10px",
    textAlign: 'right',
    //borderRightWidth: 1,
  },
  row: {
    flexDirection: 'row',
    //borderBottomColor: '#a8a8a8',
    //borderBottmWidth: 1,
    alignItems: 'center',
    //height: 24,
    fontStyle: 'bold',
  },
  snoData: {
    width: '5%',   
    textAlign: 'right',
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    fontSize: "10px",
    paddingRight: 28,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  delivery_dateData: {
    width: '8%',
    fontSize: "10px",
    //textAlign: 'left',
    textAlign: 'left',
    //alignContent: 'justify',
    //textAlign: 'justify',
    //textJustify: 'inter-word',
    //display:'flex',
    //flexWrap: 'wrap',
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    //paddingLeft: 8,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  
 
  address_line1Data: {
    width: '16%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    paddingLeft: 1,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  product_nameData: {
    width: '8%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    paddingLeft: 38,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  total_quantityData: {
    width: '10%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 1,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  unit_priceData: {
    width: '13%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 1,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  product_priceData: {
    width: '10%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 1,
     //paddingRight: 8,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
   extra_chargesData: {
    width: '15%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 1,
    //paddingRight: 8,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  total_amountData: {
    width: '15%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 1,
    //paddingRight: 8,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  snoReceipt: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left' 
  },
  noReceipt: {
    width: '20%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left' 
  },
  dateReceipt: {
    width: '20%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left' 
  },
  descriptionReceipt: {
    width: '25%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right'  
  },
  amountReceipt: {
    width: '25%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right' 
  },
  snoReceiptData: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 30 
  },
  noReceiptData: {
    width: '20%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 35 
  },
  dateReceiptData: {
    width: '20%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    //paddingRight: 30
  },
  descriptionReceiptData: {
    width: '25%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left'  
  },
  amountReceiptData: {
    width: '25%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right' 
  }
});

const PrintCustOsStmt = (props) => {

 let statementArr = [
  {
    sno: "1",
    delivery_date: "12-10-2022",
    address_line1: "Madhurai",
    product_name: "M5",
    total_quantity: "12000.00",
    unit_price: "100.00",
    product_price: "500.00",
    extra_charges: "8000.00",
    total_amount: "1900.00"

  },
  {
    sno: "2",
    delivery_date: "09-11-2018",
    address_line1: "Chennai",
    product_name: "M7",
    total_quantity: "6000.00",
    unit_price: "700.00",
    product_price: "80.00",
    extra_charges: "3000.00",
    total_amount: "20000.00"

  },
  
  {
    sno: "3",
    delivery_date: "05-08-2022",
    address_line1: "Erode",
    product_name: "M3",
    total_quantity: "15000.00",
    unit_price: "900.00",
    product_price: "800.00",
    extra_charges: "600.00",
    total_amount: "3000.00"

  },
  {
    sno: "4",
    delivery_date: "09-09-2022",
    address_line1: "Dindigul",
    product_name: "M9",
    total_quantity: "17000.00",
    unit_price: "500.00",
    product_price: "200.00",
    extra_charges: "400.00",
    total_amount: "10000.00"

  },
  

 ]

 let receiptArr = [
  {
    snoReceipt: "1",
    noReceipt: "KMC001",
    dateReceipt: "05-08-2023",
    descriptionReceipt: "M3",
    amountReceipt: "5000.00"
  },
  {
    snoReceipt: "2",
    noReceipt: "KMC002",
    dateReceipt: "08-12-2023",
    descriptionReceipt: "M8",
    amountReceipt: "9000.00"
  },
  {
    snoReceipt: "3",
    noReceipt: "KMC003",
    dateReceipt: "07-08-2023",
    descriptionReceipt: "M3",
    amountReceipt: "3000.00"
  },
 ]

  const [reportData, setReportData] = useState({});
  const [toRender, setToRender] = useState(false);
  const [statementList, setStatementList] = useState([]);
  const [receiptList, setReceiptList] = useState([]);
  const [companyInfo, setCompanyInfo] = useState({});

  var total_quantity_sum = useRef(0.00);
  var total_product_price_sum = useRef(0.00);
  var total_extra_charges_sum = useRef(0.00);
  var total_amount_sum = useRef(0.00);
  var amount_sum = useRef(0.00);


 const [osStmtInfo, setOsStmtInfo] = useState({});

   
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  const formateTime = (currTime) => {
    var h = (currTime.getHours() < 10 ? "0" : "") + currTime.getHours();
    var m = (currTime.getMinutes() < 10 ? "0" : "") + currTime.getMinutes();
    var hrMin = h + ":" + m;
    return hrMin;
  };

  const formatPrintDate = (currTime) => {
    var dateComponent = [
      padTo2Digits(currTime.getDate()),
      padTo2Digits(currTime.getMonth() + 1),
      currTime.getFullYear(),
    ].join("-");

    var timeComponent = [
      padTo2Digits(currTime.getHours() % 12 || 12 ),
      padTo2Digits(currTime.getMinutes())
    ].join(":");

    const ampm = currTime.getHours() >= 12 ? 'PM' : 'AM';

    return dateComponent + " " + timeComponent + " " + ampm;
  }
  /*
  const getAllSummaryList = async () => {
    var retState = false;
    var deliverySummaryList = [];
    try {
      
      var res = await axios.get(client.domain + `/getDeliverySummaryList?customer_id=${customerIdTemp2}&start_date=${startDateStr}&end_date=${endDateStr}&product_id=${productIdTemp2}`, { withCredentials: false });
      console.log("Response from server getAllSummaryList : ", res.data.detail);
      if (res.data.detail.status === 'SUCCESS') {
        console.log("Response message from server SummaryList : ", res.data.detail.data);
        retState = true;
        if (res.data.detail.data.length > 0) {
          deliverySummaryList = res.data.detail.data; 
        }
        
      }
    }
    catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === 'ERR_NETWORK') {
        console.log("Not able to reach target server please try after sometime");
      }
      else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === 'FAILED') {
          console.log('Request failed due to ', error.response.data.detail.res_data);
         
        }
      }
    }
    return [retState, deliverySummaryList];
  }
  
    */

  const getDeliverySummaryListForEdit = async(os_stmt_id) => {
    var retState = false;
      var newCustOsStmtArr = [];
      console.log("getCustOsStmtList method called ...");
  
      try {
        var aIVal = localStorage.getItem("_aI");
        var headers = { ...client.headers, Authorization: `Bearer ${aIVal}`}
        var res = await axios.get(client.domain + `/getDeliverySummaryListForEdit?os_stmt_id=${os_stmt_id}`, { headers }, { withCredentials: false });
        
        console.log("Response from server getCustOsStmtList : ", res.data.detail.data);
        if (res.data.detail.status === "SUCCESS") {
          console.log("Response message from server getCustOsStmtList : ", res.data.detail.data);        
          newCustOsStmtArr = res.data.detail.data;
          retState = true;
        }
       
      } catch (error) {
        console.log("FULL ERROR : ", error);
        if (error.code === "ERR_NETWORK") {
          console.log(
            "Not able to reach target server please try after sometime"
          );
        } else {
          console.log("ERROR : ", error.response.data.detail);
          if (error.response.data.detail.status === "FAILED") {
            console.log(
              "Request failed due to ",
              error.response.data.detail.res_data
            );
            
          }
        }
      }
      return [retState, newCustOsStmtArr];
  }
  
  const getReceiptsForOsStmtEdit = async(os_stmt_id) => {
    var retState = false;
      var newReceiptsArr = [];
      console.log("getReceiptsForOsStmtEdit method called ...");
  
      try {
        var aIVal = localStorage.getItem("_aI");
        var headers = { ...client.headers, Authorization: `Bearer ${aIVal}`}
        var res = await axios.get(client.domain + `/getReceiptsForOsStmtEdit?os_stmt_id=${os_stmt_id}`, { headers }, { withCredentials: false });
        
        console.log("Response from server getReceiptsForOsStmtEdit : ", res.data.detail.data);
        if (res.data.detail.status === "SUCCESS") {
          console.log("Response message from server getReceiptsForOsStmtEdit : ", res.data.detail.data);        
          newReceiptsArr = res.data.detail.data.receipts;
          retState = true;
        }
       
      } catch (error) {
        console.log("FULL ERROR : ", error);
        if (error.code === "ERR_NETWORK") {
          console.log(
            "Not able to reach target server please try after sometime"
          );
        } else {
          console.log("ERROR : ", error.response.data.detail);
          if (error.response.data.detail.status === "FAILED") {
            console.log(
              "Request failed due to ",
              error.response.data.detail.res_data
            );
            
          }
        }
      }
      return [retState, newReceiptsArr];
  }

  const getOutstandingById = async(os_stmt_id) => {
    var retState = false;
      var osStmtInfo = {};
      console.log("getOutstandingById method called ...");
  
      try {
        var aIVal = localStorage.getItem("_aI");
        var headers = { ...client.headers, Authorization: `Bearer ${aIVal}`}
        var res = await axios.get(client.domain + `/getCustOsStmtById?os_stmt_id=${os_stmt_id}`, { headers }, { withCredentials: false });
        
        console.log("Response from server getOutstandingById : ", res.data.detail.data);
        if (res.data.detail.status === "SUCCESS") {
          console.log("Response message from server getOutstandingById : ", res.data.detail.data);        
          osStmtInfo = res.data.detail.data;
          retState = true;
        }
       
      } catch (error) {
        console.log("FULL ERROR : ", error);
        if (error.code === "ERR_NETWORK") {
          console.log(
            "Not able to reach target server please try after sometime"
          );
        } else {
          console.log("ERROR : ", error.response.data.detail);
          if (error.response.data.detail.status === "FAILED") {
            console.log(
              "Request failed due to ",
              error.response.data.detail.res_data
            );
            
          }
        }
      }
      return [retState, osStmtInfo];


  }
  
  useEffect(() => {
    console.log("useEffect method called ...");
    (async () => {
      //var [retState, deliverySummaryList] = await getAllSummaryList();
      /*
      if (retState) {
        setSummaryList(deliverySummaryList);
        //setDcListFetched(true);
        setToRender(true); 
      }
      */
      console.log("CALLED CURRENT PAGE : ", window.location.href);

    console.log("CALLED window locaion : ", window.location);
    let params = (new URL(window.location)).searchParams;
    console.log("COMPLETE PARAMS : ", params);
    let orderIdParam = params.get("order_id");
    console.log("ORDER ID FROM PARAM : ", orderIdParam);

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.indexOf('?') + 1);
    var urlTmp = urlStr.substring(0, urlStr.indexOf("?"));

    console.log("URL : ", urlTmp);
    console.log("URL : ", paramsTmp);

    let searchParams = new URLSearchParams(paramsTmp);
    var osStmtId = searchParams.get("os_stmt_id");
    console.log("CUSTOMER OUTSTANDING ID : ", osStmtId);

      var [retState, companyInfoTmp] = await getCompanyInfo();
      if (retState) {
        setCompanyInfo(companyInfoTmp); 
      }

      var [retStateSummary, newSummaryArr] = await getDeliverySummaryListForEdit(osStmtId);
      if ( retStateSummary ) {
        console.log("Received Summary list:", newSummaryArr);
        setStatementList(newSummaryArr);

        total_quantity_sum.current = 0.00;
        total_product_price_sum.current = 0.00;
        total_extra_charges_sum.current = 0.00;
        total_amount_sum.current = 0.00;

        newSummaryArr.map((summary) => {
          total_quantity_sum.current = total_quantity_sum.current + parseFloat(summary.total_quantity);
          total_product_price_sum.current = total_product_price_sum.current + parseFloat(summary.unit_price * summary.total_quantity);
          total_extra_charges_sum.current = total_extra_charges_sum.current + parseFloat(summary.diesel_charges + summary.pumping_charges + summary.others_charges1);
          total_amount_sum.current = total_amount_sum.current + parseFloat(summary.total_amount);
        })
      }
      var [retStateReceipts, newReceiptsArr] = await getReceiptsForOsStmtEdit(osStmtId);
      if (retStateReceipts) {
        console.log("Received receipt list:", newReceiptsArr);
        setReceiptList(newReceiptsArr);

        amount_sum.current = 0.00;

        newReceiptsArr.map((summary) => {
          amount_sum.current = amount_sum.current + parseFloat(summary.amount);
        })

      }

      var [retStateOs, newOsInfo] = await getOutstandingById(osStmtId);
      if ( retStateOs ) {
        console.log("Received receipt list:", newOsInfo);
        setOsStmtInfo(newOsInfo);
      }

      setToRender(true);
    })();
    
  }, [])

  console.log("**************STATEMENT INFO:*************", osStmtInfo);


  return (
    <PDFViewer style={styles.viewer}>
      {toRender === true &&
        <Document>
          <Page size="A4" orientation="landscape" style={styles.page}>
            <View>
              <View style={{ flexDirection: 'row', borderBottomWidth: 1, borderBottomColor: '#a7a5a5' }}>
                <View style={{ textAlign: 'right', width: '25%' }}>
                  <Image style={{ marginHorizontal: '10%', width: 'auto', height: 'auto', height: "64px", width: "64px" }} src={logo_left} />
                </View>
                <View style={{ width: "50%", alignItems: "center", /*paddingRight: "30px",*/ /*borderBottomWidth: 1,*/ borderBottomColor: '#a7a5a5'/*,marginTop: 1, marginBottom: 10, borderWidth: 1*/ }} key="address-key">
                  <Text style={{ fontSize: "20px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>{companyInfo.name}</Text>
                  <Text>{companyInfo.address_line1}</Text>
                  <Text>{companyInfo.address_line2}-{companyInfo.pincode}</Text>
                  <Text>{companyInfo.district_name}, {companyInfo.state_name}</Text>
                  <Text style={{ fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica'}}><Text style={{ fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>Mobile:</Text> {companyInfo.phone}</Text>
                </View>
                <View style={{ textAlign: 'right', width: '25%',paddingLeft: '140px', alignItems: 'right'}}>
                  <Image style={{ /*marginHorizontal: '10%',*/ width: 'auto', height: 'auto', height: "64px", width: "64px"}} src={logo_right} />
                </View>
              </View>
            </View>
           
            <View style={{ width: "100%", alignItems: "center", textAlign: 'center',  paddingTop: "5px",borderBottomWidth: 1, borderBottomColor: '#a7a5a5' }}>
              <Text style={{ fontSize: "12px", textAlign: 'center', alignContent: 'center', fontFamily: 'Helvetica-Bold', color: '#646363' }}>Customer Outstanding Statement</Text>
            </View>
            <hr style={{paddingBottom: "10px"}}/>
            <View style={{ flexDirection: 'row' }}>
            <View style={{flexDirection: 'row',textAlign: 'left'}}>
                <Text style={{fontFamily: 'Helvetica-Bold',  alignItems: "left",textAlign: 'right'}}> Date : </Text> 
                <Text style={{  alignItems: "left",textAlign: 'right'}}>{formatDateIndian(new Date(osStmtInfo.stmt_date))}</Text>     
                </View> 
                
                <View style={{flexDirection: 'row',textAlign: 'left'}}>
                  <Text style={{fontFamily: 'Helvetica-Bold',paddingLeft: "20px", alignItems: "left",textAlign: 'left'}}>Customer : </Text>
                  <Text style={{  alignItems: "left",textAlign: 'left'}}>{osStmtInfo.customer_name}</Text>
                  </View> 
                  </View>
                  <hr style={{paddingBottom: "10px"}}/>

            <View>
            <Text style={{textAlign: 'left', alignContent: 'left', fontFamily: 'Helvetica-Bold', color: '#646363' }}>Current Outstanding Details</Text>
            </View>

            <View style={styles.tableContainer}>
              
              <View style={styles.container}>
                <Text style={styles.sno}>S.No</Text>
                <Text style={styles.delivery_date}>Date</Text>
                <Text style={styles.address_line1}>Site</Text>
                <Text style={styles.product_name}>Grade</Text>
                <Text style={styles.total_quantity}>Volume</Text>
                <Text style={styles.unit_price}>Rate</Text>
                <Text style={styles.product_price}>Amount</Text>
                <Text style={styles.extra_charges}>Extra Charges</Text>
                <Text style={styles.total_amount}>Total</Text>
                
              </View>

              <View style={{flexGrow: 1}}>
              {statementList.map((statement, idx) => (
              <View style={styles.row} key="items-${idx}">
                    <Text style={styles.snoData}>{idx + 1}</Text>
                    <Text style={styles.delivery_dateData}>{formatDateIndian(new Date(statement.delivery_date))}</Text>
                    <Text style={styles.address_line1Data}>{statement.address_line1}</Text>
                    <Text style={styles.product_nameData}>{statement.product_name}</Text>
                    <Text style={styles.total_quantityData}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(statement.total_quantity).toFixed(2)))}</Text>
                    <Text style={styles.unit_priceData}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(statement.unit_price).toFixed(2)))}</Text>
                    <Text style={styles.product_priceData}>{(statement.total_quantity.toFixed(2) * statement.unit_price.toFixed(2)).toFixed(2)}</Text>                    
                    <Text style={styles.extra_chargesData}>{statement.diesel_charges + statement.pumping_charges + statement.others_charges1.toFixed(2)}</Text>
                    <Text style={styles.total_amountData}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(statement.total_amount).toFixed(2)))}</Text>
                    
              </View>
               ))}
              </View>
              
              </View>
              <View style={{...styles.row,borderBottomWidth: 1, borderBottomColor: '#a7a5a5'}} key="items-${idx}" >
                    <Text style={styles.snoData}></Text>
                    <Text style={styles.delivery_dateData}></Text>
                    <Text style={styles.address_line1Data}></Text>
                    <Text style={{...styles.product_nameData, fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Total:</Text>
                    <Text style={{...styles.total_quantityData,textAlign: 'right',  fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(total_quantity_sum.current).toFixed(2)))}</Text>
                    <Text style={{...styles.unit_priceData,textAlign: 'right', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}></Text>
                    <Text style={{...styles.product_priceData,textAlign: 'right', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(total_product_price_sum.current).toFixed(2)))}</Text>                    
                    <Text style={{...styles.extra_chargesData,textAlign: 'right',  fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(total_extra_charges_sum.current).toFixed(2)))}</Text>
                    <Text style={{...styles.total_amountData,textAlign: 'right', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(total_amount_sum.current).toFixed(2)))}</Text>
                    
              </View>
              <hr style={{paddingBottom: "20px"}}/>


              <View>
            <Text style={{textAlign: 'left', alignContent: 'left', fontFamily: 'Helvetica-Bold', color: '#646363' }}>Advances Received</Text>
            </View>
             
              <View style={{ flexDirection: 'row' }}>
                 {/*
              <View style={{ width: "50%", alignItems: "right", textAlign: 'right', borderBottomWidth: 1, borderBottomColor: '#a7a5a5', paddingTop: "5px" }}>
              <Text style={{ fontSize: "12px", textAlign: 'left', alignContent: 'left', fontFamily: 'Helvetica-Bold', color: '#646363' }}>RECEIPTS</Text>
            </View>
            */}

            
            
            <View style={{ ...styles.tableContainer, width: '50%'}}>
            
            <View style={styles.container}>
                <Text style={styles.snoReceipt}>S.No</Text>
                <Text style={styles.noReceipt}>Receipt No</Text>
                <Text style={styles.dateReceipt}>Date</Text>
                <Text style={styles.descriptionReceipt}>Note</Text>
                <Text style={styles.amountReceipt}>Amount</Text>
              </View>
              <View style={{flexGrow: 1}}>
              {receiptList.map((receipt, idx) => (
              <View style={styles.row} key="items-${idx}">
                    <Text style={styles.snoReceiptData}>{idx + 1}</Text>
                    <Text style={styles.noReceiptData}>{receipt.receipt_number}</Text>
                    <Text style={styles.dateReceiptData}>{formatDateIndian(new Date(receipt.receipt_date))}</Text>
                    <Text style={styles.descriptionReceiptData}>{receipt.towards}</Text>
                    <Text style={styles.amountReceiptData}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(receipt.amount).toFixed(2)))}</Text>
              </View>
               ))}
              </View>

              <View style={{flexGrow: 1}}>
              <View style={styles.row} key="items-${idx}">
                    <Text style={styles.snoReceiptData}></Text>
                    <Text style={styles.noReceiptData}></Text>
                    <Text style={{...styles.dateReceiptData,  fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Total:</Text>
                    <Text style={styles.descriptionReceiptData}></Text>
                    <Text style={{...styles.amountReceiptData,textAlign: 'right',  fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(amount_sum.current).toFixed(2)))}</Text>
              </View>
              </View>
              </View>
              {/*
              <View style={{ width: "50%", alignItems: "right", textAlign: 'right', borderBottomWidth: 1, borderBottomColor: '#a7a5a5', paddingTop: "5px" }}>
              <Text style={{ fontSize: "12px", textAlign: 'right', alignContent: 'right', fontFamily: 'Helvetica-Bold', color: '#646363' }}>OUTSTANDING STATEMENT</Text>
            </View>
            */}
            <View style={{width: '30%'}}></View>
            {/*
            <View style={{width: '20%', alignContent: 'right'}}>
            <Text style={{ fontSize: "12px", fontFamily: 'Helvetica-Bold', color: '#646363' }}>Outstanding Statement</Text>
            
            <View style={{flexDirection: 'row', alignContent: 'right', alignItems: 'right', justifyContent: 'front-end !important'}}><Text style={{ fontSize: "12px",fontWeight: 'bold', fontFamily: 'Helvetica-Bold',alignContent: 'right', alignItems: 'right',}}>Previous Balance: </Text> <Text style={{fontSize: "12px", textAlign: 'right !important', alignContent: 'right !important', fontFamily: 'Helvetica'}}>1000.00</Text></View>
            <View style={{flexDirection: 'row'}}><Text style={{ fontSize: "12px",fontWeight: 'bold', fontFamily: 'Helvetica-Bold',alignContent: 'right', alignItems: 'right',}}>Previous Balance: </Text> <Text style={{fontSize: "12px", textAlign: 'right !important', alignContent: 'right !important', fontFamily: 'Helvetica'}}>10000.00</Text></View>
            
            <Text style={{ fontSize: "10px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}> Total Received <Text style={{fontSize: "12px", textAlign: 'right', alignContent: 'right', fontFamily: 'Helvetica'}}>: 1000.00</Text></Text>
            <View style={{flexDirection: 'row'}}><Text style={{ fontSize: "10px", paddingBottom: "5px",fontWeight: 'bold', fontFamily: 'Helvetica-Bold',paddingLeft: "7px",}}>Current Balance</Text> <Text style={{fontSize: "12px", textAlign: 'right', alignContent: 'right', fontFamily: 'Helvetica'}}>: 3000.00</Text></View>
            <View style={{flexDirection: 'row'}}><Text style={{ fontSize: "10px", alignContent: 'left', paddingBottom: "5px",fontWeight: 'bold', fontFamily: 'Helvetica-Bold',paddingLeft: "21px"}}>Current Balance</Text> <Text style={{fontSize: "12px", fontFamily: 'Helvetica'}}>: 5000.00</Text></View>
            <View style={{flexDirection: 'row'}}><Text style={{ fontSize: "10px", alignContent: 'left', paddingBottom: "5px",fontWeight: 'bold', fontFamily: 'Helvetica-Bold',paddingLeft: "21px"}}>Total Balance</Text> <Text style={{fontSize: "12px", fontFamily: 'Helvetica', alignContent: 'right !important', alignItems: 'right !important', textAlign: 'right !important', alignSelf: 'flex-end !important'}}>: 60000.00</Text></View>
          
            </View>
            */}

            <View style={{ flexDirection: 'row', flexWrap: 'wrap', width: '20%'}}>
              {/*
            <View>
            <Text style={{ fontSize: "12px", textAlign: 'left', alignContent: 'left', fontFamily: 'Helvetica-Bold', color: '#646363' }}>Outstanding Statement</Text>
            </View>
          */}
            <View style={{flexDirection: 'row', alignItems: 'right', height: 24, textAlign: 'right', fontStyle: 'bold'}}>  
                <Text style={{width: '50%', alignItems: 'left', height: 24, textAlign: 'left',fontWeight: 'bold',fontFamily: 'Helvetica-Bold'}}>Old Balance </Text>
                <Text style={{width: '50%'}}>{osStmtInfo.previous_balance > 0.01 ? (new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(osStmtInfo.previous_balance).toFixed(2))) : 0.00}</Text>
              </View>

              <View style={{flexDirection: 'row', alignItems: 'right', height: 24, textAlign: 'right', fontStyle: 'bold'}}>  
                <Text style={{width: '50%', alignItems: 'left', height: 24, textAlign: 'left',fontWeight: 'bold',fontFamily: 'Helvetica-Bold'}}>Current Balance </Text>
                <Text style={{width: '50%'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(osStmtInfo.current_balance).toFixed(2)))}</Text>
              </View>

              <View style={{flexDirection: 'row', alignItems: 'right', height: 24, textAlign: 'right', fontStyle: 'bold',borderBottomWidth: 1, borderBottomColor: '#a7a5a5'}}>  
                <Text style={{width: '50%', alignItems: 'left', height: 24, textAlign: 'left',fontWeight: 'bold',fontFamily: 'Helvetica-Bold'}}>Total Received </Text>
                <Text style={{width: '50%'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(osStmtInfo.total_received).toFixed(2)))}</Text>
              </View>

              <View style={{flexDirection: 'row', alignItems: 'right', height: 24, textAlign: 'right', fontStyle: 'bold',borderBottomWidth: 1, borderBottomColor: '#a7a5a5'}}>  
                <Text style={{width: '50%', alignItems: 'left', height: 24, textAlign: 'left',fontWeight: 'bold',fontFamily: 'Helvetica-Bold',paddingTop:"5px"}}>Total Balance </Text>
                <Text style={{width: '50%',paddingTop:"5px",fontWeight: 'bold',fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(osStmtInfo.total_balance).toFixed(2)))}</Text>
              </View>

            </View>
            </View>
            <hr style={{paddingBottom: "40px"}}/>
             

            <View style={{flexDirection: 'row'}}>
            
            <Text style={{textAlign: 'left', flex: 1}} render={({ pageNumber, totalPages }) => (
                `Printed On: ${formatPrintDate(new Date())}`
            )} fixed />
             
             <Text style={{  textAlign: 'center', alignContent: 'center' }}>..... End of Report .....</Text>
           
              <Text style={{flex: 1, textAlign: 'right', justifyContent: 'flex-end', alignSelf: 'flex-end'}} render={({ pageNumber, totalPages }) => (
                `Page No : ${pageNumber} / ${totalPages}`
              )} fixed />
              </View>
              

          </Page>
        </Document>
      }
    </PDFViewer>
  );
}

export default PrintCustOsStmt;