import React, { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, pdf, PDFDownloadLink } from '@react-pdf/renderer';

import { client } from '../config/Config';

//import StockDocument from "./StockDocument";
import {getCompanyInfo, formatDateIndian } from "./utils";

import logo_left from '../logo_left.png';
import logo_right from '../logo_right.png';
import { saveAs } from 'file-saver';
//import { OpenFile } from '@react-pdf-viewer/core';

const borderColor = '#55585a';
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 9,
    paddingTop: 30,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    //width: '100%',
    //height: '33%',
    width: '100%',
    height: '100%',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  logo_image2: {
    width: '15%',
    height: '98px',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  business_content: {
    width: '60%',
    height: '98px',
    //paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container2: {
    //paddingBottom:0,
    flexDirection: 'row',
    //borderBottomColor: '#bff0fd',
    //backgroundColor: '#bff0fd',
    //borderBottomWidth: 1,
    //alignItems: 'center',
    //height: 12,
    //textAlign: 'center',
    //fontStyle: 'bold',
    //flexGrow: 1,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 1,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#a8a8a8',

  },
  container: {
    flexDirection: 'row',
    borderBottomColor: '#a8a8a8',
    backgroundColor: '#a8a8a8',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    //flexGrow: 1,
    //flexWrap: 'wrap',
    //marginTop: 1,
    //marginBottom: 10,
    //borderWidth: 1,
    //borderWidth: 1,
    //marginBottom: 5,
  },
  sno: {
    width: '5%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left'
   
  },
  name: {
    width: '18%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    //paddingRight:2
  },
  gst: {
    width: '15%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    //paddingRight:2
  },
  
  
  contact_person: {
    width: '18%',
    fontSize: "10px",
    borderRightColor: borderColor,
    textAlign: 'left',
    //borderRightWidth: 1,
  },
  contact_number: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    textAlign: 'left',
    //alignItems: "right",
    //paddingLeft:10
    //borderRightWidth: 1,
  },
  place: {
    width: '20%',
    fontSize: "10px",
    borderRightColor: borderColor,
    textAlign: 'left',
    //paddingRight: 5
    //borderRightWidth: 1,
    
  },
  active: {
    width: '14%',
    fontSize: "10px",
    borderRightColor: borderColor,
    textAlign: 'right',
    paddingRight: 15
    //borderRightWidth: 1,
    
  },
  
  row: {
    flexDirection: 'row',
    //borderBottomColor: '#a8a8a8',
    //borderBottmWidth: 1,
    alignItems: 'center',
    //height: 24,
    fontStyle: 'bold',
  },
  snoData: {
    width: '5%',   
    //textAlign: 'left',
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    fontSize: "10px",
    //paddingLeft: 8,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  nameData: {
    width: '18%',
    fontSize: "10px",
    //textAlign: 'left',
    textAlign: 'justify',
    //alignContent: 'justify',
    //textAlign: 'justify',
    //textJustify: 'inter-word',
    //display:'flex',
    //flexWrap: 'wrap',
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    //paddingLeft: 8,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },

  gstData: {
    width: '15%',
    fontSize: "10px",
    //textAlign: 'left',
    textAlign: 'left',
    //paddingLeft: 50,
    //alignContent: 'justify',
    //textAlign: 'justify',
    //textJustify: 'inter-word',
    //display:'flex',
    //flexWrap: 'wrap',
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    //paddingLeft: 4,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  
 
  contact_personData: {
    width: '18%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
   // paddingLeft: 100,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  contact_numberData: {
    width: '10%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    //paddingLeft: 100,
    //paddingLeft: 4,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  placeData: {
    width: '20%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    //paddingLeft: 120,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },

  activeData: {
    width: '14%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    paddingLeft: 70,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  

});

const PrintCustomer = (props) => {

 
 
  const [reportData, setReportData] = useState({});
  const [toRender, setToRender] = useState(false);
  const [customerList, setCustomerList] = useState([]);
 
  const [companyInfo, setCompanyInfo] = useState({});
  var customer_id_ref = useRef(0);
  var start_date_ref = useRef(formatDate(new Date()));
  var end_date_ref = useRef(formatDate(new Date()));

  
  
  console.log("CALLED CURRENT PAGE : ", window.location.href);

    console.log("CALLED window locaion : ", window.location);
    /*
    let params = (new URL(window.location)).searchParams;
    console.log("COMPLETE PARAMS : ", params);
    let orderIdParam = params.get("order_id");
    console.log("ORDER ID FROM PARAM : ", orderIdParam);

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.indexOf('?') + 1);
    var urlTmp = urlStr.substring(0, urlStr.indexOf("?"));

    console.log("URL : ", urlTmp);
    console.log("URL : ", paramsTmp);

    let searchParams = new URLSearchParams(paramsTmp);
    var customerIdTemp2 = searchParams.get("customer_id");
    console.log("CUSTOMED ID : ", customerIdTemp2);
    var productIdTemp2 = searchParams.get("product_id");
    console.log("PRODUCT ID : ", productIdTemp2);
    var startDateStr = searchParams.get("start_date");
    console.log("START DATE : ", startDateStr);
    var endDateStr = searchParams.get("end_date");
    console.log("END DATE : ", endDateStr);

    console.log("Extracted CUSTOMER ID  : ", customerIdTemp2);
    console.log("Extracted PRODUCT ID : ", productIdTemp2);
   */

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  const formateTime = (currTime) => {
    var h = (currTime.getHours() < 10 ? "0" : "") + currTime.getHours();
    var m = (currTime.getMinutes() < 10 ? "0" : "") + currTime.getMinutes();
    var hrMin = h + ":" + m;
    return hrMin;
  };

  const formatPrintDate = (currTime) => {
    var dateComponent = [
      padTo2Digits(currTime.getDate()),
      padTo2Digits(currTime.getMonth() + 1),
      currTime.getFullYear(),
    ].join("-");

    var timeComponent = [
      padTo2Digits(currTime.getHours() % 12 || 12 ),
      padTo2Digits(currTime.getMinutes())
    ].join(":");

    const ampm = currTime.getHours() >= 12 ? 'PM' : 'AM';

    return dateComponent + " " + timeComponent + " " + ampm;
  }
  /*
  const getCustInvoiceList = async() => {
    var retState = false;
    var retInvoiceList = [];
    try {
      var aIVal = localStorage.getItem("_aI");
      console.log("Authorization token : ", aIVal);

      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}

      console.log("Headers : ", headers);

      console.log("++++++++++++++++++++ CUSTOMER ID SELECT ----------------: ", customer_id_ref.current);

      
      var res = await axios.get(client.domain + `/getAllOrders?customer_id=${customer_id_ref.current}&start_date=${start_date_ref.current}&end_date=${end_date_ref.current}`, {headers}, {
        withCredentials: false,
      });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getOrdersList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log(
          "Response message from server getDistrictList : ",
          res.data.detail.res_data
        );
        //setOrderList(res.data.detail.data);
        //setOrderListFull(res.data.detail.data);

        if (res.data.detail.res_data.order_list.length > 0) {
          setInvoiceList(res.data.detail.res_data.order_list);
          retInvoiceList = res.data.detail.res_data.order_list;
          retState = true;
          //setOrderListFull(res.data.detail.res_data.order_list);
        }
        setToRender(true);
      }
      
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
	setToRender(true);
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ",error.response.data.detail.res_data);
        }
      }
    }
    setToRender(true);

    return [retState, retInvoiceList];
  };
  */
  const getCustomerList = async () => {
    var retState = false;
    var customerArrRet = [];
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
    

      var res = await axios.get(
        client.domain + "/crm/getAllCustomers",
        { headers },
        { withCredentials: false }
      );
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", 0);
        setCustomerList(res.data.detail.data);
        customerArrRet = res.data.detail.data;
        retState = true;
        //setCustomerListFull(res.data.detail.data);
        //setShowMsgTitle("Customer Info Added Successfully");
        setToRender(true);
        //navigate("/dashboard");
        //setDcReportData(res.data.detail.res_data);
        //setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        //setShowMsg("Please try after sometime");
        //setShowMsgTitle("Network Issue!");
        //setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg("Please try again ...");
          //setShowMsgTitle("Unable to process your request!");
          //setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          //setShowMsg(error.response.data.detail);
          //setShowMsgTitle("Unable to process your request at this moment!");
          //setShow(true);
        }
      }
    }
    return [retState, customerArrRet];
  };
  
  useEffect(() => {
    console.log("useEffect method called ...");
    (async () => {
      var [retState, customerArrRet] = await getCustomerList();
      console.log("RETURNED CUSTOMER INFO : ", customerArrRet);

      var [retState, companyInfoTmp] = await getCompanyInfo();
      if (retState) {
        console.log("FETCHED COMPANY INFO : ", companyInfoTmp);
        setCompanyInfo(companyInfoTmp);
        setToRender(true); 
      }
    })();
    
  }, [])
  /*

  exportToJson = el => {
    if (!isEmpty(this.formData)) {
      //const obj = encodeURIComponent(JSON.stringify(this.formData, null, '\t'));
      //const data = "text/json; charset=utf-8," + "obj";
      //const date = new Date();

      const fileName = "customer.json";
      el.target.setAttribute('href', 'data:' + data);
      el.target.setAttribute('download', fileName);
    }
  };
  */
 /*
  const getFilePluginInstance = getFilePlugin({
    fileNameGenerator: (file: OpenFile) => {
        // `file.name` is the URL of opened file
        const fileName = file.name.substring(file.name.lastIndexOf('/') + 1);
        return `a-copy-of-${fileName}`;
    },
});
*/

return (
  <Document>
          <Page size="A4" orientation="landscape" style={styles.page}>
            <View>
              <View style={{ flexDirection: 'row', borderBottomWidth: 1, borderBottomColor: '#a7a5a5' }}>
                <View style={{ textAlign: 'right', width: '25%' }}>
                  <Image style={{ marginHorizontal: '10%', width: 'auto', height: 'auto', height: "64px", width: "64px" }} src={logo_left} />
                </View>
                <View style={{ width: "50%", alignItems: "center", /*paddingRight: "30px",*/ /*borderBottomWidth: 1,*/ borderBottomColor: '#a7a5a5'/*,marginTop: 1, marginBottom: 10, borderWidth: 1*/ }} key="address-key">
                  <Text style={{ fontSize: "20px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>{companyInfo.name}</Text>
                  <Text>{companyInfo.address_line1}</Text>
                  <Text>{companyInfo.address_line2}-{companyInfo.pincode}</Text>
                  <Text>{companyInfo.district_name}, {companyInfo.state_name}</Text>
                  <Text style={{ fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica'}}><Text style={{ fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>Mobile:</Text> {companyInfo.phone}</Text>
                </View>
                <View style={{ textAlign: 'right', width: '25%',paddingLeft: '140px', alignItems: 'right'}}>
                  <Image style={{ /*marginHorizontal: '10%',*/ width: 'auto', height: 'auto', height: "64px", width: "64px"}} src={logo_right} />
                </View>
              </View>
            </View>
           
            <View style={{ width: "100%", alignItems: "center", textAlign: 'center',  paddingTop: "5px",borderBottomWidth: 1, borderBottomColor: '#a7a5a5' }}>
              <Text style={{ fontSize: "12px", textAlign: 'center', alignContent: 'center', fontFamily: 'Helvetica-Bold', color: '#646363' }}>Customers</Text>
            </View>
            <hr style={{paddingBottom: "10px"}}/>
           
            <View style={styles.tableContainer}>
              
              <View style={styles.container}>
                <Text style={styles.sno}>S.No</Text>
                <Text style={styles.name}>Name</Text>
                <Text style={styles.place}>Place</Text>
                <Text style={styles.contact_person}>Contact Person</Text>
                <Text style={styles.contact_number}>Contact Number</Text>
                <Text style={styles.gst}>GST Number</Text>
                <Text style={styles.active}>Active</Text>
                
                
              </View>

              <View style={{flexGrow: 1}}>
              {customerList.map((customer, idx) => (
              <View style={styles.row} key={idx}>
                    <Text style={styles.snoData}>{idx + 1}</Text>
                    <Text style={styles.nameData}>{customer.display_name}</Text>
                    <Text style={styles.placeData}>{customer.address_line2}</Text>
                    <Text style={styles.contact_personData}>{customer.contact_person}</Text>
                    <Text style={styles.contact_numberData}>{customer.contact_person_number}</Text>
                    <Text style={styles.gstData}>{customer.gst_no}</Text>
                    <Text style={styles.activeData}>{customer.status}</Text>  
              </View>
               ))}
              </View>
              
              </View>
             
              <hr style={{paddingBottom: "20px"}}/>


              
            <hr style={{paddingBottom: "40px"}}/>
             

            <View style={{flexDirection: 'row'}}>
            
            <Text style={{textAlign: 'left', flex: 1}} render={({ pageNumber, totalPages }) => (
                `Printed On: ${formatPrintDate(new Date())}`
            )} fixed />
             
             <Text style={{  textAlign: 'center', alignContent: 'center' }}>..... End of Report .....</Text>
           
              <Text style={{flex: 1, textAlign: 'right', justifyContent: 'flex-end', alignSelf: 'flex-end'}} render={({ pageNumber, totalPages }) => (
                `Page No : ${pageNumber} / ${totalPages}`
              )} fixed />
              </View>
              

          </Page>
        </Document>
);

}

export default PrintCustomer;