import React, { useState, useEffect, useRef, useReducer } from "react";
import Table from "react-bootstrap/Table";
import { fragment } from "react";
import select from "react-select";
import Form from "react-bootstrap/Form";
import Sidebar2 from "./Sidebar2";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { client } from "../config/Config";
import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsCardText,
} from "react-icons/bs";

import { useNavigate } from "react-router-dom";
import { Row, Col, Alert, Modal, Container, Spinner,Image } from "react-bootstrap";

import MenuIms from "./MenuIms";
import SidebarMain from "./SidebarMain";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';



function ListProducts() {
  const initialized = useRef(false);
  const navigate = useNavigate();
  const [checkedState, setCheckedState] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  
 // const [productList, setProductList] = useState([]);

  

  const [checked, setChecked] = useState(false);
  

  const [productList, setProductList] = useState([]);
  const [toRender, setToRender] = useState(false);
  const [show, setShow] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(-1);
  const [showMsg, setShowMsg] = useState("Please login again");
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );
  const handleCloseDialog = () => {
    setShowDialog(false);
  };
  const handleShowDialog = () => {
    setShowDialog(true);
  };
  const updateProductStatus = async (selectedId) => {
    var selectedObj = productList[selectedId];
    let reqStatusUpdatePayload = {
      id: selectedObj.id,
      is_active: !selectedObj.is_active,
    };

    var headers = client.headers;
    console.log("payload:", reqStatusUpdatePayload);

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}


      var res = await axios.post(
        client.domain + "/ims/activateProduct",
        reqStatusUpdatePayload,
        { headers },
        { withCredentials: false }
      );
      
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        ;

        var itemSelectedUpdate = productList[selectedId];
        itemSelectedUpdate.is_active = !itemSelectedUpdate.is_active;
        console.log(
          "<<<<<<<<<<<<<<<<< ITEM SELECTED MODIFIED ********** ",
          itemSelectedUpdate
        );

        const newitems = productList.slice();
        newitems[selectedId] = itemSelectedUpdate;
        setProductList(newitems);

        setShowDialog(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
        }
      }
    }
    setShowDialog(false);
  };
  const handleSwitchAction = () => {
    console.log("user confirmed to enable or disable ...");
    console.log("user selected id for action is : ", selectedId);

     //call update API
    updateProductStatus(selectedId);
  };
  const actionEditHandle = (e, data) => {
    console.log("Handle action edit Clicked : ", e);
    //console.log("Handle Clicked edit DATA : ", data);
    console.log("Handle action edit Clicked target : ", e.target);
    console.log(
      "Handle action edit Clicked target Current : ",
      e.currentTarget
    );
    console.log(
      "Handle action edit Clicked target Current id : ",
      e.currentTarget.id
    );
    console.log("Handle action edit Clicked target ID : ", e.target.id);
    console.log("Handle action edit Clicked target NAME : ", e.target.name);
  };
  const getProductList = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
     

      var res = await axios.get(
        client.domain + "/ims/getAllProducts",
        { headers },
        { withCredentials: false }
      );
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", 0);
        setProductList(res.data.detail.data);
        setShowMsgTitle("Product Info Added Successfully");
        setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          setShowMsg(error.response.data.detail);
          setShowMsgTitle("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
  };
  const handleStateChange = (e) => {
    console.log("clicked Value :", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);

    var currentId = name.substring(name.lastIndexOf("-") + 1);
    var nameOfElement = name.substring(0, name.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);
  };
  const SwitchComponent = () => {};

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };
  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::ListProducts called ##########################"
      );
      getProductList();
      initialized.current = true;
    }
  }, []);
  const handleAdd = async (e) => {
    console.log("handle add products called : ", e);
    navigate("/addUpdateProduct", {
      state: { passed_element: [], action: "add" },
    });
  };
  const handleView = async (e) => {
    console.log("handle edit customers called : ", e);
    console.log(
      "handle edit products currentTarget called : ",
      e.currentTarget
    );
    console.log("handle edit prooducts target called : ", e.target);
    var selectedTagId = e.currentTarget.id;
    console.log("SELECTED TAG ID: ", selectedTagId);
    if (selectedTagId) {
      var currentId = selectedTagId.substring(
        selectedTagId.lastIndexOf("-") + 1
      );
      var nameOfElement = selectedTagId.substring(
        0,
        selectedTagId.lastIndexOf("-")
      );
      console.log("Name of element VIEW: ", nameOfElement);
      console.log("Current id VIEW : ", currentId);

      console.log(
        "<<<<<<<<<<<<<<<<< ITEM SELECTED VIEW ********** ",
        productList[currentId]
      );
      navigate("/addUpdateProduct", {
        state: {
          passed_element: productList[currentId],
          action: "view",
        },
      });
    }
  };
  const handleOnChange = async (e) => {
    console.log("handle on change called : ", e);

    var selectedTagId = e.target.id;
    console.log("SELECTED TAG ID: ", selectedTagId);
    var currentId = selectedTagId.substring(selectedTagId.lastIndexOf("-") + 1);
    var nameOfElement = selectedTagId.substring(
      0,
      selectedTagId.lastIndexOf("-")
    );
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    console.log(
      "<<<<<<<<<<<<<<<<< ITEM SELECTED ********** ",
      productList[currentId]
    );

    setSelectedId(currentId);

    setShowDialog(true);
  };

  const handleAlertClose = async (e) => {
    setShow(false);
    navigate(-1);
  };

  return (
    <Container>
      <SidebarMain />
      { toRender ?  
      <form>
        <Row>
          <Col xs={6} sm={6} md={6} lg={6}>
            <h4>Products</h4>
          </Col>
          <Col xs={6} md={6} sm={6} lg={6}>
          <Button
                variant="warning"
                id="add-btn-id"
                name="add-btn-name"
                className=" float-end"
                onClick={(e) => handleAdd(e)}
              >
                New Product
              </Button>
          </Col>
        </Row>
          <hr align="center" />
          <div className="ui-divider"></div>
        <Table striped bordered hover>
          <thead>
            <tr className="table-primary">
              <th className="table-row-heading" style={{textAlign: "center"}}>S.No</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Trade Type</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Name</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>HSNCode</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>UOM</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Unit Price</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>CGST</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>SGST</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>IGST</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Active</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Details</th>
            </tr>
          </thead>
          <tbody>
            {productList.map((product, idx) => (
              <tr key={`product-list-row-key-${idx}`}>
                <td style={{textAlign: "right"}}>{idx + 1}</td>
                <td style={{textAlign: "left"}}>{product.tt_value}</td>
                <td style={{textAlign: "left"}}>{product.name}</td>
                <td style={{textAlign: "right"}}>{product.hsncode}</td>
                <td style={{textAlign: "left"}}>{product.uom_value}</td>
                <td style={{textAlign: "right"}}>{(product.unit_price === null) ? (0.00).toFixed(2) : (new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(product.unit_price).toFixed(2)))}</td>
                <td style={{textAlign: "right"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(product.cgst).toFixed(2)))}</td>
                <td style={{textAlign: "right"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(product.sgst).toFixed(2)))}</td>
                <td style={{textAlign: "right"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(product.igst).toFixed(2)))}</td>
                <td style={{textAlign: "center"}}> 
                   <Form.Check // prettier-ignore
                      key={`product-switch-key-${idx}`}
                      type="switch"
                      id={`product-switch-${idx}`}
                      name={`product-switch-${idx}`}
                      onChange={handleOnChange}
                      checked={product.is_active}
                    ></Form.Check>
                </td>
                <td style={{textAlign: "center"}}>
                <BsCardText
                      key={`edit-btn-product-key-${idx}`}
                      size={28}
                      style={{ color: "#0D6EFD", cursor: "pointer" }}
                      onClick={(e) => handleView(e)}
                      name={`edit-icon-product-name-${idx}`}
                      id={`edit-icon-product-id-${idx}`}
                    />
                  </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Modal
        show={showDialog}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Activae / In-Activate this product? Click OK to proceed...
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSwitchAction}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      </form>
      : 
       <div className="container-spinner">
      <div className="vertical-center-spinner">
       {/*<Button variant="primary" disabled>*/}
       <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
       {/*</Button>*/}
       </div>
       </div>
 }
    </Container>
  );
}
export default ListProducts;
