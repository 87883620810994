import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 12,
        borderBottomColor: '#bff0fd',
    },
    billTo: {
        marginTop: 24,
        paddingBottom: 3,
        //fontFamily: 'Helvetica-Oblique'
        fontFamily: 'Helvetica-Bold'
    },
  });


  //const BillTo = ({invoice}) => {
  const BillTo = (props) => {
    console.log("PROPS DC LOAD at BillTo : ", props.dcLoad)
  return (
    <View style={styles.headerContainer}>
        <Text style={styles.billTo}>Delivery Address:</Text>
        <Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica'}}>{props.dcLoad.dc_info.customer_name}</Text>
        <Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica'}}>{props.dcLoad.dc_info.contact_person}</Text>
        <Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica'}}>{props.dcLoad.dc_info.address_line1}, {props.dcLoad.dc_info.address_line2}{"\n"}</Text>
        <Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica'}}>{props.dcLoad.dc_info.district_name}{"\n"}</Text>
        <Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica'}}>{props.dcLoad.dc_info.state_name}</Text>
        
        <Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica'}}><Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Mobile:</Text> {props.dcLoad.dc_info.contact_number}</Text>
    </View>
  );
  }
  export default BillTo
