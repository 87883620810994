import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Accordion, Card, Table, Form, Spinner, Image } from 'react-bootstrap';
import {Container, Row, Col, Button, } from "react-bootstrap";
import Sidebar2 from './Sidebar2';
import ListGroup from 'react-bootstrap/ListGroup';
//import 'react-datepicker/dist/react-datepicker.css';

import { client } from '../config/Config';
import SidebarMain from './SidebarMain';
import logo_avinaerp from '../assets/images/logo_avinaerp.png';

function ListDCsOld() {
  //const [deliveryChallans, setDeliveryChallans] = useState([]);

  const [challanData, setChallanData] = useState([]);
  const currentDate = new Date().toISOString().slice(0, 10); 
  const [fromDate, setFromDate] = useState(currentDate);
  const [toDate, setToDate] = useState(currentDate);
  const [toRender, setToRender] = useState(false);
  var dcArr = [
    {"DCId":39,"DCDate":"2023-08-16T12:59:47.415000","DCNo":"DC-10041","OrderNo":"ORD-10015","OrderDate":"2023-08-09T17:31:18.020968","Customer":"poulraj","Contact":"poulraj","ContactNo":"9842899120","Items":1,"Vehicle":"TN 18 C 8033","Driver":"Mathesh","Product":"M10","Quantity":5,"UnitPrice":4200},
    {"DCId":40,"DCDate":"2023-08-16T13:02:47.010000","DCNo":"DC-10042","OrderNo":"ORD-10016","OrderDate":"2023-08-16T18:32:40.529412","Customer":"rbz","Contact":"ilango","ContactNo":"9942410747","Items":2,"Vehicle":"TN 18 C 8033","Driver":"Shanmugaraj","Product":"M20","Quantity":5,"UnitPrice":5000},
    {"DCId":40,"DCDate":"2023-08-16T13:02:47.010000","DCNo":"DC-10042","OrderNo":"ORD-10016","OrderDate":"2023-08-16T18:32:40.529412","Customer":"rbz","Contact":"ilango","ContactNo":"9942410747","Items":2,"Vehicle":"TN 18 C 8033","Driver":"Shanmugaraj","Product":"M30","Quantity":5,"UnitPrice":5800},
    {"DCId":38,"DCDate":"2023-08-05T03:37:50.674000","DCNo":"DC-10040","OrderNo":"ORD-10015","OrderDate":"2023-08-09T17:31:18.020968","Customer":"poulraj","Contact":"poulraj","ContactNo":"9842899120","Items":1,"Vehicle":"TN 39 DX 2599","Driver":"Balasubramani","Product":"M10","Quantity":7,"UnitPrice":4200}
  ];

  const [dcList, setDcList] = useState(dcArr);
    const fetchData = async () => {
  // const fetchData = () => async() => {
    // var res = await axios.get(client.domain + `/getDeliveryChallans?From=${passedValue.order_id}`, { withCredentials: false });
      var reqParams = {
        From: '2023-08-09',
        To: '2023-08-11'
      };
    try { var aIVal = localStorage.getItem("_aI");
    var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
      
    const response = await axios.get(client.domain + `/mis/oms/getDeliveryChallans?From=2023-08-09&To=2023-08-11`, reqParams,{ headers }, { withCredentials: false });
    console.log("Response: ", response);
    }
    catch(error) {
      console.log("ERROR occured : ", error);
    }
    //console.log("RESPONSE : ",response)
    //setDeliveryChallans(response.data.detail.data);

    /*
    axios.get('/mis/oms/getDeliveryChallans', {
      params: {
        From: fromDate,
        To: toDate
      }
    })
    .then(response => {
      if (response.data && response.data.detail.status === 'SUCCESS') {
        setChallanData(response.data.detail.data);
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
    */
  };

  useEffect(() => {
    if (fromDate || toDate) {
      fetchData();
    }
    setToRender(true);
  }, [fromDate, toDate]);

  dcList.map(dcInd => {
    console.log("Dc IND :",dcInd);
  })
  return (
    <Container>
      <SidebarMain />
      { toRender ?
        
      <div>
        {/* <Row>
          <Col xs={1} md={1} sm={1}></Col>
        </Row> */}
        <br />
        <Row>
          <Col xs={12} md={12} sm={12} className="dc-heading-invoice">
            <div>DeliveryChallan</div>
          </Col>
        </Row>
        <Form>
   <Form.Group style={{ display: 'flex', justifyContent: 'space-between' }}>
       <div style={{ flex: 1, marginRight: '100px' }}>
         <Form.Label>From</Form.Label>
         <Form.Control type="date" value={fromDate} onChange={e => setFromDate(e.target.value)} />
         </div>
         <div style={{ flex: 1, marginLeft: '100px' }}>
           <Form.Label>To</Form.Label>
           <Form.Control type="date" value={toDate} onChange={e => setToDate(e.target.value)} />
           </div>
           </Form.Group><br></br>
           </Form>
    
        <Accordion>
          <Accordion.Item eventKey="header" >
          <Table className="custom-table">
      <thead>
        <tr>
          <th>#</th>
          <th>DCNo</th>
          <th>DCDate</th>
          <th>Customer</th>
          <th>Product</th>
          <th>Quantity</th>
          <th>Price</th>
        </tr>
      </thead>
    </Table>
          </Accordion.Item>
  
          {dcList.map((challan, index) => (
            <Accordion.Item key={index} eventKey={index.toString()}>
              <Accordion.Header style={{height:'85px'}}>
                <Table className="custom-table" 
                style={{
            width: '100%',
            textAlign: 'left',
            borderCollapse: 'separate', 
            borderSpacing: '10px',
          }}>
                  <tbody>
                    <tr>
                      <td >{index + 1}</td>
                      <td>{challan.DCNo}</td>
                      <td>{new Date(challan.DCDate).toISOString().split('T')[0]}</td>
                      <td>{challan.Customer}</td>
                      <td>{challan.Product}</td>
                      <td>{challan.Quantity}</td>
                      <td>{challan.UnitPrice}</td>
                    </tr>
                  </tbody>
                </Table>
              </Accordion.Header>
        <Accordion.Body style={{backgroundColor:'lightgrey'}}>
          <Table style={{backgroundColor:'lightgrey'}}>
            <thead>
                  <tr>
                    <th>OrderNo</th>
                    <th>OrderDate</th>
                    <th>ContactNo</th>
                    <th>Vehicle</th>
                    <th>Driver</th>
                    <th>Items</th>
                  </tr>
                  </thead>
              <tbody >
                    <tr>
                      <td>{challan.OrderNo}</td>
                      <td>{new Date(challan.OrderDate).toISOString().split('T')[0]}</td>
                      <td>{challan.ContactNo}</td>
                      <td>{challan.Vehicle}</td>
                      <td>{challan.Driver}</td>
                      <td>{challan.Items}</td>
                    </tr>
                  </tbody>
                </Table>              
                </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
      : 
      <div className="container-spinner">
     <div className="vertical-center-spinner">
      {/*<Button variant="primary" disabled>*/}
      <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
         <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{ width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
             <span className="visually-hidden">Loading...</span>
           </Spinner>

      {/*</Button>*/}
      </div>
      </div>
     }
      
    </Container>
  );
  
}

export default ListDCsOld;


