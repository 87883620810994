import React, { Component, Fragment } from 'react';
import {PDFViewer} from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

import Delivery from '../components/reports/Delivery';
import invoice from '../data/invoice';



// Create Document Component
const DCDocument = (props) => {
  console.log("FULL PROPS : ", props);
  console.log("props ACTION : ", props.action)
  console.log("PROPS DATA COMPANY : ", props.dc_data.company_info);
  console.log("PROPS DATA DC : ", props.dc_data.dc_info);
  //console.log("PROPS DATA ORDER : ", props.dc_data.order_info);
  return (
    <Fragment>
      <Delivery invoice={invoice} dcLoad={props.dc_data} action={props.action}/>
    </Fragment>
  )
}

export default DCDocument;
