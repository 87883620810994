import React, { useState, useEffect, useRef,useReducer } from "react";
import { Container, Row, Col, Card, Form, Button,Modal,Spinner, Image } from "react-bootstrap";
import { withRouter } from "react-router";
import Table from "react-bootstrap/Table";
//import Sidebar from './Sidebar';
import Sidebar2 from "./Sidebar2";
import SidebarMain from "./SidebarMain";
import Footer from "./Footer";
//import Topmenu from "./Topmenu";
import OrderDetails from "./OrderDetails";
import "../Dashboard.css";

//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";

import { useNavigate } from "react-router-dom";

import * as Icon from "react-bootstrap-icons";
import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsCardText,
  BsFillPrinterFill
} from "react-icons/bs";

import { BsFillEnvelopePlusFill } from "react-icons/bs";

import axios from "axios";
import { client } from "../config/Config";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';



import MenuEms from "./MenuEms";

const ListEmployees = (props) => {
  //function ListUsers(props) {
  const initialized = useRef(false);
  const navigate = useNavigate();
    const [checkedState, setCheckedState] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  var employeeArr = [
    {
      id: 1,
      employee_no: "KMC001",
      employee_name: "Ramesh",
      designation: "Manager",
      contact_no_primary: 9742854720,
      active: false,
    },
    {
      id: 2,
      employee_no: "KMC002",
      employee_name: "Marudhachalam",
      designation: "Owner",
      contact_no_primary: 8745673294,
      active: false,
    },
    {
      id: 3,
      empployee_no: "KMC003",
      employee_name: "Kalidoss",
      designation: "Supervisor",
      contact_no_primary: 9765784368,
      active: false,
    },
    {
      id: 4,
      emp_id: "KMC004",
      name: "Boopathy",
      designation: "Accounts",
      mobile: 9765784368,
      active: false,
    }
  ];
  const [checked, setChecked] = useState(false);
  const [employeeList, setEmployeeList] = useState(employeeArr);
  const [toRender, setToRender] = useState(false);
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("Please login again");
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );

  const [selectedId, setSelectedId] = useState(-1);

  const [showDialog, setShowDialog] = useState(false);
  //navigate("/addUpdateEmployees");
  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const handleShowDialog = () => {
    setShowDialog(true);
  };
  const updateEmployeeStatus = async (selectedId) => {
    var selectedObj = employeeList[selectedId];
    let reqStatusUpdatePayload = {
      id: selectedObj.id,
      is_active: !selectedObj.is_active,
    };

    var headers = client.headers;
    console.log("payload:", reqStatusUpdatePayload);

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
      var res = await axios.post(
        client.domain + "/hrms/activateEmployee",
        reqStatusUpdatePayload,
        { headers },
        { withCredentials: false }
      );
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        //console.log("Response message from server : ", res.data.detail.res_data);
        //console.log("Response message from server userInfo : ", res.data.detail.res_data.user_info);
        //console.log("Response message from server aI : ", res.data.detail.res_data.aI);
        // Remove login session from localstorage
        //localStorage.removeItem("username");
        //localStorage.removeItem("_aI")
        //navigate("/auth");

        var itemSelectedUpdate = employeeList[selectedId];
        itemSelectedUpdate.is_active = !itemSelectedUpdate.is_active;
        console.log(
          "<<<<<<<<<<<<<<<<< ITEM SELECTED MODIFIED ********** ",
          itemSelectedUpdate
        );

        const newitems = employeeList.slice();
        newitems[selectedId] = itemSelectedUpdate;
        setEmployeeList(newitems);

        setShowDialog(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle("LOGIN FAILED!");
          //setShow(true);
        }
      }
    }
    setShowDialog(false);
  };
  const handleSwitchAction = () => {
    console.log("user confirmed to enable or disable ...");
    console.log("user selected id for action is : ", selectedId);

    // call update API
    updateEmployeeStatus(selectedId);
  };

  const actionEditHandle = (e, data) => {
    console.log("Handle action edit Clicked : ", e);
    //console.log("Handle Clicked edit DATA : ", data);
    console.log("Handle action edit Clicked target : ", e.target);
    console.log(
      "Handle action edit Clicked target Current : ",
      e.currentTarget
    );
    console.log(
      "Handle action edit Clicked target Current id : ",
      e.currentTarget.id
    );
    console.log("Handle action edit Clicked target ID : ", e.target.id);
    console.log("Handle action edit Clicked target NAME : ", e.target.name);
  };

  const getEmployeeList = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      var headers = client.headers;

      var res = await axios.get(
        client.domain + "/hrms/getAllEmployees",
        { headers },
        { withCredentials: false }
      );
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", 0);
        setEmployeeList(res.data.detail.data);
        setShowMsgTitle("Employee Info Added Successfully");
        setToRender(true);
        //navigate("/dashboard");
        //setDcReportData(res.data.detail.res_data);
        //setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          setShowMsg(error.response.data.detail);
          setShowMsgTitle("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
  };

  const handleStateChange = (e) => {
    console.log("clicked Value :", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);

    var currentId = name.substring(name.lastIndexOf("-") + 1);
    var nameOfElement = name.substring(0, name.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);
  };
  const SwitchComponent = () => {};

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::ListEmployees called ##########################"
      );
      getEmployeeList();
      initialized.current = true;
    }
  }, []);

  const handleAdd = async (e) => {
    console.log("handle add employees called : ", e);
    navigate("/addUpdateEmployee", {
      state: { passed_element: [], action: "add" },
    });
  };

  const handleView = async (e) => {
    console.log("handle edit employees called : ", e);
    console.log(
      "handle edit employees currentTarget called : ",
      e.currentTarget
    );
    console.log("handle edit employees target called : ", e.target);
    var selectedTagId = e.currentTarget.id;
    console.log("SELECTED TAG ID: ", selectedTagId);
    if (selectedTagId) {
      var currentId = selectedTagId.substring(
        selectedTagId.lastIndexOf("-") + 1
      );
      var nameOfElement = selectedTagId.substring(
        0,
        selectedTagId.lastIndexOf("-")
      );
      console.log("Name of element VIEW: ", nameOfElement);
      console.log("Current id VIEW : ", currentId);

      console.log(
        "<<<<<<<<<<<<<<<<< ITEM SELECTED VIEW ********** ",
        employeeList[currentId]
      );
      navigate("/addUpdateEmployee", {
        state: {
          passed_element: employeeList[currentId],
          action: "view",
        },
      });
    } 
  };

  const handleOnChange = async (e) => {
    console.log("handle on change called : ", e);

    var selectedTagId = e.target.id;
    console.log("SELECTED TAG ID: ", selectedTagId);
    var currentId = selectedTagId.substring(selectedTagId.lastIndexOf("-") + 1);
    var nameOfElement = selectedTagId.substring(
      0,
      selectedTagId.lastIndexOf("-")
    );
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    console.log(
      "<<<<<<<<<<<<<<<<< ITEM SELECTED ********** ",
      employeeList[currentId]
    );

    setSelectedId(currentId);

    setShowDialog(true);
  };

  const handleEmployeePrint = async (e) => {
    //navigate("/printCustomer")
     
     var urlStr = window.location.href;
     var paramsTmp = urlStr.substring(
       urlStr.lastIndexOf(client.urlDelimiter) + 1
     );
     var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));
 
     console.log("CALLED URL : ", urlTmp);
     console.log("CALLED PAGE : ", paramsTmp);
 
     var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printEmployee?`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
     winPrint.moveTo(0,0);
     winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
     
     
   };
  
  return (
    <Container>
      <SidebarMain />
      { toRender ?  
        <form>
        <Row>
          <Col xs={6} md={6} sm={6}>
            <h4 className="text-left">Employees</h4>
          </Col>
          <Col xs={6} md={6} sm={6} className="d-flex justify-content-end float-end">
          <Button id="employeePrint" name = "employeePrint"  onClick={(e)  => handleEmployeePrint(e)}>
                <BsFillPrinterFill
                 size={18}
                 style={{ color: "white", cursor: "pointer" }}
                 name={`print-employee-icon`}
                 id={`print-employee-icon`}
                 />
                 </Button>
                 &nbsp;&nbsp;&nbsp;&nbsp;
            <Button
             variant="warning"
            id="add-btn-id"
            name="add-btn-name"
            className=" float-end"
            onClick={(e) => handleAdd(e)}
            >
              New Employee
            </Button>
          </Col>
        </Row>
        <hr align="center" />

        <Table striped bordered hover>
          <thead>
            <tr className="table-primary">
              <th className="table-row-heading" style={{textAlign: "center"}}>S.No</th>
              <th className="table-row-heading" style={{textAlign: "center"}}> Employee No</th>
              <th className="table-row-heading" style={{textAlign: "center"}}> Employee Name</th>
              <th className="table-row-heading" style={{textAlign: "center"}}> Designation</th>
              <th className="table-row-heading" style={{textAlign: "center"}}> Mobile Number</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Active</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Details</th>
            </tr>
          </thead>
          <tbody>
            {employeeList.map((employee, idx) => (
              <tr key={`employee-table-row-id-${idx}`}>
                <td key={`employee-list-item-key-sno-${idx}`} style={{textAlign: "right"}}>{idx + 1}</td>
                <td key={`employee-list-item-key-employee_no${idx}`} style={{textAlign: "right"}}>{employee.employee_no}</td>
                <td key={`employee-list-item-key-employee_name${idx}`} style={{textAlign: "left"}}>{employee.employee_name}</td>
                <td key={`employee-list-item-key-design-${idx}`} style={{textAlign: "left"}}>{employee.designation}</td>
                <td key={`employee-list-contact_no_primary-key-${idx}`} style={{textAlign: "right"}}>{employee.contact_no_primary}</td>
                <td style={{textAlign: "center"}}>
                  <Form.Check // prettier-ignore
                    key={`employee-switch-key-${idx}`}
                    type="switch"
                    id={`employee-switch-${idx}`}
                    onChange={(e) => handleOnChange(e)}
                    checked={employee.is_active}
                  />
                </td>
                <td style={{textAlign: "center"}}>
                  <BsCardText
                    key={`view-icon-employee-key-${idx}`}
                    size={28}
                    style={{ color: "#0D6EFD", cursor: "pointer" }}
                    onClick={(e) => handleView(e)}
                    name={`view-icon-employee-name-${idx}`}
                    id={`view-icon-employee-id-${idx}`}
                    designation={`view-icon-employee-designation-${idx}`}
                    mobile={`view-icon-employee-mobile-${idx}`}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
       
      <Modal
        show={showDialog}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Activae / In-Activate this Employee? Click OK to proceed...
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSwitchAction}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
       </form>
       : 
       <div className="container-spinner">
      <div className="vertical-center-spinner">
       {/*<Button variant="primary" disabled>*/}
       <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
       {/*</Button>*/}
       </div>
       </div>
 }
     </Container>
  );
};
//const Dashboard = withRouter(Dash);
export default ListEmployees;
