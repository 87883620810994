import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  Spinner,
} from "react-bootstrap";

import SidebarMain from "../SidebarMain";
import Table from "react-bootstrap/Table";
import { useNavigate,useLocation } from "react-router-dom";
import Badge from "react-bootstrap/Badge";

import { client } from "../../config/Config";
import axios from "axios";

import { BsArrowLeftCircle, BsFileEarmarkPdf, BsPrinter, BsDownload } from "react-icons/bs";

const StockReport = (props) => {
 const initialized = useRef(false);
 const { state } = useLocation();
 const navigate = useNavigate();
 const [reportData, setReportData] = useState({});
 //const [reportList, setReportList] = useState([]);
  const [toRender, setToRender] = useState(false);
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("Please login again");
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );
  let initialItems = [];
  var intialValues = {
    productId: "",
  };
 
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [validateError, setValidateError] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);

  var validateErr = false;
  const getReportList = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      
      var res = await axios.get(
        client.domain + "/ims/getReportCurrentStock",
        { headers },
        { withCredentials: false }
      );

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getReportList : ", res.data.detail.data);
        setReportData(res.data.detail.data);

        setShowMsgTitle("Report Info Added Successfully");
        setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          setShowMsg(error.response.data.detail);
          setShowMsgTitle("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
  };


    
    useEffect(() => {
        console.log("UseEffect called ...");
        getReportList();
      
         initialized.current = true;
        // Api call
        // /ims/getReportStock
    }, []);
    
    const handlePrintStock = async(e) => {
        console.log("Handle Print Stock called ... ");
        e.preventDefault();
      console.log("Handle action print Clicked : ", e);

      console.log("CALLED CURRENT PAGE OrderDetails PRINT DOCUMENT : ", window.location.href);

      console.log("CALLED window locaion OrderDetails PRINT DOCUMENT : ", window.location);
      
      console.log("LOCATION PATH DIRECTORY PRINT DOCUMENT : ", __dirname);

      var urlStr = window.location.href;
      var paramsTmp = urlStr.substring(urlStr.lastIndexOf('?') + 1);
      var urlTmp = urlStr.substring(0, urlStr.lastIndexOf('?'));

      console.log("CALLED URL : ", urlTmp);
      console.log("CALLED PAGE : ", paramsTmp);

      window.open(`${urlTmp}${client.urlDelimiter}/printStock?company_id=${5}`);
    }

    const handleBack = () => {
        console.log("Handle back called ... ");
    }

    const handleDownload = () => {
        console.log("Handle download clicked ...");
    }

    return (
        <div className="container">
        <SidebarMain />
        { toRender ?  
        <Form>
           
          <Row>
          <Col xs={4} md={4} sm={4} lg={4}>
          <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>{reportData.report_title}</span>
         </Col>
          
            <Col
              xs={8}
              md={8}
              sm={8}
              lg={8}
              className="d-flex justify-content-end float-end"
            >
              <Button variant="warning" className="btn btn-warning btn-md">
                <BsArrowLeftCircle
                  key={`back-btn-dc-key`}
                  size={16}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleBack(e)}
                  name={`back-icon-dc-name`}
                  id={`back-icon-dc-id`}
                />
              </Button>
              &nbsp;&nbsp;
              <Button variant="success" className="btn btn-success btn-md">
              <BsDownload
              key={`downlod-btn-stock`}
              size={16}
              style={{ cursor: "pointer" }}
              onClick={(e) => handleDownload(e)}
              name={`download-icon-stock-name`}
              id={`download-icon-stock-id`}
              />
              </Button>
              &nbsp;&nbsp;
              <Button variant="danger" className="btn btn-danger btn-md">
              <BsPrinter
              key={`print-btn-stock`}
              size={16}
              style={{ cursor: "pointer" }}
              onClick={(e) => handlePrintStock(e)}
              name={`print-icon-stock-name`}
              id={`print-icon-stock-id`}
              />
              </Button>
            </Col>
          </Row>
          <hr align="center" />
          <div className="ui-divider"></div>
          <Row>
          <Col xs={4} md={4} sm={4} lg={4}>
            { reportData.stock_ason_date.title &&
            <Badge pill bg="secondary">
              <h6> {reportData.stock_ason_date.title} </h6>
              </Badge>
            }
            </Col>
          </Row>
          <p></p>
          <Table striped bordered hover>
            <thead>
              <tr className="table-primary">
                <th className="table-row-heading">#</th>
                <th className="table-row-heading"> Product Name</th>
                <th className="table-row-heading">Stock Open</th>
                <th className="table-row-heading">Stock In</th>
                <th className="table-row-heading">Stock Out</th>
                <th className="table-row-heading">Stock Available</th>
                <th className="table-row-heading">Stock Minimum</th>
                <th className="table-row-heading">Stock Maximum</th>
              </tr>
            </thead>

            <tbody>
              {("stock_ason_date" in reportData && 'data' in reportData.stock_ason_date) ? 
              reportData.stock_ason_date.data.map((stock, idx) => (
                <tr key={`doc-num-row-key-${idx}`}>
                  <td>{idx + 1}</td>
                  <td>{stock.product_name}</td>
                  <td style={{textAlign: "right"}} >{stock.stock_open}</td>
                  <td style={{textAlign: "right"}}>{stock.stock_in}</td>
                  <td style={{textAlign: "right"}}>{stock.stock_out}</td>
                  <td style={{textAlign: "right"}}>{stock.stock_available}</td>
                  <td style={{textAlign: "right"}}>{stock.stock_min}</td>
                  <td style={{textAlign: "right"}}>{stock.stock_max}</td>
                  </tr>
                   )) : 
                   <tr><td style={{textAlign: 'center', alignItems: 'center'}} colSpan={8}>No data</td></tr>
              }
            </tbody>
          </Table>
         
          <Row>
            <Col xs={4} md={4} sm={4} lg={4}>
              { reportData.stock_today.title &&
            <Badge pill bg="secondary">
              <h6> {reportData.stock_today.title}</h6>
              </Badge>
              }
            </Col>
            </Row>
            <p></p>
            <Table striped bordered hover>
            <thead>
              <tr className="table-primary">
                <th className="table-row-heading">#</th>
                <th className="table-row-heading">Product Name</th>
                <th className="table-row-heading">Stock In</th>
                <th className="table-row-heading">Stock Out</th>
                </tr>
            </thead>

            <tbody>
              {("stock_today" in reportData && 'data' in reportData.stock_today) ? 
              reportData.stock_today.data.map((stock, idx) => (
                <tr key={`doc-num-row-key-${idx}`}>
                  <td  width="50">{idx + 1}</td>
                  <td width="30">{stock.product_name}</td>
                  <td  width="30"style={{textAlign: "right"}}>{stock.stock_in}</td>
                  <td  width="30"style={{textAlign: "right"}}>{stock.stock_out}</td>
                  </tr>
              )) : 
              <tr><td style={{textAlign: 'center', alignItems: 'center'}} colSpan={4}>No data</td></tr>
            }
            </tbody>
            </Table>
          
            <Row>
            <Col xs={4} md={4} sm={4} lg={4}>
              { reportData.stock_po_dc.title &&
            <Badge pill bg="secondary">
              <h6>{reportData.stock_po_dc.title} </h6>
              </Badge>
              }
            </Col>
            </Row>
            <p></p>
            <Table striped bordered hover>
            <thead>
              <tr className="table-primary">
                <th className="table-row-heading">#</th>
                <th className="table-row-heading"> Product Name</th>
                <th className="table-row-heading">Stock In</th>
                <th className="table-row-heading">Stock Out</th>
                <th className="table-row-heading">DC No</th>
                <th className="table-row-heading">PO DC Date</th>

                </tr>
            </thead>

            <tbody>
              {'stock_po_dc' in reportData && 'data' in reportData.stock_po_dc ?
               reportData.stock_po_dc.data.map((stock, idx) => (
                <tr key={`doc-num-row-key-${idx}`}>
                  <td>{idx + 1}</td>
                  <td>{stock.product_name}</td>
                  <td style={{textAlign: "right"}}>{stock.stock_in}</td>
                  <td style={{textAlign: "right"}}>{stock.stock_out}</td>
                  <td style={{textAlign: "right"}}>{stock.dc_no}</td>
                  <td style={{textAlign: "right"}}>{stock.po_dc_date}</td>
                  </tr>
                   )) : 
                   <tr><td style={{textAlign: 'center', alignItems: 'center'}} colSpan={6}>No data</td></tr>
              }
            </tbody>
            </Table>
            <Row>
            <Col xs={4} md={4} sm={4} lg={4}>
              { reportData.stock_so_dc.title &&
            <Badge pill bg="secondary">
              <h6>{reportData.stock_so_dc.title} </h6>
              </Badge>
              }
            </Col>
            </Row>
            <p></p>
            <Table striped bordered hover>
            <thead>
              <tr className="table-primary">
                <th className="table-row-heading">#</th>
                <th className="table-row-heading"> Product Name</th>
                <th className="table-row-heading">Stock In</th>
                <th className="table-row-heading">Stock Out</th>
                <th className="table-row-heading">DC No</th>
                <th className="table-row-heading">PO DC Date</th>

                </tr>
            </thead>

            <tbody>
              {"stock_so_dc" in reportData && 'data' in reportData.stock_so_dc ?
               reportData.stock_so_dc.data.map((stock, idx) => (
                <tr key={`doc-num-row-key-${idx}`}>
                  <td>{idx + 1}</td>
                  <td>{stock.product_name}</td>
                  <td style={{textAlign: "right"}}>{stock.stock_in}</td>
                  <td style={{textAlign: "right"}}>{stock.stock_out}</td>
                  <td style={{textAlign: "right"}}>{stock.dc_no}</td>
                  <td style={{textAlign: "right"}}>{stock.po_dc_date}</td>
                  </tr>
                   )) : 
                   <tr><td style={{textAlign: 'center', alignItems: 'center'}} colSpan={6}>No data</td></tr>
              }
            </tbody>
            </Table>
          </Form>
           : 
           <div className="container-spinner">
          <div className="vertical-center-spinner">
           <Button variant="primary" disabled>
             <Spinner
               as="span"
               animation="grow"
               size="sm"
               role="status"
               aria-hidden="true"
             />
             Loading...
           </Button>
           </div>
           </div>
     }
          </div>
    );
};

export default StockReport;