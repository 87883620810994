import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'

const tableRowsCount = 3;
const borderColor = '#0d0d0d'
const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 14,
        borderWidth: 1,
        borderColor: borderColor  
    },
});

  //const InvoiceItemsTable = (props) => (
  const InvoiceItemsTable = (props) => {
    console.log("PROPS at InvoiceItemsTable : ", props);
    let tableRowsCountTmp = props.dcLoad.dc_item_list.length + 1;
    return (
    <View style={styles.tableContainer}>
        <InvoiceTableHeader />
        <InvoiceTableRow items={props.dcLoad.dc_item_list} invoice={props.dcLoad}/>
        <InvoiceTableBlankSpace rowsCount={ tableRowsCountTmp - props.dcLoad.dc_item_list.length} />
        <InvoiceTableFooter items={props.dcLoad.dc_item_list} invoice={props.dcLoad}/>
    </View>
  );
  }
  
  export default InvoiceItemsTable