import React, { useState, useEffect, useRef, Fragment } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    Form,
    Button,
    Table,
    Spinner,
    Image
} from "react-bootstrap";
import { withRouter } from "react-router";
//import Sidebar from './Sidebar';
import Sidebar2 from "./Sidebar2";
import Footer from "./Footer";
//import Topmenu from "./Topmenu";
import OrderDetails from "./OrderDetails";
import "../Dashboard.css";

//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";

import { useNavigate } from "react-router-dom";

import * as Icon from "react-bootstrap-icons";
import axios from "axios";
import { client } from "../config/Config";
import Select from "react-select";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';



import {
    BsFillTrashFill,
    BsPencilSquare,
    BsFileRuled,
    BsArrowLeftCircle,
    BsCardText,
    BsReceipt,
    BsFillPrinterFill,
} from "react-icons/bs";

import { AiOutlineOrderedList } from "react-icons/ai";

import MenuOms from "./MenuOms";

import rmcBlueIcon from "../assets/images/ready_mix_blue.png";
import SidebarMain from "./SidebarMain";

import { getDistrictList, getStateList, getProductApiCall, getProductBuy, getProductsSell, formatDate, formatDateIndian } from "./utils";

import { OS_RECEIPT_FOR_NDAYS } from "../config/Config";

const ListEmpPayments = (props) => {

  const intialValues = {
    start_date: formatDate(new Date(new Date().setDate(new Date().getDate() - OS_RECEIPT_FOR_NDAYS))),
    end_date: formatDate(new Date())
};


    const initialized = useRef(false);
    const [toRender, setToRender] = useState(false);
   /*
    let paymentArr = [
     
          {
            sno: "4",
            payment_number: "PAYT004",
            payment_date: "07-08-2024",
            paid_to: "DCR Readymix",
            amount: "8844",
            payment_mode: "Cash",
            ref_number: "1224",
            pay_received_by:"Ramesh",
            towards: "for readymix",
            addl_notes: "testing",
            },
           
            {
              sno: "5",
              payment_number: "PAYT004",
              payment_date: "07-08-2024",
              paid_to: "KMC Readymix",
              amount: "5678",
              payment_mode: "Cash",
              ref_number: "1888",
              pay_received_by:"Raj",
              towards: "for kmc concrete",
              addl_notes: "",
              },
    ]
    */

    const [optEmployee, setOptEmployee] = useState([]);
    const [employeeIdSelect, setEmployeeIdSelect] = useState({});
    const [paymentList, setPaymentList] = useState([]);
    //const [receiptListFull, setReceiptListFull] = useState([]);
    const [formValues, setFormValues] = useState(intialValues);
    const navigate = useNavigate();

    const getEmployeeListDropdown = async () => {
        console.log("getEmployeeListDropdown method called ...");
        var retState = false;
        
        try {
          var aIVal = localStorage.getItem("_aI");
          var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
          var res = await axios.get(
            client.domain +  "/hrms/getAllEmployees",
            { headers },
            { withCredentials: false }
          );
          
          console.log(
            "Response from server getEmployeeList : ",
            res.data.detail.data
          );
          if (res.data.detail.status === "SUCCESS") {
            console.log(
              "Response message from server getEmployeeList : ",
              res.data.detail.data
              
            );
            
            var employeeArr = [];
            var empAll = { value: 0, label: 'All Employee'};
            employeeArr.push(empAll);
            res.data.detail.data.map((itemEmployee, idx) => {
              console.log("Ind val : ", itemEmployee);
              var employeeInd = {
                value: itemEmployee.id,
                label: itemEmployee.employee_name,
              };
              console.log("In Employee modified : ", employeeInd);
              employeeArr.push(employeeInd);
            });
    
            console.log("Mod Full VALUE : ", employeeArr);
            retState = true;
          }
         
        } catch (error) {
          console.log("FULL ERROR : ", error);
          if (error.code === "ERR_NETWORK") {
            console.log(
              "Not able to reach target server please try after sometime"
            );
          } else {
            console.log("ERROR : ", error.response.data.detail);
            if (error.response.data.detail.status === "FAILED") {
              console.log(
                "Request failed due to ",
                error.response.data.detail.data
              );
             
            }
          }
        }
        return [retState, employeeArr];
      };

      
      const getPaymentList = async(paid_to, start_date, end_date) => {
        console.log("getSupplierList method called ...");
        console.log("start date   : ", start_date);
        console.log("end date     : ", end_date);
        console.log("suupplier id  : ", paid_to);
        try {
          var aIVal = localStorage.getItem("_aI");
          console.log("Authorization token : ", aIVal);
    
          var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
    
          console.log("Headers : ", headers);
    
          
          var res = await axios.get(client.domain + `/getAllEmployeePaymentsByFilter?paid_to=${paid_to}&start_date=${start_date}&end_date=${end_date}`, {headers}, {
            withCredentials: false,
          });
          //.then((res) => {
          //console.log("ADD New user res:", res);
          //localStorage.setItem('_token', res.data.res_data.token);
          console.log("Response from server getPaymentList : ", res.data.detail);
          if (res.data.detail.status === "SUCCESS") {
            console.log("Response message from server getPaymentList : ", res.data.detail.data);
           if (res.data.detail.data.length > 0) {
              setPaymentList(res.data.detail.data);
              //setReceiptListFull(res.data.detail.data);
            }
            setToRender(true);
          }
          
        } catch (error) {
          console.log("FULL ERROR : ", error);
          if (error.code === "ERR_NETWORK") {
            console.log("Not able to reach target server please try after sometime");
        setToRender(true);
          } else {
            console.log("ERROR : ", error.response.data.detail);
            if (error.response.data.detail.status === "FAILED") {
              console.log("Request failed due to ",error.response.data.detail.res_data);
            }
          }
        }
        setToRender(true);
      };
      
     
      

    useEffect(() => {
        if (!initialized.current) {
            console.log("*************** useEffect called ##########################");

            (async () => {
                var [retState, employeeArr] = await getEmployeeListDropdown();
                if ( retState ) {
                    setOptEmployee(employeeArr);
                    setEmployeeIdSelect(employeeArr[0]);
                }
                
                if ( retState ) {
                  await getPaymentList(employeeArr[0].value, formValues.start_date, formValues.end_date);
                  setToRender(true);
                }
                else {
                  await getPaymentList(0, formValues.start_date, formValues.end_date);
                  setToRender(true);
                }
                
            })();

            initialized.current = true;
        }
    }, []);



    

    

    const handleEmployeeSelect = async (e) => {
        console.log("Handle Employee select called : ", e);

        setEmployeeIdSelect(e);
    }

    const onChangeDatePicker = async (e) => {
        console.log("onchangeDatePicker called : ", e);
        console.log("onChangeDatePicker target called ...", e.target);
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    }

    const handleDateSearch = async (e) => {
        console.log("handleDateSearch called : ", e);
        //await getReceiptList(customerIdSelect.value, formValues.start_date, formValues.end_date);
        await getPaymentList(employeeIdSelect.value, formValues.start_date, formValues.end_date);
    }

    const handlePaymentAdd = async (e) => {
        console.log("handleClickAddOrder called : ", e);

        navigate(`/addUpdateEmpPayment`, {
            state: { passed_element: [], action: "add" },
          });
    }
   


  const handlepaymentView = async (e) => {
    console.log("handle edit customers called : ", e);
    console.log(
      "handle edit customers currentTarget called : ",
      e.currentTarget
    );
    console.log("handle edit customers target called : ", e.target);
    var selectedTagId = e.currentTarget.id;
    console.log("SELECTED TAG ID: ", selectedTagId);
    if (selectedTagId) {
      var currentId = selectedTagId.substring(
        selectedTagId.lastIndexOf("-") + 1
      );
      var nameOfElement = selectedTagId.substring(
        0,
        selectedTagId.lastIndexOf("-")
      );
      console.log("Name of element VIEW: ", nameOfElement);
      console.log("Current id VIEW : ", currentId);

      console.log(
        "<<<<<<<<<<<<<<<<< ITEM SELECTED VIEW ********** ",
        paymentList[currentId]
      );
      navigate("/addUpdateEmpPayment", {
        state: {
          passed_element: paymentList[currentId],
          action: "view",
        },
      });
    }
  };

  const handlePaymentPrint = async (e) => {
    //setPrintAction(true);
    //setFormValues({ ...formValues, summaryPrint: "ONLY"});
    //setPrintDialog(true);
    //navigate("/printFullReceipt");
    //console.log("Handle Edit clicked FULL : ", e);
    //console.log("Handle Edit clicked FULL currentTarget : ", e.currentTarget);
    //console.log("Handle Edit clicked FULL target : ", e.target);

    //const { name, value } = e.currentTarget;
    //var currentId = name.substring(name.lastIndexOf("-") + 1);
    //console.log("SELECTED DC ROW ID FOR PRINT : ", currentId);
    //setSelectedDCRowForPrint(currentId);
    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);

    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printEmpPaymentList?paid_to=${employeeIdSelect.value}&start_date=${formValues.start_date}&end_date=${formValues.end_date}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0,0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
  };

    return (
        <Container>
            <SidebarMain />
            { toRender ?  
              <form>
                    <Row>

                        <Col xs={3} md={3} sm={3} lg={3}>
                            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem" }}>Employee Payments</span>
                        </Col>
                        <Col xs={7} md={7} sm={7} lg={7} style={{paddingLeft: "10px"}}>
                            <Row>
                                <Col>
                                    <Select
                                        inline
                                        id="employee"
                                        name="employee"
                                        value={{
                                            value: employeeIdSelect.value,
                                            label: employeeIdSelect.label,
                                        }}
                                        options={optEmployee}
                                        defaultValue={{
                                            value: employeeIdSelect.value,
                                            label: employeeIdSelect.label,
                                        }}
                                        onChange={handleEmployeeSelect}
                                    />
                                </Col>
                                

                            

                                <Col>
                                    <Form.Control
                                        type="date"
                                        name="start_date"
                                        placeholder="Start Date"
                                        size="md"
                                        value={formValues.start_date}
                                        onChange={onChangeDatePicker}
                                        style= {{paddingTop:"5px"}}
                                    
                                    //readOnly={isReadOnly}

                                    />

                                </Col>

                                <Col>
                                    <Form.Control
                                        type="date"
                                        name="end_date"
                                        placeholder="End Date"
                                        size="md"
                                        value={formValues.end_date}
                                        onChange={onChangeDatePicker}
                                      
                                    //readOnly={isReadOnly}

                                    />
                                </Col>

                                <Col>
                            <Button id="search-order-id"
                                size={"sm"}
                                variant="success"
                                style={{paddingTop: "7px", textAlign: "center"}}
                                onClick={(e) => handleDateSearch(e)}>
                                Search
                            </Button>
                            </Col>

                            </Row>

                            

                            </Col>
                        {/*
                        <Col
                            xs={1}
                            md={1}
                            sm={1}
                            lg={1}
                            className="d-flex justify-content-end float-end"
                        >
                          
                          
                        </Col>
                                      */}
                                      
                        <Col xs={2}
                            md={2}
                            sm={2}
                            lg={2}
                            className="d-flex justify-content-end float-end">

                              <Button id="paymentPrint" name = "paymentPrint"  onClick={(e)  => handlePaymentPrint(e)}>
                              <BsFillPrinterFill
                              size={18}
                              style={{ color: "white", cursor: "pointer" }}
                              name={`print-payment-icon`}
                              id={`print-payment-icon`}
                              />
                            </Button>
                              &nbsp;&nbsp;&nbsp;&nbsp;

                            <Button id="add-payment-id"
                                size={"sm"}
                                variant="warning"
                                onClick={(e) => handlePaymentAdd(e)}>
                                New payment
                            </Button>
                        </Col>
                        

                    </Row>

                    <hr align="center" />

                    <Table striped bordered hover size="sm">
          <thead>
            <tr className="table-primary">
              <th className="table-row-heading" key={"order-list-heading-key"}>
                S.No
              </th>
              <th className="table-row-heading" style={{textAlign : "center", width:"8%"}}>Payment No</th>
              <th className="table-row-heading" style={{textAlign : "center",width:"10%"}}>Payment Date</th>
              <th className="table-row-heading" style={{textAlign : "center"}}>Paid To</th>
              <th className="table-row-heading" style={{textAlign : "center"}}>Amount</th>
              <th className="table-row-heading" style={{textAlign : "center"}}>Payment Mode</th>
              <th className="table-row-heading" style={{textAlign : "center"}}>Paid By</th>
              <th className="table-row-heading" style={{textAlign : "center"}}>Paid For</th>
              <th className="table-row-heading" style={{textAlign : "center"}}>Paid As</th>
              <th className="table-row-heading" style={{textAlign : "center"}}>Details</th>
            </tr>
          </thead>

          <tbody>
            {paymentList.map((paymentInd, idx) => (
              <tr key={`receipt-table-row-key-${idx}`}>
                
                <td style={{textAlign : "right", width: "40px"}}
                  key={`payment-table-row-key-sno-${idx}`}
                  id={`payment-table-row-id-sno-${idx}`}
                  name={`payment-table-row-name-sno-${idx}`}
                  
                >
                  {idx + 1}
                </td>

                <td
                  key={`payment-table-row-key-ono-${idx}`}
                  id={`payment-table-row-id-ono-${idx}`}
                  name={`payment-table-row-name-ono-${idx}`}
                  style= {{width: "100px", textAlign: "right"}}
                >
                  {paymentInd.payment_number}
                </td>

                <td
                  key={`payment-table-row-key-date-${idx}`}
                  id={`payment-table-row-id-date-${idx}`}
                  name={`payment-table-row-name-date-${idx}`}
                  style= {{width: "120px", textAlign: "left"}}
                >
                  {formatDateIndian(new Date(paymentInd.payment_date))}
                </td>

                <td style={{textAlign: "left"}}
                  key={`payment-table-row-key-paid-to-${idx}`}
                  id={`payment-table-row-id-paid-to-${idx}`}
                  name={`payment-table-row-name-paid-to-${idx}`}
                >
                  {paymentInd.paid_to_name}
                </td>
                

                <td
                  key={`payment-table-row-key-amount-${idx}`}
                  id={`payment-table-row-id-amount-${idx}`}
                  name={`payment-table-row-name-amount-${idx}`}
                  style={{textAlign: "right"}}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(paymentInd.amount).toFixed(2)))}
                </td>

                <td style={{textAlign: "left"}}
                  key={`payment-table-row-key-payment-mode-${idx}`}
                  id={`payment-table-row-id-payment-mode-${idx}`}
                  name={`payment-table-row-name-payment-mode-${idx}`}
                >
                  {paymentInd.payment_mode}
                </td>

                

                <td style={{textAlign: "left"}}
                  key={`payment-table-row-key-payment-by-${idx}`}
                  id={`payment-table-row-id-payment-by-${idx}`}
                  name={`payment-table-row-name-payment-by-${idx}`}
                >
                  {paymentInd.payment_by_name}
                </td>

                <td style={{textAlign: "left"}}
                  key={`payment-table-row-key-towards-${idx}`}
                  id={`payment-table-row-id-towards-${idx}`}
                  name={`payment-table-row-name-towards-${idx}`}
                  //style= {{width: "120px"}}
                >
                  {paymentInd.towards}
                </td>

                <td style={{textAlign: "left"}}
                  key={`payment-table-row-key-payment-type-${idx}`}
                  id={`payment-table-row-id-payment-type-${idx}`}
                  name={`payment-table-row-name-payment-type-${idx}`}
                >
                  {paymentInd.payment_type}
                </td>

                <td style={{textAlign: "center"}}
                  key={`payment-table-row-key-actions-${idx}`}
                  id={`payment-table-row-id-actions-${idx}`}
                  name={`payment-table-row-name-actions-${idx}`}
                >
                  <Button
                    variant="light"
                    size="sm"
                    onClick={(e) => handlepaymentView(e)}
                    name={`payment-edit-icon-${idx}`}
                    id={`payment-edit-icon-${idx}`}
                  >
                    <BsCardText
                    size={28}
                    style={{ color: "#0d6efd", cursor: "pointer" }}
                    name={`payment-view-icon-${idx}`}
                    id={`payment-view-icon-${idx}`}
                    />
                  </Button>
                </td>
                </tr>
            ))}
            </tbody>
            </Table>
            </form>
       : 
       <div className="container-spinner">
      <div className="vertical-center-spinner">
       {/*<Button variant="primary" disabled>*/}
       <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{ width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
 
       {/*</Button>*/}
       </div>
       </div>
       }   
           
                
        </Container>
    );

}

export default ListEmpPayments;