import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Form, Button, View,Modal,Spinner, Image } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
import Sidebar2 from "./Sidebar2";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
//import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
//import DateTimeField from "react-bootstrap-datetimepicker"
//import DateTimePicker from "react-datetime-picker";

import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client } from "../config/Config";
import axios from "axios";

import { useLocation } from "react-router-dom";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import { BsFillPenFill, BsFillTrashFill,BsPencilSquare,BsArrowLeftCircle,BsFillPrinterFill } from "react-icons/bs";

import DateTimePicker from "react-datetime-picker";

import MenuSettings from "./MenuSettings";
import SidebarMain from "./SidebarMain";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';

//import * as bs from 'bootstrap/dist/css/bootstrap.css';
//import * as bst from 'bootstrap/dist/css/bootstrap-theme.css';


const AddUpdateFuelPass = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  //console.log("PASSED STATE VALUE IN Patient Add/Update page : ", state);

  function padTo2Digits(num) {
    console.log("<<<<<<<<<<<<<< given number >>>>>>>>>>>>>>>> : ", num);
    var modNum = num.toString().padStart(2, "0");
    console.log("Modified  value : ", modNum);
    return modNum;
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  let initialItems = [];
  const optionsPayment = [
    { value: 1, label: "Cash" },
    { value: 2, label: "Cheque" },
  ];
  const optionsAgencies = [
    { value: 1, label: "Santhosh Agencies"},
    { value: 2, label: "Ram Agencies"},
  ];
  const optionsCompany = [
    { value: 1, label: "KMC Readymix"},
    { value: 2, label: "APR Readymix"},
  ];
  const optionsVehicle = [
    { value: 1, label: "TN39 CY3799"},
    { value: 2, label: "TN32 CV6788"},
  ];
  const optionsAddress = [
    { value: 1, label: "no.30,tiruppur"},
    { value: 2, label: "no.45,avinashi"},
  ];
  
  const intialValues = {
   date: formatDate(new Date()),
   diesel: "",
   petrol: "",
   oil: "",
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [toRender, setToRender] = useState(false);
  const [validateError, setValidateError] = useState(false);
  
  var validateErr = false;

 

  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );
  const [submitAction, setSubmitAction] = useState(false);
  
  const [agenciesIdSelect, setAgenciesIdSelect] = useState(optionsAgencies);
  const [agenciesOpt, setAgenciesOpt] = useState(optionsAgencies);
  const [companyIdSelect, setCompanyIdSelect] = useState(optionsCompany);
  const [companyOpt, setCompanyOpt] = useState(optionsCompany);
  const [vehicleIdSelect, setVehicleIdSelect] = useState(optionsVehicle);
  const [vehicleOpt, setVehicleOpt] = useState(optionsVehicle);
  const [addressIdSelect, setAddressIdSelect] = useState(optionsAddress);
  const [addressOpt, setAddressOpt] = useState(optionsAddress);
  const [receivedSaved, setReceivedSaved] = useState(false);
  
  

  const handleOnChange = (e) => {
    //e.preventDefault();
    console.log("handleOnChange : ", e);

    console.log("Button clicked : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
/*
    setFormValues({ ...formValues, [name]: value});
    if (name === "phone" || name === "pincode" || name === "contact_number_primary" || name === "contact_number_secondary" ) {
      console.log("Inside check ******************");
      var valueTmp = e.currentTarget.value.replace(/[^\d]/g, '');
      setFormValues({ ...formValues, [name]: valueTmp });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
    */
    setFormValues({ ...formValues, [name]: value });
  }
  
  
  /*
  const changePassword = async () => {
    console.log("changePassword api called ..");
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}` };

      var reqParam = {
        old_password: formValues["old_password"],
        new_password: formValues["new_password"]
      };
     

      var res = await axios.post(
        client.domain + "/core/changePassword",
        reqParam,
        { headers },
        { withCredentials: false }
      );

      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.data[0]);
        setFormValues(res.data.detail.data[0]);
        setShowMsg("password Changed successfully...!");
        setShowMsgTitle("Success");
        setShowDialog(true);
        setSubmitAction(false);
      }
      else {
        setShowMsg(res.data.detail.data.message);
        setShowMsgTitle("Alert!");
        setShowDialog(true);
        setSubmitAction(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          setShowMsg(error.response.data.detail);
          setShowMsgTitle("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
  };
  */
  
  const handleValueChange = async (e) => {
    console.log("On change clicked : ", e);

    var tagId = e.target.id;
    var currentRowName = tagId.substring(tagId.lastIndexOf("-") + 1);
    var nameOfElement = tagId.substring(0, tagId.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current row id : ", currentRowName);

   
  };
  useEffect(() => {
    if (!initialized.current) {
      console.log("*************** useEffect::addUpdateProfile called ##########################");
      /*
      if ( state !== null ) {
        if ( state.action === 'add') {
          setPaymentIdSelect(optionsPayment[1]);
        }
      }
      */
      
      setAgenciesIdSelect(optionsAgencies[0]);
      setCompanyIdSelect(optionsCompany[0]);
      setVehicleIdSelect(optionsVehicle[0]);
      setAddressIdSelect(optionsAddress[0]);
      //console.log("PASSED STATE VALUE IN Service Add/Update page : ", state);
      setToRender(true);
      initialized.current = true;
      
    }
  }, []);
  

 

 
  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;
    if (!values.new_password) {
      errors.new_password = " New Password is required";
      validateErr = true;
      setValidateError(true);
    }

    if (!values.confirm_password) {
      errors.confirm_password = "Confirm Password should not be empty";
      validateErr = true;
    }

    if ( values.new_password !== values.confirm_password ) {
      errors.new_password = " New password and confirm password should be same";
      errors.confirm_password = "New password and confirm password should be same";
      validateErr = true;
    }

    

    console.log("BEFORE RETURN validate errors ...", validateError);
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };
  
  const handleEdit = async (e) => {
    console.log("handle edit clicked at AddUpdateCompany : ", e);
    setIsReadOnly(false);
  };
  
  const handleReset = async (e) => {
    console.log("handle reset clicked : ", e);
    navigate(0);
  };
  
  const handleSave = async (e) => {
    e.preventDefault();
    console.log("Handle Click save called : ", e);
    console.log("Formvalues : ", formValues);

    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    if (validateErr === false) {
      setSubmitAction(true);
      //changePassword();
      setReceivedSaved(true);
    }
  
  };
  const handlePostSaveOk = () => {
    console.log("user confirmed to customer added ...");
    //console.log("user selected id for action is : ", selectedId);

    // call update API
    // updateCustomerStatus(selectedId);
    setShowDialog(false);
    //navigate("/listCustomers");
  };
  const handleCloseDialog = () => {
    setShowDialog(false);
    //navigate("/ListCustomers");
  };
  const handleBack = async (e) => {
    console.log("handle back clicked : ", e);
    navigate(-1);
  };
  const onChangeDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);

    setFormValues({ ...formValues, ["date"]: e.target.value });
  };
  const toSqlDatetime = (inputDate) => {
    const date = new Date(inputDate);
    const dateWithOffest = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    return dateWithOffest.toISOString().slice(0, 19).replace("T", " ");
  };

  
  const handleAgenciesSelect = (e, data) => {
    console.log("handleAgenciesSelect called ...", e);
    setAgenciesIdSelect(optionsAgencies[1]);
  };
  const handleCompanySelect = (e, data) => {
    console.log("handleCompanySelect called ...", e);
    setCompanyIdSelect(optionsCompany[1]);
  };
  const handleVehicleSelect = (e, data) => {
    console.log("handleVehicleSelect called ...", e);

    setVehicleIdSelect(e);
  };
  const handleAddressSelect = (e, data) => {
    console.log("handleAddressSelect called ...", e);

    setAddressIdSelect(e);
  };
  const handlePrintReceipt = async() => {
    console.log("Handle print receipt called ...");
  }
  
 

  return (
    <>
      <Container>
        <SidebarMain />
        { toRender ?
        (
        <Form>
          <Row>
          <Col xs={4} md={4} sm={4} lg={4}>  
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>Fuel Pass</span>
            </Col>
            <Col
              xs={8}
              md={8}
              sm={8}
              lg={8}
            >
              { receivedSaved &&
                <>
                <Button id="orderPrint" name = "orderPrint"  onClick={(e)  => handlePrintReceipt(e)}>
                <BsFillPrinterFill
                  size={18}
                  style={{ color: "white", cursor: "pointer" }}
                  name={`print-order-dc-icon`}
                  id={`print-order-dc-icon`}
                />
                &nbsp;Print Receipt</Button>
                &nbsp;
                </>
                }
              
              <div className="d-flex justify-content-end">
              <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                  <BsArrowLeftCircle
                    key={`back-btn-bill-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                   
                    name={`back-icon-bill-name`}
                    id={`back-icon-bill-id`}
                  />
                </Button>
                &nbsp;&nbsp;
              {/*  
              <Button variant="primary" className="btn btn-primary btn-md"
              key={`edit-btn-company-key`}
              style={{ cursor: "pointer" }}
              onClick={handleEdit}
              name={`edit-icon-password-name`}
              id={`edit-icon-password-id`}>
                  <BsPencilSquare
                    size={24}
                  />
                </Button>
                */}
                </div>
                
                </Col> 
          </Row>
          <div className="ui-divider"></div>
          <div className="ui-form">
          <hr align="center" />
           
              
            <Row>
            
              <Col xs={3} sm={3} md={3} lg={3}>
                    <Form.Group className="mb-1" >
                      <Form.Label>Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="date"
                        id="date"
                        placeholder="Date"
                        value={formValues.date}
                        onChange={onChangeDatePicker}
                        style={{
                          border: !isReadOnly && "1px solid gray",
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={3} md={3} sm={3} lg={3}>
            <Form.Group className="mb-3">
             <Form.Label>To</Form.Label>
                <Select
                
                  name="agencies"
                  id="agencies"
                  value={{
                    value: agenciesIdSelect.value,
                    label: agenciesIdSelect.label,
                  }}
                  options={agenciesOpt}
                  defaultValue={{
                    value: agenciesIdSelect.value,
                    label: agenciesIdSelect.label,
                  }}
                  onChange={handleAgenciesSelect}
                  //isDisabled={isReadOnly}
                  style={{
                    border: !isReadOnly && "1px solid gray",
                  }}
                />
                </Form.Group>
              
              <p className="error-msg">{formErrors.agencies}</p>
            </Col>
            <Col xs={3} md={3} sm={3} lg={3}>
            <Form.Group className="mb-3">
             <Form.Label>From</Form.Label>
                <Select
                
                  name="company"
                  id="company"
                  value={{
                    value: companyIdSelect.value,
                    label: companyIdSelect.label,
                  }}
                  options={companyOpt}
                  defaultValue={{
                    value: companyIdSelect.value,
                    label: companyIdSelect.label,
                  }}
                  onChange={handleCompanySelect}
                  //isDisabled={isReadOnly}
                  style={{
                    border: !isReadOnly && "1px solid gray",
                  }}
                />
                </Form.Group>
              
              <p className="error-msg">{formErrors.company}</p>
            </Col>
            <Col xs={3} md={3} sm={3} lg={3}>
            <Form.Group className="mb-3">
             <Form.Label>Vehicle</Form.Label>
            <Select
                    id="vehicle-dropdown"
                    name="vehicle"
                    value={{
                      value: vehicleIdSelect.value,
                      label: vehicleIdSelect.label,
                    }}
                    options={vehicleOpt}
                    defaultValue={{
                      value: vehicleIdSelect.value,
                      label: vehicleIdSelect.label,
                    }}
                    onChange={handleVehicleSelect}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.vehicle}</p>
              </Col>
              </Row>
              <Row>
              <Col xs={4} md={4} sm={4} lg={4} style={{fontSize: "20px"}}> 
              <Form.Label>Please Supply the Following and Debit to Our A/C</Form.Label>
              </Col>
              </Row>

                <Row>
                 <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label >Diesel</Form.Label>
                  <Form.Control
                    type="text"
                    id="diesel"
                    name="diesel"
                    placeholder="Diesel"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={receivedSaved}
                    value={formValues["diesel"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.diesel}</p>
              </Col> 
             
            
            <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Petrol</Form.Label>
                  <Form.Control
                    type="text"
                    id="petrol"
                    name="petrol"
                    placeholder="Petrol"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={receivedSaved}
                    value={formValues["petrol"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.petrol}</p>
              </Col> 
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label >Oil</Form.Label>
                  <Form.Control
                    type="text"
                    id="oil"
                    name="oil"
                    placeholder="oil"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={receivedSaved}
                    value={formValues["oil"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.oil}</p>
              </Col>
              </Row>
              
            
            <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ disply: "flex", justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleReset(e)}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      //type="submit"
                      id="save"
                      onClick={(e) => handleSave(e)}
                      disabled={submitAction ? true: false}
                    >
                      {submitAction && 
                      <>
                      <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                       aria-hidden="true"
                      />
                    <span className="visually-hidden">Loading...</span>
                    </>  
                   }
                   Save
                      
                    </Button>
                  </div>
                </Col>  
              </Row>
            
             <Modal
              show={showDialog}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>{showMsgTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{showMsg}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handlePostSaveOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal> 
            
          </div>
        </Form>
        )
        :
        <div className="container-spinner">
        <div className="vertical-center-spinner">
         {/*<Button variant="primary" disabled>*/}
         <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
            <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
                <span className="visually-hidden">Loading...</span>
              </Spinner>
   
         {/*</Button>*/}
         </div>
         </div>
      }
      </Container>
    </>
  );
};

export default AddUpdateFuelPass;
