import React, { Fragment } from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

import { formatDateIndian } from '../../pages/utils';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 12,
        //justifyContent: "flex-end",
        //alignItems: 'flex-end',
        //alignItems: 'flex-end',
        //alignSelf: 'flex-end',
        //textAlign: 'right',
        marginRight: '40%',
        flexDirection: 'column', 
        backgroundColor: '#FFFFFF', 
        margin: 10, 
        borderTop: 3, 
        borderBottom: 3, 
        borderLeft: 3, 
        borderRight: 3, 
        borderColor: '#22618F', 
        BorderStyle: 'solid',
    },
    invoiceNoContainer: {
        flexDirection: 'row',
        //marginTop: 12,
        justifyContent: 'flex-end'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDate: {
            fontSize: 12,
            fontStyle: 'bold',
    },
    label: {
        width: 60,
        fontFamily: 'Helvetica-Bold'
    },
    left3: {
        // width: '33%',//<- working alternative
        padding: 5,
        flexGrow: 0,
        flexShrink: 1,
        flexBasis: 230,
        margin: 2, 
        borderTop: 1, 
        borderBottom: 1, 
        borderLeft: 1, 
        borderRight: 1, 
        borderColor: '#a8a8a8', 
        BorderStyle: 'dashed',
        height: '120px',
        //border: 'solid #e7e5e5 3px',
        //padding: '1em',
        //margin: '1em',
        borderRadius: 5
      },
  });


  //const InvoiceNo = ({invoice}) => (
const InvoiceNo = (props) => {
    console.log("Props Data at InvoiceNo: ", props);
    return (
        <Fragment>
            {/*
            <View style={styles.invoiceNoContainer}>
                <Text style={styles.label}>Order No:</Text>
                <Text style={styles.invoiceDate}>{props.orderLoad.order_info.order_no}</Text>
            </View >
            <View style={styles.invoiceDateContainer}>
                <Text style={styles.label}>Order Date: </Text>
                <Text >{props.orderLoad.order_info.order_date}</Text>
            </View >
            */}
            <View style={styles.left3}>
        {/*<Text style={styles.billTo}>Order Details:</Text>*/}
        <Text style={{fontSize: "11px"}}><Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold', fontSize: "12px"}}>Invoice No: </Text>{props.orderLoad.order_info.order_no}</Text>
        <Text style={{fontSize: "11px"}}><Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold', fontSize: "12px"}}>Invoice Date: </Text>{formatDateIndian(new Date(props.orderLoad.order_info.order_date))}</Text>
        <Text inline style={{fontSize: "11px"}}><Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold', fontSize: "12px"}}>State : </Text>TAMIL NADU&nbsp;&nbsp;&nbsp;
        <Text style={{fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold', fontSize: "12px"}}>State Code: </Text>{props.orderLoad.company_info.gst_no.substring(0, 2)}</Text>
        
    </View>

        </Fragment>
  );
  }
  export default InvoiceNo
