import React, { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';

import { client } from '../config/Config';

import StockDocument from "./StockDocument";
import {getCompanyInfo, formatDateIndian } from "./utils";

import logo_left from '../logo_left.png';
import logo_right from '../logo_right.png';

const borderColor = '#55585a';
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 9,
    paddingTop: 30,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    //width: '100%',
    //height: '33%',
    width: '100%',
    height: '100%',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  logo_image2: {
    width: '15%',
    height: '98px',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  business_content: {
    width: '60%',
    height: '98px',
    //paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container2: {
    //paddingBottom:0,
    flexDirection: 'row',
    //borderBottomColor: '#bff0fd',
    //backgroundColor: '#bff0fd',
    //borderBottomWidth: 1,
    //alignItems: 'center',
    //height: 12,
    //textAlign: 'center',
    //fontStyle: 'bold',
    //flexGrow: 1,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 1,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#a8a8a8',

  },
  container: {
    flexDirection: 'row',
    borderBottomColor: '#a8a8a8',
    backgroundColor: '#a8a8a8',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    //flexGrow: 1,
    //flexWrap: 'wrap',
    //marginTop: 1,
    //marginBottom: 10,
    //borderWidth: 1,
    //borderWidth: 1,
    //marginBottom: 5,
  },
  sno: {
    width: '5%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left'
   
  },

  stmt_date: {
    width: '8%',
    fontSize: "10px",
    //textAlign: 'left',
    textAlign: 'left',
    //alignContent: 'justify',
    //textAlign: 'justify',
    //textJustify: 'inter-word',
    //display:'flex',
    //flexWrap: 'wrap',
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    paddingLeft: 10,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  customer_name: {
    width: '15%',
    fontSize: "10px",
    borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    //paddingRight:2
  },

  address1: {
    width: '8%',
    fontSize: "10px",
    borderRightColor: borderColor,
    textAlign: 'left',
    //borderRightWidth: 1,
  },
  
  contact_number: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    textAlign: 'right',
    //borderRightWidth: 1,
  },
  
  
  previous_balance: {
    width: '13%',
    fontSize: "10px",
    borderRightColor: borderColor,
    textAlign: 'right',
    //borderRightWidth: 1,
  },
  total_amount: {
    width: '13%',
    fontSize: "10px",
    borderRightColor: borderColor,
    textAlign: 'right',
    //borderRightWidth: 1,
  },
  amount_received: {
    width: '13%',
    fontSize: "10px",
    borderRightColor: borderColor,
    textAlign: 'right',
    //borderRightWidth: 1,
    
  },
  total_os: {
    width: '15%',
    fontSize: "10px",
    borderRightColor: borderColor,
    textAlign: 'right',
    paddingLeft: "10px"
    //borderRightWidth: 1,
  },
  
  row: {
    flexDirection: 'row',
    //borderBottomColor: '#a8a8a8',
    //borderBottmWidth: 1,
    alignItems: 'center',
    //height: 24,
    fontStyle: 'bold',
  },
  snoData: {
    width: '5%',   
    textAlign: 'right',
    paddingRight: 30,
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    fontSize: "10px",
    //paddingLeft: 8,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },


  stmt_dateData: {
    width: '8%',
    fontSize: "10px",
    //textAlign: 'left',
    textAlign: 'left',
    //alignContent: 'justify',
    //textAlign: 'justify',
    //textJustify: 'inter-word',
    //display:'flex',
    //flexWrap: 'wrap',
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    //paddingLeft: 8,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },

  customer_nameData: {
    width: '15%',
    fontSize: "10px",
    //textAlign: 'left',
    textAlign: 'left',
    //alignContent: 'justify',
    //textAlign: 'justify',
    //textJustify: 'inter-word',
    //display:'flex',
    //flexWrap: 'wrap',
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    //paddingLeft: 8,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },


  address1Data: {
    width: '8%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    paddingLeft: 1,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  
  contact_numberData: {
    width: '10%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingLeft: 1,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  
 
  previous_balanceData: {
    width: '13%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingLeft: 1,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  total_amountData: {
    width: '13%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    //paddingLeft: 4,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  amount_receivedData: {
    width: '13%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 1,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  total_osData: {
    width: '15%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 1,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  

});

const PrintCustomerStmt = (props) => {

 let customerArr = [
  {
    sno: "1",
    customer_name: "Kannaiyan",
    contact_number: 9087643219,
    previous_balance: "2000.00",
    total_amount: "5000.00",
    amount_received: "600.00",
    total_os: "5.00"
  },
  {
    sno: "2",
    customer_name: "Rani",
    contact_number: 9807612342,
    previous_balance: "6000.00",
    total_amount: "8000.00",
    amount_received: "900.00",
    total_os: "100.00"
  },
  {
    sno: "3",
    customer_name: "Venkatraman",
    contact_number: 8123456790,
    previous_balance: "4000.00",
    total_amount: "9000.00",
    amount_received: "400.00",
    total_os: "600.00"
  },
  {
    sno: "4",
    customer_name: "RRR Construction",
    contact_number: 8901234675,
    previous_balance: "3000.00",
    total_amount: "7000.00",
    amount_received: "300.00",
    total_os: "800.00"
  },
  {
    sno: "5",
    customer_name: "Kumar",
    contact_number: 9801234675,
    previous_balance: "9000.00",
    total_amount: "5000.00",
    amount_received: "300.00",
    total_os: "500.00"
  },

 ]

 
  const [reportData, setReportData] = useState({});
  const [toRender, setToRender] = useState(false);
  const [customerOsList, setCustomerOsList] = useState([]);
  const [companyInfo, setCompanyInfo] = useState({});

  var total_received_sum = useRef(0.00);
  var total_balance_sum = useRef(0.00);
  var total_current_balance_sum = useRef(0.00);
  var total_previous_balance_sum = useRef(0.00);

  var customer_id_ref = useRef(0);
  var start_date_ref = useRef(formatDateIndian(new Date()));
  var end_date_ref = useRef(formatDateIndian(new Date()));

  /*
  console.log("CALLED CURRENT PAGE : ", window.location.href);

    console.log("CALLED window locaion : ", window.location);
    let params = (new URL(window.location)).searchParams;
    console.log("COMPLETE PARAMS : ", params);
    let orderIdParam = params.get("order_id");
    console.log("ORDER ID FROM PARAM : ", orderIdParam);

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.indexOf('?') + 1);
    var urlTmp = urlStr.substring(0, urlStr.indexOf("?"));

    console.log("URL : ", urlTmp);
    console.log("URL : ", paramsTmp);

    let searchParams = new URLSearchParams(paramsTmp);
    var customerIdTemp2 = searchParams.get("customer_id");
    console.log("CUSTOMED ID : ", customerIdTemp2);
    var productIdTemp2 = searchParams.get("product_id");
    console.log("PRODUCT ID : ", productIdTemp2);
    var startDateStr = searchParams.get("start_date");
    console.log("START DATE : ", startDateStr);
    var endDateStr = searchParams.get("end_date");
    console.log("END DATE : ", endDateStr);

    console.log("Extracted CUSTOMER ID  : ", customerIdTemp2);
    console.log("Extracted PRODUCT ID : ", productIdTemp2);
   */
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  const formateTime = (currTime) => {
    var h = (currTime.getHours() < 10 ? "0" : "") + currTime.getHours();
    var m = (currTime.getMinutes() < 10 ? "0" : "") + currTime.getMinutes();
    var hrMin = h + ":" + m;
    return hrMin;
  };

  const formatPrintDate = (currTime) => {
    var dateComponent = [
      padTo2Digits(currTime.getDate()),
      padTo2Digits(currTime.getMonth() + 1),
      currTime.getFullYear(),
    ].join("-");

    var timeComponent = [
      padTo2Digits(currTime.getHours() % 12 || 12 ),
      padTo2Digits(currTime.getMinutes())
    ].join(":");

    const ampm = currTime.getHours() >= 12 ? 'PM' : 'AM';

    return dateComponent + " " + timeComponent + " " + ampm;
  }
  /*
  const getAllSummaryList = async () => {
    var retState = false;
    var deliverySummaryList = [];
    try {
      
      var res = await axios.get(client.domain + `/getDeliverySummaryList?customer_id=${customerIdTemp2}&start_date=${startDateStr}&end_date=${endDateStr}&product_id=${productIdTemp2}`, { withCredentials: false });
      console.log("Response from server getAllSummaryList : ", res.data.detail);
      if (res.data.detail.status === 'SUCCESS') {
        console.log("Response message from server SummaryList : ", res.data.detail.data);
        retState = true;
        if (res.data.detail.data.length > 0) {
          deliverySummaryList = res.data.detail.data; 
        }
        
      }
    }
    catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === 'ERR_NETWORK') {
        console.log("Not able to reach target server please try after sometime");
      }
      else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === 'FAILED') {
          console.log('Request failed due to ', error.response.data.detail.res_data);
         
        }
      }
    }
    return [retState, deliverySummaryList];
  }
  
    */
  
  const getCustOsStmtList = async() => {
    var retState = false;
      var newCustOsStmtArr = [];
      console.log("getCustOsStmtList method called ...");
  
  
      try {
        var aIVal = localStorage.getItem("_aI");
        var headers = { ...client.headers, Authorization: `Bearer ${aIVal}`}
        var res = {};
        if ( start_date_ref.current && end_date_ref.current ) {
          res = await axios.get(client.domain + `/getCustOsStmt?customer_id=${customer_id_ref.current}&start_date=${start_date_ref.current}&end_date=${end_date_ref.current}`, { headers }, { withCredentials: false });
        }
        else {
          res = await axios.get(client.domain + `/getCustOsStmt?customer_id=${customer_id_ref.current}`, { headers }, { withCredentials: false });
        }
        console.log("Response from server getCustOsStmtList : ", res.data.detail.data);
        if (res.data.detail.status === "SUCCESS") {
          console.log("Response message from server getCustOsStmtList : ", res.data.detail.data);        
          
          newCustOsStmtArr = res.data.detail.data;
          retState = true;
        }
       
      } catch (error) {
        console.log("FULL ERROR : ", error);
        if (error.code === "ERR_NETWORK") {
          console.log(
            "Not able to reach target server please try after sometime"
          );
        } else {
          console.log("ERROR : ", error.response.data.detail);
          if (error.response.data.detail.status === "FAILED") {
            console.log(
              "Request failed due to ",
              error.response.data.detail.res_data
            );
            
          }
        }
      }
      return [retState, newCustOsStmtArr];
   }

  useEffect(() => {
    console.log("useEffect method called ...");
    console.log("CALLED CURRENT PAGE : ", window.location.href);

    console.log("CALLED window locaion : ", window.location);
    let params = (new URL(window.location)).searchParams;
    console.log("COMPLETE PARAMS : ", params);
    let orderIdParam = params.get("order_id");
    console.log("ORDER ID FROM PARAM : ", orderIdParam);

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.indexOf('?') + 1);
    var urlTmp = urlStr.substring(0, urlStr.indexOf("?"));

    console.log("URL : ", urlTmp);
    console.log("URL : ", paramsTmp);

    let searchParams = new URLSearchParams(paramsTmp);

    var customerIdTemp2 = searchParams.get("customer_id");
    console.log("PRODUCT ID : ", customerIdTemp2);
    customer_id_ref.current = customerIdTemp2;

    var startDateStr = searchParams.get("start_date");
    console.log("START DATE : ", startDateStr);
    start_date_ref.current = startDateStr;

    var endDateStr = searchParams.get("end_date");
    console.log("END DATE : ", endDateStr);
    end_date_ref.current = endDateStr;
    
    (async () => {
      var [retState, companyInfoTmp] = await getCompanyInfo();
      if (retState) {
        setCompanyInfo(companyInfoTmp);
        setToRender(true); 
      }

      var [retStateCustOs, newCustOsStmtArr] = await getCustOsStmtList();
        if ( retStateCustOs ) {
          //var newCustOsStmtArr1 = [{value: 0, label: "All Locations"}, ...newCustOsStmtArr];
          console.log("RETURNED CUSOTMER OS STMT LIST : ", newCustOsStmtArr);
          setCustomerOsList(newCustOsStmtArr);

          total_received_sum.current = 0.00;
          total_balance_sum.current = 0.00;
          total_current_balance_sum.current = 0.00;
          total_previous_balance_sum.current = 0.00;

          newCustOsStmtArr.map((customer) => {
            total_received_sum.current = total_received_sum.current + parseFloat(customer.total_received);
            total_balance_sum.current = total_balance_sum.current + parseFloat(customer.total_balance);
            total_current_balance_sum.current = total_current_balance_sum.current + parseFloat(customer.current_balance);
            total_previous_balance_sum.current = total_previous_balance_sum.current + parseFloat(customer.previous_balance)
          })
          console.log("TOTAL RECEIVED AT getCustOsStmtList call : ", total_received_sum.current);
          console.log("TOTAL BALANCE AT getCustOsStmtList call : ", total_balance_sum.current);
          console.log("TOTAL BALANCE AT getCustOsStmtList call : ", total_current_balance_sum.current);
          console.log("TOTAL BALANCE AT getCustOsStmtList call : ", total_previous_balance_sum.current);
          
        }
    })();
  }, [])

  return (
    <PDFViewer style={styles.viewer}>
      {toRender === true &&
        <Document>
          <Page size="A4" orientation="landscape" style={styles.page}>
            <View>
              <View style={{ flexDirection: 'row', borderBottomWidth: 1, borderBottomColor: '#a7a5a5' }}>
                <View style={{ textAlign: 'right', width: '25%' }}>
                  <Image style={{ marginHorizontal: '10%', width: 'auto', height: 'auto', height: "64px", width: "64px" }} src={logo_left} />
                </View>
                <View style={{ width: "50%", alignItems: "center", /*paddingRight: "30px",*/ /*borderBottomWidth: 1,*/ borderBottomColor: '#a7a5a5'/*,marginTop: 1, marginBottom: 10, borderWidth: 1*/ }} key="address-key">
                  <Text style={{ fontSize: "20px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>{companyInfo.name}</Text>
                  <Text>{companyInfo.address_line1}</Text>
                  <Text>{companyInfo.address_line2}-{companyInfo.pincode}</Text>
                  <Text>{companyInfo.district_name}, {companyInfo.state_name}</Text>
                  <Text style={{ fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica' }}><Text style={{ fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Mobile:</Text> {companyInfo.phone}</Text>
                </View>
                <View style={{ textAlign: 'right', width: '25%',paddingLeft: '140px', alignItems: 'right'}}>
                  <Image style={{ /*marginHorizontal: '10%',*/ width: 'auto', height: 'auto', height: "64px", width: "64px"}} src={logo_right} />
                </View>
              </View>
            </View>
            <View style={{ width: "100%", alignItems: "center",/* paddingLeft: "20px",*/ textAlign: 'center', borderBottomWidth: 1, borderBottomColor: '#a7a5a5', paddingTop: "5px" }}>
              { start_date_ref.current ? 
              <Text style={{ fontSize: "12px", textAlign: 'center', alignContent: 'center', fontFamily: 'Helvetica-Bold', color: '#646363' }}>Customer Outstanding Summary  ({ start_date_ref.current ? formatDateIndian(new Date(start_date_ref.current)) : formatDateIndian(new Date)} To { end_date_ref.current ? formatDateIndian(new Date(end_date_ref.current)) : formatDateIndian(new Date())})</Text>
              :
              <Text style={{ fontSize: "12px", textAlign: 'center', alignContent: 'center', fontFamily: 'Helvetica-Bold', color: '#646363' }}>Customer Outstanding Summary as on {formatDateIndian(new Date())}</Text>
              }
            </View>
            <hr style={{paddingBottom: "10px"}}/>
           
            

            <View style={styles.tableContainer}>
              
              <View style={styles.container}>
                <Text style={styles.sno}>S.No</Text>
                <Text style={styles.stmt_date}>Date</Text>
                <Text style={styles.customer_name}>Customer Name</Text>
                <Text style={styles.address1}>Location</Text>
                <Text style={styles.contact_number}>Contact Number</Text>
                <Text style={styles.previous_balance}>Old Balance</Text>
                <Text style={styles.total_amount}>Current Balance</Text>
                <Text style={styles.amount_received}>Advance</Text>
                <Text style={styles.total_os}>Total Balance</Text>
                
                
              </View>

              <View style={{flexGrow: 1}}>
              {customerOsList.map((customer, idx) => (
              <View style={styles.row} key="items-${idx}">
                    <Text style={styles.snoData}>{idx + 1}</Text>
                    <Text style={styles.stmt_dateData}>{formatDateIndian(new Date(customer.stmt_date))}</Text>
                    <Text style={styles.customer_nameData}>{customer.customer_name}</Text>
                    <Text style={styles.address1Data}>{customer.address1}</Text>
                    <Text style={styles.contact_numberData}>{customer.contact_number}</Text>
                    <Text style={styles.previous_balanceData}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(customer.previous_balance).toFixed(2)))}</Text>
                    <Text style={styles.total_amountData}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(customer.current_balance).toFixed(2)))}</Text>
                    <Text style={styles.amount_receivedData}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(customer.total_received).toFixed(2)))}</Text>                    
                    <Text style={styles.total_osData}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(customer.total_balance).toFixed(2)))}</Text>
                    
                    
              </View>
               ))}
              </View>
              
              </View>
              <View style={{...styles.row,borderBottomWidth: 1, borderBottomColor: '#a7a5a5',paddingBottom: "5px"}} key="items-${idx}">
                    <Text style={styles.snoData}></Text>
                    <Text style={styles.stmt_dateData}></Text>
                    <Text style={styles.customer_nameData}></Text>
                    <Text style={styles.address1Data}></Text>
                    <Text style={{...styles.contact_numberData,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Total:</Text>
                    <Text style={{...styles.previous_balanceData,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(total_previous_balance_sum.current).toFixed(2)))}</Text>
                    <Text style={{...styles.total_amountData,fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(total_current_balance_sum.current).toFixed(2)))}</Text>
                    <Text style={{...styles.amount_receivedData,textAlign: 'right', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(total_received_sum.current).toFixed(2)))}</Text>
                    <Text style={{...styles.total_osData,textAlign: 'right', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(total_balance_sum.current).toFixed(2)))}</Text>
                    
                    
              </View>
              
              <hr style={{paddingBottom: "20px"}}/>


             
             
              
                 {/*
              <View style={{ width: "50%", alignItems: "right", textAlign: 'right', borderBottomWidth: 1, borderBottomColor: '#a7a5a5', paddingTop: "5px" }}>
              <Text style={{ fontSize: "12px", textAlign: 'left', alignContent: 'left', fontFamily: 'Helvetica-Bold', color: '#646363' }}>RECEIPTS</Text>
            </View>
            */}

            
            
           
              {/*
              <View style={{ width: "50%", alignItems: "right", textAlign: 'right', borderBottomWidth: 1, borderBottomColor: '#a7a5a5', paddingTop: "5px" }}>
              <Text style={{ fontSize: "12px", textAlign: 'right', alignContent: 'right', fontFamily: 'Helvetica-Bold', color: '#646363' }}>OUTSTANDING STATEMENT</Text>
            </View>
            */}
            <View style={{width: '30%'}}></View>
            {/*
            <View style={{width: '20%', alignContent: 'right'}}>
            <Text style={{ fontSize: "12px", fontFamily: 'Helvetica-Bold', color: '#646363' }}>Outstanding Statement</Text>
            
            <View style={{flexDirection: 'row', alignContent: 'right', alignItems: 'right', justifyContent: 'front-end !important'}}><Text style={{ fontSize: "12px",fontWeight: 'bold', fontFamily: 'Helvetica-Bold',alignContent: 'right', alignItems: 'right',}}>Previous Balance: </Text> <Text style={{fontSize: "12px", textAlign: 'right !important', alignContent: 'right !important', fontFamily: 'Helvetica'}}>1000.00</Text></View>
            <View style={{flexDirection: 'row'}}><Text style={{ fontSize: "12px",fontWeight: 'bold', fontFamily: 'Helvetica-Bold',alignContent: 'right', alignItems: 'right',}}>Previous Balance: </Text> <Text style={{fontSize: "12px", textAlign: 'right !important', alignContent: 'right !important', fontFamily: 'Helvetica'}}>10000.00</Text></View>
            
            <Text style={{ fontSize: "10px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}> Total Received <Text style={{fontSize: "12px", textAlign: 'right', alignContent: 'right', fontFamily: 'Helvetica'}}>: 1000.00</Text></Text>
            <View style={{flexDirection: 'row'}}><Text style={{ fontSize: "10px", paddingBottom: "5px",fontWeight: 'bold', fontFamily: 'Helvetica-Bold',paddingLeft: "7px",}}>Current Balance</Text> <Text style={{fontSize: "12px", textAlign: 'right', alignContent: 'right', fontFamily: 'Helvetica'}}>: 3000.00</Text></View>
            <View style={{flexDirection: 'row'}}><Text style={{ fontSize: "10px", alignContent: 'left', paddingBottom: "5px",fontWeight: 'bold', fontFamily: 'Helvetica-Bold',paddingLeft: "21px"}}>Current Balance</Text> <Text style={{fontSize: "12px", fontFamily: 'Helvetica'}}>: 5000.00</Text></View>
            <View style={{flexDirection: 'row'}}><Text style={{ fontSize: "10px", alignContent: 'left', paddingBottom: "5px",fontWeight: 'bold', fontFamily: 'Helvetica-Bold',paddingLeft: "21px"}}>Total Balance</Text> <Text style={{fontSize: "12px", fontFamily: 'Helvetica', alignContent: 'right !important', alignItems: 'right !important', textAlign: 'right !important', alignSelf: 'flex-end !important'}}>: 60000.00</Text></View>
          
            </View>
            */}

            
            
            <hr style={{paddingBottom: "40px"}}/>
             

            <View style={{flexDirection: 'row'}}>
            
            <Text style={{textAlign: 'left', flex: 1}} render={({ pageNumber, totalPages }) => (
                `Printed On: ${formatPrintDate(new Date())}`
            )} fixed />
             
             <Text style={{ textAlign: 'center', alignContent: 'center'}}>..... End of Report .....</Text>
           
              <Text style={{flex: 1, textAlign: 'right', justifyContent: 'flex-end', alignSelf: 'flex-end'}} render={({ pageNumber, totalPages }) => (
                `Page No : ${pageNumber} / ${totalPages}`
              )} fixed />
              </View>
              

          </Page>
        </Document>
      }
    </PDFViewer>
  );
}

export default PrintCustomerStmt;