import React, { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';

import { client } from '../config/Config';

import StockDocument from "./StockDocument";
import {getCompanyInfo, formatDateIndian } from "./utils";

import logo_left from '../logo_left.png';
import logo_right from '../logo_right.png';

const borderColor = '#a8a8a8';
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 9,
    paddingTop: 30,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    //width: '100%',
    //height: '33%',
    width: '100%',
    height: '100%',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  logo_image2: {
    width: '15%',
    height: '98px',
    paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  business_content: {
    width: '60%',
    height: '98px',
    //paddingTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container2: {
    //paddingBottom:0,
    flexDirection: 'row',
    //borderBottomColor: '#bff0fd',
    //backgroundColor: '#bff0fd',
    //borderBottomWidth: 1,
    //alignItems: 'center',
    //height: 12,
    //textAlign: 'center',
    //fontStyle: 'bold',
    //flexGrow: 1,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 1,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#a8a8a8',
  },
  container: {
    flexDirection: 'row',
    borderBottomColor: '#a8a8a8',
    backgroundColor: '#a8a8a8',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
  },
  sno: {
    width: '3%',
    fontSize: "10px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'left'
   
  },
  delivery_date: {
    width: '7%',
    fontSize: "10px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'center'
  },
  customer_name: {
    width: '10%',
    fontSize: "10px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'left',
    
  },
 
  address_line1: {
    width: '12%',
    fontSize: "10px",
    borderRightColor: borderColor,
    textAlign: 'left',
    //borderRightWidth: 1,
  },
  product_name: {
    width: '3%',
    fontSize: "10px",
    borderRightColor: borderColor,
    textAlign: 'left',
    //borderRightWidth: 1,
  },
  total_quantity: {
    width: '6%',
    fontSize: "10px",
    borderRightColor: borderColor,
    textAlign: 'center',
    paddingLeft: "8px"
    //borderRightWidth: 1,
  },
  unit_price: {
    width: '7%',
    fontSize: "10px",
    borderRightColor: borderColor,
    textAlign: 'right',
    //borderRightWidth: 1,
  },
  
  product_price: {
    width: '9%',
    borderRightColor: borderColor,
    fontSize: "10px",
    textAlign: 'left',
    paddingLeft: 10
    //borderRightWidth: 1,
  },
  
  extra_charges: {
    width: '7%',
    borderRightColor: borderColor,
    fontSize: "10px",
    textAlign: 'right',
    //paddingRight: 5,
    //borderRightWidth: 1,
  },
  total_amount: {
    width: '9%',
    borderRightColor: borderColor,
    fontSize: "10px",
    textAlign: 'right',
    //borderRightWidth: 1,
  },
  return_quantity: {
    width: '6%',
    borderRightColor: borderColor,
    fontSize: "10px",
    textAlign: 'right',
    //borderRightWidth: 1,
  },
  return_quantity_amount: {
    width: '7%',
    borderRightColor: borderColor,
    fontSize: "10px",
    textAlign: 'right',
    //borderRightWidth: 1,
  },
  save_quantity: {
    width: '6%',
    borderRightColor: borderColor,
    fontSize: "10px",
    textAlign: 'right',
    //borderRightWidth: 1,
  },
  save_quantity_amount: {
    width: '8%',
    borderRightColor: borderColor,
    fontSize: "10px",
    textAlign: 'right',
     //paddingRight: '1'
    //borderRightWidth: 1,
  },
  row: {
    flexDirection: 'row',
    //borderBottomColor: '#a8a8a8',
    //borderBottmWidth: 1,
    alignItems: 'right',
    //height: 24,
    fontStyle: 'bold',
  },
  snoData: {
    width: '3%',   
    //textAlign: 'left',
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    fontSize: "10px",
    //paddingLeft: 8,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  delivery_dateData: {
    width: '7%',
    fontSize: "10px",
    //textAlign: 'left',
    //textAlign: 'justify',
    //alignContent: 'justify',
    //textAlign: 'justify',
    //textJustify: 'inter-word',
    //display:'flex',
    //flexWrap: 'wrap',
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    //paddingLeft: 8,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  customer_nameData: {
    width: '10%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    //textAlign: 'right',
    //paddingRight: 8,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  
  address_line1Data: {
    width: '12%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'left',
    //paddingRight: 8,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  product_nameData: {
    width: '3%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    //textAlign: 'right',
    //paddingRight: 8,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  total_quantityData: {
    width: '6%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    //paddingRight: 8,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  unit_priceData: {
    width: '7%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 1,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  
  product_priceData: {
    width: '9%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    //borderRightWidth: 1,
    textAlign: 'right',
     paddingRight: 8,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  
   extra_chargesData: {
    width: '7%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 1,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  total_amountData: {
    width: '9%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingLeft: 1,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  return_quantityData: {
    width: '6%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingLeft: 1,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  return_quantity_amountData: {
    width: '7%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 1,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  save_quantityData: {
    width: '6%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 1,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },
  save_quantity_amountData: {
    width: '8%',
    fontSize: "10px",
    //borderRightColor: borderColor,
    //borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 1,
    //borderWidth: 1, 
    //borderLeftWidth: 0, 
    //borderTopWidth: 0
  },

  
});

const PrintDeliveryReturnSave = (props) => {
  const [reportData, setReportData] = useState({});
  const initialized = useRef(false);
  const [toRender, setToRender] = useState(false);
  const [summaryList, setSummaryList] = useState([]);
  const [companyInfo, setCompanyInfo] = useState({});
  var total_quantity_sum = useRef(0.00);
  var total_amount_sum = useRef(0.00);
  var total_product_amount_sum = useRef(0.00);
  var total_extra_charges_sum = useRef(0.00);
  var total_balance_os = useRef(0.00);
  var total_return_quantity_sum = useRef(0.00);
  var total_return_quantity_amount_sum = useRef(0.00);
  var total_save_quantity_sum = useRef(0.00);
  var total_save_quantity_amount_sum = useRef(0.00);


  console.log("CALLED CURRENT PAGE : ", window.location.href);

    console.log("CALLED window locaion : ", window.location);
    let params = (new URL(window.location)).searchParams;
    console.log("COMPLETE PARAMS : ", params);
    let orderIdParam = params.get("order_id");
    console.log("ORDER ID FROM PARAM : ", orderIdParam);

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.indexOf('?') + 1);
    var urlTmp = urlStr.substring(0, urlStr.indexOf("?"));

    console.log("URL : ", urlTmp);
    console.log("URL : ", paramsTmp);

    let searchParams = new URLSearchParams(paramsTmp);
    var customerIdTemp2 = searchParams.get("customer_id");
    console.log("CUSTOMED ID : ", customerIdTemp2);
    var productIdTemp2 = searchParams.get("product_id");
    console.log("PRODUCT ID : ", productIdTemp2);
    var startDateStr = searchParams.get("start_date");
    console.log("START DATE : ", startDateStr);
    var endDateStr = searchParams.get("end_date");
    console.log("END DATE : ", endDateStr);

    console.log("Extracted CUSTOMER ID  : ", customerIdTemp2);
    console.log("Extracted PRODUCT ID : ", productIdTemp2);

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  const formateTime = (currTime) => {
    var h = (currTime.getHours() < 10 ? "0" : "") + currTime.getHours();
    var m = (currTime.getMinutes() < 10 ? "0" : "") + currTime.getMinutes();
    var hrMin = h + ":" + m;
    return hrMin;
  };

  const formatPrintDate = (currTime) => {
    var dateComponent = [
      padTo2Digits(currTime.getDate()),
      padTo2Digits(currTime.getMonth() + 1),
      currTime.getFullYear(),
    ].join("-");

    var timeComponent = [
      padTo2Digits(currTime.getHours() % 12 || 12 ),
      padTo2Digits(currTime.getMinutes())
    ].join(":");

    const ampm = currTime.getHours() >= 12 ? 'PM' : 'AM';

    return dateComponent + " " + timeComponent + " " + ampm;
  }
  
  const getAllSummaryList = async () => {
    var retState = false;
    var deliverySummaryList = [];
    try {
      
      var res = await axios.get(client.domain + `/getDeliverySummaryList?customer_id=${customerIdTemp2}&start_date=${startDateStr}&end_date=${endDateStr}&product_id=${productIdTemp2}`, { withCredentials: false });
      console.log("Response from server getAllSummaryList : ", res.data.detail);
      if (res.data.detail.status === 'SUCCESS') {
        console.log("Response message from server SummaryList : ", res.data.detail.data);
        retState = true;
        total_quantity_sum.current = 0.00;
          total_amount_sum.current = 0.00;
          total_product_amount_sum.current = 0.00;
          total_extra_charges_sum.current = 0.00;
          total_return_quantity_sum.current = 0.00;
          total_return_quantity_amount_sum.current = 0.00;
          total_save_quantity_sum.current = 0.00;
          total_save_quantity_amount_sum.current = 0.00
          res.data.detail.data.map((summary) => {
            console.log("TOTAL QUANTITY INDIVIDUAL : ", summary.total_quantity);
            total_quantity_sum.current = total_quantity_sum.current + parseFloat(summary.total_quantity);
            total_amount_sum.current = total_amount_sum.current + parseFloat(summary.total_amount);
            total_product_amount_sum.current = total_product_amount_sum.current + (parseFloat(summary.total_quantity) * parseFloat(summary.unit_price));
            total_extra_charges_sum.current = total_extra_charges_sum.current + parseFloat(summary.pumping_charges) + parseFloat(summary.diesel_charges) + parseFloat(summary.others_charges1);
            total_return_quantity_sum.current = total_return_quantity_sum.current + parseFloat(summary.return_quantity);
            total_return_quantity_amount_sum.current = total_return_quantity_amount_sum.current + parseFloat(summary.return_quantity_amount);
            total_save_quantity_sum.current = total_save_quantity_sum.current + parseFloat(summary.save_quantity);
            total_save_quantity_amount_sum.current = total_save_quantity_amount_sum.current + parseFloat(summary.save_quantity_amount);

          })
          console.log("TOTAL QUANTITY SUM : ", total_quantity_sum.current);
          console.log("TOTAL PRODCUCT AMOUNT SUM : ", total_product_amount_sum.current);
          console.log("TOTAL EXTRA CHARGES SUM : ", total_extra_charges_sum.current);
          console.log("TOTAL AMOUNT SUM : ", total_amount_sum.current);
          console.log("TOTAL RETURN QUANTITY SUM : ", total_return_quantity_sum.current);
          console.log("TOTAL RETURN QUANTITY AMOUNT SUM : ", total_return_quantity_amount_sum.current);
          console.log("TOTAL SAVE QUANTITY SUM : ", total_save_quantity_sum.current);
          console.log("TOTAL SAVE QUANTITY AMOUNT SUM : ", total_save_quantity_amount_sum.current);
        if (res.data.detail.data.length > 0) {
          deliverySummaryList = res.data.detail.data; 
        }
        
      }
    }
    catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === 'ERR_NETWORK') {
        console.log("Not able to reach target server please try after sometime");
      }
      else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === 'FAILED') {
          console.log('Request failed due to ', error.response.data.detail.res_data);
         
        }
      }
    }
    return [retState, deliverySummaryList];
  }
  

  
  useEffect(() => {
    console.log("useEffect method called ...");
    
    (async () => {
      var [retState, deliverySummaryList] = await getAllSummaryList();
      if (retState) {
        setSummaryList(deliverySummaryList);
        //setDcListFetched(true);
        
       
      }
      var [retState, companyInfoTmp] = await getCompanyInfo();
      if (retState) {
        setCompanyInfo(companyInfoTmp);
      }
    })();
    
    setToRender(true);
  }, [])

  return (
    <PDFViewer style={styles.viewer}>
      {toRender === true &&
        <Document>
          <Page size="A4" orientation="landscape" style={styles.page}>
            
           
              
              <View style={{ flexDirection: 'row',borderBottomWidth: 1, borderBottomColor: '#a7a5a5' }}>
              
                <View style={{ textAlign: 'right', width: '25%',paddingRight: "70px"  }}>
                  <Image style={{ marginHorizontal: '10%', width: 'auto', height: 'auto', height: "64px", width: "64px"}} src={logo_left} />
                </View>
                <View style={{ flex: 1, width: "50%", alignItems: "center", borderBottomColor: '#a7a5a5'}} key="address-key">
                  <Text style={{ fontSize: "20px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>{companyInfo.name}</Text>
                  <Text>{companyInfo.address_line1}</Text>
                  <Text>{companyInfo.address_line2}-{companyInfo.pincode}</Text>
                  <Text>{companyInfo.district_name}, {companyInfo.state_name}</Text>
                  <Text style={{ fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica' }}><Text style={{ fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Mobile:</Text> {companyInfo.phone}</Text>
                </View>
                <View style={{ textAlign: 'right', width: '25%',paddingLeft: '120px' }}>
                  <Image style={{ marginHorizontal: '10%', width: 'auto', height: 'auto', height: "64px", width: "64px" }} src={logo_right} />
                </View>
              </View>
            
      
      
            <View style={{ width: "100%", alignItems: "center",/* paddingLeft: "20px",*/ textAlign: 'center', borderBottomWidth: 1, borderBottomColor: '#a7a5a5', paddingTop: "5px" }}>
              <Text style={{ fontSize: "12px", textAlign: 'center', alignContent: 'center', fontFamily: 'Helvetica-Bold', color: '#646363' }}>Delivery Summary Report ({formatDateIndian(new Date(startDateStr))} To {formatDateIndian(new Date(endDateStr))})</Text>
            </View>

            <hr style={{paddingBottom: "10px"}}/>
            {/*
            <View style={{ width: "75%", alignItems: "left", paddingRight: "50px", paddingTop: "20px", textAlign: 'left' }}>
              <Text style={{ fontSize: "10px", textAlign: 'left', alignContent: 'left', fontFamily: 'Helvetica-Bold' }}>summary</Text>
            </View>
            */}
            

            {/*
            <View style={{ width: "75%", alignItems: "left", paddingRight: "50px", paddingTop: "20px", textAlign: 'left' }}>
              <Text style={{ fontSize: "10px", textAlign: 'left', alignContent: 'left', fontFamily: 'Helvetica-Bold' }}>{reportData.stock_ason_date.title}</Text>
            </View>
            */}
            <View style={styles.tableContainer}>
              
              <View style={styles.container}>
                <Text style={styles.sno}>S.No</Text>
                <Text style={styles.delivery_date}>Date</Text>
              
                <Text style={styles.customer_name}>Customer</Text>
                <Text style={styles.address_line1}>Site</Text>
                <Text style={styles.product_name}>Grade</Text>
                <Text style={styles.total_quantity}>Vol(CUM)</Text>
                <Text style={styles.unit_price}>Rate</Text>
                <Text style={styles.product_price}>Amount</Text>
                <Text style={styles.extra_charges}>Ext Charges</Text>
                <Text style={styles.total_amount}>Total</Text>
                <Text style={styles.return_quantity}>Rtn Qty</Text>
                <Text style={styles.return_quantity_amount}>Rtn Amt</Text>
                <Text style={styles.save_quantity}>Save Qty</Text>
                <Text style={styles.save_quantity_amount}>Save Amt</Text>
                
              </View>

              <View style={{flexGrow: 1}}>
              {summaryList.map((summary, idx) => (
              <View style={styles.row} key="items-${idx}">
                    <Text style={styles.snoData}>{idx + 1}</Text>
                    <Text style={styles.delivery_dateData}>{formatDateIndian(new Date(summary.delivery_date))}</Text>
                    <Text style={styles.customer_nameData}>{summary.customer_name}</Text>
                    <Text style={styles.address_line1Data}>{summary.address_line1}</Text>
                    <Text style={styles.product_nameData}>{summary.product_name}</Text>
                    <Text style={styles.total_quantityData}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(summary.total_quantity).toFixed(2)))}</Text>
                    <Text style={styles.unit_priceData}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(summary.unit_price).toFixed(2)))}</Text>
                    <Text style={styles.product_priceData}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat((summary.total_quantity) * parseFloat(summary.unit_price)).toFixed(2)))}</Text>                    
                    <Text style={styles.extra_chargesData}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(summary.diesel_charges) + parseFloat(summary.pumping_charges) + parseFloat(summary.others_charges1)))}</Text>
                    <Text style={styles.total_amountData}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(summary.total_amount).toFixed(2)))}</Text>
                    <Text style={styles.return_quantityData}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(summary.return_quantity).toFixed(2)))}</Text>
                    <Text style={styles.return_quantity_amountData}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(summary.return_quantity_amount).toFixed(2)))}</Text>
                    <Text style={styles.save_quantityData}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(summary.save_quantity).toFixed(2)))}</Text>
                    <Text style={styles.save_quantity_amountData}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(summary.save_quantity_amount).toFixed(2)))}</Text>
                    
              </View>
              ))}
              </View>


                {/*
                {summaryList.map((item, idx) => (
                  <View style={styles.row} key="items">
                    <Text style={styles.snoData}>{idx + 1}</Text>
                    <Text style={styles.product_nameData}>{item.product_name}</Text>
                    <Text style={styles.stock_openData}>{item.stock_open}</Text>
                    <Text style={styles.stock_inData}>{item.stock_in}</Text>
                    <Text style={styles.stock_outData}>{item.stock_out}</Text>
                    <Text style={styles.stock_availableData}>{item.stock_available}</Text>
                    <Text style={styles.stock_minData}>{item.stock_min}</Text>
                    <Text style={styles.stock_maxData}>{item.stock_max}</Text>
                  </View>
                ))}
                */}
              
              </View>
              <View style={{...styles.row,borderBottomWidth: 1, borderBottomColor: '#a7a5a5',paddingBottom: "5px"}} key="items-${idx}">
                    <Text style={styles.snoData}></Text>
                    <Text style={styles.delivery_dateData}></Text>
                    <Text style={styles.customer_nameData}></Text>
                    <Text style={styles.address_line1Data}></Text>
                    <Text style={{...styles.product_nameData,fontSize: "10px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>Total</Text>
                    <Text style={{...styles.total_quantityData,textAlign: 'right', fontSize: "10px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(total_quantity_sum.current).toFixed(2)))}</Text>
                    <Text style={styles.unit_priceData}></Text>
                    <Text style={{...styles.product_priceData,textAlign: 'right', fontSize: "10px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(total_product_amount_sum.current).toFixed(2)))}</Text>                    
                    <Text style={{...styles.extra_chargesData,textAlign: 'right', fontSize: "10px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(total_extra_charges_sum.current).toFixed(2)))}</Text>
                    <Text style={{...styles.total_amountData,textAlign: 'right', fontSize: "10px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(total_amount_sum.current).toFixed(2)))}</Text>
                    <Text style={{...styles.return_quantityData,textAlign: 'right', fontSize: "10px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(total_return_quantity_sum.current).toFixed(2)))}</Text>
                    <Text style={{...styles.return_quantity_amountData,textAlign: 'right', fontSize: "10px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(total_return_quantity_amount_sum.current).toFixed(2)))}</Text>
                    <Text style={{...styles.save_quantityData,textAlign: 'right', fontSize: "10px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(total_save_quantity_sum.current).toFixed(2)))}</Text>
                    <Text style={{...styles.save_quantity_amountData,textAlign: 'right', fontSize: "10px", fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(total_save_quantity_amount_sum.current).toFixed(2)))}</Text>
                    </View>
              <hr style={{paddingBottom: "40px"}}/>

            <View style={{flexDirection: 'row'}}>
            
            <Text style={{textAlign: 'left', flex: 1}} render={({ pageNumber, totalPages }) => (
                `Printed On: ${formatPrintDate(new Date())}`
            )} fixed />
            
            <Text style={{ textAlign: 'center', alignContent: 'center' }}>..... End of Report .....</Text>
              <Text style={{flex: 1, textAlign: 'right', justifyContent: 'flex-end', alignSelf: 'flex-end'}} render={({ pageNumber, totalPages }) => (
                `Page No : ${pageNumber} / ${totalPages}`
              )} fixed />
              </View>
              

          </Page>
        </Document>
      }
    </PDFViewer>
  );
}

export default PrintDeliveryReturnSave;