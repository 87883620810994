import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  Fragment,
} from "react";
import Table from "react-bootstrap/Table";
import { fragment } from "react";

import Select from "react-select";

import {
  Form,
  Button,
  Row,
  Col,
  Alert,
  Modal,
  Container,
  Spinner,
  Image
} from "react-bootstrap";
import Sidebar2 from "./Sidebar2";
import SidebarMain from "./SidebarMain";

import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsCardText,
  BsFillPrinterFill
} from "react-icons/bs";

import "../App.css";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { client } from "../config/Config";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';


function ListCustomers() {
  const initialized = useRef(false);
  var navigate = useNavigate();
  const [checkedState, setCheckedState] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  let customerArr = [
    {
      name: "Deppam Constructors",
      Contact_person: "Karthi",
      Place: "Tirupur",
      active: false,
    },
    {
      name: "ABC constructors",
      Contact_person: "Murali",
      Place: "Coimbatore",
      active: false,
    },
    {
      name: "ADP Promotors",
      Contact_person: "Ram",
      Place: "Avinashi",
      active: false,
    },
  ];
  let placeArr = [
    {"place": "avn"},
    {"place": "Coimbatore"},
    {"place": "Tiruppur"},
    {"place": "avn"},
    {"place": "Coimbatore"}, 
    {"place": "Sevur"}
    ];
  const unique = [...new Set(placeArr.map(item => item.place))];
  console.log(unique);

  const [checked, setChecked] = useState(false);

  const [customerList, setCustomerList] = useState([]);
  const [customerListFull, setCustomerListFull] = useState([]);
  const [toRender, setToRender] = useState(false);
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("Please login again");
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );

  const [selectedId, setSelectedId] = useState(-1);

  const [showDialog, setShowDialog] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [customerIdSelect, setCustomerIdSelect] = useState({});
  const [optCustomer, setOptCustomer] = useState([]);
  const [placeIdSelect, setPlaceIdSelect] = useState({});
  const [optPlace, setOptPlace] = useState([]);

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const handleShowDialog = () => {
    setShowDialog(true);
  };
  const updateCustomerStatus = async (selectedId) => {
    var selectedObj = customerList[selectedId];
    let reqStatusUpdatePayload = {
      id: selectedObj.id,
      is_active: !selectedObj.is_active,
    };

    console.log("payload:", reqStatusUpdatePayload);

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}


      var res = await axios.post(
        client.domain + "/crm/activateCustomer",
        reqStatusUpdatePayload,
        { headers },
        { withCredentials: false }
      );
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        //console.log("Response message from server : ", res.data.detail.res_data);
        //console.log("Response message from server userInfo : ", res.data.detail.res_data.user_info);
        //console.log("Response message from server aI : ", res.data.detail.res_data.aI);
        // Remove login session from localstorage
        //localStorage.removeItem("username");
        //localStorage.removeItem("_aI")
        //navigate("/auth");

        var itemSelectedUpdate = customerList[selectedId];
        itemSelectedUpdate.is_active = !itemSelectedUpdate.is_active;
        console.log(
          "<<<<<<<<<<<<<<<<< ITEM SELECTED MODIFIED ********** ",
          itemSelectedUpdate
        );

        const newitems = customerList.slice();
        newitems[selectedId] = itemSelectedUpdate;
        setCustomerList(newitems);

        setShowDialog(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle("LOGIN FAILED!");
          //setShow(true);
        }
      }
    }
    setShowDialog(false);
  };

  const handleSwitchAction = () => {
    console.log("user confirmed to enable or disable ...");
    console.log("user selected id for action is : ", selectedId);

    // call update API
    updateCustomerStatus(selectedId);
  };

  const actionEditHandle = (e, data) => {
    console.log("Handle action edit Clicked : ", e);
    //console.log("Handle Clicked edit DATA : ", data);
    console.log("Handle action edit Clicked target : ", e.target);
    console.log(
      "Handle action edit Clicked target Current : ",
      e.currentTarget
    );
    console.log(
      "Handle action edit Clicked target Current id : ",
      e.currentTarget.id
    );
    console.log("Handle action edit Clicked target ID : ", e.target.id);
    console.log("Handle action edit Clicked target NAME : ", e.target.name);
  };
  const getCustomerList = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
    

      var res = await axios.get(
        client.domain + "/crm/getAllCustomers",
        { headers },
        { withCredentials: false }
      );
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", 0);
        setCustomerList(res.data.detail.data);
        setCustomerListFull(res.data.detail.data);
        setShowMsgTitle("Customer Info Added Successfully");
        setToRender(true);
        //navigate("/dashboard");
        //setDcReportData(res.data.detail.res_data);
        //setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          setShowMsg(error.response.data.detail);
          setShowMsgTitle("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
  };
  {
    /*
  const handleAddAction = () => {
    console.log("user confirmed to added or not ...");
    console.log("user selected id for action is : ", selectedId);

    // call update API
    updateCustomerStatus(selectedId);
  };
*/
  }

  const handleStateChange = (e) => {
    console.log("clicked Value :", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);

    var currentId = name.substring(name.lastIndexOf("-") + 1);
    var nameOfElement = name.substring(0, name.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);
  };
  const SwitchComponent = () => {};

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };
  

  const handleCustomerSelect = (e, data) => {
    console.log("handleCustomerSelect called ...", e);

    var customerListFilter = []

    if ( e.value === 0 ) {
      setCustomerList(customerListFull);
    }
    else {
      customerListFull.map((cust) => {
        if ( cust.id === e.value ) {
          customerListFilter.push(cust);
        }
      })
      setCustomerList(customerListFilter);
    }
    setCustomerIdSelect(e);
  };
  const handlePlaceSelect = (e, data) => {
    console.log("hadlePlaceSelect called ...", e);
    var customerListFilter = []
    if ( e.value === 0 ) {
      setCustomerList(customerListFull);
    }
    else {
      customerListFull.map((cust) => {
        if ( cust.address_line2 === e.label ) {
          customerListFilter.push(cust);
        }
      })
      console.log("customerListFilter :", customerListFilter);
      setCustomerList(customerListFilter);
    }
    //setOptPlace(placeArr);    
    setPlaceIdSelect(e);
    
  }


  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::ListCustomers called ##########################"
      );
      getCustomerList();
      initialized.current = true;
      ( async() => {
        var [retState, customerArr, placeListArrRet] = await getCustomerListDropdown();
        if ( retState ) {
          setOptCustomer(customerArr);
          setCustomerIdSelect(customerArr[0]);

          setOptPlace(placeListArrRet);
          setPlaceIdSelect(placeListArrRet[0]);
        }
      })();
    }
  }, []);

  const handleAdd = async (e) => {
    console.log("handle add customers called : ", e);
    navigate("/addUpdateCustomer", {
      state: { passed_element: [], action: "add" },
    });
  };

  const handleView = async (e) => {
    console.log("handle edit customers called : ", e);
    console.log(
      "handle edit customers currentTarget called : ",
      e.currentTarget
    );
    console.log("handle edit customers target called : ", e.target);
    var selectedTagId = e.currentTarget.id;
    console.log("SELECTED TAG ID: ", selectedTagId);
    if (selectedTagId) {
      var currentId = selectedTagId.substring(
        selectedTagId.lastIndexOf("-") + 1
      );
      var nameOfElement = selectedTagId.substring(
        0,
        selectedTagId.lastIndexOf("-")
      );
      console.log("Name of element VIEW: ", nameOfElement);
      console.log("Current id VIEW : ", currentId);

      console.log(
        "<<<<<<<<<<<<<<<<< ITEM SELECTED VIEW ********** ",
        customerList[currentId]
      );
      navigate("/addUpdateCustomer", {
        state: {
          passed_element: customerList[currentId],
          action: "view",
        },
      });
    }
  };

  const handleOnChange = async (e) => {
    console.log("handle on change called : ", e);

    var selectedTagId = e.target.id;
    console.log("SELECTED TAG ID: ", selectedTagId);
    var currentId = selectedTagId.substring(selectedTagId.lastIndexOf("-") + 1);
    var nameOfElement = selectedTagId.substring(
      0,
      selectedTagId.lastIndexOf("-")
    );
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    console.log(
      "<<<<<<<<<<<<<<<<< ITEM SELECTED ********** ",
      customerList[currentId]
    );

    setSelectedId(currentId);

    setShowDialog(true);
  };

  const handleAlertClose = async (e) => {
    setShow(false);
    navigate(-1);
  };

  const getCustomerListDropdown = async () => {
    console.log("getCustomerListDropdown method called ...");
    var retState = false;
    
  
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(
        client.domain + "/crm/getAllCustomers",
        { headers },
        { withCredentials: false }
      );
      
      console.log(
        "Response from server getCustomerList : ",
        res.data.detail.data
      );
      if (res.data.detail.status === "SUCCESS") {
        console.log(
          "Response message from server getCustomerList : ",
          res.data.detail.data
        );
        
  
        var customerArr = [];
        var custAll = { value: 0, label: 'All Customers'};
        customerArr.push(custAll);
        res.data.detail.data.map((itemCustomer, idx) => {
          console.log("Ind val : ", itemCustomer);
          var customerInd = {
            value: itemCustomer.id,
            label: itemCustomer.name,
          };
          console.log("In customer modified : ", customerInd);
          customerArr.push(customerInd);
        });


        var keySearch = "address_line2";
        //let a = [{"place": "avn"}, {"place": "Coimbatore"}, {"place": "Tiruppur"}, {"place": "avn"}, {"place": "Coimbatore"}, {"place": "Sevur"}];
        const uniquePlaceList = [...new Map(res.data.detail.data.map(item => [item[keySearch], item])).values()];
        console.log("UNQUE PLACE LIST : ", uniquePlaceList);

        var placeListArrRet = [];
        var placeAll = {value: 0, label: "All Sites"};
        placeListArrRet.push(placeAll);
        uniquePlaceList.map((place, idx) => {
          var placeObj = { value: idx + 1, label: place.address_line2};
          placeListArrRet.push(placeObj);
        })
        console.log("FULL PLACE LIST :", placeListArrRet);

  
        console.log("Mod Full VALUE : ", customerArr);
        retState = true;
      }
     
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.data
          );
         
        }
      }
    }
    return [retState, customerArr, placeListArrRet];
  };
  const handleReset = async (e) => {
    console.log("handle reset clicked : ", e);
    navigate(0);
    //setOptCustomer(customerListFull);
  };

  const handleCustomerPrint = async (e) => {
   //navigate("/printCustomer")
    
    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(
      urlStr.lastIndexOf(client.urlDelimiter) + 1
    );
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);

    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printCustomer?`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0,0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
    
    
  };

  //var filename_gen = "customer";
 
  return (
    <Container>
      <SidebarMain />
      { toRender ?  
      <form>
          <Row>
              <Col xs={2} md={2} sm={2} lg={2}> 
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>Customer</span>
           
             </Col>
             <Col xs={1} md={1} sm={1} lg={1} style={{paddingTop: '8px'}} > 
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1rem",verticalAlign:"bottom" }}>Customer:</span>
           
             </Col>
             <Col xs={2} md={2} sm={2} lg={2}>
              <Select
                    inline
                    id="customer"
                    name="customer"
                    value={{
                      value: customerIdSelect.value,
                      label: customerIdSelect.label,
                    }}
                    options={optCustomer}
                    defaultValue={{
                      value: customerIdSelect.value,
                      label: customerIdSelect.label,
                    }}
                    onChange={handleCustomerSelect}
                    //isDisabled={isReadOnly}
                  />
                  
            </Col> 
            <Col xs={1} md={1} sm={1} lg={1} style={{paddingTop: '8px'}} > 
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1rem",verticalAlign:"bottom" }}>Site:</span>
           
             </Col>
             <Col xs={2} md={2} sm={2} lg={2}>
              
              <Select
                    inline
                    id="place"
                    name="place"
                    value={{
                      value: placeIdSelect.value,
                      label: placeIdSelect.label,
                    }}
                    options={optPlace}
                    defaultValue={{
                      value: placeIdSelect.value,
                      label: placeIdSelect.label,
                    }}
                    onChange={handlePlaceSelect}
                    //isDisabled={isReadOnly}
                  />
                  </Col>
                 <Col xs={1} md={1} sm={1} lg={1}> 
                   
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleReset(e)}
                    >
                      Reset
                    </Button>  
            </Col>           
            <Col xs={3} md={3} sm={3} lg={3} className="d-flex justify-content-end float-end">
            <Button id="customerPrint" name = "customerPrint"  onClick={(e)  => handleCustomerPrint(e)}
            //filename={filename_gen}
            >
                <BsFillPrinterFill
                 size={18}
                 style={{ color: "white", cursor: "pointer" }}
                 name={`print-customer-icon`}
                 id={`print-customer-icon`}
                 />
                 </Button>
                 &nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                variant="warning"
                id="add-btn-id"
                name="add-btn-name"
                className="justify-content-end float-end"
                onClick={(e) => handleAdd(e)}
              >
                New Customer
              </Button>
            </Col>
          </Row>
          <hr align="center" />
          <div className="ui-divider"></div>

          <Table striped bordered hover>
            <thead>
              <tr className="table-primary">
                <th className="table-row-heading" style={{textAlign: "center"}}>S.No</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Name</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Place</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Contact Person</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Contact Number</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>GST Number</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Active</th>
                <th className="table-row-heading" style={{textAlign: "center"}}>Details</th>
              </tr>
            </thead>
            <tbody>
              {customerList.map((customer, idx) => (
                <tr>
                  <td style={{textAlign: "right"}}>{idx + 1}</td>

                  <td style={{textAlign: "left"}}>{customer.display_name}</td>
                  <td style={{textAlign: "left"}}>{customer.address_line2}</td>
                  <td style={{textAlign: "left"}}>{customer.contact_person}</td>
                  <td style={{textAlign: "right"}}>{customer.contact_person_number}</td>
                  <td style={{textAlign: "right"}}>{customer.gst_no}</td>

                  <td style={{textAlign: "center"}}>
                    <Form.Check // prettier-ignore
                      key={`customer-switch-key-${idx}`}
                      type="switch"
                      id={`customer-switch-${idx}`}
                      name={`customer-switch-${idx}`}
                      onChange={handleOnChange}
                      checked={customer.is_active}
                    ></Form.Check>
                  </td>
                  <td style={{textAlign: "center"}}>
                    <BsCardText
                      key={`edit-btn-customer-key-${idx}`}
                      size={28}
                      style={{ color: "#0D6EFD", cursor: "pointer" }}
                      onClick={(e) => handleView(e)}
                      name={`edit-icon-customer-name-${idx}`}
                      id={`edit-icon-customer-id-${idx}`}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
      
      <Modal
        show={showDialog}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Activae / In-Activate this customer? Click OK to proceed...
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSwitchAction}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
       </form>
       : 
       <div className="container-spinner">
      <div className="vertical-center-spinner">
       {/*<Button variant="primary" disabled>*/}
       <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{ width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
 
       {/*</Button>*/}
       </div>
       </div>
 }
     </Container>
   
  );
}
export default ListCustomers;
