
import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  Label,
  Badge,
  Table,
  Spinner,
  Image,
  Modal
} from "react-bootstrap";
import "../Dashboard.css";
import "../App.css";
import { useNavigate } from "react-router-dom";

import Select from "react-select";

import { client, ConfigMinValue, configMaxValue, appGlobal } from "../config/Config";
import axios from "axios";

import { useLocation } from "react-router-dom";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import { BsCartX, BsArrowLeftCircle, BsFillXCircleFill, BsFillPrinterFill, BsPencilSquare } from "react-icons/bs";
import SidebarMain from "./SidebarMain";

import { formatDate, formatTime } from "./utils";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';
import { AsyncTypeahead } from "react-bootstrap-typeahead";

const AddUpdateEmpPayment = (async) => {

  let initialItems = [];
  const intialValues = {
    id: 0,
    payment_number: "",
    received_from: "",
    amount: 0.00,
    ref_number: "",
    towards: "",
    received_against: "",
    pay_received_by: "",
    payment_date: formatDate(new Date()),
    ref_date: formatDate(new Date()),
    //received_mode: "CASH",
    supplier_id: 0,
    payment_by_name: "",
    received_type: "",
    addl_notes: "",
    //paid_to_name: "",
  };

  let invArr = [

    { value: 657, label: " INV001 - 12-12-2023 - 2000" },
    { value: 876, label: " INV002 - 10-1-2023 - 3000" },
    { value: 978, label: " INV003 - 05-2-2023  - 5000" },

  ]
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  //const [receivedSaved, setReceivedSaved] = useState(false);
  const [toRender, setToRender] = useState(false);
  const [pendingOrderList, setPendingOrderList] = useState([]);
  const [orderPendingLoaded, setOrderPendingLoaded] = useState(false);
  const [isLoadingSupp, setIsLoadingSupp] = useState(false);
  const [isLoadingInv, setIsLoadingInv] = useState(false);
  const [optionsSearch, setOptionsSearch] = useState([]);
  const [optionsSearchInv, setOptionsSearchInv] = useState([]);
  const [customerSearchText, setCustomerSearchText] = useState("");
  const [invoiceSearchText, setInvoiceSearchText] = useState("");
  const [searchedCustomer, setSearchedCustomer] = useState({});
  const [items, setItems] = useState(initialItems);
  const [selectedDCItemRow, setSelectedDCItemRow] = useState(0);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [submitPrintAction, setSubmitPrintAction] = useState(false);
  const [submitAction, setSubmitAction] = useState(false);

  const [orderSelectedIndex, setOrderSelectedIndex] = useState([])
  const initialized = useRef(false);
  const navigate = useNavigate();
  const { state } = useLocation();

  

  const [optReferenceInvoice, setOptReferenceInvoice] = useState([]);
  const [referenceInvoiceIdSelect, setReferenceInvoiceIdSelect] = useState({ value: 0, label: "Please select" });
  const [receiptInfoDet, setReceiptInfoDet] = useState({});

  const [showAlert, setShowAlert] = useState();
  const [alertPurpose, setAlertPurpose] = useState('init');
  const [alertTitle, setAlertTitle] = useState('Success');
  const [alertBody, setAlertBody] = useState('Receipt Saved Successfully');
 
  const initPaymentType = [{value: "OUTSTANDING", label: "OUTSTANDING"}, {value: "ADVANCE", label: "ADVANCE"}]
  const [paymentType, setPaymentType] = useState([]);
  const [selectedPaymentType, setSelectedPaymentType] = useState({});

  var validateErr = false;


 

  const [optPaymentMode, setOptPaymentMode] = useState([]);
  const [paymentModeIdSelect, setPaymentModeIdSelect] = useState({});

  const [optReceivedBy, setOptReceivedBy] = useState([]);
  const [receivedByIdSelect, setReceivedByIdSelect] = useState({});


  const receivedFromList = [
    { value: "techm", label: "TECH MAHINDRA" },
    { value: "comviva", label: "COMVIVA" },
    { value: "rockbreeze", label: "ROCKBREEZE" },
    { value: "mthsense", label: "Mth Sense" }
  ];
  const [optPaidBy, setOptPaidBy] = useState([]);
  const [paidByIdSelect, setPaidByIdSelect] = useState({});

  const [optPaidTo, setOptPaidTo] = useState([]);
  const [paidToIdSelect, setPaidToIdSelect] = useState({});

  const getEmployeeList = async () => {
    var retState = false;
    var paidByArr = [];
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
      var res = await axios.get(client.domain + "/paymentReceiveEmployeeList", { headers }, { withCredentials: false });
      console.log("Response from server getEmployeeList : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getEmployeeList : ", res.data.detail.res_data);

        /*
        var custArr = [];
        res.data.detail.res_data.getEmployeeList.map((cust) => {
          var custInd = { value: cust.id, label: cust.name, id: cust.id, name: cust.name};
          custArr.push(custInd);
        })
        */
        paidByArr = res.data.detail.res_data.employee_list;
        /*
        setOptReceivedBy(res.data.detail.res_data.employee_list);
        if (res.data.detail.res_data.employee_list.length > 0) {
          setReceivedByIdSelect(res.data.detail.res_data.employee_list[0]);
        }
        */
        setToRender(true);
        retState = true;
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");

      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);

        }
      }
    }
    return [retState, paidByArr]
  };

  const getPaidToList = async () => {
    var retState = false;
    var paidToArr = [];
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
      var res = await axios.get(client.domain + "/paymentReceiveEmployeeList", { headers }, { withCredentials: false });
      console.log("Response from server getEmployeeList : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getEmployeeList : ", res.data.detail.res_data);

        /*
        var custArr = [];
        res.data.detail.res_data.getEmployeeList.map((cust) => {
          var custInd = { value: cust.id, label: cust.name, id: cust.id, name: cust.name};
          custArr.push(custInd);
        })
        */
        paidToArr = res.data.detail.res_data.employee_list;
        /*
        setOptReceivedBy(res.data.detail.res_data.employee_list);
        if (res.data.detail.res_data.employee_list.length > 0) {
          setReceivedByIdSelect(res.data.detail.res_data.employee_list[0]);
        }
        */
        setToRender(true);
        retState = true;
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");

      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);

        }
      }
    }
    return [retState, paidToArr]
  };

  

  const getPaymentType = async () => {
    var retState = false;
    var retPaymentType = [];

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
      var res = await axios.get(client.domain + "/getEmployeePaymentType", { headers }, { withCredentials: false });
      console.log("Response from server getPaymentType : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getPaymentMode : ", res.data.detail.data);
        //setPaymentType(res.data.detail.data);
        retPaymentType = res.data.detail.data;
      //setPendingOrderList(res.data.detail.data);
        /*
        res.data.detail.data.map((cust) => {
          var paymentInd = { value: cust, label: cust };
          retPaymentType.push(paymentInd);
        })
        */
      
       //paymentModeArr = res.data.detail.data;

        //setOptReceivedFrom(custArr);
        //setReceivedFromIdSelect(custArr[0]);
        //setOptReceivedMode(receivedModeArr);
        //setReceivedModeIdSelect(receivedModeArr[0]);
        setToRender(true);

        retState = true;
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");

      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);

        }
      }
    }

    return [retState, retPaymentType];

  }

  const getPaymentMode = async () => {
    var paymentModeArr = [];
    var retState = false;
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
      var res = await axios.get(client.domain + "/getPaymentMode", { headers }, { withCredentials: false });
      console.log("Response from server getPaymentMode : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getPaymentMode : ", res.data.detail.data);

        /*
        res.data.detail.data.map((cust) => {
          var modeInd = { value: cust, label: cust };
          paymentModeArr.push(modeInd);
        })
        */
       paymentModeArr = res.data.detail.data;

        //setOptReceivedFrom(custArr);
        //setReceivedFromIdSelect(custArr[0]);
        //setOptReceivedMode(receivedModeArr);
        //setReceivedModeIdSelect(receivedModeArr[0]);
        setToRender(true);

        retState = true;
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");

      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);

        }
      }
    }
    return [retState, paymentModeArr]
  }

  /*
  const getPendingOrders = async (customer_id) => {

    try {
      //var customer_id = 227;
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
      var res = await axios.get(client.domain + "/getPendingOrders?" + "customer_id=" + customer_id, { headers }, { withCredentials: false });
      console.log("Response from server getPendingOrders : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getPendingOrders : ", res.data.detail.data);

        setPendingOrderList(res.data.detail.data);
        setOrderPendingLoaded(true);
        / *
        var receivedModeArr = [];
        res.data.detail.data.map((cust) => {
          var modeInd = { value: cust, label: cust};
          receivedModeArr.push(modeInd);
        })
        
        //setOptReceivedFrom(custArr);
        //setReceivedFromIdSelect(custArr[0]);
        setOptReceivedMode(receivedModeArr);
        setReceivedModeIdSelect(receivedModeArr[0]);
        setToRender(true);
        * /
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");

      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);

        }
      }
    }
  }
*/

  useEffect(() => {
    if (!initialized.current) {
      if (state !== null) {
        console.log("PASSED VALUE : ", state);
        if (state.action === 'view' || state.action === 'edit') {
          console.log("+++++++++++++++++++++inside useEffect edit set+++++++++++++++++++++", state.passed_element);
          setFormValues({
            ...formValues,
            id: state.passed_element.id,
            payment_number: state.passed_element.payment_number,
            payment_date: state.passed_element.payment_date,
            payment_mode: state.passed_element.payment_mode,
            paid_to:state.passed_element.paid_to,
           // paid_to_name:state.passed_element.paid_to_name,
           
            amount: state.passed_element.amount,
            //payment_type: state.passed_element.payment_type,
            towards: state.passed_element.towards,
            pay_received_by:state.passed_element.pay_received_by,
            payment_by_name:state.passed_element.payment_by_name,
            ref_date: state.passed_element.ref_date,
            ref_number: state.passed_element.ref_number,
            addl_notes: state.passed_element.addl_notes
          });
        }
      }


      if (state.action === "add") {
        setFormValues({ ...formValues, payment_type: 'Salary' });
        setIsReadOnly(true);
      }

      (async () => {
        //await getEmployeeList();
        /*
        if (state.action === 'edit' || state.action === 'view') {
          var [retState, invoice_list] = await searchHandleInvoiceFunc(state.passed_element.received_from);
        }
        */
        var [retStateMode, paymentModeArr] = await getPaymentMode();
        if (retStateMode) {
          console.log("PAYMENT MODE ARR : ", paymentModeArr);
          if (state.action === 'edit' || state.action === 'view') {
            setOptPaymentMode(paymentModeArr);
            var findMode = paymentModeArr.find(element => element.value === state.passed_element.payment_mode);
            if (findMode !== undefined) {
              console.log("++++++++++++++++++------------------- FOUND RECEIVED MODE : ", findMode);
              setPaymentModeIdSelect({ value: state.passed_element.payment_mode, label: findMode.label });
            }
            else {
              console.log("<<<<<<<<<<<<<< RECEIVED MODE NOT FOUND >>>>>>>>>>>>>>>");
            }
          }
          else {
            setOptPaymentMode(paymentModeArr);
            setPaymentModeIdSelect(paymentModeArr[0]);
          }
        }

        var [retStateby, paidByArr] = await getEmployeeList();
        if (retStateby) {
          if (state.action === 'view' || state.action === 'edit') {
            setOptPaidBy(paidByArr);
            var findBy = paidByArr.find(element => element.value === state.passed_element.payment_by);
            if (findBy !== undefined) {
              console.log("++++++++++++++++++------------------- FOUND EMPLOYEE : ", findBy);
              setPaidByIdSelect({ value: state.passed_element.payment_by, label: findBy.label });
            }
            else {
              console.log("<<<<<<<<<<<<<< EmPLOYEE NOT FOUND >>>>>>>>>>>>>>>");
            }
          }
          else {
            setOptPaidBy(paidByArr);
            setPaidByIdSelect(paidByArr[0]);
          }
        } 


        var [retStateby, paidToArr] = await getPaidToList ();
        if (retStateby) {
          if (state.action === 'view' || state.action === 'edit') {
            setOptPaidTo(paidToArr);
            var findTo = paidToArr.find(element => element.value === state.passed_element.paid_to);
            if (findTo !== undefined) {
              console.log("++++++++++++++++++------------------- FOUND EMPLOYEE : ", findBy);
              setPaidToIdSelect({ value: state.passed_element.paid_to, label: findTo.label });
            }
            else {
              console.log("<<<<<<<<<<<<<< EmPLOYEE NOT FOUND >>>>>>>>>>>>>>>");
            }
          }
          else {
            setOptPaidTo(paidToArr);
            setPaidToIdSelect(paidToArr[0]);
          }
        } 


        /*
        if ( receivedFromIdSelect && receivedFromIdSelect.value > 0 ) {
        console.log("customer Id selected : ", receivedFromIdSelect);
        await getPendingOrders(receivedFromIdSelect.value);
        }
        */
        
        var [retStateType, retPaymentType] = await getPaymentType();
        if ( retStateType) {
          setPaymentType(retPaymentType);
          
          if ( state.action === 'view' || state.action === 'edit') {
          var findPaymentType = retPaymentType.find(element => element.value === state.passed_element.payment_type);
            if (findPaymentType !== undefined) {
              console.log("++++++++++++++++++------------------- FOUND PAYENT TYPE INFO ++++++++++++ : ", findPaymentType);
              setSelectedPaymentType({ value: state.passed_element.payment_type, label: findPaymentType.label });
            }
          }
          else {
            setSelectedPaymentType(retPaymentType[0]);
          }
        }
        
        setToRender(true);
      })();


      console.log("newObj :", formValues);
      if (state.action === "add") {
        setIsReadOnly(false);
      }
      initialized.current = true;
    }
  }, []);


  const handlePaymentDateChange = async (e) => {
    console.log("handleReceiptDateChange called ...", e);
    console.log("handleReceiptDateChange DATE called ...", e.target.value);

    setFormValues({ ...formValues, payment_date: e.target.value });
  };

  const handleChequeDateChange = async (e) => {
    console.log("handleChequeDateChange called ...", e);
    console.log("handleChequeDateChange DATE called ...", e.target.value);
    setFormValues({ ...formValues, ref_date: e.target.value });
  };

  /*
  const onChangeReceivedTime = (e) => {
    console.log("Date onChange Loading time called ...", e);
    console.log("Date onChange Loading time TIME called ...", e.target.value);
    
    setFormValues({ ...formValues, ["receipt_time"]: e.target.value });
  };
  */

  const handleBack = async (e) => {
    console.log("handle back called : ", e);
    navigate(-1);
  };

  const handlePaymentMode = (e) => {
    console.log("Handle Payment Mode Select Called : ", e);
    setPaymentModeIdSelect({ value: e.value, label: e.label });
  }

  const handleReferenceInvoice = (e) => {
    console.log("Handle Reference Invoice Select Called : ", e);
    setReferenceInvoiceIdSelect({ value: e.value, label: e.label });
  }

  const handlePaidBy = (e) => {
    console.log("Handle Received From Select Called : ", e);
    setPaidByIdSelect({ value: e.value, label: e.label });

    setOrderPendingLoaded(false);
    var customer_id = e.value;
    //getPendingOrders(customer_id);
  }

  const handlePaidTo = (e) => {
    console.log("Handle Paid To Select Called : ", e);
    setPaidToIdSelect({ value: e.value, label: e.label });

    setOrderPendingLoaded(false);
    var customer_id = e.value;
    //getPendingOrders(customer_id);
  }

  const handleChange = (e, data) => {
    console.log("Handle Change clicked : ", e);
    console.log("Handle Change clicked target : ", e.currentTarget);
    //console.log("HTML tag : ", data);
    const { name, value } = e.currentTarget;
    console.log("Captured Quantity name : ", name);
    console.log("Captured Quantity value : ", value);

    if (name === "amount") {
      console.log("amount item edited ...");
      if (e.currentTarget.value.match(appGlobal.numericRegExp)) {
        //setFormValues({ ...formValues, [name]: e.currentTarget.value.replace(/^0+/, '') });
        setFormValues({ ...formValues, [name]: value });
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  }

  const handleClear = async (e) => {
    e.preventDefault();
    console.log("handle clear called : ", e);
    console.log("Handle clear clicked current target ID: ", e.currentTarget.id);
    navigate(0);
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
   /*
    if (!values.paid_to_name) {
      errors.paid_to_name = "Supplier Name is required!";
      validateErr = true;

    }

    */
  
    if (paymentModeIdSelect.value === 'CHEQUE') {
      if (!values.ref_number) {
        errors.ref_number = "Reference Number is required!";
        validateErr = true;
      }
    }
    
    if (!values.amount) {
      errors.amount = " Amount is required!";
      validateErr = true;

    }
   /*
   if (!values.pay_received_by) {
    errors.pay_received_by = "Name Of Receiving Person required!"
    validateErr = true;
   }
   */

    console.log("BEFORE RETURN validate errors ...", validateErr);
    return errors;
  };

  const callApiToSubmitPayment = async (settledOrderIds, actionVal) => {
    console.log("ACTON VALUE : ", actionVal);
    console.log("PAYMENT MODE SELECT : ", paymentModeIdSelect.value);
    console.log("RECEIVED MODE SELECT : ", paidByIdSelect.value);
    var payment_id_tmp = 0;
    
    if ( state.action === 'view' || state.action === 'edit' ) {
      payment_id_tmp = formValues["id"];
    }
    
    var reqPayment = {
      "id": payment_id_tmp,
      "payment_number": formValues.payment_number || "",
      "payment_date": formValues.payment_date,
      "payment_mode": paymentModeIdSelect.value,
      "paid_to": paidToIdSelect.value,      
      "pay_received_by": formValues.pay_received_by,
      "payment_by": paidByIdSelect.value,
      "received_from": formValues.customer_id,
      "amount": formValues.amount,
      "towards": formValues.towards,
      "addl_notes": formValues.addl_notes,
      "ref_number": formValues.ref_number,
      "ref_date": formValues.ref_date,
      //"payment_by": formValues.payment_by || "",
      "payment_number": formValues.payment_number,
      "payment_type": selectedPaymentType.value

    };

    console.log("Receipt settle Request payload : ", reqPayment);

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
      
      if ( actionVal === 'save-print') {
        setSubmitPrintAction(true);
      }
      else {
        setSubmitAction(true);
      }

      var res = await axios.post(client.domain + "/insertPaymentEmployeeWOAdjust", reqPayment, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.data);

        if ( actionVal === 'save-print') {
          setSubmitPrintAction(false);
        }
        else {
          setSubmitAction(false);
        }
        setAlertPurpose("success");
        setAlertTitle("Success");
        
        if(state.action === "add"){
          setAlertBody("Employee Payment Saved Successfully!");
        }
        else{
          setAlertBody("Employee Payment Updated Successfully!");
        }
       
          if ( actionVal === 'save-print' ) {
            navigate(-1);
            //var receipt_id_tmp = res.data.detail.receipt_id;
            //console.log("RETURNED RECEIPT ID AFTER SUBMIT : ", receipt_id_tmp);

            var urlStr = window.location.href;
            var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
            var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

            console.log("CALLED URL : ", urlTmp);
            console.log("CALLED PAGE : ", paramsTmp);

            var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printEmpPayment?payment_id=${formValues["id"]}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
            winPrint.moveTo(0, 0);
            winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
          }
          else {
            setShowAlert(true);
          }  
      }
      else {
        console.log("FAILED PAYMENT INSERTION ...");
        setAlertPurpose("failure");
        setSubmitAction(false);
        setAlertTitle("Failure");
        if ( state.action === 'add') {
          setAlertBody("Employee Payment Save Failed!");
        }
        else {
          setAlertBody("Employee Payment Update Failed!");
        }
        
        setShowAlert(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);

      if (error.code === 'ERR_NETWORK') {
        console.log("Not able to reach target server please try after sometime");
        //setShowMsg('Please try after sometime');
        //setShowMsgTitle('Network Issue!');
        //setShow(true);
      }
      else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === 'FAILED') {
          console.log('Request failed due to ', error.response.data.detail.res_data);
          //setShowMsg('Please Re-Login');
          //setShowMsgTitle('Invalid Session!');
          //setShow(true);
          //navigate("/auth");
        }
      }
      setAlertPurpose("failure");
      setSubmitAction(false);
      setAlertTitle("Warning");
      setAlertBody("Unable to process your request at this moment.Please try after sometime!");
      setShowAlert(true);
    }
  }

  const handleSubmit = async (event, actionVal) => {
    event.preventDefault();
    console.log("<<<<<<<<<<<<<< handleSubmit called ...", actionVal);
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log("^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ", validateErr);

    var settledOrderIds = [];
    /*
    var totalSettleAmount = 0.00
    pendingOrderList.map((pendingOrder) => {
      if (pendingOrder.selected === true) {
        totalSettleAmount = totalSettleAmount + pendingOrder.amount_balance;

        settledOrderIds.push(pendingOrder);
      }
    })
    console.log("Total settlement amount selected : ", totalSettleAmount);
    console.log("Receipt amount entered : ", formValues.received_amount);
    */

    if ( !validateErr ) {
      await callApiToSubmitPayment(settledOrderIds, actionVal);
    }
    //setReceivedSaved(true);
  }

  const handlePrintPayment = async () => {
    console.log("Handle print receipt called ...");
    //navigate("/printCustReceipt")
    
    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);

    
    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printEmpPayment?payment_id=${formValues["id"]}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0, 0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
  }

  const handleCheckBoxOnChange = async (e, data) => {
    console.log("handleCheckBoxOnChange called event : ", e);
    console.log("handleCheckBoxOnChange called currentTarget : ", e.currentTarget);
    console.log("handleCheckBoxOnChange called currentTarget CHECKED STATE : ", e.currentTarget.checked);

    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelRowId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(0, clickedTagId.lastIndexOf("-"));

      console.log("current selected element name view : ", nameOfElement);
      console.log("Current SELECTED row id : ", curSelRowId);

      var selectedPendingOrder = pendingOrderList[curSelRowId];
      console.log("SELECTED PENDING ORDER LIST : ", selectedPendingOrder);

      if (e.currentTarget.checked === true) {
        selectedPendingOrder.selected = true;
      }
      else {
        selectedPendingOrder.selected = false;
      }

      var pendingOrderListNew = pendingOrderList.slice();
      pendingOrderListNew[curSelRowId] = selectedPendingOrder;
      setPendingOrderList(pendingOrderListNew);
    }
  };
  /*

  const getTotVolPerCustPerProd = async (customerId, productId, locationName, dc_date) => {
    console.log("SELECTED CUSTOMER ID : ", customerId);
    console.log("SELECTED PRODUCT ID : ", productId);

    var retState = false;
    var prodRes = {};
    var total_quantity_ret = 0.00;
    console.log("getTotVolPerCustPerProd called ... ");

    var userReq = {
      params: {
        customerId: customerId,
        productId: productId,
        location: locationName,
        dc_date: dc_date
      }
    };

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}`, SerialKey: 'HNGD8743JESWE' };
      var res = await axios.get(client.domain + "/getTotVolPerCustPerProd", userReq, { headers }, { withCredentials: false });
      console.log("Response from server getProductForLoad : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getTotVolPerCustPerProd : ", res.data.detail.res_data);
        //setShowConfigDialog(true);

        retState = true;
        total_quantity_ret = res.data.detail.res_data.total_quantity;

        var itemSelected = items[selectedDCItemRow];
        //itemSelected.total_quantity = total_quantity_ret + itemSelected.quantity;
        itemSelected.existing_quantity = parseFloat(total_quantity_ret);
        itemSelected.total_quantity = itemSelected.existing_quantity + parseFloat(itemSelected.quantity);
        var itemsNew = items.slice();
        itemsNew[selectedDCItemRow] = itemSelected;
        setItems(itemsNew);


        //console.log("PRODUCT LOAD STATUS SET TO TRUE")
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");

      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );

        }
      }
    }
    return [retState, total_quantity_ret];
  }
  */


  const supplierSearchSelectFunc = async (selected) => {
    console.log("**************** User selected value from search result +++++++++++++ : ", selected);
    formValues["paid_to"] = selected[0].name;
    if (selected.length > 0) {
      setSearchedCustomer(selected[0]);

      const response = await fetch(`${client.domain}/srm/getSupplier?id=${selected[0].value}`);
      const supplier_info = await response.json();
      console.log("SUPPLIER INFO : ", supplier_info.detail.data);
      //setIsLoading(false);

      //setFormValues({...formValues, [name]: value});
      //let entries = Object.entries(company_info.detail.res_data.company_info)
      //entries.map( ([key, val] = entry) => {
      //entries.map( ([key, val] = entry) => {
      for (const [key, value] of Object.entries(supplier_info.detail.data)) {
        //console.log("KEY : ", key);
        //console.log("VAL : ", val);
        console.log("KEY : %s, VAL: %s", key, value);

        //setFormValues({...formValues, [key]: value});
      }
      //setFormValues({ ...formValues, id: supplier_info.detail.data._info.customer_id, customer_name: customer_info.detail.res_data.customer_info.customer_name });
      setFormValues({ ...formValues, paid_to: supplier_info.detail.data.id})
      //var [retState, invoice_list] = await searchHandleInvoiceFunc(customer_info.detail.res_data.customer_info.customer_id);
      //console.log("++++++++++++++++++++++++++Before assign^^^^^^^^^^^^^^^^^^^^^^^^^^^")
      //setOptReferenceInvoice(invoice_list);
      //console.log("++++++++++++++++after+++++++++++++++")
      /*
      //setFormValues(customer_info.detail.res_data.customer_info);
      formValues["customer_id"] = customer_info.detail.res_data.customer_info.customer_id;
      formValues["customer_name"] = customer_info.detail.res_data.customer_info.customer_name;
      formValues["customer_disp_name"] = customer_info.detail.res_data.customer_info.customer_disp_name;
      formValues["contact_number"] = customer_info.detail.res_data.customer_info.contact_number;
      formValues["contact_person"] = customer_info.detail.res_data.customer_info.contact_person;
      formValues["address1"] = customer_info.detail.res_data.customer_info.address1;
      formValues["address2"] = customer_info.detail.res_data.customer_info.address2;
      formValues["dist_id"] = customer_info.detail.res_data.customer_info.district_id;
      formValues["district_name"] = customer_info.detail.res_data.customer_info.district_name
      formValues["state_id"] = customer_info.detail.res_data.customer_info.state_id;
      formValues["state_name"] = customer_info.detail.res_data.customer_info.state_name;
      formValues["pincode"] = customer_info.detail.res_data.customer_info.pincode;
      
      if ( customer_info.detail.res_data.customer_info.address1 ) {
        var dc_date_time = formValues["dc_date"] + " " + formValues["loading_start_time"];
        getTotVolPerCustPerProd(formValues["customer_id"], customer_info.detail.res_data.customer_info.address1, dc_date_time);
      }
      console.log("FORMVALUES 2 : ", formValues);
      */
    }
  };

  const invoiceSearchSelectFunc = async (selected) => {
    console.log("**************** User selected value from search result +++++++++++++ : ", selected);
    if (selected.length > 0) {
      setSearchedCustomer(selected[0]);

      const response = await fetch(`${client.domain}/getCustomerInfo?customer_id=${selected[0].value}`);
      const customer_info = await response.json();
      console.log("CUSTOMER INFO : ", customer_info.detail.res_data.customer_info);
      //setIsLoading(false);

      //setFormValues({...formValues, [name]: value});
      //let entries = Object.entries(company_info.detail.res_data.company_info)
      //entries.map( ([key, val] = entry) => {
      //entries.map( ([key, val] = entry) => {
      for (const [key, value] of Object.entries(customer_info.detail.res_data.customer_info)) {
        //console.log("KEY : ", key);
        //console.log("VAL : ", val);
        console.log("KEY : %s, VAL: %s", key, value);

        //setFormValues({...formValues, [key]: value});
      }

      setFormValues({ ...formValues, ref_invoice_number: customer_info.detail.res_data.customer_info.customer_id, ref_invoice_value: customer_info.detail.res_data.customer_info.customer_name });
      /*
      //setFormValues(customer_info.detail.res_data.customer_info);
      formValues["customer_id"] = customer_info.detail.res_data.customer_info.customer_id;
      formValues["customer_name"] = customer_info.detail.res_data.customer_info.customer_name;
      formValues["customer_disp_name"] = customer_info.detail.res_data.customer_info.customer_disp_name;
      formValues["contact_number"] = customer_info.detail.res_data.customer_info.contact_number;
      formValues["contact_person"] = customer_info.detail.res_data.customer_info.contact_person;
      formValues["address1"] = customer_info.detail.res_data.customer_info.address1;
      formValues["address2"] = customer_info.detail.res_data.customer_info.address2;
      formValues["dist_id"] = customer_info.detail.res_data.customer_info.district_id;
      formValues["district_name"] = customer_info.detail.res_data.customer_info.district_name
      formValues["state_id"] = customer_info.detail.res_data.customer_info.state_id;
      formValues["state_name"] = customer_info.detail.res_data.customer_info.state_name;
      formValues["pincode"] = customer_info.detail.res_data.customer_info.pincode;
      
      if ( customer_info.detail.res_data.customer_info.address1 ) {
        var dc_date_time = formValues["dc_date"] + " " + formValues["loading_start_time"];
        getTotVolPerCustPerProd(formValues["customer_id"], customer_info.detail.res_data.customer_info.address1, dc_date_time);
      }
      console.log("FORMVALUES 2 : ", formValues);
      */
    }
  };

  const handleAlertOk = async () => {
    setShowAlert(false);
    console.log("handleSaveOk clicked ...");
    if (alertPurpose === 'success') {
      navigate(-1);
      setAlertPurpose('init');
    }
    else if (alertPurpose === 'failure') {
      setAlertPurpose('init');
    }
  }

  const handleCloseDialog = async () => {
    setShowAlert(false);
    console.log("handleCloseDialog clicked ...");

    if ( alertPurpose === 'success') {
      navigate(-1);
      setAlertPurpose('init');
    }
    else  {
      setAlertPurpose('init');
    }
    /*
    if (dialogResStatus === 'success') {
      navigate(-1);
    }
    */
  };
  const searchHandleFunc = async (query) => {
    setIsLoadingSupp(true);

    fetch(`${client.domain}/search/suppliers?q=${query}`)
      .then((resp) => resp.json())
      .then((json) => {
        //searchActionFunc(json)
        setOptionsSearch(json.detail.res_data.supplier_list);
        setIsLoadingSupp(false);
        console.log("SEARCHED VALUE inside searchHandleFunc : ", json.detail.res_data.supplier_list);
      });
    //formValues["customer_name"] = query;
    //setFormValues({ ...formValues, ["customer_disp_name"]: query });
    /*
    if ( addrCopyCheck && !formValues.contact_person) {
      setFormValues({ ...formValues, ["shipContactName"]: query });
    }
    */
    //setFormValues({ ...formValues, ["customer_name"]: query });
    setCustomerSearchText(query);
  };

  /*
  const searchHandleInvoiceFunc = async (customer_id_pass) => {
    var retState = false;
    var invoice_list_ret = [];
    console.log("searchHandleInvoiceFunc called ...");
    setIsLoadingInv(true);
    fetch(`${client.domain}/search/invoice?customer_id=${customer_id_pass}`)
      .then((resp) => resp.json())
      .then((json) => {
        //searchActionFunc(json)
        if (json.detail.res_data.hasOwnProperty("invoice_list")) {
          console.log("Invoice List exist ...");
          console.log("SEARCHED VALUE inside searchHandleInvoiceFunc : ", json.detail.res_data.invoice_list);
          invoice_list_ret = json.detail.res_data.invoice_list;
          setOptReferenceInvoice(invoice_list_ret);
          var findInvoice = json.detail.res_data.invoice_list.find(element => element.value === state.passed_element.invoice_id);
            if (findInvoice !== undefined) {
              console.log("++++++++++++++++++------------------- FOUND INVOICE INFO ++++++++++++ : ", findInvoice);
              setReferenceInvoiceIdSelect({ value: state.passed_element.invoice_id, label: findInvoice.label });
            }
          //setOptionsSearchInv(json.detail.res_data.invoice_list);
          retState = true;
        }
        else {
          //setOptionsSearchInv([]);
        }
        //let testArr = [{ value:657, label: " INV001 - 12-12-2023 - 2000" }, { value:876, label: " INV002 - 10-1-2023 - 3000" },{ value:978, label: " INV003 - 05-2-2023  - 5000" }]
        //setOptionsSearchInv(testArr);
        setIsLoadingInv(false);

      });
    //formValues["customer_name"] = query;
    //setFormValues({ ...formValues, ["adderss1"]: query });SEARCHED VALUE inside searchHandleInvoiceFunc
    / *
    if ( addrCopyCheck && !formValues.contact_person) {
      setFormValues({ ...formValues, ["shipContactName"]: query });
    }
    * /
    //setFormValues({ ...formValues, ["customer_name"]: query });
    setInvoiceSearchText(customerSearchText);

    return [retState, invoice_list_ret];
  };
  */

  const handleEdit = async (e) => {
    console.log("handle edit  clicked : ", e);
    setIsReadOnly(false);
  };

  console.log("FORMVALUES FULL : ", formValues);
  console.log("INVOICE OPTIONS : ", optionsSearchInv);

  const handleRadioChange = async (e) => {
    console.log("Value button clicked : ", e);

    const { name, id } = e.currentTarget;
    console.log("Captured value name : ", name);
    console.log("Captured value id : ", id);

    var currentRowName = id.substring(id.lastIndexOf("-") + 1);
    var nameOfElement = id.substring(0, id.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current row id : ", currentRowName);
    //setFormValues({ ...formValues, ["received_type"]: currentRowName.toUpperCase() });
    setSelectedPaymentType({value: currentRowName, label: e.currentTarget.label})


  };

  console.log("************** RECEIVED AMOUNT &&&&&&&&&&&&&&&&& : ", formValues.amount);

  return (
    <Container>
      <SidebarMain />
      {toRender ?
        (
          <Form>
            <Row>
              <Col xs={4} md={4} sm={4} lg={4}>
              <span style={{ fontSize: "1.5rem", fontWeight: "500" }}>
                   Employee Payment
                  </span>
                {(state.action === "view" || state.action === "edit") && (
                    <>
                      &nbsp;&nbsp;
                      <Badge pill bg="success">
                        <h6>{state.passed_element.payment_number}</h6>
                      </Badge>
                    </>
                  )}
                
              </Col>
              <Col
                xs={8}
                md={8}
                sm={8}
                lg={8}
                className="d-flex justify-content-end float-end"
              >
                <Button variant="warning" className="btn btn-warning btn-md">
                  <BsArrowLeftCircle
                    key={`back-btn-payment-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleBack(e)}
                    name={`back-icon-payment-name`}
                    id={`back-icon-payment-id`}
                  />
                </Button>
                &nbsp;&nbsp;
                {(state.action === "edit" || state.action === "view") &&
                  <Button id="paymentPrint" name="paymentPrint" onClick={(e) => handlePrintPayment(e)}>
                    <BsFillPrinterFill
                      size={18}
                      style={{ color: "white", cursor: "pointer" }}
                      name={`print-payment-icon`}
                      id={`print-payment-icon`}
                    />
                  </Button>
                }
                &nbsp;

                {(state.action === "edit" || state.action === "view") && (
                  <>
                    &nbsp;&nbsp;
                    <Button variant="primary" className="btn btn-primary btn-md" onClick={(e) => handleEdit(e)}>
                      <BsPencilSquare
                        key={`edit-btn-payment-key`}
                        size={24}
                        style={{ cursor: "pointer" }}

                        name={`edit-icon-paBiSolidEditAlttient-name`}
                        id={`edit-icon-payment-id`}
                      />
                    </Button>
                  </>
                )}
              </Col>
            </Row>

            <p></p>
            <hr align="center" />

            <Row>

              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Payment Date</Form.Label>
                  <Form.Control
                    type="date"
                    id="payment_date"
                    name="payment_date"
                    placeholder="Payment Date"
                    value={formValues.payment_date}
                    onChange={handlePaymentDateChange}
                    readOnly={isReadOnly}
                  />
                </Form.Group>
              </Col>
              {/*
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group
                  className="mb-3"

                >
                  <Form.Label className="field">Supplier</Form.Label>
                  <AsyncTypeahead
                    id="supplier-search-fd-id"
                    name="supplier_name_search"
                    isLoading={isLoadingSupp}
                    onSearch={(query) => {
                      searchHandleFunc(query);
                    }}
                    onChange={(selected) => {
                      //supplierSearchSelectFunc(selected);
                      console.log("<<<<<<<<<<<<<<< SELECTED SUPPLIER : ", selected);
                      setFormValues({ ...formValues, paid_to: selected[0].value, paid_to_name: selected[0].label});
                    }}
                    options={optionsSearch}
                    placeholder="Search Supplier ..."
                    readOnly={(state === null || state.action === 'add') ? isReadOnly : true}
                    style={{
                      borderColor: "gray"
                    }}
                    className="rounded-sm" />
                </Form.Group>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Supplier Name </Form.Label>
                  <Form.Control
                    type="text"
                    name="paid_to_name"
                    id="paid_to_name"
                    placeholder="Supplier Name"
                    value={formValues.paid_to_name}
                    onChange={handleChange}
                    disabled="true"
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                    //maxLength={12}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.paid_to_name}</p>
              </Col>
                  */}
            
               <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Paid To</Form.Label>
                  <Select

                    id="paid_to"
                    name="paid_to"

                    value={{
                      value: paidToIdSelect.value,
                      label: paidToIdSelect.label,
                    }}
                    options={optPaidTo}
                    defaultValue={{
                      value: paidToIdSelect.value,
                      label: paidToIdSelect.label,
                    }}
                    onChange={handlePaidTo}
                    isDisabled={isReadOnly}
                    />
                </Form.Group>
              </Col>
                  
             {/*
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Name of Receiving Person </Form.Label>
                  <Form.Control
                    type="text"
                    name="pay_received_by"
                    id="pay_received_by"
                    placeholder="Payment Receiving Person"
                    value={formValues.pay_received_by}
                    onChange={handleChange}
                    //disabled="true"
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                    //maxLength={12}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.pay_received_by}</p>
              </Col>
              */}

                <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Amount</Form.Label>
                  <Form.Control
                    type="text"
                    name="amount"
                    id="amount"
                    placeholder="Amount"
                    value={formValues.amount}
                    maxLength={10}
                    onChange={handleChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",textAlign: "right"
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.amount}</p>
              </Col>


             
            </Row>
            <Row>
              


              <Col xs={3} sm={3} md={3} lg={3}>

                <Form.Group className="mb-3">
                  <Form.Label >Pay Mode</Form.Label>
                  <Select

                    id="payment_mode"
                    name="payment_mode"
                    value={{
                      value: paymentModeIdSelect.value,
                      label: paymentModeIdSelect.label,
                    }}
                    options={optPaymentMode}
                    defaultValue={{
                      value: paymentModeIdSelect.value,
                      label: paymentModeIdSelect.label,
                    }}
                    onChange={handlePaymentMode}
                    isDisabled={isReadOnly}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.payment_mode}</p>

              </Col>

              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className={paymentModeIdSelect.value === 'CHEQUE' ? 'field required' : 'field mb-3'}>Cheque / Reference No </Form.Label>
                  <Form.Control
                    type="text"
                    name="ref_number"
                    id="ref_number"
                    placeholder="Cheque / Reference No"
                    value={formValues.ref_number}
                    onChange={handleChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.ref_number}</p>
              </Col>


              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Cheque / Reference Date</Form.Label>
                  <Form.Control
                    type="date"
                    id="ref_date"
                    name="ref_date"
                    placeholder="Cheque / Reference Date"
                    value={formValues.ref_date}
                    onChange={handleChequeDateChange}
                    readOnly={isReadOnly}
                  />
                </Form.Group>
              </Col>


            </Row>
            <Row>

            <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Paid By</Form.Label>
                  <Select

                    id="pay_received_by"
                    name="pay_received_by"
                    value={{
                      value: paidByIdSelect.value,
                      label: paidByIdSelect.label,
                    }}
                    options={optPaidBy}
                    defaultValue={{
                      value: paidByIdSelect.value,
                      label: paidByIdSelect.label,
                    }}
                    onChange={handlePaidBy}
                    isDisabled={isReadOnly}
                  />
                </Form.Group>
              </Col>
             
              <Col xs={3} md={3} sm={3} lg={3}>
                <div key={`inline-radio`} className="mb-3" style={{ paddingTop: '35px' }}>
                  <Form.Group>
                  <Form.Label>Paid As &nbsp;&nbsp;</Form.Label>
                  {paymentType.map((paymentInd, idx) => (
                    <>                  
                    <Form.Check
                      disabled={isReadOnly}
                      inline
                      label={paymentInd.label}
                      name="payment_type"
                      type={"radio"}
                      id={`inline-radio-${paymentInd.value}`}
                      onChange={(e) => handleRadioChange(e)}
                      //checked={formValues["payment_type"] === paymentInd.value ? true : false}
                      checked={selectedPaymentType.value === paymentInd.value ? true : false}
                    />
                    </>
                    ))}
                  </Form.Group>
                </div>
              </Col>
            </Row>


            <Row>
              <Col xs={6} md={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Paid For</Form.Label>
                  <Form.Control
                    as="textarea" rows={3}
                    name="towards"
                    id="towards"
                    placeholder="Purpose of Payment"
                    value={formValues.towards}
                    onChange={handleChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
              </Col>

              <Col xs={6} md={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Notes</Form.Label>
                  <Form.Control
                    as="textarea" rows={3}
                    name="addl_notes"
                    id="addl_notes"
                    placeholder="Additional Notes"
                    value={formValues.addl_notes}
                    onChange={handleChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
              </Col>

            </Row>
            {!isReadOnly && (
            <Row>
              <Col xs={12} md={12} sm={12}>
                <div className="d-flex justify-content-end">
                  <Button
                    className="btn btn-secondary"
                    id="btn-clear-receipt-id"
                    name="btn-clear-receipt-name"
                    onClick={handleClear}
                  >
                    Clear
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    className="btn btn-primary"
                    id="save-print"
                    name="save-print"
                    onClick={(e) => handleSubmit(e, "save-print")}
                  >
                    {submitPrintAction &&
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Loading...</span>
                      </>  
                    }
                    Save & Print</Button>
                  &nbsp;
                  &nbsp;&nbsp;
                  <button className="btn btn-primary" onClick={(e) => handleSubmit(e, 'save-only')}>
                  { submitAction &&
                    <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                  }
                    Save</button>
                </div>
              </Col>
            </Row>
            )}
            <Modal
              show={showAlert}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>{alertTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{alertBody}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handleAlertOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
        )
        :
        <div className="container-spinner">
          <div className="vertical-center-spinner">
            {/*<Button variant="primary" disabled>*/}
            <Image src={logo_avinaerp} roundedCircle style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
            <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning" style={{ width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>
              <span className="visually-hidden">Loading...</span>
            </Spinner>

            {/*</Button>*/}
          </div>
        </div>
      }
    </Container>
  );

}

export default AddUpdateEmpPayment;