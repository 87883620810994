import React, { Fragment, useState } from 'react';
import { Alert } from 'react-bootstrap';
import logo_avn from './assets/images/logo_avs.png';

import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route, Switch } from "react-router-dom";
import { HashRouter } from "react-router-dom";
//import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Auth from "./pages/Auth";
import ListOrders from "./pages/ListOrders";
import Sidebar from "./pages/Sidebar";
import Topmenu from "./pages/Topmenu";
import AddUpdateOrder from "./pages/AddUpdateOrder";
import OrderDetails from "./pages/OrderDetails";
import InvoiceDetails from "./pages/InvoiceDetails";
import SplashScreen from "./pages/SplashScreen";
import AddUpdateDC from "./pages/AddUpdateDC";
import AddUpdateDirectDC from "./pages/AddUpdateDirectDC";
import PrintDC from "./pages/PrintDC";
import PrintCustomer from "./pages/PrintCustomer";
import PrintDirectDC from './pages/PrintDirectDC';
import PrintSuppPaymentList from './pages/PrintSuppPaymentList';

import Dashboard from "./pages/Dashboard";
import ListUsers from "./pages/ListUsers";
import AddUpdateUser from "./pages/AddUpdateUser";
import ListEmployees from "./pages/ListEmployees";
import AddUpdateEmployee from "./pages/AddUpdateEmployee";
import AddUpdateEmpAttendance from "./pages/AddUpdateEmpAttendance";
import ListLeaves from "./pages/ListLeaves";
import AddUpdateLeave from "./pages/AddUpdateLeave";
import AddUpdateCompany from "./pages/AddUpdateCompany";

import ListVehicles from './pages/ListVehicles';
import AddUpdateVehicle from './pages/AddUpdateVehicle';
import ListDeliverySummary from './pages/ListDeliverySummary';
import ListCustOsStmt from './pages/ListCustOsStmt'
import AddUpdateCustOsStmt from './pages/AddUpdateCustOsStmt';

import MenuOms from './pages/MenuOms';
import MenuIms from './pages/MenuIms';
import MenuEms from './pages/MenuEms';
import MenuVms from './pages/MenuVms';
import MenuMis from './pages/MenuMis';
import MenuUms from './pages/MenuUms';
import MenuSettings from './pages/MenuSettings';

import CustomersExcel from './pages/CustomersExcel';
import HeadersExcel from './pages/HeadersExcel';
import ExportCSVExcel from './pages/ExportCSVExcel';
import CsvGenerate from './pages/CsvGenerate';

import ListSuppliers from './pages/ListSuppliers';
import AddUpdateSupplier from './pages/AddUpdateSupplier';
import ExcelCustInv from './pages/ExcelCustInv';

import ListProducts from './pages/listProducts';
import AddUpdateProduct from './pages/AddUpdateProduct';

import ListCustomers from './pages/ListCustomers';
import AddUpdateCustomer from './pages/AddUpdateCustomer';

import ListDCsOld from './pages/ListDCsOld';

import DashboardIms from './pages/DashboardIms';

import DashboardOms from './pages/DashboardOms';

import DashboardEms from './pages/DashboardEms';

import DashboardVms from './pages/DashboardVms';

import DashboardUms from './pages/DashboardUms';

import DashboardMis from './pages/DashboardMis';

import DashboardCrm from './pages/DashboardCrm';

import DashboardSettings from './pages/DashboardSettings';

import DashboardAccounts from './pages/DashboardAccounts';
import AddUpdateDocNumber from "./pages/AddUpdateDocNumber";
import ListDocNumber from './pages/ListDocNumber';
import ListStocks from './pages/ListStocks';
import ListProductPricing from "./pages/ListProductPricing";
import AddUpdatePricing from "./pages/AddUpdatePricing";
import AddUpdateStock from "./pages/AddUpdateStock";
import AddUpdateProfile from "./pages/AddUpdateProfile";
import AddUpdatePassword from "./pages/AddUpdatePassword";
import AddUpdateResetPassword from "./pages/AddUpdateResetPassword";

import AddUpdatePurchaseDC from './pages/AddUpdatePurchaseDC';
import AddUpdatePurchaseDirectDC from './pages/AddUpdatePurchaseDirectDC';

import ListPurchaseOrders from './pages/ListPurchaseOrders';
import AddUpdatePurchaseOrder from './pages/AddUpdatePurchaseOrder';

import StockReport from './pages/Reports/StockReport';

import PrintStock from './pages/PrintStock';

import PrintOrder from './pages/PrintOrder';
import PrintReceipt from './pages/PrintReceipt';
import PrintVoucher from './pages/PrintVoucher';
import PrintFuelPass from './pages/PrintFuelPass';
import PrintDeliverySummaryDaily from './pages/PrintDeliverySummaryDaily';
import PrintDeliveryReturnSave from './pages/PrintDeliveryReturnSave';

import PrintFullOrder from './pages/PrintFullOrder';

import AddUpdateReceipt from './pages/AddUpdateReceipt';
import AddUpdateFuelPass from './pages/AddUpdateFuelPass';
import AddUpdateBill from './pages/AddUpdateBill';
import ListReceipts from './pages/ListReceipts';
import ListDCs from './pages/ListDCs';
import ListInvoice from './pages/ListInvoice';
import ListCustInv from './pages/ListCustInv';
import AddUpdateCustInv from './pages/AddUpdateCustInv';
import RptCustOsSummary from './pages/RptCustOsSummary';

import UtilQuickOsCheck from './pages/UtilQuickOsCheck';
import AddUpdateInvoice from './pages/AddUpdateInvoice';
import AddUpdateDeliverySummary from './pages/AddUpdateDeliverySummary';
import ListCustReceipts from './pages/ListCustReceipts';
import AddUpdateCustReceipt from './pages/AddUpdateCustReceipt';

import ListSuppPayments from './pages/ListSuppPayments';

import ListExpPayments from './pages/ListExpPayments';
import AddUpdateExpPayment from './pages/AddUpdateExpPayment';


import ViewReceipt from './pages/ViewReceipt';

import PrintDeliverySummary from './pages/PrintDeliverySummary';
import PrintCustOsStmt from './pages/PrintCustOsStmt';
import PrintCustomerStmt from './pages/PrintCustomerStmt';
import PrintCustReceipt from './pages/PrintCustReceipt';
import PrintFullReceipt from './pages/PrintFullReceipt';
import PrintInvoice from './pages/PrintCustInv';
import PrintVehicle from './pages/PrintVehicle';
import PrintSupplier from './pages/PrintSupplier';
import PrintEmployee from './pages/PrintEmployee';

import { client } from './config/Config';
import axios from 'axios';

//import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import PrintCustInv from './pages/PrintCustInv';
import AddUpdateSuppPayment from './pages/AddUpdateSuppPayment';
import PrintSuppPayment from './pages/PrintSuppPayment';
import PrintExpPayment from './pages/PrintExpPayment';
import PrintExpPaymentList from './pages/PrintExpPaymentList';
import ListEmpPayments from './pages/ListEmpPayments';
import AddUpdateEmpPayment from './pages/AddUpdateEmpPayment';
import PrintEmpPayment from './pages/PrintEmpPayment';
import PrintEmpPaymentList from './pages/PrintEmpPaymentList';



//import { useNavigate } from "react-router-dom";

function App() {
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState('Unable to login');
  const [showMsgTitle, setShowMsgTitle] = useState('Login FAILED!');

  //const navigate = useNavigate();

  const handleSubmit = async (event) => {
    // Prevent page reload
    event.preventDefault();

    console.log("Form submit clicked ...");

    console.log("DATA : ", document.forms[0]);

    var { username, password } = document.forms[0];
    console.log("username : ", username.value);
    console.log("pasword : ", password.value);


    let reqLoginPayload = {
      username: username.value,
      password: password.value
    };

    var headers = client.headers;
    console.log("payload:", reqLoginPayload);

    try {
      var res = await axios.post(client.domain + '/login', reqLoginPayload, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === 'SUCCESS') {
        console.log("Response message from server : ", res.data.detail.res_data);
        console.log("Response message from server userInfo : ", res.data.detail.res_data.user_info);
        console.log("Response message from server aI : ", res.data.detail.res_data.aI);

        localStorage.setItem('_aI', res.data.detail.res_data.aI);
        //localStorage.setItem('userRoleId', userRoleId);
        localStorage.setItem('username', res.data.detail.res_data.user_info.username);

        //navigate("/dashboard");
      }
    }
    catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === 'ERR_NETWORK') {
        console.log("Not able to reach target server please try after sometime");
      }
      else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === 'FAILED') {
          console.log('Request failed due to ', error.response.data.detail.res_data);
          setShowMsg(error.response.data.detail.res_data);
          setShowMsgTitle('LOGIN FAILED!');
          setShow(true);
        }
      }
    }

  }

  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<SplashScreen />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/listOrders" element={<ListOrders />} />
        <Route path="/dashboardIms" element={<DashboardIms />} />
        <Route path="/dashboardOms" element={<DashboardOms />} />
        <Route path="/dashboardEms" element={<DashboardEms />} />
        <Route path="/dashboardVms" element={<DashboardVms />} />
        <Route path="/dashboardUms" element={<DashboardUms />} />
        <Route path="/dashboardMis" element={<DashboardMis />} />
        <Route path="/dashboardCrm" element={<DashboardCrm />} />
        <Route path="/dashboardSettings" element={<DashboardSettings />} />
        <Route path="/dashboardAccounts" element={<DashboardAccounts />} />
        <Route path="/listCustomers" element={<ListCustomers />} />
        <Route path="/addUpdateCustomer" element={<AddUpdateCustomer />} />

        <Route path="/listUsers" element={<ListUsers />} />
        <Route path="/addUpdateUser" element={<AddUpdateUser />} />
        <Route path="/listEmployees" element={<ListEmployees />} />
        <Route path="/addUpdateEmployee" element={<AddUpdateEmployee />} />
        <Route path="/addUpdateEmpAttendance" element={<AddUpdateEmpAttendance />} />
        <Route path="/listLeaves" element={<ListLeaves />} />
        <Route path="/addUpdateLeave" element={<AddUpdateLeave />} />
        <Route path="/addUpdateCompany" element={<AddUpdateCompany />} />
        <Route path="/listProductPricing" element={<ListProductPricing />} />
        <Route path="/addUpdatePricing" element={<AddUpdatePricing />} />

        <Route path="/addUpdateOrder" element={<AddUpdateOrder />} />
        <Route path="/orderDetails" element={<OrderDetails />} />
        <Route path="/addUpdateDC" element={<AddUpdateDC />} />
        <Route path="/printDC" element={<PrintDC />} />
        <Route path="/printDirectDC" element={<PrintDirectDC />} />
        
        <Route path="/ListVehicles" element={<ListVehicles />} />
        <Route path="/addUpdateVehicle" element={<AddUpdateVehicle />} />

        <Route path="/listSuppliers" element={<ListSuppliers />} />
        <Route path="/addUpdateSupplier" element={<AddUpdateSupplier />} />

        <Route path="/listProducts" element={<ListProducts />} />
        <Route path="/addUpdateProduct" element={<AddUpdateProduct />} />
        <Route path="/printOrder" element={<PrintOrder />} />
        <Route path="/printFullOrder" element={<PrintFullOrder />} />
        <Route path="/addUpdateDocNumber" element={<AddUpdateDocNumber />} />
        <Route path="/listDocNumber" element={<ListDocNumber />} />

        <Route path="/listPurchaseOrders" element={<ListPurchaseOrders />} />
        <Route path="/addUpdatePurchaseOrder" element={<AddUpdatePurchaseOrder />} />
        <Route path="/listStocks" element={<ListStocks />} />
        <Route path="/addUpdateStock" element={<AddUpdateStock />} />
        <Route path="/addUpdateProfile" element={<AddUpdateProfile />} />
        <Route path="/addUpdatePassword" element={<AddUpdatePassword />} />
        <Route path="/addUpdateResetPassword" element={<AddUpdateResetPassword />} />
        <Route path="/addUpdatePurchaseDC" element={<AddUpdatePurchaseDC />} />
        <Route path="/addUpdatePurchaseDirectDC" element={<AddUpdatePurchaseDirectDC />} />
        <Route path="/stockReport" element={<StockReport/>} />
        <Route path="/printStock" element={<PrintStock />} />
        <Route path="/printReceipt" element={<PrintReceipt />} />
        <Route path="/printVoucher" element={<PrintVoucher />} />
        <Route path="/printFuelPass" element={<PrintFuelPass />} />
        <Route path="/printDeliverySummaryDaily" element={<PrintDeliverySummaryDaily />} />
        <Route path="/printDeliveryReturnSave" element={<PrintDeliveryReturnSave />} />
        <Route path="/printCustOsStmt" element={<PrintCustOsStmt />} />

        <Route path="/addUpdateReceipt" element={<AddUpdateReceipt/>} />
        <Route path="/addUpdateFuelPass" element={<AddUpdateFuelPass/>} />
        <Route path="/addUpdateBill" element={<AddUpdateBill/>} />

        <Route path="/listReceipts" element={<ListReceipts />} />
        <Route path="/viewReceipt" element={<ViewReceipt />} />
        <Route path="/listDCsOld" element={<ListDCsOld />} />
        <Route path="/listDCs" element={<ListDCs />} />
        <Route path="/addUpdateDirectDC" element={<AddUpdateDirectDC/>} />
        <Route path="/listInvoice" element={<ListInvoice />} />
        <Route path="/listCustInv" element={<ListCustInv />} />
        <Route path="/addUpdateInvoice" element={<AddUpdateInvoice/>} />
        <Route path="/invoiceDetails" element={<InvoiceDetails />} />
        <Route path="/listDeliverySummary" element={<ListDeliverySummary />} />
        <Route path="/addUpdateDeliverySummary" element={<AddUpdateDeliverySummary/>} />
        <Route path="/listCustOsStmt" element={<ListCustOsStmt />} />
        <Route path="/addUpdateCustOsStmt" element={<AddUpdateCustOsStmt/>} />
        <Route path="/addUpdateCustInv" element={<AddUpdateCustInv/>} />
        <Route path="/rptCustOsSummary" element={<RptCustOsSummary/>} />
        <Route path="/utilQuickOsCheck" element={<UtilQuickOsCheck/>} />
        <Route path="/listCustReceipts" element={<ListCustReceipts />} />
        <Route path="/addUpdateCustReceipt" element={<AddUpdateCustReceipt/>} />
        <Route path="/printDeliverySummary" element={<PrintDeliverySummary/>} />
        <Route path="/printCustomerStmt" element={<PrintCustomerStmt/>} />
        <Route path="/printCustReceipt" element={<PrintCustReceipt/>} />
        <Route path="/printFullReceipt" element={<PrintFullReceipt/>} />
        <Route path="/printCustInv" element={<PrintCustInv/>} />
        <Route path="/excelCustInv" element={<ExcelCustInv/>} />
        <Route path="/printCustomer" element={<PrintCustomer/>} />
        <Route path="/printVehicle" element={<PrintVehicle/>} />
        <Route path="/printSupplier" element={<PrintSupplier/>} />
        <Route path="/printEmployee" element={<PrintEmployee/>} />
        <Route path="/listSuppPayments" element={<ListSuppPayments />} />
        <Route path="/addUpdateSuppPayment" element={<AddUpdateSuppPayment/>} />
        <Route path="/printSuppPayment" element={<PrintSuppPayment/>} />
        <Route path="/printSuppPaymentList" element={<PrintSuppPaymentList/>} />
        <Route path="/listExpPayments" element={<ListExpPayments />} />
        <Route path="/addUpdateExpPayment" element={<AddUpdateExpPayment/>} />
        <Route path="/printExpPayment" element={<PrintExpPayment/>} />
        <Route path="/printExpPaymentList" element={<PrintExpPaymentList/>} />
        <Route path="/listEmpPayments" element={<ListEmpPayments />} />
        <Route path="/addUpdateEmpPayment" element={<AddUpdateEmpPayment/>} />
        <Route path="/printEmpPayment" element={<PrintEmpPayment/>} />
        <Route path="/printEmpPaymentList" element={<PrintEmpPaymentList/>} />
        
      </Routes>
    </HashRouter>
  );
}
export default App;
