import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#a8a8a8'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#a8a8a8',
        backgroundColor: '#a8a8a8',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        //flexGrow: 1
    },
    sno: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    description: {
        width: '28%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    hsncode: {
        width: '12%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    qty: {
        width: '13%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    rate: {
        width: '22%',
        flexDirection: 'row',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    rate1: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1
    },
    rate2: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        fontSize: 11,
        lineHeight: 24
    },
    amount: {
        width: '20%'
    },
  });

  const OrderTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.sno}>S.No</Text>
        <Text style={styles.description}>Description</Text>
        <Text style={styles.hsncode}>HSN Code</Text>
        <Text style={styles.qty}>Area(CUM)</Text>
        <Text style={styles.rate}>RATE Per M<Text style={{fontSize: '8px'}}>3</Text></Text>
        <Text style={styles.amount}>Amount</Text>paddingTop
    </View>
  );
  
  export default OrderTableHeader