import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 12
    },
    billFrom: {
        marginTop: 12,
        paddingBottom: 3,
        //fontFamily: 'Helvetica-Oblique',
        fontStyle: 'italic',
        fontWeight: 'bold',
    
    },
  });


  const OrderCompanyAddress = ({invoice}) => {
  return (
    <View style={styles.headerContainer}>
        <Text style={{fontWeight: 'bold'}}>Address:</Text>
        <Text>{invoice.owner_company}</Text>
        <Text>{invoice.owner_address1}, {invoice.owner_address2}</Text>
        <Text>{invoice.owner_city}, {invoice.owner_district}, {invoice.owner_pincode}</Text>
        <Text>GST: {invoice.owner_gst}</Text>
    </View>
  );
  }
  export default OrderCompanyAddress