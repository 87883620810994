import React, { useState, useEffect, useRef, useReducer } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Modal,
  Spinner,
  Image
} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Sidebar2 from "./Sidebar2";
import { fragment } from "react";
import select from "react-select";

import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsCardText,
  BsFillPrinterFill
} from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import MenuVms from "./MenuVms";

import axios from "axios";
import { client } from "../config/Config";
import SidebarMain from "./SidebarMain";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';
import {formatDateIndian} from "./utils";



function ListVehicles() {
  const initialized = useRef(false);
  const navigate = useNavigate();
  const [checkedState, setCheckedState] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [toRender, setToRender] = useState(false);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(-1);

  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("Please login again");
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );

  let vehicleArr = [
    {
      vehicle_name: "Lorry",
      vehicle_reg: "TN 39 DY 7314",
      fc_date: "2023-12-31",
      insurance_removal: "2023-12-31",
      active: false,
    },
    {
      vehicle_name: "Readymix",
      vehicle_reg: "TN 39 DY 7653",
      fc_date: "2023-12-31",
      insurance_removal: "2023-12-31",
      active: false,
    },
  ];

  function padTo2Digits(num) {
    //console.log("<<<<<<<<<<<<<< given number >>>>>>>>>>>>>>>> : ", num);
    var modNum = num.toString().padStart(2, "0");
    console.log("Modified  value : ", modNum);
    return modNum;
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }
  const intialValues = {
    fc_date: formatDate(new Date()),
    insurance_removal: formatDate(new Date()),
  }

  const [vehicleList, setVehicleList] = useState([]);

  const getVehicleList = async () => {
    console.log("get vehicle list called ...");

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      

      var res = await axios.get(
        client.domain + "/vms/getAllVehicles",
        { headers },
        { withCredentials: false }
      );
      console.log("Response from server Vehicle: ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log(
          "Response message from server Vehicle : ",
          res.data.detail.data
        );
        setVehicleList(res.data.detail.data);
        setToRender(true);
        //navigate("/dashboard");
        //setDcReportData(res.data.detail.res_data);
        //setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        }
      }
    }
  };
  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect called ##########################"
      );
      getVehicleList();
      initialized.current = true;
    }
  }, []);

  const handleStateChange = (e) => {
    console.log("clicked Value :", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);

    var currentId = name.substring(name.lastIndexOf("-") + 1);
    var nameOfElement = name.substring(0, name.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);
    console.log("current State : ", vehicleList[currentId].active);
    let modifiedState = !vehicleList[currentId].active;
    console.log("Modified state : ", modifiedState);

    var itemSelected = vehicleList[currentId];

    itemSelected.active = modifiedState;

    const newitems = vehicleList.slice();
    newitems[currentId] = itemSelected;

    console.log("Modified vehicle array : ", newitems);
    setVehicleList(newitems);

    //setRefresh(true);
    //forceUpdate();
    setVehicleList(vehicleList);
  };

  const actionEditHandle = (e, data) => {
    console.log("Handle action edit Clicked : ", e);
    //console.log("Handle Clicked edit DATA : ", data);
    console.log("Handle action edit Clicked target : ", e.target);
    console.log(
      "Handle action edit Clicked target Current : ",
      e.currentTarget
    );
    console.log(
      "Handle action edit Clicked target Current id : ",
      e.currentTarget.id
    );
    console.log("Handle action edit Clicked target ID : ", e.target.id);
    console.log("Handle action edit Clicked target NAME : ", e.target.name);
  };
  const actionDeleteHandle = (e, data) => {
    console.log("Handle action delete Clicked : ", e);
    //console.log("Handle Clicked delete DATA : ", data);
  };

  const handleChange = (nextChecked) => {
    //setChecked(nextChecked);
  };

  const handleOnChange = async (e) => {
    console.log("handle on change called : ", e);

    var selectedTagId = e.target.id;
    console.log("SELECTED TAG ID: ", selectedTagId);
    var currentId = selectedTagId.substring(selectedTagId.lastIndexOf("-") + 1);
    var nameOfElement = selectedTagId.substring(
      0,
      selectedTagId.lastIndexOf("-")
    );
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    console.log(
      "<<<<<<<<<<<<<<<<< ITEM SELECTED ********** ",
      vehicleList[currentId]
    );

    setSelectedId(currentId);

    setShowDialog(true);
  };

  const handleAdd = (e) => {
    console.log("Handle add clicked : ", e);

    navigate("/addUpdateVehicle", {
      state: { passed_element: [], action: "add" },
    });
  };

  const handleView = async (e) => {
    console.log("handle edit customers called : ", e);
    console.log(
      "handle edit customers currentTarget called : ",
      e.currentTarget
    );
    console.log("handle edit customers target called : ", e.target);
    var selectedTagId = e.currentTarget.id;
    console.log("SELECTED TAG ID: ", selectedTagId);
    if (selectedTagId) {
      var currentId = selectedTagId.substring(
        selectedTagId.lastIndexOf("-") + 1
      );
      var nameOfElement = selectedTagId.substring(
        0,
        selectedTagId.lastIndexOf("-")
      );
      console.log("Name of element VIEW: ", nameOfElement);
      console.log("Current id VIEW : ", currentId);

      console.log(
        "<<<<<<<<<<<<<<<<< ITEM SELECTED VIEW ********** ",
        vehicleList[currentId]
      );
      navigate("/addUpdateVehicle", {
        state: {
          passed_element: vehicleList[currentId],
          action: "view",
        },
      });
    }
  };

  const handleCloseDialog = async (e) => {
    console.log("Handle close Dialog called");
  };

  const updateVehicleStatus = async () => {
    var selectedObj = vehicleList[selectedId];
    let reqStatusUpdatePayload = {
      id: selectedObj.id,
      is_active: !selectedObj.is_active,
    };

    var headers = client.headers;
    console.log("payload:", reqStatusUpdatePayload);

    try {
      var res = await axios.post(
        client.domain + "/vms/activateVehicle",
        reqStatusUpdatePayload,
        { headers },
        { withCredentials: false }
      );
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        //console.log("Response message from server : ", res.data.detail.res_data);
        //console.log("Response message from server userInfo : ", res.data.detail.res_data.user_info);
        //console.log("Response message from server aI : ", res.data.detail.res_data.aI);
        // Remove login session from localstorage
        //localStorage.removeItem("username");
        //localStorage.removeItem("_aI")
        //navigate("/auth");

        var itemSelectedUpdate = vehicleList[selectedId];
        itemSelectedUpdate.is_active = !itemSelectedUpdate.is_active;
        console.log(
          "<<<<<<<<<<<<<<<<< ITEM SELECTED MODIFIED ********** ",
          itemSelectedUpdate
        );

        const newitems = vehicleList.slice();
        newitems[selectedId] = itemSelectedUpdate;
        setVehicleList(newitems);

        setShowDialog(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle("LOGIN FAILED!");
          //setShow(true);
        }
      }
    }
    setShowDialog(false);
  };

  const handleSwitchAction = () => {
    console.log("user confirmed to enable or disable ...");
    console.log("user selected id for action is : ", selectedId);

    // call update API
    updateVehicleStatus(selectedId);
  };

  const handleVehiclePrint = async (e) => {
    //navigate("/printCustomer")
     
     var urlStr = window.location.href;
     var paramsTmp = urlStr.substring(
       urlStr.lastIndexOf(client.urlDelimiter) + 1
     );
     var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));
 
     console.log("CALLED URL : ", urlTmp);
     console.log("CALLED PAGE : ", paramsTmp);
 
     var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printVehicle?`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
     winPrint.moveTo(0,0);
     winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
     
     
   };

  return (
    <Container>
          <SidebarMain />
          { toRender ?  
      <form>
          <br></br>
          <Row>
            <Col xs={6} md={6} sm={6}>
              <h4 className="text-left">Vehicles</h4>
            </Col>
            <Col xs={6} md={6} sm={6} className="d-flex justify-content-end float-end">
            <Button id="vehiclePrint" name = "vehiclePrint"  onClick={(e)  => handleVehiclePrint(e)}>
                <BsFillPrinterFill
                 size={18}
                 style={{ color: "white", cursor: "pointer" }}
                 name={`print-vehicle-icon`}
                 id={`print-vehicle-icon`}
                 />
                 </Button>
                 &nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                variant="warning"
                id="addUpdateId"
                className="float-end"
                onClick={(e) => handleAdd(e)}
              >
                New Vehicle
              </Button>
            </Col>
          </Row>
          <hr align="center" />
          <form>
            <Table striped bordered hover>
              <thead>
                <tr className="table-primary">
                  <th className="table-row-heading" style={{textAlign: "center"}}>S.No</th>
                  <th className="table-row-heading" style={{textAlign: "center"}}>Vehicle Name</th>
                  <th className="table-row-heading"style={{textAlign: "center"}}>Registration Number</th>
                  <th className="table-row-heading" style={{textAlign: "center"}}>Next Fc Date</th>
                  <th className="table-row-heading" style={{textAlign: "center"}}>Insurance Removal</th>
                  <th className="table-row-heading" style={{textAlign: "center"}}>Active</th>
                  <th className="table-row-heading" style={{textAlign: "center"}}>Details</th>
                </tr>
              </thead>
              <tbody>
                {vehicleList.map((vehInd, idx) => (
                  <tr>
                    <td style={{textAlign: "right"}}>{idx + 1}</td>
                    <td style={{textAlign: "left"}}>{vehInd.vehicle_name}</td>
                    <td style={{textAlign: "right"}}>{vehInd.reg_no}</td>
                    <td style={{textAlign: "left"}}>{formatDateIndian(new Date(vehInd.fc_date))}</td>
                    <td style={{textAlign: "left"}}>{formatDateIndian(new Date(vehInd.ins_renewal_date))}</td>
                    <td style={{textAlign: "center"}}>
                      <Form.Check // prettier-ignore
                        type="switch"
                        id={`custom-switch-id-${idx}`}
                        name={`custom-switch-name-${idx}`}
                        checked={vehInd.is_active}
                        onChange={(e) => handleOnChange(e)}
                      />
                    </td>

                    <td style={{textAlign: "center"}}>
                      <BsCardText
                        key={`edit-btn-patient-key-${idx}`}
                        size={28}
                        style={{ color: "#0D6EFD", cursor: "pointer" }}
                        onClick={(e) => handleView(e)}
                        name={`edit-icon-patient-name-${idx}`}
                        id={`edit-icon-patient-id-${idx}`}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </form>
          <Modal
            show={showDialog}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header>
              <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Activae / In-Activate this vehicle? Click OK to proceed...
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseDialog}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSwitchAction}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          </form>
      : 
      <div className="container-spinner">
     <div className="vertical-center-spinner">
      {/*<Button variant="primary" disabled>*/}
      <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
      {/*</Button>*/}
      </div>
      </div>
      }
    </Container>
    );
}

export default ListVehicles;
