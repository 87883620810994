import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  Fragment,
} from "react";
import Table from "react-bootstrap/Table";
import { fragment } from "react";

import Select from "react-select";

import {
  Form,
  Button,
  Row,
  Col,
  Alert,
  Modal,
  Container,
  Spinner,
  Image
} from "react-bootstrap";
import Sidebar2 from "./Sidebar2";
import SidebarMain from "./SidebarMain";
import { HiRefresh } from "react-icons/hi";
import { LuRefreshCw } from "react-icons/lu";

import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsCardText,
  BsArrowLeftCircle,
  BsFillPrinterFill
} from "react-icons/bs";

import "../App.css";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { client } from "../config/Config";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';

import { formatDateIndian, getCustomerListDropdown, getProductsSell } from "./utils";


function ListDeliverySummary() {
  const initialized = useRef(false);
  var navigate = useNavigate();
  const [checkedState, setCheckedState] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [summaryListFetched, setSummaryListFetched] = useState(false);

  function padTo2Digits(num) {
    //console.log("<<<<<<<<<<<<<< given number >>>>>>>>>>>>>>>> : ", num);
    var modNum = num.toString().padStart(2, "0");
    console.log("Modified  value : ", modNum);
    return modNum;
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }
  const intialValues = {
    start_date: formatDate(new Date()),
    end_date: formatDate(new Date()),
    summaryPrint: 'ONLY',
  }

  const [checked, setChecked] = useState(false);
  const [formValues, setFormValues] = useState(intialValues);

  const [summaryList, setSummaryList] = useState([]);
  const [summaryListFull, setSummaryListFull] = useState([]);
  const [dcList, setDcList] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState({});
  //const [customerListFull, setCustomerListFull] = useState([]);
  const [toRender, setToRender] = useState(false);
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("Please login again");
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );

  const [selectedId, setSelectedId] = useState(-1);

  const [showDialog, setShowDialog] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [customerIdSelect, setCustomerIdSelect] = useState({});
  const [optCustomer, setOptCustomer] = useState([]);
  const [placeIdSelect, setPlaceIdSelect] = useState({});
  const [optPlace, setOptPlace] = useState([]);

  const [searchError, setSearchError] = useState(false);
  const [productIdSelect, setProductIdSelect] = useState({});
  const [optProduct, setOptProduct] = useState([]);
  const [printDialog, setPrintDialog] = useState(false);
  const [selectedDCRowForPrint, setSelectedDCRowForPrint] = useState(-1);

  const handleRadioChange = async (e) => {
    console.log("Value button clicked : ", e);

    const { name, id } = e.currentTarget;
    console.log("Captured value name : ", name);
    console.log("Captured value id : ", id);

    var currentRowName = id.substring(id.lastIndexOf("-") + 1);
    var nameOfElement = id.substring(0, id.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current row id : ", currentRowName);
    setFormValues({...formValues, ["summaryPrint"]: currentRowName.toUpperCase()});
    
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const handleShowDialog = () => {
    setShowDialog(true);
  };
  /*
  const updateCustomerStatus = async (selectedId) => {
    var selectedObj = customerList[selectedId];
    let reqStatusUpdatePayload = {
      id: selectedObj.id,
      is_active: !selectedObj.is_active,
    };

    console.log("payload:", reqStatusUpdatePayload);

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}


      var res = await axios.post(
        client.domain + "/crm/activateCustomer",
        reqStatusUpdatePayload,
        { headers },
        { withCredentials: false }
      );
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        //console.log("Response message from server : ", res.data.detail.res_data);
        //console.log("Response message from server userInfo : ", res.data.detail.res_data.user_info);
        //console.log("Response message from server aI : ", res.data.detail.res_data.aI);
        // Remove login session from localstorage
        //localStorage.removeItem("username");
        //localStorage.removeItem("_aI")
        //navigate("/auth");

        var itemSelectedUpdate = customerList[selectedId];
        itemSelectedUpdate.is_active = !itemSelectedUpdate.is_active;
        console.log(
          "<<<<<<<<<<<<<<<<< ITEM SELECTED MODIFIED ********** ",
          itemSelectedUpdate
        );

        const newitems = customerList.slice();
        newitems[selectedId] = itemSelectedUpdate;
        setCustomerList(newitems);

        setShowDialog(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle("LOGIN FAILED!");
          //setShow(true);
        }
      }
    }
    setShowDialog(false);
  };
  */

  const actionEditHandle = (e, data) => {
    console.log("Handle action edit Clicked : ", e);
    //console.log("Handle Clicked edit DATA : ", data);
    console.log("Handle action edit Clicked target : ", e.target);
    console.log(
      "Handle action edit Clicked target Current : ",
      e.currentTarget
    );
    console.log(
      "Handle action edit Clicked target Current id : ",
      e.currentTarget.id
    );
    console.log("Handle action edit Clicked target ID : ", e.target.id);
    console.log("Handle action edit Clicked target NAME : ", e.target.name);
  };
  /*
  const getCustomerList = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
    

      var res = await axios.get(
        client.domain + "/crm/getAllCustomers",
        { headers },
        { withCredentials: false }
      );
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", 0);
        setCustomerList(res.data.detail.data);
        setCustomerListFull(res.data.detail.data);
        setShowMsgTitle("Customer Info Added Successfully");
        setToRender(true);
        //navigate("/dashboard");
        //setDcReportData(res.data.detail.res_data);
        //setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR occured : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        } else {
          console.log("Non status ERROR code ...");
          setShowMsg(error.response.data.detail);
          setShowMsgTitle("Unable to process your request at this moment!");
          setShow(true);
        }
      }
    }
  };
  */
  {
    /*
  const handleAddAction = () => {
    console.log("user confirmed to added or not ...");
    console.log("user selected id for action is : ", selectedId);

    // call update API
    updateCustomerStatus(selectedId);
  };
*/
  }

  const handleStateChange = (e) => {
    console.log("clicked Value :", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);

    var currentId = name.substring(name.lastIndexOf("-") + 1);
    var nameOfElement = name.substring(0, name.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);
  };

  const getAllSummaryList = async (customer_id, start_date, end_date, product_id) => {
    var retState = false;
    var deliverySummaryList = [];
    try {
      var res = await axios.get(client.domain + `/getDeliverySummaryList?customer_id=${customer_id}&start_date=${start_date}&end_date=${end_date}&product_id=${product_id}`, { withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getAllSummaryList : ", res.data.detail);
      if (res.data.detail.status === 'SUCCESS') {
        console.log("Response message from server SummaryList : ", res.data.detail.data);
        //console.log("Response message from server SummaryList user_info : ", res.data.detail.res_data.user_info);
        //console.log("Response message from server getAllSummaryList : ", res.data.detail.res_data.dc_list);
        //console.log("Length of district array : ", res.data.detail.res_data.dc_list.length);
        //optionsDistrict = res.data.detail.res_data.district_list

        //setOrderInfoDet(res.data.detail.res_data.order_info);
        retState = true;
        if (res.data.detail.data.length > 0) {
          //setDcList(res.data.detail.data);
          deliverySummaryList = res.data.detail.data;
          //setDeliveryChellan(res.data.detail.res_data.dc_list);
          //setDcList(res.data.detail.res_data.dc_list);
          //setDcListFull(res.data.detail.res_data.dc_list);
          //setProdList(res.data.detail.res_data.dc_list);
          //setOptDist(res.data.detail.res_data.district_list)
          //setDistrictIdSelect({value: res.data.detail.res_data.district_list[0].value, label: res.data.detail.res_data.district_list[0].label});
        }
        //setOrderDcFetched(true);
      }
    }
    catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === 'ERR_NETWORK') {
        console.log("Not able to reach target server please try after sometime");
      }
      else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === 'FAILED') {
          console.log('Request failed due to ', error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
    return [retState, deliverySummaryList];
  }

  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::ListSummary called ##########################"
      );
      (async () => {
        var [retState, customerArr] = await getCustomerListDropdown();
        if ( retState ) {
          setOptCustomer(customerArr);
          setCustomerIdSelect(customerArr[0]);
        }

        var [retStateSellProd, newSellProdArr] = await getProductsSell();
        var newSellProdArr1 = [{value: 0, label: "All Grades"}, ...newSellProdArr];
          if ( retStateSellProd ) {
            console.log("RETURNED SELL PRODUCT LIST : ", newSellProdArr1);
            setOptProduct(newSellProdArr1);
            setProductIdSelect(newSellProdArr1[0]);
          }

        var customer_id = 0;
        var product_id = 0;
        console.log("<<<<<<<<<<<<<<<<< CURRENT STATE >>>>>>>>>>>>>>>>> : ", new Date());
        var current_date = formatDate(new Date());
        console.log("START DATE : ", current_date);
        var start_date = current_date;
        var end_date = current_date;
        var [retState, deliverySummaryList] = await getAllSummaryList(customer_id, start_date, end_date, product_id);
        if (retState) {
          setSummaryList(deliverySummaryList);
          //setOptCustomer(customerArr);
          //setCustomerIdSelect(customerArr[0]);
        }
        setSummaryListFetched(true);
      })();
      //getCustomerList();
      initialized.current = true;
      /*
      ( async() => {
        var [retState, customerArr, placeListArrRet] = await getCustomerListDropdown();
        if ( retState ) {
          setOptCustomer(customerArr);
          setCustomerIdSelect(customerArr[0]);

          setOptPlace(placeListArrRet);
          setPlaceIdSelect(placeListArrRet[0]);
        }
      })();
      */
    }
  }, []);
  /*
   const handleAdd = async (e) => {
     console.log("handle add customers called : ", e);
     navigate("/addUpdateCustomer", {
       state: { passed_element: [], action: "add" },
     });
   };
   */

  const handleView = async (e) => {
    console.log("handle edit summary called : ", e);
    console.log(
      "handle edit summary currentTarget called : ",
      e.currentTarget
    );
    console.log("handle edit summary target called : ", e.target);
    var selectedTagId = e.currentTarget.id;
    console.log("SELECTED TAG ID: ", selectedTagId);
    if (selectedTagId) {
      var currentId = selectedTagId.substring(
        selectedTagId.lastIndexOf("-") + 1
      );
      var nameOfElement = selectedTagId.substring(
        0,
        selectedTagId.lastIndexOf("-")
      );
      console.log("Name of element VIEW: ", nameOfElement);
      console.log("Current id VIEW : ", currentId);

      console.log(
        "<<<<<<<<<<<<<<<<< ITEM SELECTED VIEW ********** ",
        summaryList[currentId]
      );

      navigate("/addUpdateDeliverySummary", {
        state: {
          passed_element: summaryList[currentId],
          action: "view",
        },
      });

    }
  };

  const handleOnChange = async (e) => {
    console.log("handle on change called : ", e);

    var selectedTagId = e.target.id;
    console.log("SELECTED TAG ID: ", selectedTagId);
    var currentId = selectedTagId.substring(selectedTagId.lastIndexOf("-") + 1);
    var nameOfElement = selectedTagId.substring(
      0,
      selectedTagId.lastIndexOf("-")
    );
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    console.log(
      "<<<<<<<<<<<<<<<<< ITEM SELECTED ********** ",
      summaryList[currentId]
    );

    setSelectedId(currentId);

    setShowDialog(true);
  };

  const handleAlertClose = async (e) => {
    setShow(false);
    navigate(-1);
  };
  const onChangeDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);
    var orderListFilter = []
    /*
     if ( e.value === 0 ) {
       setOrderList(orderListFull);
     }
     else {
       productListFull.map((date) => {
         if ( date.order_date === e.value ) {
           orderListFilter.push(date);
         }
       })
       setOrderList(orderListFilter);
     }
     
     */
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleDateSearch = async () => {
    console.log("handleDateSearch called ...");
    console.log("++++++++++++ START DATE : %s, END DATE : %s >>>>>>>>>>>>>>>>", formValues["start_date"], formValues["end_date"]);

    const start = Date.parse(formValues["start_date"]);
    const end = Date.parse(formValues["end_date"]);

    var customerIdToPass = customerIdSelect.value;
    var startDateToPass = formValues["start_date"];
    var endDateToPass = formValues["end_date"];
    var productIdToPass = productIdSelect.value;
    console.log("CUSTOMER ID SELECTED : ", customerIdSelect.value);
    console.log("START DATE SELECTED : ", formValues["start_date"]);
    console.log("END DATE SELECTED : ", formValues["end_date"]);
    console.log("PRODUCT ID SELECTED : ", productIdToPass);
    var [retSummarySearchState, summaryListBySearch, error_msg] = await getSummaryListBySearch(customerIdToPass, startDateToPass, endDateToPass, productIdToPass);
    if ( retSummarySearchState ) {
        setSummaryList(summaryListBySearch);

    }
    else {
      console.log("<<<<<<<<<<<<<<<<<<<< SEARCH FAILED >>>>>>>>>>>>>>>>>>>> ");
      setSearchError(true);
      setDcList([]);
    }
    /*
    var summaryListFilter = [];
    summaryListFull.map((summary) => {
      const d = Date.parse(summary.dc_date);
      if (d >= start && d <= end) {
        if (selectedLocationId.label !== 'All' && customerIdSelect.value !== 0) {
          if (summary.address_line1 === selectedLocationId.label && summary.customer_id === customerIdSelect.value) {
            summaryListFilter.push(summary);
          }
        }
        else if (selectedLocationId.label === 'All' && customerIdSelect.value !== 0) {
          if (summary.customer_id === customerIdSelect.value) {
            summaryListFilter.push(summary);
          }
        }
        else if (selectedLocationId.label !== 'All' && customerIdSelect.value === 0) {
          if (summary.address_line1 === selectedLocationId.label) {
            summaryListFilter.push(summary);
          }
        }
        else {
          summaryListFilter.push(summary);
        }
      }
    })
    setSummaryList(summaryListFilter);
    */
  };

  const getSummaryListBySearch = async(customerIdToPass, startDateToPass, endDateToPass, productIdToPass) => {
    console.log("Summary List search called ");
    console.log("Customer id at getSummaryListBySearch : ", customerIdToPass);
    console.log("Product id at getSummaryListBySearch : ", productIdToPass);
    console.log("Start Date to Pass at getSummaryListBySearch : ", startDateToPass);
    console.log("End Date to Pass at getSummaryListBySearch : ", endDateToPass);

    var retState = false
    var summaryListBySearch = [];
    var error_msg = "";
    try {
      var aIVal = localStorage.getItem("_aI");
      var reqParam = {
        params: {
        customer_id: customerIdToPass,
        start_date: startDateToPass,
        end_date: endDateToPass,
        product_id: productIdToPass
        }
      };
      console.log("REQUEST PARAM >>>>>>>>>>>>>> : ", reqParam);
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + `/getSummaryListBySearch?customer_id=${customerIdToPass}&start_date=${startDateToPass}&end_date=${endDateToPass}&product_id=${productIdToPass}`, { headers }, { withCredentials: false });
      
      console.log("Response from server getSummaryListBySearch : ", res.data.detail.data);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getSummaryListBySearch : ", res.data.detail.data);
        
        summaryListBySearch = res.data.detail.data;
        retState = true;
      }
      else {
        error_msg = "No Data";
      }
     
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        error_msg = "Unable to fetch data please try again";
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
        }
        error_msg = "No Data";
      }

    }
    return [retState, summaryListBySearch, error_msg];
  }

  const handleCustomerSelect = async(e, data) => {
    console.log("handleCustomerSelect called ...", e);

    var customerIdToPass = 0;
      //var locationNameToPass = ""; 
      var startDateToPass = "";
      var endDateToPass = "";
      var productIdToPass = 0;
      //setSelectedLocationId({value: 0, label: "All Locations"});
      //locationNameToPass = "All Locations";
    /*
    if ( e.value === 0 ) {
      setSelectedLocationId({value: 0, label: "All Locations"});
      locationNameToPass = "All Locations";
    }
    else {
      locationNameToPass = selectedLocationId.label;
    }
    */
    
      customerIdToPass = e.value;
      startDateToPass = formValues["start_date"];
      endDateToPass = formValues["end_date"];
      productIdToPass = productIdSelect.value;
      console.log("CUSTOMER ID SELECTED : ", e.value);
      console.log("START DATE SELECTED : ", formValues["start_date"]);
      console.log("END DATE SELECTED : ", formValues["end_date"]);
      console.log("PRODUCT ID SELECTED : ", productIdToPass);
      /*
      var [retSummarySearchState, summaryListBySearch, error_msg] = await getSummaryListBySearch(customerIdToPass, startDateToPass, endDateToPass, productIdToPass);
      if ( retSummarySearchState ) {
        setSummaryList(summaryListBySearch)
      }
      else {
        console.log("<<<<<<<<<<<<<<<<<<<< SEARCH FAILED >>>>>>>>>>>>>>>>>>>> ");
        setSearchError(true);
        setDcList([]);
      }
      */

    setCustomerIdSelect(e);
  };
  const getCustomerListDropdown = async () => {
    console.log("getCustomerListDropdown method called ...");
    var retState = false;


    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}` }
      var res = await axios.get(client.domain + "/crm/getAllCustomers", { headers }, { withCredentials: false });

      console.log("Response from server getCustomerList : ", res.data.detail.data);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getCustomerList : ", res.data.detail.data);


        var customerArr = [];
        var custAll = { value: 0, label: 'All Customers' };
        customerArr.push(custAll);
        res.data.detail.data.map((itemCustomer, idx) => {
          console.log("Ind val : ", itemCustomer);
          var customerInd = {
            value: itemCustomer.id,
            label: itemCustomer.name,
          };
          console.log("In customer modified : ", customerInd);
          customerArr.push(customerInd);
        });

        var keySearch = "order_date";



        console.log("Mod Full VALUE : ", customerArr);
        retState = true;
      }

    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.data
          );

        }
      }
    }
    return [retState, customerArr];
  };
  const handleBack = async (e) => {
    console.log("handle back called : ", e);
    navigate(0);
  };
  const handleFullPrint = async (e) => {
    //setPrintAction(true);
    setFormValues({ ...formValues, summaryPrint: "ONLY"});
    setPrintDialog(true);
    console.log("Handle Edit clicked FULL : ", e);
    console.log("Handle Edit clicked FULL currentTarget : ", e.currentTarget);
    console.log("Handle Edit clicked FULL target : ", e.target);

    const { name, value } = e.currentTarget;
    var currentId = name.substring(name.lastIndexOf("-") + 1);
    console.log("SELECTED DC ROW ID FOR PRINT : ", currentId);
    setSelectedDCRowForPrint(currentId);
  };
  const handlePrint = async (e) => {
    console.log("handle print called : ", e);
    setPrintDialog(true);
    //navigate("/printDeliverySummary");
    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(
      urlStr.lastIndexOf(client.urlDelimiter) + 1
    );
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);

    var customerIdToPass = customerIdSelect.value;
    var startDateToPass = formValues["start_date"];
    var endDateToPass = formValues["end_date"];
    var productIdToPass = productIdSelect.value;

    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printDeliverySummary?customer_id=${customerIdToPass}&start_date=${startDateToPass}&end_date=${endDateToPass}&product_id=${productIdToPass}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0,0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
  };

  const handleProductSelect = async(e, data) => {
    console.log("handleProductSelect called ...", e);
    
    //var orderListFilter = []
    var customerIdToPass = customerIdSelect.value;
    var startDateToPass = formValues["start_date"];
    var endDateToPass = formValues["end_date"];
    var productIdToPass = e.value;

    console.log("CUSTOMER ID SELECTED  at handleProductSelect: ", customerIdSelect.value);
    console.log("START DATE SELECTED at handleProductSelect: ", formValues["start_date"]);
    console.log("END DATE SELECTED at handleProductSelect: ", formValues["end_date"]);
    console.log("PRODUCT ID SELECTED at handleProductSelect: ", productIdToPass);
    /*
    var [retSummarySearchState, summaryListBySearch, error_msg] = await getSummaryListBySearch(customerIdToPass, startDateToPass, endDateToPass, productIdToPass);
    if ( retSummarySearchState ) {
        setSummaryList(summaryListBySearch);
    }
    else {
      console.log("<<<<<<<<<<<<<<<<<<<< SEARCH FAILED >>>>>>>>>>>>>>>>>>>> ");
      setSearchError(true);
      setDcList([]);
    }
    */
    setProductIdSelect(e);
  };
  const handlePrintCloseDialog = () => {
    setPrintDialog(false);
    
  };
  
  const handlePrintSaveDialog = () => {
    //console.log("user confirmed to enable or disable ...");
    //console.log("user selected id for action is : ", selectedId);

    //console.log("handlePrintDCDirect called : ", e);
    //console.log("handlePrintDCDirect called : ", e.currentTarget);
    //console.log("handlePrintDCDirect called ID : ", e.currentTarget.id);
    //console.log("handlePrintDCDirect called NAME : ", e.currentTarget.name);

    setPrintDialog(false);

    console.log("<<<<<<<<<<<< SELECTED OPTION FOR PRINT >>>>>>>>>>>>>>> : ", formValues["summaryPrint"]);
    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(
      urlStr.lastIndexOf(client.urlDelimiter) + 1
    );
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);

    var customerIdToPass = customerIdSelect.value;
    var startDateToPass = formValues["start_date"];
    var endDateToPass = formValues["end_date"];
    var productIdToPass = productIdSelect.value;

    if ( formValues["summaryPrint"] === "ONLY") {
    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printDeliverySummary?customer_id=${customerIdToPass}&start_date=${startDateToPass}&end_date=${endDateToPass}&product_id=${productIdToPass}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
    }
    else if (formValues["summaryPrint"] === "RETURNSAVE") {
      var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printDeliveryReturnSave?customer_id=${customerIdToPass}&start_date=${startDateToPass}&end_date=${endDateToPass}&product_id=${productIdToPass}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
    }
    else {
      var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printDeliverySummary?customer_id=${customerIdToPass}&start_date=${startDateToPass}&end_date=${endDateToPass}&product_id=${productIdToPass}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
    }
      winPrint.moveTo(0,0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
    /*
    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(
      urlStr.lastIndexOf(client.urlDelimiter) + 1
    );
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);

    //var dcIndInfo = dcList[selectedDCRowForPrint];
    //console.log("SELECTED DC INFO : ", dcIndInfo);
    //var dc_id = dcIndInfo.dc_id;
    
    //var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printDirectDC?dc_id=${dc_id}&action=${formValues["dcprint"]}`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0,0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight); 
    */   
  };


  return (
    <Container>
      <SidebarMain />
      { summaryListFetched ? 
      <form>
        <Row>
          <Col xs={2} md={2} sm={2} lg={2}>
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.25rem", verticalAlign: "bottom" }}>Delivery Summary</span>
          </Col>
          <Col xs={3} md={3} sm={3} lg={3}>
            <Row>
              <Col>
                <Select
                inline
                  id="customer"
                  name="customer"
                  value={{
                    value: customerIdSelect.value,
                    label: customerIdSelect.label,
                  }}
                  options={optCustomer}
                  defaultValue={{
                    value: customerIdSelect.value,
                    label: customerIdSelect.label,
                  }}
                  onChange={handleCustomerSelect}
                //isDisabled={isReadOnly}
                />
              </Col>
              <Col>
              <Select
                    inline
                    id="product"
                    name="product"
                    value={{
                      value: productIdSelect.value,
                      label: productIdSelect.label,
                    }}
                    options={optProduct}
                    defaultValue={{
                      value: productIdSelect.value,
                      label: productIdSelect.label,
                    }}
                    onChange={handleProductSelect}
                    //isDisabled={isReadOnly}
                  />
                  </Col>
            </Row>
          </Col>

          <Col xs={5} md={5} sm={5} lg={5}>

            <Row>

              <Col>
                <Form.Control
                  type="date"
                  name="start_date"
                  placeholder="Start Date"
                  value={formValues.start_date}
                  onChange={onChangeDatePicker}
                //readOnly={isReadOnly}

                />

              </Col>

              <Col>
                <Form.Control
                  type="date"
                  name="end_date"
                  placeholder="End Date"
                  value={formValues.end_date}
                  onChange={onChangeDatePicker}
                //readOnly={isReadOnly}

                />
              </Col>

              <Col>
              <Button id="search-order-id"
              variant="success"
              onClick={(e) => handleDateSearch(e)}>
              Search
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
              </Col>
            </Row>
          </Col>

          <Col
            xs={2}
            md={2}
            sm={2}
            lg={2}
            className="d-flex justify-content-end float-end">
          
            <Button variant="warning" className="btn btn-warning btn-md">
                <LuRefreshCw
                  key={`back-btn-receipt-key`}
                  size={24}
                  style={{ cursor: "pointer", color:"white" }}
                  onClick={(e) => handleBack(e)}
                  name={`back-icon-summary-name`}
                  id={`back-icon-summary-id`}
                />
              </Button>
              { summaryList.length > 0 &&
              <>
              &nbsp;&nbsp;
            <Button id="summaryPrint" name = "summaryPrint"  onClick={(e)  => handleFullPrint(e)}>
                <BsFillPrinterFill
                  size={18}
                  style={{ color: "white", cursor: "pointer" }}
                  name={`print-summary-icon`}
                  id={`print-summary-icon`}
                />
              </Button>
              </>
              }
          </Col>

          
          {/*
            <Col xs={3} md={3} sm={3} lg={3} className="justify-content-end float-end">
              <Button
                variant="warning"
                id="add-btn-id"
                name="add-btn-name"
                className="justify-content-end float-end"
                onClick={(e) => handleAdd(e)}
              >
                New Customer
              </Button>
            </Col>
           */}
        </Row>
        <hr align="center" />
        <div className="ui-divider"></div>

        <Table striped bordered hover>
          <thead>
            <tr className="table-primary">
              <th className="table-row-heading" style={{textAlign: "center"}}>S.No</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Date</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Customer</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Grade</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Location</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Total Vol (M<sup>3</sup>)</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Unit Price</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Product Price</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Extra charges </th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Total Amount</th>
              <th className="table-row-heading" style={{textAlign: "center"}}>Details</th>
            </tr>
          </thead>
          <tbody>
            {summaryList.map((summary, idx) => (
                <tr key={`summary-table-row-key-${idx}`}>
                <td style={{textAlign: "right"}}>{idx + 1}</td>

                <td style={{textAlign: "left"}}>{formatDateIndian(new Date(summary.delivery_date))}</td>
                <td style={{textAlign: "left"}}>{summary.customer_name}</td>
                <td style={{textAlign: "left"}}>{summary.product_name}</td>
                <td style={{textAlign: "left"}}>{summary.address_line1}</td>
                <td style={{textAlign: "right"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(summary.total_quantity).toFixed(2)))}</td>
                <td style={{textAlign: "right"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(summary.unit_price).toFixed(2)))}</td>
                <td style={{textAlign: "right"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(summary.total_quantity * summary.unit_price).toFixed(2)))}</td>
                <td style={{textAlign: "right"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(summary.diesel_charges) + parseFloat(summary.pumping_charges) + parseFloat(summary.others_charges1)))}</td>
                <td style={{textAlign: "right"}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(summary.total_amount).toFixed(2)))}</td>

                <td style={{textAlign: "center"}}>
                  <BsCardText
                    key={`edit-btn-summary-key-${idx}`}
                    size={28}
                    style={{ color: "#0D6EFD", cursor: "pointer" }}
                    onClick={(e) => handleView(e)}
                    name={`edit-icon-summary-name-${idx}`}
                    id={`edit-icon-summary-id-${idx}`}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/*
      <Modal
        show={showDialog}
        onHide={handleCloseDialog}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Activae / In-Activate this customer? Click OK to proceed...
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSwitchAction}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      */}
      <Modal
              show={printDialog}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>Print Options</Modal.Title>
              </Modal.Header>
              <Modal.Body><Row>
              <Col xs={6} md={6} sm={6} lg={6} >
              {/*
              <Button
                      variant="primary"
                      id="print_savings"
                      
                      onClick={(e) => handlePrintFullDC(e)}
                    >
                      Print With Returned & Savings
                    </Button>
                    */}
                    <Form.Check
            inline
            label="Without Return & Save"
            name="summaryPrint"
            type="radio"
            id={`summary-print-radio-only`}
            onChange={(e) => handleRadioChange(e)}
            checked={ formValues["summaryPrint"] === 'ONLY' ? true : false}
          />
                    </Col>
                   
                    <Col xs={6} md={6} sm={6} lg={6} >
                    {/*
                    <Button
                      variant="primary"
                      id="print_dc"
                      
                      onClick={(e) => handlePrintDC(e)}
                    >
                      Print DC Only
                    </Button>
                    */}
                    <Form.Check
            inline
            label="With Return & save"
            name="summaryPrint"
            type="radio"
            id={`summary-print-radio-returnsave`}
            onChange={(e) => handleRadioChange(e)}
            checked={ formValues["summaryPrint"] === 'RETURNSAVE' ? true : false}
          />
                    </Col>
                   
                </Row>
                </Modal.Body>
              <Modal.Footer>
              <Button variant="secondary" onClick={handlePrintCloseDialog}>
            Cancel
          </Button>
                <Button variant="primary" onClick={handlePrintSaveDialog}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal> 
      </form>
    
    :
    <div className="container-spinner">
    <div className="vertical-center-spinner">
     {/*<Button variant="primary" disabled>*/}
     <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
        <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{ width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
            <span className="visually-hidden">Loading...</span>
          </Spinner>

     {/*</Button>*/}
     </div>
     </div>
    }
    </Container>

  );
}
export default ListDeliverySummary;
