import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  FormControl,
  Spinner,
  Image
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
import Sidebar from "./Sidebar";
import Sidebar2 from "./Sidebar2";
import Topmenu from "./Topmenu";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";

import { useNavigate, useLocation } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client, ConfigMinValue, configMaxValue, appGlobal } from "../config/Config";
import axios from "axios";

import Footer from "./Footer";

import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "bootstrap/dist/css/bootstrap.min.css";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';

import {  getProductBuy } from "./utils";

import {
  BsCartX,
  BsArrowLeftCircle,
  BsFillPenFill,
  Bs3Circle,
  BsFillXCircleFill,
  BsPencilSquare,
  BsCardText
} from "react-icons/bs";
import SidebarMain from "./SidebarMain";
import {getDistrictList, getStateList } from "./utils";

const AddUpdatePurchaseOrder = (props) => {
  const initialized = useRef(false);
  const distListRef = useRef([]);
  const stateListRef = useRef([]);
  const navigate = useNavigate();
  const { state } = useLocation(); 
  const[districtListLoaded, setDistrictListLoaded] = useState(false);

  const [passedValue, setPassedValue] = useState(state.passed_element);
  /*
  let initialItems = [
    {id:1, product: {value:3, label:"M15"}, price: "4900", qty: 5, "product_cost": "24500", "gst": "4410", "total_cost":"28910", gst:{cgst: 2205.00, sgst:2205.00, igst:0.00, totGst: 4410.00}}, 
    {id:1, product: {value:6, label:"M25"}, price: "4800", qty: 7, "product_cost": "215001", "gst": "38701", "total_cost":"253701", gst:{cgst: 2205.00, sgst:2205.00, igst:0.00, totGst: 4410}}
  ]
  */
  let initialItems = [];

  /*
  const options = [
    { value: 1, label: 'M5', stock_id: 1 },
    { value: 2, label: 'M7.5', stock_id: 13 },
    { value: 6, label: 'M10', stock_id: 14 },
    { value: 7, label: 'M15', stock_id: 15 },
    { value: 8, label: 'M20', stock_id: 16 },
    { value: 9, label: 'M25', stock_id: 17 },
    { value: 10, label: 'M30', stock_id: 18 },
    { value: 13, label: 'M30', stock_id: 19 },
  ];
  */
 /*

  let product_list = [
    {
      value: 1,
      label: "M5",
      product_id: 1,
      stock_id: 1,
      product_name: "M5",
      unit_cost: 3900,
      gst: { cgst: 9.0, sgst: 9.0, igst: 0.0 },
    },
    {
      value: 2,
      label: "M7.5",
      product_id: 2,
      stock_id: 13,
      product_name: "M7.5",
      unit_cost: 0.00,
      gst: { cgst: 9.0, sgst: 9.0, igst: 0.0 },
    },
    {
      value: 6,
      label: "M10",
      product_id: 6,
      stock_id: 14,
      product_name: "M10",
      unit_cost: 0.00,
      gst: { cgst: 9.0, sgst: 9.0, igst: 0.0 },
    },
    {
      value: 7,
      label: "M15",
      product_id: 7,
      stock_id: 15,
      product_name: "M15",
      unit_cost: 0.00,
      gst: { cgst: 9.0, sgst: 9.0, igst: 0.0 },
    },
    {
      value: 8,
      label: "M20",
      product_id: 8,
      stock_id: 16,
      product_name: "M20",
      unit_cost: 0.00,
      gst: { cgst: 9.0, sgst: 9.0, igst: 0.0 },
    },
    {
      value: 9,
      label: "M25",
      product_id: 9,
      stock_id: 17,
      product_name: "M25",
      unit_cost: 0.00,
      gst: { cgst: 9.0, sgst: 9.0, igst: 0.0 },
    },
    {
      value: 10,
      label: "M30",
      product_id: 10,
      stock_id: 18,
      product_name: "M30",
      unit_cost: 0.00,
      gst: { cgst: 9.0, sgst: 9.0, igst: 0.0 },
    },
    {
      value: 13,
      label: "M40",
      product_id: 13,
      stock_id: 19,
      product_name: "M40",
      unit_cost: 6000,
      gst: { cgst: 9.0, sgst: 9.0, igst: 0.0 },
    },
  ];
*/
/*
  const optionsDistrict = [
    { value: 1, label: "Coimbatore" },
    { value: 2, label: "Tiruppur" },
    { value: 3, label: "Erode" },
    { value: 4, label: "Karur" },
    { value: 5, label: "Namakkal" },
    { value: 6, label: "Ooty" },
    { value: 7, label: "Salem" },
  ];

  const optionsState = [
    { value: 1, label: "Tamil Nadu" },
    { value: 2, label: "Kerala" },
    { value: 3, label: "Karanataka" },
    { value: 4, label: "Andra Pradesh" },
    { value: 5, label: "Telungana" },
    { value: 6, label: "Pondichery" },
  ];
  */

  const [stateList, setStateList] = useState([]);

  /*
  const buyProdArr = [
    {
      "id": 14,
      "name": "RMCM05",
      "description": "RMC M05555",
      "hsncode": "123",
      "uom": "m3",
      "unit_price": 4500.00,
      "cgst": 100,
      "sgst": 200,
      "igst": 250
    },
    {
      "id": 12,
      "name": "flyash",
      "description": "flyash",
      "hsncode": "68159990",
      "uom": "kg",
      "unit_price": 0.00,
      "cgst": 0,
      "sgst": 0,
      "igst": 0
    },
    {
      "id": 11,
      "name": "admixture",
      "description": "admixture",
      "hsncode": "38244090",
      "uom": "kg",
      "unit_price": 0.00,
      "cgst": 0,
      "sgst": 0,
      "igst": 0
    },
    {
      "id": 5,
      "name": "aggregate",
      "description": "",
      "hsncode": "25171010",
      "uom": "unit",
      "unit_price": 0.00,
      "cgst": 0,
      "sgst": 0,
      "igst": 0
    },
    {
      "id": 4,
      "name": "cement",
      "description": "",
      "hsncode": "252321",
      "uom": "kg",
      "unit_price": 0.00,
      "cgst": 0,
      "sgst": 0,
      "igst": 0
    },
    {
      "id": 3,
      "name": "msand",
      "description": "",
      "hsncode": "441039",
      "uom": "unit",
      "unit_price": 0.00,
      "cgst": 0,
      "sgst": 0,
      "igst": 0
    }
  ];
  */

  const [buyProdList, setBuyProdList] = useState([]);

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  //const intialValues = {company:"", address: "", city:"", zip:"", gst_number: "", phone: "", district: "", state: "", product:{value:"m15", label:"M15"}};
  const intialValues = {
    /*
    supplier_id: 0,
    supplier_name: "",
    supplier_disp_name: "",
    gst_number: "",
    phone: "",
    primary_number: "",
    secondary_number: "",
    contact_person: "",
    contact_number: "",
    email: "",
    address1: "",
    address2: "",
    pincode: "",
    website: "",
    product: { value: "m15", label: "M15" },
    dist_id: 1,
    state_id: 1,
    */

    /*
    shipContactName: "",
    shipContactNumber: "",
    shipAddress1: "",
    shipAddress2: "",
    shipPincode: "",

    billContactName: "",
    billContactNumber: "",
    billAddress1: "",
    billAddress2: "",
    billPincode: "",
    */
    id: 0,
    name: "",
    display_name: "",
    contact_person: "",
    contact_person_number: "",
    gst_no: "",
    phone: "",
    contact_number_primary: "",
    contact_number_secondary: "",
    email: "",
    website: "",
    address_line1: "",
    address_line2: "",
    district_id: 1,
    state_id: 1,
    country_id: 1,
    pincode: "",
    is_active: true,
    status: "",
    products: [],
    order_date: formatDate(new Date())
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [productMaster, setProductMaster] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [stateIdSelect, setStateIdSelect] = useState({});

  const [districtIdSelect, setDistrictIdSelect] = useState({});

  const [billDistrictIdSelect, setBillDistrictIdSelect] = useState({});

  const [optDist, setOptDist] = useState([]);


  const [optState, setOptState] = useState([]);

  const [optionsSearch, setOptionsSearch] = useState([]);

  const [supplierSearchText, setSupplierSearchText] = useState("");

  const [searchedSupplier, setSearchedSupplier] = useState({});

  const [orderAddress, setOrderAddress] = useState({});
  const [orderInfoDet, setOrderInfoDet] = useState({});

  const [addressFetched, setAddressFetched] = useState(false);
  const [orderDetFetched, setOrderDetFetched] = useState(false);
  const [orderItemFetched, setOrderItemFetched] = useState(false);
  const [orderDcFetched, setOrderDcFetched] = useState(false);

  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("");
  const [msgType, setMsgType] = useState("success");

  const [companyInfo, setCompanyInfo] = useState({});
  const [companyFetchStatus, setCompanyFetchStatus] = useState(false);
  const [optionsState, setOptionsState] = useState([]);
  const [toRender, setToRender] = useState(false);

  var validateErr = false;

  var prodListLoad = [
    //{prod_id: 1, product_name: "M-10", hsncode:"HSN778328", unit_price:4300.00, quantity:10, total_item_price: 43000.00, gst: 7740.00, total_price:50740.00},
    //{prod_id: 1, product_name: "M-20", hsncode:"HSN778329", unit_price:4700.00, quantity:10, total_item_price: 47000.00, gst: 8460.00, total_price:55460.00},
    //{prod_id: 1, product_name: "M-30", hsncode:"HSN778378", unit_price:4900.00, quantity:10, total_item_price: 49000.00, gst: 8820.00, total_price:57820.00}
  ];
  var dcLoad = [
    //{dc_id: 12, dc_number:"DC102091Z1", dc_date:"2023-08-01", grade_id:1, grade_name:"M-20", loading_time:"2023-08-01", vehicle_number:"TN 39 DX 9927", total_volume:"18 cum", volume: "10 cum", total_amount:4200, driver_name:"Azhagu Raja"},
    //{dc_id: 11, dc_number:"DC10209879", dc_date:"2023-07-31", grade_id:2, grade_name:"M-15", loading_time:"2023-07-31", vehicle_number:"TN 39 DY 1254", total_volume:"28 cum", volume: "18 cum", total_amount:42000, driver_name:"Mahesh C"}
  ];

  const [deliveryChellan, setDeliveryChellan] = useState(dcLoad);
  const [prodList, setProdList] = useState(prodListLoad);

  const [gstBillStatus, setGstBillStatus] = useState(true);

  const [isReadOnly, setIsReadOnly] = useState(true);

  //const [searchText, setSearchText] = useState('');

  const handleValueChange = async (e, data) => {
    e.preventDefault();
    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    //console.log("Event code : ", e.keyCode);

    if (
      name === "phone" ||
      name === "contact_number" ||
      name === "shipContactNumber" ||
      name === "billContactNumber" ||
      name === "pincode" ||
      name === "shipPincode" ||
      name === "billPincode"
    ) {
      console.log("Inside check ******************");
      //const numericRegExp = /^(?:[1-9]\d*|\d)$/;
      if (e.target.value.match(appGlobal.wholeNumberRegExp)) {
      //var valueTmp = Math.round(e.target.value.replace(/\D/g, ""));
      setFormValues({ ...formValues, [name]: e.target.value });
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleSearch = (e, dat) => {
    console.log("Handled search method called ...", e);

    
  };

  const handleDistrictSelect = (e, data) => {
    console.log("handleDistrictSelect called ...", e);

    setDistrictIdSelect(e);
  };

  const handleBillDistrictSelect = async (e) => {
    console.log("handle bill district : ", e);

    setBillDistrictIdSelect(e);
  };
  const handleStateSelect = async (e) => {
    console.log("handleStateSelect called ...", e);
    setStateIdSelect(e);

    // var stateIdInput = e.value;
    //var stateNameInput = e.label;
    var [retStateDist, distArrRet] = await getDistrictList(e.value);
    if (retStateDist) {
      setOptDist(distArrRet);
      setDistrictIdSelect(distArrRet[0]);
    }
  };

  

  const handleChange = (e, data) => {
    //const min = 0;
    //const max = 10000;
    console.log("Handle Change clicked : ", e);
    console.log("Handle Change clicked target : ", e.currentTarget);
    //console.log("HTML tag : ", data);
    const { name, value } = e.currentTarget;
    console.log("Captured Quantity name : ", name);
    console.log("Captured Quantity value : ", value);
    //setFormValues({...formValues, [name]: value});
    console.log("FORM VALUES at handleChange func : ", formValues);

    var currentId = name.substring(name.lastIndexOf("-") + 1);
    var nameOfElement = name.substring(0, name.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    var itemSelected = items[currentId];
    //console.log("Product: ", itemSelected);
    console.log("Product Name : ", itemSelected.product_name);
    console.log("Price : ", itemSelected.unit_price);
    console.log("Product cost : ", itemSelected.total_item_price);
    console.log("GST : ", parseFloat(itemSelected.cgst + itemSelected.sgst + itemSelected.igst));
    console.log("Total cost : ", itemSelected.total_price);
    console.log("Qty : ", itemSelected.quantity);

    console.log("Product Info : ", buyProdList);
    const currentProductIndex = buyProdList.findIndex((productInd) => productInd.name === itemSelected.product_name);
    console.log("Currently selected row product id : ", currentProductIndex);
    if (currentProductIndex < 0) {
      itemSelected.error = "Please select product from list";

      const newitems = items.slice();
      newitems[currentId] = itemSelected;
      setItems(newitems);
      return 0;
    } else {
      itemSelected.error = "";
    }

    console.log("Currently selected row product value : ", buyProdList[currentProductIndex]);

    //console.log("%%%%%%%%%%%%%% GST INFO ++++++++++++++ : ", buyProdList[currentProductIndex].cgst);


    /*
    itemSelected.product = {
      value: productMaster[currentProductIndex].product_id,
      label: productMaster[currentProductIndex].product_name,
      //stock_id: productMaster[currentProductIndex].stock_id,
      cgst: productMaster[currentProductIndex].cgst,
      sgst: productMaster[currentProductIndex].sgst,
      igst: productMaster[currentProductIndex].igst,
    };
    */
    itemSelected.product_id = buyProdList[currentProductIndex].id;
    itemSelected.product_name = buyProdList[currentProductIndex].name;
    //itemSelected.cgst = buyProdList[currentProductIndex].cgst;
    //itemSelected.sgst = buyProdList[currentProductIndex].sgst;
    //itemSelected.igst = buyProdList[currentProductIndex].igst;


    //itemSelected.unit_cost = productMaster[currentProductIndex].unit_cost;

    
    //itemSelected.product_cost = productMaster[currentProductIndex].unit_cost * itemSelected.qty;
    //itemSelected.unit_cost = productMaster[currentProductIndex].unit_cost;
    //itemSelected.total_item_price = itemSelected.unit_price * itemSelected.quantity;
    //itemSelected.total_item_price = 0.00;
    if ( value && parseFloat(itemSelected.unit_price) > 0.00 && parseFloat(itemSelected.quantity) > 0.00) {
      if ( nameOfElement === "quantity" ) {
        itemSelected.total_item_price = parseFloat(itemSelected.unit_price) * parseFloat(value); 
      }
      else if ( nameOfElement === 'unit_price' ) {
        itemSelected.total_item_price = parseFloat(value) * parseFloat(itemSelected.quantity);
      }
      else {
        itemSelected.total_item_price = parseFloat(itemSelected.unit_price) * parseFloat(itemSelected.quantity);
      }
      itemSelected.total_item_price = itemSelected.total_item_price.toFixed(2);
    }
    else {
      itemSelected.total_item_price = 0.00;
    }
    var cgstCalculate = 0.0;
    if (gstBillStatus) {
      cgstCalculate = (itemSelected.total_item_price * buyProdList[currentProductIndex].cgst) / 100;
    }
    //itemSelected.cgst = 0.00;

    var sgstCalculate = 0.0;

    if (gstBillStatus) {
      sgstCalculate = (itemSelected.total_item_price * buyProdList[currentProductIndex].sgst) / 100;
    }
    //itemSelected.sgst = 0.00;

    var igstCalculate = 0.0;
    if (gstBillStatus) {
      igstCalculate = (itemSelected.total_item_price * buyProdList[currentProductIndex].igst) / 100;
    }

    if ( parseFloat(itemSelected.total_item_price) > 0.00 ) {
      itemSelected.total_price = parseFloat(itemSelected.total_item_price) + parseFloat(itemSelected.cgst) + parseFloat(itemSelected.sgst) + parseFloat(itemSelected.igst);
      itemSelected.total_price = itemSelected.total_price.toFixed(2);
    }
    //itemSelected.igst = 0.00;
    //let totalGst = cgstTmp + sgstTmp + igstTmp;

    //var gst = { cgst: cgstTmp, sgst: sgstTmp, igst: igstTmp, totGst: totalGst }
    //itemSelected.gst = gst;
    //itemSelected.product_cost = productMaster[currentProductIndex].unit_cost * itemSelected.qty;

    //itemSelected.total_price = parseFloat(itemSelected.total_item_price) + parseFloat(itemSelected.cgst + itemSelected.sgst + itemSelected.igst);
    //itemSelected.total_price = 0.00;
    if (nameOfElement === "quantity" || nameOfElement === "unit_price" || nameOfElement === 'total_item_price' || nameOfElement === 'total_price' || nameOfElement === 'cgst' || nameOfElement === 'sgst' || nameOfElement === 'igst') {
      console.log("Product Quantity item edited ...");
      //const numericRegExp = /^\d*\.?\d*$/;
      if (e.currentTarget.value.match(appGlobal.numericRegExp) /*&& parseFloat(e.currentTarget.value) > 0.00*/) {
        if ( !isNaN(e.currentTarget.value) ) {
          itemSelected[nameOfElement] = e.currentTarget.value;
        }
      }
    }

    /*
    if ( parseFloat(itemSelected.unit_price) > 0.00 && parseFloat(itemSelected.unit_price) > 0.00) {
      itemSelected.total_item_price = parseFloat(itemSelected.unit_price) * parseFloat(itemSelected.quantity);
    }
    else {
      itemSelected.total_item_price = 0.00;
    }
    */
    if ( parseFloat(itemSelected.total_item_price) > 0.00 ) {
      itemSelected.total_price = parseFloat(itemSelected.total_item_price) + parseFloat(itemSelected.cgst) + parseFloat(itemSelected.sgst) + parseFloat(itemSelected.igst);
      itemSelected.total_price = itemSelected.total_price.toFixed(2);
    }
    else {
      itemSelected.total_price = 0.00;
    }
    console.log("Quantity after set : ", itemSelected.quantity);
    console.log("Modified unit cost : ", itemSelected.unit_price);

    console.log("Modified Product cost : ", itemSelected.total_item_price);
    console.log("Modified Total cost : ", itemSelected.total_price);

    const newitems = items.slice();
    newitems[currentId] = itemSelected;
    setItems(newitems);
  };

  
  const getPurchaseOrderAddress = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(
        client.domain + `/getPurchaseOrderAddress?order_id=${passedValue.order_id}`,{ headers },
        { withCredentials: false }
      );
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getPurchaseOrderAddress : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log(
          "Response message from server getPurchaseOrderAddress : ",
          res.data.detail.res_data
        );
        setOrderAddress(res.data.detail.res_data.order_address);

        formValues["shipContactName"] =
          res.data.detail.res_data.order_address.delivery_address.contact_name;
        formValues["shipContactNumber"] =
          res.data.detail.res_data.order_address.delivery_address.contact_number;
        formValues["shipAddress1"] =
          res.data.detail.res_data.order_address.delivery_address.address_line1;
        formValues["shipAddress2"] =
          res.data.detail.res_data.order_address.delivery_address.address_line2;
        formValues["shipPincode"] =
          res.data.detail.res_data.order_address.delivery_address.pincode;

        console.log("Delivery contact : ", formValues["shipContactName"]);

        formValues["billContactName"] =
          res.data.detail.res_data.order_address.billing_address.contact_name;
        formValues["billContactNumber"] =
          res.data.detail.res_data.order_address.billing_address.contact_number;
        formValues["billAddress1"] =
          res.data.detail.res_data.order_address.billing_address.address_line1;
        formValues["billAddress2"] =
          res.data.detail.res_data.order_address.billing_address.address_line2;
        formValues["billPincode"] =
          res.data.detail.res_data.order_address.billing_address.pincode;

        console.log("Billing : ", formValues["billContactName"]);

        setAddressFetched(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
  };

  const getPurchaseOrderDetails = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(
        client.domain + `/getPurchaseOrderById?order_id=${passedValue.order_id}`,{ headers },
        { withCredentials: false }
      );
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getOrdersList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getPurchaseOrderDetails : ", res.data.detail.res_data);
        //console.log("Response message from server getPurchaseOrderDetails user_info : ", res.data.detail.res_data.order_info);
        //console.log("Response message from server getPurchaseOrderDetails order info : ", res.data.detail.res_data.order_info);
        //console.log("Length of orderInfo array : ", res.data.detail.res_data.order_info.length);
        setOrderDetFetched(true);
        //optionsDistrict = res.data.detail.res_data.district_list

        setOrderInfoDet(res.data.detail.res_data.order_info);
        var dd = new Date(res.data.detail.res_data.order_info.order_date);
        formValues["order_date"] = formatDate(dd);
        console.log("Date of order_date : ", dd.getMonth());
        //setGstBillStatus(res.data.detail.res_data.order_info.bill_gst_include);
        //console.log("CUSTOMER ID : ", res.data.detail.res_data.order_info.customer_id);

        /*
        if ( res.data.detail.res_data.order_list.length > 0 ) {
          setOrderList(res.data.detail.res_data.order_list);
          //setOptDist(res.data.detail.res_data.district_list)
          //setDistrictIdSelect({value: res.data.detail.res_data.district_list[0].value, label: res.data.detail.res_data.district_list[0].label});
        }
        */
        //setDistrictIdSelect(res.data.detail.res_data.district_list);

        //localStorage.setItem('_aI', res.data.detail.res_data.aI);
        //localStorage.setItem('userRoleId', userRoleId);
        //localStorage.setItem('username', res.data.detail.res_data.user_info.username);

        //navigate("/dashboard");
      }
      /*
      if (res.data.status === "SUCCESS" ) {
        this.setState ({
        tokenJwt: res.data.res_data.token,
        isLoaded: true,
        openOTP: true,
        })
          // this.props.history.push({pathname: client.taskViewPath, state:{pageList: this.state.page, orgName: this.state.orgName} });
      }
      else {
      }
    */
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
  };

  const getPurchaseOrderItemsByOrderId = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(
        client.domain +
          `/getPurchaseOrderItemsByOrderId?order_id=${passedValue.order_id}`,{ headers },
        { withCredentials: false }
      );
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getPurchaseOrdersList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log(
          "Response message from server PurchaseOrderDetails : ",
          res.data.detail.res_data
        );
        console.log(
          "Response message from server PurchaseOrderDetails user_info : ",
          res.data.detail.res_data.user_info
        );
        console.log(
          "Response message from server purchase order items list : ",
          res.data.detail.res_data.order_item_list
        );
        console.log("Length of purchase order items array : ", res.data.detail.res_data.order_item_list.length);
        //optionsDistrict = res.data.detail.res_data.district_list

        //setOrderInfoDet(res.data.detail.res_data.order_info);

        if (res.data.detail.res_data.order_item_list.length > 0) {
          //setProdList(res.data.detail.res_data.order_item_list);
          setItems(res.data.detail.res_data.order_item_list);
          //setOptDist(res.data.detail.res_data.district_list)
          //setDistrictIdSelect({value: res.data.detail.res_data.district_list[0].value, label: res.data.detail.res_data.district_list[0].label});
        }

        setOrderItemFetched(true);
        //setDistrictIdSelect(res.data.detail.res_data.district_list);

        //localStorage.setItem('_aI', res.data.detail.res_data.aI);
        //localStorage.setItem('userRoleId', userRoleId);
        //localStorage.setItem('username', res.data.detail.res_data.user_info.username);

        //navigate("/dashboard");
      }
      /*
      if (res.data.status === "SUCCESS" ) {
        this.setState ({
        tokenJwt: res.data.res_data.token,
        isLoaded: true,
        openOTP: true,
        })
          // this.props.history.push({pathname: client.taskViewPath, state:{pageList: this.state.page, orgName: this.state.orgName} });
      }
      else {
      }
    */
      //setOrderItemFetched(true);
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
  };

  const getPurchaseDCListByOrderId = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(
        client.domain + `/getPurchaseDCListByOrderId?order_id=${passedValue.order_id}`,{ headers },
        { withCredentials: false }
      );
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getPurchaseOrdersList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log(
          "Response message from server Purchase Order DC Details : ",
          res.data.detail.res_data
        );
        /*
        console.log(
          "Response message from server OrderDetails user_info : ",
          res.data.detail.res_data.user_info
        );
        console.log(
          "Response message from server purchase order items list : ",
          res.data.detail.res_data.dc_list
        );
        console.log(
          "Length of purchase order dc list array : ",
          res.data.detail.res_data.dc_list.length
        );
        */
        //optionsDistrict = res.data.detail.res_data.district_list

        //setOrderInfoDet(res.data.detail.res_data.order_info);

        if (res.data.detail.res_data.dc_list.length > 0) {
          setDeliveryChellan(res.data.detail.res_data.dc_list);
          //setProdList(res.data.detail.res_data.dc_list);
          //setOptDist(res.data.detail.res_data.district_list)
          //setDistrictIdSelect({value: res.data.detail.res_data.district_list[0].value, label: res.data.detail.res_data.district_list[0].label});
        }

        //setDistrictIdSelect(res.data.detail.res_data.district_list);

        //localStorage.setItem('_aI', res.data.detail.res_data.aI);
        //localStorage.setItem('userRoleId', userRoleId);
        //localStorage.setItem('username', res.data.detail.res_data.user_info.username);

        //navigate("/dashboard");
        setOrderDcFetched(true);
      }
      /*
      if (res.data.status === "SUCCESS" ) {
        this.setState ({
        tokenJwt: res.data.res_data.token,
        isLoaded: true,
        openOTP: true,
        })
          // this.props.history.push({pathname: client.taskViewPath, state:{pageList: this.state.page, orgName: this.state.orgName} });
      }
      else {
      }
    */
      //setOrderDcFetched(true);
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
    setOrderDcFetched(true);
  };


  /*
  async function getProductsBuyWrapper () {
    const [retState, newBuyProdArr] = await getProductBuy();
    console.log("BUY PRODUCT LIST RETURN STATE getProductsBuyWrapper : ", retState);
    console.log("BUY PRODUCT LIST getProductsBuyWrapper 2 : ", newBuyProdArr);
    return {retState, newBuyProdArr};
  }
  */

  const getCompanyInfo = async() => {
    console.log("getCompanyInfo called ...");

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers,Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(
        client.domain + `/core/getCompany`,{ headers },
        { withCredentials: false }
      );

      console.log("Response from server getCompanyInfo : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server for company info : ", res.data.detail.data[0]);
        if (res.data.detail.data.length > 0) {
          
          console.log("SHIPPING CONTACT NAME : ", res.data.detail.data[0].contact_person);
          formValues["shipContactName"] = res.data.detail.data[0].contact_person;
          formValues["shipContactNumber"] = res.data.detail.data[0].contact_person_number || res.data.detail.data[0].phone;
          formValues["shipAddress1"] = res.data.detail.data[0].address_line1;
          formValues["shipAddress2"] = res.data.detail.data[0].address_line2;
          formValues["shipPincode"] = res.data.detail.data[0].pincode;

          formValues["billContactName"] = res.data.detail.data[0].contact_person;
          formValues["billContactNumber"] = res.data.detail.data[0].contact_person_number || res.data.detail.data[0].phone;
          formValues["billAddress1"] = res.data.detail.data[0].address_line1;
          formValues["billAddress2"] = res.data.detail.data[0].address_line2;
          formValues["billPincode"] = res.data.detail.data[0].pincode;

          setCompanyInfo(res.data.detail.data[0]);
          //setProdList(res.data.detail.res_data.dc_list);
          //setOptDist(res.data.detail.res_data.district_list)
          //setDistrictIdSelect({value: res.data.detail.res_data.district_list[0].value, label: res.data.detail.res_data.district_list[0].label});
        }

        //setDistrictIdSelect(res.data.detail.res_data.district_list);

        //localStorage.setItem('_aI', res.data.detail.res_data.aI);
        //localStorage.setItem('userRoleId', userRoleId);
        //localStorage.setItem('username', res.data.detail.res_data.user_info.username);

        //navigate("/dashboard");
        //setOrderDcFetched(true);
        setCompanyFetchStatus(true);
      }
      /*
      if (res.data.status === "SUCCESS" ) {
        this.setState ({
        tokenJwt: res.data.res_data.token,
        isLoaded: true,
        openOTP: true,
        })
          // this.props.history.push({pathname: client.taskViewPath, state:{pageList: this.state.page, orgName: this.state.orgName} });
      }
      else {
      }
    */
      //setOrderDcFetched(true);
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
    //setOrderDcFetched(true);
  }

  useEffect(() => {
    if (!initialized.current) {
      console.log("*************** useEffect called ##########################");
      console.log(formErrors);
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        console.log(formErrors);
      }

      
      if (state === null) {
        navigate("/listPurchaseOrders");
      } else {
        console.log("PASSED VALUE THROUGH STATE variable : ", state);

        //const {retState, newBuyProdArr} = getProductsBuyWrapper();
       
      /*
      (async () => {
        var stateIdInput = 1;
        if (state.action === 'view' || state.action === 'edit') {
          stateIdInput = state.passed_element.state_id;
        }
        var retStateDist = false;
        var distArrRet = [];
        [retStateDist, distArrRet] = await getDistrictList(stateIdInput);

        if (retStateDist) {
          setOptDist(distArrRet);
          if (state.action === 'add') {
            setDistrictIdSelect(distArrRet[0]);
          }
          else {
            var findDist = distArrRet.find(element => element.value === state.passed_element.district_id);
            if (findDist !== undefined) {
              console.log("++++++++++++++++++------------------- FOUND DISTRICT : ", findDist);
              setDistrictIdSelect({ value: state.passed_element.district_id, label: findDist.label });
            }
          }
        }

        var country_id = appGlobal.COUNTRY_ID;
        var [retStateStList, stateArrRet] = await getStateList(country_id);
        if (retStateStList) {
          //setOptionsState(stateArrRet);
          setOptState(stateArrRet);
          if (state.action === 'add') {
            setStateIdSelect(stateArrRet[0]);
          }
          else {
            var findState = stateArrRet.find(element => element.value === state.passed_element.state_id);
            if (findState) {
              console.log("STATE FOUND OBJECT : ", findState);
              setStateIdSelect({ value: state.passed_element.state_id, label: findState.label });
            }
          }
        }
      })();
      */
     
      //getProductBuy();

        //setProductMaster(buyProdList);
      
        getCompanyInfo();

        if (state.action === "edit" || state.action === "view") {
          getSupplierDetails();
          getPurchaseOrderAddress();
          getPurchaseOrderDetails();
          getPurchaseOrderItemsByOrderId();
          getPurchaseDCListByOrderId();
        }
        

        if (state.action === "add") {
          setIsReadOnly(false);
        }
        
      }
      (async () => {
      var stateIdInput = 1;
      var retStateDist = false;
      var customerInfo = {};
          var distArrRet = [];
          [retStateDist, distArrRet] = await getDistrictList(stateIdInput);

          if (retStateDist) {
            setOptDist(distArrRet);
            if (state.action === 'add') {
              setDistrictIdSelect(distArrRet[0]);
            }
            else {
              var findDist = distArrRet.find(element => element.value === customerInfo.district_id);
              if (findDist !== undefined) {
                console.log("++++++++++++++++++------------------- FOUND DISTRICT : ", findDist);
                setDistrictIdSelect({ value: customerInfo.district_id, label: findDist.label });
              }
            }
          }

          var country_id = appGlobal.COUNTRY_ID;
          var [retStateStList, stateArrRet] = await getStateList(country_id);
          if (retStateStList) {
            setOptionsState(stateArrRet);
            if (state.action === 'add') {
              setStateIdSelect(stateArrRet[0]);
            }
            else {
              var findState = stateArrRet.find(element => element.value === customerInfo.state_id);
              if (findState) {
                console.log("STATE FOUND OBJECT : ", findState);
                setStateIdSelect({ value: customerInfo.state_id, label: findState.label });
              }
            }
          }
          setToRender(true);
        })();
     
      initialized.current = true;
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit called ...");

    console.log("FORM VALUES at handleSubmit: ", formValues);
    console.log("LINE ITEMS : ", items);
    console.log("Form submit called ...");

    console.log("FORM VALUES at handleChange func : ", formValues);

    console.log("CUSTOMER : ", formValues["name"]);

    console.log("GST Number : ", formValues["gst_no"]);
    console.log("Phone Number : ", formValues["phone"]);

    console.log("Tot number of items : ", total_items);
    console.log("Sum of Tot Product Cost : ", sum_product_cost);
    console.log("Sum of Tot GST : ", sum_product_gst);
    console.log("Sum of total cost : ", sum_product_total);

    console.log("SEARCHED CUSTOMER : ", searchedSupplier);

    console.log("FORM VALUES : ", formValues);

    formErrors.alertMsg = "";
    var supplier_name_tmp = "";
    var supplier_id_tmp = 0;

    //if (!searchedSupplier || searchedSupplier.value.length < 1) {
    if ( !searchedSupplier || Object.keys(searchedSupplier).length < 1) {
      supplier_name_tmp = supplierSearchText;
      supplier_id_tmp = 0;
    } else {
      supplier_name_tmp = searchedSupplier.label;
      supplier_id_tmp = searchedSupplier.value;
    }

    console.log("Customer name to submit : ", supplier_name_tmp);
    setFormValues({ ...formValues, ["name"]: supplier_name_tmp });

    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log("After validateErrors and setsubmit call ...");

    if (validateErr) {
      console.log("Validate error occured ...");
      return 0;
    }

    var orderIdTmp = 0;
    var actionTmp = "add";
    var billingAddrIdTmp = 0;
    var shipAddrIdTmp = 0;
    var customerId = 0;
    var orderNoTmp = "";
    if (state.action === "view" || state.action === "edit") {
      orderIdTmp = orderInfoDet.order_id;
      orderNoTmp = orderInfoDet.order_no;
      billingAddrIdTmp = orderAddress.billing_address.address_id;
      shipAddrIdTmp = orderAddress.delivery_address.address_id;

      supplier_id_tmp = formValues["supplier_id"];
      formValues["order_date"] = orderInfoDet.order_date;
      actionTmp = "edit";
    }

    if (items.length < 1) {
      //setShowMsg("Please add atleast one product in order");
      //setMsgType("warning");
      //setShow(true);
      console.log("Empty product list ...");
      setFormErrors({ ...formErrors, ["alertMsg"]: "Add atleast one item" });
      return 0;
    }

    var emptyItem = false;
    items.map((item) => {
      console.log("Item value : ", item);
      if (item.product_id < 1 || !item.product_name) {
        item.error = "Please select product from list";
        //setShowMsg("Please selecyt product from dropdown");
        //setMsgType("warning");
        //setShow(true);
        emptyItem = true;
        return 0;
      } else {
        item.error = "";
      }
    });

    console.log("<<<<<<<<<<<<<<<< API calling continue >>>>>>>>>>>>>>>>>>");
    if (emptyItem) {
      console.log("unselcted product item so exit from submission ...");
      return 0;
    }

    var dispNameTmp = "";
    var dispNameFromField = formValues["customer_disp_name"];
    if ( dispNameFromField === "" || dispNameFromField === null || dispNameFromField === undefined ) {
      dispNameTmp = supplier_id_tmp;
    }
    else {
      dispNameTmp = dispNameFromField;
    }
    let reqValidateJwt = {
      /*
      company: formValues["company"],
      address: formValues["address"],
      city: formValues["city"],
      zip: formValues["zip"],

      phone: formValues["phone"],
      gst_number: formValues["gst_number"],

      district: formValues["district"],
      state: formValues["state"],
      */
      supplier_id: formValues["supplier_id"],
      //customer_name: supplier_name_tmp,
      //customer_id: supplier_id_tmp,
      //customer_disp_name: dispNameTmp,
      //gst_number: formValues["gst_number"],
      //phone: formValues["phone"],
      //primary_number: formValues["primary_number"],
      //secondary_number: formValues["secondary_number"],
      //contact_person: formValues["contact_person"],
      //contact_number: formValues["contact_number"],
      //email: formValues["email"],
      //address1: formValues["address1"],
      //address2: formValues["address2"],
      distId: districtIdSelect.value,
      stateId: stateIdSelect.value,
      //pincode: formValues["pincode"],
      website: formValues["website"],

      total_items: total_items,
      sum_product_cost: sum_product_cost,
      sum_product_gst: sum_product_gst,

      sum_product_cgst: sum_product_cgst,
      sum_product_sgst: sum_product_sgst,
      sum_product_igst: sum_product_igst,

      amount_discount: amount_discount,

      sum_product_total: sum_product_total,

      orderItems: JSON.stringify(items),

      order_id: orderIdTmp,
      order_no: orderNoTmp,

      ship_addr_id: shipAddrIdTmp,
      ship_contact_name: formValues["shipContactName"],
      ship_contact_number: formValues["shipContactNumber"],
      ship_address1: formValues["shipAddress1"],
      ship_address2: formValues["shipAddress2"],
      ship_pincode: formValues["shipPincode"],

      bill_addr_id: billingAddrIdTmp,
      bill_contact_name: formValues["billContactName"],
      bill_contact_number: formValues["billContactNumber"],
      bill_address1: formValues["billAddress1"],
      bill_address2: formValues["billAddress2"],
      bill_pincode: formValues["billPincode"],

      //bill_gst_include: gstBillStatus,
    };

    console.log("payload:", reqValidateJwt);

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.post(
        client.domain + "/insertPurchaseOrder",
        reqValidateJwt,
        { headers },
        { withCredentials: false }
      );
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.res_data);
        navigate("/listPurchaseOrders");
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      
      if ( error.code === 'ERR_NETWORK' ) {
        console.log("Not able to reach target server please try after sometime");
        //setShowMsg('Please try after sometime');
        //setShowMsgTitle('Network Issue!');
        //setShow(true);
      }
      else{
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === 'FAILED') {
          console.log('Request failed due to ', error.response.data.detail.res_data);
          //setShowMsg('Please Re-Login');
          //setShowMsgTitle('Invalid Session!');
          //setShow(true);
          //navigate("/listPurchaseOrders");
        }
      }
    }
  };
  const validate = (values) => {
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!supplierSearchText && !searchedSupplier) {
      //if (!values.customer){
      errors.name = "Supplier name is required!";
    }

    if (!values.display_name) {
      errors.display_name = "Display name is required!";
      validateErr = true;
    }
    /*
    if (!values.phone) {
      errors.phone = "Phone  is required!";
      validateErr = true;
    }
    */

    if (values.phone && values.phone.length != 10) {
      errors.phone = "Phone number should be 10 digits";
      validateErr = true;
    }

    {
      /*
    if (!values.contact_person) {
      errors.contact_person = "Contact person is required!";
      validateErr = true;
    }

    if (!values.contact_number) {
      errors.contact_number = "Contact person number is required!";
      validateErr = true;
    }
  */
    }

    if (!values.address_line2) {
      errors.address_line2 = "City / Town  is required!";
      validateErr = true;
    }
    {
      /*
    if (!values.address2) {
      errors.address2 = "City or Town name is required!";
      validateErr = true;
    }
  */
    }
    /*
    if (!values.pincode) {
      errors.pincode = "Pincode is required!";
      validateErr = true;
    }
    */

    if (values.pincode && values.pincode.length != 6) {
      errors.pincode = "Pincode should be 6 digits";
      validateErr = true;
    }
    /*
    if (!values.primary_number) {
      errors.primary_number = "Mobile number is required";
      validateErr = true;
    }

    if (values.primary_number && values.primary_number.length != 10) {
      errors.primary_number = "Mobile number should be 10 digits";
      validateErr = true;
    }
    */
    // Delivery address validation
    if (!formValues.shipContactName) {
      errors.shipContactName = "Contact name is required!";
      validateErr = true;
    }

    if (!formValues.shipContactNumber) {
      errors.shipContactNumber = "Contact number is required!";
      validateErr = true;
    }

    if (!formValues.shipAddress1) {
      errors.shipAddress1 = " Street / Location is required!";
      validateErr = true;
    }

    if (!formValues.shipPincode) {
      errors.shipPincode = " Pincode is required!";
      validateErr = true;
    }

    /*
    if (!formValues.shipAddress2) {
      errors.shipAddress2 = "Town / City is required!";
      validateErr = true;
    }
    */

    // Billing address validation
    if (!formValues.billContactName) {
      errors.billContactName = "Contact name is required!";
      validateErr = true;
    }

    if (!formValues.billContactNumber) {
      errors.billContactNumber = "Contact number is required!";
      validateErr = true;
    }

    if (!formValues.billAddress1) {
      errors.billAddress1 = "Street / Location is required!";
      validateErr = true;
    }

    /*
    if (!formValues.billAddress2) {
      errors.billAddress2 = "Town / City is required!";
      validateErr = true;
    }
    */

    if (!formValues.billPincode) {
      errors.billPincode = "Pincode is required!";
      validateErr = true;
    }
    return errors;
  };

  const saveHandler = async (event) => {
    // Prevent page reload
    event.preventDefault();

    console.log("Form submit clicked ...");

    console.log("DATA : ", document.forms[0]);

    /*
    var { username, password } = document.forms[0];
    console.log("username : ", username.value);
    console.log("pasword : ", password.value);
    */
  };

  const backHandler = async (event) => {
    event.preventDefault();
    console.log("Back button clicked ...");
  };

  const newItemHandle = async (event) => {
    event.preventDefault();
    console.log("New item addition button clicked ...");

    const newitems = items.slice();
    //let itemNew = { id: 1, product: { value: 0, label: "" }, unit_cost: "", qty: 1, "product_cost": "", "total_cost": "", gst: { cgst: 0.00, sgst: 0.00, igst: 0.00, totGst: 0.00 } };
    let itemNew = {
      order_item_id: 0,
      product_id: 0,
      product_name: "",
      unit_price: "",
      quantity: "",
      total_item_price: "",
      total_price: "",
      cgst: "",
      sgst: "",
      igst: "",
    };
    newitems.push(itemNew);
    setItems(newitems);
  };

  const handleProductSelect = (e, data) => {
    console.log(
      ">>>>>>>>>>>>>>>>>>>>>>> HandleProductSelect called *******************"
    );
    console.log("SELECTED Dropdown value : ", e);
    console.log("SELECTED element id : ", data);
    var currentId = data.name.substring(data.name.lastIndexOf("-") + 1);
    console.log("Current id : ", currentId);

    var itemSelected = items[currentId];
    console.log("Product: ", itemSelected);

    console.log("<<<<<<<<<<<<<<<<<<<<< ITEM ID >>>>>>>>>>>>>>>>>>: ", itemSelected.id);
    console.log("<<<<<<<<<<<<<<<< PRODUCT ID >>>>>>>>>>>>>>>>>>>>: ", itemSelected.product_id);
    console.log("<<<<<<<<<<<<<<<< PRODUCT NAME >>>>>>>>>>>>>>>>>>: ", itemSelected.product_name);
    //console.log("Price : ", itemSelected.product_id);
    //console.log("Price : ", itemSelected.product_name);

    console.log("Price : ", itemSelected.unit_price);
    console.log("Product cost : ", itemSelected.total_item_price);
    console.log("GST : ", parseFloat(itemSelected.cgst + itemSelected.sgst + itemSelected.igst));
    console.log("Total cost : ", itemSelected.total_price);
    console.log("Qty : ", itemSelected.quantity);

    console.log("Product Info : ", buyProdList);
    const currentProductIndex = buyProdList.findIndex(
      (productInd) => productInd.name === e.label
    );
    console.log("Currently selected row product id : ", currentProductIndex);
    console.log("Currently selected row product value : ", buyProdList[currentProductIndex]);

    itemSelected.product_id = e.value;
    itemSelected.product_name = e.label;
    //itemSelected.product.label = e.label;
    /*
    var productNew = {
      label: e.label,
      value: e.value,
      product_id: e.id,
      product_name: e.name,
      stock_id: 0,
      unit_cost: e.unit_price,
      uom: e.uom,
      cgst: e.cgst,
      sgst: e.sgst,
      igst: e.igst,
      hsncode: e.hsncode,
      description: e.description
    };
    itemSelected.product = productNew;
    */
    //itemSelected.price = productMaster[currentProductIndex].unit_cost;
    //itemSelected.unit_price = buyProdList[currentProductIndex].unit_price;
    itemSelected.unit_price = 0.00;
    //itemSelected.total_item_price = buyProdList[currentProductIndex].unit_cost * itemSelected.quantity;
    itemSelected.total_item_price = 0.00;

    /*
    var cgstCalculate = 0.0;
    if (gstBillStatus) {
      cgstCalculate = (itemSelected.total_item_price * buyProdList[currentProductIndex].cgst) / 100;
    }
    */
    itemSelected.cgst = 0.00;

    /*
    var sgstCalculate = 0.0;
    if (gstBillStatus) {
      sgstCalculate = (itemSelected.total_item_price * buyProdList[currentProductIndex].sgst) / 100;
    }
    */
    itemSelected.sgst = 0.00;

    /*
    var igstCalculate = 0.0;
    if (gstBillStatus) {
      igstCalculate = (itemSelected.total_item_price * buyProdList[currentProductIndex].igst) / 100;
    }
    */
    itemSelected.igst = 0.00;
    //let totalGst = cgstTmp + sgstTmp + igstTmp;

    //var gst = { cgst: cgstTmp, sgst: sgstTmp, igst: igstTmp, totGst: totalGst }
    //itemSelected.gst = gst
    //itemSelected.product_cost = productMaster[currentProductIndex].unit_cost * itemSelected.qty;

    //itemSelected.total_price = parseFloat(itemSelected.total_item_price) + parseFloat(itemSelected.cgst + itemSelected.sgst + itemSelected.igst);
    itemSelected.total_price = 0.00;
    console.log("Modified unit cost : ", itemSelected.unit_price);
    console.log("Modified Product cost : ", itemSelected.total_item_price);
    console.log("Modified Total cost : ", itemSelected.total_price);

    const newitems = items.slice();
    newitems[currentId] = itemSelected;
    setItems(newitems);
    //var fieldValue = "product";
    //setFormValues({ ...formValues, [fieldValue]: e })
  };

  /*
  const searchActionFunc = (json) => {
    setOptionsSearch(json.detail.res_data.district_list);
    setIsLoading(false)
    console.log("SEARCHED VALUE inside searchActionFunc : ", json.detail.res_data.district_list);
  }
  */

  const supplierSearchSelectFunc = async (selected) => {
    console.log(
      "**************** User selected value from search result +++++++++++++ : ",
      selected
    );
    if (selected.length > 0) {
      setSearchedSupplier(selected[0]);

      const response = await fetch(
        `${client.domain}/srm/getSupplier?id=${selected[0].value}`
      );
      const supplier_info = await response.json();
      console.log("CUSTOMER INFO : ", supplier_info.detail.data);

      //setFormValues({...formValues, [name]: value});
      //let entries = Object.entries(company_info.detail.res_data.company_info)
      //entries.map( ([key, val] = entry) => {
      //entries.map( ([key, val] = entry) => {
      for (const [key, value] of Object.entries(supplier_info.detail.data)) {
        //console.log("KEY : ", key);
        //console.log("VAL : ", val);
        console.log("KEY : %s, VAL: %s", key, value);
      }

      formValues["supplier_id"] = supplier_info.detail.data["id"];
      formValues["name"] = supplier_info.detail.data["name"];
      formValues["display_name"] = supplier_info.detail.data["display_name"];
      formValues["contact_person"] = supplier_info.detail.data["contact_person"];
      formValues["contact_person_number"] = supplier_info.detail.data["contact_person_number"];
      formValues["gst_no"] = supplier_info.detail.data["gst_no"];
      formValues["phone"] = supplier_info.detail.data["phone"];
      formValues["contact_number_primary"] = supplier_info.detail.data["contact_number_primary"];
      formValues["contact_number_secondary"] = supplier_info.detail.data["contact_number_secondary"];
      formValues["email"] = supplier_info.detail.data["email"];
      formValues["website"] = supplier_info.detail.data["website"];
      formValues["address_line1"] = supplier_info.detail.data["address_line1"];
      formValues["address_line2"] = supplier_info.detail.data["address_line2"];
      formValues["district_id"] = supplier_info.detail.data["district_id"];
      formValues["state_id"] = supplier_info.detail.data["state_id"];
      formValues["country_id"] = supplier_info.detail.data["country_id"];
      formValues["pincode"] = supplier_info.detail.data["pincode"];
      formValues["is_active"] = supplier_info.detail.data["is_active"];
      formValues["status"] = supplier_info.detail.data["status"];
      formValues["products"] = supplier_info.detail.data["products"];

      //setFormValues({...formValues, [key]: value});
      var supplyProdArr = [];
      supplier_info.detail.data["products"].map((prodIndTmp) => {
        var supplierInd = { value: prodIndTmp.id, label: prodIndTmp.name, uom: prodIndTmp.uom, id: prodIndTmp.id, name: prodIndTmp.name};
        supplyProdArr.push(supplierInd);
      })

      if ( supplier_info.detail.data["products"].length > 0 ) {
        setBuyProdList(supplyProdArr);
      }

      
      //setFormValues(supplier_info.detail.data);

      console.log("Supplier Name : ", supplier_info.detail.data["name"]);
      console.log("<<<<<<<<<<<<<<<< SUPPLIER INFO : >>>>>>>>>>>>>>>>>> ", supplier_info.detail.data);
      
      var country_id = appGlobal.COUNTRY_ID;
      var [retStateList, stateArrRet] = await getStateList(country_id);
      if ( retStateList ) {
        console.log("STATE LIST <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< : ", stateArrRet);
        stateListRef.current = stateArrRet;
        setOptState(stateArrRet);
      }

      console.log("<<<<<<<<<<<<<<<< STATE LIST : +++++++++++++++++++++ ", stateListRef.current);
      const currentStateIndex = stateListRef.current.findIndex((stateInd) => stateInd.state_id === supplier_info.detail.data["state_id"]);
      setStateIdSelect({
        value: supplier_info.detail.data["state_id"],
        label: stateListRef.current[currentStateIndex]["state_name"],
      });

      var retStateDist = false;
      var distArrRet = [];
      [retStateDist, distArrRet] = await getDistrictList(supplier_info.detail.data["state_id"]);
      if (retStateDist) {
        setOptDist(distArrRet);
      }

      console.log("DISTRICT ID : ", supplier_info.detail.data["district_id"]);
      console.log("<<<<<<<<<<<<<<<<<<<<<<<< DISTRICT LIST : >>>>>>>>>>>>>>>>>>>>>>>>", distArrRet);
      const currentDistIndex = distArrRet.findIndex((distInd) => distInd.district_id === supplier_info.detail.data["district_id"]);
      setDistrictIdSelect({
        value: supplier_info.detail.data["district_id"],
        label: distArrRet[currentDistIndex]["district_name"],
      });

      //setFormValues({...formValues, ["company_disp_name"]: "ABC"});
      //setFormValues({...formValues, ["pincode"]: "641654"});

      console.log("FORMVALUES 2 : ", formValues);
    }
  };

  const getSupplierDetails = async () => {
    //console.log("**************** User selected value from search result +++++++++++++ : ", selected);
    //if (selected.length > 0) {
    //setSearchedCompany(selected[0])
    var supplierId = passedValue.supplier_id;

    const response = await fetch(`${client.domain}/srm/getSupplier?id=${supplierId}`);
    const supplier_info = await response.json();
    console.log("SUPPLIER INFO : ", supplier_info.detail.data);

    //setFormValues({...formValues, [name]: value});
    //let entries = Object.entries(company_info.detail.res_data.company_info)
    //entries.map( ([key, val] = entry) => {
    //entries.map( ([key, val] = entry) => {
    /*
    for (const [key, value] of Object.entries(supplier_info.detail.data)) {
      //console.log("KEY : ", key);
      //console.log("VAL : ", val);
      console.log("KEY : %s, VAL: %s", key, value);

      //setFormValues({...formValues, [key]: value});
    }
    */

    //setFormValues(customer_info.detail.res_data.customer_info);

    formValues["supplier_id"] = supplier_info.detail.data.id;
    formValues["name"] = supplier_info.detail.data.name;
    formValues["display_name"] = supplier_info.detail.data.display_name;
    formValues["contact_person"] = supplier_info.detail.data.contact_person;
    formValues["contact_person_number"] = supplier_info.detail.data.contact_person_number;
    formValues["gst_no"] = supplier_info.detail.data.gst_no;
    formValues["phone"] = supplier_info.detail.data.phone;
    formValues["contact_number_primary"] = supplier_info.detail.data["contact_number_primary"];
    formValues["contact_number_secondary"] = supplier_info.detail.data["contact_number_secondary"];
    formValues["email"] = supplier_info.detail.data.email;
    formValues["website"] = supplier_info.detail.data.website;
    formValues["address_line1"] = supplier_info.detail.data.address_line1;
    formValues["address_line2"] = supplier_info.detail.data.address_line2;
    formValues["district_id"] = supplier_info.detail.data["district_id"];
    formValues["state_id"] = supplier_info.detail.data["state_id"];
    formValues["country_id"] = supplier_info.detail.data["country_id"];
    formValues["pincode"] = supplier_info.detail.data["pincode"];
    formValues["is_active"] = supplier_info.detail.data.is_active;
    formValues["status"] = supplier_info.detail.data.status;
    formValues["products"] = supplier_info.detail.data["products"];

    var supplyProdArr = [];
      supplier_info.detail.data["products"].map((prodIndTmp) => {
        var supplierInd = { value: prodIndTmp.id, label: prodIndTmp.name, uom: prodIndTmp.uom, id: prodIndTmp.id, name: prodIndTmp.name};
        supplyProdArr.push(supplierInd);
      })

      if ( supplier_info.detail.data["products"].length > 0 ) {
        setBuyProdList(supplyProdArr);
      }
    
    if (state.action === "edit" || state.action === "view") {
      console.log("######################## Condition met for view or edit %%%%%%%%%%%%%%%%%%%%");
      setSupplierSearchText(supplier_info.detail.data.name);
    }

    var country_id = appGlobal.COUNTRY_ID;
    var [retStateList, stateArrRet] = await getStateList(country_id);
    if ( retStateList ) {
      stateListRef.current = stateArrRet;
      setOptState(stateArrRet);
    }
    console.log("^^^^^^^^^^^^ STATE ID : ", supplier_info.detail.data["state_id"]);
    console.log("++++++++++++++++++++++++ OPT STATE : ", stateListRef.current);
    var findState = stateListRef.current.find(element => element.value === supplier_info.detail.data["state_id"]);
    if ( findState !== undefined ) {
      setStateIdSelect({value: supplier_info.detail.data["state_id"], label: findState.label});
    }

    var [retDistState, distArrRet] = await getDistrictList(supplier_info.detail.data["state_id"]);
    if ( retDistState ) {
      distListRef.current = distArrRet;
      setOptDist(distArrRet);
      setDistrictListLoaded(true);
    }
    console.log("DISTRICT ID : ", supplier_info.detail.data["district_id"]);
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> OPT DISTRICT : ", distListRef.current);
    var findDist = distListRef.current.find(element => element.value === supplier_info.detail.data["district_id"]);
    if ( findDist !== undefined ) {
      console.log("++++++++++++++++++------------------- FOUND DISTRICT : ", findDist);
      setDistrictIdSelect({value: supplier_info.detail.data["district_id"], label: findDist.label});
  }

    //setFormValues({...formValues, ["company_disp_name"]: "ABC"});
    //setFormValues({...formValues, ["pincode"]: "641654"});

    console.log("FORMVALUES 2 : ", formValues);
    //}
  };

  /*
  const getProductList = async() =>{
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {
        ...client.headers,
        Authorization: `Bearer ${aIVal}`
      }
      var res = await axios.get(
        client.domain + `/ims/getProductsTTBuy`,{ headers }, { withCredentials: false }
      );
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getProductList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        setBuyProdList(res.data.detail.data);
      }
    }catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
  };
*/

  const searchHandleFunc = async (query) => {
    setIsLoading(true);
    
    fetch(`${client.domain}/search/suppliers?q=${query}`)
      .then((resp) => resp.json())
      .then((json) => {
        //searchActionFunc(json)
        setOptionsSearch(json.detail.res_data.supplier_list);
        setIsLoading(false);
        console.log(
          "SEARCHED VALUE inside searchActionFunc : ",
          json.detail.res_data.supplier_list
        );
      });
      setFormValues({ ...formValues, ["display_name"]: query });
      setSupplierSearchText(query);
  }

  console.log("FORM VALUES Before rendering :", formValues);

  var total_items = 0;
  var sum_product_cost = 0.0;
  var sum_product_gst = 0.0;
  var sum_product_cgst = 0.0;
  var sum_product_sgst = 0.0;
  var sum_product_igst = 0.0;

  var sum_product_total = 0.0;
  var amount_discount = 0.0;

  console.log(
    "<<<<<<<<<<<<<<<<+++++++++++++++ Length of items : ",
    items.length
  );
  items.map((item) => {
    console.log("IND ITEM : ", item);
    console.log("Product Cost : ", item.total_item_price);
    var totGst = item.cgst + item.sgst + item.igst;
    console.log("GST  : ", totGst);

    //console.log("GST  : ", item.gst.cgst);
    //console.log("GST  : ", item.gst.sgst);
    //console.log("GST  : ", item.gst.igst);

    console.log("Total Price : ", item.total_price);
    total_items = total_items + 1;
    sum_product_cost = sum_product_cost + parseFloat(item.total_item_price);

    //sum_product_gst = sum_product_gst + item.gst.totGst;
    sum_product_cgst = sum_product_cgst + parseFloat(item.cgst);
    sum_product_sgst = sum_product_sgst + parseFloat(item.sgst);
    sum_product_igst = sum_product_igst + parseFloat(item.igst);

    //sum_product_total = sum_product_total + item.total_cost;
    //sum_product_total =  sum_product_total +
  });

  sum_product_gst = sum_product_cgst + sum_product_sgst + sum_product_igst;
  sum_product_total = sum_product_cost + sum_product_gst;

  sum_product_total = sum_product_total - parseFloat(amount_discount);

  console.log("Tot number of items : ", total_items);
  console.log("Sum of Tot Product Cost : ", sum_product_cost);

  console.log("Sum of Tot CGST : ", sum_product_cgst);
  console.log("Sum of Tot SGST : ", sum_product_sgst);
  console.log("Sum of Tot iGST : ", sum_product_igst);

  console.log("Sum of Tot GST : ", sum_product_gst);

  console.log("Sum of total cost : ", sum_product_total);

  console.log("Currently selected district : ", districtIdSelect);
  console.log("opt district : ", optDist);
  //console.log("*********** opt district value ############# : ", optDist.value);

  console.log("Currently selected state : ", stateIdSelect);
  console.log("+++++++++++++++ Customer search text ^^^^^^^^^^^^^^^^^^^^  :", supplierSearchText);
  console.log("Searched customer <<<<<<<<<<<<<<<<<<< : ", searchedSupplier);

  const [addrCopyCheck, setAddrCopyCheck] = useState(false);

  const [refresh, setRefresh] = useState(false);

  const handleCopyToShipping = async (e) => {
    console.log("handleCopyToShipping called : ", e);
    console.log("handleCopyToShipping called CHECKED : ", e.target.checked);
    setAddrCopyCheck(e.target.checked);

    if (e.target.checked === true) {
      var contactPersontmp = formValues["contact_person"];
      var contactPersonAssign = "";

      console.log("Customer contact name before check : ", contactPersontmp);

      console.log(
        "Customer contact name length before check : ",
        contactPersontmp.length
      );

      if (contactPersontmp) {
        contactPersonAssign = contactPersontmp;
      } else {
        var supplier_name_tmp = "";
        var supplier_id_tmp = 0;

        //if (!searchedSupplier || searchedSupplier.value.length < 1) {
        if ( !searchedSupplier || Object.keys(searchedSupplier).length < 1) {
          supplier_name_tmp = supplierSearchText;
          supplier_id_tmp = 0;
        } else {
          supplier_name_tmp = searchedSupplier.label;
          supplier_id_tmp = searchedSupplier.value;
        }

        console.log("Customer name to submit : ", supplier_name_tmp);

        contactPersonAssign = supplier_name_tmp;
      }

      var contactNumbertmp = formValues["contact_number"];
      var contactNumberAssign = "";

      console.log("Contact number : ", contactNumbertmp);
      if (contactNumbertmp) {
        contactNumberAssign = contactNumbertmp;
      } else {
        contactNumberAssign = formValues["phone"];
      }

      formValues["shipContactName"] = contactPersonAssign;
      formValues["shipContactNumber"] = contactNumberAssign;
      formValues["shipAddress1"] = formValues["address_line1"];
      formValues["shipAddress2"] = formValues["address_line2"];
      //formValues["billPincode"] =  formValues["pincode"];
      //setFormValues({...formValues, ["billContactName"]: formValues["contact_person"]});
      //setFormValues({...formValues, ["billContactNumber"]: formValues["contact_number"]});
      //setFormValues({...formValues, ["billAddress1"]: formValues["address1"]});
      //setFormValues({...formValues, ["billAddress2"]: formValues["Address2"]});
      setFormValues({ ...formValues, ["shipPincode"]: formValues["pincode"] });

      //setBillDistrictIdSelect(districtIdSelect);
    }
  };

  const [addrCopyBillCheck, setAddrCopyBillCheck] = useState(false);

  const handleCopyToBilling = async (e) => {
    console.log("handle copy to billing called :", e);
    console.log("handle copy to billing called CHECKED :", e.target.checked);
    setAddrCopyBillCheck(e.target.checked);

    if (e.target.checked === true) {
      formValues["billContactName"] = formValues["shipContactName"];
      formValues["billContactNumber"] = formValues["shipContactNumber"];
      formValues["billAddress1"] = formValues["shipAddress1"];
      formValues["billAddress2"] = formValues["shipAddress2"];
      setFormValues({
        ...formValues,
        ["billPincode"]: formValues["shipPincode"],
      });
    }
  };

  const handleItemDelete = (e) => {
    console.log("handle item delete called ...", e);
    console.log("handle item delete e.currentTarget : ", e.currentTarget.id);
    console.log("handle item delete e.target : ", e.target.id);

    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(
        0,
        clickedTagId.lastIndexOf("-")
      );
      console.log("current selected DELETE element name : ", nameOfElement);
      console.log("Current SELECTED DELETE id : ", curSelId);
      console.log("CUR SELECTED DELETE VALUE : ", items[curSelId]);

      let sliced = items.splice(curSelId, 1);
      console.log("Removed item from order items: ", sliced);
      var newItem = items.slice();
      setItems(newItem);

      //setRefresh(true);
      //console.log("Removed item from order items: ", sliced);
      //setRefresh(false);
    }
  };

  const handleBack = async (e) => {
    console.log("handle back called : ", e);
    navigate(-1);
  };

  const handleClose = async (e) => {
    setShow(false);
    //navigate(-1);
  };

  const handleGSTBillStatus = async (e) => {
    console.log("HandleGSTBillStatus called : ", e);
    console.log("target checked : ", e.target.checked);

    var itemModNew = [];
    items.map((item) => {
      console.log("IND ITEM : ", item);

      var cgstCalculate = 0.0;
      var sgstCalculate = 0.0;
      var igstCalculate = 0.0;

      if (e.target.checked) {
        if (item["product"] !== undefined) {
          console.log("CGST FROM EXISTING : ", item.cgst);
          console.log("TOTAL ITEM PRICE FROM EXISTING : ", item.total_item_price);
          cgstCalculate = (item.total_item_price * parseFloat(item.cgst)) / 100;
          sgstCalculate = (item.total_item_price * parseFloat(item.sgst)) / 100;
          igstCalculate = (item.total_item_price * parseFloat(item.igst)) / 100;
        }
      }
      item.cgst = cgstCalculate;
      item.sgst = sgstCalculate;
      item.igst = igstCalculate;

      console.log("CALCULATED CGST : ", cgstCalculate);
      console.log("CALCULATED SGST : ", sgstCalculate);
      console.log("CALCULATED IGST : ", igstCalculate);
      item.total_price =
        item.total_item_price + cgstCalculate + sgstCalculate + igstCalculate;
      console.log("CALCULATED TOTAL : ", item.total_price);

      itemModNew.push(item);
    });

    setGstBillStatus(e.target.checked);
    setItems(itemModNew);
  };

  const handleEdit = async (e) => {
    console.log("Handle Edit clicked : ", e);
    console.log("Handle Edit clicked current target ID: ", e.currentTarget.id);

    setIsReadOnly(false);
  };

  const handleClear = async (e) => {
    e.preventDefault();
    console.log("handle clear called : ", e);
    console.log("Handle clear clicked current target ID: ", e.currentTarget.id);
    navigate(0);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    console.log("handle save called : ", e);
    console.log("Handle save clicked current target ID: ", e.currentTarget.id);
  };

  const handleKeyDown = async (e) => {
    console.log("handle key down called : ", e);
    return e.keyCode !== 190;
  };

  const onChangeDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);

    setFormValues({ ...formValues, ["order_date"]: e.target.value });
  };

  
  //console.log("DISTRICT LOADED STATE : ", districtListLoaded);

  const handleNewPurchaseDC = async() => {
    console.log("New purchase dc clicked ...");

    navigate('/addUpdatePurchaseDC', 
      { 
        state: 
        { 
          passed_element: {
            dc_id: 0,
            order_id: passedValue.order_id,
            supplier_info: formValues, 
            order_info: orderInfoDet, 
            order_items: items,
            company_info: companyInfo
          },
          action: "add",
        },
      }
    )
  }


  const handlePurchaseDCEdit = async(e) => {
    console.log("handle Purchase DC Edit called : ", e);

    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(0, clickedTagId.lastIndexOf("-"));

      console.log("current selected element name OrderDetails : ", nameOfElement);
      console.log("Current SELECTED id DC ID : ", curSelId);


    navigate('/addUpdatePurchaseDC', 
      { 
        state: 
        { 
          passed_element: {
            //dc_id: deliveryChellan[curSelId].dc_id,
            dc_detail: deliveryChellan[curSelId],
            order_id: passedValue.order_id,
            //dc_no: deliveryChellan[curSelId].dc_no,
            //vehicle_no: deliveryChellan[curSelId].vehicle_no,
            //addl_info: deliveryChellan[curSelId].addl_info,
            supplier_info: formValues, 
            order_info: orderInfoDet, 
            order_items: items,
            //dc_items: deliveryChellan[curSelId].items,
          },
          action: "edit",
        },
      }
    )
    }
  }

  return (
    <>
      {((addressFetched && orderDetFetched && orderItemFetched && districtListLoaded) ||
        state.action === "add") && (
        <Container>
          <SidebarMain />
          { toRender ?
        (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={4} md={4} sm={4} lg={4}>
                <span style={{ fontSize: "1.5rem", fontWeight: "500" }}>
                  Purchase Order
                </span>
                {(state.action === "view" || state.action === "edit") && (
                  <>
                    &nbsp;&nbsp;
                    <Badge pill bg="success">
                      <h6>{orderInfoDet.order_no}</h6>
                    </Badge>
                  </>
                )}
              </Col>
              <Col
                xs={8}
                md={8}
                sm={8}
                lg={8}
                className="d-flex justify-content-end float-end"
              >
                <Button variant="warning" className="btn btn-warning btn-md"  onClick={(e) => handleBack(e)}>
                  <BsArrowLeftCircle
                    key={`back-btn-order-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                  
                    name={`back-icon-order-name`}
                    id={`edit-icon-order-id`}
                  />
                </Button>
                {(state.action === "edit" || state.action === "view") && (
                  <>
                    &nbsp;&nbsp;
                    <Button
                      variant="primary"
                      className="btn btn-primary btn-md" 
                      onClick={(e) => handleEdit(e)}

                    >
                      <BsPencilSquare
                        key={`edit-btn-order-key`}
                        size={24}
                        style={{ cursor: "pointer" }}
                       
                        name={`edit-btn-order-name`}
                        id={`edit-btn-order-id`}
                      />
                    </Button>
                  </>
                )}
              </Col>
            </Row>

            <p></p>

            <div className="ui-form">
              <hr align="center" />

              <Row>
                <Col xs={6} md={6} sm={6} lg={6}>
                  <h4 style={{ color: "#4d4d4d" }}>
                    <Badge pill bg="secondary">
                      Supplier Details
                    </Badge>
                  </h4>
                </Col>
              </Row>
              <hr align="center" />

              <Row disabled={true}>
                <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Supplier Name</Form.Label>
                    <AsyncTypeahead
                      id="customer-search-fd-id"
                      name="customer_name"
                      isLoading={isLoading}
                      onSearch={(query) => {
                        searchHandleFunc(query);
                      }}
                      onChange={(selected) => {
                        supplierSearchSelectFunc(selected);
                      }}
                      options={optionsSearch}
                      placeholder="Search Supplier ..."
                      readOnly={isReadOnly}
                      style={{
                        /*border: !isReadOnly && "0px solid gray",*/
                        borderColor: "gray",
                        /*borderStyle: "solid",*/
                      }}
                      className="rounded-sm"
                    />
                  </Form.Group>

                  <p className="error-msg">{formErrors.name}</p>
                </Col>

                <Col xs={2} md={2} sm={2} lg={2}>
                  {/*
                  <div className="field ">
                    <label>Display Name :</label>
                  </div>
                  */}
                  <Form.Group className="mb-3">
                    <Form.Label>Display Name</Form.Label>
                    <Form.Control
                      id="diplay_name"
                      type="text"
                      name="display_name"
                      placeholder="Display Name"
                      value={formValues.display_name}
                      onChange={handleValueChange}
                      readOnly={isReadOnly}
                      style={{
                        border: !isReadOnly && "1px solid gray",
                      }}
                    />
                  </Form.Group>
                  <p className="error-msg">{formErrors.display_name}</p>
                </Col>
                <Col xs={2} sm={2} md={2} lg={2}>
                  <Form.Group
                    as={Row}
                    className="mb-1">
                    <Form.Label>Order date</Form.Label>
                    <Form.Control
                      type="date"
                      id="order_date"
                      name="order_date"
                      placeholder="Order Date"
                      value={formValues.order_date}
                      onChange={onChangeDatePicker}
                      readOnly={isReadOnly}
                      style={{
                        border: !isReadOnly && "1px solid gray",
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>GST / PAN Number</Form.Label>

                    <Form.Control
                      type="text"
                      id="gst_no"
                      name="gst_no"
                      placeholder="GST / PAN Number"
                      value={formValues.gst_no}
                      onChange={handleValueChange}
                      readOnly={isReadOnly}
                      style={{
                        border: !isReadOnly && "1px solid gray",
                      }}
                    />
                  </Form.Group>

                  <p className="error-msg">{formErrors.gst_no}</p>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2}>
                  <Form.Group className="mb-3">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      id="phone"
                      name="phone"
                      placeholder="Phone"
                      value={formValues.phone}
                      onChange={handleValueChange}
                      maxLength={10}
                      readOnly={isReadOnly}
                      style={{
                        border: !isReadOnly && "1px solid gray",
                      }}
                    />
                  </Form.Group>

                  <p className="error-msg">{formErrors.phone}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Street / Location
                    </Form.Label>

                    <Form.Control
                      type="text"
                      id="address_line1"
                      name="address_line1"
                      placeholder="Building No / Street Name"
                      value={formValues.address_line1}
                      onChange={handleValueChange}
                      readOnly={isReadOnly}
                      style={{
                        border: !isReadOnly && "1px solid gray",
                      }}
                    />
                  </Form.Group>

                  <p className="error-msg">{formErrors.address_line1}</p>
                </Col>

                <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label className="field required">City / Town</Form.Label>

                    <Form.Control
                      type="text"
                      id="address_line2"
                      name="address_line2"
                      placeholder="City / Town"
                      value={formValues.address_line2}
                      onChange={handleValueChange}
                      readOnly={isReadOnly}
                      style={{
                        border: !isReadOnly && "1px solid gray",
                      }}
                    />
                  </Form.Group>

                  <p className="error-msg">{formErrors.address_line1}</p>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2}>
                  <Form.Group className="mb-3">
                    <Form.Label>District</Form.Label>

                    <Select
                      id="district"
                      name="district"
                      value={{
                        value: districtIdSelect.value,
                        label: districtIdSelect.label,
                      }}
                      options={optDist}
                      defaultValue={{
                        value: districtIdSelect.value,
                        label: districtIdSelect.label,
                      }}
                      onChange={handleDistrictSelect}
                      isDisabled={isReadOnly}
                      style={{
                        border: !isReadOnly && "1px solid gray",
                      }}
                    />
                  </Form.Group>

                  <p className="error-msg">{formErrors.district}</p>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2}>
                  <Form.Group className="mb-3">
                    <Form.Label>Pincode</Form.Label>

                    <Form.Control
                      type="text"
                      name="pincode"
                      placeholder="Pincode"
                      value={formValues.pincode}
                      onChange={handleValueChange}
                      maxLength={6}
                      readOnly={isReadOnly}
                      style={{
                        border: !isReadOnly && "1px solid gray",
                      }}
                    />
                  </Form.Group>

                  <p className="error-msg">{formErrors.pincode}</p>
                </Col>

                <Col xs={2} md={2} sm={2} lg={2}>
                  <Form.Group className="mb-3">
                    <Form.Label>State</Form.Label>

                    <Select
                      id="state"
                      name="state"
                      value={{
                        value: stateIdSelect.value,
                        label: stateIdSelect.label,
                      }}
                      options={optionsState}
                      defaultValue={{
                        value: stateIdSelect.value,
                        label: stateIdSelect.label,
                      }}
                      onChange={handleStateSelect}
                      isDisabled={isReadOnly}
                      style={{
                        border: !isReadOnly && "1px solid gray",
                      }}
                    />
                  </Form.Group>

                  <p className="error-msg">{formErrors.state}</p>
                </Col>
              </Row>

              <Row>
                <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Contact Person</Form.Label>

                    <Form.Control
                      type="text"
                      id="contact_person"
                      name="contact_person"
                      placeholder="Contact Person Name"
                      value={formValues.contact_person}
                      onChange={handleValueChange}
                      readOnly={isReadOnly}
                      style={{
                        border: !isReadOnly && "1px solid gray",
                      }}
                    />
                  </Form.Group>

                  <p className="error-msg">{formErrors.contact_person}</p>
                
                </Col>
                <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Contact Person Number</Form.Label>

                    <Form.Control
                      type="text"
                      id="contact_person_number"
                      name="contact_person_number"
                      placeholder="Contact Person Number"
                      value={formValues.contact_person_number}
                      onChange={handleValueChange}
                      maxLength={10}
                      readOnly={isReadOnly}
                      style={{
                        border: !isReadOnly && "1px solid gray",
                      }}
                    />
                  </Form.Group>

                  <p className="error-msg">{formErrors.contact_person_number}</p>
                </Col>

                <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      id="email"
                      name="email"
                      placeholder="Email"
                      value={formValues.email}
                      onChange={handleValueChange}
                      readOnly={isReadOnly}
                      style={{
                        border: !isReadOnly && "1px solid gray",
                      }}
                    />
                  </Form.Group>

                  <p className="error-msg">{formErrors.email}</p>
                </Col>

                <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Website</Form.Label>
                    <Form.Control
                      type="text"
                      id="website"
                      name="website"
                      placeholder="Website"
                      value={formValues.website}
                      onChange={handleValueChange}
                      readOnly={isReadOnly}
                      style={{
                        border: !isReadOnly && "1px solid gray",
                      }}
                    />
                  </Form.Group>

                  <p className="error-msg">{formErrors.website}</p>
                </Col>
              </Row>
              <p></p>

              
              <div className="ui-divider"></div>
              <div className="ui-form"></div>

              <Row className="show-grid">
                <Col xs={6} sm={6} md={6} lg={6}>
                  <Row>
                    <Col xs={6} md={6} sm={6} lg={6}>
                      <h4 style={{ color: "#4d4d4d" }}>
                        <Badge pill bg="secondary">
                          Delivery Address
                        </Badge>
                      </h4>
                    </Col>
                    {/*
                    <Col
                      xs={6}
                      md={6}
                      sm={6}
                      lg={6}
                      className="float-center justify-content-center"
                    >
                      <div className="d-flex justify-content-center">
                        <Form.Check
                          label="Same as Customer Address"
                          name="address-copy"
                          id="id-addr-copy-ship"
                          checked={addrCopyCheck}
                          onChange={handleCopyToShipping}
                        ></Form.Check>
                      </div>
                    </Col>
                    */}
                  </Row>
                  <hr align="center" />

                  <Row>
                    <Col xs={6} sm={6} md={6} lg={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="orderform.shipContactName"
                      >
                        <Form.Label className="field required">
                          Contact Name
                        </Form.Label>

                        <Form.Control
                          className="col-md-3 "
                          type="text"
                          name="shipContactName"
                          placeholder="Contact   Name"
                          value={formValues.shipContactName}
                          onChange={handleValueChange}
                          readOnly={isReadOnly}
                          style={{
                            border: !isReadOnly && "1px solid gray",
                          }}
                        />
                      </Form.Group>
                      <p className="error-msg">{formErrors.shipContactName}</p>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6}>
                      <Form.Group
                        className="mb-3 "
                        controlId="orderform.shipContactNumber"
                      >
                        <Form.Label className="field required">
                          Contact Number
                        </Form.Label>

                        <Form.Control
                          className="col-md-3"
                          type="text"
                          name="shipContactNumber"
                          placeholder="Contact Number"
                          value={formValues.shipContactNumber}
                          onChange={handleValueChange}
                          readOnly={isReadOnly}
                          maxLength={10}
                          style={{
                            border: !isReadOnly && "1px solid gray",
                          }}
                        />
                      </Form.Group>
                      <p className="error-msg">
                        {formErrors.shipContactNumber}
                      </p>
                    </Col>
                  </Row>

                  <div className="ui-divider"></div>
                  <Row>
                    <Col xs={6} sm={6} md={6} lg={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="orderform.shipAddress1"
                      >
                        <Form.Label
                          className="col-md-6 field required"
                          style={{ paddingTop: "10px" }}
                        >
                          Street / Location
                        </Form.Label>

                        <Form.Control
                          className="col-md-3"
                          type="text"
                          name="shipAddress1"
                          placeholder="Building No / Street Name"
                          value={formValues.shipAddress1}
                          onChange={handleValueChange}
                          readOnly={isReadOnly}
                          style={{
                            border: !isReadOnly && "1px solid gray",
                          }}
                        />
                      </Form.Group>
                      <p className="error-msg">{formErrors.shipAddress1}</p>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="orderform.shipAddress2"
                      >
                        <Form.Label
                          className="col-md-6 field"
                          style={{ paddingTop: "10px" }}
                        >
                          City / Town
                        </Form.Label>

                        <Form.Control
                          className="col-md-3"
                          type="text"
                          name="shipAddress2"
                          placeholder="City / Town"
                          value={formValues.shipAddress2}
                          onChange={handleValueChange}
                          readOnly={isReadOnly}
                          style={{
                            border: !isReadOnly && "1px solid gray",
                          }}
                        />
                      </Form.Group>
                      <p className="error-msg">{formErrors.shipAddress2}</p>
                    </Col>
                  </Row>
                  
                  {/*<label className="col-md-2" style={{paddingTop: "10px"}}>District: </label>*/}

                  
                  <Row>
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      //style={{ paddingTop: "2px" }}
                    >
                      <Form.Group
                        className="mb-3"
                        controlId="orderform.pincode"
                      >
                        <Form.Label column sm={2} className="field required">
                          Pincode
                        </Form.Label>

                        <Form.Control
                          type="text"
                          name="shipPincode"
                          placeholder="Pincode"
                          value={formValues.shipPincode}
                          onChange={handleValueChange}
                          maxLength={6}
                          readOnly={isReadOnly}
                          style={{
                            border: !isReadOnly && "1px solid gray",
                          }}
                        />
                      </Form.Group>
                      <p className="error-msg">{formErrors.shipPincode}</p>
                    </Col>
                  </Row>
                </Col>

                <Col xs={6} sm={6} md={6} lg={6}>
                  <Row>
                    <Col xs={6} md={6} sm={6} lg={6}>
                      <h4 style={{ color: "#4d4d4d" }}>
                        <Badge pill bg="secondary">
                          Billing Address
                        </Badge>
                      </h4>
                    </Col>
                    {/*
                    <Col
                      xs={6}
                      md={6}
                      sm={6}
                      lg={6}
                      className="float-center justify-content-center"
                    >
                      <div className="d-flex justify-content-center">
                        <Form.Check
                          label="Same as Delivery Address"
                          name="address-copy"
                          id="id-addr-copy-bill"
                          checked={addrCopyBillCheck}
                          onChange={handleCopyToBilling}
                        ></Form.Check>
                      </div>
                    </Col>
                    */}
                  </Row>

                  {/*
                  <Row style={{ height: props.height, paddingLeft: "10px" }}>
                    <hr />
                  </Row>
                  */}

                  
                  <hr align="center" />
                  <Row>
                    <Col xs={6} sm={6} md={6} lg={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="orderform.billContactName"
                      >
                        <Form.Label className="field required">
                          Contact Name
                        </Form.Label>

                        <Form.Control
                          className="col-md-3"
                          type="text"
                          name="billContactName"
                          placeholder="Contact Name"
                          value={formValues.billContactName}
                          onChange={handleValueChange}
                          maxLength={10}
                          readOnly={isReadOnly}
                          style={{
                            border: !isReadOnly && "1px solid gray",
                          }}
                        />
                      </Form.Group>
                      <p className="error-msg">{formErrors.billContactName}</p>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="orderform.billContactNumber"
                      >
                        <Form.Label className="field required">
                          Contact Number
                        </Form.Label>

                        <Form.Control
                          className="col-md-3"
                          type="text"
                          name="billContactNumber"
                          placeholder="Contact Number"
                          value={formValues.billContactNumber}
                          maxLength={10}
                          onChange={handleValueChange}
                          readOnly={isReadOnly}
                          style={{
                            border: !isReadOnly && "1px solid gray",
                          }}
                        />
                      </Form.Group>
                      <p className="error-msg">
                        {formErrors.billContactNumber}
                      </p>
                    </Col>
                  </Row>

                  <div className="ui-divider"></div>
                  <Row>
                    <Col xs={6} sm={6} md={6} lg={6}>
                      <Form.Group
                        className="mb-2"
                        controlId="orderform.billAddress1"
                      >
                        <Form.Label
                          className="col-md-6 field required"
                          style={{ paddingTop: "10px" }}
                        >
                          Street / Location
                        </Form.Label>

                        <Form.Control
                          className="col-md-3"
                          type="text"
                          name="billAddress1"
                          placeholder="Building No / Street Name"
                          value={formValues.billAddress1}
                          onChange={handleValueChange}
                          readOnly={isReadOnly}
                          style={{
                            border: !isReadOnly && "1px solid gray",
                          }}
                        />
                      </Form.Group>
                      <p className="error-msg">{formErrors.billAddress1}</p>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="orderform.billAddress2"
                      >
                        <Form.Label
                          className="col-md-6 field"
                          style={{ paddingTop: "10px" }}
                        >
                          City / Town
                        </Form.Label>

                        <Form.Control
                          className="col-md-3"
                          type="text"
                          name="billAddress2"
                          placeholder="City / Town"
                          value={formValues.billAddress2}
                          onChange={handleValueChange}
                          readOnly={isReadOnly}
                          style={{
                            border: !isReadOnly && "1px solid gray",
                          }}
                        />
                      </Form.Group>
                      <p className="error-msg">{formErrors.billAddress2}</p>
                    </Col>
                  </Row>
                  
                  {/*<label className="col-md-2" style={{paddingTop: "10px"}}>District: </label>*/}
                  
                  
                  <Row>
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      // style={{ paddingTop: "10px" }}
                    >
                      <Form.Group
                        className="mb-3"
                        controlId="orderform.billPincode"
                      >
                        <Form.Label column sm={2} className="field required">
                          Pincode
                        </Form.Label>

                        <Form.Control
                          type="text"
                          name="billPincode"
                          placeholder="Pincode"
                          value={formValues.billPincode}
                          onChange={handleValueChange}
                          maxLength={6}
                          readOnly={isReadOnly}
                          style={{
                            border: !isReadOnly && "1px solid gray",
                          }}
                        />
                      </Form.Group>
                      <p className="error-msg">{formErrors.billPincode}</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              
              
              <p></p>

              <Row>
                <Col xs={6} sm={6} md={6} lg={6}>
                  <div className="d-flex justify-content-start">
                    <h4>
                      <Badge pill bg="secondary">
                        Item Details
                      </Badge>
                    </h4>
                    <p
                      className="error-msg"
                      style={{
                        position: "relative",
                        //top: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      {formErrors.alertMsg}
                    </p>
                  </div>
                </Col>

                <Col xs={6} md={6} sm={6} lg={6}>
                  <div className="d-flex justify-content-end">
                    {/*
                    <Form.Check
                      style={{ paddingTop: "5px" }}
                      label="GST Bill"
                      name="gst-bill-status-name"
                      id="gst-bill-status-id"
                      checked={gstBillStatus}
                      onChange={handleGSTBillStatus}
                    ></Form.Check>
                    &nbsp;&nbsp;
                    */}
                    <Button
                      className="btn btn-warning"
                      style={{ alignItems: "self-end" }}
                      onClick={newItemHandle}
                    >
                      Add Item
                    </Button>
                  </div>
                </Col>
              </Row>
              <hr align="center" />
              <p></p>

              {items.length > 0 && (
                <>
                  <Table striped bordered hover>
                    <thead>
                      <tr className="table-primary">
                        <th className="table-row-heading">S.No</th>
                        <th className="table-row-heading">Product</th>
                        <th className="table-row-heading">Qty</th>
                        <th className="table-row-heading">Unit Price</th>
                        <th className="table-row-heading">Product Price</th>
                        <th className="table-row-heading">CGST</th>
                        <th className="table-row-heading">SGST</th>
                        <th className="table-row-heading">IGST</th>
                        <th className="table-row-heading">Total Price</th>
                        <th className="table-row-heading">***</th>
                      </tr>
                    </thead>

                    <tbody>
                      {items.map((item, idx) => (
                        <tr>
                          <td className="col-md-2" style={{width: "5%"}}>{idx + 1}</td>
                          <td style={{width: "15%"}}>
                            <Select
                              id={`product-item-${idx}`}
                              name={`product-item-${idx}`}
                              //value={options.value}
                              value={{
                                value: item.product_id,
                                label: item.product_name,
                              }}
                              options={buyProdList}
                              defaultValue={{
                                value: item.product_id,
                                label: item.product_name,
                              }}
                              onChange={handleProductSelect}
                            />
                            <p className="error-msg">{item.error}</p>
                          </td>

                          <td className="col-2" style={{width: "10%"}}>
                            <Form.Group
                              as={Row}
                              className="mb-3">
                              <Col sm="9">
                                <Form.Control
                                  style={{ padding: "5px", textAlign: "right" }}
                                  type="text"
                                  id={`quantity-${idx}`}
                                  name={`quantity-${idx}`}
                                  placeholder="Quantity"
                                  value={item.quantity}
                                  maxLength={5}
                                  onChange={handleChange}
                                  defaultValue={item.quantity}
                                />
                              </Col>
                              {/*
                              <Form.Label column sm="3">
                                M<sup>3</sup>
                              </Form.Label>
                              */}
                            </Form.Group>
                          </td>

                          <td className="col-2" style={{width: "10%"}}>
                            <Form.Control
                              style={{ padding: "5px", textAlign: "right" }}
                              type="text"
                              name={`unit_price-${idx}`}
                              placeholder="Unit Price"
                              value={item.unit_price}
                              maxLength={15}
                              onChange={handleChange}
                            />
                          </td>

                          <td
                            style={{
                              width: "120px",
                              padding: "5px",
                              textAlign: "right",
                              width: "10%"
                            }}
                          >
                            <Form.Control
                              style={{ padding: "5px", textAlign: "right" }}
                              type="text"
                              name={`total_item_price-${idx}`}
                              placeholder="Item Total Price"
                              value={item.total_item_price}
                              maxLength={15}
                              onChange={handleChange}
                            />
                          </td>
                          <td
                            style={{
                              width: "100px",
                              padding: "5px",
                              textAlign: "right",
                              width: "8%"
                            }}
                          >
                            <Form.Control
                              style={{ padding: "5px", textAlign: "right" }}
                              type="text"
                              name={`cgst-${idx}`}
                              placeholder="CGST"
                              value={item.cgst}
                              maxLength={15}
                              onChange={handleChange}
                            />
                          </td>


                          <td
                            style={{
                              width: "100px",
                              padding: "5px",
                              textAlign: "right",
                              width: "8%"
                            }}
                          >
                            <Form.Control
                              style={{ padding: "5px", textAlign: "right" }}
                              type="text"
                              name={`sgst-${idx}`}
                              placeholder="SGST"
                              value={item.sgst}
                              maxLength={15}
                              onChange={handleChange}
                            />
                          </td>



                          <td
                            style={{
                              width: "100px",
                              padding: "5px",
                              textAlign: "right",
                              width: "8%"
                            }}
                          >
                            <Form.Control
                              style={{ padding: "5px", textAlign: "right" }}
                              type="text"
                              name={`igst-${idx}`}
                              placeholder="IGST"
                              value={item.igst}
                              maxLength={15}
                              onChange={handleChange}
                            />
                          </td>

                          <td
                            style={{
                              width: "120px",
                              padding: "5px",
                              textAlign: "right",
                              width: "10%"
                            }}
                          >
                            <Form.Control
                              style={{ padding: "5px", textAlign: "right" }}
                              type="text"
                              name={`total_price-${idx}`}
                              placeholder="Total Price"
                              value={item.total_price}
                              maxLength={10}
                              onChange={handleChange}
                            />
                          </td>

                          <td>
                            <BsFillXCircleFill
                              size={28}
                              style={{ color: "red", cursor: "pointer" }}
                              onClick={(e) => handleItemDelete(e)}
                              name={`item-delete-icon-${idx}`}
                              id={`item-delete-icon-${idx}`}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <Row>
                    <Col xs={3} md={3} sm={3}>
                      <div>
                        <span style={{ fontWeight: "bold" }}>
                          Total Items :
                        </span>
                        <span> {total_items}</span>
                      </div>
                    </Col>
                    <Col xs={3} md={3} sm={3}>
                      <div>
                        <span style={{ fontWeight: "bold" }}>
                          Total Product Cost :
                        </span>
                        <span>{sum_product_cost.toFixed(2)}</span>
                      </div>
                    </Col>
                    <Col xs={3} md={3} sm={3}>
                      <div>
                        <span style={{ fontWeight: "bold" }}>Total GST :</span>
                        <span>{sum_product_gst.toFixed(2)}</span>
                      </div>
                    </Col>
                    <Col xs={3} md={3} sm={3}>
                      <div>
                        <span style={{ fontWeight: "bold" }}>
                          {" "}
                          Total Cost :
                        </span>
                        <span>{sum_product_total.toFixed(2)}</span>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              <Row>
                <Col xs={12} md={12} sm={12}>
                  <div className="d-flex justify-content-end">
                    <Button
                      className="btn btn-secondary"
                      id="btn-clear-order-id"
                      name="btn-clear-order-name"
                      onClick={handleClear}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      type="submit"
                      className="btn btn-primary"
                      id="btn-save-order-id"
                      name="btn-save-order-name"
                      //onClick={(e) => handleSave(e)}
                     
                    >
                      
                  Save Order
                 
                    </Button>
                  </div>
                </Col>
              </Row>

              
              <Row><p></p><hr align="center" /></Row>
              <Row>
                <Col xs={6} sm={6} md={6} lg={6}>
                  <div className="d-flex justify-content-start">
                    <h4>
                      <Badge pill bg="secondary">
                        Purchase DCs 
                      </Badge>
                    </h4>
                    <p
                      className="error-msg"
                      style={{
                        position: "relative",
                        //top: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      {formErrors.alertMsg}
                    </p>
                  </div>
                </Col>

                <Col xs={6} md={6} sm={6} lg={6}>
                  <div className="d-flex justify-content-end">
                    
                    <Button
                      className="btn btn-warning"
                      style={{ alignItems: "self-end" }}
                      onClick={handleNewPurchaseDC}
                    >
                      New DC
                    </Button>
                  </div>
                </Col>
              </Row>
              
              <Row>
              <Table striped bordered hover>
                    <thead>
                      <tr className="table-primary">
                        <th className="table-row-heading">S.No</th>
                        <th className="table-row-heading">DC#</th>
                        <th className="table-row-heading">DC Date</th>
                        <th className="table-row-heading">Product</th>
                        <th className="table-row-heading">Vehicle</th>
                        <th className="table-row-heading">Notes</th>
                        <th className="table-row-heading">***</th>
                      </tr>
                    </thead>

                    <tbody>
                    {deliveryChellan.map((dcInd, idx) => (
                    <tr>
                      <td>{idx + 1 }</td>
                      <td>{dcInd.dc_no}</td>
                      <td>{dcInd.dc_date}</td>
                      <td>
                      <Table striped bordered hover size="sm">
                              <thead>
                                <tr className="table-primary">
                                  <th>#</th>
                                  <th>Product</th>
                                  <th>Quantity</th>
                                </tr>
                              </thead>
                              <tbody>
                                {dcInd.items.map((dcItem, idxItem) => (
                                  <tr key={`key-dc-item-row-${idxItem}`}>
                                    <td key={`key-dc-item-sno-${idxItem}`}>
                                      {idxItem + 1}
                                    </td>
                                    <td key={`key-dc-item-name-${idxItem}`}>
                                      {dcItem.product_name}
                                    </td>
                                    <td key={`key-dc-item-qty-${idxItem}`}>
                                      {dcItem.quantity}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                      </td>
                      <td>{dcInd.vehicle_info}</td>
                      <td>{dcInd.addl_info}</td>
                      <td>
                        <BsCardText
                          size={28}
                          style={{ color: "#0d6efd", cursor: "pointer" }}
                          onClick={(e) => handlePurchaseDCEdit(e)}
                          name={`order-view-icon-${0}`}
                          id={`order-view-icon-${0}`}/>
                      </td>
                    </tr>
                    ))}
                    </tbody>
                    </Table>
              </Row>
            </div>
          </Form>
          )
          :
          <div className="container-spinner">
          <div className="vertical-center-spinner">
           {/*<Button variant="primary" disabled>*/}
           <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
              <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{ width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
     
           {/*</Button>*/}
           </div>
           </div>
        }
        </Container>
      )}

    
    </>
  );
};

export default AddUpdatePurchaseOrder;
