import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  FormControl,
  Spinner,
  Modal,
  Image
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
import Sidebar from "./Sidebar";
import Sidebar2 from "./Sidebar2";
import Topmenu from "./Topmenu";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";

import { useNavigate, useLocation } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client, ConfigMinValue, configMaxValue, appGlobal} from "../config/Config";
import axios from "axios";

import Footer from "./Footer";

import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "bootstrap/dist/css/bootstrap.min.css";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';
import InputGroup from 'react-bootstrap/InputGroup';

import {
  BsCartX,
  BsArrowLeftCircle,
  BsFillPenFill,
  Bs3Circle,
  BsFillXCircleFill,
  BsPencilSquare,
  BsFillPrinterFill,
  BsCardText
} from "react-icons/bs";
import { MdDelete } from "react-icons/md";

import SidebarMain from "./SidebarMain";
import {getDistrictList, getStateList, getProductApiCall, getProductBuy, getProductsSell, formatDateIndian } from "./utils";

const AddUpdateCustOsStmt = (props) => {
  const initialized = useRef(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  
  var total_quantity_sum = useRef(0.00);
  var total_amount_sum = useRef(0.00);
  var receipt_total_sum = useRef(0.00);
  var total_product_amount_sum = useRef(0.00);
  var total_extra_charges_sum = useRef(0.00);
  var total_balance_os = useRef(0.00);

  const [passedValue, setPassedValue] = useState(state.passed_element);
  const [submitAction, setSubmitAction] = useState(false);

  //const [alertDialogTitle, setAlertDialogTitle] = useState('SUCCESSS');
  //const [alertDialogBody, setAlertDialogBody] = useState('Outstanding statment saved successfully!');

  const [submitDisabled, setSubmitDisabled] = useState(false);
  
  /*
  let initialItems = [
    {id:1, product: {value:3, label:"M15"}, price: "4900", qty: 5, "product_cost": "24500", "gst": "4410", "total_cost":"28910", gst:{cgst: 2205.00, sgst:2205.00, igst:0.00, totGst: 4410.00}}, 
    {id:1, product: {value:6, label:"M25"}, price: "4800", qty: 7, "product_cost": "215001", "gst": "38701", "total_cost":"253701", gst:{cgst: 2205.00, sgst:2205.00, igst:0.00, totGst: 4410}}
  ]
  */
  let initialItems = [];

  const optionsDate = [
    { value: 1, label: "12-12-2023" },
    { value: 2, label: "01-04-2023" },
    { value: 3, label: "05-09-2023" },
    { value: 4, label: "07-08-2023" },
    { value: 5, label: "23-05-2023" },
  ];

 
  

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  //const intialValues = {company:"", address: "", city:"", zip:"", gst_number: "", phone: "", district: "", state: "", product:{value:"m15", label:"M15"}};
  const intialValues = {
    os_date: formatDate(new Date()),
    customer_id: 0,
    customer_name: ""
	  
    
  };
  
  let receiptArr = [
    {
      stmt_no: "101",
      os_date: "01-01-2023",
      description: "",
      amount:5000,
    },
    {
      stmt_no: "101",
      os_date: "05-05-2023",
      description: "",
      amount:8000,
    },
    {
      stmt_no: "101",
      os_date: "07-07-2023",
      description: "",
      amount:9000,
    },
    {
      stmt_no: "101",
      os_date: "11-11-2023",
      description: "",
      amount:1000,
    },
  ]
  let summaryArr = [
    {
      date: "14-12-2023",
      
      grade: "M20",
      location: "Avinashi",
      total_volume: "34",
      unit_price: "4500",
      product_price: "153000",
      pumping_charges: "2000",
      diesel_charges: "3000",
      other_charges: "1500",
      total_amount: "159500"
    },
    {
      date: "14-12-2023",
     
      grade: "M40",
      location: "Erode",
      total_volume: "15",
      unit_price: "33",
      product_price: "18",
      pumping_charges: "2670",
      diesel_charges: "800",
      other_charges: "1200",
      total_amount: "8000"
    },
    {
      date: "14-12-2023",
     
      grade: "M35",
      location: "Trichy",
      total_volume: "67",
      unit_price: "18",
      product_price: "41",
      pumping_charges: "4500",
      diesel_charges: "2300",
      other_charges: "9000",
      total_amount: "20000"
    },
    {
      date: "14-12-2023",
      
      grade: "M7",
      location: "Dindigul",
      total_volume: "78",
      unit_price: "45",
      product_price: "23",
      pumping_charges: "3400",
      diesel_charges: "1200",
      other_charges: "5600",
      total_amount: "18000"
    },
    {
      date: "14-12-2023",
    
      grade: "M8",
      location: "Madurai",
      total_volume: "78",
      unit_price: "21",
      product_price: "43",
      pumping_charges: "6700",
      diesel_charges: "7800",
      other_charges: "3600",
      total_amount: "15000"
    },
    
  ];


  const [showAlert, setShowAlert] = useState();
  const [alertPurpose, setAlertPurpose] = useState('init');
  const [alertTitle, setAlertTitle] = useState('Success');
  const [alertBody, setAlertBody] = useState('Oustanding Saved Successfully');
 
  const [checked, setChecked] = useState(false);

  const [summaryList, setSummaryList] = useState([]);
  const [customerOs, setCustomerOs] = useState({});

 
  const [receiptList, setReceiptList] = useState([]);
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [productMaster, setProductMaster] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [stateIdSelect, setStateIdSelect] = useState({});
  const [toRender, setToRender] = useState(false);

  const [dateIdSelect, setDateIdSelect] = useState({});

  const [billDistrictIdSelect, setBillDistrictIdSelect] = useState(optionsDate);

  const [optDate, setOptDate] = useState(optionsDate);

  const [optionsSearch, setOptionsSearch] = useState([]);

  const [customerSearchText, setCustomerSearchText] = useState("");

  const [searchedCustomer, setSearchedCustomer] = useState({});

  const [orderAddress, setOrderAddress] = useState({});
  const [orderInfoDet, setOrderInfoDet] = useState({});

  const [addressFetched, setAddressFetched] = useState(false);
  const [orderDetFetched, setOrderDetFetched] = useState(false);
  const [orderItemFetched, setOrderItemFetched] = useState(false);
  const [orderDcFetched, setOrderDcFetched] = useState(false);
  const [productList, setProductList] = useState();
  const [editAction, setEditAction] = useState(false);
  const [submitPrintAction, setSubmitPrintAction] = useState(false);


  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("");
  const [msgType, setMsgType] = useState("success");

  const [submitDelAction, setSubmitDelAction] = useState(false);
  const [dialogResStatus, setDialogResStatus] = useState("");
  const [showMsgTitle, setShowMsgTitle] = useState("");
  const [showMsgBody, setShowMsgBody] = useState("");

  const [isSearched, setIsSearched] = useState(false);

  const [actionVal, setActionVal] = useState('save-only');

  var validateErr = false;

  var prodListLoad = [
    //{prod_id: 1, product_name: "M-10", hsncode:"HSN778328", unit_price:4300.00, quantity:10, total_item_price: 43000.00, gst: 7740.00, total_price:50740.00},
    //{prod_id: 1, product_name: "M-20", hsncode:"HSN778329", unit_price:4700.00, quantity:10, total_item_price: 47000.00, gst: 8460.00, total_price:55460.00},
    //{prod_id: 1, product_name: "M-30", hsncode:"HSN778378", unit_price:4900.00, quantity:10, total_item_price: 49000.00, gst: 8820.00, total_price:57820.00}
  ];
  var dcLoad = [
    //{dc_id: 12, dc_number:"DC102091Z1", dc_date:"2023-08-01", grade_id:1, grade_name:"M-20", loading_time:"2023-08-01", vehicle_number:"TN 39 DX 9927", total_volume:"18 cum", volume: "10 cum", total_amount:4200, driver_name:"Azhagu Raja"},
    //{dc_id: 11, dc_number:"DC10209879", dc_date:"2023-07-31", grade_id:2, grade_name:"M-15", loading_time:"2023-07-31", vehicle_number:"TN 39 DY 1254", total_volume:"28 cum", volume: "18 cum", total_amount:42000, driver_name:"Mahesh C"}
  ];

  const [deliveryChellan, setDeliveryChellan] = useState(dcLoad);
  const [prodList, setProdList] = useState(prodListLoad);

  const [gstBillStatus, setGstBillStatus] = useState(true);

  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isEditable, setIsEditable] = useState(true);

  const [showConfigDialog, setShowConfigDialog] = useState(false);
  const [uomIdSelect, setUomIdSelect] = useState({});
  const [productCompositionList, setProductCompositionList] = useState([]);

  const [compositionUpdate, setCompositionUpdate] = useState(false);

  const [selectedCompositionId, setSelectedCompositionId] = useState(-1);

  var action_val_ref = useRef('save-only');

  //const [searchText, setSearchText] = useState('');

  const handleValueChange = async (e, data) => {
    e.preventDefault();
    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    //console.log("Event code : ", e.keyCode);

    if (name === "customer") {
      console.log("Inside check ******************");
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleSearch = (e, dat) => {
    console.log("Handled search method called ...", e);

   
   // getDistrictList(stateIdInput);
  };

  const handleDateSelect = (e, data) => {
    console.log("handleDateSelect called ...", e);

    setDateIdSelect(e);
  };

  

  const handleChange = (e, data) => {
    //const min = 0;
    //const max = 10000;
    console.log("Handle Change clicked : ", e);
    console.log("Handle Change clicked target : ", e.target);
    //console.log("HTML tag : ", data);
    const { name, value } = e.target;
    console.log("Captured Quantity name : ", name);
    console.log("Captured Quantity value : ", value);
    setFormValues({...formValues, [name]: value});
    //console.log("FORM VALUES at handleChange func : ", formValues);

    /*
    var currentId = name.substring(name.lastIndexOf("-") + 1);
    var nameOfElement = name.substring(0, name.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    var itemSelected = items[currentId];

    console.log("Product: ", itemSelected.product);
    console.log("Product Name : ", itemSelected.product_name);
    console.log("Price : ", itemSelected.unit_price);
    console.log("Product cost : ", itemSelected.total_item_price);
    console.log("GST : ", parseFloat(itemSelected.cgst + itemSelected.sgst + itemSelected.igst));
    console.log("Total cost : ", itemSelected.total_price);
    console.log("Qty : ", itemSelected.quantity);

    console.log("Product Info : ", productMaster);
    const currentProductIndex = productMaster.findIndex(
      (productInd) => productInd.name === itemSelected.product_name);
    console.log("Currently selected row product id : ", currentProductIndex);
    if (currentProductIndex < 0) {
      itemSelected.error = "Please select product from list";

      const newitems = items.slice();
      newitems[currentId] = itemSelected;
      setItems(newitems);
      return 0;
    } else {
      itemSelected.error = "";
    }

    console.log("Currently selected row product value : ", productMaster[currentProductIndex]);

    //console.log("%%%%%%%%%%%%%% GST INFO ++++++++++++++ : ", productMaster[currentProductIndex]["gst"].cgst);
    */

    /*
    itemSelected.product = {
      value: productMaster[currentProductIndex].product_id,
      label: productMaster[currentProductIndex].product_name,
      stock_id: productMaster[currentProductIndex].stock_id,
      cgst: productMaster[currentProductIndex]["gst"].cgst,
      sgst: productMaster[currentProductIndex]["gst"].sgst,
      igst: productMaster[currentProductIndex]["gst"].igst,
    };
    */

    /*
    itemSelected.cgst_perc = productMaster[currentProductIndex].cgst;
    itemSelected.sgst_perc = productMaster[currentProductIndex].sgst;
    itemSelected.sgst_perc = productMaster[currentProductIndex].sgst;
    //itemSelected.unit_cost = productMaster[currentProductIndex].unit_cost;
    */

    /*
    if (nameOfElement === "quantity" || nameOfElement === "unit_price") {
      console.log("Product Quantity item edited ...");
      / *
      const valueTmp = Math.max(
        ConfigMinValue,
        Math.min(configMaxValue, Number(e.target.value))
      );
      * /
      //var valueTmp = e.target.value.replace(/[^0-9.]/g, "");
      //var valueTmp = e.target.value.replace(/^-?\d+\.?\d*$/g, "");
      //var numericRegExp = new RegExp("^[0-9]+$");
      //const numericRegExp = /^[\+\-]?\d*\.?\d+(?:[Ee][\+\-]?\d+)?$/;
      //const numericRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      //const numericRegExp = new RegExp(/^-?\d*[\.]?\d+$/);

      // const numericRegExp = /^\d*\.?\d*$/;//original
      ////^([0-9]+-)*([0-9]+)$///.replace(/(\..*)\./g, "$1");
      //console.log("Matched value >>>>>>>>>>>>>>> : ", valueTmp);
      //if (numericRegExp.match(e.target.value)) {
      if (e.target.value.match(appGlobal.numericRegExp)) {
        itemSelected[nameOfElement] = e.target.value;
      }
    } else {
      console.log("Other than handled option clicked so skipping it ...");
    }
    //itemSelected.product_cost = productMaster[currentProductIndex].unit_cost * itemSelected.qty;
    //itemSelected.unit_cost = productMaster[currentProductIndex].unit_cost;
    itemSelected.total_item_price = itemSelected.unit_price * itemSelected.quantity;

    var cgstCalculate = 0.0;
    if (gstBillStatus) {
      cgstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].cgst) / 100;
    }
    itemSelected.cgst = cgstCalculate;

    var sgstCalculate = 0.0;

    if (gstBillStatus) {
      sgstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].sgst) / 100;
    }
    itemSelected.sgst = sgstCalculate;

    var igstCalculate = 0.0;
    if (gstBillStatus) {
      igstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].igst) / 100;
    }
    itemSelected.igst = igstCalculate;
    //let totalGst = cgstTmp + sgstTmp + igstTmp;

    //var gst = { cgst: cgstTmp, sgst: sgstTmp, igst: igstTmp, totGst: totalGst }
    //itemSelected.gst = gst;
    //itemSelected.product_cost = productMaster[currentProductIndex].unit_cost * itemSelected.qty;
    console.log("CGST STATE 501 : ", itemSelected.cgst);
    console.log("SGST STATE 501 : ", itemSelected.sgst);
    console.log("IGST STATE 501 : ", itemSelected.igst);

    itemSelected.total_price = parseFloat(itemSelected.total_item_price) + parseFloat(itemSelected.cgst + itemSelected.sgst + itemSelected.igst);

    console.log("Modified unit cost : ", itemSelected.unit_price);
    console.log("Modified Product cost : ", itemSelected.total_item_price);
    console.log("Modified Total cost : ", itemSelected.total_price);

    const newitems = items.slice();
    newitems[currentId] = itemSelected;
    setItems(newitems);
    */
  };
 
 
  const getOrderAddress = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + `/getOrderAddress?order_id=${passedValue.order_id}`, { headers },{ withCredentials: false });
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server getOrderAddress : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getOrderAddress : ",res.data.detail.res_data);
        setOrderAddress(res.data.detail.res_data.order_address);

        formValues["shipContactName"] = res.data.detail.res_data.order_address.delivery_address.contact_name;
        formValues["shipContactNumber"] = res.data.detail.res_data.order_address.delivery_address.contact_number;
        formValues["shipAddress1"] = res.data.detail.res_data.order_address.delivery_address.address_line1;
        formValues["shipAddress2"] = res.data.detail.res_data.order_address.delivery_address.address_line2;
        formValues["shipPincode"] = res.data.detail.res_data.order_address.delivery_address.pincode;

        console.log("Delivery contact : ", formValues["shipContactName"]);

        formValues["billContactName"] = res.data.detail.res_data.order_address.billing_address.contact_name;
        formValues["billContactNumber"] = res.data.detail.res_data.order_address.billing_address.contact_number;
        formValues["billAddress1"] = res.data.detail.res_data.order_address.billing_address.address_line1;
        formValues["billAddress2"] = res.data.detail.res_data.order_address.billing_address.address_line2;
        formValues["billPincode"] = res.data.detail.res_data.order_address.billing_address.pincode;

        console.log("Billing : ", formValues["billContactName"]);

        setAddressFetched(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
  };
  
 
  
const getDeliverySummaryListForEdit = async() => {
  var retState = false;
    var newCustOsStmtArr = [];
    console.log("getCustOsStmtList method called ...");

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + `/getDeliverySummaryListForEdit?os_stmt_id=${state.passed_element.id}`, { headers }, { withCredentials: false });
      
      console.log("Response from server getCustOsStmtList : ", res.data.detail.data);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getCustOsStmtList : ", res.data.detail.data);        
        newCustOsStmtArr = res.data.detail.data;
        retState = true;
      }
     
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          
        }
      }
    }
    return [retState, newCustOsStmtArr];
}

const getReceiptsForOsStmtEdit = async() => {
  var retState = false;
    var newReceiptsArr = [];
    console.log("getReceiptsForOsStmtEdit method called ...");

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = { ...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + `/getReceiptsForOsStmtEdit?os_stmt_id=${state.passed_element.id}`, { headers }, { withCredentials: false });
      
      console.log("Response from server getReceiptsForOsStmtEdit : ", res.data.detail.data);
      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getReceiptsForOsStmtEdit : ", res.data.detail.data);        
        newReceiptsArr = res.data.detail.data;
        retState = true;
      }
     
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          
        }
      }
    }
    return [retState, newReceiptsArr];
}

  useEffect(() => {
    if (!initialized.current) {
      console.log("*************** useEffect called ##########################");
      console.log(formErrors);

      if ( state.action === 'view' || state.action === 'edit') {
        console.log("+++++++++++++++++++++inside useEffect edit set+++++++++++++++++++++", state.passed_element);
        setFormValues({ ...formValues,
          customer_name: state.passed_element.customer_name});
        }
      
      /*
      if (state === null) {
        navigate("/listOrders");
      } else {
        console.log("PASSED VALUE THROUGH STATE variable : ", state);
        setDateIdSelect(optionsDate[0]);
        
        initialized.current = true;
      }
      */
      console.log("PASSED VALUE THROUGH STATE variable : ", state);
      setDateIdSelect(optionsDate[0]);
      initialized.current = true;
      ( async() =>{
        
          if ( state.action === 'view' || state.action === 'edit') {
            var [retStateSummary, newSummaryArr] = await getDeliverySummaryListForEdit();
            if ( retStateSummary ) {
            setSummaryList(newSummaryArr);

            total_quantity_sum.current = 0.00;
            total_amount_sum.current = 0.00;
            total_product_amount_sum.current = 0.00;
            total_extra_charges_sum.current = 0.00;
            newSummaryArr.map((summary) => {
            console.log("TOTAL QUANTITY INDIVIDUAL : ", summary.total_quantity);
            total_quantity_sum.current = total_quantity_sum.current + parseFloat(summary.total_quantity);
            total_amount_sum.current = total_amount_sum.current + parseFloat(summary.total_amount);
            total_product_amount_sum.current = total_product_amount_sum.current + (parseFloat(summary.total_quantity) * parseFloat(summary.unit_price));
            total_extra_charges_sum.current = total_extra_charges_sum.current + parseFloat(summary.pumping_charges) + parseFloat(summary.diesel_charges) + parseFloat(summary.others_charges1);
          })
          console.log("TOTAL QUANTITY SUM : ", total_quantity_sum.current);
          console.log("TOTAL PRODCUCT AMOUNT SUM : ", total_product_amount_sum.current);
          console.log("TOTAL EXTRA CHARGES SUM : ", total_extra_charges_sum.current);
          console.log("TOTAL AMOUNT SUM : ", total_amount_sum.current);          
          }

          var [retStateReceipts, newReceiptsArr] = await getReceiptsForOsStmtEdit();
          if (retStateReceipts) {
            setReceiptList(newReceiptsArr.receipts);

            receipt_total_sum.current = 0.00;
            newReceiptsArr.receipts.map((receipt) => {
            console.log("TOTAL QUANTITY INDIVIDUAL : ", receipt.amount);
            receipt_total_sum.current = receipt_total_sum.current + parseFloat(receipt.amount); 
            })
            console.log("TOTAL RECEIPTS SUM : ", receipt_total_sum.current);
          }

          //total_balance_os.current = ( res.data.detail.data.customer_os.total_balance + total_amount_sum.current ) - receipt_total_sum.current;
          //console.log("TOTAL BALANCE Outstanding : ", total_balance_os.current);

          setCustomerOs({previous_balance: state.passed_element.previous_balance});
          //receipt_total_sum.current = state.passed_element.total_received;
          //total_amount_sum.current = state.passed_element.current_balance;
          total_balance_os.current = state.passed_element.total_balance;

          setIsSearched(true);
        }
        setToRender(true);
      })();


    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit called ...");

    console.log("FORM VALUES at handleSubmit: ", formValues);
    console.log("LINE ITEMS : ", items);
    console.log("Form submit called ...");

    console.log("FORM VALUES at handleChange func : ", formValues);

    console.log("CUSTOMER : ", formValues["customer_name"]);
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log("After validateErrors and setsubmit call ...");

    if (validateErr) {
      console.log("Validate error occured ...");
      return 0;
    }
    

    
    if (items.length < 1) {
      //setShowMsg("Please add atleast one product in order");
      //setMsgType("warning");
      //setShow(true);
      console.log("Empty product list ...");
      setFormErrors({ ...formErrors, ["alertMsg"]: "Add atleast one item" });
      return 0;
    }

   
    

   
    /*
    let reqValidateJwt = {
     
      customer_name: customer_name_tmp,
      customer_id: customer_id_tmp,
      customer_disp_name: dispNameTmp,
      gst_number: formValues["gst_number"],
      phone: formValues["phone"] || "",
      primary_number: formValues["primary_number"],
      secondary_number: formValues["secondary_number"],
      contact_person: formValues["contact_person"] || "",
      contact_number: formValues["contact_number"] || "",
      email: formValues["email"],
      address1: formValues["address1"],
      address2: formValues["address2"],
      distId: districtIdSelect.value,
      stateId: stateIdSelect.value,
      pincode: formValues["pincode"] || "",
      website: formValues["website"],

      total_items: total_items,
      sum_product_cost: sum_product_cost,
      sum_product_gst: sum_product_gst,

      sum_product_cgst: sum_product_cgst,
      sum_product_sgst: sum_product_sgst,
      sum_product_igst: sum_product_igst,

      amount_discount: amount_discount,

      sum_product_total: sum_product_total,

      orderItems: JSON.stringify(items),

      order_id: orderIdTmp,
      order_no: orderNoTmp,

      ship_addr_id: shipAddrIdTmp,
      ship_contact_name: formValues["shipContactName"] || "",
      ship_contact_number: formValues["shipContactNumber"] || "",
      ship_address1: formValues["shipAddress1"],
      ship_address2: formValues["shipAddress2"],
      ship_pincode: formValues["shipPincode"] || "",

      bill_addr_id: billingAddrIdTmp,
      bill_contact_name: formValues["billContactName"] || "",
      bill_contact_number: formValues["billContactNumber"] || "",
      bill_address1: formValues["billAddress1"],
      bill_address2: formValues["billAddress2"],
      bill_pincode: formValues["billPincode"] || "",

      bill_gst_include: gstBillStatus,
    };

   
    console.log("payload:", reqValidateJwt);

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers ,Authorization: `Bearer ${aIVal}`}
      setSubmitAction(true);
      var res = await axios.post(client.domain + "/insertOrder", reqValidateJwt, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        setAlertPurpose('success');
        setSubmitAction(false);
        console.log("Response message from server : ", res.data.detail.res_data);
        setShowAlert(true);
      }
      else {
        setSubmitAction(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      setAlertPurpose('failed');
      setShowAlert(true);
      setAlertDialogBody("FAILED!");
      setAlertDialogTitle("Failed to save the order please try again");
     
      setSubmitAction(false);
    }
    */
  };
  const validate = (values) => {
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    //if (!customerSearchText && !searchedCustomer) {
    if (!values.customer_name){
      console.log("Customer name is missing ...", formValues["customer_name"]);
      errors.customer_name = "Customer name is required!";
      validateErr = true;
    }

   
    return errors;
  };

  const saveHandler = async (event) => {
    // Prevent page reload
    event.preventDefault();

    console.log("Form submit clicked ...");

    console.log("DATA : ", document.forms[0]);

    /*
    var { username, password } = document.forms[0];
    console.log("username : ", username.value);
    console.log("pasword : ", password.value);
    */
  };

  const backHandler = async (event) => {
    event.preventDefault();
    console.log("Back button clicked ...");
  };

  const newItemHandle = async (event) => {
    event.preventDefault();
    console.log("New item addition button clicked ...");

    const newitems = items.slice();
    //let itemNew = { id: 1, product: { value: 0, label: "" }, unit_cost: "", qty: 1, "product_cost": "", "total_cost": "", gst: { cgst: 0.00, sgst: 0.00, igst: 0.00, totGst: 0.00 } };
    let itemNew = {
      id: 0,
      product_id: 0,
      product_name: "",
      unit_price: "",
      quantity: "",
      total_item_price: 0.0,
      total_price: 0.0,
      cgst: 0.0,
      sgst: 0.0,
      igst: 0.0,
      editAction: false,
      enableState: false,
      composition: [],
      is_product_cc: false,
    };
    newitems.push(itemNew);
    setItems(newitems);
  };

  const handleProductSelect = (e, data) => {
    console.log(">>>>>>>>>>>>>>>>>>>>>>> HandleProductSelect called *******************");
    console.log("SELECTED Dropdown value : ", e);
    console.log("SELECTED element id : ", data);
    var currentId = data.name.substring(data.name.lastIndexOf("-") + 1);
    console.log("Current id : ", currentId);

    var itemSelected = items[currentId];
    //console.log("Product: ", itemSelected.product);
    console.log("<<<<<<<<<<<<<<<<<<<<< ITEM ID >>>>>>>>>>>>>>>>>>: ", itemSelected.id);
    console.log("Price : ", itemSelected.unit_price);
    console.log("Product cost : ", itemSelected.total_item_price);
    console.log("GST : ", parseFloat(itemSelected.cgst + itemSelected.sgst + itemSelected.igst));
    console.log("Total cost : ", itemSelected.total_price);
    console.log("Qty : ", itemSelected.quantity);

    console.log("Product Info : ", productMaster);
    const currentProductIndex = productMaster.findIndex((productInd) => productInd.name === e.label);
    console.log("Currently selected row product id : ", currentProductIndex);
    console.log("Currently selected row product value : ", productMaster[currentProductIndex]);

    itemSelected.product_id = e.value;
    itemSelected.product_name = e.label;
    itemSelected.enableState = true;
    //itemSelected.isEditable = true;
    itemSelected.editAction = false;
    //itemSelected.product.label = e.label;
    /*
    var productNew = {
      label: e.label,
      value: e.value,
      product_id: e.product_id,
      product_name: e.product_name,
      stock_id: e.stock_id,
      unit_cost: e.unit_cost,
      cgst: e.gst.cgst,
      sgst: e.gst.sgst,
      igst: e.gst.igst,
    };
    itemSelected.product = productNew;
    */
    //itemSelected.price = productMaster[currentProductIndex].unit_cost;
    itemSelected.cgst_perc = productMaster[currentProductIndex].cgst;
    itemSelected.sgst_perc = productMaster[currentProductIndex].sgst;
    itemSelected.igst_perc = productMaster[currentProductIndex].igst;

    itemSelected.unit_price = productMaster[currentProductIndex].unit_price;
    itemSelected.total_item_price =
      productMaster[currentProductIndex].unit_price * itemSelected.quantity;

    var cgstCalculate = 0.0;
    if (gstBillStatus) {
      cgstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].cgst) / 100;
    }
    itemSelected.cgst = cgstCalculate;

    var sgstCalculate = 0.0;
    if (gstBillStatus) {
      sgstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].sgst) / 100;
    }
    itemSelected.sgst = sgstCalculate;

    var igstCalculate = 0.0;
    if (gstBillStatus) {
      igstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].igst) / 100;
    }
    itemSelected.igst = igstCalculate;
    //let totalGst = cgstTmp + sgstTmp + igstTmp;

    //var gst = { cgst: cgstTmp, sgst: sgstTmp, igst: igstTmp, totGst: totalGst }
    //itemSelected.gst = gst
    //itemSelected.product_cost = productMaster[currentProductIndex].unit_cost * itemSelected.qty;

    itemSelected.total_price =
      parseFloat(itemSelected.total_item_price) +
      parseFloat(itemSelected.cgst + itemSelected.sgst + itemSelected.igst);

    console.log("Modified unit cost : ", itemSelected.unit_price);
    console.log("Modified Product cost : ", itemSelected.total_item_price);
    console.log("Modified Total cost : ", itemSelected.total_price);

    const newitems = items.slice();
    newitems[currentId] = itemSelected;
    setItems(newitems);
    //var fieldValue = "product";
    //setFormValues({ ...formValues, [fieldValue]: e })
    setIsEditable(false);
  };

  /*
  const searchActionFunc = (json) => {
    setOptionsSearch(json.detail.res_data.district_list);
    setIsLoading(false)
    console.log("SEARCHED VALUE inside searchActionFunc : ", json.detail.res_data.district_list);
  }
  */

  const customerSearchSelectFunc = async (selected) => {
    console.log("**************** User selected value from search result +++++++++++++ : ", selected);
    if (selected.length > 0) {
      setSearchedCustomer(selected[0]);
      
      const response = await fetch(`${client.domain}/getCustomerInfo?customer_id=${selected[0].value}`);
      const customer_info = await response.json();
      console.log("CUSTOMER INFO : ", customer_info.detail.res_data.customer_info);
      //setIsLoading(false);

      //setFormValues({...formValues, [name]: value});
      //let entries = Object.entries(company_info.detail.res_data.company_info)
      //entries.map( ([key, val] = entry) => {
      //entries.map( ([key, val] = entry) => {
      /*
      for (const [key, value] of Object.entries(customer_info.detail.res_data.customer_info)) {
        console.log("KEY : %s, VAL: %s", key, value);
      }
      */
      setFormValues({ ...formValues, customer_id: customer_info.detail.res_data.customer_info.customer_id, customer_name: customer_info.detail.res_data.customer_info.customer_name});

      //setFormValues(customer_info.detail.res_data.customer_info);
      //setIsLoading(true);

      
      //setFormValues({...formValues, ["company_disp_name"]: "ABC"});
      //setFormValues({...formValues, ["pincode"]: "641654"});

      //console.log("FORMVALUES 2 : ", formValues);
    }
  };

  const getCustomerDetails = async () => {
    var retState = false;
    //console.log("**************** User selected value from search result +++++++++++++ : ", selected);
    //if (selected.length > 0) {
    //setSearchedCompany(selected[0])
    var customerId = passedValue.customer_id;

    const response = await fetch(`${client.domain}/getCustomerInfo?customer_id=${customerId}`);
    const customer_info = await response.json();
    retState = true;
    console.log("CuSTOMER INFO : ", customer_info.detail.res_data.customer_info);

    //setFormValues({...formValues, [name]: value});
    //let entries = Object.entries(company_info.detail.res_data.company_info)
    //entries.map( ([key, val] = entry) => {
    //entries.map( ([key, val] = entry) => {
    for (const [key, value] of Object.entries(customer_info.detail.res_data.customer_info)) {
      //console.log("KEY : ", key);
      //console.log("VAL : ", val);
      console.log("KEY : %s, VAL: %s", key, value);

      //setFormValues({...formValues, [key]: value});
    }

    //setFormValues(customer_info.detail.res_data.customer_info);

    formValues["customer_id"] = customer_info.detail.res_data.customer_info.customer_id;
    formValues["customer_name"] = customer_info.detail.res_data.customer_info.customer_name;
    formValues["customer_disp_name"] = customer_info.detail.res_data.customer_info.customer_disp_name;
    formValues["contact_person"] = customer_info.detail.res_data.customer_info.contact_person;
    formValues["contact_number"] = customer_info.detail.res_data.customer_info.contact_number;
    formValues["gst_number"] = customer_info.detail.res_data.customer_info.gst_number;
    formValues["phone"] = customer_info.detail.res_data.customer_info.phone;
    formValues["email"] = customer_info.detail.res_data.customer_info.email;
    formValues["website"] = customer_info.detail.res_data.customer_info.website;
    formValues["address1"] = customer_info.detail.res_data.customer_info.address1;
    formValues["address2"] = customer_info.detail.res_data.customer_info.address2;
    /*
      formValues["dist_id"] = customer_info.detail.res_data.customer_info.dist_id;
      formValues["district_name"] = customer_info.detail.res_data.customer_info.district_name;
      formValues["state_id"] = customer_info.detail.res_data.customer_info.state_id;
      formValues["state_name"] = customer_info.detail.res_data.customer_info.state_name;
      */
    formValues["pincode"] = customer_info.detail.res_data.customer_info.pincode;
    formValues["primary_number"] = customer_info.detail.res_data.customer_info.primary_number;
    formValues["secondary_number"] = customer_info.detail.res_data.customer_info.secondary_number;

    if (state.action === "edit" || state.action === "view") {
      console.log(
        "######################## Condition met for view or edit %%%%%%%%%%%%%%%%%%%%"
      );
      setCustomerSearchText(customer_info.detail.res_data.customer_info.customer_name);
      //setIsLoading(true);
    }

   
    console.log("FORMVALUES 2 : ", formValues);
    //}
    return [retState, customer_info.detail.res_data.customer_info];
  };
 /*
  console.log("FORM VALUES Before rendering :", formValues);

  var total_items = 0;
  var sum_product_cost = 0.0;
  var sum_product_gst = 0.0;
  var sum_product_cgst = 0.0;
  var sum_product_sgst = 0.0;
  var sum_product_igst = 0.0;

  var sum_product_total = 0.0;
  var amount_discount = 0.0;

  console.log("<<<<<<<<<<<<<<<<+++++++++++++++ Length of items : ", items.length);
  items.map((item) => {
    console.log("IND ITEM : ", item);
    console.log("Product Cost : ", item.total_item_price);
    var totGst = item.cgst + item.sgst + item.igst;
    console.log("GST  : ", totGst);

    //console.log("GST  : ", item.gst.cgst);
    //console.log("GST  : ", item.gst.sgst);
    //console.log("GST  : ", item.gst.igst);

    console.log("Total Price : ", item.total_price);
    total_items = total_items + 1;
    sum_product_cost = sum_product_cost + item.total_item_price;

    //sum_product_gst = sum_product_gst + item.gst.totGst;
    sum_product_cgst = sum_product_cgst + item.cgst;
    sum_product_sgst = sum_product_sgst + item.sgst;
    sum_product_igst = sum_product_igst + item.igst;

    //sum_product_total = sum_product_total + item.total_cost;
    //sum_product_total =  sum_product_total +
  });

  sum_product_gst = sum_product_cgst + sum_product_sgst + sum_product_igst;
  sum_product_total = sum_product_cost + sum_product_gst;

  sum_product_total = sum_product_total - amount_discount;

  console.log("Tot number of items : ", total_items);
  console.log("Sum of Tot Product Cost : ", sum_product_cost);

  console.log("Sum of Tot CGST : ", sum_product_cgst);
  console.log("Sum of Tot SGST : ", sum_product_sgst);
  console.log("Sum of Tot iGST : ", sum_product_igst);

  console.log("Sum of Tot GST : ", sum_product_gst);

  console.log("Sum of total cost : ", sum_product_total);

  console.log("Currently selected district : ", districtIdSelect);
  console.log("opt district : ", optDist);
  //console.log("*********** opt district value ############# : ", optDist.value);

  console.log("Currently selected state : ", stateIdSelect);
  console.log("+++++++++++++++ Customer search text ^^^^^^^^^^^^^^^^^^^^  :", customerSearchText);
  console.log("Searched customer <<<<<<<<<<<<<<<<<<< : ", searchedCustomer);

  const [addrCopyCheck, setAddrCopyCheck] = useState(false);

  const [refresh, setRefresh] = useState(false);
  */

  
      
      
  const [addrCopyBillCheck, setAddrCopyBillCheck] = useState(false);

 

  const handleItemDelete = (e) => {
    console.log("handle item delete called ...", e);
    console.log("handle item delete e.currentTarget : ", e.currentTarget.id);
    console.log("handle item delete e.target : ", e.target.id);

    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(
        0,
        clickedTagId.lastIndexOf("-")
      );
      console.log("current selected DELETE element name : ", nameOfElement);
      console.log("Current SELECTED DELETE id : ", curSelId);
      console.log("CUR SELECTED DELETE VALUE : ", items[curSelId]);

      let sliced = items.splice(curSelId, 1);
      console.log("Removed item from order items: ", sliced);
      var newItem = items.slice();
      setItems(newItem);

      //setRefresh(true);
      //console.log("Removed item from order items: ", sliced);
      //setRefresh(false);
    }
  };

  const handleBack = async (e) => {
    console.log("handle back called : ", e);
    navigate(-1);
  };
  /*

  const handleClose = async (e) => {
    setShow(false);
    //navigate(-1);
  };
  */

  const handleGSTBillStatus = async (e) => {
    console.log("HandleGSTBillStatus called : ", e);
    console.log("target checked : ", e.target.checked);

    var itemModNew = [];
    items.map((item) => {
      console.log("IND ITEM : ", item);

      var cgstCalculate = 0.0;
      var sgstCalculate = 0.0;
      var igstCalculate = 0.0;

      if (e.target.checked) {
        //if (item["product"] !== undefined) {
          console.log("CGST FROM EXISTING : ", item.cgst_perc);
          console.log(
            "TOTAL ITEM PRICE FROM EXISTING : ",
            item.total_item_price
          );
          cgstCalculate =
            (item.total_item_price * parseFloat(item.cgst_perc)) / 100;
          sgstCalculate =
            (item.total_item_price * parseFloat(item.sgst_perc)) / 100;
          igstCalculate =
            (item.total_item_price * parseFloat(item.igst_perc)) / 100;
        //}
      }
      item.cgst = cgstCalculate;
      item.sgst = sgstCalculate;
      item.igst = igstCalculate;

      console.log("CALCULATED CGST : ", cgstCalculate);
      console.log("CALCULATED SGST : ", sgstCalculate);
      console.log("CALCULATED IGST : ", igstCalculate);
      item.total_price =
        item.total_item_price + cgstCalculate + sgstCalculate + igstCalculate;
      console.log("CALCULATED TOTAL : ", item.total_price);

      itemModNew.push(item);
    });

    setGstBillStatus(e.target.checked);
    setItems(itemModNew);
  };

  const deleteCustOsStmt = async() => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
      
      let reqAddUpdateDC = {
        customer_id: state.passed_element.customer_id,
        cust_os_stmt_id: state.passed_element.id
      };
      setSubmitDelAction(true);
      var res = await axios.post(client.domain + "/deleteCustOsStmt", reqAddUpdateDC, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.res_data);
        setAlertPurpose('deleted');
        setAlertTitle('Success');
        setAlertBody("Outstanding Statement Deleted Successfully!");
        setDialogResStatus('success');
        setSubmitDelAction(false);
        setShowAlert(true);
        }
      else {
        setAlertPurpose('delete_failed');
        setAlertTitle('Failed');
        setAlertBody("Outstanding Statement Delete Failed!");
        setDialogResStatus('failure');
        setSubmitDelAction(false);
        setShowAlert(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      setAlertPurpose('delete_failed');
      setAlertTitle("Warning");
      setAlertBody("Unable to process your request at this moment.Please try after sometime!");
      setDialogResStatus('failure');

     
      setSubmitDelAction(false);
      setShowAlert(true);
    }
  }

  const handleDelete = async (e) => {
    console.log("Handle Delete clicked : ", e);
    console.log("Handle Delete clicked current target ID: ", e.currentTarget.id);

    setAlertPurpose('delete');
    //setDialogResStatus('delete');
    setAlertTitle("Confirmation");
    setAlertBody("Are you sure want to delete this Customer Statement!");
    setShowAlert(true);

    //setIsReadOnly(false);

    //await deleteCustOsStmt();
  };

  const getProductForLoad = async (productIdInput, prodIdIdx) => {
    var retState = false;
    var prodRes = {};
    console.log("get product for load called ... ");

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`, SerialKey: 'HNGD8743JESWE'};
      var res = await axios.get(client.domain + "/ims/getProduct?id=" + productIdInput, { headers }, { withCredentials: false });
      console.log("Response from server getProductForLoad : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getProductForLoad : ", res.data.detail.data);
        console.log("Response message from server getproductForLoad (Products) : ", res.data.detail.data);

        //setFormValues(res.data.detail.data);
        /*
        if ( res.data.detail.data.is_composite === true && res.data.detail.data.tt_key === 'SELL' && 'composite' in res.data.detail.data && res.data.detail.data.composite.length > 0 ) {
          setProductCompositionList(res.data.detail.data.composite);
        }
        */
        //setProductCompositionList(res.data.detail.data.composite);
        console.log("SELECTED PROD INDEX ID : ", prodIdIdx);
        console.log("SELECTED PROD DATA : ", items[prodIdIdx]);

        var selectedProdTmp2 = items[prodIdIdx];

        selectedProdTmp2.composition = res.data.detail.data.composite;

        var newItemsTmp2 = items.slice();
        newItemsTmp2[prodIdIdx] = selectedProdTmp2;
        setItems(newItemsTmp2);
        //setProductCompositionList(res.data.detail.data.composite);
        /*
        console.log("UOM array : ", uomOpt);
        var findUom = uomRef.current.find(element => element.value === res.data.detail.data.uom_key);
        console.log("**************** findUOM value ++++++++++++ : ", findUom);
        if (findUom !== undefined) {
          setUomIdSelect({ label: findUom.label, value: findUom.value })
        }
        */
        //setProdDetLoaded(true);
        //setToRender(true);
        //setEditAction(false);
        setShowConfigDialog(true);

        retState = true;
        prodRes = res.data.detail.data;
        console.log("PRODUCT LOAD STATUS SET TO TRUE")
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");

      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );

        }
      }
    }
    return [retState, prodRes];
  }

  const handleCompositeEdit = async (e, rowData) => {
    setEditAction(true);
    console.log("Handle Edit clicked FULL : ", e);
    console.log("Handle Edit clicked FULL currentTarget : ", e.currentTarget);
    console.log("Handle Edit clicked FULL target : ", e.target);
    var { id, name } = e.currentTarget;
    //var {id1, name1 } = e.currentTarget;
    console.log("id : ", id , ", name : " , name);
    //console.log("id : ", id, ", name : " , name);
    console.log("Handle Edit clicked FULL : ", rowData);
    
    //console.log("Handle Edit clicked currentTarget: ", rowData.currentTarget);
    //console.log("Handle Edit clicked target : ", rowData.target);
    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var productIdInput = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(0, clickedTagId.lastIndexOf("-"));
      console.log("current selected Edit element name : ", nameOfElement);
      console.log("Current SELECTED Edit id : ", productIdInput);
      console.log("CUR SELECTED Edit VALUE : ", items[productIdInput]);


      setSelectedCompositionId(productIdInput);
      /*
      let sliced = items.splice(productIdInput, 1);
      console.log("Edit item from order items: ", sliced);
      var newItem = items.slice();
      setItems(newItem);
      */
    }
    

    // get composition from api call
    //https://api.erp.avinasoft.in/ims/getProduct?id=6

    
    var selectedItem = items[productIdInput];
    var [retStateProdBuy, buyProdArr] =  await getProductBuy();
    if ( retStateProdBuy ) {
      setProductList(buyProdArr);
    }
    var productIdPass = items[productIdInput].product_id;
    selectedItem.enableState = false;
    selectedItem.editAction = true;
    console.log("selectedItem :", selectedItem);
    var compExist = 'composition' in selectedItem;
    if ( !compExist || selectedItem.composition.length < 1 ) { 
      await getProductForLoad(productIdPass, productIdInput);
    }
    /*
    var [retStateProdBuy, buyProdArr] =  await getProductBuy();
    if ( retStateProdBuy ) {
      setProductList(buyProdArr);
    }
    var productIdPass = items[productIdInput].product_id;
    selectedItem.enableState = false;
    selectedItem.editAction = true;
    if ( selectedItem.composition.length < 1 ) { 
      await getProductForLoad(productIdPass, productIdInput);
    }
    */
    else {
      setShowConfigDialog(true);
    }

    setEditAction(false);
    selectedItem.enableState = true;
    //selectedItem.isEditable=true;
    selectedItem.editAction = false;

    if (!selectedItem.hasOwnProperty("composition") ) {
      console.log("********************** Product composition doesn't exist +++++++++++++++++ ");
      //selectedItem.composition = [];
    }
    else {
      console.log("******************* Product composition already exist in items ++++++++++++++");
    }

    const newitems = items.slice();
    newitems[productIdInput] = selectedItem;
    setItems(newitems);
  };


  const handleClear = async (e) => {
    e.preventDefault();
    console.log("handle clear called : ", e);
    console.log("Handle clear clicked current target ID: ", e.currentTarget.id);
    navigate(0);
  };
  const handleSavePrint = async (e) => {
    e.preventDefault();
    console.log("handle save print called : ", e);
    console.log("Handle save print clicked current target ID: ", e.currentTarget.id);
    
  };

  const handleSave = async (event, actionVal) => {
    //event.preventDefault();
    //setActionVal(actionVal);
    action_val_ref.current = actionVal;
    console.log("handle save called : ", event);
    console.log("handle save called 2 : ", actionVal);
    console.log("Handle save clicked current target ID: ", event.currentTarget.id);
    console.log("CUSTOMER OUSTANDING STATEMENT : ", customerOs);
    console.log("CUSTOMER OUSTANDING STATEMENT PREV BALANCE : ", customerOs.previous_balance);

    if (alertPurpose === 'init') {
      setAlertPurpose("confirmation");
      setAlertTitle("Confirmation");
      setAlertBody("Are you sure want to Generate Outstanding Statement");
      setShowAlert(true);
      return; 
    }
    else if ( alertPurpose === 'confirmation' ) {
    if ( customerOs.previous_balance < 0.01 ) {
      setAlertTitle("Confirmation");
      setAlertBody("Opening balance is 0.00 are you sure want to proceed? otherwise please update opening balance in Customer page");
      //setAlertPurpose("confirmed");
      setShowAlert(true);
    }
    else {
      callApiToGenerateOS();
    }
  }
  };

  const handleKeyDown = async (e) => {
    console.log("handle key down called : ", e);
    return e.keyCode !== 190;
  };

  const onChangeDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.target.value);

    setFormValues({ ...formValues, ["os_date"]: e.target.value });
  };

  const newItemHandleComposition = async (event) => {
    event.preventDefault();
    console.log("New item addition button clicked : ", event);
    console.log("New item addition button clicked target :", event.target);
    console.log("New item addition button clicked currentTarget : ", event.currentTarget);

    /*
    var { name, id } = event.currentTarget;
    var currentId = name.substring(name.lastIndexOf("-") + 1);
    var nameOfElement = name.substring(0, name.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    var itemSelected = items[currentId];

    //console.log("Product: ", itemSelected.product);
    console.log("Product Name : ", itemSelected.name);
    console.log("Price : ", itemSelected.unit_price);
    console.log("Product cost : ", itemSelected.total_item_price);
    console.log("GST : ", parseFloat(itemSelected.cgst + itemSelected.sgst + itemSelected.igst));
    console.log("Total cost : ", itemSelected.total_price);
    console.log("Qty : ", itemSelected.quantity);

    console.log("Product Info : ", productMaster);
    const currentProductIndex = productMaster.findIndex(
      (productInd) => productInd.name === itemSelected.product_name);
    console.log("Currently selected row product id : ", currentProductIndex);
    if (currentProductIndex < 0) {
      itemSelected.error = "Please select product from list";
    }
    */
    //const newProductCompositionList = productCompositionList.slice();
    var selectedSellProdItem = items[selectedCompositionId];
    
    //let itemNew = { id: 1, product: { value: 0, label: "" }, unit_cost: "", qty: 1, "product_cost": "", "total_cost": "", gst: { cgst: 0.00, sgst: 0.00, igst: 0.00, totGst: 0.00 } };
    let itemNewComposite = {
      id: 0,
      product_id: 0,
      product_name: "",
      quantity: 0.00,
    };
    //selectedSellProdItem.composition = [];
    /*
    if ( selectedSellProdItem.composition.length < 1 ) {
      selectedSellProdItem.composition = [];
    }
    */
    selectedSellProdItem.composition.push(itemNewComposite);
    //selectedSellProdItem.composition.push();
    //newProductCompositionList.push(itemNew);
    //setProductCompositionList(newProductCompositionList);
    //itemNew.composition.push();
    //itemsNew[selectedCompositionId].composition.push(itemNewComposite);
    const itemsNew = items.slice();
    itemsNew[selectedCompositionId] = selectedSellProdItem;
    console.log("modified with composition array : ", selectedSellProdItem);
    console.log("item array : ", itemsNew);
    setItems(itemsNew);
  };
  const handleProductSelectComposition = (e, item, idx) => {
    console.log(">>>>>>>>>>>>>>>>>>>>>>> HandleProductSelect called *******************");
    console.log("SELECTED Dropdown value : ", e);
    console.log("SELECTED element full : ", item);
    console.log("SELECTED element idx : ", idx);
    //var currentId = data.name.substring(data.name.lastIndexOf("-") + 1);
    //console.log("Current id : ", currentId);
   // console.log("Product Info : ", productMaster);

    var itemSelected = items[idx];
    console.log("Passed element item : ", item);
    console.log("Item selected : ", itemSelected);


    items[selectedCompositionId].composition.push();
    /*
    if (itemSelected.product) {
      console.log("Product: ", itemSelected.product);
    }
    */
    //console.log("<<<<<<<<<<<<<<<<<<<<< ITEM ID >>>>>>>>>>>>>>>>>>: ", itemSelected.id);

    /*
    console.log("Product Info : ", productMaster);
    const currentProductIndex = productCompositionList.findIndex(
      (productInd) => productInd.name === e.label
    );
    */

    //console.log("Currently selected row product id : ", currentProductIndex);
    //console.log("Currently selected row product value : ", productCompositionList[currentProductIndex]);

    //itemSelected.product_id = e.id;
    //itemSelected.product_name = e.name;
    //itemSelected.uom = e.uom;
    //itemSelected.product.label = e.label;
    /*
    var productNew = {
      id: e.id,
      name: e.name,
    };
    console.log("Product Value : ", productNew);
    itemSelected.product = productNew;
    */

    /*
    const newProductCompositionList = productCompositionList.slice();
    newProductCompositionList[currentId] = itemSelected;
    setProductCompositionList(newProductCompositionList);
    */
    //setProductMaster(newSellProdArr);


  };
  const handleItemDeleteComposition = (e) => {
    console.log("handle item delete called ...", e);
    console.log("handle item delete e.currentTarget : ", e.currentTarget.id);
    console.log("handle item delete e.target : ", e.target.id);

    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(
        0,
        clickedTagId.lastIndexOf("-")
      );
      console.log("current selected DELETE element name : ", nameOfElement);
      console.log("Current SELECTED DELETE id : ", curSelId);
      console.log("CUR SELECTED DELETE VALUE : ", productCompositionList[curSelId]);

      let sliced = productCompositionList.splice(curSelId, 1);
      console.log("Removed item from order items: ", sliced);
      var newProductCompositionList = productCompositionList.slice();
      setProductCompositionList(newProductCompositionList);

    }
  };

  const handleCloseComposition = async () => {
    console.log("handle close composition called ...");
    setShowConfigDialog(false);
  }

  const handleSaveComposition = async () => {
    console.log("Handle Save compostion called ...");
    setShowConfigDialog(false);
    setCompositionUpdate(true);

    var selectedItem = items[selectedCompositionId];
    selectedItem.compositionUpdate = true;
    selectedItem.is_product_cc = true;

    var newItemsTmp = items.slice();
    newItemsTmp[selectedCompositionId] = selectedItem;
    setItems(newItemsTmp);
  }
  const handleCloseCompositionProduct = async () => {
    console.log("handle close composition  product called ...");
    navigate(0);

  }

  const handleOnItemChangeComposition = (e) => {
    //e.preventDefault();
    console.log("Button clicked : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);


    var currentId = name.substring(name.lastIndexOf("-") + 1);
    var nameOfElement = name.substring(0, name.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    var itemSelected = items[selectedCompositionId];
    console.log("selected composition for M grade products : ", itemSelected.composition);
    
    console.log("SELECTED COMPOSITION COMPONENt element : ", itemSelected.composition[currentId]);
    /*
    //console.log("Product: ", itemSelected.product);
    console.log("Product Name : ", itemSelected.product_name);
    //setFormValues({ ...formValues, [name]: value });
    console.log("Product Info : ", productCompositionList);
    const currentProductIndex = productCompositionList.findIndex(
      (productInd) => productInd.product_name === itemSelected.product_name
    );
    
    console.log("Currently selected row product id : ", currentProductIndex);
    if (currentProductIndex < 0) {
      itemSelected.error = "Please select product from list";

      const newProductCompositionList = productCompositionList.slice();
      newProductCompositionList[currentId] = itemSelected;
      setProductCompositionList(newProductCompositionList);
      return 0;
    } else {
      itemSelected.error = "";
    }

    console.log("Currently selected row product value : ", productCompositionList[currentProductIndex]);
    */

    /*
    itemSelected.product = {
      id: productList[currentProductIndex].id,
      name: productList[currentProductIndex].name,
      
    };
    */
    //itemSelected.unit_cost = productMaster[currentProductIndex].unit_cost;

    if (nameOfElement === "quantity") {
      console.log("Product Quantity item edited ...");

      // const regex =  /^[0-9\b]+$/;
      /*
       const numericRegExp = /^\d*\.?\d*$/;
       //if (numericRegExp.match(e.target.value)) {
       if (e.currentTarget.value.match(numericRegExp)) {
         itemSelected[nameOfElement] = e.currentTarget.value;
       }
       */
      //const numericRegExp = /^\d*\.?\d*$/;
      if (e.currentTarget.value.match(appGlobal.numericRegExp)) {
        //itemSelected[nameOfElement] = e.currentTarget.value.replace(/^0+/, '');
        itemSelected.composition[currentId].quantity = value;
      }
    } else {
      console.log("Other than handled option clicked so skipping it ...");
    }

    //const newProductCompositionList = productCompositionList.slice();
    //newProductCompositionList[currentId] = itemSelected;
    //setProductCompositionList(newProductCompositionList);
    var newItemsTmp = items.slice();
    newItemsTmp[selectedCompositionId] = itemSelected;
    setItems(newItemsTmp);
  };

  const handleAlertClose = () => {
    console.log("Handle alert close called ...");
    setShowAlert(false);
    if ( alertPurpose === 'success') {
      navigate("/listInvoice");
    }
  }

  const callApiToGenerateOS = async() => {
    console.log("callApiToGenerateOS called ...: ", actionVal);

    var summaryUpdateArr = [];
    summaryList.map((summary) => {
      console.log("SUMMARY INDIVIDUAL : ", summary);
      console.log("INDIVIDUAL SUMMARY ID : ", summary.id);
      summaryUpdateArr.push(summary.id);
    });

    var receiptArr = [];
    receiptList.map((receipt) => {
      console.log("RECEIPT INDIVIDUAL : ", receipt);
      console.log("RECEIPT ID : ", receipt.id);
      receiptArr.push(receipt.id);
    });

    let reqAddUpdateOsStmt = {
      os_date: formValues.os_date,
      customer_id: formValues.customer_id,
      previous_balance: customerOs.previous_balance,
      total_received: receipt_total_sum.current,
      current_balance: total_amount_sum.current,
      total_balance: total_balance_os.current,
      summary_array: summaryUpdateArr,
      receipt_array: receiptArr
    };

   
    console.log("payload addUpdateDC :", reqAddUpdateOsStmt);

    //var testTkn = "UJDFISDHjkdah8473bjkhgjhf";
    //var headers = {...client.headers,Authorization: " " + testTkn,};

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
      if ( actionVal === 'save-print') {
        setSubmitPrintAction(true);
      }
      else {
        setSubmitAction(true);
      }

     
      //setSubmitAction(true);
      setAlertPurpose('submit');
      var res = await axios.post(client.domain + "/insertCustOsStmt", reqAddUpdateOsStmt, { headers }, { withCredentials: false });
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.res_data);

        if ( action_val_ref.current === 'save-print') {
          setSubmitPrintAction(false);
        }
        else {
          setSubmitAction(false);
        }
        //setAlertDialogTitle("SUCCESS!");
        //setAlertDialogBody("Outstanding Summary Created Successfully ...");
        //setAlertPurpose("success");
        //setShowAlert(true);
        //setSubmitAction(false);

        if ( action_val_ref.current === 'save-print' ) {
          console.log(">>>>>>>>>>>>>>>> INSIDE SAVE AND PRINT >>>>>>>>>>>>>>>>>>>>>>>");
          setAlertPurpose("success");
          var urlStr = window.location.href;
          var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
          var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));
      
          console.log("CALLED URL : ", urlTmp);
          console.log("CALLED PAGE : ", paramsTmp);
          
          //window.open(`${urlTmp}${client.urlDelimiter}/printCustOsStmt?order_id=${passedValue.order_id}`);
          var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printCustOsStmt?os_stmt_id=${res.data.detail.res_data.os_stmt_id}`, "width=" + window.screen.width + ",height=" + window.screen.height);
          winPrint.moveTo(0,0);
          winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
          navigate(-1);
        }
        else {
          setAlertPurpose("success");
          setAlertTitle("Success");
          setSubmitAction(false);
          setAlertBody("Outstanding Statement Generated Successfully!"); 
          setShowAlert(true); 
        }
        
      }
      else {
        setAlertPurpose("failure");
        setAlertTitle("Failed");
        setAlertBody("Outstanding Statement Generated Failed!");
        setShowAlert(true);
        setSubmitAction(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
        setAlertPurpose("failure");
        setAlertTitle("Warning");
        setAlertBody("Unable to process your request at this moment.Please try after sometime!");
        setShowAlert(true);
        setSubmitAction(false);
    }
  }

  const handleAlertOK = async(e) => {
    console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ Handle alert close called ...: ", alertPurpose);
    setShowAlert(false);
    setSubmitAction(false);
    
    if( alertPurpose === 'submit') {
      //callApiToGenerateOS();
    }
    else if ( alertPurpose === 'success') {
      navigate('/listCustOsStmt');
      setAlertPurpose('init');
    }
    else if (alertPurpose === 'failure') {
      setAlertPurpose('init');
    }
    else if ( alertPurpose === 'customer_status') {

    }
    else if ( alertPurpose === 'confirmation' ) {
      setAlertPurpose("confirmed");
      handleSave(e, action_val_ref.current);
      //callApiToGenerateOS();
    }
    else if (alertPurpose === 'confirmed') {
      callApiToGenerateOS();
    }
    else if ( alertPurpose === 'delete' ) {
      await deleteCustOsStmt();
    }
    else if ( alertPurpose === 'deleted' ) {
      navigate('/listCustOsStmt');
    }
    else if ( alertPurpose === 'delete_failed') {

    } 
  }

  const handleCloseDialog = () => {
    console.log("Dilaog canceled ....");
    setShowAlert(false);
    setSubmitAction(false);
    if ( alertPurpose === 'success') {
      navigate('/listCustOsStmt');
      setAlertPurpose('init');
    }
    else  {
      setAlertPurpose('init');
    }
  }
  const handleReset = async (e) => {
    e.preventDefault();
    console.log("handle clear called : ", e);
    console.log("Handle clear clicked current target ID: ", e.currentTarget.id);
    //setDistrictIdSelect(optDist[0]);
    //setStateIdSelect(optionsState[0]);
      setAlertPurpose('confirmation');
      setShowAlert(true);
      setAlertTitle("Confirmation");
      setAlertBody("Are you sure want to Reset  Customer Details and Address");
      
    //navigate(0);
  };
 
  const handlePrintFullOrder = async(e) => {
    console.log("Handle print order clicked : ", e);
    //passedValue.order_id

    e.preventDefault();
    console.log("Handle action print Clicked : ", e);
    //navigate("/printCustOsStmt");
    
    
    /*
    console.log("CALLED CURRENT PAGE OrderDetails PRINT DOCUMENT : ", window.location.href);

    console.log("CALLED window locaion OrderDetails PRINT DOCUMENT : ", window.location);
    //console.log("ORDER ID : ", orderId);
    //console.log("DC ID : ", dcId);

    //const navigate = useNavigate();

    //navigate("/printDC");
    //navigate("/printDocument", {state:{orderId: orderId, dcId: dcId}}, { replace: true });

    //navigate("/renderInWindow", {state:{orderId: orderId, dcId: dcId}}, { replace: true });
    console.log("LOCATION PATH DIRECTORY PRINT DOCUMENT : ", __dirname);

    //navigate("/newWindow", {state:{orderId: orderId, dcId: dcId}}, { replace: true });

    //window.open(`http://localhost:3000/#/printDocument?order_id=${orderId}&dc_id=${dcId}`);
    //window.open(`http://${window.location.host}/#/printDocument?order_id=${orderId}&dc_id=${dcId}`);
    */
    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);
    
    //window.open(`${urlTmp}${client.urlDelimiter}/printCustOsStmt?order_id=${passedValue.order_id}`);
    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printCustOsStmt?os_stmt_id=${state.passed_element.id}`, "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0,0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);
  }

    const getDeliverySummary = async() => {
      var retState = false;
      console.log("getDeliverySummary called ...");

      try {
        var aIVal = localStorage.getItem("_aI");
        var headers = {...client.headers, Authorization: `Bearer ${aIVal}`, SerialKey: 'HNGD8743JESWE'};
        var res = await axios.get(client.domain + `/getDeliverySummaryByDtAndCustomer?customer_id=${formValues.customer_id}&os_date=${formValues.os_date}`, { headers }, { withCredentials: false });
        console.log("Response from server getProductForLoad : ", res.data.detail);
  
        if (res.data.detail.status === "SUCCESS") {
          console.log("Response message from server getDeliverySummary : ", res.data.detail.data);
          retState = true;
          
          console.log("Length summary : %d, length receipts : %s", res.data.detail.data.summary.length, res.data.detail.data.receipts.length);
          if (res.data.detail.data.summary.length < 1 && res.data.detail.data.receipts.length < 1 ) {
            console.log("<<<<<<<<<<<<<<<<<< DISABLED CALLED >>>>>>>>>>>>>>>>>>");
            setSubmitDisabled(true);
            return retState;
          }
          else {
            setSubmitDisabled(false);
          }
          setIsSearched(true);
          console.log("<<<<<<<<<<<<<<<<<< DISABLED STATE : >>>>>>>>>>>>>>>>>>", submitDisabled);
          total_quantity_sum.current = 0.00;
          total_amount_sum.current = 0.00;
          total_product_amount_sum.current = 0.00;
          total_extra_charges_sum.current = 0.00;
          res.data.detail.data.summary.map((summary) => {
            console.log("TOTAL QUANTITY INDIVIDUAL : ", summary.total_quantity);
            total_quantity_sum.current = total_quantity_sum.current + parseFloat(summary.total_quantity);
            total_amount_sum.current = total_amount_sum.current + parseFloat(summary.total_amount);
            total_product_amount_sum.current = total_product_amount_sum.current + (parseFloat(summary.total_quantity) * parseFloat(summary.unit_price));
            total_extra_charges_sum.current = total_extra_charges_sum.current + parseFloat(summary.pumping_charges) + parseFloat(summary.diesel_charges) + parseFloat(summary.others_charges1);
          })
          console.log("TOTAL QUANTITY SUM : ", total_quantity_sum.current);
          console.log("TOTAL PRODCUCT AMOUNT SUM : ", total_product_amount_sum.current);
          console.log("TOTAL EXTRA CHARGES SUM : ", total_extra_charges_sum.current);
          console.log("TOTAL AMOUNT SUM : ", total_amount_sum.current);

          receipt_total_sum.current = 0.00;
          res.data.detail.data.receipts.map((receipt) => {
            console.log("TOTAL QUANTITY INDIVIDUAL : ", receipt.amount);
            receipt_total_sum.current = receipt_total_sum.current + parseFloat(receipt.amount); 
          })
          console.log("TOTAL RECEIPTS SUM : ", receipt_total_sum.current);

          console.log("CUSTOMER OUTSTANDING at getDeliverySummary : ", customerOs);
          //total_balance_os.current = ( res.data.detail.data.customer_os.total_balance + total_amount_sum.current ) - receipt_total_sum.current;
          total_balance_os.current = (parseFloat(res.data.detail.data.customer_os.previous_balance)  + total_amount_sum.current) - receipt_total_sum.current;
          console.log("TOTAL BALANCE Outstanding : ", total_balance_os.current);

          setSummaryList(res.data.detail.data.summary);
          setReceiptList(res.data.detail.data.receipts);
          setCustomerOs(res.data.detail.data.customer_os);
          //setFormValues(res.data.detail.data);
          /*
          if ( res.data.detail.data.is_composite === true && res.data.detail.data.tt_key === 'SELL' && 'composite' in res.data.detail.data && res.data.detail.data.composite.length > 0 ) {
            setProductCompositionList(res.data.detail.data.composite);
          }
          */
          //setProductCompositionList(res.data.detail.data.composite);
          //console.log("SELECTED PROD INDEX ID : ", prodIdIdx);
          //console.log("SELECTED PROD DATA : ", items[prodIdIdx]);
  
          //var selectedProdTmp2 = items[prodIdIdx];
  
          //selectedProdTmp2.composition = res.data.detail.data.composite;
  
          //var newItemsTmp2 = items.slice();
          //newItemsTmp2[prodIdIdx] = selectedProdTmp2;
          //setItems(newItemsTmp2);
          //setProductCompositionList(res.data.detail.data.composite);
          /*
          console.log("UOM array : ", uomOpt);
          var findUom = uomRef.current.find(element => element.value === res.data.detail.data.uom_key);
          console.log("**************** findUOM value ++++++++++++ : ", findUom);
          if (findUom !== undefined) {
            setUomIdSelect({ label: findUom.label, value: findUom.value })
          }
          */
          //setProdDetLoaded(true);
          //setToRender(true);
          //setEditAction(false);
          //setShowConfigDialog(true);
  
          //retState = true;
          //prodRes = res.data.detail.data;
          //console.log("PRODUCT LOAD STATUS SET TO TRUE")
        }
      } catch (error) {
        console.log("FULL ERROR : ", error);
        if (error.code === "ERR_NETWORK") {
          console.log("Not able to reach target server please try after sometime");
  
        } else {
          console.log("ERROR : ", error.response.data.detail);
          if (error.response.data.detail.status === "FAILED") {
            console.log(
              "Request failed due to ",
              error.response.data.detail.res_data
            );
  
          }
        }
      }
      return retState;
    }
    

  const handleView = async (e) => {
    console.log("handle edit summary called : ", e);
    console.log("handle edit summary currentTarget called : ", e.currentTarget);
    //setIsReadOnly(false);
    if(formValues.customer_id < 1){
      setAlertPurpose('customer_status');
      setAlertTitle('Warning');
      setAlertBody("Select Valid Customer from Search!");
      setSubmitDelAction(false);
      setShowAlert(true);
    }
    else {
      await getDeliverySummary();
      setIsSearched(true);
    }

  };

  const searchHandleFunc = async (query) => {
    setIsLoading(true);

    fetch(`${client.domain}/search/customers?q=${query}`)
      .then((resp) => resp.json())
      .then((json) => {
        //searchActionFunc(json)
        setOptionsSearch(json.detail.res_data.customer_list);
        setIsLoading(false);
        console.log("SEARCHED VALUE inside searchActionFunc : ", json.detail.res_data.customer_list);
      });
    formValues["customer_name"] = query;
    //setFormValues({ ...formValues, ["customer_disp_name"]: query });
    /*
    if ( addrCopyCheck && !formValues.contact_person) {
      setFormValues({ ...formValues, ["shipContactName"]: query });
    }
    */
    //setFormValues({ ...formValues, ["customer_name"]: query });
    setCustomerSearchText(query);
  };

  /*
  const searchHandleLocationFunc = (query) => {
    console.log("searchHandleLocationFunc called ...");
    setIsLoading(true);

    fetch(`${client.domain}/search/locations?q=${query}`)
      .then((resp) => resp.json())
      .then((json) => {
        //searchActionFunc(json)
        setOptionsSearchLoc(json.detail.res_data.location_list);
        setIsLoading(false);
        console.log("SEARCHED VALUE inside searchActionFunc : ", json.detail.res_data.location_list);
      });
    //formValues["customer_name"] = query;
    //setFormValues({ ...formValues, ["adderss1"]: query });
    //setFormValues({ ...formValues, ["customer_name"]: query });
    setLocationSearchText(query);
  };
  */
  
  //console.log("<<<<<<<<<<<<< TOTAL RECEIPT AMOUNT : ", receipt_total_sum.current)
  //console.log("<<<<<<<<<<<<< TOTAL CURRENT AMOUNT : ", total_amount_sum.current)
  //console.log("<<<<<<<<<<<<< TOTAL OUTSTANDING AMOUNT : ", total_balance_os.current)

  return (
    <>
          <Container>
            <SidebarMain />
            { toRender ?
            (
            <Form>
              <Row>
                <Col xs={4} md={4} sm={4} lg={4}>
                  <span style={{ fontSize: "1.5rem", fontWeight: "500" }}>
                    Customer Outstanding Statement
                  </span>
                  {(state.action === "view" || state.action === "edit") && (
                    <>
                      &nbsp;&nbsp;
                      <Badge pill bg="success">
                        <h6>{state.passed_element.id}</h6>
                      </Badge>
                    </>
                  )}
                </Col>
                <Col
                  xs={8}
                  md={8}
                  sm={8}
                  lg={8}
                  className="d-flex justify-content-end float-end"
                >
                  <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                    <BsArrowLeftCircle
                      key={`back-btn-order-key`}
                      size={24}
                      style={{ cursor: "pointer" }}

                      name={`back-icon-order-name`}
                      id={`edit-icon-order-id`}
                    />
                  </Button>
                  {(state.action === "edit" || state.action === "view") && (
                    <>
                      
                      &nbsp;&nbsp;
                <Button id="fullOrderPrint" name = "fullorderPrint"  onClick={(e)  => handlePrintFullOrder(e)}>
                <BsFillPrinterFill
                  size={18}
                  style={{ color: "white", cursor: "pointer" }}
                  name={`print-order-dc-icon`}
                  id={`print-order-dc-icon`}
                />
                  </Button>
                  &nbsp;&nbsp;
                      <Button
                        variant="danger"
                        className="btn btn-danger btn-md"
                        onClick={(e) => handleDelete(e)}
                        disabled={submitDelAction ? true: false}
                      >
                        {submitDelAction &&
                      <>
                      <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                     <span className="visually-hidden">Loading...</span>
                     </> 
                      }

                        <MdDelete
                          key={`edit-btn-order-key`}
                          size={24}
                          style={{ cursor: "pointer" }}

                          name={`edit-btn-order-name`}
                          id={`edit-btn-order-id`}
                        />
                      </Button>
                    </>
                  )}
                </Col>
              </Row>

              <p></p>

              <div className="ui-form">
                <hr align="center" />

                

                <Row>
                <Col xs={3} md={3} sm={3} lg={3}>
                    <Form.Group className="mb-3" controlId="osform.date">
                      <Form.Label>Outstanding Statement Date</Form.Label>
                      {/*
                      <Select
                        id="os_date"
                        name="os_date"
                        value={{
                          value: dateIdSelect.value,
                          label: dateIdSelect.label,
                        }}
                        options={optDate}
                        defaultValue={{
                          value: dateIdSelect.value,
                          label: dateIdSelect.label,
                        }}
                        onChange={handleDateSelect}
                        //readOnly={state.action === 'add' ? isReadOnly : true}
                        isDisabled={state.action !== 'add' || isLoading}
                        style={{
                          border: !isReadOnly && "1px solid gray",
                        }}
                      />
                      */}
                      <Form.Control
                  type="date"
                  size="sm"
                  name="os_date"
                  placeholder="Outstanding Date"
                  value={formValues.os_date}
                  onChange={onChangeDatePicker}
                  //readOnly={isReadOnly}
                  
                />
                    </Form.Group>
                  </Col>
                  
                 
                  <Col xs={3} md={3} sm={3} lg={3}>
                    {/*
                    <Form.Group className="mb-3" controlId="osform.customr">
                      <Form.Label>Customer</Form.Label>

                      <Form.Control
                        type="text"
                        name="customer"
                        placeholder="Customer"
                        value={formValues.customer}
                        onChange={handleValueChange}
                        //readOnly={state.action === 'add' ? isReadOnly : true}
                        //disabled={state.action !== 'add' || isLoading}
                        style={{
                          border: !isReadOnly && "1px solid gray",
                        }}
                      />
                    </Form.Group>
                    */}
                    <Form.Group
                      className="mb-3"
                      controlId="orderform.customer_name"
                    >
                      <Form.Label className="field">Customer Search</Form.Label>
                      <AsyncTypeahead
                        id="customer-search-fd-id"
                        name="customer_name_search"
                        isLoading={isLoading}
                        onSearch={(query) => {
                          searchHandleFunc(query);
                        }}
                        onChange={(selected) => {
                          customerSearchSelectFunc(selected);
                        }}
                        options={optionsSearch}
                        placeholder="Search Customer ..."
                        readOnly={(state === null || state.action === 'add') ? isReadOnly : true}
                        style={{
                          /*border: !isReadOnly && "0px solid gray",*/
                          borderColor: "gray",
                          /*borderStyle: "solid",*/
                        }}
                        className="rounded-sm"
                      />
                    </Form.Group>
                      
                    <p className="error-msg">{formErrors.customer}</p>
                  </Col>
                  <Col xs={3} md={3} sm={3} lg={3}>
                    <Form.Group className="mb-3">
                    <Form.Label className="field required">Customer Name </Form.Label>
                    <Form.Control
                    type="text"
                    name="customer_name"
                    id="customer_name"
                    placeholder="Customer Name"
                    value={formValues.customer_name}
                    onChange={handleChange}
                    disabled={true}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                    maxLength={12}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.customer_name}</p>
              </Col>
              
                  <Col xs={2} sm={2} md={2} lg={2} style={{paddingTop: "30px"}}>
               
                <Button id="osView" 
                name = "osView"  
                onClick={(e)  => handleView(e)}
                disabled={state.action === 'add' ? false : true}
                //disabled={deliveryChellan.length > 0 ? false: true}
                >
                    <BsCardText
                    size={18}
                    style={{ color: "white", cursor: "pointer" }}
                    name={`view-os-icon`}
                    id={`view-os-icon`}
                  />
                &nbsp;
                View OS
                </Button>
                </Col> 
                </Row>
                
                { isSearched && 
                <>
                <Row>
                      <Col xs={6} md={6} sm={6} lg={6}>
                        <h5>
                          Current Ouststanding Details
                        </h5>
                      </Col>
                </Row>
                <Table striped bordered hover>
            <thead>
              <tr className="table-primary">
                <th className="table-row-heading">S.No</th>
                <th className="table-row-heading">Delivery Date</th>
                <th className="table-row-heading">Location</th>
                <th className="table-row-heading">Grade</th>
                <th className="table-row-heading">Total Volumns (M<sup>3</sup>)</th>
                <th className="table-row-heading">Unit Price</th>
                <th className="table-row-heading">Product Price</th>
                <th className="table-row-heading">Extra charges </th>
                <th className="table-row-heading">Total Amount</th>
                
              </tr>
            </thead>
            <tbody>
              {summaryList.map((summary, idx) => (
                <tr>
                  <td>{idx + 1}</td>

                  <td>{formatDateIndian(new Date(summary.delivery_date))}</td>
                  <td>{summary.address_line1}</td>
                  <td>{summary.product_name}</td>
                  <td style={{textAlign: 'right'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(summary.total_quantity).toFixed(2)))}</td>
                  <td style={{textAlign: 'right'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(summary.unit_price).toFixed(2)))}</td>
                  <td style={{textAlign: 'right'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(summary.total_quantity * summary.unit_price).toFixed(2)))}</td>
                  <td style={{textAlign: 'right'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(parseFloat(summary.pumping_charges) + parseFloat(summary.diesel_charges) + parseFloat(summary.others_charges1)).toFixed(2)))}</td>
                  <td style={{textAlign: 'right'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(summary.total_amount).toFixed(2)))}</td>

                 
                </tr>
              ))}
            </tbody>

            <tbody>
              <tr>
                  <td></td>
                  <td style={{fontWeight: 'bold'}}>Total </td>
                  <td></td>
                  <td></td>
                  <td style={{textAlign: 'right', fontWeight: 'bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(total_quantity_sum.current).toFixed(2)))}</td>
                  <td style={{textAlign: 'right'}}></td>
                  <td style={{textAlign: 'right',  fontWeight: 'bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(total_product_amount_sum.current).toFixed(2)))}</td>
                  <td style={{textAlign: 'right', fontWeight: 'bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(total_extra_charges_sum.current).toFixed(2)))}</td>
                  <td style={{textAlign: 'right',  fontWeight: 'bold'}}>{(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(total_amount_sum.current).toFixed(2)))}</td>
                  

                 
                </tr>
            </tbody>
            
          </Table>
        {/*        
        <Row>
        <Col xs={6} md={6} sm={6}>
        </Col>
        <Col xs={3} md={3} sm={3}>
                        <div>
                          <span style={{ fontWeight: "bold" }}>
                            Total Volume :
                          </span>
                          <span>{total_quantity_sum.current}</span>
                        </div>
                      </Col>
                      <Col xs={3} md={3} sm={3}>
                        <div>
                          <span style={{ fontWeight: "bold" }}>
                            Total Amount :
                          </span>
                          <span>{total_amount_sum.current}</span>
                        </div>
                      </Col>
        </Row>
        */}
                
                <p></p>
                {/*
                <div className="ui-divider"></div>
                <div className="ui-form"></div>
                */}
                <Row className="show-grid">
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <Row>
                      <Col xs={6} md={6} sm={6} lg={6}>
                        <h5>
                          Advances Received
                        </h5>
                      </Col>
                      <Col xs={12} md={12} sm={12} lg={12}>
                      <Table striped bordered hover>
          <thead>
            <tr className="table-primary">
             
              <th className="table-row-heading">#</th>
              <th className="table-row-heading"> Receipt No</th>
              <th className="table-row-heading">Date</th>
              <th className="table-row-heading">Note</th>
              <th className="table-row-heading">Amount</th>
              
             
            </tr>
          </thead>

          <tbody>
            {receiptList.map((stmtInd, idx) => (
              <tr key={`stmt-table-row-key-${idx}`}>
                <td>{idx + 1}</td>
                <td
                  key={`stmt-table-row-key-stmtno-${idx}`}
                  id={`stmt-table-row-id-stmtno-${idx}`}
                  name={`stmt-table-row-name-stmtno-${idx}`}
                >
                  {stmtInd.receipt_number}
                </td>
                
                <td
                  key={`stmt-table-row-key-date-${idx}`}
                  id={`stmt-table-row-id-date-${idx}`}
                  name={`stmt-table-row-name-date-${idx}`}
                >
                  {formatDateIndian(new Date(stmtInd.receipt_date))}
                </td>
               
                <td
                  style={{ padding: "5px", textAlign: "left" }}
                  key={`stmt-table-row-key-description-${idx}`}
                  id={`stmt-table-row-id-description-${idx}`}
                  name={`stmt-table-row-name-description-${idx}`}
                >
                  {stmtInd.towards}
                </td>
                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`stmt-table-row-key-amount-${idx}`}
                  id={`stmt-table-row-id-amount-${idx}`}
                  name={`stmt-table-row-name-amount-${idx}`}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(stmtInd.amount).toFixed(2)))}
                </td>
               
              </tr>
            ))}
          </tbody>

          <tbody>
            <tr key={`stmt-table-row-key-total`}>
                <td></td>
                <td
                  key={`stmt-table-row-key-stmtno-total`}
                  id={`stmt-table-row-id-stmtno-total`}
                  name={`stmt-table-row-name-stmtno-total`}
                  style={{ fontWeight: 'bold'}}
                >
                  Total
                </td>
                
                <td
                  key={`stmt-table-row-key-date-total`}
                  id={`stmt-table-row-id-date-total`}
                  name={`stmt-table-row-name-date-total`}
                >
                  
                </td>
               
                <td
                  style={{ padding: "5px", textAlign: "right" }}
                  key={`stmt-table-row-key-description-total`}
                  id={`stmt-table-row-id-description-total`}
                  name={`stmt-table-row-name-description-total`}
                >
                 
                </td>
                <td
                  key={`stmt-table-row-key-amount-total`}
                  id={`stmt-table-row-id-amount-total`}
                  name={`stmt-table-row-name-amount-total`}
                  style={{padding: "5px", textAlign: "right", fontWeight: 'bold'}}
                >
                  {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(receipt_total_sum.current).toFixed(2)))}
                </td>
               
              </tr>
          </tbody>

        </Table>
        </Col>
        {/*
        <Row>
        <Col xs={3} md={3} sm={3}>
                        <div>
                          <span style={{ fontWeight: "bold" }}>
                            Total:
                          </span>
                          <span>{receipt_total_sum.current}</span>
                        </div>
                      </Col>
        </Row>
        */}   
                    </Row>
                    <div className="ui-divider"></div>
                   
                  </Col>
                  

                  <Col col-12 col-sm-6 xs={6} sm={6} md={6} lg={6} style={{alignContent: 'float-end', align: 'right',paddingTop: "35px"}} className="order-last order-sm-last justify-content-end float-end">
                    
                    <Row>  
                      <Col xs={"10"} sm={"10"} md={"10"} lg={"10"} style={{alignContent: 'right', alignItems: 'right', textAlign: 'right'}}>
                    <Form.Label style={{fontWeight: 'bold'}}>
                     Old Balance :
                      </Form.Label>
                      </Col>
                      <Col xs={"2"} sm={"2"} md={"2"} lg={"2"} style={{alignContent: 'right', alignItems: 'right', textAlign: 'right'}}>
                        
                        <Form.Label style={{fontWeight: 'bold'}}>
                        {customerOs.previous_balance > 0.01 ? (new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(customerOs.previous_balance).toFixed(2))) : 0.00}
                      </Form.Label>
                       </Col>
                    
                    </Row>

                    <Row>  
                      <Col xs={"10"} sm={"10"} md={"10"} lg={"10"} style={{textAlign: 'right'}}>
                    <Form.Label style={{fontWeight: 'bold'}}>
                     Current Balance : 
                      </Form.Label>
                      </Col>
                      <Col xs={"2"} sm={"2"} md={"2"} lg={"2"} style={{textAlign: 'right'}}>
                        
                        <Form.Label style={{fontWeight: 'bold'}}>
                        {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(total_amount_sum.current).toFixed(2)))}
                      </Form.Label>
                       </Col>
                    
                    </Row>

                    
                  <Row>  
                      <Col xs={"10"} sm={"10"} md={"10"} lg={"10"} style={{alignContent: 'right', alignItems: 'right', textAlign: 'right'}}>
                    <Form.Label style={{fontWeight: 'bold'}} >
                     Advance :
                      </Form.Label>
                      </Col>
                      <Col xs={"2"} sm={"2"} md={"2"} lg={"2"} style={{alignContent: 'right', alignItems: 'right', textAlign: 'right'}}>
                        
                        <Form.Label style={{fontWeight: 'bold'}}>
                        {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(receipt_total_sum.current).toFixed(2)))}
                      </Form.Label>
                       </Col>
                    
                    </Row>

                    
              <Row>  
                      <Col xs={"10"} sm={"10"} md={"10"} lg={"10"} style={{alignContent: 'right', alignItems: 'right', textAlign: 'right'}}>
                    <Form.Label style={{fontWeight: 'bold'}}>
                     Total Balance :
                      </Form.Label>
                      </Col>
                      <Col xs={"2"} sm={"2"} md={"2"} lg={"2"} style={{alignContent: 'right', alignItems: 'right', textAlign: 'right'}}>
                        
                        <Form.Label style={{fontWeight: 'bold'}}>
                        {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(total_balance_os.current).toFixed(2)))}
                      </Form.Label>
                       </Col>
                    
                    </Row>
                    
                    
                    <div className="ui-divider"></div> 
                   
                  </Col>
                </Row>
                <p></p>
               
                {state.action === 'add' && (
                <Row>
                  <Col xs={12} md={12} sm={12}>
                    <div className="d-flex justify-content-end">
                      <Button
                        className="btn btn-secondary"
                        id="btn-clear-order-id"
                        name="btn-clear-order-name"
                        onClick={handleClear}
                      >
                        Clear
                      </Button>
                      &nbsp;&nbsp;
                      {/*
                      <Button
                        type="submit"
                        className="btn btn-primary"
                        id="save-only"
                        name="save-only"
                        disabled={submitAction ? true: false}
                        onClick={handleSave}

                      >
                        {submitAction &&
                      <>
                      <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                     <span className="visually-hidden">Loading...</span>
                     </> 
                      }
                     GENERATE & PRINT    
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        className="btn btn-primary"
                        id="btn-clear-order-id"
                        name="btn-clear-order-name"
                        onClick={handleSavePrint}
                      >
                        GENERATE
                      </Button>
                    */}

                   <Button 
                    className="btn btn-primary"
                    id="save-print"
                    name="save-print"
                    onClick={(e) => handleSave(e, "save-print")}
                    disabled={submitDisabled}
                  >
                  { submitPrintAction &&
                    <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                  }
                  Generate & Print</Button>
                  &nbsp;
                  &nbsp;&nbsp;
                  
                  <Button className="btn btn-primary" 
                    onClick={(e) => handleSave(e, 'save-only')}
                    disabled={submitDisabled}>
                    { submitAction &&
                    <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                  }  
                  Generate
                  </Button>
                    </div>
                  </Col>
                </Row>
                )}
                </>
                }
              </div>
            </Form>
             )
             :
             <div className="container-spinner">
             <div className="vertical-center-spinner">
              {/*<Button variant="primary" disabled>*/}
              <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
                 <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
                     <span className="visually-hidden">Loading...</span>
                   </Spinner>
        
              {/*</Button>*/}
              </div>
              </div>
           }
          </Container>
       
      
      <Modal show={showAlert}>
        <Modal.Header>
          <Modal.Title>{alertTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{alertBody}</Modal.Body>
        <Modal.Footer>
        { (alertPurpose == 'submit' || alertPurpose === 'confirmation' || alertPurpose === 'delete' || alertPurpose === 'confirmed') &&
        <Button variant="secondary" onClick={handleCloseDialog}>Cancel</Button>
          }
          <Button variant="primary" onClick={handleAlertOK}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddUpdateCustOsStmt;
