import { Fragment, useState, useEffect, useRef  } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Badge,
  Modal,
  Spinner,
  Image
} from "react-bootstrap";

import Sidebar2 from "./Sidebar2";
import SidebarMain from "./SidebarMain";
import Select from "react-select";

import "../Dashboard.css";
import "../App.css";
import { client,appGlobal} from "../config/Config";
import axios from "axios";

import MenuIms from "./MenuIms";

import { useNavigate } from "react-router-dom";
import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsTrashFill,
  BsArrowLeftCircle,
  BsFillPenFill,
} from "react-icons/bs";
import { useLocation } from "react-router-dom";
import {getDistrictList, getStateList, getProductBuy } from "./utils";
import { Front } from "react-bootstrap-icons";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';

//function AddUpdateCustomer () {
const AddUpdateSupplier = (props) => {
  const initialized = useRef(false);
  let initialItems = [];
  const intialValues = {  
  name: "",
  contact_person: "",
  contact_person_number: "",
  gst_no: "",
  pan_no: "",
  phone: "",
  email: "",
  website: "",
  address_line1: "",
  address_line2: "",
  stateId: 0,
  districtId: 0,
  products: [],
  pincode: "" 
};
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const { state } = useLocation();
  const [validateError, setValidateError] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );

  const [submitAction, setSubmitAction] = useState(false);

  const [toRender, setToRender] = useState(false);
  var validateErr = false;

  const [productSelectedList, setProductSelectedList] = useState({});
  const [optDist, setOptDist] = useState([]);
  const [productListApi, setProductListApi] = useState([]);

  const [refresh, setRefresh] = useState(true);
 
  
  const handleOnChange = async(e) => {
    e.preventDefault();
    console.log("Button clicked : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name handleOnChange : ", name);
    console.log("Captured input element value handleOnChange : ", value);
    //console.log("Captured input element id : ", e.currentTarget.id);
    //console.log("Captured input element check state : ", e.currentTarget.checked);

    
    if (name === "phone" || name === "contact_person_number" || name === "pincode") {
      console.log("Inside check ******************: ", name);
      //const numericRegExp = /^(?:[1-9]\d*|\d)$/;
      if (e.currentTarget.value.match(appGlobal.wholeNumberRegExp)) {
        //var valueTmp = Math.round(e.target.value.replace(/\D/g, ""));
        console.log("e.currentTarget.value : ", e.currentTarget.value);
        setFormValues({ ...formValues, [name]: e.currentTarget.value });
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

    const handleCheckBoxOnChange = async(e) => {
    console.log("Button clicked handleCheckBoxOnChange : ", e);

    console.log("Clicked value handleCheckBoxOnChange : ", e.target);
    const { name, id, checked, label } = e.target;
    console.log("Captured input element name handleCheckBoxOnChange : ", name);
    console.log("Captured input element id handleCheckBoxOnChange : ", id);
    console.log("Captured input element check state handleCheckBoxOnChange : ", checked);
    console.log("Captured input element check label handleCheckBoxOnChange : ", e.currentTarget.labels[0].innerText);



    //setProductSelectedList({ ...productSelectedList, [id]: checked});
    
    if ( checked === true ) {
      //setRefresh(false);
      //formValues["products"][id.toString()] = {id: parseInt(id), name: e.currentTarget.labels[0].innerText, uom: "kg"};
      console.log("<<<<<<<<<<<<< products in formValues >>>>>>>>>>>>>>>", formValues["products"]);
      formValues["products"].push({id: parseInt(id), name: e.currentTarget.labels[0].innerText, uom: "kg"})
      //setRefresh(true);
      //setFormValues( ...formValues);
      //setFormValues({ ...formValues["products"], [id]: {id: id, name: e.currentTarget.labels[0].innerText, uom: "kg"}})
      var newFormValues = Object.create(formValues);
      setFormValues(newFormValues);
    }
    else {
      console.log("FORMVALUES products : ", formValues["products"]);
      const index = formValues["products"].findIndex(key => key.id === parseInt(id));
      console.log("Searched Index : ", index);
      if (index >= 0 ) {
        formValues["products"].splice(index,1);

        var newFormValues = Object.create(formValues);
        setFormValues(newFormValues);
      }
    }
    
    //setFormValues({ ...formValues, formValues["products"]: });
  }

  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    if ( values.phone ) {
    console.log("phone number length : ", values["phone"].length);
    }
    if ( values.pincode ) {
      console.log("pincode number length : ", values["pincode"].length);
    }

    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!values.name) {
      errors.name = "Name is required!";
      validateErr = true;
      //setValidateError(true);
    }
   /*
    if (!values.contact_person) {
      errors.contact_person = "Contact person is required!";
      validateErr = true;
      //setValidateError(true);
    }
    */

    if (!values.address_line2) {
      errors.address_line2 = "City / Town is required!";
      validateErr = true;
      //setValidateError(true);
    }
 
    if (!values.phone) {
      errors.phone = "Phone number is required!";
      validateErr = true;
      //setValidateError(true);
    }

    if (values.phone && values.phone.length != 10) {
      errors.phone = "Phone number should be 10 digits!";
      validateErr = true;
      //setValidateError(true);
    }
    
   /*
    if (!values.contact_person_number) {
      errors.contact_person_number = "Contact Number is required!";
      validateErr = true;
      setValidateError(true);
    }
    */
    if (!values.pincode){
      errors.pincode = "pincode is required!";
      validateErr = true;
    }
    if (values.pincode && values.pincode.length != 6) {
      errors.pincode = "pincode should be 6 digits!";
      validateErr = true;
    }

    if (values.contact_person_number && values.contact_person_number.length != 10) {
      errors.contact_person_number = "Contact Number should be 10 digits!";
      validateErr = true;
      setValidateError(true);
    }

    if (formValues["products"].length < 1) {
      errors.products = "Please select atleast one product!";
      validateErr = true;
    }
    else {
      errors.products = "";
    }
  
   
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };
  const handleSelect = (e, data) => {
    console.log("SELECTED Dropdown value : ", e);
    console.log("SELECTED element id : ", data);
    var currentId = data.name.substring(data.name.lastIndexOf("-") + 1);
    console.log("Current id : ", currentId);
  };
/*
  var optionsState = [
    { value: 1, label: "TamilNadu" },
    { value: 2, label: "Kerala" },
    { value: 3, label: "Karnataka" },
  ];
*/
  const [stateIdSelect, setStateIdSelect] = useState({});
  const handleStateSelect = async(e) => {
    console.log("handleStateSelect called ...", e);
    setStateIdSelect(e);

    // var stateIdInput = e.value;
    //var stateNameInput = e.label;
    var [retStateDist, distArrRet] = await getDistrictList(e.value, e.label);
    if ( retStateDist ){
      setDistrictIdSelect(distArrRet[0]);
      setOptDist(distArrRet);
    }
  };
 

 
  
  const [optionsState, setOptionsState] = useState([]);
 
  const [districtIdSelect, setDistrictIdSelect] = useState({});
  const handleDistrictSelect = (e, data) => {
    console.log("handleDistrictSelect called ...", e);
    setDistrictIdSelect(e);
  };
 
  const handleEdit = async (e) => {
    console.log("handle add clicked : ", e);
    setIsReadOnly(false);
  };

  const handleDelete = async (e) => {
    console.log("handle delete clicked : ", e);
  };

  const handleReset = async (e) => {
    console.log("handle reset clicked : ", e);
    navigate(0);
  };
  const handleBack = async (e) => {
    console.log("handle Back called : ", e);
    navigate(-1);
  };
  const handleCloseDialog = () => {
    setShowDialog(false);
    navigate("/ListSuppliers");
  };
  
  


  const getSupplierForLoad = async() =>  {
    console.log("get supplier for load called ... ");

  
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(client.domain + "/srm/getSupplier?id=" + state.passed_element.id, { headers }, { withCredentials: false });
      console.log("Response from server getSupplierForLoad : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getSupplierForLoad : ", res.data.detail.data);
        console.log("Response message from server getSupplierForLoad (Products) : ", res.data.detail.data.products);

        //setFormValues({...formValues, ["products"]: res.data.detail.data});
        //formValues["products"] = res.data.detail.data.products;
        setFormValues(res.data.detail.data);
        setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");
       
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data);
         
        }
      }
    }
  }
  
  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::addUpdateSupplier called ##########################"
      );

      if (state === null) {
        navigate("/listSuppliers");
      } else {
        console.log("PASSED STATE VALUE IN Supplier Add/Update page : ", state);
        console.log("Inside else for view or edit ...");
        //setFormValues(state.passed_element);
        if (state.action === "add") {
          //setIsReadOnly(true);
        }
        else {
          setFormValues(state.passed_element);
        }
        

        ( async() => {
          //await getDistrictListWrapper();
          var stateIdTmp = 1;
          if ( state.action === 'view' || state.action === 'edit') {
            stateIdTmp = state.passed_element.state_id;
          }
          var [retStateDist, distArrRet] = await getDistrictList(stateIdTmp);
          if ( retStateDist) {
            setOptDist(distArrRet);
            if ( state.action === 'add') {
            setDistrictIdSelect(distArrRet[0]);
            }
            else {
              var findDist = distArrRet.find(element => element.value === state.passed_element.district_id);
              if ( findDist !== undefined ) {
                console.log("++++++++++++++++++------------------- FOUND DISTRICT : ", findDist);
                setDistrictIdSelect({value: state.passed_element.district_id, label: findDist.label});
              }
            }
          }

          var country_id = appGlobal.COUNTRY_ID;
          var [retStateStList, stateArrRet] = await getStateList(country_id);
          if ( retStateStList ) {
            setOptionsState(stateArrRet);
            if ( state.action === 'add' ) {
              setStateIdSelect(stateArrRet[0]);
            }
            else {
              var findState = stateArrRet.find(element => element.value === state.passed_element.state_id);
              if ( findState ) {
                console.log("STATE FOUND OBJECT : ", findState);
                setStateIdSelect({value: state.passed_element.state_id, label: findState.label});
              }
          }
          }
          
          var [retStateProd, productArrRet] = await getProductBuy();
          if ( retStateProd) {
            setProductListApi(productArrRet);
          }
        if (state.action === "add") {
          setIsReadOnly(true);
          setToRender(true);
        }
        else {
          //setFormValues(state.passed_element);
          getSupplierForLoad();
        }

        if (state.action === "add") {
          setIsReadOnly(false);
        }
        })();
        
        setToRender(true);
        console.log("newObj :", formValues);
        
      }
     
      initialized.current = true;
    }
  }, []);
  const callUpdateApi = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var apiToCall = "";
      if (state.action === "add") {
        apiToCall = "/srm/addSupplier";
      } else if (state.action === "view") {
        apiToCall = "/srm/updateSupplier";
      } else {
        apiToCall = "/srm/addSupplier";
      }

      var prodSendArr = [];
    
      formValues["products"].map((product) => {
        console.log("IND ITEM : ", product);
        var prodSendInd = { id: product.id }
          prodSendArr.push(prodSendInd);
      })

      console.log("Prod list to send : ", prodSendArr);

      var reqParam = {
        id: formValues["id"],
        name: formValues["name"] || "",
        display_name: formValues["name"] || "",
        contact_person: formValues["contact_person"] || "",
        contact_person_number: formValues["contact_person_number"] || "",
        gst_no: formValues["gst_no"],
        phone: formValues["phone"],
        contact_number_primary: formValues["contact_number_primary"] || "",
        Contact_number_secondary: formValues["contact_number_secondary"] || "",
        email: formValues["email"] || "",
        website: formValues["website"] || "",
        address_line1: formValues["address_line1"] || "",
        address_line2: formValues["address_line2"],
        district_id: districtIdSelect.value,
        pincode: formValues["pincode"],
        products: prodSendArr,
      };
      var headers = client.headers;
      var res = await axios.post(
        client.domain + apiToCall,
        reqParam,
        { headers },
        { withCredentials: false }
      );
      
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        setShowMsg(res.data.detail.data.message);
        setShowMsgTitle("Success");
        setShowDialog(true);
        setSubmitAction(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime" );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log("Request failed due to ", error.response.data.detail.res_data); 
        }
      }
      setSubmitAction(false);
    }
  };
  const handlePostSaveOk = () => {
    console.log("user confirmed to supplier added ...");
    //console.log("user selected id for action is : ", selectedId);

    // call update API
    // updateCustomerStatus(selectedId);
    setShowDialog(false);
    navigate("/listSuppliers");
  };
  const handleSave = async (e) => {
    e.preventDefault();
    console.log("Handle Click save called : ", e);
    console.log("Formvalues : ", formValues);
    console.log("Product array : ", productSelectedList);
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    if (validateErr === false) {
      setSubmitAction(true);
      callUpdateApi();
    }
  };

  //console.log("formvalues FULL : ", formValues);

  const handleClose = async (e) => {
    setShow(false);
    navigate(-1);
  };

  console.log("<<<<<<<<<<<<<<<<<<<<<< Product list : ", formValues["products"]);

  var dd;
  return (
    
    <Container>
        <SidebarMain />
        { toRender ?
        (
        <Form>
          <Row>
          <Col xs={4} md={4} sm={4} lg={4}>  
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>Supplier</span>
            &nbsp;&nbsp;
             
             { state.action !== 'add' &&
            <Button key={`active-inactive-label`} variant={state.passed_element.is_active ? "outline-success" : "outline-danger"} size="sm"><span style={{cursor: "default", fontWeight: "500"}}>{state.passed_element.status}</span></Button>
             }
            </Col>

            <Col
              xs={8}
              md={8}
              sm={8}
              lg={8}
              className="float-end justify-content-end"
            >
              <div className="d-flex justify-content-end">
              <Button key={`back-btn`} variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                  <BsArrowLeftCircle
                    key={`back-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                   
                    name={`back-icon-patient-name`}
                    id={`edit-icon-patient-id`}
                  />
                </Button>
                &nbsp;&nbsp;
                {(state.action === "edit" || state.action === "view") && (
                  <>
                <Button key={`edit-btn`} variant="primary" className="btn btn-primary btn-md"  onClick={(e) => handleEdit(e)}>
                  <BsPencilSquare
                    key={`edit-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                  
                    name={`edit-icon-paBiSolidEditAlttient-name`}
                    id={`edit-icon-doctor-id`}
                  />
                </Button>
                </>
                )}
                &nbsp;&nbsp;
                
              </div>
            </Col>
            <p></p>
          </Row>

          <div className="ui-divider"></div>
          <div className="ui-form">
          <hr align="center" />
            <Row>
            <Col xs={4} md={4} sm={4} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Supplier Name"
                    onChange={handleOnChange}
                    value={formValues["name"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                  </Form.Group>
                  <p className="error-msg">{formErrors.name}</p>
              </Col>
             
              
              <Col xs={4} md={4} sm={4} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label>GST / PAN Number</Form.Label>
                  <Form.Control
                    type="text"
                    id="gst_no"
                    name="gst_no"
                    placeholder=" GST / PAN Number"
                    onChange={handleOnChange}
                    value={formValues["gst_no"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                 </Form.Group>
                  <p className="error-msg">{formErrors.gst_no}</p>
              </Col>
              <Col xs={4} md={4} sm={4} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Phone</Form.Label>
                  <Form.Control
                    type="text"
                    id="phone"
                    name="phone"
                    //className="mobNum"
                    placeholder="Phone Number"
                    onChange={handleOnChange}
                    readOnly={isReadOnly}
                    maxLength={10}
                    value={formValues["phone"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.phone}</p>
              </Col>
              </Row>
            <h5>Contact Details</h5>
            <hr align="center" />
            <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label >
                    Street / Location
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="address_line1"
                    name="address_line1"
                    placeholder="Street / Location"
                    onChange={handleOnChange}
                    readOnly={isReadOnly}
                    value={formValues["address_line1"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.address_line1}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">
                    City / Town
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="address_line2"
                    name="address_line2"
                    placeholder="City / Town"
                    onChange={handleOnChange}
                    readOnly={isReadOnly}
                    value={formValues["address_line2"]}
                    style={{
                      border: !isReadOnly && "1px solid gray"
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.address_line2}</p>
              </Col>
              <Col xs={2} sm={2} md={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>District</Form.Label>
                  <Select
                    id="district"
                    name="district"
                    value={{
                      value: districtIdSelect.value,
                      label: districtIdSelect.label,
                    }}
                    options={optDist}
                    defaultValue={{
                      value: districtIdSelect.value,
                      label: districtIdSelect.label,
                    }}
                    onChange={handleDistrictSelect}
                    isDisabled={isReadOnly}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.districtId}</p>
              </Col>

              <Col xs={2} sm={2} md={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>State</Form.Label>
                  <Select
                    id="state"
                    name="state"
                    value={{
                      value: stateIdSelect.value,
                      label: stateIdSelect.label,
                    }}
                    defaultValue={{
                      value: stateIdSelect.value,
                      label: stateIdSelect,
                    }}
                    options={optionsState}
                    onChange={handleStateSelect}
                    isDisabled={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.setStateId}</p>
              </Col>

              <Col xs={2} sm={2} md={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label className="required field" >Pincode</Form.Label>
                  <Form.Control
                    type="text"
                    id="pincode"
                    name="pincode"
                    placeholder="Pincode"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    maxLength={6}
                    value={formValues["pincode"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>

                <p className="error-msg">{formErrors.pincode}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Contact Person
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="contact_person"
                    name="contact_person"
                    placeholder="Contact Person"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["contact_person"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.contact_person}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Contact Number
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="contact_person_number"
                    name="contact_person_number"
                    //className="mobNum"
                    placeholder="Contact Number"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["contact_person_number"]}
                    maxLength={10}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.contact_person_number}</p>
                </Col>
             
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Email"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["email"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.email}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Website</Form.Label>
                  <Form.Control
                    type="text"
                    id="website"
                    name="website"
                    placeholder="Website"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["website"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.website}</p>
              </Col>
            </Row>
              <h5>Supplying Products</h5>
              <hr align="center" />
            
            
             <Row>
              {productListApi.map((productInd, idx) => (
                <Col xs={2} md={2} sm={2}>
                <div key={`product-item-div-key-${idx}`} className="field">
                  <Form.Check // prettier-ignore
                    key={`product-item-ctrl-key-${idx}`}
                    id={productInd.id}
                    defaultChecked={"products" in formValues && formValues["products"].find(element => element.id === productInd.id) !== undefined ? true : false }
                    checked={"products" in formValues && formValues["products"].find(element => element.id === productInd.id) !== undefined ? true : false }
                    name="products"
                    type="Checkbox"
                    disabled={isReadOnly}
                    label={productInd.name}
                    //label={"products" in formValues && formValues["products"].find(element => element.id === productInd.id) !== undefined ? 'checked': productInd.name}
                    onChange={(e) => handleCheckBoxOnChange(e)}
                  />
                </div>
              </Col>
              ))
              }
              <p className="error-msg">{formErrors.products}</p>
              </Row>
            
           { !isReadOnly &&
           <> 
            <Row>
            <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ disply: "flex", justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleReset(e)}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      type="submit"
                      id="save"
                      onClick={(e) => handleSave(e)}
                      disabled={submitAction ? true: false}
                    >
                      { submitAction &&
                      <>
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                        <span className="visually-hidden">Loading...</span>
                      </>
                        }
                        <span>Save</span>
                      
                  </Button>
                  </div>
                </Col>
            </Row>
            </>
          }
          
          
            
             <Modal
              show={showDialog}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>{showMsgTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{showMsg}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handlePostSaveOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          
           
          
            <br></br>
          </div>
        </Form>
        )
        :
        <div className="container-spinner">
        <div className="vertical-center-spinner">
         {/*<Button variant="primary" disabled>*/}
         <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
            <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{ width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
                <span className="visually-hidden">Loading...</span>
              </Spinner>
   
         {/*</Button>*/}
         </div>
         </div>
      }
        </Container>
  )

      };

export default AddUpdateSupplier;
