import React from "react";
import { Button } from "react-bootstrap";
import FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import {
  Container,
  Row,
  Col,
} from "react-bootstrap";
import CsvGenerate from "../pages/CsvGenerate";
const ExportCSVExcel = ({ csvData, fileName, wscols }) => {
  // ******** XLSX with object key as header *************
  // const fileType =
  //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  // const fileExtension = ".xlsx";

  // const exportToCSV = (csvData, fileName) => {
  //   const ws = XLSX.utils.json_to_sheet(csvData);
  //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //   const data = new Blob([excelBuffer], { type: fileType });
  //   FileSaver.saveAs(data, fileName + fileExtension);
  // };

  // ******** XLSX with new header *************
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const Heading = [
    {
      inv_no: "Inv.No",
      inv_date: "Date",
      customer_name: "Customer",
      gst: "GST IN",
      sub_total: "Sub Total",
      cgst: "CGST",
      sgst: "SGST",
      Total_amount: "Total Amount",
    }
  ];

  const exportToCSV = (csvData, fileName, wscols) => {
    console.log(Heading);
    /*const ws = XLSX.utils.json_to_sheet(Heading, {
      header: ["firstName", "lastName", "email", "address", "postcode"],
      skipHeader: true,
      origin: 0 //ok
    });*/
    var ws = XLSX.utils.aoa_to_sheet([
     ["Customer Invoice"]
      //["sdaaaaaaaaaaaaaaaaaaaaaaa: 111111111111111111111"]
    ]);
    ws["A1"].s = { fill: { fgColor: { rgb: "BDD7EE" } } };
    ws["A1"].l = {
      Target: "https://sheetjs.com",
      Tooltip: "Find us @ SheetJS.com!"
    };
    /*ws["A1"].l = {
      Target: "https://sheetjs.com",
      Tooltip: "Find us @ SheetJS.com!"
    };*/
    //ONLY Data Added here
    XLSX.utils.sheet_add_json(ws, csvData, {
      header: ["inv_no", "inv_date", "customer_name", "gst", "sub_total", "cgst", "sgst", "total_amount"],
      skipHeader: false,
      origin: "A6" //ok -1
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Container>
    <Row>
    <Col 
    xs={12}
    md={12}
    sm={12}
    lg={12}
    className="d-flex justify-content-end float-end">
      {/* 
    <Button
      variant="warning"
      onClick={(e) => exportToCSV(csvData, fileName, wscols)}>
      Export XLSX
    </Button>
    */} 
    
    <CsvGenerate dataPass={csvData}/> 
    
    </Col> 
    
    </Row>
    </Container>   
  );


  
  
};

export default ExportCSVExcel;