import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Form, Button, Image } from "react-bootstrap";
import { withRouter } from "react-router";
import Sidebar2 from './Sidebar2';

import logo_avn from '../assets/images/logo_avs.png';

import orderLogo from '../assets/images/order_logo.jpg';
import crmLogo from '../assets/images/crm_logo.png';
import inventoryLogo from '../assets/images/inventory_logo.jpg';
import employeeLogo from '../assets/images/employee_logo.png';
import vehicleLogo from '../assets/images/vehicle_logo.png';
import accountLogo from '../assets/images/account_logo.png';
import misLogo from '../assets/images/mis_logo.png';
import userManagementLogo from '../assets/images/user_management_logo.png';
import settingsLogo from '../assets/images/settings_logo.png';

import '../App.css';
import MenuCrm from "./MenuCrm";

const DashboardCrm = (props) => {
    const getOrderBoard = async (e) => {
        console.log("getOrderBoard clicked : ", e);
    }

    const getDCBoard = async (e) => {
        console.log("getDCBoard clicked : ", e);
    }

    const getInventoryBoard = async (e) => {
        console.log("getInventoryBoard clicked : ", e);
    }

    return (
        <>
            <Container>
                <MenuCrm />
                <div>
                </div>
            </Container>
        </>
    )
}

export default DashboardCrm;