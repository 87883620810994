import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Form, Button, Image } from "react-bootstrap";
import { withRouter } from "react-router";
import Sidebar2 from './Sidebar2';

import logo_avn from '../assets/images/logo_avs.png';

import orderLogo from '../assets/images/order_logo.jpg';
import crmLogo from '../assets/images/crm_logo.png';
import inventoryLogo from '../assets/images/inventory_logo.jpg';
import employeeLogo from '../assets/images/employee_logo.png';
import vehicleLogo from '../assets/images/vehicle_logo.png';
import accountLogo from '../assets/images/account_logo.png';
import misLogo from '../assets/images/mis_logo.png';
import userManagementLogo from '../assets/images/user_management_logo.png';
import settingsLogo from '../assets/images/settings_logo.png';

import '../App.css';
import { client } from '../config/Config';

const Dashboard = (props) => {
    const getOrderBoard = async (e) => {
        console.log("getOrderBoard clicked : ", e);
    }

    const getDCBoard = async (e) => {
        console.log("getDCBoard clicked : ", e);
    }

    const getInventoryBoard = async (e) => {
        console.log("getInventoryBoard clicked : ", e);
    }

    //var delimiter = "#";
    console.log("DELIMITER FOR URL : ", client.urlDelimiter);
    return (
        <>
            <Container>
                <Sidebar2 />
                <div>
                    <Row className="justify-content-md-center">
                        <Col xs={4} sm={2} md={2} lg={2}>
                        <a href={`${client.urlDelimiter}dashboardCrm`}>
                            <div roundedCircle className="rounded-circle">                                
                                    <Image width="98" height="98" src={crmLogo} roundedCircle className="border border-3 rounded-circle border-radius-sm" style={{color:"red"}} />
                            </div>
                            <label className="text-center" style={{paddingLeft: 10, cursor: 'pointer'}}>Customer<br/>Management</label>
                            </a>
                        </Col>

                        <Col xs={4} sm={2} md={2} lg={2}>
                        {/*<a href={`${client.urlDelimiter}dashboardOms`}>*/}
                        <a href={`${client.urlDelimiter}listOrders`}>
                            <div roundedCircle >
                                <Image width="98" height="98" src={orderLogo} roundedCircle className="border border-3 rounded-circle border-radius-sm" style={{background: "red", color:"red"}} />
                            </div>
                            <label className="text-center" style={{paddingLeft: 10, cursor: 'pointer'}}>Order<br/> Management</label>
                            </a>
                        </Col>

                        <Col xs={4} sm={2} md={2} lg={2}>
                        <a href={`${client.urlDelimiter}dashboardIms`}>
                            <div roundedCircle className="rounded-circle">
                                <Image width="98" height="98" src={inventoryLogo} roundedCircle className="border border-3 rounded-circle border-radius-sm" />
                            </div>
                            <label className="text-center" style={{paddingLeft: 10, cursor: 'pointer'}}>Inventory<br/>Management</label>
                        </a>
                        </Col>


                    </Row>

                    <br></br>

                    <Row className="justify-content-md-center">
                        <Col xs={4} sm={2} md={2} lg={2}>
                        <a href={`${client.urlDelimiter}dashboardEms`}>
                            <div roundedCircle className="rounded-circle">
                                <Image width="98" height="98" src={employeeLogo} roundedCircle className="border border-3 rounded-circle border-radius-sm"/>
                            </div>
                            <label className="text-center" style={{paddingLeft: 10, cursor: 'pointer'}}>Employee<br/>Management</label>
                        </a>
                        </Col>

                        <Col xs={4} sm={2} md={2} lg={2}>
                        <a href={`${client.urlDelimiter}dashboardVms`}>
                            <div roundedCircle >
                                <Image width="98" height="98" src={vehicleLogo} roundedCircle className="border border-3 rounded-circle border-radius-sm" />
                            </div>
                            <label className="text-center" style={{paddingLeft: 10, cursor: 'pointer'}}>Vehicle<br/>Management</label>
                        </a>
                        </Col>

                        <Col xs={4} sm={2} md={2} lg={2}>
                        <a href={`${client.urlDelimiter}dashboardAccounts`}>
                            <div roundedCircle >
                                <Image width="98" height="98" src={accountLogo} roundedCircle className="border border-3 rounded-circle border-radius-sm" />
                            </div>
                            <label className="text-center" style={{paddingLeft: 20, cursor: 'pointer'}}>Accounts</label>
                        </a>
                        </Col>


                    </Row>

                    <br></br>

                    <Row className="justify-content-md-center">
                        <Col xs={4} sm={2} md={2} lg={2}>
                        <a href={`${client.urlDelimiter}dashboardMis`}>
                            <div roundedCircle className="rounded-circle">
                                <Image width="98" height="98" src={misLogo} roundedCircle className="border border-3 rounded-circle border-radius-sm"/>
                            </div>
                            <label className="text-center" style={{paddingLeft: 10, cursor: 'pointer'}}>Management<br/>Reports</label>
                        </a>
                        </Col>

                        <Col xs={4} sm={2} md={2} lg={2}>
                        <a href={`${client.urlDelimiter}dashboardUms`}>
                            <div roundedCircle >
                                <Image width="98" height="98" src={userManagementLogo} roundedCircle className="border border-3 rounded-circle border-radius-sm" />
                            </div>
                            <label className="text-center" style={{paddingLeft: 10, cursor: 'pointer'}}>User<br/>Management</label>
                        </a>
                        </Col>

                        <Col xs={4} sm={2} md={2} lg={2}>
                        <a href={`${client.urlDelimiter}dashboardSettings`}>
                            <div roundedCircle >
                                <Image width="98" height="98" src={settingsLogo} roundedCircle className="border border-3 rounded-circle border-radius-sm" />
                            </div>
                            <label className="text-center" style={{paddingLeft: 20, cursor: 'pointer'}}>Settings</label>
                        </a>
                        </Col>


                    </Row>

                </div>
            </Container>
        </>
    )
}

export default Dashboard;