import React, { Component, Fragment } from 'react';
import {PDFViewer} from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

import Order from '../components/reports/Order';
import invoice from '../data/invoice';



// Create Document Component
const OrderDocument = (props) => {
  console.log("FULL PROPS : ", props);
  //console.log("PROPS DATA COMPANY : ", props.order_data.company_info);
  //console.log("PROPS DATA DC : ", props.order_data.dc_info);
  //console.log("PROPS DATA ORDER : ", props.order_data.order_info);
  return (
    <Fragment>
      <Order invoice={invoice} orderLoad={props.order_data}/>
    </Fragment>
  )
}

export default OrderDocument;