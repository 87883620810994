import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  Spinner,
  Modal,
  Image
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
import Sidebar from "./Sidebar";
import Sidebar2 from "./Sidebar2";
import Topmenu from "./Topmenu";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
//import DateTimeField from "react-bootstrap-datetimepicker"
import DateTimePicker from "react-datetime-picker";

import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client, ConfigMinValue, configMaxValue, appGlobal } from "../config/Config";
import axios from "axios";

import { useLocation } from "react-router-dom";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import { BsCartX, BsArrowLeftCircle, BsFillXCircleFill, BsFillPrinterFill, BsPencilSquare } from "react-icons/bs";
import SidebarMain from "./SidebarMain";
import {getDistrictList, getStateList, getVehicleList, getDriverList, getProductsSell, getProductBuy, formatDateForDC } from "./utils";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import logo_avinaerp from '../assets/images/logo_avinaerp.png';

import { MdDelete } from "react-icons/md";

const AddUpdateDirectDC = (props) => {
  const initialized = useRef(false);
  const navigate = useNavigate();
  const { state } = useLocation();

  //console.log("Product List : ", state.prod_list);
 
  console.log("STATE VALUE : ", state);
  /*
  if (state === null) {
    navigate("/");
  } else {
    console.log("PASSED STATE VALUE IN DC Add/Update page : ", state);
  }
  */
  
  var final_total = 0.00;
  var dc_products_total = 0.00;
  const [passedValue, setPassedValue] = useState({});
  const [date, setDate] = useState(new Date());

  const [selectedDCItemRow, setSelectedDCItemRow] = useState(0);
  const [selectedProductInfo, setSelectedProductInfo] = useState({});
  //setPassedValue(state);

  /*
    let initialItems = [
      {id:1, product: {value:3, label:"M15"}, price: "4900", quantity: 5, "total_item_price": "24500", "gst": "4410", "total_price":"28910", gst:{cgst: 2205.00, sgst:2205.00, igst:0.00, totGst: 4410.00}}, 
      {id:1, product: {value:6, label:"M25"}, price: "4800", quantity: 7, "total_item_price": "215001", "gst": "38701", "total_price":"253701", gst:{cgst: 2205.00, sgst:2205.00, igst:0.00, totGst: 4410}}
    ]
    */

  /*
    const itemProdOptions = [
      { value: 1, label: 'M5', stock_id: 1 },
    ];
    */
  let initialItems = [];

  /*
    const options = [
        { value: 1, label: 'M5', stock_id: 1 },
        { value: 2, label: 'M7.5', stock_id: 2 },
        { value: 6, label: 'M10', stock_id: 3 },
        { value: 7, label: 'M15', stock_id: 4 },
        { value: 8, label: 'M20', stock_id: 5 },
        { value: 9, label: 'M25', stock_id: 6 },
        { value: 10, label: 'M30', stock_id: 7 }
    ];
    */
/*
  let product_list = [
    {
      product_id: 1,
      //stock_id: 1,
      product_name: "M5",
      unit_price: 3900,
      cgst: 9.0,
      sgst: 9.0,
      igst: 0.0,
    },
    {
      product_id: 2,
      stock_id: 13,
      product_name: "M7.5",
      unit_price: 3900,
      cgst: 9.0,
      sgst: 9.0,
      igst: 0.0,
    },
    {
      product_id: 6,
      stock_id: 14,
      product_name: "M10",
      unit_price: 4200,
      cgst: 9.0,
      sgst: 9.0,
      igst: 0.0,
    },
    {
      product_id: 7,
      stock_id: 15,
      product_name: "M15",
      unit_price: 4600,
      cgst: 9.0,
      sgst: 9.0,
      igst: 0.0,
    },
    {
      product_id: 8,
      stock_id: 16,
      product_name: "M20",
      unit_price: 5000,
      cgst: 9.0,
      sgst: 9.0,
      igst: 0.0,
    },
    {
      product_id: 9,
      stock_id: 17,
      product_name: "M25",
      unit_price: 5400,
      cgst: 9.0,
      sgst: 9.0,
      igst: 0.0,
    },
    {
      product_id: 10,
      stock_id: 18,
      product_name: "M30",
      unit_price: 5800,
      cgst: 9.0,
      sgst: 9.0,
      igst: 0.0,
    },
    {
      product_id: 13,
      stock_id: 19,
      product_name: "M40",
      unit_price: 6000,
      cgst: 9.0,
      sgst: 9.0,
      igst: 0.0,
    },
  ];
  */
  /*

  const optionsDistrict = [
    { value: 1, label: "Coimbatore" },
    { value: 2, label: "Tiruppur" },
    { value: 3, label: "Erode" },
    { value: 4, label: "Karur" },
    { value: 5, label: "Namakkal" },
    { value: 6, label: "Ooty" },
    { value: 7, label: "Salem" },
  ];

  const optionsState = [
    { value: 1, label: "Tamil Nadu" },
    { value: 2, label: "Kerala" },
    { value: 3, label: "Karanataka" },
    { value: 4, label: "Andra Pradesh" },
    { value: 5, label: "Telungana" },
    { value: 6, label: "Pondichery" },
  ];

  const vehicleArr = [
    { value: 4, label: "TN 20 AS 4152" },
    { value: 5, label: "TN 18 C 8033" },
    { value: 6, label: "TN 39 DX 7296" },
    { value: 7, label: "TN 39 DX 7257" },
    { value: 8, label: "TN 39 DX 9927" },
    { value: 9, label: "TN 39 DX 2599" },
  ];

  const driverArr = [
    { value: 3, label: "Prabhu" },
    { value: 4, label: "Deva" },
    { value: 5, label: "Mathesh" },
    { value: 6, label: "Murukeshan" },
    { value: 7, label: "Shanmugaraj" },
    { value: 8, label: "Ayyappan" },
    { value: 9, label: "Azhagu Raja" },
    { value: 10, label: "Balasubramani" },
  ];
  */
  const optionsSites = [
    { value: 1, label: "(Anna Nagar, Dindigul, TamilNadu, 624215)" },
    { value: 2, label: "(Avinashi, Tiruppur, TamilNadu, 641654)" },
    { value: 3, label: "(Attur, Salem, TamilNadu, 613253)" },
    { value: 4, label: "(Gandhi Nagar, Madurai, TamilNadu, 625001)" },
    
  ];
  const optionsProdLoad = [];

  const [optionDriver, setOptionDriver] = useState([]);
  const [optionVehicle, setOptionVehicle] = useState([]);

  const [vehicleLoad, setVehicleLoad] = useState(false);
  const [driverLoad, setDriverLoad] = useState(false);

  const [submitAction, setSubmitAction] = useState(false);
  const [submitPrintAction, setSubmitPrintAction] = useState(false);
  //const [showDialog, setShowDialog] = useState(false);
  //const [showMsgBody, setShowMsgBody] = useState("");
  //const [showMsgTitle, setShowMsgTitle] = useState("");
  //const [dialogResStatus, setDialogResStatus] = useState("success");
  const [customerSearchText, setCustomerSearchText] = useState("");
  const [locationSearchText, setLocationSearchText] = useState("");
  const [isReadOnly, setIsReadOnly] = useState(true);

  const [searchedCustomer, setSearchedCustomer] = useState({});
  const [searchedLocation, setSearchedLocation] = useState({});
  const [productMaster, setProductMaster] = useState([]);
  const [productList, setProductList] = useState();

  const [showAlert, setShowAlert] = useState();
  const [alertPurpose, setAlertPurpose] = useState('init');
  const [alertTitle, setAlertTitle] = useState('Success');
  const [alertBody, setAlertBody] = useState('DC Saved Successfully');

  const handleAlertOk = async() => {
    setShowAlert(false);
    console.log("handleSaveOk clicked ...");
    if ( alertPurpose === 'success' ) {
      if ( state === null ) {
        navigate('/');
        setAlertPurpose('init');
      }
      else {
        if ( state.action === 'edit' || state.action === 'view' ) {
          navigate('/listDCs');
          setAlertPurpose('init');
        }
        else {
          //navigate('/addUpdateDirectDC');
          navigate(0);
        }
      }
    }
    else if ( alertPurpose === 'deleted' ) {
      navigate('/listDCs');

    }
    else if ( alertPurpose === 'delete') {
      deleteDirectDc();
    }
  }

  const handleCloseDialog = async() => {
    setShowAlert(false);
    console.log("handleCloseDialog clicked ...");
    if (alertPurpose === 'success' ) {
      if ( state === null ) {
        navigate('/');
        setAlertPurpose('init');
      }
      else {
        //navigate('/addUpdateDirectDC');
        navigate(0);
      }
    }
    else if ( alertPurpose === 'delete' ) {
      // No action
    }
  }


  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");

    /*
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("-");
    */
  }

  const formateTime = (currTime) => {
    console.log("<<<<<<<<<<<<<<<<<<<< ORIGINAL DATA >>>>>>>>>>>>>>>>>>> : ", currTime);
    var h = (currTime.getHours() < 10 ? "0" : "") + currTime.getHours();
    var m = (currTime.getMinutes() < 10 ? "0" : "") + currTime.getMinutes();
    var hrMin = h + ":" + m;
    return hrMin;
  };

  //const intialValues = {company:"", address: "", city:"", zip:"", gst_number: "", phone: "", district: "", state: "", product:{value:"m15", label:"M15"}};
  var endTimeTmp = new Date();
  const dateCopy = new Date(endTimeTmp.getTime());
  var minForFiiling = 10;
  var endTimeMod = dateCopy.setMinutes(dateCopy.getMinutes() + minForFiiling);
  console.log("endTimeMod: ", endTimeMod);
  var endTimeMod2 = new Date(endTimeMod);
  console.log("endTimeMod2: ", endTimeMod2);
  const intialValues = {
    customer_id: 0,
    contact_person: "",
    contact_number: "",
    email: "",
    address1: "",
    address2: "",
    pincode: "",
    website: "",
    product: { value: "m15", label: "M15" },
    district_id: 1,
    state_id: 1,
    dc_date: formatDate(new Date()),
    loading_start_time: formateTime(new Date()),
    loading_end_time: formateTime(endTimeMod2),
    driverId: 1,
    vehicleId: 1,
    pumping_charges: 0.00,
    diesel_charges: 0.00,
    others_charges1: 0.00,
    ewaybill_number: "",
    customer_name:"",
    site: "",
    sitelist_id: 1,
    amount: 0.00,
    quantity: 1.00,
    total_quantity: 0.00,
    existing_quantity: 0.00,
    unit_price:0.00,
    others_reason1: "",
    //is_product_cc: false,
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [productInfo, setProductInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [stateIdSelect, setStateIdSelect] = useState({});

  const [districtIdSelect, setDistrictIdSelect] = useState({});
  const [siteIdSelect, setSiteIdSelect] = useState({});

  const [vehicleIdSelect, setVehicleIdSelect] = useState({});

  const [driverIdSelect, setDriverIdSelect] = useState({});

  const [optDist, setOptDist] = useState([]);
  const [optSite, setOptSite] = useState([]);
  const [optState, setOptState] = useState([]);

  const [optVehicle, setOptVehicle] = useState([]);

  const [optDriver, setOptDriver] = useState([]);

  const [optionsSearch, setOptionsSearch] = useState([]);
  const [optionsSearchLoc, setOptionsSearchLoc] = useState([]);

  const [companySearchText, setCompanySearchText] = useState("");
  const [showConfigDialog, setShowConfigDialog] = useState(false);

  const [searchedCompany, setSearchedCompany] = useState("");

  const [optionsProd, setOptionsProd] = useState([]);

  const [validateError, setValidateError] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [showMsg, setShowMsg] = useState("");
  const [msgType, setMsgType] = useState("success");
  const [show, setShow] = useState(false);
  const [isEditable, setIsEditable] = useState(true);
  const [editAction, setEditAction] = useState(false);
  const [selectedCompositionId, setSelectedCompositionId] = useState(-1);
  const [compositionUpdate, setCompositionUpdate] = useState(false);

  var validateErr = false;

  //type ValuePiece = Date | null;
  //type Value = ValuePiece | [ValuePiece, ValuePiece];

  //const [value, onChange] = useState<Value>(new Date());

  //const [searchText, setSearchText] = useState('');
  function convertDatePickerTimeToMySQLTime(str) {
    var month, day, year, hours, minutes, seconds;
    var date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    hours = ("0" + date.getHours()).slice(-2);
    minutes = ("0" + date.getMinutes()).slice(-2);
    seconds = ("0" + date.getSeconds()).slice(-2);

    var mySQLDate = [date.getFullYear(), month, day].join("-");
    var mySQLTime = [hours, minutes, seconds].join(":");
    return [mySQLDate, mySQLTime].join(" ");
  }

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

    const toSqlDatetime = (inputDate) => {
    const date = new Date(inputDate);
    const dateWithOffest = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    return dateWithOffest.toISOString().slice(0, 19).replace("T", " ");
  };

  const onChangeDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    console.log("Date onChange DATE called ...", e.currentTarget.value);

    /*
    var formattedDt = toSqlDatetime(e);
    setFormValues({ ...formValues, ["dc_date"]: formattedDt });
    console.log("Formatted str DC date : ", formattedDt);
    */
    if ( formValues["customer_id"] && items[selectedDCItemRow].product_id && formValues["address1"] ) {
      console.log("Customer Id : %s, Product Id :%s, location : %s", formValues["customer_id"], items[selectedDCItemRow].product_id, formValues["address1"]);
      var dc_date_time = e.currentTarget.value + " " + formValues["loading_start_time"];
      getTotVolPerCustPerProd(formValues["customer_id"], items[selectedDCItemRow].product_id, formValues["address1"], dc_date_time);
    }
    setFormValues({ ...formValues, ["dc_date"]: e.currentTarget.value });
  };

  const onChangeLoadingStartTime = (e) => {
    console.log("Date onChange Loading time called ...", e);
    console.log("Date onChange Loading time TIME called START TIME ...", e.target.value);
    /*
    var formattedDt = toSqlDatetime(e);

    setFormValues({ ...formValues, ["loading_start_time"]: formattedDt });
    console.log("Formatted str Loading time : ", formattedDt);
    */
    var formattedTime = e.target.value;
    if ( e.target.value.length > 5) {
      formattedTime = formateTime(e.target.value);
    }
    console.log("FORMATTED TIME 501 : ", formattedTime);
    setFormValues({ ...formValues, ["loading_start_time"]: formattedTime });
  };

  const onChangeLoadingEndTime = (e) => {
    console.log("Date onChange Loading time called ...", e);
    console.log("Date onChange Loading time TIME called END TIME ...", e.target.value);
    // var formattedDt = toSqlDatetime(e);
     //date: this.state.date.setMinutes((new Date()).getMinutes()+5)
     //var m = (currTime.getMinutes() < 10 ? "0" : "") + currTime.getMinutes() +10;

     var formattedTime = e.target.value;
     if ( e.target.value.length > 5) {
      formattedTime = formateTime(e.target.value);
     }
    console.log("FORMATTED TIME 501 : ", formattedTime);
    setFormValues({ ...formValues, ["loading_end_time"]: formattedTime });

    //console.log("Formatted str Loading time : ", formattedDt);
  };

  const handleValueChange = async (e, data) => {
    e.preventDefault();
    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    //setFormValues({ ...formValues, [name]: value });
   
    

    //console.log("Clicked data : ", data);
    
    if (name === "contact_number" || name === "pincode") {
      console.log("Inside check ******************");
      var valueTmp = Math.round(e.target.value.replace(/\D/g, ""));
      setFormValues({ ...formValues, [name]: valueTmp });
    }
    else  if (name === "pumping_charges" || name === "diesel_charges" || name === "others_charges1") 
    {
      console.log("Inside check ******************");
      //const numericRegExp = /^(?:[1-9]\d*|\d)$/;  
      if (e.target.value.match(appGlobal.numericRegExp)) {
        setFormValues({ ...formValues, [name]: e.target.value });
      }
      else {
        console.log("VALUE NOT MATCHED FOR ", name);
      }
    } 
    else if (name === "address1") {
      if ( value ) {
        console.log("Customer Id : %s, Product Id :%s, location : %s", formValues["customer_id"], items[selectedDCItemRow].product_id, value );
        var dc_date_time = formValues["dc_date"] + " " + formValues["loading_start_time"];
        getTotVolPerCustPerProd(formValues["customer_id"], items[selectedDCItemRow].product_id, value, dc_date_time);
      }
      setFormValues({ ...formValues, [name]: value });
    }
    else {
      setFormValues({ ...formValues, [name]: value });
    }
    

    var pumping_charges_tmp = 0.00;
    var diesel_charges_tmp = 0.00;
    var others_charges1_tmp = 0.00;

    if ( !isNaN(parseFloat(formValues["pumping_charges"])) ) {
      pumping_charges_tmp = parseFloat(formValues["pumping_charges"]);
    }
    if ( !isNaN(parseFloat(formValues["diesel_charges"])) ) {
      diesel_charges_tmp = parseFloat(formValues["diesel_charges"]);
    }
    if ( !isNaN(parseFloat(formValues["others_charges1"])) ) {
      others_charges1_tmp = parseFloat(formValues["others_charges1"]);
    }
    final_total = parseFloat(dc_products_total) + pumping_charges_tmp + diesel_charges_tmp + others_charges1_tmp;
    console.log("FINAL TOTAL at handleValueChange : ", final_total);
  };

  /*
  const handleSearch = (e, dat) => {
    console.log("Handled search method called ...", e); 
    getDistrictList(stateIdInput, stateNameInput);
  };
  */

  const handleDistrictSelect = (e, data) => {
    console.log("handleDistrictSelect called ...", e);

    setDistrictIdSelect(e);
  };

  const handleSiteSelect = (e, data) => {
    console.log("handleSiteSelect called ...", e);
    setSiteIdSelect(e);
    setFormValues({ ...formValues, ["address1"]: e.value});

    var dc_date_time = formValues["dc_date"] + " " + formValues["loading_start_time"];
    getTotVolPerCustPerProd(formValues["customer_id"], items[selectedDCItemRow].product_id, e.label, dc_date_time);
  };

  const handleVehicleSelect = (e, data) => {
    console.log("handleVehicleSelect called ...", e);

    setVehicleIdSelect(e);
  };

  const handleDriverSelect = (e, data) => {
    console.log("handleDriverSelect called ...", e);

    setDriverIdSelect(e);
  };

  const handleStateSelect = async(e, data) => {
    console.log("handleStateSelect called ...", e);
    setStateIdSelect(e);
    var [retStateDist, distArrRet] = await getDistrictList(e.value);
    if ( retStateDist) {
      setOptDist(distArrRet);
      setDistrictIdSelect(distArrRet[0]);
    }
  };

  const handleChange = (e, data) => {
    console.log("Handle Change clicked : ", e);
    console.log("Handle Change clicked target : ", e.target);
    console.log("Handle Change clicked current target : ", e.currentTarget);
    //console.log("HTML tag : ", data);
    const { name, value } = e.target;
    console.log("Captured Quantity name : ", name);
    console.log("Captured Quantity value : ", value);
    //setFormValues({...formValues, [name]: value});
    console.log("FORM VALUES at handleChange func : ", formValues);

    var currentId = name.substring(name.lastIndexOf("-") + 1);
    var nameOfElement = name.substring(0, name.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    console.log("<<<<<<<<<<<<<<<<< ITEM SELECTED ********** ", items[currentId]);
    var itemSelected = items[currentId];
    console.log("Product: ", itemSelected.product_id);
    console.log("Product: ", itemSelected.product_name);
    console.log("Unit Price : ", itemSelected.unit_price);
    console.log("Product cost : ", itemSelected.total_item_price);
    console.log("GST : ", itemSelected.gst);
    console.log("Total cost : ", itemSelected.total_price);
    console.log("Quantity : ", itemSelected.quantity);

    //console.log("Product Info : ", state.passed_element.prod_list);


    var eVal = { value : itemSelected.product_id, label: itemSelected.product_name };
    var dc_prod_cnt = calculateDCCount(eVal);
    console.log("total Delivered qty at handleChange: ", dc_prod_cnt);

    var prod_ordered_qty = getOrderedQty(eVal);
    console.log("Product Ordered Qty at handleChange: ", prod_ordered_qty);

    //var qty_balance_dc = prod_ordered_qty - dc_prod_cnt;
    //qty_balance_dc = Math.round((qty_balance_dc + Number.EPSILON) * 100) / 100
    //console.log("Balance qty to be delivered : ", qty_balance_dc);

    //itemSelected.balance_dc = qty_balance_dc;
   /*
    const currentProductIndex = state.passed_element.prod_list.findIndex((productInd) => productInd.product_name === itemSelected.product_name);
    console.log("Currently selected row product id at handleChange : ", currentProductIndex);
    
    var currAvaialble = parseFloat(itemSelected.balance_dc);
    if ( state.action === 'edit' || state.action === 'view') {
      const currentItemIndex = state.passed_element.dcInfo.items.findIndex((itemInd) => itemInd.product_name === itemSelected.product_name);
      console.log("Currently selected row ITEM VALUE at handleChange : ", currentItemIndex);

      console.log("EXITING QUAAAAAANNNNNNTTTTTTIIIIIITTTTTTYYYYYY : ", state.passed_element.dcInfo.items[currentItemIndex].quantity);
      currAvaialble = parseFloat(itemSelected.balance_dc) + parseFloat(state.passed_element.dcInfo.items[currentItemIndex].quantity);
    }
    console.log("CURRENTLY AVAILABLE**************** : ", currAvaialble);
    console.log("USER TYPED VALUE&&&&&&&&&&&&&&&&&&& : ", e.currentTarget.value);
    */
    var currAvaialble = parseFloat(itemSelected.balance_dc);
    console.log("Product Info : ", productMaster);
    const currentProductIndex = productMaster.findIndex(
      (productInd) => productInd.name === itemSelected.product_name);
    console.log("Currently selected row product id : ", currentProductIndex);

    if (currentProductIndex < 0) {
      itemSelected.error = "Please select product from list";

      const newitems = items.slice();
      newitems[currentId] = itemSelected;
      setItems(newitems);

      return 0;
    } 
    
    //else if ( nameOfElement === "quantity" && e.target.value.match(appGlobal.numericRegExp) && ((itemSelected.balance_dc + itemSelected.quantity) < e.target.value) ) {
    /*  
    else if ( nameOfElement === "quantity" && e.target.value.match(appGlobal.numericRegExp) && currAvaialble < e.target.value ) {  
      itemSelected.qty_error = "Please select valid quantity";

      const newitems = items.slice();
      newitems[currentId] = itemSelected;
      setItems(newitems);

      return 0;
    }
    */
    else if ( nameOfElement === "quantity" && !e.target.value.match(appGlobal.numericRegExp) ) {  
      itemSelected.qty_error = "Please select valid quantity";

      const newitems = items.slice();
      newitems[currentId] = itemSelected;
      setItems(newitems);

      return 0;
    }
    else if ( nameOfElement === "unit_price" && !e.target.value.match(appGlobal.numericRegExp)  ) {
      itemSelected.qty_error = "Please type valid unit price";

      const newitems = items.slice();
      newitems[currentId] = itemSelected;
      setItems(newitems);

      return 0;
    }
    else {
      itemSelected.error = "";
      itemSelected.qty_error = "";
    }
    //console.log("Currently selected row product value : ", state.passed_element.prod_list[currentProductIndex]);

    // Commented on 08-08-2023 05:55:45 PM By Rayappan Antoniraj
    //itemSelected.product = {value: productInfo[currentProductIndex].product_id, label: productInfo[currentProductIndex].product_name, stock_id: productInfo[currentProductIndex].stock_id};

    //itemSelected.unit_price = productInfo[currentProductIndex].unit_price;
    var qtyBeforeUpdate = 0.00;
    console.log("QUANTITY FIELD NAME : ", itemSelected["quantity"]);

    if ( isNaN(parseFloat(itemSelected["quantity"])) === false ) {
      qtyBeforeUpdate = parseFloat(itemSelected["quantity"]);
    }
    console.log("QUANTITY BEFORE UPDATE : ", qtyBeforeUpdate);
    console.log("NEW QUANTITY <<<<<<<<<<<<<<<<<< : ", e.currentTarget.value);
    qtyBeforeUpdate = itemSelected.quantity_orig;

    var totalQtyEditFlag = false;
    if (nameOfElement === "quantity" || nameOfElement === "unit_price" || nameOfElement === "pumping_charges" || nameOfElement === "diesel_charges") {
      console.log("Product Quantity item edited ...");
      if ( e.currentTarget.value.match(appGlobal.numericRegExp) ) {
        itemSelected[nameOfElement] = e.currentTarget.value;
      }
    }
    else if ( nameOfElement === 'total_quantity' ) {
      console.log("Product Total Quantity item edited ...");
      if ( e.currentTarget.value.match(appGlobal.numericRegExp)) {
        itemSelected[nameOfElement] = e.currentTarget.value;

        totalQtyEditFlag = true;
      }
    }
    else {
      console.log("Other than handled option clicked so skipping it ...");
    }
      /*
      const valueTmp = Math.max(
        ConfigMinValue,
        Math.min(configMaxValue, Number(e.target.value))
      );
      */
     /*
      var valueTmp = e.target.value.replace(/[^0-9.]/g, "");
      //.replace(/(\..*)\./g, "$1");
      console.log("Matched value >>>>>>>>>>>>>>> : ", valueTmp);
      itemSelected[nameOfElement] = valueTmp;
    } else if (nameOfElement === "unit_price") {
      console.log("Product unit cost item edited ...");
      itemSelected.unit_price = value;
    } else {
      console.log("Other than handled option clicked so skipping it ...");
    }
    */
    {
      /*
      itemSelected.quantity = valueTmp;
    } else if (nameOfElement === "prod-unit-cost") {
      console.log("Product unit cost item edited ...");
      itemSelected.unit_price = value;
    } else {
      console.log("Other than handled option clicked so skipping it ...");
    }
    //itemSelected.total_item_price = productInfo[currentProductIndex].unit_price * itemSelected.quantity;
    //itemSelected.unit_price = productInfo[currentProductIndex].unit_price;
  */
    }
    
    //itemSelected.total_quantity = 0.00;
    /*
    if ( itemSelected.quantity ) {
      //itemSelected.total_quantity = parseFloat(itemSelected.total_quantity) - qtyBeforeUpdate + parseFloat(itemSelected.quantity);
      var total_quantity1 = 0.00;
      var existing_quantity1 = 0.00;
      console.log("QUANTITY 102 <<<<<<<<<<<<<<<<< : ", itemSelected.quantity);
      console.log("QUANTITY TOTAL 102 <<<<<<<<<<<<<<<<< : ", itemSelected.total_quantity);
      console.log("QUANTITY TOTAL ORIG 102 <<<<<<<<<<<<<<<<< : ", itemSelected.total_quantity_orig);
      console.log("QUANTITY FLOAT 102 <<<<<<<<<<<<<<<<< : ", parseFloat(itemSelected.quantity));
      console.log("EXISTING QUANTITY 102 <<<<<<<<<<<<<<<<< : ", itemSelected.existing_quantity);
      if ( itemSelected.existing_quantity && isNaN(parseFloat(itemSelected.existing_quantity)) === false ) {
        existing_quantity1 = parseFloat(itemSelected.existing_quantity)
      }
      console.log("EXISTING QUANTITY AFTER PROCESSED 102 <<<<<<<<<<<<<<<<< : ", existing_quantity1);
      //var quantity1 = 0.00;
      if ( state.action === 'add' ) { 
        total_quantity1 = existing_quantity1 + parseFloat(itemSelected.quantity);
      }
      else {
        total_quantity1 = parseFloat(itemSelected.total_quantity) - parseFloat(qtyBeforeUpdate) + parseFloat(itemSelected.quantity);
        console.log("+++++++++++++++ QTY BEFORE UPDATE : +++++++++++++ ", qtyBeforeUpdate);
        console.log("+++++++++++++++ total_quantity1 : +++++++++++++ ", total_quantity1);
      }
      console.log("TOTAL QUANTITY 102 : <<<<<<<<<<<<<<<< :", total_quantity1);
      itemSelected.total_item_price = itemSelected.unit_price * total_quantity1;
      console.log("TOTAL ITEM PRICE >>>>>>>>>>>>>>>>>>>>> : ", itemSelected.total_item_price);
      itemSelected.total_price = itemSelected.total_item_price;
      itemSelected.total_quantity = total_quantity1;
    }
    else {
      //itemSelected.quantity = 0.00;
      //itemSelected.total_quantity = parseFloat(itemSelected.total_quantity) - qtyBeforeUpdate + 0.00;
      var existing_quantity1 = 0.00;
      if ( isNaN(parseFloat(itemSelected.existing_quantity)) === false ) {
        existing_quantity1 = parseFloat(itemSelected.existing_quantity)
      }
      var total_quantity1 = existing_quantity1 + 0.00;
      itemSelected.total_item_price = itemSelected.unit_price * total_quantity1;
      itemSelected.total_price = itemSelected.total_item_price;
      itemSelected.total_quantity = total_quantity1;
    }
    */
    if ( isNaN(parseFloat(itemSelected.quantity)) === false ) {
      console.log("<<<<<<<<<<<<<<<<<<<<< EXISTIGN QUANTITY >>>>>>>>>>>>>>>>>>>>>>>> : ", itemSelected.existing_quantity);
      if ( isNaN(parseFloat(itemSelected.existing_quantity)) === false ) {
        if ( (state === null || state.action === 'add') && !totalQtyEditFlag) {
          itemSelected.total_quantity = parseFloat(itemSelected.existing_quantity) + parseFloat(itemSelected.quantity);
          console.log("<<<<<<<<<<<<<<<<<<<<<<<<< TOTAL QUANTITY >>>>>>>>>>>>>>>>>>>>> :", itemSelected.total_quantity);
        }
        else {
          if ( !totalQtyEditFlag ) {
            itemSelected.total_quantity = parseFloat(itemSelected.total_quantity_orig) - parseFloat(itemSelected.quantity_orig) + parseFloat(itemSelected.quantity);
          }
        }
        itemSelected.total_item_price = parseFloat(itemSelected.unit_price) * parseFloat(itemSelected.total_quantity);
        itemSelected.total_price = itemSelected.total_item_price;
      }

    }
    /*
    console.log("<<<<<<<<<<<<<<<<<<< PASSED ORDER INFO +++++++++++++++ : ", passedValue.passed_element.order_info);
    if ( passedValue.passed_element.order_info.bill_gst_include === true ) {
      itemSelected.cgst = (itemSelected.total_item_price * state.passed_element.prod_list[currentProductIndex].cgst_perc) / 100;
      itemSelected.sgst = (itemSelected.total_item_price * state.passed_element.prod_list[currentProductIndex].sgst_perc) / 100;
      itemSelected.igst = (itemSelected.total_item_price * state.passed_element.prod_list[currentProductIndex].igst_perc) / 100;
    }

    itemSelected.cgst_perc = state.passed_element.prod_list[currentProductIndex].cgst_perc;
    itemSelected.sgst_perc = state.passed_element.prod_list[currentProductIndex].sgst_perc;
    itemSelected.igst_perc = state.passed_element.prod_list[currentProductIndex].igst_perc;

    let totalGst = itemSelected.cgst + itemSelected.sgst + itemSelected.igst;
    totalGst = 0.00;
    //var gst = { cgst: cgstTmp, sgst: sgstTmp, igst: igstTmp, totGst: totalGst}
    //itemSelected.gst = gst;
    //itemSelected.total_item_price = productInfo[currentProductIndex].unit_price * itemSelected.quantity;

    itemSelected.total_price = parseFloat(itemSelected.total_item_price) + parseFloat(totalGst);
   */

    /*
    var total_quantity_tmp2 = (isNaN(parseFloat(itemSelected.existing_quantity)) === false ? parseFloat(itemSelected.existing_quantity) : 0.00)  + (isNaN(parseFloat(itemSelected.quantity)) === false ? parseFloat(itemSelected.quantity) : 0.00)
    console.log("TOTAL QUANTITY AFTER NEW CALCULATION : ", total_quantity_tmp2);
    itemSelected.total_quantity = Math.round((total_quantity_tmp2 + Number.EPSILON) * 100) / 100;

    
    var total_item_price_tmp2 = total_quantity_tmp2 * itemSelected.unit_price;
    console.log("TOTAL ITEM PRICE AFTER NEW CALCULATION : ", total_item_price_tmp2);
    itemSelected.total_item_price = Math.round((total_item_price_tmp2 + Number.EPSILON) * 100) / 100
    */

    console.log("Modified unit cost : ", itemSelected.unit_price);
    console.log("Modified volume : ", itemSelected.quantity);
    console.log("Modified Total volume : ", itemSelected.total_quantity);
    console.log("Modified Product cost : ", itemSelected.total_item_price);
    console.log("Modified Total cost : ", itemSelected.total_price);

    const newitems = items.slice();
    newitems[currentId] = itemSelected;
    setItems(newitems);

    dc_products_total = 0.00;
    newitems.map((item) => {
      console.log("IND ITEM : ", item);
      console.log("Total item price : ", item.total_item_price);
      //console.log("GST  : ", item.cgst + item.sgst + item.igst);
      dc_products_total = dc_products_total + item.total_item_price;
    })

    final_total = parseFloat(dc_products_total) + parseFloat(formValues["pumping_charges"]) + parseFloat(formValues["diesel_charges"]) + parseFloat(formValues["others_charges1"]);
    console.log("FINAL TOTAL at handleChange : ", final_total);
  };

 /*
  const getDriverList = async (driverIdInput, driverNameInput) => {
    console.log("getDriverList method called ...");
    let reqLoginPayload = {
      driverId: driverIdInput,
      driverName: driverNameInput,
    };

   
    console.log("payload:", reqLoginPayload);

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(
        client.domain + "/hrms/getDrivers",
        { headers },
        { withCredentials: false }
      );
     
      console.log("Response from server getDriverList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log(
          "Response message from server getDriverList : ",
          res.data.detail.data
        );
        console.log(
          "Length of driver array : ",
          res.data.detail.data.length
        );
        //optionsDistrict = res.data.detail.res_data.district_list

        if (res.data.detail.data.length > 0) {
          var driverArr = [];
          res.data.detail.data.map((driver) => {
            console.log("Driver ind data : ", driver);
            var driverInd = {};
            driverInd.value = driver.id;
            driverInd.label = driver.name;
            driverArr.push(driverInd);
          
          })
          console.log("Driver Arr : ", driverArr);  
          setOptDriver(driverArr);
          setDriverIdSelect({
            value: res.data.detail.data[0].id,
            label: res.data.detail.data[0].name,
          });
        }
        //setDistrictIdSelect(res.data.detail.res_data.district_list);

      }
     
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle('LOGIN FAILED!');
          //setShow(true);
        }
      }
    }
  };
*/


  const autoPopulateDCItems = async(pastProdList) => {
    var eCal = { value : pastProdList[0].id, label: pastProdList[0].name};
    var dc_prod_cnt = calculateDCCount(eCal);
    console.log("total Delivered qty : ", dc_prod_cnt);

    var prod_ordered_qty = getOrderedQty(eCal);
    console.log("Product Ordered Qty : ", prod_ordered_qty);

    var qty_balance_dc = prod_ordered_qty - dc_prod_cnt;
    qty_balance_dc = Math.round((qty_balance_dc + Number.EPSILON) * 100) / 100

    console.log("Balance qty to be delivered : ", qty_balance_dc);

    //itemSelected.balance_dc = qty_balance_dc;
    //var dc_date_time = formValues["dc_date"] + " " + formValues["loading_start_time"];
    //var [retStateTotQty, totQty] = await getTotVolPerCustPerProd(formValues["customer_id"], pastProdList[0].id, formValues["address1"], dc_date_time);

    const newitems = items.slice();
    let itemNew = {
      //id: 0,
      //product: { value: 0, label: "" },
      unit_price: pastProdList[0].unit_price,
      quantity: 0.00,
      existing_quantity: 0.00,
      total_quantity: 0.00,
      //total_quantity: 1.00,
      total_item_price: pastProdList[0].unit_price,
      total_price: pastProdList[0].unit_price,
      cgst: 0.00,
      sgst: 0.00,
      igst: 0.00,
      cgst_perc: 0.00,
      sgst_perc: 0.00,
      igst_perc: 0.00,
      dc_item_id: 0,
      value: 0,
      label: "",
      product_id: pastProdList[0].id,
      product_name: pastProdList[0].name,
      //order_item_id: state.passed_element.prod_list[0].id,
      balance_dc: qty_balance_dc,
      total_dc: dc_prod_cnt,
      //quantity:1,
      //unit_price:0.00
      is_product_cc: pastProdList[0].is_product_cc,
    };
    newitems.push(itemNew);
    setItems(newitems);
    console.log("After called AutoPopulate items ...");
  }
 
  const [dcReportData, setDcReportData] = useState({});
  const [toRender, setToRender] = useState(false);

  const getCustomerDetails = async () => {
    var retState = false;
    var customerId = passedValue.customer_id;

    const response = await fetch(`${client.domain}/getCustomerInfo?customer_id=${customerId}`);
    const customer_info = await response.json();
    retState = true;
    console.log("CUSTOMER INFO : ", customer_info);
    console.log("CuSTOMER INFO : ", customer_info.detail.res_data.customer_info);
    for (const [key, value] of Object.entries(customer_info.detail.res_data.customer_info)) {
      console.log("KEY : %s, VAL: %s", key, value);
    }
    formValues["customer_id"] = customer_info.detail.res_data.customer_info.customer_id;
    formValues["customer_name"] = customer_info.detail.res_data.customer_info.customer_name;
    formValues["customer_disp_name"] = customer_info.detail.res_data.customer_info.customer_disp_name;
    formValues["contact_person"] = customer_info.detail.res_data.customer_info.contact_person;
    formValues["contact_number"] = customer_info.detail.res_data.customer_info.contact_number;
    formValues["gst_number"] = customer_info.detail.res_data.customer_info.gst_number;
    formValues["phone"] = customer_info.detail.res_data.customer_info.phone;
    formValues["email"] = customer_info.detail.res_data.customer_info.email;
    formValues["website"] = customer_info.detail.res_data.customer_info.website;
    formValues["address1"] = customer_info.detail.res_data.customer_info.address1;
    formValues["address2"] = customer_info.detail.res_data.customer_info.address2;
    formValues["pincode"] = customer_info.detail.res_data.customer_info.pincode;
    formValues["primary_number"] = customer_info.detail.res_data.customer_info.primary_number;
    formValues["secondary_number"] = customer_info.detail.res_data.customer_info.secondary_number;

    if (state.action === "edit" || state.action === "view") {
      console.log(
        "######################## Condition met for view or edit %%%%%%%%%%%%%%%%%%%%"
      );
      setCustomerSearchText(customer_info.detail.res_data.customer_info.customer_name);
      setLocationSearchText(customer_info.detail.res_data.customer_info.address1);
    }

    console.log("STATE ID : ", customer_info.detail.res_data.customer_info["state_id"]);
    setStateIdSelect({
      value: customer_info.detail.res_data.customer_info["state_id"],
      label: customer_info.detail.res_data.customer_info["state_name"],
    });
    console.log("DISTRICT ID : ", customer_info.detail.res_data.customer_info["dist_id"]);
    setDistrictIdSelect({
      value: customer_info.detail.res_data.customer_info["dist_id"],
      label: customer_info.detail.res_data.customer_info["district_name"],
    });
    console.log("FORMVALUES 2 : ", formValues);
    return [retState, customer_info.detail.res_data.customer_info];
  };

  const getProductForLoad = async (productIdInput, prodIdIdx) => {
    var retState = false;
    var prodRes = {};
    console.log("get product for load called ... ");

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`, SerialKey: 'HNGD8743JESWE'};
      var res = await axios.get(client.domain + "/ims/getProduct?id=" + productIdInput, { headers }, { withCredentials: false });
      console.log("Response from server getProductForLoad : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getProductForLoad : ", res.data.detail.data);
        console.log("Response message from server getproductForLoad (Products) : ", res.data.detail.data);

        //setFormValues(res.data.detail.data);
        /*
        if ( res.data.detail.data.is_composite === true && res.data.detail.data.tt_key === 'SELL' && 'composite' in res.data.detail.data && res.data.detail.data.composite.length > 0 ) {
          setProductCompositionList(res.data.detail.data.composite);
        }
        */
        //setProductCompositionList(res.data.detail.data.composite);
        console.log("SELECTED PROD INDEX ID : ", prodIdIdx);
        console.log("SELECTED PROD DATA : ", items[prodIdIdx]);

        var selectedProdTmp2 = items[prodIdIdx];

        selectedProdTmp2.composition = res.data.detail.data.composite;

        var newItemsTmp2 = items.slice();
        newItemsTmp2[prodIdIdx] = selectedProdTmp2;
        setItems(newItemsTmp2);
        //setProductCompositionList(res.data.detail.data.composite);
        /*
        console.log("UOM array : ", uomOpt);
        var findUom = uomRef.current.find(element => element.value === res.data.detail.data.uom_key);
        console.log("**************** findUOM value ++++++++++++ : ", findUom);
        if (findUom !== undefined) {
          setUomIdSelect({ label: findUom.label, value: findUom.value })
        }
        */
        //setProdDetLoaded(true);
        //setToRender(true);
        //setEditAction(false);
        setShowConfigDialog(true);

        retState = true;
        prodRes = res.data.detail.data;
        console.log("PRODUCT LOAD STATUS SET TO TRUE")
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");

      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );

        }
      }
    }
    return [retState, prodRes];
  }

  const handleCloseComposition = async () => {
    console.log("handle close composition called ...");
    setShowConfigDialog(false);
  }

  const handleSaveComposition = async () => {
    console.log("Handle Save compostion called ...");
    setShowConfigDialog(false);
    setCompositionUpdate(true);

    var selectedItem = items[selectedCompositionId];
    selectedItem.compositionUpdate = true;
    selectedItem.is_product_cc = true;

    var newItemsTmp = items.slice();
    newItemsTmp[selectedCompositionId] = selectedItem;
    setItems(newItemsTmp);
  }

  useEffect(() => {
    /*
    if (state === null) {
      navigate("/listDCs");
    }
    */
    if (!initialized.current) {
      console.log("*************** useEffect called ##########################");
      console.log(formErrors);
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        console.log(formErrors);
      }
     
      
      var prodListFromOrder = [];
      if (state !== null) {
        console.log("PASSED STATE VALUE : ", state);
        setPassedValue(state);
        /*
        state.passed_element.prod_list.map((itemProduct) => {
          console.log("Product List AddUpdateDirectDC: ", itemProduct);
          
          var prodFromOrder = {
            value: itemProduct.product_id,
            label: itemProduct.product_name,
            //stock_id: itemProduct.stock_id,
            item_id: itemProduct.id,
          };
          
          prodListFromOrder.push(prodFromOrder);
        });
        */
        console.log("Product List from order AddUpdateDC : ", prodListFromOrder);
        setOptionsProd(prodListFromOrder);

        /*
        if (state.action === "edit") {
          console.log("PASSED DC INFO : ", state.passed_element.dcInfo);

          getDcDetailsEdit(state.passed_element.order_id, state.passed_element.dc_id);
        }
        */

        //setFormValues({ ...formValues, ["address1"]: state.passed_element.order_address.delivery_address.address_line1});
        /*
        if ( state.action === 'add') {
          formValues["address1"] = state.passed_element.order_address.delivery_address.address_line1;
          formValues["pincode"] = state.passed_element.order_address.delivery_address.pincode;
          formValues["contact_person"] = state.passed_element.order_address.delivery_address.contact_name;
          formValues["contact_number"] = state.passed_element.order_address.delivery_address.contact_number;
          setFormValues({ ...formValues, ["address2"]: state.passed_element.order_address.delivery_address.address_line2});
        }
        else if ( state.action === 'view' || state.action === 'edit' ) {
          // Will be fetched by api 
        }
        else {
          formValues["address1"] = state.passed_element.order_address.delivery_address.address_line1;
          formValues["pincode"] = state.passed_element.order_address.delivery_address.pincode;
          formValues["contact_person"] = state.passed_element.order_address.delivery_address.contact_name;
          formValues["contact_number"] = state.passed_element.order_address.delivery_address.contact_number;
          setFormValues({ ...formValues, ["address2"]: state.passed_element.order_address.delivery_address.address_line2}); 
        }
        */
      }
      if ( optSite.length > 0 ) {
        setSiteIdSelect(optSite[0]);
      }
      setFormValues({ ...formValues, ["site"]: 'NEWSITE'});
      ( async() => {
      
        var stateIdInput = 1;
        var retStateCustomer = false;
        var customerInfo = {};
        var [retStateSellProd, newSellProdArr] = await getProductsSell();
        if ( retStateSellProd ) {
          console.log("RETURNED SELL PRODUCT LIST : ", newSellProdArr);
          setProductMaster(newSellProdArr);
        }
        /*
        else {
          navigate(0);
        }
        */
        var retStateCustomer = false;
          var customerInfo = {};
        
        if ( state !== null && "action" in state && (state.action === "edit" || state.action === "view")) {
          //[retStateCustomer, customerInfo] = await getCustomerDetails();
        }
        else {
          setIsReadOnly(false);
        }
        var stateIdTmp = 1;
        if ( state !== null && "action" in state && (state.action === 'view' || state.action === 'edit')) {
          stateIdTmp = state.passed_element.state_id;
        }
        
        
        var [retStateDist, distArrRet] = await getDistrictList(stateIdTmp);
        if ( retStateDist) {
          setOptDist(distArrRet);
          if ( state === null || state.action === 'add') {
            //setDistrictIdSelect(distArrRet[0]);
            var findDist = distArrRet.find(element => element.label === appGlobal.DEFAULT_DISTRICT);
            if (findDist !== undefined) {
              console.log("++++++++++++++++++------------------- FOUND DISTRICT : ", findDist);
              setDistrictIdSelect(findDist);
            }
          }
          else {
            var findDist = distArrRet.find(element => element.value === state.passed_element.district_id);
            if ( findDist !== undefined ) {
              console.log("++++++++++++++++++------------------- FOUND DISTRICT : ", findDist);
              setDistrictIdSelect({value: state.passed_element.district_id, label: findDist.label});
            }
          }
        }
        

        var country_id = appGlobal.COUNTRY_ID;
        var [retStateStList, stateArrRet] = await getStateList(country_id);
        if ( retStateStList ) {
          setOptState(stateArrRet);
          if ( state === null || state.action === 'add' ) {
            setStateIdSelect(stateArrRet[0]);
          }
          else {
            var findState = stateArrRet.find(element => element.value === state.passed_element.state_id);
            if ( findState ) {
              console.log("STATE FOUND OBJECT : ", findState);
              setStateIdSelect({value: state.passed_element.state_id, label: findState.label});
            }
        }
        }
        
        /*
        var [retStateSellProd, newSellProdArr] = await getProductsSell();
        if ( retStateSellProd ) {
          setProductInfo(newSellProdArr);
        }
        */

        
        var [retStateDriver, driverArr] = await getDriverList();
        if (retStateDriver) {
          setOptDriver(driverArr);
          if ( state === null || state.action === 'add' ) {
            setDriverIdSelect(driverArr[0]);
          }
          setDriverLoad(true);
        }

        var [retStateVehicle, vehicleArr] = await getVehicleList();
        if ( retStateVehicle ) {
          setOptVehicle(vehicleArr);
          if ( state === null || state.action === 'add' ) {
            setVehicleIdSelect(vehicleArr[0]);
          }
          setVehicleLoad(true);
        }
        
        
        if ( state !== null && "action" in state && (state.action === "edit" || state.action === 'view') ) {
          console.log("PASSED DC INFO : ", state.passed_element.dcInfo);
          await getDcDetailsEdit(state.passed_element.order_id, state.passed_element.dc_id);
        }
       
        console.log("++++++++++++++ PRODUCT ARRAY LENGTH ------------- : ", newSellProdArr.length);
        if ( (state === null || (state && 'action' in state && state.action === 'add')) && newSellProdArr.length > 0 ) {
          await autoPopulateDCItems(newSellProdArr);
        }
        
      })();
       
      
      initialized.current = true;
    }
  }, []);

  const handleSubmit = async (event, actionVal) => {
    //event.preventDefault();
    console.log("<<<<<<<<<<<<<<<<<<<<< handleSubmit currentTarget called : ", event.currentTarget);
    console.log("<<<<<<<<<<<<<<<<<<<<< handleSubmit target called : ", event.target);
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log("^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& : ", validateError);
    console.log("^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ", validateErr);

    if (items.length < 1) {
      setShowMsg("Please add atleast one product in order");
      setMsgType("warning");
      setShow(true);
      return 0;
    }
    {
      /*
    console.log("Currently selected row product id : ", currentProductIndex);
    if (currentProductIndex < 0) {
      itemSelected.error = "Please select product from list";

      const newitems = items.slice();
      newitems[currentId] = itemSelected;
      setItems(newitems);
      return 0;
    } else {
      itemSelected.error = "";
    }
  */
    }

    {
      /*
    var emptyItem = false;
    items.map((item) => {
      console.log("Item value : ", item);
      if (item.product_id < 1 || !item.product.product_name) {
        item.error = "Please select product from list";
        //setShowMsg("Please selecyt product from dropdown");
        //setMsgType("warning");
        //setShow(true);
        emptyItem = true;
        return 0;
      } else {
        item.error = "";
      }
    });
  */
    }

    console.log("<<<<<<<<<<<<<<<< API calling continue >>>>>>>>>>>>>>>>>>");
    if (emptyItem) {
      console.log("unselcted product item so exit from submission ...");
      return 0;
    }

    if (validateErr === false) {
      console.log("FORM VALUES at handleSubmit: ", formValues);
      console.log("LINE ITEMS : ", items);
      console.log("Form submit called ...");

      console.log("DC Date : ", formValues["dc_date"]);
      //console.log("ORDER ID : ", passedValue.passed_element.order_id);
      //console.log("CUSTOMER ID : ", passedValue.passed_element.customer_id);
      console.log("Vehicle ID select value: ", vehicleIdSelect.value);
      console.log("Vehicle ID select name: ", vehicleIdSelect.label);
      //console.log("Vehicle ID select value : ", formValues["vehicleIdSelect"].value);
      console.log("Driver ID select value: ", driverIdSelect.value);
      console.log("Driver ID select name: ", driverIdSelect.label);

      console.log("DISTRICT ID select value: ", districtIdSelect.value);
      console.log("STATE ID select name: ", stateIdSelect.label);
      //console.log("GST Number : ", formValues["gst_number"]);
      //console.log("Phone Number : ", formValues["phone"]);

      //console.log("Tot number of items : ", total_items);
      //console.log("Sum of Tot Product Cost : ", sum_product_cost);
      //console.log("Sum of Tot GST : ", sum_product_gst);
      //console.log("Sum of total cost : ", sum_product_total);
      //console.log("FINAL TOTAL : ", final_total);
      //console.log("SEARCHED COMPANY : ", searchedCompany);

      /*
          var company_name_tmp = '';
          var company_id_tmp = 0;
          if (!searchedCompany || searchedCompany.length < 1 ) {
            company_name_tmp = companySearchText;
            company_id_tmp = 0; 
          }
          else {
            company_name_tmp = searchedCompany.label;
            company_id_tmp = searchedCompany.value;
          }

          console.log("Company name to submit : ", company_name_tmp);
          */
      var addrIdTmp = 0;
      var dcIdTmp = 0;
      var dcNoTmp = "";
      if (state !== null && "action" in state && (state.action === "view" || state.action === "edit")) {
        addrIdTmp = formValues["address_id"];
        dcIdTmp = formValues["dc_id"];
        dcNoTmp = formValues["dc_no"];
      }

      if (items.length < 1) {
        setShowMsg("Please add atleast one product in order");
        setMsgType("warning");
        setShow(true);
        return 0;
      }

      var emptyItem = false;
      items.map((item) => {
        console.log("Item value : ", item);
        if (item.product_id < 1 || !item.product_name) {
          item.error = "Please select product from list";
          //setShowMsg("Please selecyt product from dropdown");
          //setMsgType("warning");
          //setShow(true);
          emptyItem = true;
          return 0;
        } 
        else if ( !item.quantity || item.quantity < 0.01 ) {
          item.qty_error = "Please enter valid qty";
          emptyItem = true;
          return 0;
        }
        else if ( !item.total_quantity || item.total_quantity < 0.01 ) {
          item.total_quantity_err = "Please enter valid total qty";
          emptyItem = true;
          return 0;
        }
        else {
          item.error = "";
        }
      });

      console.log("<<<<<<<<<<<<<<<< API calling continue >>>>>>>>>>>>>>>>>>");
      if (emptyItem) {
        console.log("unselcted product item so exit from submission ...");
        return 0;
      }

      console.log("FORMVALUES BEFORE SUBMIT : ", formValues);
      final_total = parseFloat(dc_products_total) + parseFloat(formValues["pumping_charges"]) + parseFloat(formValues["diesel_charges"]) + parseFloat(formValues["others_charges1"]); 
      console.log("FINAL TOTAL at handleSubmit : ", final_total);
      var dc_date_time = formValues["dc_date"] + " " + formValues["loading_start_time"];
      console.log(">>>>>>>>>>>>>>>>>>>>> DC DATE TIME <<<<<<<<<<<<<<<<<<<<<<<<< : ", dc_date_time);

      console.log("LOADING START TIME COMPONENT ONLY : ", formValues["loading_start_time"]);
      console.log("LOADING END TIME COMPONENT ONLY : ", formValues["loading_end_time"]);
      let reqAddUpdateDC = {
        /*
            company: formValues["company"],
            address: formValues["address"],
            city: formValues["city"],
            zip: formValues["zip"],

            phone: formValues["phone"],
            gst_number: formValues["gst_number"],

            district: formValues["district"],
            state: formValues["state"],
          */
        /*
          company_name: company_name_tmp,
          company_id: company_id_tmp,
          company_disp_name: formValues["company_disp_name"],
          gst_number: formValues["gst_number"],
          phone: formValues["phone"],
          primary_number: formValues["primary_number"],
          secondary_number : formValues["secondary_number"],
          contact_person: formValues["contact_person"],
          contact_number: formValues["contact_number"],
          email: formValues["email"],
          address1: formValues["address1"],
          address2: formValues["address2"],
          distId: districtIdSelect.value,
          stateId: stateIdSelect.value,
          pincode: formValues["pincode"],
          website: formValues["website"],
          */
        customer_id: formValues["customer_id"],
        customer_name: formValues["customer_name"],
        orderId: 0,

        dc_id: dcIdTmp,
        dc_no: dcNoTmp,
        address_id: addrIdTmp,
        //orderId: passedValue.passed_element.order_id,
        //customerId: passedValue.passed_element.customer_id,
        dc_date: dc_date_time,
        vehicleId: vehicleIdSelect.value,
        driverId: driverIdSelect.value,
        contactPerson: formValues["contact_person"],
        contactNumber: formValues["contact_number"].toString(),
        address1: formValues["address1"],
        address2: formValues["address2"],
        districtId: districtIdSelect.value,
        stateId: stateIdSelect.value,
        pincode: formValues["pincode"].toString(),
        //loadingStartTime: formValues["loading_start_time"],
        //loadingEndTime:  formValues["loadingEndTime"],

        total_items: 1,
        //sum_product_cost: sum_product_cost,
        //sum_product_gst: sum_product_gst,

        //sum_product_cgst: sum_product_cgst,
        //sum_product_sgst: sum_product_sgst,
        //sum_product_igst: sum_product_igst,

        //amount_discount: amount_discount,

        //sum_product_total: sum_product_total,
        dc_products_total: dc_products_total,
        final_total: final_total,
        pumping_charges: formValues["pumping_charges"],
        diesel_charges: formValues["diesel_charges"],
        others_reason1: formValues["others_reason1"],
        others_charges1: formValues["others_charges1"],
        loading_start_time: formValues["dc_date"] + " " + formValues["loading_start_time"],
        loading_end_time: formValues["dc_date"] + " " + formValues["loading_end_time"],
        //dc_id: formValues["dc_id"],
        //address_id: formValues["address_id"],
        ewaybill_number: formValues["ewaybill_number"] || "",

        orderItems: JSON.stringify(items),
      };

     
      console.log("payload addUpdateDC :", reqAddUpdateDC);

      //var testTkn = "UJDFISDHjkdah8473bjkhgjhf";
      //var headers = {...client.headers,Authorization: " " + testTkn,};

      try {
        var aIVal = localStorage.getItem("_aI");
        var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
        if ( actionVal === 'submit_print') {
          setSubmitPrintAction(true);
        }
        else {
          setSubmitAction(true);
        }
        var res = await axios.post(client.domain + "/insertDirectDC", reqAddUpdateDC, { headers }, { withCredentials: false });
        console.log("Response from server : ", res.data.detail);

        if (res.data.detail.status === "SUCCESS") {
          console.log("Response message from server : ", res.data.detail.res_data);
          //navigate(-1);
          if ( actionVal === 'submit_print') {
            setSubmitPrintAction(false);
          }
          else {
            setSubmitAction(false);
          }
          
          //setShowDialog(true);
          setAlertPurpose('success');
          setAlertTitle('Success');
          if( (state === null) || (state !== null && 'action'in state && state.action === 'add') ) {
            setAlertBody("DC Saved Successfully!");
          }
          else{
            setAlertBody("DC Updated Successfully!"); 
          }
          setShowAlert(true);

          const { id, name } = event.target;
          console.log("CURRENT TARGET ID : ", id);
          console.log("CURRENT TARGET NAME : ", name);

          if ( event.target.id === 'save-print' ) {            
            var dc_id_tmp = res.data.detail.res_data.dc_number;
            console.log("RETURNED DC ID AFTER SUBMIT : ", dc_id_tmp);

            var urlStr = window.location.href;
            var paramsTmp = urlStr.substring(urlStr.lastIndexOf(client.urlDelimiter) + 1);
            var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

            console.log("CALLED URL : ", urlTmp);
            console.log("CALLED PAGE : ", paramsTmp);

            var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printDirectDC?dc_id=${dc_id_tmp}&action=ONLY`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
            winPrint.moveTo(0,0);
            winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);

            if ( state.action === 'view' || state.action === 'edit' ) {
              navigate('/listDCs');
            }
            else {
              navigate(0);
            }
          }
          else {
            setShowAlert(true);
          }
          //navigate(0);
        }
        else {
          setSubmitAction(false);
          setAlertPurpose('failure');
          setAlertTitle('Failed');
          if(state.action === 'add'){
          setAlertBody("DC Save Failed!");
          }
          else{
            setAlertBody("DC Update Failed!");
          }
         
          setShowAlert(true);
        }
      } catch (error) {
        console.log("FULL ERROR : ", error);
        setSubmitAction(false);
        setAlertPurpose('failure'); 
        setAlertTitle('Failed');
        setAlertBody("Unable to process your request at this moment.Please try after sometime!");
        setShowAlert(true);
        
        /*
        if ( error.code === 'ERR_NETWORK' ) {
          console.log("Not able to reach target server please try after sometime");
          setShowMsg('Please try after sometime');
          setShowMsgTitle('Network Issue!');
          setShow(true);
        }
        else{
          console.log("ERROR : ", error.response.data.detail);
          if (error.response.data.detail.status === 'FAILED') {
            console.log('Request failed due to ', error.response.data.detail.res_data);
            //setShowMsg('Please Re-Login');
            //setShowMsgTitle('Invalid Session!');
            //setShow(true);
            navigate("/auth");
          }
        }
        */
      }
    }
  };
  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);

    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    
   
    if (!values.customer_name){
      console.log("Customer name is missing ...", formValues["customer_name"]);
      errors.customer_name = "Customer name is required!";
      validateErr = true;
    }

    if ( values.contact_number ) {
    console.log("contact number length : ", values.contact_number);
    }
    if ( values.pincode ) {
      console.log("pincode number length : ", values.pincode);
    }

    if (!companySearchText && !searchedCompany) {
      //if (!values.company){
      //errors.company = "Company is required!";
    }

  
    /*
    if (!values.quantity) {
      errors.quantity = "Volume is required!";
      console.log("ERROR AT QUANTITY CHECK");
      validateErr = true;
      setValidateError(true);
    }

    if (!values.unit_price) {
      errors.unit_price = "Unit price is required!";
      console.log("ERROR AT UNIT PRICE CHECK");
      validateErr = true;
      setValidateError(true);
    }
    */

    if (values.contact_number && values.contact_number.toString().length != 10) {
      errors.contact_number = "Contact number should be 10 digits!";
      console.log("ERROR AT CONTACT NUMBER CHECK");
      validateErr = true;
    }

    if (!values.address1) {
      errors.address1 = "Street / Location name is required!";
      console.log("ERROR AT LOCATION CHECK");
      validateErr = true;
      setValidateError(true);
    }
    if (driverIdSelect.value < 1) {
      console.log("driverIdSelect VALUE : ", driverIdSelect);
      errors.driverId = "Driver name is required!";
      validateErr = true;
      setValidateError(true);
    }

    /*
    if (!values.pincode) {
      errors.pincode = "Pincode is required!";
      validateErr = true;
      setValidateError(true);
    }
    */
   
    if (values.pincode && values.pincode.toString().length != 6) {
      errors.pincode = "Pincode should be 6 digits!";
      console.log("ERROR AT PINCODE CHECK");
      validateErr = true;
    }

    if (vehicleIdSelect.value < 1) {
      console.log("vehicleIdSelect VALUE : ", vehicleIdSelect);
      errors.vehicleId = "Vehicle Number is required!";
      validateErr = true;
      setValidateError(true);
    }

    console.log("BEFORE RETURN validate errors ...", validateError);
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };

  const saveHandler = async (event) => {
    // Prevent page reload
    event.preventDefault();

    console.log("Form submit clicked ...");

    console.log("DATA : ", document.forms[0]);

    /*
        var { username, password } = document.forms[0];
        console.log("username : ", username.value);
        console.log("pasword : ", password.value);
        */
  };

  const backHandler = async (event) => {
    event.preventDefault();
    console.log("Back button clicked ...");
  };
  const handleClear = async (e) => {
    e.preventDefault();
    console.log("handle clear called : ", e);
    console.log("Handle clear clicked current target ID: ", e.currentTarget.id);
    navigate(0);
  };

  const newItemHandle = async (event) => {
    event.preventDefault();
    console.log("New item addition button clicked ...");

    const newitems = items.slice();
    let itemNew = {
      id: 1,
      //product: { value: 0, label: "" },
      unit_price: 0.00,
      quantity: 0.00,
      total_item_price: 0.00,
      total_price: 0.00,
      cgst: 0.00,
      sgst: 0.00,
      igst: 0.00,
      cgst_perc: 0.00,
      sgst_perc: 0.00,
      igst_perc: 0.00,
      dc_item_id: 0,
      value: 0,
      label: "",
      //volume: 1,
      total_quantity: 0.00,
      order_item_id: 0
    };
    newitems.push(itemNew);
    setItems(newitems);

    console.log("FULL ITEM VALUE at newItemHandle : ", items);
  };

  const calculateDCCount = (e) => {
    var product_dc_cnt = 0;
    console.log("Product ID at calculateDCCount: ", e.value);
    console.log("Product NAME at calculateDCCount: ", e.label);
    
    //console.log("DC list : ", state.passed_element.dc_list);
   /*
    state.passed_element.dc_list.map((dc_ind) => {
      dc_ind.items.map((item) => {
        if ( e.value === item.product_id ) {
          console.log("DC product ID at calculateDCCount : ", item.product_id);
          console.log("DC product NAME at calculateDCCount : ", item.product_name);
          console.log("DC product QUANTITY at calculateDCCount : ", item.quantity);
          product_dc_cnt = product_dc_cnt + item.quantity;
        }
      })
    })
    */
    return product_dc_cnt;
  } 

  const getOrderedQty = (e) => {
    var product_ordered_qty = 0;
    console.log("Product ID : ", e.value);
    console.log("Product NAME : ", e.label);
    
    //console.log("Product list : ", state.passed_element.prod_list);
    /* 
    state.passed_element.prod_list.map((prod_info) => {
      if ( prod_info.product_id === e.value ) {
        console.log("Product ID : ", prod_info.product_id);
        console.log("Product NAME : ", prod_info.product_name);
        console.log("Product QUANTITY : ", prod_info.quantity);
        product_ordered_qty = prod_info.quantity;
      }
    })
    */
    return product_ordered_qty;
  } 

  const getTotVolPerCustPerProd = async(customerId, productId, locationName, dc_date) => {
    console.log("SELECTED CUSTOMER ID : ", customerId);
    console.log("SELECTED PRODUCT ID : ", productId);

    var retState = false;
    var prodRes = {};
    var total_quantity_ret = 0.00;
    console.log("getTotVolPerCustPerProd called ... ");

    var userReq = {
      params: {
      customerId: customerId,
      productId: productId,
      location: locationName,
      dc_date: dc_date
      }
    };

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`, SerialKey: 'HNGD8743JESWE'};
      var res = await axios.get(client.domain + "/getTotVolPerCustPerProd", userReq, { headers }, { withCredentials: false });
      console.log("Response from server getProductForLoad : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getTotVolPerCustPerProd : ", res.data.detail.res_data);
        //setShowConfigDialog(true);

        retState = true;
        total_quantity_ret = res.data.detail.res_data.total_quantity;

        var itemSelected = items[selectedDCItemRow];
        //itemSelected.total_quantity = total_quantity_ret + itemSelected.quantity;
        itemSelected.existing_quantity = parseFloat(total_quantity_ret);
        itemSelected.total_quantity = itemSelected.existing_quantity + parseFloat(itemSelected.quantity);
        var itemsNew = items.slice();
        itemsNew[selectedDCItemRow] = itemSelected;
        setItems(itemsNew);
      

        //console.log("PRODUCT LOAD STATUS SET TO TRUE")
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");

      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );

        }
      }
    }
    return [retState, total_quantity_ret];
  }

  const handleSelect = async(e, data) => {
    console.log(">>>>>>>>>>>>>>>>>>>>>>> HandleProductSelect called *******************");
    console.log("SELECTED Dropdown value : ", e);
    console.log("SELECTED element id : ", data);
    var currentId = data.name.substring(data.name.lastIndexOf("-") + 1);
    console.log("Current id : ", currentId);

    var itemSelected = items[currentId];
    setSelectedDCItemRow(currentId);
    //console.log("Product: ", itemSelected.product);
    console.log("<<<<<<<<<<<<<<<<<<<<< ITEM ID >>>>>>>>>>>>>>>>>>: ", itemSelected.id);
    console.log("Price : ", itemSelected.unit_price);
    console.log("Product cost : ", itemSelected.total_item_price);
    //console.log("GST : ", parseFloat(itemSelected.cgst + itemSelected.sgst + itemSelected.igst));
    //console.log("Total cost : ", itemSelected.total_price);
    console.log("Qty : ", itemSelected.quantity);

    console.log("Product Info : ", productMaster);
    const currentProductIndex = productMaster.findIndex((productInd) => productInd.name === e.label);
    console.log("Currently selected row product id : ", currentProductIndex);
    console.log("Currently selected row product value : ", productMaster[currentProductIndex]);

    var retStateTotQty = false;
    var totQty = 0.00;
    var totQty1 = 0.00;
    //itemSelected.existing_quantity = totQty1;
    console.log("EXISTING QUANTITY READ 2 : ", itemSelected.existing_quantity);
    if ( formValues.address1 && formValues.customer_id ) {
      var dc_date_time = formValues["dc_date"] + " " + formValues["loading_start_time"];
      [retStateTotQty, totQty] = await getTotVolPerCustPerProd(formValues["customer_id"], productMaster[currentProductIndex].id, formValues["address1"], dc_date_time);
      console.log("RETURNED TOTAL QUANTITY STATE 2 : ", retStateTotQty);
      console.log("RETURNED TOTAL QUANTITY 2 : ", totQty);

      
      if ( retStateTotQty ) {
        if( isNaN(parseFloat(totQty)) === false ) {
          totQty1 = parseFloat(totQty);
        }
      //itemSelected.total_quantity = totQty + itemSelected.quantity;
      itemSelected.existing_quantity = totQty1;
      }
    }
    setSelectedProductInfo({product_id: productMaster[currentProductIndex].id, product_name: productMaster[currentProductIndex].name});
    
    itemSelected.product_id = e.value;
    itemSelected.product_name = e.label;
    itemSelected.enableState = true;
    //itemSelected.isEditable = true;
    itemSelected.editAction = false;
    //itemSelected.product.label = e.label;
    /*
    var productNew = {
      label: e.label,
      value: e.value,
      product_id: e.product_id,
      product_name: e.product_name,
      stock_id: e.stock_id,
      unit_cost: e.unit_cost,
      cgst: e.gst.cgst,
      sgst: e.gst.sgst,
      igst: e.gst.igst,
    };
    itemSelected.product = productNew;
    */
    //itemSelected.price = productMaster[currentProductIndex].unit_cost;
    //itemSelected.cgst_perc = productMaster[currentProductIndex].cgst;
    //itemSelected.sgst_perc = productMaster[currentProductIndex].sgst;
    //itemSelected.igst_perc = productMaster[currentProductIndex].igst;

    itemSelected.unit_price = productMaster[currentProductIndex].unit_price;
    itemSelected.is_product_cc = productMaster[currentProductIndex].is_product_cc;
    //itemSelected.total_quantity = itemSelected.quantity;
    console.log("ENTERED QTY at handleSelect : ", itemSelected.quantity);
    var quantity1 = 0.00;
    if ( !isNaN(parseFloat(itemSelected.quantity)) ) {
      quantity1 = itemSelected.quantity;
    }
    //var total_quantity1 = itemSelected.existing_quantity + quantity1;
    console.log("EXISTING QTY at handleSelect : ", itemSelected.existing_quantity);
    //console.log("CALCULATED TOTAL QTY at handleSelect : ", total_quantity1);
    if ( isNaN(parseFloat(itemSelected.existing_quantity)) === false && isNaN(parseFloat(itemSelected.quantity)) ) { 
      itemSelected.total_quantity = parseFloat(itemSelected.existing_quantity) + parseFloat(itemSelected.quantity);
      itemSelected.total_item_price = parseFloat(itemSelected.unit_price) * parseFloat(itemSelected.total_quantity);
      itemSelected.total_price = itemSelected.total_item_price;
    }
    //itemSelected.total_quantity = total_quantity1;
   /*
    var cgstCalculate = 0.0;
    if (gstBillStatus) {
      cgstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].cgst) / 100;
    }
    itemSelected.cgst = cgstCalculate;

    var sgstCalculate = 0.0;
    if (gstBillStatus) {
      sgstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].sgst) / 100;
    }
    itemSelected.sgst = sgstCalculate;

    var igstCalculate = 0.0;
    if (gstBillStatus) {
      igstCalculate = (itemSelected.total_item_price * productMaster[currentProductIndex].igst) / 100;
    }
    itemSelected.igst = igstCalculate;
    */
    //let totalGst = cgstTmp + sgstTmp + igstTmp;

    //var gst = { cgst: cgstTmp, sgst: sgstTmp, igst: igstTmp, totGst: totalGst }
    //itemSelected.gst = gst
    //itemSelected.product_cost = productMaster[currentProductIndex].unit_cost * itemSelected.qty;

    //itemSelected.total_price = parseFloat(itemSelected.total_item_price) + parseFloat(itemSelected.cgst + itemSelected.sgst + itemSelected.igst);

    console.log("Modified unit cost : ", itemSelected.unit_price);
    console.log("Modified Product cost : ", itemSelected.total_item_price);
    //console.log("Modified Total cost : ", itemSelected.total_price);

    
    const newitems = items.slice();
    newitems[currentId] = itemSelected;
    setItems(newitems);

    //var total_items = 0;
    //var sum_product_cost = 0.0;
    //var sum_product_gst = 0.0;
    //var sum_product_cgst = 0.0;
    //var sum_product_sgst = 0.0;
    //var sum_product_igst = 0.0;

    //var sum_product_total = 0.0;
    //var amount_discount = 0.0;
    dc_products_total = 0.00;
    newitems.map((item) => {
      console.log("IND ITEM : ", item);
      console.log("Total item price : ", item.total_item_price);
      //console.log("GST  : ", item.cgst + item.sgst + item.igst);
      dc_products_total = dc_products_total + item.total_item_price;
    })
    final_total = parseFloat(dc_products_total) + parseFloat(formValues["pumping_charges"]) + parseFloat(formValues["diesel_charges"]) + parseFloat(formValues["others_charges1"]);
    console.log("FINAL TOTAL at handleSelect : ", final_total);
    //var fieldValue = "product";
    //setFormValues({ ...formValues, [fieldValue]: e })
    setIsEditable(false);
  };

 /*
  const handleSelect = (e, data) => {
    console.log("SELECTED Dropdown value : ", e);
    console.log("SELECTED element id : ", data);
    var currentId = data.name.substring(data.name.lastIndexOf("-") + 1);
    console.log("Current id : ", currentId);

    var itemSelected = items[currentId];
    console.log("---------------- SELECTED ITEMS ++++++++++++++++ : ", items[currentId]);
    //console.log("Product: ", itemSelected.product);
    console.log("Price : ", itemSelected.unit_price);
    console.log("Product cost : ", itemSelected.total_item_price);
    console.log("GST : ", itemSelected.cgst);
    console.log("Total cost : ", itemSelected.total_price);
    console.log("Quantity : ", itemSelected.quantity);

    console.log("Product Info : ", state.passed_element.prod_list);
    
    const currentProductIndex = state.passed_element.prod_list.findIndex((productInd) => productInd.product_name === e.label);
    console.log("Currently selected row product id at handleSelect : ", currentProductIndex);
    console.log("Currently selected row product value : ", state.passed_element.prod_list[currentProductIndex]);
   
    itemSelected.id = e.value;
    //itemSelected.product = e;
    itemSelected.product_id = e.value;
    itemSelected.product_name = e.label;
    itemSelected.order_item_id = e.item_id;
    
    itemSelected.value = e.value;
    itemSelected.label = e.label;
    //itemSelected.price = productInfo[currentProductIndex].unit_price;
    //itemSelected.unit_price = productInfo[currentProductIndex].unit_price;
    itemSelected.unit_price = state.passed_element.prod_list[currentProductIndex].unit_price;
    //itemSelected.total_item_price = productInfo[currentProductIndex].unit_price * itemSelected.quantity;

    if ( passedValue.passed_element.order_info.bill_gst_include === true ) {
      itemSelected.cgst = (itemSelected.total_item_price * state.passed_element.prod_list[currentProductIndex].cgst_perc) / 100;
      itemSelected.sgst = (itemSelected.total_item_price * state.passed_element.prod_list[currentProductIndex].sgst_perc) / 100;
      itemSelected.igst = (itemSelected.total_item_price * state.passed_element.prod_list[currentProductIndex].igst_perc) / 100;
    }
    itemSelected.cgst_perc = state.passed_element.prod_list[currentProductIndex].cgst_perc;
    itemSelected.sgst_perc = state.passed_element.prod_list[currentProductIndex].sgst_perc;
    itemSelected.igst_perc = state.passed_element.prod_list[currentProductIndex].igst_perc;

    let totalGst = itemSelected.cgst + itemSelected.sgst + itemSelected.igst;

    //var gst = { cgst: cgstTmp, sgst: sgstTmp, igst: igstTmp, totGst: totalGst}
    //itemSelected.gst = gst
    itemSelected.total_item_price = state.passed_element.prod_list[currentProductIndex].unit_price * itemSelected.quantity;

    itemSelected.total_price = parseFloat(itemSelected.total_item_price) + parseFloat(totalGst);

    console.log("Modified unit cost : ", itemSelected.unit_price);
    console.log("Modified Product cost : ", itemSelected.total_item_price);
    console.log("Modified Total cost : ", itemSelected.total_price);

    var dc_prod_cnt = calculateDCCount(e);
    console.log("total Delivered qty : ", dc_prod_cnt);

    var prod_ordered_qty = getOrderedQty(e);
    console.log("Product Ordered Qty : ", prod_ordered_qty);

    var qty_balance_dc = prod_ordered_qty - dc_prod_cnt;
    qty_balance_dc = Math.round((qty_balance_dc + Number.EPSILON) * 100) / 100

    console.log("Balance qty to be delivered : ", qty_balance_dc);

    itemSelected.balance_dc = qty_balance_dc;
    //itemSelected.order_item_id = e.item_id;
    const newitems = items.slice();
    newitems[currentId] = itemSelected;
    setItems(newitems);
    //var fieldValue = "product";
    //setFormValues({ ...formValues, [fieldValue]: e });
  };
  */


  /*
  console.log("FORM VALUES Before rendering :", formValues);

  var total_items = 0;
  var sum_product_cost = 0.0;
  var sum_product_gst = 0.0;
  var sum_product_cgst = 0.0;
  var sum_product_sgst = 0.0;
  var sum_product_igst = 0.0;

  var sum_product_total = 0.0;
  var amount_discount = 0.0;

  items.map((item) => {
    console.log("IND ITEM : ", item);
    console.log("Total item price : ", item.total_item_price);
    console.log("GST  : ", item.cgst + item.sgst + item.igst);

    console.log("CGST  : ", item.cgst);
    console.log("SGST  : ", item.sgst);
    console.log("IGST  : ", item.igst);

    console.log("Total price : ", item.total_price);
    total_items = total_items + 1;
    sum_product_cost = sum_product_cost + item.total_item_price;

    sum_product_cgst = sum_product_cgst + item.cgst;
    sum_product_sgst = sum_product_sgst + item.sgst;
    sum_product_igst = sum_product_igst + item.igst;

    //sum_product_total = sum_product_total + item.total_price;
    //sum_product_total =  sum_product_total +
  });

  sum_product_cgst = 0.00;
  sum_product_sgst = 0.00;
  sum_product_igst = 0.00;

  sum_product_gst = sum_product_cgst + sum_product_sgst + sum_product_igst;
  sum_product_total = sum_product_cost + sum_product_gst;

  sum_product_total = sum_product_total - amount_discount + parseFloat(formValues.pumping_charges) + parseFloat(formValues.diesel_charges);
  */
  //console.log("Tot number of items : ", total_items);
  //console.log("Sum of Tot Product Cost : ", sum_product_cost);

  //console.log("Sum of Tot CGST : ", sum_product_cgst);
  //console.log("Sum of Tot SGST : ", sum_product_sgst);
  //console.log("Sum of Tot iGST : ", sum_product_igst);

  //console.log("Sum of Tot GST : ", sum_product_gst);

  //console.log("Sum of total cost : ", sum_product_total);

  //console.log("Currently selected district : ", districtIdSelect);
  //console.log("opt district : ", optDist);
  //console.log("*********** opt district value ############# : ", optDist.value);

  //console.log("Currently selected state : ", stateIdSelect);
  //console.log("+++++++++++++++ Company search text ^^^^^^^^^^^^^^^^^^^^  :", companySearchText);
  //console.log("Searched company <<<<<<<<<<<<<<<<<<< : ", searchedCompany);
  //console.log("PRODUCT LIST FROM ORDER ITEMS : ", optionsProd);

  const getDcDetailsEdit = async (orderId, dcId) => {
    let reqDCPrintReq = {
      dc_id: dcId
    };
    console.log("payload DC Print :", reqDCPrintReq);

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
      var res = await axios.post(
        client.domain + "/getDCDetailsDirect",
        reqDCPrintReq,
        { headers },
        { withCredentials: false }
      );
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server DCDetails : ", res.data.detail.res_data);
        //navigate("/dashboard");
        setDcReportData(res.data.detail.res_data);
        /*
        formValues["dc_id"] = res.data.detail.res_data.dc_info.dc_id;
        formValues["dc_no"] = res.data.detail.res_data.dc_info.dc_no;
        formValues["dc_date"] = formatDate(new Date(res.data.detail.res_data.dc_info.dc_date));
        formValues["contact_person"] = res.data.detail.res_data.dc_info.contact_person;
        formValues["contact_number"] = res.data.detail.res_data.dc_info.contact_number;

        formValues["address1"] = res.data.detail.res_data.dc_info.address_line1;
        formValues["address2"] = res.data.detail.res_data.dc_info.address_line2;
        */
        setFormValues({ ...formValues, 
          dc_id: res.data.detail.res_data.dc_info.dc_id, 
          dc_no: res.data.detail.res_data.dc_info.dc_no,
          dc_date: formatDate(new Date(res.data.detail.res_data.dc_info.dc_date)),
          contact_person: res.data.detail.res_data.dc_info.contact_person,
          contact_number: res.data.detail.res_data.dc_info.contact_number,
          customer_id: res.data.detail.res_data.dc_info.customer_id,
          customer_name: res.data.detail.res_data.dc_info.customer_name,
          address_id: res.data.detail.res_data.dc_info.address_id,
          address1: res.data.detail.res_data.dc_info.address_line1,
          address2: res.data.detail.res_data.dc_info.address_line2,
          pincode: res.data.detail.res_data.dc_info.pincode,
          loading_start_time: formateTime(new Date(res.data.detail.res_data.dc_info.loading_start_time)),
          loading_end_time: formateTime(new Date(res.data.detail.res_data.dc_info.loading_end_time)),
          pumping_charges: res.data.detail.res_data.dc_info.pumping_charges,
          diesel_charges : res.data.detail.res_data.dc_info.diesel_charges,
          others_charges1 :res.data.detail.res_data.dc_info.others_charges1,
          others_reason1 : res.data.detail.res_data.dc_info.others_reason1,
          ewaybill_number: res.data.detail.res_data.dc_info.ewaybill_number
        })

        //Object.assign({}, state, { timespan: action.timespan.value, customTimespan: action.timespan.value})
        setDistrictIdSelect({value: res.data.detail.res_data.dc_info.district_id, label: res.data.detail.res_data.dc_info.district_name});
        setDriverIdSelect({value: res.data.detail.res_data.dc_info.driver_id, label: res.data.detail.res_data.dc_info.driver_name});
        setStateIdSelect({value: res.data.detail.res_data.dc_info.state_id, label: res.data.detail.res_data.dc_info.state_name});
        setVehicleIdSelect({value: res.data.detail.res_data.dc_info.vehicle_id, label: res.data.detail.res_data.dc_info.vehicle_reg_number});

        //formValues["pincode"] = res.data.detail.res_data.dc_info.pincode;
        //formValues["loading_start_time"] = res.data.detail.res_data.dc_info.loading_start_time;
        var loadStartDateTime = new Date(res.data.detail.res_data.dc_info.loading_start_time);
        var onlyHrMin = formateTime(loadStartDateTime);
        console.log("Load Start time in hh:mm : ", onlyHrMin);
        //formValues["loading_start_time"] = onlyHrMin;

        //formValues["loading_end_time"] = res.data.detail.res_data.dc_info.loading_end_time;
        var loadEndDateTime = new Date(res.data.detail.res_data.dc_info.loading_end_time);
        var onlyHrMin = formateTime(loadEndDateTime);
        console.log("Load End time in hh:mm : ", onlyHrMin);
        //formValues["loading_end_time"] = onlyHrMin;
        console.log("PUMPING CHARGES at useEffect : ", res.data.detail.res_data.dc_info.pumping_charges);
        //formValues["pumping_charges"] = res.data.detail.res_data.dc_info.pumping_charges;
        //setFormValues(formValues => ({ ...formValues, pumping_charges: res.data.detail.res_data.dc_info.pumping_charges }))
        //setFormValues({ ...formValues, pumping_charges : res.data.detail.res_data.dc_info.pumping_charges });
        //formValues["diesel_charges"] = res.data.detail.res_data.dc_info.diesel_charges;
        //setFormValues({ ...formValues, diesel_charges : res.data.detail.res_data.dc_info.diesel_charges });
        console.log("DIESEL CHARGES at useEffect : ", res.data.detail.res_data.dc_info.diesel_charges);

        //formValues["others_charges1"] = res.data.detail.res_data.dc_info.others_charges1;
        //setFormValues({ ...formValues, others_charges1 :res.data.detail.res_data.dc_info.others_charges1 });
        console.log("OTHERS CHARGES at useEffect : ", res.data.detail.res_data.dc_info.others_charges1);

        //setFormValues({ ...formValues, others_reason1 : res.data.detail.res_data.dc_info.others_reason1 });
        console.log("OTHERS REASON at useEffect : ", res.data.detail.res_data.dc_info.others_reason1);
        //formValues["address_id"] = res.data.detail.res_data.dc_info.address_id;

        if ( state.action === 'add' ) {
          setItems(res.data.detail.res_data.dc_item_list);
        }
        else {
          //setItems(res.data.detail.res_data.dc_item_list);
          var itemSelected = res.data.detail.res_data.dc_item_list[selectedDCItemRow];
          itemSelected.existing_quantity = res.data.detail.res_data.dc_item_list[selectedDCItemRow].total_quantity - res.data.detail.res_data.dc_item_list[selectedDCItemRow].quantity;
          itemSelected.quantity_orig = res.data.detail.res_data.dc_item_list[selectedDCItemRow].quantity;
          itemSelected.total_quantity_orig = res.data.detail.res_data.dc_item_list[selectedDCItemRow].total_quantity;
          var newItems = res.data.detail.res_data.dc_item_list;
          newItems[selectedDCItemRow] = itemSelected;
          setItems(newItems);
        }
        //setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        //setShowMsg('Please try after sometime');
        //setShowMsgTitle('Network Issue!');
        //setShow(true);
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg('Please try again ...');
          //setShowMsgTitle('Unable to process your request!');
          //setShow(true);
          //navigate("/auth");
        }
      }
    }
  };

  const handleItemDelete = (e) => {
    console.log("handle item delete called ...", e);
    console.log("handle item delete e.currentTarget : ", e.currentTarget);
    console.log("handle item delete e.currentTarget ID : ", e.currentTarget.id);
    console.log("handle item delete e.target : ", e.target.id);

    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var curSelId = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(
        0,
        clickedTagId.lastIndexOf("-")
      );
      console.log("current selected DELETE element name : ", nameOfElement);
      console.log("Current SELECTED DELETE id : ", curSelId);
      console.log("FULL ITEM VALUE : ", items);
      console.log("CUR SELECTED DELETE VALUE : ", items[curSelId]);

      //const indexPos = items.indexOf(curSelId);

      const spliced = items.splice(curSelId, 1);
      console.log("Removed item from order items: ", spliced);

      var newItems = items.slice();
      setItems(newItems);
    }
  };

  const handleBack = async (e) => {
    console.log("handle back called : ", e);
    navigate(-1);
  };

  const handlePrintDC = async(e) => {
    e.preventDefault();
    console.log("Handle action print Clicked : ", e);
  };

  const searchHandleFunc = async (query) => {
    setIsLoading(true);

    fetch(`${client.domain}/search/customers?q=${query}`)
      .then((resp) => resp.json())
      .then((json) => {
        //searchActionFunc(json)
        setOptionsSearch(json.detail.res_data.customer_list);
        setIsLoading(false);
        console.log("SEARCHED VALUE inside searchActionFunc : ", json.detail.res_data.customer_list);
      });
    formValues["customer_name"] = query;
    //setFormValues({ ...formValues, ["customer_disp_name"]: query });
    /*
    if ( addrCopyCheck && !formValues.contact_person) {
      setFormValues({ ...formValues, ["shipContactName"]: query });
    }
    */
    //setFormValues({ ...formValues, ["customer_name"]: query });
    setCustomerSearchText(query);
  };
  const searchHandleLocationFunc = (query) => {
    console.log("searchHandleLocationFunc called ...");
    setIsLoading(true);

    fetch(`${client.domain}/search/locations?q=${query}`)
      .then((resp) => resp.json())
      .then((json) => {
        //searchActionFunc(json)
        setOptionsSearchLoc(json.detail.res_data.location_list);
        setIsLoading(false);
        console.log("SEARCHED VALUE inside searchActionFunc : ", json.detail.res_data.location_list);
      });
    //formValues["customer_name"] = query;
    //setFormValues({ ...formValues, ["adderss1"]: query });
    /*
    if ( addrCopyCheck && !formValues.contact_person) {
      setFormValues({ ...formValues, ["shipContactName"]: query });
    }
    */
    //setFormValues({ ...formValues, ["customer_name"]: query });
    setLocationSearchText(query);
  };


  const customerSearchSelectFunc = async (selected) => {
    console.log("**************** User selected value from search result +++++++++++++ : ", selected);
    if (selected.length > 0) {
      setSearchedCustomer(selected[0]);
      
      const response = await fetch(`${client.domain}/getCustomerInfo?customer_id=${selected[0].value}`);
      const customer_info = await response.json();
      console.log("CUSTOMER INFO : ", customer_info.detail.res_data.customer_info);
      //setIsLoading(false);

      //setFormValues({...formValues, [name]: value});
      //let entries = Object.entries(company_info.detail.res_data.company_info)
      //entries.map( ([key, val] = entry) => {
      //entries.map( ([key, val] = entry) => {
      for (const [key, value] of Object.entries(customer_info.detail.res_data.customer_info)) {
        //console.log("KEY : ", key);
        //console.log("VAL : ", val);
        console.log("KEY : %s, VAL: %s", key, value);

        //setFormValues({...formValues, [key]: value});
      }

      //setFormValues(customer_info.detail.res_data.customer_info);
      formValues["customer_id"] = customer_info.detail.res_data.customer_info.customer_id;
      formValues["customer_name"] = customer_info.detail.res_data.customer_info.customer_name;
      formValues["customer_disp_name"] = customer_info.detail.res_data.customer_info.customer_disp_name;
      formValues["contact_number"] = customer_info.detail.res_data.customer_info.contact_number;
      formValues["contact_person"] = customer_info.detail.res_data.customer_info.contact_person;
      formValues["address1"] = customer_info.detail.res_data.customer_info.address1;
      formValues["address2"] = customer_info.detail.res_data.customer_info.address2;
      formValues["dist_id"] = customer_info.detail.res_data.customer_info.district_id;
      formValues["district_name"] = customer_info.detail.res_data.customer_info.district_name
      formValues["state_id"] = customer_info.detail.res_data.customer_info.state_id;
      formValues["state_name"] = customer_info.detail.res_data.customer_info.state_name;
      formValues["pincode"] = customer_info.detail.res_data.customer_info.pincode;
      //setOptionsSearchLoc([{label: customer_info.detail.res_data.customer_info.address1, value: 1}]);
      //setIsLoading(true);

      console.log("STATE ID : ", customer_info.detail.res_data.customer_info["state_id"]);
      setStateIdSelect({
        value: customer_info.detail.res_data.customer_info["state_id"],
        label: customer_info.detail.res_data.customer_info["state_name"],
      });
      console.log("DISTRICT ID : ", customer_info.detail.res_data.customer_info["dist_id"]);
      setDistrictIdSelect({
        value: customer_info.detail.res_data.customer_info["dist_id"],
        label: customer_info.detail.res_data.customer_info["district_name"],
      });

      //setFormValues({...formValues, ["company_disp_name"]: "ABC"});
      //setFormValues({...formValues, ["pincode"]: "641654"});

      /*
      var retStateTotQty = false;
      var totQty = 0.00;
      var totQty1 =0.00;
      if ( customer_info.detail.res_data.customer_info.address1 ) {
        [retStateTotQty, totQty] = await getTotVolPerCustPerProd(state.passed_element.customer_id, productMaster[currentProductIndex].id, customer_info.detail.res_data.customer_info.address1);
        console.log("RETURNED TOTAL QUANTITY STATE 2 : ", retStateTotQty);
        console.log("RETURNED TOTAL QUANTITY 2 : ", totQty);
  
        
        if ( retStateTotQty ) {
          if( isNaN(parseFloat(totQty)) !== false ) {
            totQty1 = parseFloat(totQty);
          }
        //itemSelected.total_quantity = totQty + itemSelected.quantity;
        //itemSelected.existing_quantity = totQty1;
        }
      }
      */
      if ( customer_info.detail.res_data.customer_info.address1 ) {
        var dc_date_time = formValues["dc_date"] + " " + formValues["loading_start_time"];
        getTotVolPerCustPerProd(formValues["customer_id"], items[selectedDCItemRow].product_id, customer_info.detail.res_data.customer_info.address1, dc_date_time);
      }
      console.log("FORMVALUES 2 : ", formValues);
    }
  };

  const locationSearchSelectFunc = async (selected) => {
    console.log("**************** User selected value from search result +++++++++++++ : ", selected);
    if (selected.length > 0) {
      setSearchedLocation(selected[0]);
      
      const response = await fetch(`${client.domain}/getCustomerInfo?customer_id=${selected[0].value}`);
      const customer_info = await response.json();
      console.log("CUSTOMER INFO : ", customer_info.detail.res_data.customer_info);
      //setIsLoading(false);

      //setFormValues({...formValues, [name]: value});
      //let entries = Object.entries(company_info.detail.res_data.company_info)
      //entries.map( ([key, val] = entry) => {
      //entries.map( ([key, val] = entry) => {
      for (const [key, value] of Object.entries(
        customer_info.detail.res_data.customer_info
      )) {
        //console.log("KEY : ", key);
        //console.log("VAL : ", val);
        console.log("KEY : %s, VAL: %s", key, value);

        //setFormValues({...formValues, [key]: value});
      }

      setFormValues(customer_info.detail.res_data.customer_info);
      //setIsLoading(true);

      console.log("STATE ID : ", customer_info.detail.res_data.customer_info["state_id"]);
      setStateIdSelect({
        value: customer_info.detail.res_data.customer_info["state_id"],
        label: customer_info.detail.res_data.customer_info["state_name"],
      });
      console.log("DISTRICT ID : ", customer_info.detail.res_data.customer_info["dist_id"]);
      setDistrictIdSelect({
        value: customer_info.detail.res_data.customer_info["dist_id"],
        label: customer_info.detail.res_data.customer_info["district_name"],
      });

      //setFormValues({...formValues, ["company_disp_name"]: "ABC"});
      //setFormValues({...formValues, ["pincode"]: "641654"});

      console.log("FORMVALUES 2 : ", formValues);
    }
  };

const getCustomerOldLocations = async(customer_id) => {
  console.log("getCustomerOldLocations called :", customer_id);

  var retState = false;
    var prodRes = {};
    var total_quantity_ret = 0.00;
    console.log("getTotVolPerCustPerProd called ... ");

    var userReq = {
      params: {
      customer_id: customer_id
      }
    };

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`, SerialKey: 'HNGD8743JESWE'};
      var res = await axios.get(client.domain + "/getCustomerLocations", userReq, { headers }, { withCredentials: false });
      console.log("Response from server getCustomerOldLocations : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server getCustomerOldLocations : ", res.data.detail.res_data.locations);
        //setShowConfigDialog(true);

        retState = true;
        if ( res.data.detail.res_data.locations.length > 0 ) {
          setOptSite(res.data.detail.res_data.locations);
          setSiteIdSelect(res.data.detail.res_data.locations[0]);
        }
        //total_quantity_ret = res.data.detail.res_data.locations;

        //var itemSelected = items[selectedDCItemRow];
        //itemSelected.total_quantity = total_quantity_ret + itemSelected.quantity;
        //itemSelected.existing_quantity = parseFloat(total_quantity_ret);
    
        //var itemsNew = items.slice();
        //itemsNew[selectedDCItemRow] = itemSelected;
        //setItems(itemsNew);
      

        //console.log("PRODUCT LOAD STATUS SET TO TRUE")
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");

      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );

        }
      }
    }
    return [retState, total_quantity_ret];
}

  const handleRadioChange = async (e) => {
    console.log("Value button clicked : ", e);

    const { name, id } = e.currentTarget;
    console.log("Captured value name : ", name);
    console.log("Captured value id : ", id);

    var currentRowName = id.substring(id.lastIndexOf("-") + 1);
    var nameOfElement = id.substring(0, id.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current row id : ", currentRowName);
    setFormValues({ ...formValues, ["site"]: currentRowName.toUpperCase() });

    
    if ( currentRowName === 'oldsite' ) {
      getCustomerOldLocations(formValues["customer_id"]);
    }
    
  };

  const handlePrintDCDirect = async(e) => {
    console.log("handlePrintDCDirect called : ", e);
    console.log("handlePrintDCDirect called : ", e.currentTarget);
    console.log("handlePrintDCDirect called ID : ", e.currentTarget.id);
    console.log("handlePrintDCDirect called NAME : ", e.currentTarget.name);

    var urlStr = window.location.href;
    var paramsTmp = urlStr.substring(
      urlStr.lastIndexOf(client.urlDelimiter) + 1
    );
    var urlTmp = urlStr.substring(0, urlStr.lastIndexOf(client.urlDelimiter));

    console.log("CALLED URL : ", urlTmp);
    console.log("CALLED PAGE : ", paramsTmp);

    var winPrint = window.open(`${urlTmp}${client.urlDelimiter}/printDirectDC?dc_id=${formValues["dc_id"]}&action=ONLY`, "popup", "width=" + window.screen.width + ",height=" + window.screen.height);
    winPrint.moveTo(0,0);
    winPrint.resizeTo(window.screen.availWidth, window.screen.availHeight);

  }

  const handleEdit = async (e) => {
    console.log("handle edit  clicked : ", e);
    setIsReadOnly(false);
  };

  const deleteDirectDc = async() => {
    console.log("CLICKED DC ID : ", state.passed_element.dc_id);

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`, SerialKey: 'HNGD8743JESWE'};
      var res = await axios.get(client.domain + "/deleteDirectDC?dc_id=" + state.passed_element.dc_id, { headers }, { withCredentials: false });
      console.log("Response from server deleteDirectDc : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server deleteDirectDc : ", res.data.detail.data);
        console.log("Response message from server deleteDirectDc (Products) : ", res.data.detail.data);

        setAlertPurpose('deleted');
        setAlertTitle("Success");
        setAlertBody("DC Deleted Successfully!");
        setShowAlert(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log("Not able to reach target server please try after sometime");

      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );

        }
      }
      setAlertPurpose('failure');
      setAlertTitle("Failed");
      setAlertBody("Unable to process your request at this moment.Please try after sometime!");
      setShowAlert(true);
  }
}

  const handleDelete = async(e) => {
    console.log("DELETE BUTTON CLICKED : ", e);

    setAlertPurpose('delete');
    setAlertTitle("Confirmation");
    setAlertBody("Are you sure want to delete this DC!");
    setShowAlert(true);

    // Call delete api
    //deleteDirectDc();
  }
  
  const handleCompositeEdit = async (e, rowData) => {
    setEditAction(true);
    console.log("Handle Edit clicked FULL : ", e);
    console.log("Handle Edit clicked FULL currentTarget : ", e.currentTarget);
    console.log("Handle Edit clicked FULL target : ", e.target);
    var { id, name } = e.currentTarget;
    //var {id1, name1 } = e.currentTarget;
    console.log("id : ", id , ", name : " , name);
    //console.log("id : ", id, ", name : " , name);
    console.log("Handle Edit clicked FULL : ", rowData);
    
    //console.log("Handle Edit clicked currentTarget: ", rowData.currentTarget);
    //console.log("Handle Edit clicked target : ", rowData.target);
    var clickedTagId = e.currentTarget.id;
    if (clickedTagId) {
      var productIdInput = clickedTagId.substring(clickedTagId.lastIndexOf("-") + 1);
      var nameOfElement = clickedTagId.substring(0, clickedTagId.lastIndexOf("-"));
      console.log("current selected Edit element name : ", nameOfElement);
      console.log("Current SELECTED Edit id : ", productIdInput);
      console.log("CUR SELECTED Edit VALUE : ", items[productIdInput]);


      setSelectedCompositionId(productIdInput);
      /*
      let sliced = items.splice(productIdInput, 1);
      console.log("Edit item from order items: ", sliced);
      var newItem = items.slice();
      setItems(newItem);
      */
    }
    

    // get composition from api call
    //https://api.erp.avinasoft.in/ims/getProduct?id=6

    
    var selectedItem = items[productIdInput];
    var [retStateProdBuy, buyProdArr] =  await getProductBuy();
    if ( retStateProdBuy ) {
      setProductList(buyProdArr);
    }
    var productIdPass = items[productIdInput].product_id;
    selectedItem.enableState = false;
    selectedItem.editAction = true;
    console.log("selectedItem :", selectedItem);
    var compExist = 'composition' in selectedItem;
    if ( !compExist || selectedItem.composition.length < 1 ) { 
      await getProductForLoad(productIdPass, productIdInput);
    }
    else {
      setShowConfigDialog(true);
    }
    
    //await getProductForLoad(productIdPass, productIdInput);
    setEditAction(false);
    selectedItem.enableState = true;
    //selectedItem.isEditable=true;
    selectedItem.editAction = false;

    if (!selectedItem.hasOwnProperty("composition") ) {
      console.log("********************** Product composition doesn't exist +++++++++++++++++ ");
      //selectedItem.composition = [];
    }
    else {
      console.log("******************* Product composition already exist in items ++++++++++++++");
    }

    const newitems = items.slice();
    newitems[productIdInput] = selectedItem;
    setItems(newitems);
  };
  const handleProductSelectComposition = (e, item, idx) => {
    console.log(">>>>>>>>>>>>>>>>>>>>>>> HandleProductSelect called *******************");
    console.log("SELECTED Dropdown value : ", e);
    console.log("SELECTED element full : ", item);
    console.log("SELECTED element idx : ", idx);
    //var currentId = data.name.substring(data.name.lastIndexOf("-") + 1);
    //console.log("Current id : ", currentId);
   // console.log("Product Info : ", productMaster);

    var itemSelected = items[idx];
    console.log("Passed element item : ", item);
    console.log("Item selected : ", itemSelected);


    items[selectedCompositionId].composition.push();
   
  };
  const handleOnItemChangeComposition = (e) => {
    //e.preventDefault();
    console.log("Button clicked : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);


    var currentId = name.substring(name.lastIndexOf("-") + 1);
    var nameOfElement = name.substring(0, name.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    var itemSelected = items[selectedCompositionId];
    console.log("selected composition for M grade products : ", itemSelected.composition);
    
    console.log("SELECTED COMPOSITION COMPONENt element : ", itemSelected.composition[currentId]);
    if (nameOfElement === "quantity") {
      console.log("Product Quantity item edited ...");
      if (e.currentTarget.value.match(appGlobal.numericRegExp)) { 
        itemSelected.composition[currentId].quantity = value;
      }
    } else {
      console.log("Other than handled option clicked so skipping it ...");
    }
    var newItemsTmp = items.slice();
    newItemsTmp[selectedCompositionId] = itemSelected;
    setItems(newItemsTmp);
  };

  const assignSelectedLocation = async(selected) => {
    console.log("USER SELECTED LOCATION VALUE : ", selected);
    if ( selected.length > 0 ){
      setFormValues({ ...formValues, ["address1"]: selected[0].label});

      var retStateTotQty = false;
      var totQty = 0.00;
      var dc_date_time = formValues["dc_date"] + " " + formValues["loading_start_time"];
      getTotVolPerCustPerProd(formValues["customer_id"], items[selectedDCItemRow].product_id, selected[0].label, dc_date_time);
      console.log("RETURNED TOTAL QUANTITY STATE 2: ", retStateTotQty);
      console.log("RETURNED TOTAL QUANTITY  2: ", totQty);
    
      if ( retStateTotQty ) {
        var itemSelected = items[selectedDCItemRow];
        itemSelected.total_quantity = parseFloat(totQty) + itemSelected.quantity;

        var itemsNew = items.slice();
        itemsNew[selectedDCItemRow] = itemSelected;
        setItems(itemsNew);
      }
      else {
        var itemSelected = items[selectedDCItemRow];
        itemSelected.total_quantity = parseFloat(totQty) + itemSelected.quantity;
        console.log("Modified total quantity >>>>>>>>>>>> : ", itemSelected.total_quantity);
        var itemsNew = items.slice();
        itemsNew[selectedDCItemRow] = itemSelected;
        setItems(itemsNew);
      }
    }
    
    //setOptionsSearchLoc([]);
  }


  dc_products_total = 0.00;
    items.map((item) => {
      console.log("IND ITEM : ", item);
      console.log("Total item price : ", item.total_item_price);
      //console.log("GST  : ", item.cgst + item.sgst + item.igst);
      dc_products_total = dc_products_total + item.total_item_price;
    })

    final_total = parseFloat(dc_products_total) + parseFloat(formValues["pumping_charges"]) + parseFloat(formValues["diesel_charges"]) + parseFloat(formValues["others_charges1"]);
    console.log("FINAL TOTAL at Inside Component : ", final_total);

  return (
    <>
      <Container>
        <SidebarMain />
        
        { (vehicleLoad && driverLoad) ?
        <Form>
          <Row>
            <Col xs={4} md={4} sm={4} lg={4}>
            <span style={{ fontSize: "1.5rem", fontWeight: "500" }}>Delivery Challan</span>
              {(state !== null && "action" in state && (state.action === "view" || state.action === "edit")) && (
                    <>
                      &nbsp;&nbsp;
                      <Badge pill bg="success">
                        <h6>{state.passed_element.dc_no}</h6>
                      </Badge>
                    </>
                  )}
            </Col>
            <Col
              xs={8}
              md={8}
              sm={8}
              lg={8}
              className="d-flex justify-content-end float-end"
            >
              
              {/*
              <Button id="orderPrint" 
                name = "orderPrint"  
                onClick={(e)  => handlePrintDC(e)}
                //disabled={deliveryChellan.length > 0 ? false: true}
                >
                  <BsFillPrinterFill
                    size={18}
                    style={{ color: "white", cursor: "pointer" }}
                    name={`print-order-dc-icon`}
                    id={`print-order-dc-icon`}
                  />
                &nbsp;
                Print DC
                </Button>
                &nbsp;&nbsp;
              */}
              <Button 
                variant="warning" 
                className="btn btn-warning btn-md"
                onClick={(e) => handleBack(e)}
                >
                <BsArrowLeftCircle
                  key={`back-btn-dc-key`}
                  size={24}
                  style={{ cursor: "pointer" }}
                  name={`back-icon-dc-name`}
                  id={`edit-icon-dc-id`}
                />
              </Button>
              {(state !== null && "action" in state && (state.action === "edit" || state.action === "view")) && (
                  <>
                      
                  &nbsp;&nbsp;
              <Button id="direct-dc-print" name = "direct-dc-print"  onClick={(e)  => handlePrintDCDirect(e)}>
                <BsFillPrinterFill
                  size={18}
                  style={{ color: "white", cursor: "pointer" }}
                  name={`print-direct-dc-icon`}
                  id={`print-direct-dc-icon`}
                />
                </Button>

                &nbsp;&nbsp;
                <Button variant="danger" className="btn btn-danger btn-md"  onClick={(e) => handleDelete(e)}>
                  <MdDelete
                    key={`delete-btn-direct-dc-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                  
                    name={`delete-icon-direct-dc-name`}
                    id={`delete-icon-direct-dc-id`}
                  />
                </Button>

                &nbsp;&nbsp;
                <Button variant="primary" className="btn btn-primary btn-md"  onClick={(e) => handleEdit(e)}>
                  <BsPencilSquare
                    key={`edit-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                  
                    name={`edit-icon-paBiSolidEditAlttient-name`}
                    id={`edit-icon-doctor-id`}
                  />
                </Button>
                </>
                )}
            </Col>
          </Row>
          <div className="ui-divider"></div>
          <div className="ui-form">
            <hr align="center" />

            <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
                    <Form.Group
                      className="mb-3"
                      controlId="orderform.customer_name"
                    >
                      <Form.Label className="field">Customer Search</Form.Label>
                      <AsyncTypeahead
                        id="customer-search-fd-id"
                        name="customer_name_search"
                        isLoading={isLoading}
                        onSearch={(query) => {
                          searchHandleFunc(query);
                        }}
                        onChange={(selected) => {
                          customerSearchSelectFunc(selected);
                        }}
                        //isDisabled={isReadOnly}
                        options={optionsSearch}
                        placeholder="Search Customer ..."
                        readOnly={(state === null || state.action === 'add') ? isReadOnly : true}
                        style={{
                          /*border: !isReadOnly && "0px solid gray",*/
                          borderColor: "gray",
                          /*borderStyle: "solid",*/
                        }}
                        className="rounded-sm"
                      />
                    </Form.Group>
              </Col>
                    <Col xs={2} md={2} sm={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Customer Name </Form.Label>
                  <Form.Control
                    type="text"
                    name="customer_name"
                    id="customer_name"
                    placeholder="Customer Name"
                    value={formValues.customer_name}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                    disabled={true}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                    maxLength={12}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.customer_name}</p>
              </Col>

              <Col xs={2} md={2} sm={2} lg={2}>
                <div key={`inline-radio`} className="mb-3" style={{paddingTop: '35px'}}>
                <Form.Group>
                  <Form.Check
                    disabled={isReadOnly}
                    inline
                    label="New Site"
                    name="site"
                    type={"radio"}
                    id={`inline-radio-newsite`}
                    onChange={(e) => handleRadioChange(e)}
                    checked={ formValues["site"] === 'NEWSITE' ? true : false}
                  />
                  <Form.Check
                    disabled={isReadOnly}
                    inline
                    label="Old Site"
                    name="site"
                    type={"radio"}
                    id={`inline-radio-oldsite`}
                    onChange={(e) => handleRadioChange(e)}
                    checked={ formValues["site"] === 'OLDSITE' ? true : false}
                  />
                  </Form.Group>
                </div>
              </Col>

              { formValues.site === "OLDSITE" &&
              <Col xs={3} md={3} sm={3} lg={3} style={{paddingTop: '30px'}}>
                  <Select
                    id="sitelist"
                    name="sitelist"
                    value={{
                      value: siteIdSelect.value,
                      label: siteIdSelect.label,
                    }}
                    options={optSite}
                    defaultValue={{
                      value: siteIdSelect.value,
                      label: siteIdSelect.label,
                    }}
                    onChange={handleSiteSelect}
                    isDisabled={isReadOnly}
                  />
                <p className="error-msg">{formErrors.sitelist}</p>
              </Col>
            }
            <Col xs={2} md={2} sm={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label className="field">E-WayBill Number </Form.Label>
                  <Form.Control
                    type="text"
                    name="ewaybill_number"
                    id="ewaybill_number"
                    placeholder="E-WayBill Number"
                    value={formValues.ewaybill_number}
                    readOnly={isReadOnly}
                    onChange={handleValueChange}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                    maxLength={12}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.ewaybill_number}</p>
              </Col>

            </Row>
            <Row>
              <Col xs={6} md={3} sm={4} lg={2}>
                <Form.Group className="mb-3" controlId="dcform.dc_date">
                  <Form.Label>DC Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="dc_date"
                    placeholder="DC Date"
                    value={formValues.dc_date}
                    readOnly={isReadOnly}
                    onChange={onChangeDatePicker}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} md={3} sm={4} lg={2}>
                <Form.Group
                  className="mb-3"
                  controlId="dcform.loading_start_time"
                >
                  <Form.Label>Loading start time </Form.Label>
                  <Form.Control
                    type="time"
                    name="loading_start_time"
                    value={formValues.loading_start_time}
                    onChange={onChangeLoadingStartTime}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
              </Col>

              <Col xs={6} md={3} sm={4} lg={2}>
                <Form.Group
                  className="mb-3"
                  controlId="dcform.loading_end_time"
                >
                  <Form.Label>Loading end time </Form.Label>
                  <Form.Control
                    type="time"
                    name="loading_end_time"
                    value={formValues.loading_end_time}
                    readOnly={isReadOnly}
                    onChange={onChangeLoadingEndTime}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group> 
              </Col>

              <Col xs={6} md={3} sm={4} lg={3}>
                <Form.Group className="mb-3" controlId="dcform.vehicle">
                  <Form.Label className="field required">Vehicle</Form.Label>
                   <Select
                    id="vehicle-dropdown"
                    name="vehicle"
                    value={{
                      value: vehicleIdSelect.value,
                      label: vehicleIdSelect.label,
                    }}
                    options={optVehicle}
                    defaultValue={{
                      value: vehicleIdSelect.value,
                      label: vehicleIdSelect.label,
                    }}
                    onChange={handleVehicleSelect}
                    isDisabled={isReadOnly}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.vehicle}</p>
              </Col>

              <Col xs={6} md={3} sm={4} lg={3}>
                <Form.Group className="mb-3" controlId="dcform.driver">
                  <Form.Label className="field required">Driver </Form.Label>
                  <Select
                    name="driver"
                    value={{
                      value: driverIdSelect.value,
                      label: driverIdSelect.label,
                    }}
                    options={optDriver}
                    defaultValue={{
                      value: driverIdSelect.value,
                      label: driverIdSelect.label,
                    }}
                    onChange={handleDriverSelect}
                    isDisabled={isReadOnly}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.driver}</p>
              </Col>
              
            </Row>

              <Row>
              <Col xs={3} md={3} sm={3} lg={2}>
                    <Form.Group
                      className="mb-3"
                    
                    >
                      <Form.Label className="field">Street / Location Search</Form.Label>
                      <AsyncTypeahead
                        id="location-search-fd-id"
                        name="address1_search"
                        //searchText={"Avinashi" || formValues.address1}
                        //defaultInputValue={"Avinashi" || formValues.address1}
                        isLoading={isLoading}
                        onSearch={(query) => {
                          searchHandleLocationFunc(query);
                        }}
                        onChange={(selected) => {
                          //locationSearchSelectFunc(selected);
                          assignSelectedLocation(selected);
                          //setFormValues({ ...formValues, ["address1"]: selected[0].label});
                        }}
                        options={optionsSearchLoc}
                        placeholder="Search Location ..."
                        readOnly={(state === null || state.action === 'add') ? isReadOnly : true}
                        style={{
                          /*border: !isReadOnly && "0px solid gray",*/
                          borderColor: "gray",
                          /*borderStyle: "solid",*/
                        }}
                        className="rounded-sm"
                      />
                    </Form.Group>

                    {/*<p className="error-msg">{formErrors.address1}</p>*/}
                    </Col>

                    <Col xs={6} md={3} sm={4} lg={2}>

                <Form.Group className="mb-3" controlId="dcform.address1">
                  <Form.Label className="field required">Street / Location </Form.Label>
                  <Form.Control
                    type="text"
                    name="address1"
                    placeholder="Street / Location"
                    value={formValues.address1}
                    readOnly={isReadOnly}
                    onChange={handleValueChange}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.address1}</p>
              </Col>

              <Col xs={6} md={3} sm={4} lg={2}>
                <Form.Group className="mb-3" controlId="dcform.address2">
                  <Form.Label className="field">City / Town </Form.Label>
                  <Form.Control
                    type="text"
                    name="address2"
                    placeholder="City / Town"
                    value={formValues.address2}
                    readOnly={isReadOnly}
                    onChange={handleValueChange}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.address2}</p>
              </Col>

              <Col xs={6} md={3} sm={4} lg={2}>
                <Form.Group className="mb-3" controlId="dcform.district">
                  <Form.Label>District </Form.Label>
                  <Select
                    id="district"
                    name="district"
                    value={{
                      value: districtIdSelect.value,
                      label: districtIdSelect.label,
                    }}
                    options={optDist}
                    defaultValue={{
                      value: districtIdSelect.value,
                      label: districtIdSelect.label,
                    }}
                    onChange={handleDistrictSelect}
                    isDisabled={isReadOnly}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.district}</p>
              </Col>

              <Col xs={6} md={3} sm={4} lg={2}>
                <Form.Group className="mb-3" controlId="dcform.state">
                  <Form.Label>State </Form.Label>
                  <Select
                   
                    name="state"
                    value={{
                      value: stateIdSelect.value,
                      label: stateIdSelect.label,
                    }}
                    options={optState}
                    defaultValue={{
                      value: stateIdSelect.value,
                      label: stateIdSelect.label,
                    }}
                    onChange={handleStateSelect}
                    isDisabled={isReadOnly}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.state}</p>
              </Col>
              <Col xs={6} md={3} sm={4} lg={2}>
                <Form.Group className="mb-3" controlId="dcform.pincode">
                  <Form.Label className="field">Pincode </Form.Label>
                  <Form.Control
                    type="text"
                    name="pincode"
                    placeholder="Pincode"
                    value={formValues.pincode}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                    maxLength={6}
                  />
                </Form.Group>

                <p className="error-msg">{formErrors.pincode}</p>
              </Col>
            </Row>

            <Row>
            <Col xs={6} md={3} sm={4} lg={2}>
                <Form.Group className="mb-3" controlId="dcform.contact_person">
                  <Form.Label  className="field">Contact Person </Form.Label>
                  <Form.Control
                    type="text"
                    name="contact_person"
                    placeholder="Contact Person"
                    value={formValues.contact_person}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.contact_person}</p>
              </Col>

              <Col xs={6} md={3} sm={4} lg={2}>
                <Form.Group className="mb-3" controlId="dcform.contact_number">
                  <Form.Label className="field">Contact Number </Form.Label>
                  <Form.Control
                    type="text"
                    name="contact_number"
                    placeholder="Contact Number"
                    value={formValues.contact_number}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                    maxLength={10}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.contact_number}</p>
              </Col>
 
            </Row>

            <hr align="center" />

            <Row>
              <Col xs={6} md={6} sm={6} lg={6}>
                <div className="d-flex justify-content-start">
                  <h5>
                    <Badge pill bg="secondary">
                      Item Details
                    </Badge>
                  </h5>
                </div>
              </Col>

              {/*
                  &nbsp;&nbsp;
                  <p
                    className="error-msg"
                    style={{
                      position: "relative",
                      top: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    {formErrors.alertMsg}
                  </p>
               
              </Col>
                  */}
              {/*
              <Col xs={6} md={6} sm={6}>
                <div className="d-flex justify-content-end">
                  <Button
                    className="btn btn-warning"
                    style={{ alignItems: "self-end" }}
                    onClick={newItemHandle}
                  >
                    Add Item
                  </Button>
                </div>
              </Col>
                */}
            </Row>

            {/*<Row style={{height:props.height}}><hr /></Row>*/}

            {/*
        <Row>
        <Col xs={3} md={3} sm={3}>
            
        <Dropdown  onSelect={handleSelect}>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item eventKey="m5">M.5</Dropdown.Item>
        <Dropdown.Item eventKey="m7.5">M7.5</Dropdown.Item>
        <Dropdown.Item eventKey="m10">M10</Dropdown.Item>
        <Dropdown.Item eventKey="m15" active>M15</Dropdown.Item>
        <Dropdown.Item eventKey="m20">M20</Dropdown.Item>
        <Dropdown.Item eventKey="m25">M25</Dropdown.Item>
        <Dropdown.Item eventKey="m30">M30</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    */}
            {/*
    <Select
                    value={options.value}
                    options={options}
                    defaultValue={{value: formValues.product.value, label: formValues.product.label}}
                    onChange={handleSelect}
                />
        </Col>
        <Col xs={3} md={3} sm={3}>
        <div className="field">
          <input 
          type="number" 
          name="quantity" 
          placeholder="Quantity"
          value={formValues.quantity}
          onChange={handleChange}
          />
          <label>m3</label>
        </div>
        <p>{ formErrors.quantity}</p>
        
        </Col>
        </Row>
    */}

            

            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Product</th>
                  <th className="field required">Volume</th>
                  <th className="field required">Total Volume</th>
                  <th className="field required">Unit Price</th>
                  <th>Product Price</th>
                  {/*<th>GST</th>*/}
                  {/*<th>Total Price</th>*/}
                  {/*state.action === "add" && <th>...</th>*/}
                </tr>
              </thead>

              <tbody>
                {items.map((item, idx) => (
                  <tr>
                    <td style={{paddingTop:"15px"}}>{idx + 1}</td>
                    
                                  <td className="col-2"
                            >
                              <Row>
                                <Col sm={9} md={9} xs={12} lg={9}>
                                  <Select
                                    className="mb-1"
                                    id={`product-item-${idx}`}
                                    name={`product-item-${idx}`}
                                    //value={options.value}
                                    value={{
                                      value: item.product_id,
                                      label: item.product_name,
                                    }}
                                    options={productMaster}
                                    defaultValue={{
                                      value: item.product_id,
                                      label: item.product_name,
                                    }}
                                    onChange={handleSelect}
                                    isDisabled={isReadOnly}
                                  />
                                </Col>
                                { item.is_product_cc &&
                                <Col sm={3} md={3} xs={12} lg={3} style={{ paddingTop: "5px"}}>
                                  <Button
                                    column sm="3"
                                    variant="primary"
                                    className="btn btn-primary btn-sm"
                                    onClick={handleCompositeEdit}
                                    name={`edit-btn-order-name-${idx}`}
                                    id={`edit-btn-order-id-${idx}`}
                                    
                                    //disabled={(item.enableState || (!isReadOnly && (state.action === 'view' || state.action === 'edit'))) ? false : true}
                                  //disabled={submitAction ? true: false}
                                  disabled={isReadOnly ? true : false}
                                  >
                                    {item.editAction ?
                                      <>
                                        <Spinner
                                          as="span"
                                          animation="border"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                        <span className="visually-hidden">Loading...</span>
                                      </> :
                                      ''}
                                    {!item.editAction &&
                                      <BsPencilSquare
                                        key={`edit-btn-order-key`}
                                        size={12}
                                        style={{ cursor: "pointer" }}
                                      />
                                    }
                                  </Button>
                                </Col>
                                }
                              </Row>
                              <p className="error-msg">{item.error}</p>
                            </td>
                    <td style={{width: "20%"}}>
                      <Form.Group  as={Row} className="mb-3" controlId="m3">
                        <Col xs={8} sm={8} md={8} lg={8}>
                          <Form.Control
                            type="text"
                            name={`quantity-${idx}`}
                            placeholder="Volume"
                            value={item.quantity}
                            // max="100000"
                            onChange={handleChange}
                            readOnly={isReadOnly}
                            maxLength={5}
                          />
                        </Col>
                      </Form.Group>
                      <p className="error-msg">{item.quantity_err}</p>
                    </td>
                   
                    {/*<td style={{color: "red", paddingTop: "15px"}}>{item.total_quantity.toFixed(2)}</td>*/}
                    <td>
                    <Form.Control
                                style={{ padding: "5px", textAlign: "right", color:"red" }}
                                type="text"
                                id={`total_quantity-${idx}`}
                                name={`total_quantity-${idx}`}
                                placeholder="Total Volume"
                                value={item.total_quantity}
                                maxLength={10}
                                onChange={handleChange}
                                readOnly={isReadOnly}
                              />
                              <p className="error-msg">{item.total_quantity_err}</p>
                    </td>
                    {/*<td>{item.unit_price}</td>*/}
                    <td>
                    <Form.Control
                                style={{ padding: "5px", textAlign: "right" }}
                                type="text"
                                name={`unit_price-${idx}`}
                                placeholder="Unit Price"
                                value={item.unit_price}
                                maxLength={10}
                                onChange={handleChange}
                                readOnly={isReadOnly}
                              />
                    </td>

                    {/*<td style={{paddingTop:"15px"}}>{item.total_item_price}</td>*/}
                    {/*<td>{item.cgst + item.sgst + item.igst}</td>*/}
                    <td style={{paddingTop:"15px"}}>
                    {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(item.total_item_price).toFixed(2)))}
                    </td>
                    {/*
                    {state.action === "add" && (
                      <td>
                        <BsFillXCircleFill
                          size={28}
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={(e) => handleItemDelete(e)}
                          name={`item-delete-icon-${idx}`}
                          id={`item-delete-icon-${idx}`}
                        />
                      </td>
                    )}
                    */}
                  </tr>
                ))}
              </tbody>
            </Table>

            <Row>
              <Col xs={2} sm={2} md={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>Pumping Charges </Form.Label>

                  <Form.Control
                    type="text"
                    placeholder="Pumping Charges"
                    name="pumping_charges"
                    id="pumping_charges"
                    value={formValues.pumping_charges}
                    maxLength={10}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray", textAlign: "right"
                    }}
                  />
                </Form.Group>
              </Col>

              <Col xs={2} sm={2} md={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>Diesel Charges </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Diesel Charges"
                    name="diesel_charges"
                    id="diesel_charges"
                    value={formValues.diesel_charges}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray", textAlign: "right"
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={5} sm={5} md={5} lg={5}>
                <Form.Group className="mb-3" controlId="dcform.reason">
                  <Form.Label>Others </Form.Label>
                  <Form.Control
                    //as="textarea" rows={1}
                    type="text"
                    placeholder="Reason"
                    name="others_reason1"
                    value={formValues.others_reason1}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                  />
                </Form.Group>
              </Col>
              <Col xs={3} sm={3} md={3} lg={3}>
                <Form.Group className="mb-3" controlId="dcform.amount">
                  <Form.Label>Amount </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Amount"
                    name="others_charges1"
                    value={formValues.others_charges1}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray", textAlign: "right"
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row style={{ height: props.height }}>
              <br></br>
              <p></p>
            </Row>

            <Row>
              {/*
              <Col xs={3} md={3} sm={3}>
                <div style={{ fontWeight: "bold" }}>
                  Total Items : {total_items}
                </div>
              </Col>
              <Col xs={3} md={3} sm={3}>
                <div style={{ fontWeight: "bold" }}>
                  Total Product Cost : {sum_product_cost}
                </div>
              </Col>
              {/*
              <Col xs={3} md={3} sm={3}>
                <div style={{ fontWeight: "bold" }}>
                  Total GST : {sum_product_gst}
                </div>
              </Col>
              */}
              <Col xs={12} md={12} sm={12}>
                <div style={{ fontWeight: "bold" }} className="d-flex justify-content-end">
                 
                Total Cost : {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, currency: "INR" }).format(parseFloat(final_total).toFixed(2)))}
                </div>
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col xs={12} md={12} sm={12}>
                <div className="d-flex justify-content-end">
                  <Button
                    className="btn btn-secondary"
                    id="btn-clear-order-id"
                    name="btn-clear-order-name"
                    onClick={handleClear}
                  >
                    Clear
                  </Button>
                  &nbsp;&nbsp;
                  <Button 
                    className="btn btn-primary"
                    id="save-print"
                    name="save-print"
                    onClick={(e) => handleSubmit(e, "submit_print")}
                  >
                  { submitPrintAction &&
                    <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                  }
                  Save & Print</Button>
                  &nbsp;
                  <Button 
                    className="btn btn-primary"
                    id="save-only"
                    name="save-only"
                    onClick={(e) => handleSubmit(e, "submit")}
                  >
                  { submitAction &&
                    <Spinner
                       as="span"
                       animation="border"
                       size="sm"
                       role="status"
                      aria-hidden="true"
                     />
                  }
                  Save</Button>
                </div>
              </Col>
            </Row>
          </div>
          <Modal
              show={showAlert}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>{alertTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{alertBody}</Modal.Body>
              <Modal.Footer>
              { alertPurpose === 'delete' &&
              <Button variant="secondary" onClick={handleCloseDialog}>
                Cancel
              </Button>
              }
                <Button variant="primary" onClick={handleAlertOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
        </Form>
        :
        <div className="container-spinner">
     <div className="vertical-center-spinner">
      <Image src={logo_avinaerp} roundedCircle  style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          <Spinner animation="border" role="status" size="lg" variant="success" label="Spinning"   style={{width: "84px", height: "84px", position: "fixed", top: "54%", left: "48%" }}>                                  
              <span className="visually-hidden">Loading...</span>
            </Spinner>
      </div>
      </div>
        }
      </Container>
      <Modal show={showConfigDialog}>
        <Modal.Header>
          <Modal.Title>Composition Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col xs={8} md={8} sm={8} lg={8}>
                <h5>Composite  product - <span style={{color: 'green', fontStyle: 'italic', fontWeight: 'bold', fontFamily: 'Helvetica-Bold'}}>{ selectedCompositionId > -1 && items[selectedCompositionId].product_name }</span></h5>
              </Col>
              {/*
              <Col xs={6} md={6} sm={6}>
                <Button
                  className="btn btn-sm btn-warning d-flex justify-content-end pull-right float-end"
                  style={{ alignItems: "self-end" }}
                  onClick={newItemHandleComposition}
                //disabled={(disableComposite || isReadOnly) ? true : false}
                >
                  Add Item
                </Button>

              </Col>
              */}
            </Row>
            <Table
              striped bordered hover
              size="sm">
              <thead>
                <tr className="table-primary">
                  <th className="table-row-heading">S.No</th>
                  <th className="table-row-heading">Product</th>
                  <th className="table-row-heading">Qty</th>
                  <th className="table-row-heading">Uom</th>
                  {/*<th className="table-row-heading">...</th>*/}
                </tr>
              </thead>

              <tbody>
                {/*{productCompositionList.map((item, idx) => (*/}
                {(selectedCompositionId > -1 && 'composition' in items[selectedCompositionId]) &&
                items[selectedCompositionId].composition.map((item, idx) => (
                  <tr key={`prod-composition-table-${idx}`}>
                    <td className="w-25">{idx + 1}</td>
                    <td className="w-50">
                      <Select
                        id={`composite-product-item-${idx}`}
                        name={`composite-product-item-${idx}`}
                        //value={options.value}
                        value={{
                          value: item.product_id,
                          label: item.product_name,
                        }}
                        options={productList}
                        defaultValue={{
                          value: item.product_id,
                          label: item.product_name,
                        }}
                        //isDisabled={(disableComposite || isReadOnly)? true: false}
                        isDisabled={true}
                        onChange={(e, item) => handleProductSelectComposition(e, item, idx)}
                      />
                      <p className="error-msg">{item.error}</p>
                    </td>
                    <td className="w-25">
                      <Form.Group className="mb-3">
                        <Form.Control
                          //pattern="^[0-9]*\.[0-9]{2}$"
                          //step=".01"
                          type="text"
                          size="sm"
                          id={`quantity-${idx}`}
                          name={`quantity-${idx}`}
                          placeholder="Quantity"
                          onChange={(e) => handleOnItemChangeComposition(e)}
                          readOnly={isReadOnly}
                          value={item.quantity}
                          //disabled={(disableComposite || isReadOnly)? true: false}
                          //defaultValue={item.quantity}

                          style={{
                            border: !isReadOnly && "1px solid gray",
                          }}

                        />
                      </Form.Group>
                    </td>
                    <td>{item.uom}</td>
                    {/*
                    <td>
                      <BsFillXCircleFill
                        size={28}
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={(e) => handleItemDeleteComposition(e)}
                        name={`item-delete-icon-${idx}`}
                        id={`item-delete-icon-${idx}`}
                      />
                    </td>
                    */}
                  </tr>
                ))
                }
              </tbody>
            </Table>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseComposition}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveComposition}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      
    </>
  );
};

export default AddUpdateDirectDC;
